import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: ({ spacing }: { spacing: number | number[] }) =>
      Number.isInteger(spacing)
        ? theme.spacing(spacing as number, 0)
        : theme.spacing(...(spacing as [number, number, number, number])),
  },
  line: {
    flex: "auto",
    border: "1px solid",
    borderTop: 0,
    borderColor: theme.palette.divider,
  },
  dark: {
    borderColor: theme.palette.dividerDark.main,
  },
  label: {
    flex: "none",
    color: theme.palette.text.disabled,
    padding: theme.spacing(0, 1),
  },
}));

export interface DividerProps {
  label?: string;

  spacing?: number | number[];

  dark?: boolean;
}

/**
 * Horizontal line with optional text label.
 *
 * todo: consider allowing only preset spacing
 */
export function Divider({
  label,
  spacing = 1,
  dark,
}: DividerProps): ReactElement {
  const styles = useStyles({ spacing });

  return (
    <div className={styles.container}>
      <span className={clsx(styles.line, dark && styles.dark)} />
      {label && (
        <>
          <span className={styles.label}>{label}</span>
          <span className={clsx(styles.line, dark && styles.dark)} />
        </>
      )}
    </div>
  );
}
