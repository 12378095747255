import {
  createEntitySelector,
  EntityContainer,
  EntitySelector,
} from "../entity";
import { Badge } from "./Badge";
import { BadgeGroup } from "./BadgeGroup";

export type BadgeContainer = EntityContainer<Badge, "Badge">;

export type BadgeGroupContainer = EntityContainer<BadgeGroup, "BadgeGroup">;

export const selectBadge: EntitySelector<Badge> = createEntitySelector<
  Badge,
  BadgeContainer
>("Badge");

export const selectBadgeGroup: EntitySelector<BadgeGroup> = createEntitySelector<
  BadgeGroup,
  BadgeGroupContainer
>("BadgeGroup");
