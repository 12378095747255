import { Box, makeStyles } from "@material-ui/core";
import React, { PropsWithChildren } from "react";

const useStyles = makeStyles(() => ({
  breakWord: {
    wordBreak: "break-word",
  },
}));
export function WrapWords({ children }: PropsWithChildren<unknown>) {
  const style = useStyles();
  return <Box className={style.breakWord}>{children}</Box>;
}
