import { mdiTimerSandEmpty, mdiThumbUp, mdiThumbDown } from "@mdi/js";
import { ReimbursementStatus as Status } from "@sinch/entity";
import { t } from "@sinch/intl";
import { IconText } from "@sinch/ui";
import React, { ReactElement } from "react";

export function ReimbursementStatusElement({ status }: { status: Status }): ReactElement | null {
  switch (status) {
    case Status.Pending:
      return <IconText icon={mdiTimerSandEmpty}>{t<string>("Reimbursement.pending")}</IconText>;
    case Status.Approved:
      return (
        <IconText color="success" icon={mdiThumbUp}>
          {t<string>("Reimbursement.approved")}
        </IconText>
      );
    case Status.Rejected:
      return (
        <IconText color="error" icon={mdiThumbDown}>
          {t<string>("Reimbursement.rejected")}
        </IconText>
      );
    default:
      break;
  }
  return null;
}
