export enum ShiftFeedbackStatus {
  /**
   * Feedback can't be submitted because job is not finished.
   */
  Pending = 0,

  /**
   * Feedback can be submitted, user didn't send any.
   */
  Open = 1,

  /**
   * Feedback can be submitted.
   *
   * User already sent some, but there are still slots without feedback.
   */
  InProgress = 2,

  /**
   * User already sent feedback for all slots.
   */
  Completed = 3,

  /**
   * Feedback can't be submitted anymore.
   */
  Closed = 4,
}
