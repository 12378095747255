import { useBusinessRules } from "@sinch/core";
import { Fk, PayoutMethod } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, ButtonProps, GridLayout, routerLink } from "@sinch/ui";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";

interface CreatePayoutButtonProps extends Pick<ButtonProps, "variant" | "stretch" | "color"> {
  disabled?: boolean;
  method?: Fk<PayoutMethod>;
}

export function CreatePayoutButton({
  variant,
  disabled,
  stretch,
  color = "info",
  method,
}: CreatePayoutButtonProps): ReactElement | null {
  const { allowWorkerPaymentRequest } = useBusinessRules();
  const { search } = useLocation();
  if (!allowWorkerPaymentRequest) {
    return null;
  }
  return (
    <GridLayout.Item>
      <Button
        action={routerLink({ search, hash: "#/payout" }, { state: { method } })}
        color={color}
        disabled={disabled}
        size="small"
        stretch={stretch}
        variant={variant}
      >
        {t("Wallet.action.requestPayout")}
      </Button>
    </GridLayout.Item>
  );
}
