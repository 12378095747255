import { InputAdornment } from "@material-ui/core";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { mdiCalendar } from "@mdi/js";

import { t, useFormat, useIntl } from "@sinch/intl";
import { Consumer } from "@sinch/types";
import { Icon } from "@sinch/ui";
import { isValid, set as dateSet } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { mergeRight } from "ramda";
import React, { ReactElement, useCallback } from "react";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { DateInputProps } from "./DateInput.props";
import { PresetProps, useDateTimePreset } from "./DateInputPresetProvider";

export function DateInput({
  preset,
  correctTimezone,
  ...props
}: PresetProps & DateInputProps & { correctTimezone?: boolean }): ReactElement {
  const presetProps = useDateTimePreset("DateInput", preset);
  const [baseProps, { setValue }] = useFormField<ParsableDate, DateInputProps>(mergeRight(presetProps, props));
  const { timeZone } = useIntl();

  const onChange = useCallback(
    (next) => {
      setValue(next);
    },
    [setValue]
  );

  let value: object | string | number | Date | null | undefined;
  if (!correctTimezone) {
    value = baseProps.value;
  } else {
    value = baseProps.value ? utcToZonedTime(baseProps.value || new Date(), timeZone) : null;
  }
  return (
    <DateInputBase
      {...baseProps}
      onChange={(date) => {
        if (!correctTimezone) {
          onChange(date);
        } else if (date) {
          let zoned = utcToZonedTime(baseProps.value || new Date(), timeZone);
          zoned = dateSet(zoned, {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
          });
          onChange(zonedTimeToUtc(zoned, timeZone));
        }
      }}
      value={value}
    />
  );
}

const defaultFormatFn = (): DatePickerProps["labelFunc"] => {
  const { dt } = useFormat();
  return (date, invalidLabel) => (isValid(date) ? dt(date as Date) : invalidLabel || "");
};

export function DateInputBase({
  dense,
  disabled,
  disableFuture,
  disablePast,
  error,
  format,
  label,
  maxDate,
  minDate,
  name,
  note,
  nullable,
  onChange,
  onInvalid,
  openTo,
  placeholder,
  value,
  views,
  className,
  labelFunc,
  adornment = true,
  ...props
}: BaseInputProps<ParsableDate, DateInputProps, Consumer<MaterialUiPickersDate>>): ReactElement {
  return (
    <DatePicker
      {...props}
      cancelLabel={t("action.cancel")}
      className={className}
      clearable={nullable}
      clearLabel={t("action.clear")}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      emptyLabel={placeholder}
      error={error}
      format={format}
      fullWidth
      helperText={note}
      InputProps={{
        endAdornment: adornment && (
          <InputAdornment position="end">
            <Icon icon={mdiCalendar} />
          </InputAdornment>
        ),
      }}
      inputVariant="outlined"
      label={label}
      labelFunc={labelFunc || defaultFormatFn()}
      margin={dense ? "dense" : "normal"}
      maxDate={maxDate}
      minDate={minDate}
      name={name}
      okLabel={t("ok")}
      onChange={onChange}
      onInvalid={onInvalid}
      openTo={openTo}
      placeholder={placeholder}
      value={value}
      variant="dialog"
      views={views}
    />
  );
}
