import {
  DataProvider,
  HashRoute,
  PagingParams,
  ScrollHistory,
  useCurrentUser,
  useRefresh,
  useSearchParamsCustom,
} from "@sinch/core";
import { useCachedResponse } from "@sinch/core/backend/provider/CachedResponseProvider";
import { useMobileLayout } from "@sinch/ui";
import { isNil } from "ramda";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
  PositionSearchParams,
  PositionSearchUrlParams,
  requestPositionList,
  requestPositionRecentSearch,
} from "../../Position";
import { ShiftFeedback } from "../../Shift";
import { PenaltyList, SuspendedPenaltyList } from "../Penalty";
import { requestDashboardAttendanceList } from "./api";
import { DesktopDashboard } from "./DesktopDashboard";
import { MobileDashboard } from "./Mobile/MobileDashboard";

function Dashboard(): ReactElement {
  const mobile = useMobileLayout();

  return mobile ? <MobileDashboard /> : <DesktopDashboard />;
}

export function DashboardContainer(): ReactElement {
  const request = useMemo(() => requestDashboardAttendanceList(), []);
  const { refresh, refreshToken } = useRefresh();
  const mobile = useMobileLayout();

  return (
    <>
      <DataProvider cache refresh={refreshToken} refresher={refresh} request={request}>
        <ScrollHistory>
          <Dashboard />
        </ScrollHistory>
        <HashRoute path="shift/:shift/feedback/*">
          {/* @ts-expect-error */}
          <ShiftFeedback />
        </HashRoute>
      </DataProvider>
      <HashRoute path="suspendedPenalty">
        <SuspendedPenaltyList />
      </HashRoute>
      <HashRoute path="penalty">
        <PenaltyList />
      </HashRoute>
      {!mobile && <PreloadPositions />}
    </>
  );
}

function PreloadPositions(): ReactElement | null {
  const [preload, setPreload] = useState(false);
  const [cached] = useCachedResponse();
  const { searchParams } = useSearchParamsCustom<PositionSearchUrlParams & PagingParams>([
    ["page", "1"],
    ["limit", "100"],
  ]);

  const {
    restrictions: { positionAccess },
  } = useCurrentUser();

  const positionRequest = useMemo(
    () => requestPositionList(PositionSearchParams.toRequest(searchParams), PagingParams.pick(searchParams)),
    [searchParams]
  );
  const recentSearchesRequest = useMemo(() => requestPositionRecentSearch(), []);

  const isCached = !isNil(cached[positionRequest.requestHash]);
  useEffect(() => {
    if (!isCached) {
      setPreload(true);
    }
  }, [isCached]);

  if (positionAccess !== true || isCached || !preload) {
    return null;
  }

  return (
    <>
      <DataProvider cache request={positionRequest} />
      <DataProvider cache request={recentSearchesRequest} />
    </>
  );
}
