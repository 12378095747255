import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/styles";
import { mdiImageOffOutline } from "@mdi/js";

import { useFiles } from "@sinch/core";
import { FileHash, Maybe, Predicate } from "@sinch/types";
import clsx from "clsx";
import { complement, concat, either, isNil, last, split, startsWith } from "ramda";
import { isNilOrEmpty, isString, isTrue } from "ramda-adjunct";
import React, { MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { Optional } from "utility-types";
import { Anchor } from "./Anchor";
import { Icon } from "./Icon";

interface ImageFixedProps {
  height?: string | number;
  width?: string | number;
}

const useStyles = makeStyles(({ palette }) => ({
  placeholder: {
    backgroundColor: palette.background.default,

    height: "100%",
    width: "100%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  fitHeight: {
    height: "100%",
  },

  fitWidth: {
    width: "100%",
  },

  scaleHeight: {
    left: "50%",
    height: "100%",
    position: "relative",
    transform: "translateX(-50%)",
  },
  scaleWidth: {
    top: "50%",
    width: "100%",
    position: "relative",
    transform: "translateY(-50%)",
  },
  fixed: ({ height, width }: ImageFixedProps) => ({
    height,
    width,
  }),
  round: {
    borderRadius: "50%",
  },
}));

type ImageScaling = "fitHeight" | "fitWidth" | "scaleHeight" | "scaleWidth";

interface ImageDisplayProps {
  scaling?: ImageScaling;
  fixed?: ImageFixedProps;
  round?: boolean;
}

export interface ImageFileProps {
  /**
   * Pass {@link FileInfo.hash} to resolve image `src` from referenced
   * {@link FileInfo.url}.
   */
  file: FileHash;

  /**
   * Wrap image with HTML anchor element.
   */
  href?: boolean;

  /**
   * Appended to resolved {@link FileInfo.url} for anchor `href` attribute.
   */
  hrefParam?: string;

  /**
   * Image source props `file` and `src` are mutually exclusive.
   */
  src?: never;

  /**
   * Appended to resolved {@link FileInfo.url} for image `src` attribute.
   */
  srcParam?: string;
}

export interface ImageSrcProps {
  /**
   * Image source props `file` and `src` are mutually exclusive.
   */
  file?: never;

  /**
   * Wrap image with HTML anchor element.
   *
   * - string value is used as anchor element `href` prop
   * - value of `src` prop is used instead when `true` is given
   */
  href?: boolean | string;

  /**
   * Image source URL.
   */
  src: string;
}

const isFileProps = (props: ImageFileProps | ImageSrcProps): props is ImageFileProps => "file" in props;

export const isNotImageType: Predicate<Maybe<string>> = either(isNilOrEmpty, complement(startsWith("image/")));

const srcToAlt = (src?: string): Maybe<string> => src && last(split("/", src));

export const resolveUrl = (url: string, param?: string): string => (param ? concat(url, param) : url);

const isTrueOrNil: Predicate<Maybe<boolean>> = either(isTrue, isNil);

export const resolveHref = (url: string, href?: boolean, hrefParam?: string): Maybe<string> =>
  isTrueOrNil(href) ? (href || hrefParam) && resolveUrl(url, hrefParam) : undefined;

function useImageProps(props: ImageFileProps | ImageSrcProps): { href?: string; name?: string; src: string } {
  const storage = useFiles();

  if (isFileProps(props)) {
    const { file, href, hrefParam, srcParam } = props;
    const { name, type, url } = storage(file);

    if (isNotImageType(type)) {
      console.warn("File passed into Image component is not a valid image type.", { hash: file, name, type, url });
    }

    return {
      href: resolveHref(url, href, hrefParam),
      name,
      src: resolveUrl(url, srcParam),
    };
  }

  const { href, src } = props;

  return {
    href: isString(href) ? href : resolveHref(src, href),
    name: srcToAlt(src),
    src,
  };
}

type ImageLoadState = "error" | "loading" | "ready";

function useLoadedState(src: string): ImageLoadState {
  const [state, setState] = useState<ImageLoadState>("loading");

  useEffect(() => {
    let mounted = true;

    const image = new window.Image();

    image.onload = () => {
      if (mounted) setState("ready");
    };

    image.onprogress = () => {
      console.log("image loading progress");
    };

    image.onerror = () => {
      if (mounted) setState("error");
    };

    image.src = src;

    return () => {
      mounted = false;
    };
  }, [src]);
  return state;
}

export type ImageProps = ImageDisplayProps &
  (ImageFileProps | ImageSrcProps) & {
    classes?: Optional<ClassNameMap<"loading" | "error" | "ready">>;
    onClick?: MouseEventHandler<"img"> | undefined;
  };

/**
 * todo: implement container with fixed width/height to use scaling
 *  (see MuiGridList)
 */
export function Image({ scaling, fixed, round, classes, onClick, ...props }: ImageProps): ReactElement {
  // todo fix this type mess
  const fixedPar = fixed ?? {};
  const styles = useStyles(fixedPar);
  const { href, name, src } = useImageProps(props);

  if (scaling && fixed) throw new Error("Invalid combination of scaling and fixed props provided to Image");

  const state = useLoadedState(src);

  /* eslint-disable-next-line default-case */
  switch (state) {
    case "loading":
      return (
        <div className={clsx(classes?.loading, styles.placeholder)}>
          <MuiCircularProgress color="secondary" />
        </div>
      );

    case "error":
      return (
        <Anchor href={href}>
          <div className={clsx(classes?.error, styles.placeholder)}>
            <Icon icon={mdiImageOffOutline} />
          </div>
        </Anchor>
      );

    case "ready":
      return (
        <Anchor href={href}>
          <img
            alt={name}
            className={clsx(classes?.ready, round && styles.round, scaling && styles[scaling], fixed && styles.fixed)}
            onClick={onClick}
            src={src}
          />
        </Anchor>
      );
  }
}
