import { Nullable } from "@sinch/types";
import { toElement } from "@sinch/utils";
import { ReactElement, ReactNode } from "react";
import { useFormValues } from "./useFormValues";
import { FormValues } from "./Values";

export type FormDependantCond<TValues extends FormValues> = (
  values: TValues
) => boolean | null | undefined;

interface FormDependantCondProps<TValues extends FormValues> {
  children: ReactNode;

  cond: FormDependantCond<TValues>;

  render?: never;
}

interface FormDependantRenderProps<TValues extends FormValues> {
  children?: never;

  cond?: never;

  render: (values: TValues) => ReactNode;
}

type FormDependantProps<TValues extends FormValues> =
  | FormDependantCondProps<TValues>
  | FormDependantRenderProps<TValues>;

const isCondProps = (
  props: FormDependantProps<never>
): props is FormDependantCondProps<never> => "cond" in props;

const isRenderProps = (
  props: FormDependantProps<never>
): props is FormDependantRenderProps<never> => "render" in props;

export function FormDependant<TValues extends FormValues>(
  props: FormDependantProps<TValues>
): Nullable<ReactElement> {
  const values = useFormValues<TValues>();

  if (isRenderProps(props)) {
    const { render } = props;
    return toElement(render(values));
  }

  if (isCondProps(props)) {
    const { children, cond } = props;
    if (cond(values)) return toElement(children);
  }

  return null;
}
