import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import {
  Fk,
  PayoutContainer,
  PayoutMethodContainer,
  WalletTransaction,
  WalletTransactionContainer,
  WalletTransactionType,
} from "@sinch/entity";

export interface WalletTransactionListParams {
  from?: Date;

  to?: Date;

  transactionType?: WalletTransactionType;

  includeArchived?: boolean;
}

export const requestWalletTransactionList = defineRequest("Wallets/TransactionList")
  .withParams<WalletTransactionListParams>()
  .withMeta<PagingParams>()
  .withResponse<{
    walletTransactionIds: Fk<WalletTransaction>[];
    incomeAmount: number;
    payoutAmount: number;
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<PayoutContainer & PayoutMethodContainer & WalletTransactionContainer>()
  .withCreator((params: WalletTransactionListParams, paging: PagingParams) => ({
    meta: paging,
    params,
  }));
