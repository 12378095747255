import { mdiClose } from "@mdi/js";
import { IconButton as MuiIconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { FileListContainer, Icon, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { useFileInputContext } from "./FileInputProvider";

const useStyles = makeStyles((theme) => ({
  error: {
    padding: theme.spacing(0.5, 1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: theme.palette.background.default,
  },
}));

export interface FileInputErrorProps {
  text: string;
}

export function FileInputError({ text }: FileInputErrorProps): ReactElement {
  const styles = useStyles();

  const { closeError } = useFileInputContext();

  return (
    <div className={styles.error}>
      <Text color="warning">{text}</Text>
      <MuiIconButton color="primary" onClick={() => closeError(text)} size="small">
        <Icon icon={mdiClose} size="small" />
      </MuiIconButton>
    </div>
  );
}

export function FileInputErrors(): ReactElement {
  const { errors } = useFileInputContext();

  return (
    <FileListContainer>
      {errors.map((text) => (
        <FileInputError key={text} text={text} />
      ))}
    </FileListContainer>
  );
}
