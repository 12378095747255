import { OneOrMore } from "@sinch/types";
import { noop } from "@sinch/utils";
import i18n, { Module, Resource, TFunction } from "i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import HttpApi from "i18next-http-backend";
import { isEmpty, keys } from "ramda";
import { initReactI18next } from "react-i18next";
import ReactPostprocessor from "./reactPostprocessor";

const DEFAULT_LNG = "en";

interface InitIntlParams {
  dictionary: Resource;
  routerBasePath: string;
}

/**
 * todo: rename to initTranslations? or pass from IntlProvider?
 */
export function initIntl({ dictionary, routerBasePath }: InitIntlParams): void {
  const translations: Resource = {
    ...dictionary,
  };
  i18n
    .use(initReactI18next)
    .use(
      new HttpApi(null, {
        // path where resources get loaded from
        loadPath: (lngs, namespaces) =>
          `${isEmpty(routerBasePath) ? "" : `/${routerBasePath}`}/locales/${lngs[0].replace("-", "_")}/${
            namespaces[0]
          }.json`,
      })
    )
    .use(
      // @ts-ignore
      new ReactPostprocessor({
        keepUnknownVariables: true,
        prefix: "{{",
        suffix: "}}",
      }) as Module
    )
    .init(
      {
        lng: DEFAULT_LNG,

        fallbackLng: DEFAULT_LNG,

        ns: ["common", ...(keys(dictionary) as string[])],

        nsSeparator: ":",

        // for flat format
        keySeparator: false,

        defaultNS: "common",

        resources: {
          [DEFAULT_LNG]: translations,
        },

        partialBundledLanguages: true,

        // simplifyPluralSuffix: false,

        interpolation: {
          // not needed for react as it escapes by default
          escapeValue: false,
          prefix: "<<",
          suffix: ">>",
        },

        postProcess: ["reactPostprocessor"],

        /* @ts-expect-error */
        useSuspense: false,

        appendNamespaceToCIMode: false,
      },
      noop
    );
}

export function useTranslation(): TFunction {
  return i18n.t.bind(i18n);
}

/**
 * @deprecated use hooks to access intl functions
 * todo: replace usage and remove
 */
// @ts-ignore
export const t: TFunction = (...params) => i18n.t<OneOrMore<string>>(...params);
