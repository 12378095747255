import * as Sentry from "@sentry/react";
import { Consumer } from "@sinch/types";
import Axios, { AxiosError, AxiosInstance } from "axios";
import { omit } from "ramda";
import {
  Backend,
  FileUploadProgress,
  FileUploadRequest,
  FileUploadResponse,
  isExpectedErrorStatus,
  isSupported,
  isUnexpectedErrorStatus,
  RequestCreator,
  RequestData,
  RequestOf,
  ResponseData,
  ResponseOf,
  Status,
  withResponseDefaults,
} from "../contract";

export interface RemoteBackendConfig {
  timeout: number;

  url: string;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function isAxiosError(error: any): error is AxiosError<ResponseData> {
  if ("isAxiosError" in error) {
    return error.isAxiosError;
  }
  return false;
}

/**
 * @deprecated todo: temporary workaround - remove when not needed
 *  server should accept request without params and meta without throwing error
 */
function requestDefaults({ key, meta, params }: RequestData): RequestData {
  return {
    key,
    meta: meta ?? {},
    params: params ?? {},
  };
}

/**
 * Send requests to remote json api via http client
 *
 * todo: cleanup error response handlers
 *  - define behaviour for timeout (request sent but no response received)
 *  - check for known status codes, how to handle unknown? throw or use default?
 *  - extract common error handlers for data request and file upload
 *
 * todo: enable request cancellation (via axios token)
 */
export function createBackend({ timeout, url }: RemoteBackendConfig): Backend {
  const axios: AxiosInstance = Axios.create({
    baseURL: url,
    method: "POST",
    withCredentials: true,
  });

  function sendRequest<TResponse>(request: RequestData) {
    return axios.request<TResponse>({
      data: request,
      timeout,
    });
  }

  function sendFile({ file, target }: FileUploadRequest, onProgress?: Consumer<FileUploadProgress>) {
    const formData = new FormData();
    formData.append("target", target);
    formData.append("file", file);

    return axios.request<FileUploadResponse>({
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: onProgress ? ({ loaded, total }: ProgressEvent) => onProgress({ loaded, total }) : undefined,
    });
  }

  async function resolveRequest<TCreator extends RequestCreator>(
    request: RequestOf<TCreator>
  ): Promise<ResponseOf<TCreator>> {
    try {
      const response = await sendRequest<ResponseOf<TCreator>>(requestDefaults(request));
      const { data, status } = response;

      let forceOk = false;

      if (!isSupported(status)) {
        forceOk = true;
        console.warn("Unsupported response status, using default OK 200", request.key, status);
      }

      return {
        /* @ts-expect-error */
        status: forceOk ? 200 : (status as Status),
        ...data,
      };
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response) {
          const {
            response: {
              data: { messages },
              status,
            },
          } = error;

          if (isSupported(status) && isExpectedErrorStatus(status)) {
            return {
              status,
              messages,
            } as ResponseOf<TCreator>;
          }

          if (isSupported(status) && isUnexpectedErrorStatus(status)) {
            Sentry.withScope((scope) => {
              scope.setContext("xhrRequest", omit(["resultParser"], request));
              Sentry.captureException(error);
            });
            throw {
              status,
              messages,
            };
          }

          console.warn("Unsupported response status:", request.key, status);
          throw error;
        }

        if (error.request) {
          console.warn("Backend request lost or timeout:", request.key);

          /*
          return {
            code: -1,
            request,
          };
          */
          throw error;
        }
      }

      console.error("Backend request error");
      throw error;
    }
  }

  async function uploadFile(
    request: FileUploadRequest,
    onProgress?: Consumer<FileUploadProgress>
  ): Promise<FileUploadResponse> {
    try {
      const response = await sendFile(request, onProgress);
      const { data, status } = response;

      return {
        ...data,
        status: status as Status,
      };
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response) {
          const {
            response: {
              data: { messages },
              status,
            },
          } = error;

          if (isSupported(status) && isExpectedErrorStatus(status)) {
            return withResponseDefaults<FileUploadResponse>({
              status,
              messages,
            });
          }

          console.warn("Unsupported response status:", request.target, status);
          throw error;
        }

        if (error.request) {
          console.warn("Backend request lost or timeout:", request.target);
          throw error;
        }
      }

      console.error("Backend request error");
      throw error;
    }
  }

  return {
    type: "remote",
    resolveRequest,
    uploadFile,
  };
}
