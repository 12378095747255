import { HashRoute, useCurrentUser, useData, useSearchParamsCustom } from "@sinch/core";
import {
  CalendarEntryType,
  selectAppointment,
  selectCalendarEntry,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  selectTransport,
  Shift,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, Grid, routerLink } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import {
  flatten,
  head,
  includes,
  isNil,
  map,
  pick,
  pipe,
  props,
  reject,
  symmetricDifference,
  uniq,
  values,
} from "ramda";
import { concatAll, renameKeys } from "ramda-adjunct";
import React, { ReactElement, useMemo } from "react";
import { Event } from "react-big-calendar";
import { HibernationStatusStrip } from "../components";
import { requestCalendar } from "./api";
import { AvailabilityForm } from "./AvailabilityForm";
import { CalendarBigCalendar, GenEvent } from "./CalendarBigCalendar";
import { CalendarExportDialog } from "./CalendarExportDialog";
import { EntryDetailDialog } from "./EntryDetailDialog";
import { SelectSlotDialog } from "./SelectSlotDialog";

const entitiesToEvents = map<any, GenEvent>(
  pipe(
    renameKeys({
      startTime: "start",
      endTime: "end",
      name: "title",
    }),
    pick(["id", "start", "end", "title", "type"])
  )
);

const setEventType = (type: CalendarEntryType) =>
  // @ts-ignore
  map<Event, GenEvent>((event: GenEvent) => ({ type, ...event }));

const extractTransportIds = pipe<
  Shift["transport"][],
  Shift["transport"][],
  number[],
  number[]
  // @ts-expect-error
>(reject(isNil), map(props(["from", "to"])), flatten);

export function Calendar(): ReactElement {
  const {
    restrictions: { hibernated },
  } = useCurrentUser();
  const { selectResult, selectEntity } = useData(requestCalendar);

  const {
    calendarEntryIds,
    positionIds,
    appointmentIds,
    applicantSlotsIds,
    needApplicantConfirmationSlotIds,
  } = selectResult();
  const { searchParams } = useSearchParamsCustom<Record<string, string>>();

  const events = useMemo((): GenEvent[] => {
    const calendarEvents = entitiesToEvents(selectEntity(selectCalendarEntry(calendarEntryIds)));
    const positionEvents = setEventType(CalendarEntryType.Position)(
      entitiesToEvents(
        selectEntity(selectPosition(positionIds))
          .filter((pos) => !head(selectEntity(selectPositionAttendance({ position: pos.id }, "confirmation"))))
          .map((position) => ({
            ...position,
            title: selectEntity(selectShift(position.shift, "name")),
          }))
      )
    );

    const confirmationEvents = setEventType(CalendarEntryType.Confirmation)(
      entitiesToEvents(
        selectEntity(selectPosition([...positionIds, ...uniq(flatten(values(needApplicantConfirmationSlotIds)))]))
          .filter(
            (pos) =>
              includes(pos.id, flatten(values(needApplicantConfirmationSlotIds))) ||
              Boolean(head(selectEntity(selectPositionAttendance({ position: pos.id }, "confirmation"))))
          )
          .map((position) => ({
            ...position,
            title: selectEntity(selectShift(position.shift, "name")),
          }))
      )
    );
    const appointmentEvents = setEventType(CalendarEntryType.Appointment)(
      entitiesToEvents(selectEntity(selectAppointment(appointmentIds)))
    );

    const applicantEvents = setEventType(CalendarEntryType.Applicant)(
      entitiesToEvents(
        selectEntity(
          selectPosition(
            symmetricDifference(applicantSlotsIds, uniq(flatten(values(needApplicantConfirmationSlotIds))))
          )
        ).map((position) => ({
          ...position,
          title: selectEntity(selectShift(position.shift, "name")),
        }))
      )
    );

    const shifts = selectEntity(selectPosition(positionIds, "shift"));
    const transportsIds = selectEntity(selectShift(shifts, "transport"));
    // @ts-expect-error
    const transportIdsFlatten = rejectFalsy(extractTransportIds(transportsIds));
    const transportEvents = setEventType(CalendarEntryType.Transport)(
      entitiesToEvents(
        selectEntity(selectTransport(transportIdsFlatten)).map((transport) => {
          const title = selectEntity(selectShift({})).filter(
            (shift) => shift.transport?.to === transport.id || shift.transport?.from === transport.id
          );
          return { ...transport, title: title.shift()?.name };
        })
      )
    );
    return (
      concatAll<GenEvent[]>(
        map(values, [
          applicantEvents,
          positionEvents,
          confirmationEvents,
          calendarEvents,
          appointmentEvents,
          transportEvents,
        ])
      ) || []
    );
  }, [calendarEntryIds, positionIds, appointmentIds, applicantSlotsIds]);

  return (
    <>
      <Grid spacing="outer" vertical>
        {!isNil(hibernated) && hibernated !== false && <HibernationStatusStrip />}
        <CalendarBigCalendar events={events} />
        <Grid sizes={{ xs: 12, md: 3, lg: 3 }} spacing="inner">
          <Button
            action={routerLink({
              search: `?${new URLSearchParams(searchParams)}`,
              hash: "#/export",
            })}
            color="primary"
          >
            {t("Calendar.action.export") /* Exportovat kalendář */}
          </Button>
        </Grid>
      </Grid>
      <HashRoute path="detail/:type/:entry">
        <EntryDetailDialog />
      </HashRoute>
      <HashRoute path="selection">
        <SelectSlotDialog />
      </HashRoute>
      <HashRoute path="availability/:id">
        <AvailabilityForm />
      </HashRoute>
      <HashRoute path="export">
        <CalendarExportDialog />
      </HashRoute>
    </>
  );
}
