import { makeStyles } from "@material-ui/core";
import { availableLocales } from "@sinch/i18n";
import { useIntl } from "@sinch/intl";
import { format, getDay, parse, startOfWeek } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
/* eslint-disable import/no-internal-modules */
import React, { ReactElement, useEffect, useRef } from "react";
import { Calendar, CalendarProps, dateFnsLocalizer, DateLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSwiper } from "swiper/react";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".rbc-overlay": {
      backgroundColor: `${theme.palette.background.paper} !important`,
      borderColor: `${theme.palette.divider} !important`,
    },
  },
  calendar: {
    "& .rbc-day-bg, & .rbc-day-slot.rbc-time-column": {
      backgroundColor: theme.palette.background.paper,
      "&.rbc-selected-cell": {
        backgroundColor: theme.palette.action.selected,
      },
    },
    "& .rbc-day-bg.rbc-off-range-bg, & .rbc-day-slot.rbc-time-column.rbc-today": {
      backgroundColor: theme.palette.background.default,
    },
    "& .rbc-today": {
      backgroundColor: theme.palette.background.default,
    },
    "& .rbc-day-slot .rbc-time-slot": {
      borderColor: theme.palette.divider,
    },
    "& .rbc-button-link.rbc-show-more": {
      backgroundColor: "transparent",
    },
    "& .rbc-time-header-content": {
      "& .rbc-time-header-cell>.rbc-header": {
        borderBottom: "none",
      },
      "& .rbc-allday-cell": {
        display: "none",
      },
    },
  },
}));

/**
 * todo: synchronize with global intl settings
 */
const localizer: DateLocalizer = dateFnsLocalizer({
  format,
  parse,
  getDay,
  startOfWeek,
  locales: availableLocales,
});

export type BigCalendarProps = Omit<CalendarProps, "localizer" | "language">;

export function BigCalendar({ events, ...props }: BigCalendarProps): ReactElement {
  const styles = useStyles();
  const { safeLanguage, timeZone, locale } = useIntl();
  const localizedEvents = events?.map(({ start, end, ...event }) => ({
    ...event,
    // Calendar work with local time, convert dates
    start: start ? utcToZonedTime(start, timeZone) : undefined,
    end: end ? utcToZonedTime(end, timeZone) : undefined,
    timeZone,
  }));
  const divRef = useRef<HTMLElement>();
  const timer = useRef(0);
  const timeout = useRef<number | null>(null);
  const swiper = useSwiper();
  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener("touchstart", () => {
        if (timeout.current) {
          window.clearTimeout(timeout.current);
        }
        timeout.current = window.setTimeout(() => {
          if (swiper && swiper.disable) {
            swiper.disable();
          }
        }, 1000);
        timer.current = new Date().getTime();
      });
      divRef.current.addEventListener("touchend", () => {
        if (timeout.current) {
          window.clearTimeout(timeout.current);
        }
        timer.current = 0;
        if (swiper && swiper.enable) {
          swiper.enable();
        }
      });
    }
  }, [divRef, swiper]);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return (
    <div ref={divRef}>
      <Calendar
        {...props}
        className={styles.calendar}
        culture={safeLanguage || locale}
        events={localizedEvents}
        localizer={localizer}
      />
    </div>
  );
}
