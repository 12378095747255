import { FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { mdiBrightness3, mdiBrightness7 } from "@mdi/js";
import { requestUserPreferenceUpdate, useRequest, UserPreferenceContainer, UserPreferencesKeys } from "@sinch/core";
import { ColorTheme } from "@sinch/core/contract";
import { t } from "@sinch/intl";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Icon } from "../../Icon";
import { useDarkTheme } from "../../UiProvider";

const useStyles = makeStyles((theme) => ({
  switchCircle: {
    borderRadius: "50%",
    background: theme.palette.background.paper,
    padding: "2px",
    color: theme.palette.text.primary,
    width: "inherit",
    height: "inherit",
  },
  iconSwitch: {
    width: "68px",
    height: "52px",
    "& [class^='MuiButtonBase-root']": {
      paddingTop: "12px",
    },
  },
  trackRadius: {
    borderRadius: "26px",
  },
  switchLabel: {
    paddingLeft: theme.spacing(1),
  },
}));

export function ThemeSwitcher() {
  return (
    <UserPreferenceContainer>
      <DarkModeSwitch />
    </UserPreferenceContainer>
  );
}

function DarkModeSwitch() {
  const styles = useStyles();
  const dispatch = useRequest();
  const [theme, setTheme] = useDarkTheme();
  const [switched, setSwitched] = useState(theme === ColorTheme.Dark);

  const handleChange = (event) => {
    setSwitched(event.target.checked);
  };

  useEffect(() => {
    const newTheme = switched ? ColorTheme.Dark : ColorTheme.Light;
    if (theme !== newTheme) {
      setTimeout(() => {
        setTheme(newTheme);
        dispatch(requestUserPreferenceUpdate(UserPreferencesKeys.uiColorTheme, newTheme));
      }, 50);
    }
  }, [switched]);

  return (
    <FormControlLabel
      classes={{ label: styles.switchLabel }}
      control={
        <Switch
          checked={switched}
          checkedIcon={
            <div className={clsx("MuiSwitch-thumb", styles.switchCircle)}>
              <Icon icon={mdiBrightness3} />
            </div>
          }
          classes={{ track: styles.trackRadius }}
          className={styles.iconSwitch}
          edge="end"
          icon={
            <div className={clsx("MuiSwitch-thumb", styles.switchCircle)}>
              <Icon icon={mdiBrightness7} />
            </div>
          }
          name="themeSwitcher"
          onChange={handleChange}
        />
      }
      label={theme === ColorTheme.Dark ? (t("Application.darkMode") as string) : (t("Application.lightMode") as string)}
    />
  );
}
