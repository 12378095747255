import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import {
  Fk,
  Payout,
  PayoutContainer,
  PayoutMethod,
  PayoutMethodContainer,
  WalletTransactionContainer,
} from "@sinch/entity";

export interface WalletPayoutListParams {
  from?: Date;

  payoutMethod?: PayoutMethod;

  to?: Date;
}

export const requestWalletPayoutList = defineRequest("Wallets/PayoutList")
  .withParams<WalletPayoutListParams>()
  .withMeta<PagingParams>()
  .withResponse<{ payoutIds: Fk<Payout>[] }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<PayoutContainer & PayoutMethodContainer & WalletTransactionContainer>()
  .withCreator((params: WalletPayoutListParams, paging: PagingParams) => ({
    meta: paging,
    params,
  }));
