import { SelectFieldOption } from "@sinch/types";
import { isNil, sort } from "ramda";

const countries = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  VI: "US Virgin Islands",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BE: "Belgium",
  BZ: "Belize",
  BY: "Belarus",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  VE: "Bolivarian Republic Of Venezuela",
  BQ: "Bonaire, Saint Eustatius And Saba",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  VG: "British Virgin Islands",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  TD: "Cad",
  ME: "Montenegro",
  CL: "Chile",
  HR: "Croatia",
  CN: "China",
  CK: "Cooks Islands",
  CW: "Cura\u00e7ao",
  CZ: "Czech Republic",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DJ: "Djibouti",
  EG: "Egypt",
  EC: "Ecuador",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FO: "Faroe Islands",
  FK: "Falkland Islands (Malvinas)",
  FJ: "Fiji",
  PH: "Philippines",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  TF: "French Southern Territories",
  PF: "French Polynesia",
  GA: "Gabon",
  GM: "Gambia",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Grenada",
  GL: "Greenland",
  GE: "Georgia",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island And McDonald Islands",
  HN: "Honduras",
  HK: "Hong Kong",
  IN: "India",
  ID: "Indonesia",
  IQ: "Iraq",
  IR: "Iran (Islamic Republic Of)",
  IE: "Ireland",
  IS: "Iceland",
  IT: "Italy",
  IL: "Israel",
  JM: "Jamaica",
  JP: "Japan",
  YE: "Yemen",
  JE: "Jersey",
  ZA: "South Africa",
  GS: "South Georgia And The South Sandwich Islands",
  SS: "South Sudan",
  JO: "Jordan",
  KY: "Cayman Islands",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  QA: "Qatar",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CD: "Congo, Democratic Republic",
  CG: "Congo, Republic",
  KP: "Korea, Democratic People's Republic",
  KR: "The Korean Republic",
  XK: "Kosovo",
  CR: "Costa Rica",
  CU: "Cuba",
  KW: "Kuwait",
  CY: "Cyprus",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LS: "Lesotho",
  LB: "Lebanon",
  LR: "Liberia",
  LY: "Libya",
  LI: "Lichtenstein",
  LT: "Lithuania",
  LV: "Latvia",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  HU: "Hungary",
  MK: "Macedonia, The Former Yugoslav Republic",
  MY: "Malaysia",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MA: "Morocco",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MU: "Mauritius",
  MR: "Mauritania",
  YT: "Mayotte",
  UM: "Minor Outlying Islands",
  MX: "Mexico",
  FM: "Micronesia, Federated States",
  BO: "Plurinational State Of Bolivia",
  MD: "The Republic Of Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanma",
  NA: "Namibia",
  NR: "Nauru",
  DE: "Germany",
  NP: "Nepal",
  NE: "Niger",
  NG: "Nigeria",
  NI: "Nicaragua",
  NU: "Niue",
  NL: "Netherlands",
  NO: "Norway",
  NC: "New Caledonia",
  NZ: "New Zealand",
  OM: "Oman",
  IM: "Isle Of Man",
  NF: "Norfolk Island",
  MP: "Islands Northern Mariana Islands",
  TC: "Turks And Caicos Islands",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory (Occupied)",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PN: "Pitcairn",
  CI: "Ivory Coast",
  PL: "Poland",
  PR: "Puerto Rico",
  PT: "Portugal",
  AT: "Austria",
  GR: "Greece",
  RE: "Reunion",
  GQ: "Equatorial Guinea",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  PM: "Saint Pierre And Miquelon",
  SB: "Solomon Islands",
  SV: "Salvador",
  WS: "Samoa",
  SM: "San Marino",
  SA: "Saudi Arabia",
  SN: "Senegal",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SO: "Somalia",
  ES: "Spain",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  RS: "Serbia",
  LK: "Sri Lanka",
  CF: "Central African Republic",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SH: "Saint Helena, Ascension And Tristan Da Cunha",
  LC: "Saint Lucia",
  BL: "St. Barthelemy",
  KN: "St. Kitts And Nevis",
  MF: "Saint Martin (French Part)",
  SX: "Sint Maarten (Dutch Part)",
  VA: "Holy See (Vatican City State)",
  ST: "Sao Tom\u00e9 And Pr\u00edncipe",
  VC: "St. Vincent And The Grenadines",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TJ: "Tajikistan",
  TZ: "United Republic Of Tanzania",
  TW: "Taiwan (Province Of China)",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  CX: "Christmas Island",
  VU: "Vanuatu",
  VN: "Vietnam",
  TL: "East Timor",
  WF: "Wallis And Futuna",
  ZM: "Zambia",
  EH: "Western Sahara",
  ZW: "Zimbabwe",
};

export const countryOptions = (locale: string | null, preferred?: string): SelectFieldOption[] => {
  let intlNames: unknown | null = null;
  if (!isNil(Intl.DisplayNames) && locale) {
    try {
      intlNames = new Intl.DisplayNames([locale, locale.substr(0, 2)], {
        type: "region",
      });
    } catch (err) {
      intlNames = new Intl.DisplayNames(["en"], { type: "region" });
    }
  }

  return sort<SelectFieldOption>(({ label: a, value: countryIdA }, { label: b, value: countryIdB }) => {
    if (preferred && countryIdA === preferred) {
      return -1;
    }
    if (preferred && countryIdB === preferred) {
      return 1;
    }
    return a.localeCompare(b, "en", { sensitivity: "base" });
  })(
    Object.keys(countries).map((key) => ({
      /* @ts-ignore */
      label: isNil(Intl.DisplayNames) ? countries[key] : intlNames.of(key) || countries[key],
      value: key,
    }))
  );
};
