import MuiTypography from "@material-ui/core/Typography";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { useStyles } from "./styles";
import { ColorParams, FormatParams } from "./TextParams";

type TypographyComponent = "div" | "p" | "span";

interface TypographyParams {
  component: TypographyComponent;
  className?: string;
}

type TypographyProps = TypographyParams & ColorParams & FormatParams & ChildrenProps;

const isParagraph = equals<TypographyComponent>("p");

export function Typography({
  component,
  small,
  color,
  bold,
  italic,
  underline,
  strikethrough,
  overline,
  children,
  multiline,
  className,
  ...params
}: TypographyProps): ReactElement {
  const styles = useStyles();

  const paragraph = isParagraph(component);

  return (
    <MuiTypography
      className={clsx(
        color && styles[color],
        {
          [styles.paragraph]: paragraph,
          [styles.bold]: bold,
          [styles.italic]: italic,
          [styles.underline]: underline,
          [styles.strikethrough]: strikethrough,
          [styles.overline]: overline,
          [styles.multiline]: multiline,
        },
        className
      )}
      component={component}
      // gutterBottom={paragraph}
      paragraph={paragraph}
      variant={small ? "body2" : "body1"}
      {...params}
    >
      {children}
    </MuiTypography>
  );
}
