import { mdiCheck } from "@mdi/js";
import { useData } from "@sinch/core";
import { Id, selectNews } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Box, IconText, List, PaperContainer, Text, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
/* eslint-disable-next-line import/no-internal-modules -- todo: extract shared func */
import { SearchParamsPagination } from "../../../components";
import { requestNewsList } from "../api";
import { NEWS_LIST_DEFAULT_LIMIT } from "./NewsListContainer";

export function NewsMobileList(): ReactElement {
  const { dt } = useFormat();

  const navigate = useNavigate();
  const [, , data] = useSpacing();

  const { selectEntity, selectResult } = useData(requestNewsList);
  const { newsIds } = selectResult();

  return (
    <PaperContainer>
      <List
        center
        getAction={(id: Id) => () => {
          navigate(`/communication/news/${id}`);
        }}
        getBody={(id: Id) => {
          const { confirmation, publishedDate, title } = selectEntity(selectNews(id));

          return (
            <>
              <Box display="flex" justifyContent="space-between" mb={data}>
                <Text bold>{title}</Text>
                <Text>{dt(publishedDate)}</Text>
              </Box>
              {confirmation && (
                <IconText color="success" icon={mdiCheck}>
                  {t<string>("confirmed")}
                </IconText>
              )}
            </>
          );
        }}
        items={newsIds}
      />

      <SearchParamsPagination defaultLimit={NEWS_LIST_DEFAULT_LIMIT} />
    </PaperContainer>
  );
}
