import MuiChip, { ChipProps as MuiChipProps } from "@material-ui/core/Chip";
import { fade, makeStyles } from "@material-ui/core/styles";
import { useFormat } from "@sinch/intl";
import { Color } from "@sinch/types";
import clsx from "clsx";
import React, { ReactChild, ReactElement } from "react";
import { Action, resolveAction } from "../actions";
import { createPaletteStyles } from "../paletteStyles";

/**
 * todo: extract hover helper to palette styles?
 */
const paletteStyles = createPaletteStyles(({ contrastText, main }, { theme }) => ({
  background: main,
  color: contrastText,
  "&:hover": {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    backgroundColor: fade(main, 1 - theme!.palette.action.hoverOpacity),
  },
}));

const useStyles = makeStyles((theme) => ({
  maxWidthViewport: {
    "& .MuiChip-label": {
      maxWidth: `calc(100vw - ${theme.spacing(3)}px)`,
    },
  },
  ...paletteStyles(theme),
}));

export interface ChipProps extends MuiChipProps {
  action?: Action;

  color?: Color;

  label: ReactChild;

  large?: boolean;
}

export function Chip({ action, color, label, large, className, ...chipProps }: ChipProps): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(action);

  return (
    <MuiChip
      {...chipProps}
      className={`${clsx(className, color && styles[color])} ${styles.maxWidthViewport}`}
      label={label}
      size={large ? "medium" : "small"}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...actionProps}
    />
  );
}

export function useChipDate(date: Date): string {
  const { dt } = useFormat();

  return `${dt.wday(date)} ${dt.chip(date)}`;
}

export interface DateChipProps extends Omit<ChipProps, "label"> {
  date: Date;
}

export function DateChip({ date, ...props }: DateChipProps): ReactElement {
  const label = useChipDate(date);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <Chip label={label} {...props} />;
}
