import { Box, InputLabel } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { mdiCellphoneCheck, mdiInformation } from "@mdi/js";

import {
  DataProvider,
  responseHandlerKey,
  useCascadeRefresh,
  useCurrentUser,
  useData,
  useRefresh,
  useRequest,
} from "@sinch/core";
import { Form, SubmitButton, TextInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Button, Header, Icon, IconText, InformationDialog, Text, useSnackbar, useSpacing } from "@sinch/ui";
import { differenceInMinutes } from "date-fns";
import { isEmpty, isNil, map, pluck } from "ramda";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { PhoneVerificationStatus, requestProfileStatus } from "../api";
import { requestVerifyPhone } from "../api/VerifyPhone";
import { useProfileContext } from "../ProfileContext";

const useStyles = makeStyles({
  codeInput: {
    "& [name=code]": {
      textAlign: "center",
      fontSize: "1.5rem",
    },
  },
});

export function PhoneVerification(): ReactElement | null {
  const { cascadeRefresh } = useCascadeRefresh();
  const { refresh } = useProfileContext();
  const snackbar = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { selectResult } = useData(requestProfileStatus);
  const { phoneVerificationStatus } = selectResult();
  const [codeRequested, setCodeRequested] = useState(phoneVerificationStatus !== PhoneVerificationStatus.Unverified);

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestVerifyPhone,
        ({ request }) => {
          const {
            params: { code },
          } = request;

          if (!code) {
            setCodeRequested(true);
          } else {
            snackbar("success", t("Profile.PhoneVerification.verificationSuccess"));
            navigate(pathname);
          }
          refresh();
          cascadeRefresh();
        },
        ({ request }) => {
          const {
            params: { code },
          } = request;
          if (code) {
            snackbar("error", t("Profile.PhoneVerification.verificationFailed"));
          } else {
            snackbar("error", t("Profile.PhoneVerification.verificationFailed"));
          }
        }
      ),
    [navigate, pathname, cascadeRefresh, snackbar]
  );

  if (isNil(phoneVerificationStatus)) {
    return null;
  }

  return (
    <DataProvider handler={responseHandler}>
      <VerificationDialog requested={codeRequested} />
    </DataProvider>
  );
}

export function VerificationDialog({ requested }: { requested: boolean }) {
  return (
    <InformationDialog width="xs">
      <Form<{ code: string }>
        initialValues={{ code: "" }}
        submitRequest={({ code: verificationCode }) => requestVerifyPhone(verificationCode)}
      >
        <VerificationFormContent requested={requested} />
      </Form>
    </InformationDialog>
  );
}

function VerificationFormContent({ requested }: { requested: boolean }) {
  const { refreshToken } = useRefresh({ interval: 60 * 1000 });
  const dispatch = useRequest();
  const { phone } = useCurrentUser();
  const [, inner] = useSpacing();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const styles = useStyles();
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const { selectResult } = useData(requestProfileStatus);
  const { phoneVerificationTimeout } = selectResult();
  const [nextSendIn, setNestSendIn] = useState(differenceInMinutes(phoneVerificationTimeout, new Date()));

  useEffect(() => {
    setNestSendIn(differenceInMinutes(phoneVerificationTimeout, new Date()));
  }, [refreshToken, phoneVerificationTimeout]);

  return (
    <Box textAlign="center">
      <MuiGrid alignContent="stretch" alignItems="stretch" container direction="column" spacing={inner}>
        <MuiGrid item>
          <Box display="flex" justifyContent="center">
            <Icon color="success" icon={mdiCellphoneCheck} size="large" />
          </Box>
        </MuiGrid>
        <MuiGrid item>
          <Header color="success" level={2}>
            {t("Profile.PhoneVerification.verifyYourPhone")}
          </Header>
        </MuiGrid>
        {!requested && (
          <>
            <MuiGrid item>{phone ?? ""}</MuiGrid>
            <MuiGrid item>
              <Button
                action={async () => {
                  const response = await dispatch(requestVerifyPhone());
                  if (!isEmpty(response.messages)) {
                    setErrorMsg(pluck("text", response.messages));
                  }
                }}
                color="info"
                variant="contained"
              >
                {t("Profile.PhoneVerification.requestVerificationCodeButton")}
              </Button>
              {errorMsg &&
                map(
                  (msg) => (
                    <Box mt={2}>
                      <Text color="error">{msg}</Text>
                    </Box>
                  ),
                  errorMsg
                )}
            </MuiGrid>
          </>
        )}
        {requested && (
          <>
            <MuiGrid className={styles.codeInput} item>
              <InputLabel color="secondary">{t("Profile.PhoneVerification.enterVerificationCodeLabel")}</InputLabel>
              <TextInput maxLength={4} minLength={4} name="code" />
              {nextSendIn > 0 && (
                <Box textAlign="left">
                  <IconText color="info" icon={mdiInformation}>
                    {t<string>("Profile.PhoneVerification.anotherInTimeLimit", { timeout: nextSendIn })}
                  </IconText>
                </Box>
              )}
            </MuiGrid>
            <MuiGrid item>
              <SubmitButton label={t("Profile.PhoneVerification.verify")} />
            </MuiGrid>
          </>
        )}
        <MuiGrid item>
          <Button action={() => navigate(pathname)} color="primary" variant="text">
            {t("action.cancel")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
}
