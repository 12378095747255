import { DataProvider, PagingParams, ScrollHistory, useSearchParamsCustom } from "@sinch/core";
import { LoadingOverlay } from "@sinch/ui";
import { isValid, parseISO } from "date-fns";
import React, { ReactElement, useMemo } from "react";
import { useWalletContext } from "../Wallet";
import { ReimbursementListParams, requestReimbursementList } from "./api";

export type ReimbursementListContainerParams = {
  children: ReactElement;
};

export function ReimbursementListContainer({ children }: ReimbursementListContainerParams): ReactElement {
  const { refresh, refreshToken } = useWalletContext();
  const { searchParams } = useSearchParamsCustom<ReimbursementListParams & PagingParams>();

  if (searchParams.to && !isValid(parseISO(searchParams.to))) {
    searchParams.to = null;
  }

  if (searchParams.from && !isValid(parseISO(searchParams.from))) {
    searchParams.from = null;
  }

  const request = useMemo(() => requestReimbursementList(searchParams, PagingParams.pick(searchParams)), [
    searchParams,
  ]);

  return (
    <DataProvider progress={<LoadingOverlay />} refresh={refreshToken} refresher={refresh} request={request}>
      <ScrollHistory>{children}</ScrollHistory>
    </DataProvider>
  );
}
