import { makeStyles } from "@material-ui/core/styles";
import React, { Children, ReactElement, ReactNode } from "react";

const useStyles = makeStyles({
  bar: {
    marginTop: "8px",
    borderTop: "1px dashed rgba(0, 0, 0, 0.1)",
    padding: "8px",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    fontSize: "smaller",
    color: "darkblue",
  },
  slot: {
    "& > :not(:first-child)": {
      marginLeft: "4px",
    },
  },
});

export interface DevBarProps {
  children: ReactNode;
}

export function DevBar({ children }: DevBarProps): ReactElement {
  const styles = useStyles();

  /* eslint-disable react/no-array-index-key */
  return (
    <aside className={styles.bar}>
      {Children.toArray(children).map((child, i) => (
        <div key={i} className={styles.slot}>
          {child}
        </div>
      ))}
    </aside>
  );
}
