import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import { useScrollHistory } from "../context";

export function ScrollHistory({ children }: ChildrenProps): ReactElement {
  const { key, pathname } = useLocation(); // get unique key of history
  const { scrollHistory, setScroll } = useScrollHistory();
  const hashKey = `${pathname}~${key}`;

  useLayoutEffect(
    () => () => {
      setScroll(hashKey, window.scrollY); // set scroll position for history item
    },
    []
  );

  useEffect(() => {
    // restore scroll position of history item
    if (scrollHistory[hashKey]) {
      window.scrollTo(0, scrollHistory[hashKey]);
    }
  }, [scrollHistory, key, pathname]);

  return <>{children}</>;
}
