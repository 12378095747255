import React, { ReactElement } from "react";
import { ToolbarPosition, useToolbarPortal } from "./ToolbarProvider";

interface ToolbarPortalProps {
  position: ToolbarPosition;
}

export function ToolbarPortal({ position }: ToolbarPortalProps): ReactElement {
  const ref = useToolbarPortal(position, true);
  return <slot ref={ref} id={`toolbar-portal-${position}`} />;
}
