import MuiGrid from "@material-ui/core/Grid";
import { mdiAccountCash, mdiBankTransfer, mdiCurrencyUsd } from "@mdi/js";

import { useBusinessRules } from "@sinch/core";
import { t } from "@sinch/intl";
import { ButtonCard, routerLink } from "@sinch/ui";
import React, { ReactElement } from "react";

// todo disable particular button on empty
/* disabled={isEmpty(transactions)} */

/* disabled={isEmpty(payouts)} */

export function MobileWalletPaymentList(): ReactElement {
  const { allowWorkerReimbursementRequest } = useBusinessRules();
  return (
    <MuiGrid container direction="column" spacing={1}>
      <MuiGrid item>
        <ButtonCard action={routerLink("/wallet/transaction")} icon={mdiBankTransfer}>
          {t("Wallet.display.transactions")}
        </ButtonCard>
      </MuiGrid>
      {allowWorkerReimbursementRequest && (
        <MuiGrid item>
          <ButtonCard action={routerLink("/wallet/reimbursements")} icon={mdiAccountCash}>
            {t("Reimbursement.titlePlural")}
          </ButtonCard>
        </MuiGrid>
      )}
      <MuiGrid item>
        <ButtonCard action={routerLink("/wallet/payout")} icon={mdiCurrencyUsd}>
          {t("Wallet.display.payoutHistory")}
        </ButtonCard>
      </MuiGrid>
    </MuiGrid>
  );
}
