import { Nullable } from "@sinch/types";
import { asInt } from "@sinch/utils";
import { mergeRight } from "ramda";
import { isFunction } from "ramda-adjunct";
import { useCallback, useMemo } from "react";
import {
  URLSearchParamsInit,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom";
import { parseSearchParams, SearchParamsState, SearchParamsUpdate } from "./searchParams";

export function useHashNavigate(): (hash: string) => void {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useCallback(
    (hash: string) =>
      navigate({
        pathname,
        hash: `#${hash}`,
      }),
    [navigate, pathname]
  );
}

/**
 * Provides access to URL parameters automatically parsed to integer values.
 */
export function useIntParams(): Record<string, number> {
  const params: Record<string, string> = useParams();

  const handler: ProxyHandler<Record<string, number>> = {
    get(target: Record<string, number>, prop: string): number {
      return asInt(params[prop]);
    },
  };

  return new Proxy({}, handler);
}

/**
 * Return path segment matched to current Route path.
 * (eg. "info" when pathname "/dashboard/info" in Route path="dashboard")
 */
export function usePathSection(): Nullable<string> {
  const { pathname: basePath } = useResolvedPath(".");
  const match = useMatch(`${basePath}/:section`);

  return match?.params?.section ?? null;
}

/**
 * todo: support configurable parser to enable reading any data from url
 *
 * todo: support configurable serializer to enable writing any data to url
 *  (unsafe cast to URLSearchParamsInit can be removed then)
 *
 * todo: rename to useSearchParamsState? or just useSearchState?
 *
 * todo: update other exported functions to support generics
 *  (parseSearchParams<TParams>(...))
 */
export function useSearchParamsCustom<
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  TParams extends object
>(defaultParams?: TParams): SearchParamsState<TParams> {
  const { pathname } = useLocation();
  // @ts-ignore
  const [searchParamsRouter, setSearchParamsRouter] = useSearchParams(defaultParams);
  const searchParams = useMemo(() => parseSearchParams.asBase(searchParamsRouter) as TParams, [
    searchParamsRouter,
    pathname,
  ]);

  function updateSearchParams(update: SearchParamsUpdate<TParams>): void {
    const nextParams = isFunction(update) ? update(searchParams) : (mergeRight(searchParams, update) as TParams);

    setSearchParamsRouter(nextParams as URLSearchParamsInit);
  }

  return {
    searchParams,
    updateSearchParams,
  };
}
