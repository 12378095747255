import {
  mdiAccountStar,
  mdiBriefcase,
  mdiBriefcaseOutline,
  mdiCalendar,
  mdiCheckAll,
  mdiCurrencyUsd,
  mdiEmail,
  mdiHistory,
  mdiHome,
  mdiMessageProcessing,
} from "@mdi/js";
import { IndicatorView, MenuRouteItem, useBusinessRules } from "@sinch/core";
import { useMobileLayout } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { useTranslation } from "react-i18next";

export const staticMenu = (): MenuRouteItem[] => {
  const { t } = useTranslation();
  const { hideFinances } = useBusinessRules();
  const mobile = useMobileLayout();
  return rejectFalsy([
    mobile && {
      icon: mdiHome,
      id: "Dashboard",
      name: t("Dashboard.title"),
      path: "/dashboard/overview",
    },
    {
      icon: mdiBriefcaseOutline,
      id: "MyAgenda",
      name: t("Dashboard.myAgenda"),
      path: "/dashboard/incoming",
      indicator: IndicatorView.Any,
    },
    {
      icon: mdiCheckAll,
      id: "Finished",
      name: t("Dashboard.finished"),
      path: "/dashboard/finished",
      indicator: IndicatorView.Any,
    },
    {
      icon: mdiAccountStar,
      id: "Feedback",
      name: t("Feedback.titlePlural"),
      path: "/dashboard/feedback",
      indicator: IndicatorView.Any,
    },
    {
      icon: mdiBriefcase,
      id: "Position",
      name: t("Position.display.availablePositions"),
      path: "/position",
    },
    !hideFinances && {
      icon: mdiCurrencyUsd,
      id: "Wallet",
      name: t("Wallet.title"),
      path: "/wallet",
      indicator: IndicatorView.Mobile,
    },
    {
      icon: mdiCalendar,
      id: "Calendar",
      name: t("Calendar.title"),
      path: mobile ? "/dashboard/calendar" : "/calendar",
    },
    {
      icon: mdiEmail,
      id: "News",
      name: t("Communication.title"),
      path: "/communication",
      indicator: IndicatorView.Any,
    },
    {
      icon: mdiMessageProcessing,
      id: "Appointment",
      name: t("Appointment.titlePlural"),
      path: "/appointment",
    },
    {
      icon: mdiHistory,
      id: "History",
      name: t("Shift.history"),
      path: "/history",
    },
  ]);
};
