import { DataProvider } from "@sinch/core";
import { t } from "@sinch/intl";
import { InformationDialog } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestWalletView } from "../api";
import { WalletView } from "./WalletView";

export interface PayoutDetailParams {
  /**
   * Id param from router
   */
  id: string;
}
export function WalletDetail({ id: walletId }: PayoutDetailParams): ReactElement | null {
  const id = parseInt(walletId, 10);
  const request = useMemo(() => requestWalletView(id), [id]);

  return (
    <DataProvider request={request}>
      <InformationDialog title={t("Wallet.wageDetail")} width="xs">
        <WalletView />
      </InformationDialog>
    </DataProvider>
  );
}
