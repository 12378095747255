import { Grid as MuiGrid } from "@material-ui/core";
import { useCurrentUser, useData } from "@sinch/core";
import { Fk, Position, selectAppointment, selectAppointmentAttendance } from "@sinch/entity";
import { Format, useFormat } from "@sinch/intl";
import { TimeRange, useSpacing } from "@sinch/ui";
import { head } from "ramda";
import React, { ReactElement } from "react";

export function AppointmentTime({ id }: { id: Fk<Position> }): ReactElement {
  const [, inner] = useSpacing();
  const { dt } = useFormat();
  // @ts-expect-error
  const { selectEntity } = useData();

  const { id: workerId } = useCurrentUser();
  const appointmentAttendance = selectEntity(selectAppointmentAttendance({ appointment: id, worker: workerId }));
  const appointmentItem = selectEntity(selectAppointment(id));
  const { startTime, endTime } = head(appointmentAttendance) || appointmentItem;

  return (
    <MuiGrid container spacing={inner}>
      <MuiGrid item xs="auto">
        <span>
          {dt.wday(startTime)} {dt(startTime)}
        </span>
      </MuiGrid>
      <MuiGrid item xs>
        {endTime ? (
          <TimeRange endTime={endTime} showLength startTime={startTime} />
        ) : (
          <Format dt={startTime} variant="time" />
        )}
      </MuiGrid>
    </MuiGrid>
  );
}
