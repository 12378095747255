import React, { ReactElement } from "react";
import { DataTableCellProps, DataTableDisplay } from "./types";

export function DataTableCell<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>({
  table,
  row,
  column,
}: DataTableCellProps<TData, TColumn, TDisplay>): ReactElement {
  const { data, config, display } = table;
  const { Cell } = display;

  const { selector } = config[column];
  const current = data[row];

  const value = selector(current);

  return <Cell>{value}</Cell>;
}
