import { DataProvider, ParamsOf, useData } from "@sinch/core";
import { PayoutComponent } from "@sinch/entity";
import { useFormValues } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Transform } from "@sinch/types";
import { ExpandableArea, MuiDataTable, Text } from "@sinch/ui";
import { debounce } from "debounce";
import { append, pipe, prepend } from "ramda";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { PayoutParams, requestWalletPayoutCalculation } from "./api";

type CalculationColumn = "component" | "value";

interface CalculationTableStaticProps {
  components: PayoutComponent[];

  grossAmount: number;

  netAmount: number;
}

/**
 * todo: consider reading gross+net amount from payout components as well,
 *  that way we can support displaying super-gross without additional logic
 */
export function CalculationTableStatic({
  components,
  grossAmount,
  netAmount,
}: CalculationTableStaticProps): ReactElement {
  const { curr } = useFormat();

  const CalculationDataTable = MuiDataTable.withConfig<PayoutComponent, CalculationColumn>({
    component: {
      selector: ({ name }) => <Text>{name}</Text>,
    },
    value: {
      selector: ({ amount }) => <Text>{curr(amount)}</Text>,
    },
  });

  const withFixed: Transform<PayoutComponent[]> = pipe(
    prepend({
      name: t("Wallet.display.grossAmount"),
      amount: grossAmount,
    }),
    append({
      name: t("Wallet.display.netAmount"),
      amount: netAmount,
    })
  );

  return (
    <ExpandableArea title={t("PayoutRequest.calculationDetail")}>
      <CalculationDataTable columns={["component", "value"]} data={withFixed(components)} />
    </ExpandableArea>
  );
}

function ResponseCalculationTable(): ReactElement {
  const { selectResult } = useData(requestWalletPayoutCalculation);
  const { components, grossAmount, netAmount } = selectResult();

  return <CalculationTableStatic components={components} grossAmount={grossAmount} netAmount={netAmount} />;
}

export function CalculationTableContainer(): ReactElement {
  const { grossAmount, method } = useFormValues<PayoutParams>();

  const [reqRef, setReqRef] = useState(requestWalletPayoutCalculation(method, grossAmount));

  const makeRequest = useCallback((params: ParamsOf<typeof requestWalletPayoutCalculation>) => {
    setReqRef(requestWalletPayoutCalculation(params.method, params.grossAmount));
  }, []);

  const refresh = useMemo(() => debounce(makeRequest, 400, false), [makeRequest]);

  useEffect(() => refresh({ grossAmount, method }), [grossAmount, method, refresh]);

  return (
    <DataProvider request={reqRef}>
      <ResponseCalculationTable />
    </DataProvider>
  );
}
