import { Box, Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import {
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectProfession,
  selectWorker,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Avatar, Card, Header, Identifier, PhoneLink, useSpacing } from "@sinch/ui";
import { intervalToDuration } from "date-fns";
import React, { ReactElement } from "react";
import { PositionReference } from "../../../Position/Detail/PositionReference";
import { requestPresenceView } from "../api";

interface PresenceDetailProps {
  id: Fk<PositionAttendance>;
}

export function PresenceWorkerDetailInfo({ id }: PresenceDetailProps): ReactElement {
  const { dt, num } = useFormat();
  const { selectEntity } = useData(requestPresenceView);
  const [, inner] = useSpacing();

  const { worker, position } = selectEntity(selectPositionAttendance(id));
  const { avatar, phone, name } = selectEntity(selectWorker(worker));
  const { profession, startTime, endTime } = selectEntity(selectPosition(position));
  const { name: professionName } = selectEntity(selectProfession(profession));

  const duration = startTime && endTime && intervalToDuration({ start: startTime, end: endTime });
  // @ts-ignore
  const hours = duration && num(duration.hours + duration.minutes / 60);

  return (
    <>
      <Card>
        <Box textAlign="center">
          <MuiGrid alignContent="center" alignItems="center" container direction="column" spacing={0}>
            <MuiGrid item>
              <Box py={inner}>
                <Avatar
                  file={{ file: avatar as string, variant: "thumbnail_200x200" }}
                  size="xl"
                  withPreview="thumbnail_500x500"
                />
              </Box>
            </MuiGrid>
            <MuiGrid item>
              <Header level={2}>{name}</Header>
            </MuiGrid>
            <MuiGrid item>
              <PositionReference id={position} name noId title /> (<Identifier entity="position" id={position} />)
            </MuiGrid>
            <MuiGrid item>{professionName}</MuiGrid>
            <MuiGrid item>
              {dt.time(startTime)} - {dt.time(endTime)} ({t("hoursCount", { hours })})
            </MuiGrid>
            <MuiGrid item>
              <PhoneLink phone={phone}>{t("phoneAbbr", { phone }) as string}</PhoneLink>
            </MuiGrid>
          </MuiGrid>
        </Box>
      </Card>
    </>
  );
}
