import { Box, Card, Collapse, lighten } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useData } from "@sinch/core";
import {
  ClosingProgress,
  Fk,
  Position,
  selectPosition,
  selectPositionAttendance,
  Shift,
  WorkerRole,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Button, RowsGrid, Text, useSpacing } from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import clsx from "clsx";
import { isBefore } from "date-fns";
import {
  filter,
  groupBy,
  head,
  includes,
  last,
  length,
  map,
  pipe,
  pluck,
  prop,
  propEq,
  reduce,
  reject,
  sortBy,
  sum,
  values,
} from "ramda";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../api";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.palette.success.light, 0.8),
    color: theme.palette.getContrastText(lighten(theme.palette.success.light, 0.8)),
    padding: theme.spacing(1),
  },
  success: {
    backgroundColor: lighten(theme.palette.success.light, 0.8),
    color: theme.palette.getContrastText(lighten(theme.palette.success.light, 0.8)),
  },
  error: {
    backgroundColor: lighten(theme.palette.error.light, 0.8),
    color: theme.palette.getContrastText(lighten(theme.palette.error.light, 0.8)),
  },
  warning: {
    backgroundColor: lighten(theme.palette.warning.light, 0.8),
    color: theme.palette.getContrastText(lighten(theme.palette.warning.light, 0.8)),
  },
  sticky: {
    position: "sticky",
    top: theme.mixins.component.titleBar.large.height - theme.spacing(1),
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1),
  },
}));

function getColor(attendanceCount, positionsCapacities) {
  // eslint-disable-next-line default-case
  switch (true) {
    case attendanceCount === positionsCapacities:
      return "success";
    case attendanceCount < positionsCapacities:
      return "error";
    case attendanceCount > positionsCapacities:
      return "warning";
  }
}

const getAttendanceCount = (positions: Position[]) =>
  pipe(
    filter(({ position }) => includes(position, pluck("id", reject(propEq("role", WorkerRole.Backup), positions)))),
    reject(({ progressStatus }) => includes(progressStatus, [ClosingProgress.Pending, ClosingProgress.Absent])),
    length
  );

const getBackupCount = (positions: Position[]) =>
  pipe(
    filter(({ position }) => includes(position, pluck("id", filter(propEq("role", WorkerRole.Backup), positions)))),
    filter(propEq("progressStatus", ClosingProgress.Present)),
    length
  );

const getPositionsCapacity = pipe(reject(propEq("role", WorkerRole.Backup)), pluck("totalCapacity"), sum);

export function TeamSizeOverview({ shift }: { shift: Fk<Shift> }): ReactElement {
  const styles = useStyles();
  const [, , data] = useSpacing();
  const { selectEntity } = useData(requestPresenceView);
  const positions = selectEntity(selectPosition({ shift }));
  const attendances = selectEntity(selectPositionAttendance({}));
  const [value, toggleValue] = useToggleState(false);

  const cards = pipe(
    groupBy(({ startTime }) => startTime.getTime()),
    map((positions) => {
      const startTime = prop("startTime", head(positions));
      const attendanceCount = getAttendanceCount(positions)(attendances);
      const backupCount = getBackupCount(positions)(attendances);
      const positionsCapacities = getPositionsCapacity(positions);
      return { startTime, attendanceCount, backupCount, positionsCapacities };
    }),
    values,
    sortBy(prop("startTime"))
  )(positions);

  const splitedCards = reduce(
    (acc, position) => {
      const { before, now, after } = acc;

      if (length(now) < 2) {
        now.push(position);
      } else if (isBefore(last(now).startTime, new Date())) {
        const last = now.shift();
        before.push(last);
        now.push(position);
      } else {
        after.push(position);
      }

      return { before, now, after };
    },
    { before: [], now: [], after: [] }
  )(cards);

  const hasBefore = length(splitedCards.before) > 0;
  const hasAfter = length(splitedCards.after) > 0;

  return (
    <Box className={styles.sticky}>
      {hasBefore && (
        <Collapse in={value}>
          <Box pb={data}>
            <TeamSizeCardList cards={splitedCards.before} />
          </Box>
        </Collapse>
      )}
      <TeamSizeCardList cards={splitedCards.now} />
      {hasAfter && (
        <Collapse in={value}>
          <Box pt={data}>
            <TeamSizeCardList cards={splitedCards.after} />
          </Box>
        </Collapse>
      )}
      {(hasBefore || hasAfter) && (
        <Box pt={data}>
          <Button action={toggleValue} size="small" variant="outlined">
            {value ? t("Shift.closing.showNearest") : t("Shift.closing.showAll")}
          </Button>
        </Box>
      )}
    </Box>
  );
}

function TeamSizeCardList({ cards }) {
  const styles = useStyles();
  const [, , data] = useSpacing();

  const { dt } = useFormat();
  return (
    <RowsGrid spacing={data}>
      {cards.map(({ startTime, attendanceCount, backupCount, positionsCapacities }) => {
        const color = getColor(attendanceCount, positionsCapacities);
        return (
          <Card classes={{ root: clsx(styles.root, styles[color]) }}>
            <MuiGrid container spacing={data}>
              <MuiGrid item xs="auto">
                <Text bold>{dt.time(startTime)}</Text>
              </MuiGrid>
              <MuiGrid item xs>
                <MuiGrid container direction="column">
                  <MuiGrid item>
                    <MuiGrid container spacing={data}>
                      <MuiGrid item xs>
                        <Text bold color={color}>
                          {t("Shift.closing.clockedIn")}
                        </Text>
                      </MuiGrid>
                      <MuiGrid item xs="auto">
                        <Text bold color={color}>
                          {attendanceCount} / {positionsCapacities}
                        </Text>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                  {backupCount > 0 && (
                    <MuiGrid item>
                      <MuiGrid container spacing={data}>
                        <MuiGrid item xs>
                          {t("Shift.closing.sortOutStandbys")}
                        </MuiGrid>
                        <MuiGrid item xs="auto">
                          <Text bold>{backupCount}</Text>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  )}
                  {attendanceCount > positionsCapacities && <MuiGrid item>{t("Shift.closing.moreThanNeeded")}</MuiGrid>}
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </Card>
        );
      })}
    </RowsGrid>
  );
}
