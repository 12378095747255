import { Grid as MuiGrid } from "@material-ui/core";
import { mdiEyeSettings, mdiFormatListBulletedSquare, mdiViewAgendaOutline } from "@mdi/js";

/* eslint-disable react/jsx-props-no-spreading */
import {
  PagingResult,
  requestUserPreferenceUpdate,
  useData,
  useRequest,
  UserPreferenceContainer,
  UserPreferencesKeys,
  useUserPreferences,
} from "@sinch/core";
import { t } from "@sinch/intl";
import { MenuMultiSelect, MenuSelect, TourProvider } from "@sinch/ui";
import { useLocalStorage } from "@sinch/utils";
import { ensureArray } from "ramda-adjunct";
import React, { ReactElement, ReactNode, useEffect } from "react";

export enum PositionView {
  Standard,
  Condensed,
}

interface PositionListViewSelectHeaderProps<TFilterField extends string> {
  filterFields?: Record<TFilterField, string>;
  onFieldsFilterChange?: (fields: TFilterField[]) => void;
  onViewChange: (view: PositionView) => void;
  count?: number;
  fieldsKey?: string;
  countLabel?: (count: number) => ReactNode;
  additionalFields?: ReactNode | ReactNode[];
}

export function PositionListViewSelectHeader<TFilterField extends string = "">(
  props: PositionListViewSelectHeaderProps<TFilterField>
): ReactElement {
  return (
    <UserPreferenceContainer>
      <PositionListViewSelectContainer {...props} />
    </UserPreferenceContainer>
  );
}

export function PositionListViewSelectContainer<TFilterField extends string = "">(
  props: PositionListViewSelectHeaderProps<TFilterField>
): ReactElement {
  const dispatch = useRequest();
  const preferences = useUserPreferences();
  return (
    <TourProvider
      enabled={preferences.UI_tourNewCompactView as boolean}
      onFinish={() => dispatch(requestUserPreferenceUpdate(UserPreferencesKeys.uiTourNewCompactView, 0))}
    >
      <PositionListViewSelectHeaderContent {...props} />
    </TourProvider>
  );
}

function PositionListViewSelectHeaderContent<TFilterField extends string = "">({
  filterFields,
  onViewChange,
  onFieldsFilterChange,
  count,
  fieldsKey,
  countLabel,
  additionalFields,
}: PositionListViewSelectHeaderProps<TFilterField>): ReactElement {
  const [view, setView] = useLocalStorage<PositionView>("listView", PositionView.Standard);
  const [fields, setFields] = useLocalStorage<TFilterField[]>(`listViewFields${fieldsKey || ""}`, []);
  /* @ts-expect-error */
  const { selectMeta } = useData<{ meta: PagingResult }>();
  const { count: paginatorCount } = selectMeta();

  useEffect(() => {
    if (onFieldsFilterChange) {
      onFieldsFilterChange(fields);
    }
  }, []);

  const handleChangeView = (newView: PositionView) => {
    setView(newView);
  };

  const handleFieldsFilterChange = (newFields: TFilterField[]) => {
    if (onFieldsFilterChange) {
      onFieldsFilterChange(newFields);
      setFields(newFields);
    }
  };

  useEffect(() => {
    onViewChange(view);
  }, [view]);

  return (
    <MuiGrid alignItems="center" container justify="flex-end">
      <MuiGrid item xs>
        {countLabel
          ? countLabel(count || paginatorCount || 0)
          : t("Position.display.positionCount", { count: count || paginatorCount || 0 })}
      </MuiGrid>
      {additionalFields &&
        ensureArray(additionalFields).map((additional, ix) => (
          <MuiGrid key={ix} item xs="auto">
            {additional}
          </MuiGrid>
        ))}
      {filterFields && view === PositionView.Condensed && (
        <MuiGrid item xs="auto">
          <MenuMultiSelect<TFilterField>
            defaultValue={fields}
            icon={mdiEyeSettings}
            onChange={handleFieldsFilterChange}
            // @ts-expect-error
            options={Object.entries(filterFields).map(([value, text]) => ({ value, text }))}
            SelectDisplayProps={{ "data-cy": "eyeIcon" }}
          />
        </MuiGrid>
      )}
      <MuiGrid item xs="auto">
        <MenuSelect<PositionView>
          defaultValue={view}
          onChange={handleChangeView}
          options={[
            { value: PositionView.Standard, text: t("Position.display.standard"), icon: mdiViewAgendaOutline },
            {
              value: PositionView.Condensed,
              text: t("Position.display.condensed"),
              icon: mdiFormatListBulletedSquare,
              "data-cy": "compactViewOption",
            },
          ]}
          renderValueText={false}
          SelectDisplayProps={{ "data-cy": "listBoxIcon" }}
        />
      </MuiGrid>
    </MuiGrid>
  );
}
