import { Box, Divider, Grid as MuiGrid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useData } from "@sinch/core";
import {
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  Shift,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Card, ToggleButtonProps, ToggleButtonSwitches, useSpacing } from "@sinch/ui";
import {
  countBy,
  difference,
  groupBy,
  head,
  includes,
  intersection,
  isEmpty,
  join,
  map,
  mergeRight,
  pipe,
  pluck,
  toLower,
  union,
  uniq,
  values,
} from "ramda";
import React, { ReactElement, useEffect, useState } from "react";
import { requestPresenceView } from "../api";
import { FilterInput, PositionHeader } from "../CardList";
import { TeamSizeOverview, usePresenceToggleButtons } from "../components";
import { useSelectionSet } from "../context";
import { useAttendanceGrouping, useClosingStatusProps } from "../hooks";
import { PresenceAttendanceItem } from "./PresenceAttendanceItem";
import { PresenceMultiselect } from "./PresenceMultiselect";

const useStyles = makeStyles((theme) => ({
  divider: {
    // backgroundClip: "padding-box",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  sticky: {
    position: "sticky",
    top: theme.mixins.component.titleBar.large.height - theme.spacing(1),
    zIndex: theme.zIndex.drawer,
    paddingTop: theme.spacing(1),
  },
}));

interface WorkTabProps {
  shift: Fk<Shift>;
}

export function PresenceTableList({ shift }: WorkTabProps): ReactElement {
  const styles = useStyles();
  const [, , data] = useSpacing();
  const { getLabel } = useClosingStatusProps();
  const { selectEntity } = useData(requestPresenceView);
  const [filter, setFilter] = useState<ClosingProgress>(ClosingProgress.Pending);
  const [search, setSearch] = useState<string>("");

  const positions = selectEntity(selectPosition({ shift }, "id"));
  const positionAttendanceEntities = selectEntity(selectPositionAttendance({})).filter(({ position }) =>
    includes(position, positions)
  );

  const filteredAttendance = positionAttendanceEntities.filter(({ progressStatus, worker }) => {
    const { name, phone } = selectEntity(selectWorker(worker));
    return (
      (progressStatus === ClosingProgress.AfterBreak ? ClosingProgress.Present : progressStatus) === filter &&
      (includes(toLower(search || ""), toLower(name)) || includes(toLower(search || ""), toLower(phone ?? "")))
    );
  });
  const attendanceIds = pluck("id", filteredAttendance);
  const { selected, setSelected, toggleSelected } = useSelectionSet<Fk<PositionAttendance>>(attendanceIds);

  const { attendancesByRole } = useAttendanceGrouping(filteredAttendance);

  const statusCounts = pipe(
    groupBy(({ progressStatus }: PositionAttendance) =>
      progressStatus === ClosingProgress.AfterBreak ? ClosingProgress.Present : progressStatus
    ),
    map(
      pipe(
        countBy(({ position }) => {
          const role = selectEntity(selectPosition(position, "role"));
          return role === WorkerRole.Backup;
        }),
        mergeRight({ false: 0 }),
        values,
        join("+")
      )
    )
  )(positionAttendanceEntities);

  const items: ToggleButtonProps<ClosingProgress>[] = usePresenceToggleButtons({ statusCounts });

  const handleSelectItem = (id: Fk<PositionAttendance>) => {
    toggleSelected(id);
  };

  const handleFilter = (item: ClosingProgress) => {
    setSelected([]);
    setFilter(item);
  };

  const selectedLabel = getLabel(filter);

  return (
    <>
      <MuiGrid container direction="column" spacing={data}>
        <MuiGrid item>
          <Box display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column" textAlign="center" width="fit-content">
              <ToggleButtonSwitches<ClosingProgress> items={items} onSelect={handleFilter} value={filter} />
              <FilterInput onChange={setSearch} value={search} />
            </Box>
          </Box>
        </MuiGrid>

        <MuiGrid className={styles.sticky} item>
          <TeamSizeOverview shift={shift} />
        </MuiGrid>

        <MuiGrid item>
          <Card gutters="none" guttersVertical="small">
            {attendancesByRole.map((attendanceList, i) => {
              const attendances = pluck<"id", PositionAttendance>("id", attendanceList);
              const position = head(pluck<"position", PositionAttendance>("position", attendanceList));
              return (
                // eslint-disable-next-line react/no-array-index-key
                <MuiGrid key={i} item>
                  <MuiGrid container direction="column">
                    <MuiGrid item>
                      <PositionHeader
                        count={attendances.length}
                        onSelectAll={(checked: boolean) => {
                          if (checked) {
                            setSelected(
                              uniq(intersection([...selected, ...attendances], pluck("id", filteredAttendance)))
                            );
                          } else {
                            setSelected(difference(selected, attendances));
                          }
                        }}
                        // @ts-expect-error
                        position={position}
                        selected={intersection(attendances, selected).length === attendances.length}
                      />
                      <Divider variant="fullWidth" />
                    </MuiGrid>
                    <MuiGrid item>
                      <AttendanceRows
                        handleSelectItem={handleSelectItem}
                        positionAttendances={attendanceList}
                        selected={selected}
                      />
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              );
            })}
          </Card>
        </MuiGrid>
        {isEmpty(attendancesByRole) && (
          <MuiGrid item xs>
            <Box py={5} textAlign="center">
              {t("Shift.closing.noWorkersInState", { state: selectedLabel })}
            </Box>
          </MuiGrid>
        )}
      </MuiGrid>
      {!isEmpty(selected) && <PresenceMultiselect closingStatus={filter} />}
    </>
  );
}

function AttendanceRows({ positionAttendances, handleSelectItem, selected }) {
  const { selectEntity } = useData(requestPresenceView);
  const [hidden, setHidden] = useState<Fk<PositionAttendance>[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [, , data] = useSpacing();
  const styles = useStyles();

  useEffect(() => {
    if (!submitting) {
      setHidden([]);
    }
  }, [selectEntity]);

  return (
    <MuiGrid container direction="column" spacing={data}>
      {positionAttendances.map(({ id }) => (
        <MuiGrid
          key={id}
          className={styles.divider}
          item
          style={{ display: includes(id, hidden) ? "none" : undefined }}
        >
          <PresenceAttendanceItem
            attendance={id}
            onProcess={(toHide, inProcess) => {
              if (inProcess) {
                setHidden((item) => union(item, [toHide]));
              }
              setSubmitting(inProcess);
            }}
            onSelect={handleSelectItem}
            selected={includes(id, selected)}
          />
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
