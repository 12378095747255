import { ListItem as MuiListItem } from "@material-ui/core";
import { mdiBookOpenOutline } from "@mdi/js";

import {
  Indicator,
  IndicatorView,
  isPageItem,
  isRouteItem,
  isSectionItem,
  MenuNavigationItem,
  useStatus,
} from "@sinch/core";
import { find, propEq } from "ramda";
import React, { ReactElement, useCallback } from "react";
import { useLocation, useResolvedPath } from "react-router-dom";
import { externalLink, resolveAction, routerLink } from "../../actions";
import { Badge } from "../../Badge";
import { Chip } from "../../Chip";
import { ConditionalWrapper } from "../../Utils";
import { useMenuContext } from "./context";
import { MenuIcon } from "./MenuIcon";
import { useListItemStyle } from "./style";
import { Title } from "./Title";
import { Tooltip } from "./Tooltip";

interface NavigationItemProps {
  item: MenuNavigationItem;

  nested: boolean;
}

/**
 * check if current path is matched with page path
 * @param path
 * @param item
 */
const checkMatch = (path: string, item: MenuNavigationItem): boolean =>
  isPageItem(item) && (path.endsWith(`/page/${item.page}`) || path.endsWith(`/page/${item.page}/`));

/**
 * Check if some of ancestors item is selected
 * @param path
 * @param item
 */
const hasSelectedSection = (path: string, item: MenuNavigationItem): boolean => {
  if (!isSectionItem(item)) {
    return false;
  }
  const { menu } = item;
  for (let i = 0; i < menu.length; i += 1) {
    const subItem = menu[i];
    if (checkMatch(path, subItem)) return true;
    if (isSectionItem(subItem) && hasSelectedSection(path, subItem)) {
      return true;
    }
  }
  return false;
};

export function NavigationItem({ item, nested }: NavigationItemProps): ReactElement | null {
  const location = useLocation();
  const listItemStyle = useListItemStyle(nested);
  const { mobile, onClose, opened } = useMenuContext();
  const { indicators } = useStatus();

  const { icon, id, name, indicator: indicatorView } = item;
  /* eslint-disable-next-line no-nested-ternary */
  const action = isRouteItem(item)
    ? routerLink(item.path)
    : isPageItem(item)
    ? routerLink(`/page/${item.page}`)
    : externalLink(item.url);

  const actionProps = resolveAction(action);

  // close menu on mobile after link clicked
  const onItemClick = useCallback(() => {
    if (mobile) onClose();
  }, [mobile, onClose]);

  // this method of partial matching is ofc terribly wrong, but it works in our case
  const selected =
    (isRouteItem(item) &&
      // eslint-disable-next-line react-hooks/rules-of-hooks
      location.pathname.startsWith(useResolvedPath(item.path).pathname)) ||
    (isPageItem(item) && RegExp(`/page/${item.page}`).test(location.pathname)) ||
    hasSelectedSection(location.pathname, item);

  const indicator = find<Indicator>(propEq("key", id), indicators);

  if (indicator?.hidden) {
    return null;
  }

  const indicatorVisible =
    (mobile && indicatorView === IndicatorView.Mobile) ||
    (!mobile && indicatorView === IndicatorView.Desktop) ||
    indicatorView === IndicatorView.Any;

  return (
    <Tooltip title={name}>
      <MuiListItem
        button
        className={listItemStyle}
        selected={selected}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...actionProps}
        onClick={onItemClick}
      >
        <ConditionalWrapper
          condition={(!opened && indicator && indicatorVisible) || false}
          wrapper={(children) => (
            <Badge badgeContent={indicator?.content} color={indicator?.color || "primary"}>
              {children}
            </Badge>
          )}
        >
          <MenuIcon icon={icon || mdiBookOpenOutline} />
        </ConditionalWrapper>
        <Title title={name} />
        {indicator && indicatorVisible && opened && (
          <Chip color={indicator.color || "primary"} label={indicator.content} size="small" />
        )}
      </MuiListItem>
    </Tooltip>
  );
}
