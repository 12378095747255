import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { mdiMagnify } from "@mdi/js";

import { makeSearchQuery, useData, useInstanceSettings, useSearchParamsCustom } from "@sinch/core";
import { selectProfession } from "@sinch/entity";
import { DateInput, Form, MultiSelectInput, SearchInput, SelectInput, SwitchInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { createMapClient, MapLang } from "@sinch/mapycz";
import { Button, useDialogContext, useSnackbar } from "@sinch/ui";
import { values } from "ramda";
import { isObject } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { WorkerRoleOptions } from "../../Shift";
import { requestPositionRecentSearch } from "./api";
import { MapPlaceInput } from "./MapPlaceInput";
import { distanceOptions, PositionSearchFormValues, PositionSearchParams, PositionSearchUrlParams } from "./params";

export function PositionSearchForm(): ReactElement {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { position: { lat, lng } = { lat: null, lng: null }, locale } = useInstanceSettings();
  const { selectEntity, selectResult } = useData(requestPositionRecentSearch);
  const { workerProfessionIds } = selectResult();
  const professions = selectEntity(selectProfession(workerProfessionIds));

  const mapClient = createMapClient({
    lang: locale ? (locale.substr(0, 2) as MapLang) : undefined,
    limit: 5,
    // locality: [country],
    type: ["regional", "poi"],
    lat: lat || undefined,
    lon: lng || undefined,
  });

  const { close } = useDialogContext();

  const { searchParams } = useSearchParamsCustom<PositionSearchUrlParams>();

  const professionOptions = (professions || []).filter(isObject).map(({ id, name }) => ({ value: id, label: name }));

  return (
    <Form<PositionSearchFormValues>
      initialValues={PositionSearchParams.toForm(searchParams)}
      submitHandler={(values) => {
        snackbar("info", t("validation.filterApplied"));
        navigate({
          pathname: "/position",
          search: makeSearchQuery(PositionSearchParams.fromForm(values)),
        });
      }}
    >
      <MuiGrid container direction="column">
        <MuiGrid item xs={12}>
          <SearchInput label={t("PositionSearch.query")} name="query" placeholder={t("PositionSearch.queryPH")} />
        </MuiGrid>
        <MuiGrid container item spacing={1}>
          <MuiGrid item xs={8}>
            <MapPlaceInput
              label={t("PositionSearch.place")}
              mapClient={mapClient}
              name="place"
              placeholder={t("PositionSearch.placePH")}
            />
          </MuiGrid>
          <MuiGrid item xs={4}>
            <SelectInput label={t("PositionSearch.radius")} name="distance" options={distanceOptions} />
          </MuiGrid>
        </MuiGrid>
        <MuiGrid container item spacing={1}>
          <MuiGrid item xs={6}>
            <DateInput label={t("fromDate")} name="from" nullable />
          </MuiGrid>
          <MuiGrid item xs={6}>
            <DateInput label={t("toDate")} name="to" nullable />
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MultiSelectInput
            label={t("Position.prop.role")}
            name="role"
            options={values(WorkerRoleOptions())}
            placeholder={t("PositionSearch.anyRole")}
            toggleAll
          />
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MultiSelectInput
            label={t("Profile.display.professions")}
            name="profession"
            options={professionOptions}
            placeholder={t("PositionSearch.anyProfession")}
            toggleAll
          />
        </MuiGrid>
        <MuiGrid item xs={12}>
          <Box my={1}>
            <SwitchInput label={t("PositionSearch.excludeOverlapping")} name="ignoreConflict" />
          </Box>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <Button action={close} color="info" icon={mdiMagnify} large type="submit">
            {t("searchShifts")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Form>
  );
}
