import { defineRequest } from "@sinch/core";
import { Fk, Position } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { parseDate } from "@sinch/utils";

export const requestClockIn = defineRequest("StaffClockIns/clockIn/:positionId")
  .withUriParams({ positionId: ["params", "positionId"] })
  .withParams<{ positionId: Fk<Position> }>()
  .withResponse<{
    clockIn: boolean;
    clockInTime: Nullable<Date>;
  }>({
    clockIn: (val: boolean) => val,
    clockInTime: parseDate,
  })
  .withCreator(
    (params: {
      positionId: Fk<Position>;
      errorCode?: number | null;
      lat?: number | null;
      lng?: number | null;
      accuracy?: number | null;
    }) => ({
      params,
    })
  );

export const requestClockOut = defineRequest("StaffClockIns/clockOut/:positionId")
  .withUriParams({ positionId: ["params", "positionId"] })
  .withParams<{ positionId: Fk<Position>; note?: string; dateTime?: Date; files?: string[] }>()
  .withResponse<{
    clockOut: boolean;
    clockOutTime: Nullable<Date>;
  }>({
    clockOut: (val: boolean) => val,
    clockOutTime: parseDate,
  })
  .withCreator(
    (params: {
      positionId: Fk<Position>;
      note?: string;
      dateTime?: Date;
      errorCode?: number | null;
      lat?: number | null;
      lng?: number | null;
      accuracy?: number | null;
      files?: string[];
    }) => ({ params })
  );
