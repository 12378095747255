import React, { ReactElement, ReactNode } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactElement;
  children: ReactNode;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): ReactElement => (
  <>{condition ? wrapper(children) : children}</>
);
