import { useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectProfession } from "@sinch/entity";
import { Spacer } from "@sinch/ui";
import React from "react";
import { requestPositionList } from "../../Position/List/api";
import { WorkerRoleOptions } from "../../Shift";

export function PositionProfession({ id }: { id: Fk<Position> }) {
  const { selectEntity } = useData(requestPositionList);

  const { profession, role } = selectEntity(selectPosition(id));
  const professionName = selectEntity(selectProfession(profession, "name"));
  const roleName = WorkerRoleOptions()[role].label;

  return (
    <Spacer dropEmpty separator=" - ">
      {professionName}
      {roleName}
    </Spacer>
  );
}
