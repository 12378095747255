import { defineRequest, FileHash, FilesMeta, IdParams } from "@sinch/core";
import {
  ApplicantAttendance,
  AppointmentContainer,
  CompanyContainer,
  FeedbackContainer,
  Fk,
  LocationContainer,
  PayoutMethod,
  PayoutMethodContainer,
  Position,
  PositionAttendanceContainer,
  PositionContainer,
  ProfessionContainer,
  Reimbursement,
  ReimbursementContainer,
  ShiftContainer,
  Snippet,
  SnippetContainer,
  TransportAttendanceContainer,
  TransportContainer,
  UserContainer,
  WalletTransaction,
  WorkerContainer,
} from "@sinch/entity";
import { parseISO } from "date-fns";

export const requestPositionDetail = defineRequest("Positions/View/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Position>>()
  .withResponse<{
    positionId: Fk<Position>;
    noticeIds?: Fk<Snippet>[];
    reimbursementIds: Fk<Reimbursement>[];
    workerSignIn: Fk<Position>[];
    applicantSignIn: Fk<Position>[];
    workerSignOut: boolean;
    signedAsApplicant: boolean;
    attendSameShift: boolean;
    isJoinable: boolean;
    availableInGroup: Fk<Position>[];
    appliedPositions: Fk<Position>[];
    publicDescriptionFiles: FileHash[];
    crewbossDescriptionFiles?: FileHash[];
    payoutMethodsIds: Fk<PayoutMethod>;
    wage: { hour: number; fix: number };
    totalWage?: number;
    minimumRenumeration?: number;
    shiftDetailDocument?: string;
    walletHistory?: { date: Date; walletId: Fk<WalletTransaction>[] }[];
    canWorkerUploadFiles?: boolean;
    workerFiles: FileHash[];
    isApplicantRequiredConfirmation: boolean;
    groupedApplicantConfirmations: Record<FK<ApplicantAttendance>, FK<Position>>;
    clockOutMessage: string | null;
  }>({
    walletHistory: (walletHistory: { date: string; walletId: Fk<WalletTransaction> }[]) =>
      walletHistory.map(({ date, walletId }) => ({
        date: parseISO(date),
        walletId,
      })),
  })
  .withResponseMeta<FilesMeta>()
  .withResponseEntity<
    AppointmentContainer &
      CompanyContainer &
      FeedbackContainer &
      LocationContainer &
      PositionContainer &
      PositionAttendanceContainer &
      ProfessionContainer &
      ShiftContainer &
      SnippetContainer &
      TransportContainer &
      TransportAttendanceContainer &
      UserContainer &
      WorkerContainer &
      ReimbursementContainer &
      PayoutMethodContainer &
      WalletTransaction
  >()
  .withCreator((id: Fk<Position>) => ({ params: { id } }));

export const MAX_UPLOADED_FILES = 5;

export const PositionWorkerFilesTarget = "worker/Positions/addWorkerFile";

export const requestFileRemove = defineRequest("Positions/deleteWorkerFile/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<{ id: FileHash }>()
  .withEmptyResponse()
  .withCreator((id: FileHash) => ({
    params: { id },
  }));
