import { useData } from "@sinch/core";
import { Fk, Payout, PayoutMethod, PayoutPaymentType, selectPayout, selectPayoutMethod } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { rejectFalsy } from "@sinch/utils";
import React, { ReactElement } from "react";
import { requestWalletPayoutDetail } from "../Wallet";
import { PayoutParams } from "./api";
import { PayoutRequestForm } from "./PayoutRequestForm";

interface PayoutRequestModifyProps {
  availablePayout: Fk<PayoutMethod>[];

  balance: number;

  payout: Fk<Payout>;
}

export function PayoutRequestModify({ availablePayout, balance, payout }: PayoutRequestModifyProps): ReactElement {
  const { curr } = useFormat();
  const { selectEntity } = useData(requestWalletPayoutDetail);

  const { files, grossAmount, method, netAmount, paymentType } = selectEntity(selectPayout(payout));

  const overview = rejectFalsy([
    {
      label: t("Wallet.display.netAmount"),
      content: curr(netAmount),
    },
    {
      label: t("Wallet.display.grossAmount"),
      content: curr(grossAmount),
    },
    {
      label: t("Wallet.display.paymentType"),
      content: PayoutPaymentType[paymentType],
    },
    {
      label: t("Contract.title"),
      content: selectEntity(selectPayoutMethod(method, "name")),
    },
  ]);

  const initialValues: PayoutParams = {
    files,
    grossAmount,
    method,
    payout,
  };

  return (
    <PayoutRequestForm
      availablePayout={availablePayout}
      balance={balance}
      initialValues={initialValues}
      overview={overview}
      payout={payout}
    />
  );
}
