import { mdiClose } from "@mdi/js";
import {
  GridList as MuiGridList,
  GridListTile as MuiGridListTile,
  GridListTileBar as MuiGridListTileBar,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useFiles } from "@sinch/core";
import { FileHash, Nullable, OneOrMore } from "@sinch/types";
import { ChildrenProps, fileSize, flatList } from "@sinch/utils";
import clsx from "clsx";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { CSSProperties, ReactElement, useState } from "react";
import { IconButton } from "./Button";
import { FileListItemProps } from "./FileList";
import { Image, ImageFileProps, ImageSrcProps, isNotImageType, resolveHref, resolveUrl } from "./Image";
import { Text } from "./Text";

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: "100%",
  },
  center: {
    justifyContent: "center",
  },

  item: {
    // "&:hover": {
    //   "& $bar": {
    //     visibility: "visible",
    //   },
    // },
  },

  bar: {
    // visibility: "hidden",
  },

  remove: {
    "& button": {
      color: palette.common.white,

      "&:hover": {
        color: palette.error.light,
      },
    },
  },
}));

export interface ImageGridItemProps extends Omit<FileListItemProps, "href">, Pick<ImageSrcProps, "href"> {
  /**
   * Style prop is necessary for displaying MuiGridListTile sized properly
   * and it gets injected by parent MuiGridList automatically
   *
   * todo: maybe we don't have to expose it in public api...
   */
  style?: CSSProperties;
}

export function ImageGridItem({ href, name, onRemove, progress, style, type, url }: ImageGridItemProps): ReactElement {
  const styles = useStyles();
  const [titleBarVisibility, setTitleBarVisibility] = useState(false);

  const handleTitleBarShow = () => {
    setTitleBarVisibility(true);
  };
  const handleTitleBarHide = () => {
    setTitleBarVisibility(false);
  };

  if (isNotImageType(type)) {
    console.warn("File passed into ImageGridItem component is not a valid image type.", { hash: name, type, url });
  }

  return (
    <MuiGridListTile
      className={styles.item}
      onMouseEnter={handleTitleBarShow}
      onMouseLeave={handleTitleBarHide}
      style={style}
    >
      <Image href={href} scaling="scaleHeight" src={url} />
      {titleBarVisibility && (
        <MuiGridListTileBar
          actionIcon={
            onRemove && (
              <span className={styles.remove}>
                <IconButton action={onRemove} icon={mdiClose} size="small" />
              </span>
            )
          }
          className={styles.bar}
          subtitle={<Text small>{progress && `(${progress} %)`}</Text>}
          title={<Text>{name}</Text>}
        />
      )}
    </MuiGridListTile>
  );
}

export interface ImageGridContainerProps {
  /**
   * Number of px for one cell height.
   * Set "auto" to let the children determine the height.
   */
  cellHeight?: number | "auto";

  cols?: number;

  center?: boolean;
}

export function ImageGridContainer({
  cellHeight,
  children,
  cols,
  center,
}: ImageGridContainerProps & ChildrenProps): Nullable<ReactElement> {
  const styles = useStyles();
  const { spacing } = useTheme();

  if (isNilOrEmpty(children)) return null;

  return (
    <MuiGridList
      cellHeight={cellHeight}
      className={clsx(styles.container, center && styles.center)}
      cols={cols}
      spacing={spacing(1)}
    >
      {children}
    </MuiGridList>
  );
}

export interface ImageGridProps extends ImageGridContainerProps, Pick<ImageFileProps, "hrefParam" | "srcParam"> {
  files: OneOrMore<FileHash>;
}

/**
 * Displays grid of image files retrieved via {@link FileInfo.hash} reference.
 *
 * For displaying regular images use {@link ImageGridContainer} directly and
 * pass individual images as {@link ImageGridItem} in children.
 */
export function ImageGrid({ cellHeight, cols, files, hrefParam, srcParam }: ImageGridProps): ReactElement {
  const storage = useFiles();

  return (
    <ImageGridContainer cellHeight={cellHeight} cols={cols}>
      {flatList(files).map((file) => {
        const { url, ...fileInfo } = storage(file);

        return (
          <ImageGridItem
            key={file}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...fileInfo}
            href={resolveHref(url, true, hrefParam)}
            url={resolveUrl(url, srcParam)}
          />
        );
      })}
    </ImageGridContainer>
  );
}
