import { defineRequest, IdParams, PagingParams, PagingResult } from "@sinch/core";
import { Fk, News, NewsContainer } from "@sinch/entity";

export const requestNewsConfirm = defineRequest("News/Confirm/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<News>>()
  .withEmptyResponse()
  .withCreator((id: Fk<News>) => ({ params: { id } }));

export const requestNewsDetail = defineRequest("News/View/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<News>>()
  .withResponse<{
    newsId: Fk<News>;
  }>()
  .withResponseEntity<NewsContainer>()
  .withCreator((id: Fk<News>) => ({ params: { id } }));

export const requestNewsList = defineRequest("News/Index")
  .withEmptyParams()
  .withMeta<PagingParams>()
  .withResponse<{
    newsIds: Fk<News>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<NewsContainer>()
  .withCreator((paging: PagingParams) => ({
    meta: paging,
  }));
