import { mdiCashPlus, mdiBankTransferOut } from "@mdi/js";
import { Grid as MuiGrid } from "@material-ui/core";
import MuiTypography from "@material-ui/core/Typography";

import { useData } from "@sinch/core";
import { t, useFormat } from "@sinch/intl";
import { Color, IconId } from "@sinch/types";
import { Card, Icon, RowsGrid, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestWalletTransactionList } from "../api";
import { WalletSearchForm } from "../WalletSearchForm";

interface SummaryBoxProps {
  icon: IconId;
  color: Color;
  label: string;
  amount: number;
}

function SummaryBox({ icon, color, label, amount }: SummaryBoxProps): ReactElement {
  const [, , data] = useSpacing();
  const { curr } = useFormat();
  return (
    <Card center gutters="small" guttersVertical="small">
      <RowsGrid alignItems="center">
        <MuiGrid container spacing={data}>
          <MuiGrid item>
            <Icon color={color} icon={icon} />
          </MuiGrid>
          <MuiGrid item>{label}</MuiGrid>
        </MuiGrid>
        <MuiTypography color={amount < 0 ? "error" : undefined} component="span" variant="h3">
          {curr(amount)}
        </MuiTypography>
      </RowsGrid>
    </Card>
  );
}

export function WalletTransactionHeader(): ReactElement {
  const [, inner] = useSpacing();
  const { selectResult } = useData(requestWalletTransactionList);
  const { incomeAmount, payoutAmount } = selectResult();
  return (
    <>
      <WalletSearchForm entity="transaction" showArchivedCheckbox />

      <MuiGrid container spacing={inner}>
        <MuiGrid item xs>
          <SummaryBox amount={incomeAmount} color="success" icon={mdiCashPlus} label={t("wallet.income")} />
        </MuiGrid>
        <MuiGrid item xs>
          <SummaryBox amount={payoutAmount} color="info" icon={mdiBankTransferOut} label={t("wallet.payouts")} />
        </MuiGrid>
      </MuiGrid>
    </>
  );
}
