import { DataProvider, responseHandlerKey, useIntParams, useRefresh, useStatusUpdate } from "@sinch/core";
import { t } from "@sinch/intl";
import { useSnackbar, useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { requestShiftAttendanceClose, requestShiftAttendanceView } from "./api";
import { AttendanceClosing } from "./AttendanceClosing";

export function AttendanceClosingContainer(): ReactElement {
  useTitleBar({
    container: "core:attendance-closing",
    locationTitle: t("Shift.closingTitle"),
    showBack: true,
  });
  const { refresh: refreshStatus } = useStatusUpdate();

  const { shift } = useIntParams();

  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh();
  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestShiftAttendanceClose,
        () => {
          snackbar("success", t("Shift.closingSuccess"));
          refreshStatus();
          navigate(`/dashboard/feedback#/shift/${shift}/feedback`);
        },
        () => {
          snackbar("error", t("Shift.closingFailed"));
          refresh();
        }
      ),
    [navigate, refresh, snackbar]
  );

  const request = useMemo(() => requestShiftAttendanceView(shift), [shift]);

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request}>
      <AttendanceClosing />
    </DataProvider>
  );
}
