import { FileId } from "@sinch/core";
import { FileListContainer, FileListItem } from "@sinch/ui";
import { flatList } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useFileInputContext } from "./FileInputProvider";

export function FileInputItems({
  denyRemove,
  onDeleteFile,
}: {
  denyRemove?: boolean;
  onDeleteFile?: (file: FileId) => Promise<void>;
}): ReactElement {
  const { removeFile, progress, storage, value } = useFileInputContext();

  const handleRemoveFile = (file: FileId) => {
    if (onDeleteFile) {
      onDeleteFile(file).then(() => removeFile(file));
    } else {
      removeFile(file);
    }
  };

  return (
    <FileListContainer>
      {value &&
        flatList(value).map((file) => {
          try {
            return (
              <FileListItem
                key={file}
                href
                onRemove={denyRemove ? undefined : () => handleRemoveFile(file)}
                progress={progress(file)}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...storage(file)}
              />
            );
          } catch (e) {
            return null;
          }
        })}
    </FileListContainer>
  );
}
