import { OneOrMore } from "@sinch/types";
import { unwrapList } from "@sinch/utils";
import {
  either,
  equals,
  forEach,
  forEachObjIndexed,
  groupBy,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  last,
  map,
  mapObjIndexed,
  pipe,
  reject,
} from "ramda";
import { isArray, isPlainObj } from "ramda-adjunct";

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * todo: automatically convert values to strings or pass configurable serializer
 *
 * todo: add type constrains
 */
function makeSearchParams(values: Record<string, any>): URLSearchParams {
  const searchParams = new URLSearchParams();

  /**
   * Primitive values are serialized automatically, array values are added
   * individually, but objects have to be explicitly resolved here, unknown
   * objects will throw error.
   */
  function serialize(value: any): string | never {
    if (!isPlainObj(value)) return value;

    if (value instanceof Date) return value.toISOString();

    throw new Error("Cannot use object as search params value");
  }

  function append(key: string, value: any) {
    const serialized = serialize(value);
    searchParams.append(key, serialized);
  }

  function resolveValues(value: any, key: string) {
    if (isArray(value)) {
      forEach((el: any) => append(key, el), value);
      return;
    }

    append(key, value);
  }

  forEachObjIndexed(resolveValues, values);

  return searchParams;
}

/**
 * todo: extract to common utils
 */
const filterValues = reject(either(isNil, isEmpty));

/**
 * todo: make generic to enable strict type checking
 *
 * todo: consider integrating into custom module based navigation handler
 *  ```
 *  navigate({
 *    pathname: "/position",
 *    search: makeSearchQuery(values), // <-- wrap automatically
 *  });
 *  // might be simplified to
 *  `PositionView.available.navigate(values)`
 *  ```
 */
export function makeSearchQuery(values: Record<string, any>): string {
  const searchParams = makeSearchParams(filterValues(values));
  return `?${searchParams}`;
}

/**
 * String tuples representing key-value pairs contained in
 * {@link URLSearchParams}.
 */
type KeyValuePair = [key: string, value: string];

/**
 * Split list of key-value pairs into object grouped by their key.
 */
const groupByKey = groupBy<KeyValuePair>(head);

/**
 * Extract values from list of key-value pairs.
 */
const extractValues = map<KeyValuePair, string>(last);

/**
 * Return {@link Record} mapping keys found in given {@link URLSearchParams}
 * to one or more values associated to them.
 */
function resolveSearchParams(
  searchParams: URLSearchParams
): Record<string, OneOrMore<string>> {
  /**
   * Iterate through given record and resolve contained key-value pairs by
   * extracting the values and unwrapping single element lists.
   */
  const resolveParams = mapObjIndexed(pipe(extractValues, unwrapList));

  const keyValuePairs: KeyValuePair[] = Array.from(searchParams);
  const groups: Record<string, KeyValuePair[]> = groupByKey(keyValuePairs);
  return resolveParams(groups);
}

export function parseSearchParams<TOut>(
  valueParser: (value: string) => TOut,
  searchParams: URLSearchParams
): Record<string, OneOrMore<TOut>>;

export function parseSearchParams<TOut>(
  valueParser: (value: string) => TOut
): (searchParams: URLSearchParams) => Record<string, OneOrMore<TOut>>;

export function parseSearchParams<TOut>(
  valueParser: (value: string) => TOut,
  searchParams?: URLSearchParams
):
  | Record<string, OneOrMore<TOut>>
  | ((searchParams: URLSearchParams) => Record<string, OneOrMore<TOut>>) {
  /* */
  const parseValues = ifElse(isArray, map(valueParser), valueParser);

  function parse(params: URLSearchParams): Record<string, OneOrMore<TOut>> {
    const resolvedParams = resolveSearchParams(params);
    return map(parseValues, resolvedParams);
  }

  return searchParams ? parse(searchParams) : parse;
}

const isNull = either(equals("null"), equals(""));
const isTrue = equals("true");
const isFalse = equals("false");
const isNaN = equals(NaN);

const toNumber = (value: string) => +value;

function parseBase(value: string): string | number | boolean | null {
  if (isNull(value)) return null;
  if (isTrue(value)) return true;
  if (isFalse(value)) return false;
  const number = toNumber(value);
  return isNaN(number) ? value : number;
}

parseSearchParams.asBase = parseSearchParams(parseBase);

parseSearchParams.asNumber = parseSearchParams(toNumber);

parseSearchParams.asString = parseSearchParams(identity);

/* eslint-disable @typescript-eslint/ban-types */

export type SearchParamsUpdate<TParams extends object> =
  | Partial<TParams>
  | ((prevParams: TParams) => TParams);

export interface SearchParamsState<TParams extends object> {
  /**
   * Current state of URL search params deserialized as object.
   */
  searchParams: TParams;

  /**
   * Set or patch current state of URL search params using given input.
   * - if `update` is object, it is merged into previous state
   *   (partial input is valid in this case)
   * - if `update` is function, state is replaced by result of calling it with
   *   current state as input param (so result must be complete `TParams`)
   */
  updateSearchParams: (update: SearchParamsUpdate<TParams>) => void;
}
