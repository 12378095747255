import { useData } from "@sinch/core";
import { selectNews } from "@sinch/entity";
import { Card, HtmlContent } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestNewsDetail } from "../api";

export function NewsDetail(): ReactElement {
  const { selectEntity, selectResult } = useData(requestNewsDetail);
  const { newsId } = selectResult();
  const { body } = selectEntity(selectNews(newsId));

  return (
    <Card>
      <HtmlContent html={body} />
    </Card>
  );
}
