import { mdiFolderZipOutline, mdiFileMusicOutline, mdiFileDocumentOutline, mdiFileOutline, mdiFileImageOutline, mdiFileTableOutline, mdiFileVideoOutline } from "@mdi/js";
import { IconId } from "@sinch/types";
import { find, keys, startsWith } from "ramda";
import React, { ReactElement } from "react";
import { Icon, IconProps } from "./Icon";

type FileTypeCategory = "archive" | "audio" | "document" | "file" | "image" | "pdf" | "sheet" | "video";

const categoryIcon: Record<FileTypeCategory, IconId> = {
  archive: mdiFolderZipOutline,
  audio: mdiFileMusicOutline,
  document: mdiFileDocumentOutline,
  file: mdiFileOutline,
  image: mdiFileImageOutline,
  pdf: mdiFileOutline,
  sheet: mdiFileTableOutline,
  video: mdiFileVideoOutline,
};

const typeToCategory: Record<string, FileTypeCategory> = {
  audio: "audio",
  image: "image",
  video: "video",

  "application/pdf": "pdf",

  "application/gzip": "archive",
  "application/zip": "archive",

  text: "document",

  "application/msword": "document",
  "application/vnd.ms-": "document",
  "application/vnd.oasis.opendocument": "document",
  "application/vnd.openxmlformats-officedocument": "document",
};

/**
 * List of official MIME Types:
 * http://www.iana.org/assignments/media-types/media-types.xhtml
 *
 * todo: recognize `sheet` type (now categorized as `document`)
 */
function getCategory(type?: string): FileTypeCategory {
  if (!type) return "file";

  const match = find((prefix: string) => startsWith(prefix, type), keys(typeToCategory));

  return match ? typeToCategory[match] : "file";
}

interface FileIconProps extends IconProps {
  type?: string;
}

export function FileIcon({ color, size, tooltip, type }: FileIconProps): ReactElement {
  const icon = categoryIcon[getCategory(type)];
  return <Icon color={color} icon={icon} size={size} tooltip={tooltip} />;
}
