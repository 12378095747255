import { mdiAccountEdit, mdiAlertOutline, mdiCancel } from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import { selectUser } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Grid, GridLayout, routerLink, Strip, Text } from "@sinch/ui";
import { isDefined, rejectFalsy } from "@sinch/utils";
import { isBefore } from "date-fns";
import { concat, flatten, isEmpty, isNil } from "ramda";
import React, { ReactElement } from "react";
import { HibernationStatusStrip, StatusProps } from "../../components";
import { PhoneVerificationStatus, requestProfileStatus } from "./api";

const prefix = "profile-";

export function ProfileStatus(): ReactElement | null {
  const {
    restrictions: { hibernated, incompleteAttributesCounts },
  } = useCurrentUser();
  const isHibernated = !isNil(hibernated) && hibernated !== false;
  const { selectResult, selectEntity } = useData(requestProfileStatus);
  const { verified, attributeExpiration: expiration, incomplete, phoneVerificationStatus, userId } = selectResult();
  const isProfileExpired =
    !isNil(expiration?.profileAttribute) && isBefore(expiration.profileAttribute.expiration, new Date());
  const isPersonalExpired =
    !isNil(expiration?.personalAttribute) && isBefore(expiration.personalAttribute.expiration, new Date());

  const phone = selectEntity(selectUser(userId, "phone"));

  const status = flatten<StatusProps[]>(
    rejectFalsy([
      incomplete && {
        key: `${prefix}unfilled`,
        icon: mdiAlertOutline,
        color: "warning",
        content: t("Profile.display.incompleteProfile"),
        action: {
          btnProps: { variant: "outlined", color: "info" },
          action: routerLink(concat("edit/personal", incomplete[1] ? `#${incomplete[1]}` : ""), {
            state: {
              group: incomplete[0],
              scrollToken: new Date(),
            },
          }),
          label: t("action.edit"),
        },
      },
      isDefined(expiration?.personalAttribute) &&
        !isPersonalExpired && {
          key: `${prefix}expiring`,
          icon: mdiAlertOutline,
          color: "warning",
          content: t("Profile.display.expiringPersonal"),
          action: {
            btnProps: { variant: "outlined", color: "info" },
            action: routerLink(`personal#${expiration?.personalAttribute?.id}`, {
              state: {
                scrollToken: new Date(),
              },
            }),
            label: t("action.check"),
          },
        },
      isDefined(expiration?.personalAttribute) &&
        isPersonalExpired && {
          key: `${prefix}expired`,
          icon: mdiCancel,
          color: "error",
          content: t("Profile.display.expiredPersonal"),

          action: {
            btnProps: { variant: "outlined", color: "info" },
            action: routerLink(`personal#${expiration?.personalAttribute?.id}`, {
              state: {
                scrollToken: new Date(),
              },
            }),
            label: t("action.check"),
          },
        },
      isDefined(expiration?.profileAttribute) &&
        !isProfileExpired && {
          key: `${prefix}expiring`,
          icon: mdiAlertOutline,
          color: "warning",
          content: t("Profile.display.expiringProfile"),
          action: {
            btnProps: { variant: "outlined", color: "info" },
            action: routerLink(`attributes#${expiration?.profileAttribute?.id}`, {
              state: {
                scrollToken: new Date(),
              },
            }),
            label: t("action.check"),
          },
        },
      isDefined(expiration?.profileAttribute) &&
        isProfileExpired && {
          key: `${prefix}expired`,
          icon: mdiCancel,
          color: "error",
          content: t("Profile.display.expiredProfile"),

          action: {
            btnProps: { variant: "outlined", color: "info" },
            action: routerLink(`attributes#${expiration?.profileAttribute?.id}`, {
              state: {
                scrollToken: new Date(),
              },
            }),
            label: t("action.check"),
          },
        },
      !verified && {
        key: `${prefix}unverified`,
        icon: mdiAlertOutline,
        color: "warning",
        content: t("Profile.display.unverifiedProfile"),
      },
      phone &&
        !isNil(phoneVerificationStatus) &&
        phoneVerificationStatus !== PhoneVerificationStatus.Verified && {
          key: `${prefix}unverifiedPhone`,
          icon: mdiCancel,
          color: "error",
          content: t("Profile.PhoneVerification.yourPhoneIsNotVerified"),
          action: {
            btnProps: { variant: "outlined", color: "info" },
            action: routerLink(`#/phoneVerification`),
            label: t("verify"),
          },
        },
      !isNil(incompleteAttributesCounts) &&
        incompleteAttributesCounts && {
          key: `${prefix}unfilledProfile`,
          icon: mdiAccountEdit,
          color: "warning",
          title: t("Application.unfilledAttributesWarningTitle"),
          content: <Text>{t("Application.unfilledAttributesWarningMessage")}</Text>,
          action: {
            btnProps: { variant: "outlined", color: "info" },
            label: t("editProfile"),
            action:
              (incompleteAttributesCounts?.profile || 0) > 0
                ? routerLink("/profile/edit/attributes")
                : routerLink("/profile/edit/personal"),
          },
        },
    ])
  );

  if (isEmpty(status)) {
    return null;
  }

  return (
    <GridLayout.Item>
      <Grid spacing="data" vertical>
        {status.map(({ title, action, color, content, icon, key, secondaryAction }) => (
          <Strip
            key={key}
            color={color}
            icon={icon}
            primaryAction={action}
            secondaryAction={secondaryAction}
            title={title}
          >
            {content}
          </Strip>
        ))}
        {isHibernated && <HibernationStatusStrip />}
      </Grid>
    </GridLayout.Item>
  );
}
