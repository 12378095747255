import { useRefresh } from "@sinch/core";
import { useFormat } from "@sinch/intl";
import React, { ReactElement } from "react";

interface TimeoutTimerProps {
  timestamp: Date;
}

export function TimeoutTimer({ timestamp }: TimeoutTimerProps): ReactElement {
  const { refreshToken } = useRefresh({ interval: 1000 });
  const { dur } = useFormat();
  const diff = (new Date().getTime() - timestamp.getTime()) / 1000;

  return <>{refreshToken && dur.timer(diff)}</>;
}
