import { IdParams, useData } from "@sinch/core";
import { Fk, Payout, PayoutMethod, PayoutStatus, selectPayout } from "@sinch/entity";
import { t } from "@sinch/intl";
import { InformationDialog } from "@sinch/ui";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { requestWalletPayoutDetail } from "../Wallet";
import { PayoutRequestModify } from "./PayoutRequestModify";
import { PayoutRequestView } from "./PayoutRequestView";

interface PayoutRequestViewDialogProps extends IdParams<Payout> {
  availablePayout: Fk<PayoutMethod>[];

  balance: number;
}

export function PayoutRequestViewDialog({ availablePayout, balance, id }: PayoutRequestViewDialogProps): ReactElement {
  const { selectEntity } = useData(requestWalletPayoutDetail);

  const { status } = selectEntity(selectPayout(id));

  return equals(status, PayoutStatus.Pending) ? (
    <InformationDialog title={t("PayoutRequest.editPayout")} width="xs">
      <PayoutRequestModify availablePayout={availablePayout} balance={balance} payout={id} />
    </InformationDialog>
  ) : (
    <InformationDialog title={t("PayoutRequest.payoutDetail")} width="xs">
      <PayoutRequestView id={id} />
    </InformationDialog>
  );
}
