import { RestFunc } from "@sinch/types";
import hash from "object-hash";
import { identity, path as rPath, replace } from "ramda";
import { Args } from "./RequestCreator";
import { RequestKeyStep } from "./steps";

/**
 * todo: what about naming? what should be resulting type?
 *  contract? creator? request..?
 *  find something non-colliding with native types...
 *
 * todo: can we somehow define request-response tuples for meta?
 *  - adding PagingParams will automatically add PagingResult as well
 *    `withMeta(PagingMeta)`
 *
 * todo: extend args to accept `zod` Schema object
 *  - transform is used for de/serializing data
 *  - parse is used for validation
 *  - default values are set
 *  - type information is available in runtime
 */

export function defineRequest<TKey extends string>(key: TKey): RequestKeyStep<TKey> {
  const step = {
    key,

    resultParser: undefined,

    uriParamMap: undefined,

    requestHash: undefined,

    withCreator(selector?: RestFunc<Args, object>) {
      const { resultParser, uriParamMap } = this;

      return Object.assign((...args: Args) => {
        const selectorParams = selector?.(...args);

        let requestKey: string = key;

        if (uriParamMap) {
          // @ts-ignore
          Object.entries(uriParamMap).forEach(([placeholder, path]: [string, string[]]) => {
            // @ts-ignore
            requestKey = replace(`:${placeholder}`, rPath(path, selectorParams), key);
          });
        }

        const requestObject = {
          ...selector?.(...args),
          key: requestKey,
          resultParser,
        };
        return { ...requestObject, requestHash: hash(requestObject) };
      }, this);
    },

    withDefaultCreator() {
      return this.withCreator(identity);
    },

    withEmptyParams() {
      return this;
    },

    withEmptyResponse() {
      return this;
    },

    withMeta() {
      return this;
    },

    withParams() {
      return this;
    },

    withUriParams(uriParams: { [key: string]: string[] }) {
      return {
        ...this,
        uriParamMap: uriParams,
      };
    },

    withResponse(parser: never) {
      return {
        ...this,
        resultParser: parser,
      };
    },

    withResponseEntity() {
      return this;
    },

    withResponseMeta() {
      return this;
    },
  };

  return step as never;
}
