import { FileId, FileStorage, LocalFileKey, uploadProgressPercent, useFiles } from "@sinch/core";
import { Consumer, Maybe, Nullable } from "@sinch/types";
import { ChildrenProps, createCheckedContext, replaceElement } from "@sinch/utils";
import { append, assoc, filter, forEach, take, without } from "ramda";
import { concatRight, isTrue } from "ramda-adjunct";
import React, { ReactElement, SetStateAction, useCallback, useMemo, useState } from "react";
import { createValidator, FileValidator, FileValidatorParams } from "./FileValidator";

/**
 * todo: rename props to differentiate between File and Val handlers
 *
 * todo: split into display data, state and handlers
 */
interface FileInputState {
  addFiles: (files: File[]) => void;

  closeError: (text: string) => void;

  errors: string[];

  multiple?: boolean;

  removeFile: (file: FileId) => void;

  showError: (text: string, timeout?: number) => void;

  progress: (file: LocalFileKey) => Maybe<number>;

  storage: FileStorage;

  validateFile: (file: File) => boolean;

  validator: FileValidator;

  /**
   * Array of strings if `multiple` is true or nullable string if false
   */
  value: Nullable<FileId> | FileId[];
}

interface FileInputUploadParams {
  target: string;
}

type FileInputCommonParams = FileValidatorParams & FileInputUploadParams;

interface FileInputSingleValueParams {
  multiple?: false;

  onChange: (v: SetStateAction<Nullable<FileId>>, progress?: string | undefined) => void;

  value: Nullable<FileId>;
  maxFiles?: number;
}

interface FileInputMultiValueParams {
  multiple: true;

  onChange: (v: SetStateAction<FileId[]>, progress?: string | undefined) => void;

  value: FileId[];

  maxFiles?: number;
}

type FileInputValueParams = FileInputSingleValueParams | FileInputMultiValueParams;

const isMultiValue = (props: FileInputValueParams): props is FileInputMultiValueParams => isTrue(props.multiple);

export function useFileInput(props: FileInputCommonParams & FileInputSingleValueParams): FileInputState;

export function useFileInput(props: FileInputCommonParams & FileInputMultiValueParams): FileInputState;

export function useFileInput(props: FileInputCommonParams & FileInputValueParams): FileInputState {
  const { accept, extensions, maxSize, multiple, target, value, maxFiles } = props;

  const storage = useFiles();

  const [progressInfo, updateProgressInfo] = useState<Record<FileId, number>>({});

  const [errors, updateErrors] = useState<string[]>([]);

  const closeError = useCallback((text: string) => {
    updateErrors(without([text]));
  }, []);

  const showError = useCallback(
    (text: string, timeout?: number) => {
      updateErrors(append(text));

      if (timeout) {
        window.setTimeout(() => closeError(text), timeout);
      }
    },
    [closeError]
  );

  const validator = useMemo(
    () =>
      createValidator({
        accept,
        extensions,
        maxSize,
      }),
    [accept, extensions, maxSize]
  );

  const validateFile = useCallback(
    (file: File) => {
      try {
        return validator(file);
      } catch (err) {
        if (err instanceof TypeError) {
          showError(err.message);
          return false;
        }
        throw err;
      }
    },
    [showError, validator]
  );

  const addFiles = useCallback(
    (inputFiles: File[]) => {
      const files = maxFiles && multiple ? inputFiles.slice(0, maxFiles) : inputFiles;
      const validated = filter(validateFile, multiple ? files : take(1, files));

      const cacheKeys = storage.store(target, validated, {
        onError: (key, storeErrors) => {
          if (isMultiValue(props)) props.onChange(replaceElement(key, null), "error");
          else props.onChange(null);
          forEach(showError, storeErrors);
        },
        onProgress: (key, progress) => {
          updateErrors([]);
          updateProgressInfo(assoc(key, uploadProgressPercent(progress)));
        },
        onSuccess: (key, hash) => {
          if (isMultiValue(props)) props.onChange(replaceElement(key, hash), "success");
          else props.onChange(hash, "success");
        },
      });

      if (isMultiValue(props)) props.onChange(concatRight(cacheKeys));
      else props.onChange(cacheKeys[0]);
    },
    [multiple, props, showError, storage, target, validateFile]
  );

  const removeFile = useCallback(
    (file: FileId) => {
      storage.remove(file);

      if (isMultiValue(props)) props.onChange(without([file]));
      else props.onChange(null);
    },
    [props, storage]
  );

  const progress = useCallback((key: LocalFileKey) => progressInfo[key], [progressInfo]);

  return useMemo(
    () => ({
      addFiles,
      closeError,
      errors,
      multiple,
      progress,
      removeFile,
      showError,
      storage,
      validateFile,
      validator,
      value,
    }),
    [addFiles, closeError, errors, multiple, progress, removeFile, showError, storage, validateFile, validator, value]
  );
}

const FileInputContext = createCheckedContext<FileInputState>("FileInputState");
export const { use: useFileInputContext } = FileInputContext;

export function FileInputProvider(
  props: FileInputCommonParams & FileInputSingleValueParams & ChildrenProps
): ReactElement;

export function FileInputProvider(
  props: FileInputCommonParams & FileInputMultiValueParams & ChildrenProps
): ReactElement;

export function FileInputProvider({
  children,
  ...props
}: FileInputCommonParams & FileInputValueParams & ChildrenProps): ReactElement {
  const context = useFileInput(props as never);

  return <FileInputContext value={context}>{children}</FileInputContext>;
}
