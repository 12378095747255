import {
  mdiAccount,
  mdiAccountCheck,
  mdiAccountMultiple,
  mdiAccountPlus,
  mdiAccountRemove,
  mdiAirplane,
  mdiAnchor,
  mdiArrowCollapseAll,
  mdiArrowDown,
  mdiArrowExpandAll,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiAutoFix,
  mdiBasket,
  mdiBasketFill,
  mdiBell,
  mdiBookOpen,
  mdiBriefcase,
  mdiCalculator,
  mdiCalendar,
  mdiCamcorder,
  mdiCamera,
  mdiCancel,
  mdiCar,
  mdiChartBar,
  mdiChartBubble,
  mdiChartPie,
  mdiCheck,
  mdiClock,
  mdiClose,
  mdiCloudDownload,
  mdiCloudUpload,
  mdiCog,
  mdiCommentText,
  mdiCompass,
  mdiCreditCard,
  mdiCrop,
  mdiCup,
  mdiCursorDefault,
  mdiCursorMove,
  mdiDelete,
  mdiDiamond,
  mdiDirections,
  mdiDisc,
  mdiDropbox,
  mdiEarth,
  mdiEmail,
  mdiEmailOpen,
  mdiEmoticon,
  mdiEqualizer,
  mdiEye,
  mdiFacebook,
  mdiFaceWoman,
  mdiFastForward,
  mdiFile,
  mdiFileMultiple,
  mdiFilm,
  mdiFire,
  mdiFlag,
  mdiFlash,
  mdiFolder,
  mdiFormatListBulleted,
  mdiGenderFemale,
  mdiGenderMale,
  mdiGhost,
  mdiGift,
  mdiGlasses,
  mdiGoogleController,
  mdiGraph,
  mdiGrid,
  mdiHeadphones,
  mdiHeart,
  mdiHelpCircle,
  mdiHome,
  mdiImage,
  mdiInformation,
  mdiKey,
  mdiLayers,
  mdiLifebuoy,
  mdiLightbulb,
  mdiLink,
  mdiLock,
  mdiLockOpen,
  mdiLogin,
  mdiLogout,
  mdiMagnet,
  mdiMagnify,
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiMap,
  mdiMapMarker,
  mdiMicrophone,
  mdiMonitor,
  mdiMouse,
  mdiMusicNote,
  mdiNote,
  mdiNotebook,
  mdiPaperclip,
  mdiPause,
  mdiPencil,
  mdiPhone,
  mdiPin,
  mdiPlay,
  mdiPlaylistMusic,
  mdiPlayPause,
  mdiPlus,
  mdiPower,
  mdiPrinter,
  mdiPurse,
  mdiPuzzle,
  mdiRedo,
  mdiRefresh,
  mdiReload,
  mdiRestore,
  mdiRewind,
  mdiRocket,
  mdiRss,
  mdiSchool,
  mdiSend,
  mdiShare,
  mdiShield,
  mdiShieldStar,
  mdiShuffle,
  mdiSpeedometer,
  mdiStar,
  mdiStop,
  mdiTabletAndroid,
  mdiTag,
  mdiTarget,
  mdiTestTube,
  mdiThumbDown,
  mdiThumbUp,
  mdiTimerSandFull,
  mdiTranslate,
  mdiTrophy,
  mdiTwitter,
  mdiUmbrella,
  mdiUndo,
  mdiVectorCurve,
  mdiVolumeHigh,
  mdiVolumeOff,
  mdiWallet,
  mdiWater,
  mdiWrench,
  mdiYoutube,
} from "@mdi/js";

export const availableMenuIcons = {
  mdiRedo,
  mdiUndo,
  mdiAnchor,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiShieldStar,
  mdiCancel,
  mdiCar,
  mdiChartBar,
  mdiBasketFill,
  mdiBasket,
  mdiBell,
  mdiBookOpen,
  mdiBriefcase,
  mdiChartBubble,
  mdiLightbulb,
  mdiCalculator,
  mdiCalendar,
  mdiPhone,
  mdiCamcorder,
  mdiCamera,
  mdiClock,
  mdiClose,
  mdiCloudDownload,
  mdiCloudUpload,
  mdiCompass,
  mdiStop,
  mdiFastForward,
  mdiPause,
  mdiPlay,
  mdiRewind,
  mdiPlayPause,
  mdiCreditCard,
  mdiCrop,
  mdiCup,
  mdiCursorMove,
  mdiCursorDefault,
  mdiDiamond,
  mdiDirections,
  mdiDisc,
  mdiThumbDown,
  mdiFile,
  mdiFileMultiple,
  mdiWater,
  mdiHeadphones,
  mdiEmoticon,
  mdiFlash,
  mdiEmailOpen,
  mdiEmail,
  mdiEqualizer,
  mdiEye,
  mdiGlasses,
  mdiRss,
  mdiFilm,
  mdiFire,
  mdiFlag,
  mdiFolder,
  mdiGoogleController,
  mdiGhost,
  mdiEarth,
  mdiSchool,
  mdiGraph,
  mdiGrid,
  mdiPurse,
  mdiHeart,
  mdiHome,
  mdiTimerSandFull,
  mdiCheck,
  mdiTestTube,
  mdiInformation,
  mdiKey,
  mdiLayers,
  mdiThumbUp,
  mdiLink,
  mdiFormatListBulleted,
  mdiLockOpen,
  mdiLock,
  mdiLogin,
  mdiLogout,
  mdiRestore,
  mdiAutoFix,
  mdiMagnet,
  mdiMagnifyPlus,
  mdiMagnifyMinus,
  mdiMagnify,
  mdiMap,
  mdiMicrophone,
  mdiMouse,
  mdiMusicNote,
  mdiNote,
  mdiNotebook,
  mdiPaperclip,
  mdiSend,
  mdiPencil,
  mdiImage,
  mdiChartPie,
  mdiPin,
  mdiAirplane,
  mdiPlaylistMusic,
  mdiPlus,
  mdiMapMarker,
  mdiPower,
  mdiGift,
  mdiPrinter,
  mdiPuzzle,
  mdiHelpCircle,
  mdiRefresh,
  mdiReload,
  mdiRocket,
  mdiMonitor,
  mdiTabletAndroid,
  mdiCog,
  mdiShare,
  mdiShield,
  mdiShuffle,
  mdiArrowCollapseAll,
  mdiArrowExpandAll,
  mdiDropbox,
  mdiFacebook,
  mdiTwitter,
  mdiYoutube,
  mdiSpeedometer,
  mdiCommentText,
  mdiStar,
  mdiLifebuoy,
  mdiGenderFemale,
  mdiGenderMale,
  mdiTag,
  mdiTarget,
  mdiDelete,
  mdiTranslate,
  mdiTrophy,
  mdiUmbrella,
  mdiFaceWoman,
  mdiAccountPlus,
  mdiAccountCheck,
  mdiAccountRemove,
  mdiAccount,
  mdiAccountMultiple,
  mdiVectorCurve,
  mdiVolumeHigh,
  mdiVolumeOff,
  mdiWallet,
  mdiWrench,
};
