import { makeStyles } from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import React from "react";
import { createPaletteStyles, identifiersStylesCreator } from "../paletteStyles";

const paletteStyles = createPaletteStyles(({ main }) => ({
  // borderLeft: `5px solid ${main}`,
  "&::before": {
    content: "''",
    borderLeft: `4px solid ${main}`,
    height: "100%",
    position: "absolute",
    left: "0",
  },
}));

const identifiersPaletteStyles = identifiersStylesCreator(({ color }) => ({
  // borderLeft: `5px solid ${color}`,
  "&::before": {
    content: "''",
    borderLeft: `5px solid ${color}`,
    height: "100%",
    position: "absolute",
    left: "0",
  },
}));

const useStyles = makeStyles((theme) => ({
  ...paletteStyles(theme),
  ...identifiersPaletteStyles(theme),
}));

export function BorderColorListItem({ color, children }) {
  const styles = useStyles();
  return <MuiListItem className={styles[color]}>{children}</MuiListItem>;
}
