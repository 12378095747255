import { Theme } from "@material-ui/core";
import { IntlProvider } from "@sinch/intl";
import { LoadingOverlay, UiProvider } from "@sinch/ui";
import { composeElements } from "@sinch/utils";
import React, { PropsWithChildren, ReactElement } from "react";
import { Backend, DataProvider, FileUploadProvider } from "../backend";
import { OnlineStatusProvider } from "../backend/provider/OnlineStatusProvider";
import { SessionProvider, StatusProvider } from "../context";
import { HtmlHead } from "./HtmlHead";
import { OfflineWarning } from "./OfflineWarning";
import { RootErrorHandler } from "./RootErrorHandler";

export type PluginSlot = "contentFooter" | "titleBar";

export interface ApplicationContainerProps extends PropsWithChildren<any> {
  /**
   * Backend for application (remote)
   */
  backend?: Backend;

  fetchStatus?: boolean;
  scope?: "admin" | "worker";
  defaultTheme?: Theme;
}

/**
 * Main application container
 */
export function ApplicationContainer({
  backend,
  fetchStatus = true,
  scope,
  defaultTheme,
  children,
}: ApplicationContainerProps): ReactElement {
  return composeElements([
    // Provide Intl hooks with default settings
    <IntlProvider />,
    <OnlineStatusProvider />,
    // Top tier data provider, provide base settings for other data provider, specifically backend instance.
    <DataProvider backend={backend} fallback={<LoadingOverlay />} scope={scope} />,
    // Provide UI and Theme (material-ui theme) settings
    <UiProvider defaultTheme={defaultTheme} />,
    // Main error handler for application, handle requests and redirect user to login page if user is not logged in
    <RootErrorHandler />,
    // Provide hooks for handling file upload
    <FileUploadProvider />,
    // Session provider provide application settings for nested components such as theme, logged user or settings of application
    <SessionProvider />,
    // Provide context for handling application status as notifications or badges in menu.
    fetchStatus && <StatusProvider />,
    // Set html headers
    <HtmlHead />,
    // Top level router
    <OfflineWarning />,
    children,
  ]);
}
