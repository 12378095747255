import { DataProvider, requestPasswordUpdate, responseHandlerKey } from "@sinch/core";
import { Form, formValidator, PasswordInput, SubmitButton } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Divider, Grid, InformationDialog, useSnackbar } from "@sinch/ui";
import { equals } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProfileContext } from "./ProfileContext";

interface PasswordForm {
  confirm: string;

  current: string;

  next: string;
}

const initialValues: PasswordForm = {
  confirm: "",
  current: "",
  next: "",
};

const passwordFormat = new RegExp(/^.*(?=.{6,})(?=.*[a-zA-Z])(?=.*\d).*$/);
const isPassword = (val: string) => passwordFormat.test(val);

const validateForm = formValidator<PasswordForm>({
  next: (val) => !isPassword(val) && t<string>("Profile.passwordRules"),
  confirm: (value, { next }) => !equals(value, next) && t<string>("Profile.validation.passwordEqual"),
});

function PasswordUpdateForm(): ReactElement {
  return (
    <Form<PasswordForm>
      initialValues={initialValues}
      submitRequest={({ current, next }) => requestPasswordUpdate(current, next)}
      validate={validateForm}
    >
      <Grid spacing="inner" vertical>
        <PasswordInput data-cy="current-pass-field" label={t("Profile.form.currentPassword")} name="current" />
        <PasswordInput data-cy="new-password-field" label={t("Profile.form.nextPassword")} name="next" />
        <PasswordInput data-cy="confirm-pass-field" label={t("Profile.form.confirmPassword")} name="confirm" />
        <Divider />
        <SubmitButton data-cy="confirm-pass-change-btn" label={t("Profile.action.changePassword")} />
      </Grid>
    </Form>
  );
}

export function PasswordUpdateContainer(): ReactElement {
  const { refresh } = useProfileContext();
  const snackbar = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestPasswordUpdate,
        () => {
          snackbar("success", t("Profile.display.passwordChangeSuccess"), { "data-cy": "password-change-success" });
          navigate(pathname);
          refresh();
        },
        () => {
          snackbar("error", t("Profile.display.passwordChangeError"));
          refresh();
        }
      ),
    [navigate, pathname, refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <InformationDialog title={t("Profile.action.changePassword")} width="xs">
        <PasswordUpdateForm />
      </InformationDialog>
    </DataProvider>
  );
}
