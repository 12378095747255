import {
  mdiAccountCancel,
  mdiAccountClock,
  mdiAccountMultipleOutline,
  mdiAlert,
  mdiAmbulance,
  mdiCancel,
  mdiCar,
  mdiCheck,
  mdiCheckAll,
  mdiCheckboxBlankCircle,
  mdiClockTimeFourOutline,
  mdiCrown,
  mdiHumanGreetingVariant,
  mdiLink,
  mdiLock,
  mdiShuffleVariant,
  mdiSteering,
  mdiTimerSandFull,
} from "@mdi/js";
import { iconPersonPending } from "@sinch/assets/img/customIcons";
import { useBusinessRules } from "@sinch/core";
import { PositionQ, sAnd, selectPosition, selectShift, sNot, sOr, WorkerRole } from "@sinch/entity";
import { Format, t, useFormat } from "@sinch/intl";
import { Animate, B, Icon, Identifier, P, routerLink, StructuredText, Text } from "@sinch/ui";
import { addHours, isFuture, isPast, min } from "date-fns";
import { F, head, includes, map } from "ramda";
import React from "react";
import { PositionReference } from "./PositionReference";

/*
 * todo: review naming of individual statuses and group
 *  related ones into properly defined categories
 *
 * todo: generate unique keys for components from names
 *  - consider exporting as object instead of an array,
 *    status ids can be then auto generated from keys
 *  - prefix keys by application/plugin and entity
 *    `const ID_PREFIX = "core/position/"`
 *
 * todo: implement collapsible groups
 */

const isRoleCrewboss = {
  condition: sAnd(PositionQ.isStatusOpen, PositionQ.isRoleCrewboss, sNot(PositionQ.User.isAssigned)),
  resultSelector: () => {
    const { crewbossName: crewboss } = useBusinessRules();
    return {
      icon: mdiCrown,
      color: "colorCrewboss",
      content: t("Position.display.crewbossPosition", { crewboss }),
      priority: 31,
    };
  },
};

const isStatusRunning = {
  condition: PositionQ.isStatusRunning,
  dataSelector: selectPosition,
  resultSelector: ({ shift, id }, { selectEntity, queryEntity }) => {
    const canOnSitePresence = selectEntity(selectShift(shift, "presence"));
    const isCrewboss = queryEntity(PositionQ.User.isCrewboss(id));
    return {
      icon: (
        <Animate animation="pulse">
          <Icon color="error" icon={mdiCheckboxBlankCircle} />
        </Animate>
      ),
      color: "note",
      content: t("Position.display.takingPlaceNow"),
      action:
        canOnSitePresence && isCrewboss
          ? {
              action: routerLink(`/presence/${shift}`),
              btnProps: { variant: "outlined", color: "info", icon: mdiAccountClock },
              label: t("Shift.closing.recordAttendanceButton"),
            }
          : undefined,
      priority: 10,
    };
  },
};

const isStatusFinishedWorkerView = {
  condition: sAnd(PositionQ.isStatusFinished, sNot(PositionQ.User.isCrewboss)),
  resultSelector: () => {
    const { crewbossName: crewboss } = useBusinessRules();
    return {
      icon: mdiTimerSandFull,
      color: "info",
      content: t("Position.display.jobFinished", { crewboss }),
    };
  },
  priority: 40,
};

const isStatusCrewbossClosedWorkerView = {
  condition: sAnd(PositionQ.isStatusCrewbossClosed, sNot(PositionQ.User.isCrewboss)),
  resultSelector: () => {
    const { crewbossName: crewboss } = useBusinessRules();
    return {
      icon: mdiTimerSandFull,
      color: "info",
      content: t("Position.display.jobClosedByCrewboss", { crewboss }),
    };
  },
  priority: 40,
};

const isStatusSupervisorClosedWorkerView = {
  condition: sAnd(PositionQ.isStatusSupervisorClosed, sNot(PositionQ.User.isCrewboss)),
  resultSelector: () => ({
    icon: mdiCheckAll,
    color: "success",
    content: t("Position.display.jobCompletelyClosed"),
  }),
  priority: 40,
};

const isStatusFinishedCrewbossView = {
  condition: sAnd(PositionQ.isStatusFinished, PositionQ.User.isCrewboss, sNot(PositionQ.isPositionSelfClockIn)),
  dataSelector: selectPosition,
  resultSelector: ({ shift }, { selectEntity }) => {
    const { crewbossName: crewboss, shiftClosingTimeInterval } = useBusinessRules();
    const closeTimeout = addHours(min(selectEntity(selectPosition({ shift }, "startTime"))), shiftClosingTimeInterval);
    const dateBold = (
      <B>
        <Format dt={closeTimeout} variant="full" />
      </B>
    );
    return {
      icon: mdiTimerSandFull,
      color: "info",
      content: (
        <StructuredText>
          <P>{t("Position.position.shiftEnded")}</P>
          <P>
            {t("Position.display.crewbossCloseShift", {
              date: dateBold,
              crewboss,
            })}
          </P>
        </StructuredText>
      ),
      action: {
        action: routerLink(`/shift/${shift}/closing`),
        label: t("Shift.action.closeShift"),
        btnProps: { variant: "outlined", color: "info" },
      },
      priority: 40,
    };
  },
};

const isStatusCrewbossClosedCrewbossView = {
  condition: sAnd(PositionQ.isStatusCrewbossClosed, PositionQ.User.isCrewboss, sNot(PositionQ.isPositionSelfClockIn)),
  dataSelector: selectPosition,
  resultSelector: ({ shift }) => ({
    icon: mdiTimerSandFull,
    color: "info",
    content: <Text bold>{t("Position.display.crewbossClosedShift")}</Text>,
    action: {
      btnProps: { variant: "outlined", color: "info" },
      action: routerLink(`/shift/${shift}/closing`),
      label: t("action.edit"),
    },
  }),
  priority: 40,
};

const isStatusSupervisorClosedCrewbossView = {
  condition: sAnd(PositionQ.isStatusSupervisorClosed, PositionQ.User.isCrewboss, sNot(PositionQ.isPositionSelfClockIn)),
  resultSelector: () => ({
    icon: mdiCheckAll,
    color: "success",
    content: (
      <StructuredText>
        <P bold>{t("Position.display.completelyClosed")}</P>
        <P bold>{t("Position.display.crewbossCompletelyClosed")}</P>
      </StructuredText>
    ),
  }),
  priority: 40,
};

const hasConnectedPositions = {
  condition: sAnd(PositionQ.hasConnected, sNot(PositionQ.User.isAssigned), PositionQ.isStatusOpen),
  dataSelector: selectPosition,
  resultSelector: ({ connected }) => ({
    icon: mdiLink,
    color: "info",
    content: t("Position.connectedPositionsNotice", {
      count: connected.length,
    }),
    priority: 34,
    link: "#connected",
  }),
};

const isJoinableAsApplicant = {
  condition: sAnd(sNot(PositionQ.User.isAssigned), PositionQ.isStatusOpen),
  resultConditions: ({ applicantSignIn = [], signedAsApplicant = false, positionId }) =>
    includes(positionId, applicantSignIn) && !signedAsApplicant,
  dataSelector: selectPosition,
  resultSelector: () => ({
    icon: mdiHumanGreetingVariant,
    color: "colorApplicant",
    content: (
      <StructuredText>
        <P bold>{t("Position.applicant.availableNoticeTitle")}</P>
        {t("Position.applicant.availableNoticeDescription")}
      </StructuredText>
    ),
    priority: 34,
  }),
};

const isJoinedAsApplicant = {
  condition: PositionQ.isStatusOpen,
  resultConditions: ({ signedAsApplicant = false, isApplicantRequiredConfirmation = false }) =>
    signedAsApplicant && !isApplicantRequiredConfirmation,
  dataSelector: selectPosition,
  resultSelector: () => ({
    icon: mdiHumanGreetingVariant,
    color: "colorApplicant",
    content: (
      <StructuredText>
        <P bold>{t("Position.signedAsApplicantNoticeTitle")}</P>
        {t("Position.signedAsApplicantNoticeDescription")}
      </StructuredText>
    ),
    priority: 5,
  }),
};

const isRequestedConfirmationAsApplicant = {
  condition: PositionQ.isStatusOpen,
  resultConditions: ({ isApplicantRequiredConfirmation = false }) => isApplicantRequiredConfirmation,
  dataSelector: selectPosition,
  resultSelector: () => ({
    icon: mdiHumanGreetingVariant,
    color: "colorApplicant",
    content: (
      <StructuredText>
        <P bold>{t("Position.youHaveBeenSelectedAsApplicant")}</P>
      </StructuredText>
    ),
    priority: 5,
  }),
};

const hasConflictingPosition = {
  condition: sAnd(PositionQ.User.hasConflictingPosition, PositionQ.isStatusOpen),
  dataSelector: selectPosition,
  resultSelector: ({ conflicting: { position } }) =>
    map((conflict) => {
      const identifier = (
        <Text bold>
          <Identifier entity="position" id={conflict} long />
        </Text>
      );
      return {
        icon: mdiShuffleVariant,
        color: "error",
        content: (
          <Text>
            {t("Position.alreadySignUpAtSameTime", {
              positionIdentifier: identifier,
            })}
          </Text>
        ),
        action: {
          btnProps: { variant: "outlined", color: "info" },
          action: routerLink(`/position/${conflict}`),
          label: t("action.show"),
        },
        priority: 26.0,
      };
    }, position),
};

const hasConflictingAppointment = {
  condition: sAnd(PositionQ.User.hasConflictingAppointment, PositionQ.isStatusOpen),
  dataSelector: selectPosition,
  resultSelector: ({ conflicting: { appointment } }) =>
    map((conflict) => {
      const appointmentIdentifier = (
        <Text bold>
          <Identifier entity="appointment" id={conflict} long />
        </Text>
      );
      return {
        icon: mdiShuffleVariant,
        color: "error",
        content: (
          <StructuredText>
            <Text>
              {t("Position.alreadySignUpAppointmentAtSameTime", {
                appointmentIdentifier,
              })}
            </Text>
          </StructuredText>
        ),
        action: {
          btnProps: { variant: "outlined", color: "info" },
          action: routerLink(`/appointment/${conflict}`),
          label: t("action.show"),
        },
        priority: 26.1,
      };
    }, appointment),
};

const hasConflictingApplicants = {
  condition: sAnd(PositionQ.User.hasConflictingApplicants, PositionQ.isStatusOpen),
  resultConditions: ({ signedAsApplicant = false }) => !signedAsApplicant,
  dataSelector: selectPosition,
  resultSelector({ conflicting: { applicants } }) {
    return map((conflict) => {
      const identifier = (
        <Text bold>
          <Identifier entity="position" id={conflict} long />,
        </Text>
      );
      return {
        icon: mdiShuffleVariant,
        color: "error",
        content: (
          <Text>
            {t("Position.alreadySignUpAsApplicantAtSameTime", {
              positionIdentifier: identifier,
            })}
          </Text>
        ),
        action: {
          btnProps: { variant: "outlined", color: "info" },
          action: routerLink(`/position/${conflict}`),
          label: t("action.show"),
        },
        priority: 26.0,
      };
    }, applicants);
  },
};

const isLocked = {
  condition: PositionQ.isLocked,
  resultConditions: ({ applicantSignIn = [], positionId }) => !includes(positionId, applicantSignIn),
  resultSelector: () => ({
    icon: mdiLock,
    color: "neutral",
    content: t("Position.thisPositionLocked"),
    priority: 21,
  }),
};

const isCancelled = {
  condition: PositionQ.isCancelled,
  resultSelector: () => ({
    icon: mdiCancel,
    color: "error",
    content: t("Position.thisPositionCancelled"),
    priority: 20,
  }),
};

const isFull = {
  condition: sAnd(PositionQ.isStatusOpen, sNot(PositionQ.User.isAssigned), PositionQ.isFull),
  resultConditions: ({ applicantSignIn = [], positionId }) => !includes(positionId, applicantSignIn),
  resultSelector: () => ({
    icon: mdiAccountCancel,
    color: "neutral",
    content: t("Position.thisPositionFull"),
    priority: 22,
  }),
};

const hasRequirementsUnfulfilled = {
  condition: sAnd(
    sNot(PositionQ.User.hasRequirementsFulfilled),
    sNot(PositionQ.User.isAssigned),
    PositionQ.isStatusOpen
  ),
  resultConditions: ({ applicantSignIn = [], positionId }) => !includes(positionId, applicantSignIn),
  resultSelector: () => ({
    icon: mdiCancel,
    color: "error",
    content: t("Requirement.notMet"),
    priority: 23,
  }),
};

const hasConnectedUnavailable = {
  condition: sAnd(PositionQ.hasConnectedRequirementFailed, PositionQ.isStatusOpen, sNot(PositionQ.User.isAssigned)),
  resultSelector: () => ({
    icon: mdiCancel,
    color: "error",
    content: t("Position.connectedPositionNotAvailableToSign"),
    priority: 24,
  }),
};

const hasTransport = {
  condition: sAnd(PositionQ.isStatusOpen, PositionQ.hasTransport, sNot(PositionQ.User.isTransportDriver)),
  resultSelector: () => ({
    icon: mdiCar,
    color: "info",
    content: t("Position.hasTransport"),
    priority: 33,
  }),
};

const isAssignedDriver = {
  condition: sAnd(PositionQ.isStatusOpen, PositionQ.hasTransport, PositionQ.User.isTransportDriver),
  resultSelector: () => ({
    icon: mdiSteering,
    color: "info",
    content: t("Position.isDriver"),
    priority: 32,
  }),
};

const isAssigned = {
  condition: sAnd(
    PositionQ.User.isAssigned,
    sNot(PositionQ.User.isRequiredConfirmation),
    sOr(PositionQ.isStatusOpen, PositionQ.isStatusRunning)
  ),
  dataSelector: (positionId) => () => positionId,
  resultSelector: (positionId, { queryEntity, selectEntity }) => {
    const signOffTime = queryEntity(PositionQ.User.getSignOffTime(positionId));
    const locked = queryEntity(PositionQ.isLocked(positionId));
    const { role } = selectEntity(selectPosition(positionId));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { dt } = useFormat();
    const { crewbossName: crewboss } = useBusinessRules();
    const signOffDateTime = <B>{dt.full(signOffTime)}</B>;

    let roleVariant = {};
    switch (role) {
      case WorkerRole.Crewboss:
        roleVariant = {
          color: "colorCrewboss",
          icon: mdiCrown,
        };
        break;
      case WorkerRole.Backup:
        roleVariant = {
          color: "info",
          icon: mdiAccountMultipleOutline,
        };
        break;
      default:
        roleVariant = {
          color: "success",
          icon: mdiCheck,
        };
    }

    return {
      ...roleVariant,
      content: (
        <StructuredText>
          <P>
            {role === WorkerRole.Crewboss && t("Position.youAreCrewboss", { crewboss })}
            {role !== WorkerRole.Crewboss && t("Position.youAreSignUp")}
          </P>
          {role === WorkerRole.Backup && <P>{t("Position.display.backupPosition")}</P>}
          {!locked && isFuture(signOffTime) && <P>{t("Position.status.signOffDateTime", { signOffDateTime })}</P>}
          {!locked && isPast(signOffTime) && <P>{t("Position.status.noPossibleToSignOut")}</P>}
        </StructuredText>
      ),
      priority: 20,
      link: "#assignedForm",
    };
  },
};

const otherPositionAssigned = {
  condition: sAnd(PositionQ.isStatusOpen, PositionQ.User.isAssignedToOther, sNot(PositionQ.User.isAssigned)),
  dataSelector: PositionQ.User.getAssignedPositions,
  resultSelector: (assigned) => ({
    icon: mdiAlert,
    color: "warning",
    content: (
      <Text>
        {t("Position.youAreAlreadySingUp")}
        <B>
          <PositionReference id={head(assigned)} prof role title />
        </B>
      </Text>
    ),
    action: {
      btnProps: { variant: "outlined", color: "info" },
      action: routerLink(`/position/${assigned}`),
      label: t("action.show"),
    },
    priority: 25,
  }),
};

const hasAttendanceLate = {
  condition: PositionQ.User.hasAttendanceLate,
  resultSelector: () => ({
    icon: mdiClockTimeFourOutline,
    color: "warning",
    content: t("Position.youAreLate"),
  }),
  priority: 11,
};

const hasAttendanceAbsent = {
  condition: PositionQ.User.hasAttendanceAbsent,
  resultSelector: () => ({
    icon: mdiCancel,
    color: "error",
    content: t("Position.youMissAPosition"),
  }),
  priority: 12,
};

const hasAttendanceExcused = {
  condition: PositionQ.User.hasAttendanceExcused,
  resultSelector: () => ({
    icon: mdiAmbulance,
    color: "info",
    content: t("Position.youAreExcused"),
    priority: 13,
  }),
};

/**
 * todo: group status for all reasons user cannot join the position
 *  - is full
 *  - is locked
 *  - requirements unfulfilled
 *  - connected not available
 *  - user assigned to other
 *  - user assigned to conflicting
 */
const notAvailableForUser = {
  condition: () => F,
  resultSelector: () => ({
    icon: mdiCancel,
    color: "error",
    content: t("Position.youCannotSignUp"),
    action: {
      btnProps: { variant: "outlined", color: "info" },
      action: routerLink(""),
      label: t("detail").toUpperCase(),
    },
    priority: 7,
  }),
};

const isRequiredConfirmation = {
  condition: PositionQ.User.isRequiredConfirmation,
  resultSelector: () => ({
    icon: iconPersonPending,
    color: "warning",
    content: t("Position.youNeedConfirmAttendance"),
    priority: 13,
  }),
};

export const positionStatusDefinitionsNew = [
  isRequiredConfirmation,
  isStatusRunning,
  isStatusFinishedWorkerView,
  isStatusCrewbossClosedWorkerView,
  isStatusSupervisorClosedWorkerView,
  isStatusFinishedCrewbossView,
  isStatusCrewbossClosedCrewbossView,
  isStatusSupervisorClosedCrewbossView,
  isAssigned,
  isJoinedAsApplicant,
  isRequestedConfirmationAsApplicant,
  isAssignedDriver,
  hasAttendanceLate,
  hasAttendanceAbsent,
  hasAttendanceExcused,
  notAvailableForUser,

  isFull,
  isLocked,
  isCancelled,
  hasRequirementsUnfulfilled,
  hasConnectedUnavailable,
  hasConflictingPosition,
  hasConflictingAppointment,
  hasConflictingApplicants,
  otherPositionAssigned,
  isJoinableAsApplicant,
  isRoleCrewboss,
  hasConnectedPositions,
  hasTransport,
];
