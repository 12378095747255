/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { DataTableDisplay, DataTableHeaderProps } from "./types";

export function DataTableHeader<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>({ table }: DataTableHeaderProps<TData, TColumn, TDisplay>): ReactElement {
  const { config, columns, display } = table;
  const { Head, Row, Cell } = display;

  return (
    <Head>
      <Row>
        {columns.map((column) => (
          <Cell key={column} {...(config[column].titleCellProps || {})}>
            {config[column].title}
          </Cell>
        ))}
      </Row>
    </Head>
  );
}
