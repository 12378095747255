import { IconId, Key } from "@sinch/types";

export enum IndicatorView {
  Desktop,
  Mobile,
  Any,
}

export interface MenuDivider {
  divider: true;
}

export interface MenuItemBase {
  icon?: IconId;

  id: Key;

  name: string;

  indicator?: IndicatorView;
}

export interface MenuLinkItem extends MenuItemBase {
  /**
   * External link, opens in new tab.
   */
  url: string;
}

export interface MenuPageItem extends MenuItemBase {
  /**
   * Internal page link, resolved as route item.
   */
  page: string;
}

export interface MenuRouteItem extends MenuItemBase {
  /**
   * Internal route path.
   */
  path: string;
}

export type MenuNavigationItem = MenuLinkItem | MenuPageItem | MenuRouteItem;

export interface MenuSectionItem extends MenuItemBase {
  /**
   * Child menu item tree.
   */
  menu: MenuNavigationItem[];
}

export type MenuItem = MenuDivider | MenuLinkItem | MenuPageItem | MenuRouteItem | MenuSectionItem;

export const isDivider = (item: MenuItem): item is MenuDivider => "divider" in item;

export const isLinkItem = (item: MenuItem): item is MenuLinkItem => "url" in item;

export const isPageItem = (item: MenuItem): item is MenuPageItem => "page" in item;

export const isRouteItem = (item: MenuItem): item is MenuRouteItem => "path" in item;

export const isSectionItem = (item: MenuItem): item is MenuSectionItem => "menu" in item;
