/* eslint-disable react/jsx-props-no-spreading */
import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormat, useIntl } from "@sinch/intl";
import clsx from "clsx";
import { set as dateSet } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { omit } from "ramda";
import { isNull } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { DateInputBase } from "../DateInput";
import { DateTimeInputNative } from "./DateTimeInputNative";

const useStyles = makeStyles((theme) => ({
  inputLeft: {
    borderRight: `1px solid ${theme.overrides?.MuiOutlinedInput?.notchedOutline?.borderColor || "rgba(0, 0, 0, 0.23)"}`,
    "&.Mui-error": {
      borderRightColor: theme.palette.error.main,
    },
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  inputLeftDimmed: {
    "& .MuiInputBase-input": {
      color: theme.palette.grey[500],
    },
  },
}));

export interface DateInputPartProps {
  name: string;
  value?: Date;
  onChange: (newDate: Date | null) => void;
  useNative?: boolean;
  error?: boolean;
  minDate?: Date;
  dimmed?: boolean;
}

/**
 * Date part of SelectDateTimeInput
 */
export function DateInputPart({
  name,
  value,
  onChange,
  useNative,
  error,
  minDate,
  dimmed = true,
  inputProps,
}: DateInputPartProps): ReactElement {
  const { dt } = useFormat();
  const classes = useStyles();
  const { timeZone } = useIntl();

  return useNative ? (
    <DateTimeInputNative
      className={clsx(dimmed && classes.inputLeftDimmed, classes.inputLeft)}
      error={error}
      minDate={minDate}
      name={`${name}.date`}
      onChange={(date) => {
        if (date) {
          let zoned = utcToZonedTime(value || new Date(), timeZone);
          zoned = dateSet(zoned, {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
          });
          onChange(zonedTimeToUtc(zoned, timeZone));
        }
      }}
      type="date"
      value={value}
    />
  ) : (
    <DateInputBase
      adornment={false}
      className={clsx(dimmed && classes.inputLeftDimmed, classes.inputLeft)}
      dense
      error={error}
      format={undefined}
      labelFunc={(date) => {
        if (isNull(date)) {
          return "";
        }
        return dt.chip(zonedTimeToUtc(date || new Date(), timeZone));
      }}
      minDate={minDate}
      name={`${name}.date`}
      onChange={(date) => {
        if (date) {
          let zoned = utcToZonedTime(value || new Date(), timeZone);
          zoned = dateSet(zoned, {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
          });
          onChange(zonedTimeToUtc(zoned, timeZone));
        } else if (isNull(date)) {
          onChange(null);
        }
      }}
      TextFieldComponent={({ InputProps, ...props }) => (
        // @ts-ignore
        <InputBase
          {...omit(["helperText"], props)}
          {...InputProps}
          classes={{
            input: "MuiOutlinedInput-inputMarginDense MuiOutlinedInput-input",
          }}
          inputProps={{ ...(props.inputProps ?? {}), ...inputProps }}
        />
      )}
      value={value ? utcToZonedTime(value || new Date(), timeZone) : null}
    />
  );
}
