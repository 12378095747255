import { mdiEmoticonHappyOutline, mdiEmoticonNeutralOutline, mdiEmoticonFrownOutline } from "@mdi/js";
import { FeedbackType } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Color, IconId, SelectFieldOption } from "@sinch/types";
import { map } from "ramda";

export const getFeedbackTypeDisplayProps = (): {
  [feedback: string]: SelectFieldOption;
} => ({
  [FeedbackType.Positive]: {
    color: "success",
    icon: mdiEmoticonHappyOutline,
    label: t("Feedback.positive"),
    value: FeedbackType.Positive,
  },
  [FeedbackType.Neutral]: {
    color: "default",
    icon: mdiEmoticonNeutralOutline,
    label: t("Feedback.neutral"),
    value: FeedbackType.Neutral,
  },
  [FeedbackType.Negative]: {
    color: "error",
    icon: mdiEmoticonFrownOutline,
    label: t("Feedback.negative"),
    value: FeedbackType.Negative,
  },
});

export const getFeedbackSelectOptions = () =>
  map((type: FeedbackType) => getFeedbackTypeDisplayProps()[type], [
    FeedbackType.Positive,
    FeedbackType.Neutral,
    FeedbackType.Negative,
  ]);

export const feedbackColor = (type: FeedbackType): Color => getFeedbackTypeDisplayProps()[type].color;

export const feedbackIcon = (type: FeedbackType): IconId => getFeedbackTypeDisplayProps()[type].icon;

export const feedbackLabel = (type: FeedbackType): string => getFeedbackTypeDisplayProps()[type].label;
