import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import {
  mdiAlert,
  mdiAlertCircle,
  mdiCalendarToday,
  mdiCar,
  mdiCheck,
  mdiClockOutline,
  mdiCrown,
  mdiDomain,
  mdiHumanGreetingVariant,
  mdiMapMarker,
  mdiTimerSandEmpty,
} from "@mdi/js";

/* eslint-disable react/jsx-props-no-spreading */
import { QueryEntity, ResponseOf, SelectEntity, useBusinessRules, useData } from "@sinch/core";
import {
  ContactType,
  Fk,
  Position,
  PositionQ,
  PositionStatus,
  selectAppointment,
  selectLocation,
  selectPosition,
  selectShift,
  selectUser,
  WorkerRole,
} from "@sinch/entity";
import { Format, t } from "@sinch/intl";
import { BiFunc, Color, IconId, Maybe } from "@sinch/types";
import {
  Action,
  Card,
  Center,
  DataGrid,
  DataGridItem,
  DataGridItemProps,
  DateChip,
  Grid,
  Identifier,
  Text,
  TimeRange,
  useMobileLayout,
  useSpacing,
  WrapWords,
} from "@sinch/ui";
import { isArray } from "@sinch/utils";
import { isSameDay, subMinutes } from "date-fns";
import {
  always,
  both,
  cond,
  find,
  flatten,
  has,
  head,
  ifElse,
  includes,
  isEmpty,
  pipe,
  pluck,
  prop,
  propEq,
  props,
  sortBy,
  toUpper,
  values,
} from "ramda";
import React, { ReactChild, ReactElement, ReactNode } from "react";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "./api";

export type PositionCardField =
  | "status"
  | "role"
  | "contact"
  | "date"
  | "location"
  | "time"
  | "transport"
  | "applicants"
  | "conflict";

export type PositionCardListSelector<T> = BiFunc<
  QueryEntity<ResponseOf<typeof requestDashboardAttendanceList>>,
  Fk<Position>,
  Maybe<T>
>;

type AttendanceListSelectEntity = SelectEntity<ResponseOf<typeof requestDashboardAttendanceList>>;

function getStatus(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  if (selectEntity(PositionQ.isStatusCrewbossClosed(position))) {
    return {
      icon: mdiCheck,
      color: "success",
      content: toUpper(t("Position.status.closed")),
    };
  }
  if (selectEntity(PositionQ.isStatusUnclosedLate(position))) {
    return {
      icon: mdiTimerSandEmpty,
      color: "error",
      content: toUpper(t("Position.status.closedLate")),
    };
  }
  return {
    icon: mdiAlertCircle,
    color: "warning",
    content: toUpper(t("Position.status.notClosed")),
  };
}

function getDate(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const startTime = selectEntity(selectPosition(position, "startTime"));

  return {
    icon: mdiCalendarToday,
    content: <Format dt={startTime} />,
  };
}

function getTime(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const { startTime, endTime, meetingTimeInterval, status } = selectEntity(selectPosition(position));

  const meetingTime = subMinutes(startTime, meetingTimeInterval);

  return {
    icon: mdiClockOutline,
    content: [
      ...(status < PositionStatus.Finished
        ? [
            <Text>
              {t("meeting")}
              <Format dt={meetingTime} variant="time" />
            </Text>,
          ]
        : []),
      <Text>
        {t("work")}
        <TimeRange endTime={endTime} showLength startTime={startTime} />
      </Text>,
    ],
  };
}

function getLocation(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const location = selectEntity(selectPosition(position, "location"));
  const { address } = selectEntity(selectLocation(location));

  return {
    icon: mdiMapMarker,
    content: [<WrapWords>{address}</WrapWords>],
  };
}

/**
 * todo: can Position have no contact?
 *  -> simplify code if there will always be at least one
 */
function getContact(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const contacts = selectEntity(selectPosition(position, "contacts"));

  const getDisplayContact = ifElse(
    has("userId"),
    pipe(
      // contact is User reference
      prop("userId"),
      /* @ts-expect-error */
      selectUser,
      selectEntity,
      props(["name", "phone"])
    ),
    // contact is just plain text
    props(["name", "phone"])
  );

  const hasCrewboss = find(propEq("type", ContactType.Crewboss));

  return {
    icon: hasCrewboss(contacts || []) ? mdiCrown : mdiDomain,
    content:
      isArray(contacts) && contacts.length > 0
        ? flatten(contacts.map((contact) => getDisplayContact(contact)))
        : t<string>("Position.noContact"),
  };
}

/**
 * todo: hide label completely when no transport?
 */
function getTransport(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps | false {
  const shift = selectEntity(selectPosition(position, "shift"));
  const transport = selectEntity(selectShift(shift, "transport"));

  if (!transport || (!transport.from && !transport.to)) return false;

  const label = cond([
    [both(has("to"), has("from")), always(t("Transport.thereAndBack"))], // Transport there and back
    [has("to"), always(t("Transport.there"))], // Transport there
    [has("from"), always(t("Transport.back"))], // Transport back
    // [T, always(t("Transport.noTransport"))],
  ]);

  return {
    icon: mdiCar,
    content: label(transport),
  };
}

function getApplicant(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps | false {
  const applicants = selectEntity(selectPosition(position, "applicants"));

  if (!applicants) return false;

  return {
    color: "colorApplicant",
    icon: mdiHumanGreetingVariant,
    content: t<string>("Position.signedAsApplicantNoticeTitle"),
  };
}

function getRole(
  selectEntity: AttendanceListSelectEntity,
  position: Fk<Position>,
  crewbossName: string
): DataGridItemProps | false {
  const role = selectEntity(selectPosition(position, "role"));

  if (role === WorkerRole.Crewboss) {
    return {
      color: "colorCrewboss",
      icon: mdiCrown,
      content: t<string>("Position.youAreCrewboss", { crewboss: crewbossName }),
    };
  }
  return false;
}

function getConflict(selectEntity: AttendanceListSelectEntity, position: Fk<Position>) {
  const inConflict = selectEntity(selectPosition(position, "inConflict"));

  if (!inConflict) {
    return {};
  }
  const conflicting = selectEntity(selectPosition(position, "conflicting"));
  const { position: conflictedPositions, appointment: conflictedAppointments } = conflicting;
  const appointmentId = conflictedAppointments ? head(conflictedAppointments) : null;

  const hasConflictingPosition = conflictedPositions && !isEmpty(conflictedPositions);
  const conflictingItem = (
    <>
      {hasConflictingPosition && <PositionReference id={head(conflictedPositions)} short />}
      {!hasConflictingPosition && conflictedAppointments && !isEmpty(conflictedAppointments) && (
        <>
          <Identifier entity="appointment" id={appointmentId} />{" "}
          {selectEntity(selectAppointment(appointmentId, "name"))}
        </>
      )}
    </>
  );

  return {
    color: "warning",
    icon: mdiAlert,
    content: [
      <>
        {`${t("Position.display.conflictingJob")} `}
        {conflictingItem}
      </>,
    ],
  };
}

interface PositionCardContentProps {
  position: Fk<Position>;

  fields: PositionCardField[];
}

function PositionCardContent({ position, fields }: PositionCardContentProps): ReactElement {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { confirmationApplicantPositionIds } = selectResult();
  const [, , data] = useSpacing();
  const { crewbossName } = useBusinessRules();

  const mobile = useMobileLayout();

  const isApplicantConfirmationRequired = includes(position, flatten(values(confirmationApplicantPositionIds)));

  return (
    <>
      {fields.includes("conflict") && (
        <Box mb={mobile ? 0 : data}>
          <DataGrid data={[getConflict(selectEntity, position)]} small />
        </Box>
      )}
      <MuiGrid container spacing={data}>
        <MuiGrid container direction="column" item md={4} spacing={data} xs={12}>
          {fields.includes("status") && (
            <MuiGrid item>
              <DataGridItem {...getStatus(selectEntity, position)} />
            </MuiGrid>
          )}
          {isApplicantConfirmationRequired && (
            <MuiGrid item>
              <DataGridItem
                color="colorApplicant"
                content={t("Position.youHaveBeenSelectedAsApplicant")}
                icon={mdiHumanGreetingVariant}
              />
            </MuiGrid>
          )}
          {!isApplicantConfirmationRequired && fields.includes("applicants") && getApplicant(selectEntity, position) && (
            <MuiGrid item>
              <DataGridItem {...getApplicant(selectEntity, position)} />
            </MuiGrid>
          )}
          {fields.includes("role") &&
            !(fields.includes("applicants") && mobile && getApplicant(selectEntity, position)) && (
              <MuiGrid item>
                <DataGridItem {...getRole(selectEntity, position, crewbossName)} />
              </MuiGrid>
            )}
          {fields.includes("date") && (
            <MuiGrid item>
              <DataGridItem {...getDate(selectEntity, position)} />
            </MuiGrid>
          )}
          {fields.includes("time") && (
            <MuiGrid item>
              <DataGridItem {...getTime(selectEntity, position)} />
            </MuiGrid>
          )}
        </MuiGrid>
        <MuiGrid container direction="column" item md={4} spacing={data} xs={12}>
          {fields.includes("location") && (
            <MuiGrid item>
              <DataGridItem {...getLocation(selectEntity, position)} />
            </MuiGrid>
          )}
          {fields.includes("contact") && (
            <MuiGrid item>
              <DataGridItem {...getContact(selectEntity, position)} />
            </MuiGrid>
          )}
        </MuiGrid>
        <MuiGrid container direction="column" item md={4} spacing={data} xs={12}>
          {fields.includes("transport") && getTransport(selectEntity, position) && (
            <MuiGrid item>
              <DataGridItem {...getTransport(selectEntity, position)} />
            </MuiGrid>
          )}
        </MuiGrid>
      </MuiGrid>
    </>
  );
}

function showChip(
  selectEntity: AttendanceListSelectEntity,
  [current, previous]: [Fk<Position>, Maybe<Fk<Position>>]
): boolean {
  if (!previous) return true;

  const [startTime, previousStartTime] = selectEntity(selectPosition([current, previous], "startTime"));

  return !isSameDay(startTime, previousStartTime);
}

function getTitle(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): ReactNode {
  return <PositionReference id={position} name noId title />;
}

interface PositionCardListProps {
  actionSelector?: PositionCardListSelector<Action>;

  colorSelector?: PositionCardListSelector<Color>;

  fields: PositionCardField[];

  iconSelector?: PositionCardListSelector<IconId | ReactChild>;

  labelSelector?: PositionCardListSelector<string | ReactChild>;

  positions?: Fk<Position>[];

  textColorSelector?: PositionCardListSelector<Color>;

  buttonsSelector?: PositionCardListSelector<ReactNode>;
}

export function PositionCardList({
  actionSelector,
  colorSelector,
  fields,
  iconSelector,
  labelSelector,
  positions,
  textColorSelector,
  buttonsSelector,
}: PositionCardListProps): ReactElement {
  const mobile = useMobileLayout();

  const { selectEntity } = useData(requestDashboardAttendanceList);

  const positionsList = selectEntity(selectPosition(positions));
  const sortedPositions: number[] = pipe<Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    pluck("id")
  )(positionsList);

  return (
    <Grid spacing="inner" vertical>
      {sortedPositions.map((position, i) => [
        showChip(selectEntity, [sortedPositions[i], sortedPositions[i - 1]]) && (
          <Center>
            <DateChip color="primary" date={selectEntity(selectPosition(position, "startTime"))} large />
          </Center>
        ),
        <Card
          action={actionSelector?.(selectEntity, position)}
          color={colorSelector?.(selectEntity, position)}
          icon={iconSelector?.(selectEntity, position)}
          label={labelSelector?.(selectEntity, position)}
          small={mobile}
          textColor={textColorSelector?.(selectEntity, position)}
          title={getTitle(selectEntity, position)}
        >
          <PositionCardContent fields={fields} position={position} />
          {buttonsSelector?.(selectEntity, position)}
        </Card>,
      ])}
    </Grid>
  );
}
