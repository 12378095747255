import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { makeSearchQuery, useData, useSearchParamsCustom } from "@sinch/core";
import { selectPayoutMethod } from "@sinch/entity";
import { CheckboxInput, DateInput, Form, MultiSelectInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { useMobileLayout, useSpacing } from "@sinch/ui";
import { dateToUrl } from "@sinch/utils";
import { endOfDay, isValid, parseISO, startOfDay, subMonths } from "date-fns";
import { useFormikContext } from "formik";
import { equals, isNil, omit, pick, reject } from "ramda";
import { ensureArray, isString, isUndefined } from "ramda-adjunct";
import React, { ReactElement, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { requestWalletStatus, WalletPayoutListParams, WalletTransactionListParams } from "./api";
import { transactionTypes } from "./common";

const useStyles = makeStyles(({ palette }) => ({
  whiteInput: {
    backgroundColor: palette.background.paper,
  },
}));

export interface WalletSearchFormProps {
  entity: "transaction" | "payout";
  showArchivedCheckbox?: boolean;
}

/* eslint-disable-next-line consistent-return */
export function useWalletSearchParams(entity: WalletSearchFormProps["entity"]) {
  const { selectEntity } = useData(requestWalletStatus);

  if (equals("transaction", entity)) {
    return {
      placeholder: t("type"),
      name: "transactionType",
      options: transactionTypes(),
    };
  }

  if (equals("payout", entity)) {
    const methods = selectEntity(selectPayoutMethod({}));

    return {
      placeholder: t("Contract.title"),
      name: "payoutMethod",
      options: [{ name: t("Wallet.display.unassignedType"), id: -1 }, ...methods].map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    };
  }
}

export function useWalletSearchInitialParams(entity: "transaction"): WalletTransactionListParams;
export function useWalletSearchInitialParams(entity: "payout"): WalletPayoutListParams;
// eslint-disable-next-line consistent-return
export function useWalletSearchInitialParams(
  entity: WalletSearchFormProps["entity"]
): WalletTransactionListParams | WalletPayoutListParams | undefined {
  if (equals("transaction", entity)) {
    return {
      transactionType: [],
      from: startOfDay(subMonths(new Date(), 1)),
      to: endOfDay(new Date()),
      includeArchived: false,
    };
  }

  if (equals("payout", entity)) {
    return {
      payoutMethod: [],
      from: startOfDay(subMonths(new Date(), 12)),
      to: endOfDay(new Date()),
    };
  }
}

export function WalletSearchForm({ entity, showArchivedCheckbox }: WalletSearchFormProps): ReactElement {
  const navigate = useNavigate();

  const initialTransactionValue = useWalletSearchInitialParams("transaction");
  const initialPayoutValue = useWalletSearchInitialParams("payout");

  const defaultDateRange = pick(["from", "to"], useWalletSearchInitialParams(entity));

  const { searchParams } = useSearchParamsCustom();

  const initialValues = {
    ...initialTransactionValue,
    ...initialPayoutValue,
    ...defaultDateRange,
    ...omit(["page", "limit"], searchParams),
    payoutMethod: !isNil(searchParams.payoutMethod) ? ensureArray(searchParams.payoutMethod) : [],
    transactionType: !isNil(searchParams.transactionType) ? ensureArray(searchParams.transactionType) : [],
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      submitHandler={(values) => {
        const { from, to, transactionType, includeArchived, payoutMethod } = values;
        navigate({
          pathname: `/wallet/${entity}`,
          search: makeSearchQuery(
            reject(isUndefined)({
              from: dateToUrl(startOfDay(isString(from) && isValid(new Date(from)) ? parseISO(from) : from)),
              to: dateToUrl(endOfDay(isString(to) && isValid(new Date(from)) ? parseISO(to) : to)),
              transactionType,
              payoutMethod,
              includeArchived,
            })
          ),
        });
      }}
    >
      <FormContent entity={entity} showArchivedCheckbox={showArchivedCheckbox} />
    </Form>
  );
}

function usePrevious(value: unknown) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current || value;
}

function FormContent({ entity, showArchivedCheckbox }: WalletSearchFormProps): ReactElement {
  const mobile = useMobileLayout();
  const [, , data] = useSpacing();
  const styles = useStyles();

  const selectTypeProps = useWalletSearchParams("transaction");
  const selectContractProps = useWalletSearchParams("payout");

  const { values, submitForm } = useFormikContext();
  const previoustValues = usePrevious(values);
  useEffect(() => {
    if (!equals(previoustValues, values)) {
      submitForm();
    }
  }, [values]);

  return (
    <MuiGrid container direction="column" spacing={mobile ? 0 : data}>
      <MuiGrid alignItems="stretch" container item spacing={mobile ? 0 : data}>
        <MuiGrid item md={6} xs={12}>
          <MuiGrid container spacing={data}>
            <MuiGrid item xs>
              <DateInput className={styles.whiteInput} correctTimezone dense name="from" />
            </MuiGrid>
            <MuiGrid item xs>
              <DateInput className={styles.whiteInput} correctTimezone dense name="to" />
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item md={6} xs={12}>
          <MuiGrid container spacing={data}>
            {entity === "transaction" && (
              <MuiGrid item xs>
                <MultiSelectInput className={styles.whiteInput} {...selectTypeProps} dense />
              </MuiGrid>
            )}
            <MuiGrid item xs>
              <MultiSelectInput className={styles.whiteInput} {...selectContractProps} dense />
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>

      {showArchivedCheckbox && (
        <MuiGrid item>
          <Box pb={data} px={data}>
            <CheckboxInput dense label={t("wallet.filter.showArchived")} name="includeArchived" />
          </Box>
        </MuiGrid>
      )}
    </MuiGrid>
  );
}
