import { defineRequest } from "@sinch/core";
import {
  Fk,
  PayoutContainer,
  PayoutDistribution,
  PayoutMethod,
  PayoutMethodContainer,
  WalletTransactionContainer,
} from "@sinch/entity";

export const requestWalletStatus = defineRequest("Wallets/Status")
  .withEmptyParams()
  .withResponse<{
    availablePayoutMethodIds: Fk<PayoutMethod>[];
    balance: number;
    totalIncome: number;
    payoutDistribution: PayoutDistribution[];
  }>()
  .withResponseEntity<PayoutContainer & PayoutMethodContainer & WalletTransactionContainer>()
  .withDefaultCreator();
