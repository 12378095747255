import React, { ReactElement } from "react";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputTextProps } from "./InternalInput";

interface SearchInputProps
  extends Omit<InternalInputTextProps, "multiline" | "type"> {}

export function SearchInput(props: SearchInputProps): ReactElement {
  const [baseProps] = useFormField<string, SearchInputProps>(props);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <SearchInputBase {...baseProps} />;
}

export function SearchInputBase(
  props: BaseInputProps<string, SearchInputProps>
): ReactElement {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <InternalInput type="search" {...props} />;
}
