import { InputBase, MenuItem, Select, SelectProps } from "@material-ui/core";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import { IconId } from "@sinch/types";
import { find, propEq } from "ramda";
import React, { ChangeEvent, ReactElement } from "react";
import { Icon } from "../Icon";

interface MenuSelectProps<T> {
  options: { value: T; text: string; icon: IconId }[];
  defaultValue?: T;
  renderValueIcon?: boolean;
  renderValueText?: boolean;
  onChange?: (value: T) => void;
  SelectDisplayProps: SelectProps["SelectDisplayProps"];
}

export function MenuSelect<T extends string | number>({
  options,
  defaultValue,
  renderValueIcon = true,
  renderValueText = true,
  onChange,
  SelectDisplayProps,
}: MenuSelectProps<T>): ReactElement {
  return (
    <Select
      defaultValue={defaultValue}
      input={<InputBase />}
      // @ts-expect-error
      onChange={(event: ChangeEvent<{ value: T }>) => onChange && onChange(event.target.value)}
      renderValue={(value) => {
        const option = find(propEq("value", value))(options);
        if (!option) {
          return undefined;
        }
        // @ts-expect-error
        const { text, icon } = option;
        return (
          <>
            {renderValueIcon && icon && <Icon icon={icon} />}
            {renderValueText && text && <>{text}</>}
          </>
        );
      }}
      SelectDisplayProps={SelectDisplayProps}
    >
      {options.map(({ value, text, icon, ...optionProps }) => (
        <MenuItem key={value} value={value}>
          {icon && (
            <MuiListItemIcon>
              <Icon icon={icon} />
            </MuiListItemIcon>
          )}
          {text && <MuiListItemText primaryTypographyProps={{ ...optionProps }}>{text}</MuiListItemText>}
        </MenuItem>
      ))}
    </Select>
  );
}
