import { Box } from "@material-ui/core";
import { useData, useIntParams } from "@sinch/core";
import {
  ClockInState,
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { ToggleButtonProps, ToggleButtonSwitches } from "@sinch/ui";
import {
  countBy,
  groupBy,
  includes,
  isEmpty,
  join,
  map,
  mergeRight,
  pick,
  pipe,
  pluck,
  sum,
  toLower,
  values,
} from "ramda";
import React, { ReactElement, useState } from "react";
import { useLocation } from "react-router";
import { requestPresenceView } from "../../api";
import { ShiftHeader, TeamSizeOverview, usePresenceToggleButtons } from "../../components";
import { useSelectionSet } from "../../context";
import { useClosingStatusProps } from "../../hooks";
import { FilterInput } from "../FilterInput";
import { FinishedShiftStrip } from "./FinishedShiftStrip";
import { PresenceCardList } from "./PresenceCardList";
import { SelectionMenu } from "./SelectionMenu";

export function WorkPresenceList(): ReactElement {
  const { selectEntity } = useData(requestPresenceView);
  const { hash } = useLocation();
  const { getLabel } = useClosingStatusProps();

  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<ClosingProgress>(+hash?.substr(1) || ClosingProgress.Pending);

  const { shift } = useIntParams();

  const positionAttendanceEntities = selectEntity(selectPositionAttendance({}));
  const positionEntities = selectEntity(selectPosition({}));

  const filteredAttendance = positionAttendanceEntities.filter(({ worker, progressStatus }) => {
    const { name, phone } = selectEntity(selectWorker(worker));
    return (
      (progressStatus === ClosingProgress.AfterBreak ? ClosingProgress.Present : progressStatus) === filter &&
      (includes(toLower(search || ""), toLower(name)) || includes(toLower(search || ""), toLower(phone ?? "")))
    );
  });

  const attendanceIds = pluck("id", filteredAttendance);
  const { selected, setSelected } = useSelectionSet<Fk<PositionAttendance>>(attendanceIds);
  const selectMode = !isEmpty(selected);

  const statusCounts = pipe(
    groupBy(({ progressStatus }: PositionAttendance) =>
      progressStatus === ClosingProgress.AfterBreak ? ClosingProgress.Present : progressStatus
    ),
    map(
      pipe(
        countBy(({ position }) => {
          const role = selectEntity(selectPosition(position, "role"));
          return role === WorkerRole.Backup;
        }),
        mergeRight({ false: 0 }),
        values,
        join("+")
      )
    )
  )(positionAttendanceEntities);

  const handleFilter = (item: ClosingProgress) => {
    setSelected([]);
    setFilter(item);
  };
  const items: ToggleButtonProps<ClosingProgress>[] = usePresenceToggleButtons({ statusCounts });

  const selectedLabel = getLabel(filter);

  const canClose =
    positionEntities.every(({ clockInState }) => clockInState === ClockInState.Off) &&
    sum(
      values(
        pick(
          [
            ClosingProgress.Pending,
            ClosingProgress.Break,
            ClosingProgress.AfterBreak,
            ClosingProgress.Late,
            ClosingProgress.Present,
          ],
          statusCounts
        )
      )
    ) === 0;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box mb={1}>
        <ShiftHeader shift={shift} />
      </Box>
      {canClose && <FinishedShiftStrip shift={shift} />}
      <ToggleButtonSwitches fullWidth items={items} onSelect={handleFilter} value={filter} />
      <TeamSizeOverview shift={shift} />
      <FilterInput onChange={setSearch} value={search} />
      <PresenceCardList attendances={filteredAttendance} presenceStatus={filter} />
      {isEmpty(filteredAttendance) && (
        <Box alignItems="center" display="flex" flex={1} justifyContent="center" py={2}>
          {t("Shift.closing.noWorkersInState", { state: selectedLabel })}
        </Box>
      )}
      {canClose && (
        <Box pt={1}>
          <FinishedShiftStrip shift={shift} />
        </Box>
      )}
      {selectMode && <SelectionMenu closingStatus={filter} />}
    </Box>
  );
}
