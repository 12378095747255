import { Transform, Updater } from "@sinch/types";
import { parseDate } from "@sinch/utils";
import { addMinutes, isAfter } from "date-fns/fp";
import { append, assoc, dissoc, evolve, filter, mapObjIndexed, propSatisfies, reduce, values } from "ramda";
import { propNotEq } from "ramda-adjunct";
import { FileInfo, FileUploadProgress } from "../contract";

/**
 * Identifier for files stored on backend.
 */
export type FileHash = string;

/**
 * Identifier for cached or enqueued local files.
 */
export type LocalFileKey = string;

export type FileId = FileHash | LocalFileKey;

export type FileUploadErrorHandler = (key: LocalFileKey, messages: string[]) => void;

export type FileUploadProgressHandler = (key: LocalFileKey, progress: FileUploadProgress) => void;

export type FileUploadSuccessHandler = (key: LocalFileKey, hash: FileHash) => void;

type CacheFileInfo = FileInfo & { expiration: Date };

type SerializedFileInfo = Omit<CacheFileInfo, "expiration"> & {
  expiration: string;
};

export type FileUploadHandlers = {
  onError?: FileUploadErrorHandler;
  onProgress?: FileUploadProgressHandler;
  onSuccess?: FileUploadSuccessHandler;
};

export type QueueFileParams = {
  file: File;
  handlers: FileUploadHandlers;
  target: string;
};

export type QueueFile = QueueFileParams & { key: LocalFileKey };

type UploadEnqueue = { type: "enqueue"; payload: QueueFileParams };

type UploadError = { type: "error"; payload: LocalFileKey };

type UploadPrune = { type: "prune"; payload?: Date };

type UploadRemove = { type: "remove"; payload: LocalFileKey };

type UploadStart = { type: "start" };

type UploadSuccess = {
  type: "success";
  payload: CacheFileInfo & { key: LocalFileKey };
};

type UploadAction = UploadEnqueue | UploadError | UploadPrune | UploadRemove | UploadStart | UploadSuccess;

type FileUploadCache = Record<FileId, CacheFileInfo>;

type BackendFileIndex = Record<LocalFileKey, FileHash>;

interface UploadState {
  /**
   * FileInfo indexed either by local file key (for files in uploading queue)
   * or backend hash (for already uploaded files).
   */
  cache: FileUploadCache;

  /**
   * Index translating cached files local key to backend hash.
   */
  index: BackendFileIndex;

  /**
   * Upload queue.
   */
  queue: QueueFile[];

  /**
   * Toggles processing of next file upload. Allows temporarily stopping
   * the upload when connection lost.
   */
  running: boolean;

  /**
   * Signals whether there is file upload in progress.
   */
  uploading: boolean;
}

const localStorageKey = "fileCache";

function persist(cache: FileUploadCache): void {
  try {
    window.localStorage.setItem(localStorageKey, JSON.stringify(cache));
  } catch (e) {
    console.warn(e);
  }
}

const parseExpiration = mapObjIndexed(
  evolve({
    expiration: parseDate,
  }) as Transform<SerializedFileInfo, CacheFileInfo>
);

function rehydrate(): FileUploadCache {
  try {
    const serial = window.localStorage.getItem(localStorageKey);
    return parseExpiration(serial ? JSON.parse(serial) : {});
  } catch (e) {
    return {};
  }
}

export const localFileKey = ({
  name,
  size,
  lastModified,
}: Pick<File, "name" | "size" | "lastModified">): LocalFileKey => `${name};${size};${lastModified}`;

const indexByLocalKey = (cache: FileUploadCache): BackendFileIndex =>
  reduce((acc, file) => assoc(localFileKey(file), file.hash, acc), {}, values(cache));

export const initUploadState = (): UploadState => {
  const cache = rehydrate();
  const state = {
    cache,
    index: indexByLocalKey(cache),
    queue: [],
    running: true,
    uploading: false,
  };

  return uploadReducer(state, { type: "prune" });
};

const queueExpiration = () => addMinutes(30, new Date());

function rejectExpired(date?: Date): Updater<FileUploadCache> {
  return (cache) => {
    const notExpired = isAfter(date ?? new Date());
    const predicate = propSatisfies(notExpired, "expiration");

    return filter(predicate, cache);
  };
}

type UploadReducer<TAction extends UploadAction> = (state: UploadState, action: TAction) => UploadState;

const enqueueReducer: UploadReducer<UploadEnqueue> = (state, { payload }) => {
  const { cache, queue } = state;
  const { file } = payload;
  const { name, size, type } = file;

  const fileInfo = {
    expiration: queueExpiration(),
    hash: localFileKey(file),
    name,
    size,
    type,
    url: URL.createObjectURL(file),
  };
  const { hash: key } = fileInfo;

  return {
    ...state,
    cache: assoc(key, fileInfo, cache),
    queue: append(assoc("key", key, payload), queue),
  };
};

const rejectKey = (key: LocalFileKey, list: QueueFile[]) => filter(propNotEq("key", key), list);

const errorReducer: UploadReducer<UploadError> = ({ cache, queue, ...state }, { payload: key }) => {
  URL.revokeObjectURL(cache[key]?.url);

  return {
    ...state,
    cache: dissoc(key, cache),
    queue: rejectKey(key, queue),
    uploading: false,
  };
};

const pruneReducer: UploadReducer<UploadPrune> = ({ cache, ...state }, { payload: date }) => {
  const nextCache = rejectExpired(date)(cache);
  persist(nextCache);

  return {
    ...state,
    cache: nextCache,
    index: indexByLocalKey(nextCache),
  };
};

const removeReducer: UploadReducer<UploadRemove> = (state, { payload: key }) => {
  const { cache, queue } = state;
  URL.revokeObjectURL(cache[key]?.url);

  return {
    ...state,
    queue: rejectKey(key, queue),
  };
};

const startReducer: UploadReducer<UploadStart> = (state) => ({
  ...state,
  uploading: true,
});

const successReducer: UploadReducer<UploadSuccess> = ({ cache, index, queue, ...state }, { payload: file }) => {
  const { hash, key } = file;
  const next = {
    ...state,
    cache: assoc(hash, file, dissoc(key, cache)),
    index: assoc(key, hash, index),
    queue: rejectKey(key, queue),
    uploading: false,
  };

  URL.revokeObjectURL(cache[key]?.url);
  persist(next.cache);

  return next;
};

/**
 * todo: consider implementing `assoc` wrapper since it's not typed properly
 */
export function uploadReducer(state: UploadState, action: UploadAction): UploadState {
  switch (action.type) {
    case "enqueue":
      return enqueueReducer(state, action);

    case "error":
      return errorReducer(state, action);

    case "prune":
      return pruneReducer(state, action);

    case "remove":
      return removeReducer(state, action);

    case "start":
      return startReducer(state, action);

    case "success":
      return successReducer(state, action);

    default:
      return state;
  }
}
