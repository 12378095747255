import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import {
  mdiAccountCancel,
  mdiBookmark,
  mdiCalendarToday,
  mdiCheck,
  mdiClockOutline,
  mdiMapMarker,
  mdiShuffleVariant,
} from "@mdi/js";

import { useData } from "@sinch/core";
import { Appointment, AppointmentQ, Id, selectAppointment, selectAppointmentType, selectLocation } from "@sinch/entity";
import { Format, t, useFormat } from "@sinch/intl";
import {
  CardList,
  Center,
  Chip,
  DisableStyle,
  IconText,
  Text,
  TimeRange,
  useFullWidthContent,
  WrapWords,
} from "@sinch/ui";
import { CardProps } from "@sinch/ui/Card/Card";
import { isEqual } from "date-fns";
import { isEmpty, isNil, max, prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router";
import { requestAppointmentList } from "./api";
import { AppointmentListEmptyMessage } from "./AppointmentListEmptyMessage";

export function AppointmentListMobile(): ReactElement {
  useFullWidthContent();
  const { dt } = useFormat();
  const { selectEntity, selectResult, queryEntity } = useData(requestAppointmentList);
  const navigate = useNavigate();

  const { appointmentIds } = selectResult();

  const appointmentEntities = sortBy(prop("startTime"), selectEntity(selectAppointment(appointmentIds)));

  const getAppointmentChip = (item: Appointment, i: number): ReactElement | undefined => {
    if (!isNil(appointmentEntities[i - 1]) && isEqual(appointmentEntities[i - 1].startTime, item.startTime)) {
      return undefined;
    }
    return (
      <Center>
        <Chip color="primary" label={dt.chip(item.startTime)} large />
      </Center>
    );
  };

  const handleClick = ({ id }: Appointment) => {
    navigate(`/appointment/${id}`);
  };

  const getColor = ({ id }: Appointment): CardProps => {
    if (queryEntity(AppointmentQ.User.isAttending(id))) {
      return {
        color: "success",
      };
    }
    if (selectEntity(AppointmentQ.isFull(id))) {
      return {
        color: "error",
      };
    }
    if (selectEntity(AppointmentQ.User.hasConflicting(id))) {
      return {
        color: "error",
      };
    }

    return {};
  };

  return (
    <>
      {isEmpty(appointmentIds) ? (
        <AppointmentListEmptyMessage />
      ) : (
        <CardList<Appointment>
          cardProps={getColor}
          content={(appointment: Appointment) => <AppointmentCardContent appointment={appointment} />}
          decorator={(children, { freeCapacity = 0 }) =>
            freeCapacity <= 0 ? <DisableStyle>{children}</DisableStyle> : children
          }
          items={appointmentEntities}
          onClick={handleClick}
          separator={getAppointmentChip}
        />
      )}
    </>
  );
}

function AppointmentCardContent({ appointment }: { appointment: Appointment }): ReactElement {
  const { id, name, startTime, endTime, location, type, freeCapacity, totalCapacity } = appointment;
  const { selectEntity, queryEntity } = useData(requestAppointmentList);
  const { address } = selectEntity(selectLocation(location));
  const typeName = type && selectEntity(selectAppointmentType(type, "name"));

  const getStatus = (appointmentId: Id): ReactElement | null => {
    if (queryEntity(AppointmentQ.User.isAttending(appointmentId))) {
      return (
        <IconText color="success" icon={mdiCheck}>
          {t("Appointment.attending") as string}
        </IconText>
      );
    }
    if (selectEntity(AppointmentQ.isFull(appointmentId))) {
      return (
        <IconText color="neutral" icon={mdiAccountCancel}>
          {t("full") as string}
        </IconText>
      );
    }
    if (selectEntity(AppointmentQ.User.hasConflicting(appointmentId))) {
      return (
        <IconText color="error" icon={mdiShuffleVariant}>
          {t("Appointment.inConflict") as string}
        </IconText>
      );
    }
    return null;
  };

  const status = getStatus(id);

  return (
    <MuiGrid container direction="column" spacing={1}>
      <MuiGrid item>
        <Text bold>{name}</Text>
      </MuiGrid>
      {status && (
        <MuiGrid item>
          <Text bold overline>
            {status}
          </Text>
        </MuiGrid>
      )}
      <MuiGrid container item spacing={2}>
        <MuiGrid item>
          <IconText icon={mdiCalendarToday}>
            <Format dt={startTime} />
          </IconText>
        </MuiGrid>
        <MuiGrid item>
          <IconText icon={mdiClockOutline}>
            {endTime ? (
              <TimeRange endTime={endTime} showLength startTime={startTime} />
            ) : (
              <Format dt={startTime} variant="time" />
            )}
          </IconText>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container item>
        <MuiGrid container direction="column" item justify="space-between" spacing={1} xs>
          <MuiGrid item>
            <IconText icon={mdiMapMarker}>
              <WrapWords>{address}</WrapWords>
            </IconText>
          </MuiGrid>
          {typeName && (
            <MuiGrid item>
              <IconText icon={mdiBookmark}>{typeName}</IconText>
            </MuiGrid>
          )}
        </MuiGrid>
        {!isNil(freeCapacity) && !isNil(totalCapacity) && (
          <MuiGrid item xs="auto">
            <Box alignItems="flex-end" display="flex" height="100%">
              <Text bold>{`${max(0, totalCapacity - freeCapacity)}/${totalCapacity}`}</Text>
            </Box>
          </MuiGrid>
        )}
      </MuiGrid>
    </MuiGrid>
  );
}
