// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useData } from "@sinch/core";
import { Fk, Position, PositionQ, selectPosition, selectShift, WorkerRole } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  ChipedList,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedHeader,
  CondensedStatus,
  CondensedTitle,
  ListItemBorder,
  ListItemContent,
  routerLink,
  SimpleListItem,
  SpacingBox,
  Text,
  useSpacing,
} from "@sinch/ui";
import clsx from "clsx";
import { includes, prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import {
  PositionClosingStatusIcons,
  PositionContact,
  PositionPlace,
  PositionStatusIcons,
  PositionTime,
} from "../../Position";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "./api";
import { PositionCardListSelector } from "./PositionCardList";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: theme.palette.grey[50],
    color: `${theme.palette.neutral.main} !important`,
    // filter: "grayscale(100%)",
  },
}));

export interface PositionTableProps {
  fields: string[];
}

export function FinishedCondensedList({ fields }: PositionTableProps): ReactElement | null {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { positionIdsToClose } = selectResult();

  if (!positionIdsToClose) {
    return null;
  }

  const positionsList = selectEntity(selectPosition(positionIdsToClose));

  const items = sortBy(prop("startTime"))(positionsList);

  return (
    <SpacingBox mx={-1}>
      <ChipedList>
        {items.map(({ id }: Position) => (
          <PositionListRow key={id} fields={fields} id={id} />
        ))}
      </ChipedList>
    </SpacingBox>
  );
}

const getButtons: PositionCardListSelector<ReactElement> = (query, position) => {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const [, , data] = useSpacing();

  const { applicantPositionIds } = selectResult();
  const { role, shift } = query(selectPosition(position));
  const canOnSitePresence =
    role === WorkerRole.Crewboss &&
    !includes(position, applicantPositionIds) &&
    selectEntity(selectShift(shift, "presence"));

  return (
    <Box pt={data}>
      <MuiGrid container justify="flex-end" spacing={data}>
        {canOnSitePresence && (
          <MuiGrid item>
            <Button action={routerLink(`/presence/${shift}`)} color="info" size="small" stretch variant="outlined">
              {t("Shift.closing.recordAttendanceButton")}
            </Button>
          </MuiGrid>
        )}
        <MuiGrid item>
          <Button action={routerLink(`/shift/${shift}/closing`)} color="info" size="small" stretch variant="outlined">
            {t("Shift.action.closeShift")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};

function PositionListRow({ id, fields }: { id: Fk<Position> } & PositionTableProps): ReactElement {
  const classes = useStyles();
  const { queryEntity } = useData(requestDashboardAttendanceList);
  const isDisabled = queryEntity(PositionQ.isCancelled(id));

  return (
    <SimpleListItem action={routerLink(`/position/${id}`)} className={clsx(isDisabled && classes.disabled)}>
      <ListItemBorder>
        <PositionClosingStatusIcons id={id} />
      </ListItemBorder>
      <ListItemContent>
        <PositionListItemContent fields={fields} id={id} />
        {getButtons(queryEntity, id)}
      </ListItemContent>
    </SimpleListItem>
  );
}

function PositionListItemContent({ id, fields }: { id: Fk<Position> } & PositionTableProps): ReactElement {
  return (
    <CondensedCard>
      <CondensedHeader>
        <CondensedTitle>
          <Text bold>
            <PositionReference id={id} name noId title />
          </Text>
        </CondensedTitle>
        <CondensedStatus>
          <PositionStatusIcons id={id} statuses={["transport"]} />
        </CondensedStatus>
      </CondensedHeader>
      <CondensedBody>
        <CondensedContent>
          <PositionTime id={id} />
          {includes("place", fields) && <PositionPlace id={id} />}
          {includes("contact", fields) && <PositionContact id={id} />}
        </CondensedContent>
      </CondensedBody>
    </CondensedCard>
  );
}
