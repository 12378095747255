import { mdiStar } from "@mdi/js";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { t } from "@sinch/intl";
import { Chip, Icon, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";

const useStyles = makeStyles({
  uppercase: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
});

interface FeaturedChipParams {
  size?: "small" | "large";
}

/**
 * Render chip with featured label
 */
export function FeaturedChip({ size }: FeaturedChipParams): ReactElement {
  const [, , data] = useSpacing();
  const styles = useStyles();
  return (
    <Chip
      color="primary"
      label={
        <MuiGrid alignItems="center" container spacing={data} wrap="nowrap">
          <MuiGrid item>
            <Icon icon={mdiStar} size={size === "small" ? "small" : undefined} />
          </MuiGrid>
          <MuiGrid className={styles.uppercase} item>
            {t("featured") as string}
          </MuiGrid>
        </MuiGrid>
      }
      large={size === "large"}
    />
  );
}
