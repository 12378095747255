import { useData } from "@sinch/core";
import { selectAppointment } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Card, GridLayout, Header, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestAppointmentDetail } from "./api";

export function AppointmentDetailHeader(): ReactElement {
  const { selectEntity, selectResult } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const { name } = selectEntity(selectAppointment(appointmentId));

  return (
    <GridLayout.Item>
      <Card>
        <Text small>{t("Appointment.title")}</Text>
        <Header>{name}</Header>
      </Card>
    </GridLayout.Item>
  );
}
