import { map, prop } from "ramda";
import { Entity } from "./Entity";
import { Id } from "./Id";

/**
 * Entity id selector
 *
 * todo: consider merging into single function handling both one and many
 */
export const selectId: <TEntity extends Entity>(entity: TEntity) => Id = prop(
  "id"
);

/**
 * Multiple entity ids selector
 */
export const selectIds: <TEntity extends Entity>(
  entities: TEntity[]
) => Id[] = map(selectId);
