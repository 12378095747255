import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from "@material-ui/core";
import { Consumer, FileHash, Nullable } from "@sinch/types";
import { Text } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement, SetStateAction, useCallback } from "react";
import { Overwrite } from "utility-types";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";
import { FileInputButton } from "./FileInputButton";
import { FileInputItems } from "./FileInputItems";
import { FileInputProvider, useFileInputContext } from "./FileInputProvider";
import { FileValidatorParams } from "./FileValidator";

/* eslint-disable react/jsx-props-no-spreading */

export interface FileInputProps extends Omit<ManagedInputProps, "dense" | "placeholder">, FileValidatorParams {
  /**
   * Allow multiple files selected.
   */
  multiple?: boolean;

  /**
   * Backend target key for upload client.
   */
  target: string;
}

export function FileInput(props: FileInputProps): ReactElement {
  const [{ multiple, value, ...baseProps }, { setValue, setError }] = useFormField<
    Nullable<FileHash> | FileHash[],
    FileInputProps
  >(props);

  const onError = useCallback(
    (error) => {
      setError(error);
    },
    [setError]
  );

  return (
    <FileInputBase
      {...baseProps}
      multiple={multiple as never}
      onChange={setValue as never}
      onError={onError}
      value={value as never}
    />
  );
}

type FileInputSingleBaseProps = BaseInputProps<
  Nullable<FileHash>,
  Overwrite<FileInputProps, { multiple?: false }>,
  Consumer<SetStateAction<Nullable<FileHash>>>
>;

type FileInputMultiBaseProps = BaseInputProps<
  FileHash[],
  Overwrite<FileInputProps, { multiple: true }>,
  Consumer<SetStateAction<FileHash[]>>
>;

export function FileInputBase(props: FileInputSingleBaseProps): ReactElement;

export function FileInputBase(props: FileInputMultiBaseProps): ReactElement;

/**
 * todo: add form label and control for consistent visual with other inputs
 *
 * todo: consider styling button in similar design as individual file list items
 *
 * todo: differentiate button label for various state
 *  - select file(s) when empty
 *  - add file(s) when value set
 *
 * todo: extract styled base components to `ui` package
 */
export function FileInputBase({
  accept,
  disabled,
  error: formError,
  extensions,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  label,
  maxSize,
  multiple,
  maxFiles,
  name,
  note,
  onChange,
  target,
  value,
  required = false,
}: FileInputSingleBaseProps | FileInputMultiBaseProps): ReactElement {
  const requiredSymbol = required ? "*" : "";

  return (
    <FileInputProvider
      accept={accept}
      extensions={extensions}
      maxFiles={maxFiles}
      maxSize={maxSize}
      multiple={multiple as never}
      onChange={onChange as never}
      target={target}
      value={value as never}
    >
      <FormControl error={formError} fullWidth margin="normal" variant="outlined">
        <InputLabel shrink>{`${label} ${requiredSymbol}`}</InputLabel>
        <OutlinedInput
          fullWidth
          inputComponent={() => (
            <Box m={1} width="inherit">
              {(!value || (multiple && !maxFiles) || (multiple && value.length < maxFiles)) && (
                <Box pb={value && !isEmpty(value) ? 1 : 0}>
                  <FileInputButton disabled={disabled} error={formError} name={name} />
                </Box>
              )}
              <FileInputItems />
            </Box>
          )}
          label={`${label} ${requiredSymbol}`}
          notched
          required={required}
        />
        <UploadErors />
        <FormHelperText>{note}</FormHelperText>
      </FormControl>
    </FileInputProvider>
  );
}

function UploadErors(): ReactElement {
  const { errors } = useFileInputContext();
  return (
    <FormHelperText error>
      <Text separator={<br />}>{errors}</Text>
    </FormHelperText>
  );
}
