import { useData } from "@sinch/core";
import { Fk, Penalty, selectPosition, selectSuspendedPenalty, SuspendedPenalty } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  composeCellLogic,
  Identifier,
  MuiDataTable,
  MuiDataTableDisplay,
  Spacer,
  Text,
  TypographyCell,
} from "@sinch/ui";
import React, { ReactElement } from "react";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { requestSuspendedPenaltyList } from "./api";

type PenaltyColumn = "identifier" | "penalty" | "note" | "deadline";

export function SuspendedPenaltyTable(): ReactElement {
  const { curr, dt } = useFormat();

  const { selectEntity, selectResult } = useData(requestSuspendedPenaltyList);
  const { suspendedPenaltyIds } = selectResult();

  const PenaltyDataTable = MuiDataTable.withConfig<Fk<SuspendedPenalty>, PenaltyColumn>({
    identifier: {
      title: t("Position.title"),
      selector: (id) => {
        const position = selectEntity(selectSuspendedPenalty(id, "position"));
        const { shift } = selectEntity(selectPosition(position));
        return (
          <Text bold>
            <Identifier entity="shift" id={shift} />
            -
            <Identifier entity="position" id={position} />
            <b>
              <PositionReference id={position} name noId title />
            </b>
          </Text>
        );
      },
    },
    penalty: {
      title: t("Penalty.title"),
      selector: (id) => {
        const { penalty = 0, rating } = selectEntity(selectSuspendedPenalty(id));
        return (
          <Text color="error">
            <Spacer separator=" / ">
              {curr(penalty)}
              <>{rating}</>
            </Spacer>
          </Text>
        );
      },
    },
    note: {
      title: t("description"),
      selector: (id) => selectEntity(selectSuspendedPenalty(id, "description")),
    },
    deadline: {
      title: t("Penalty.deadline"),
      selector: (id) => {
        const endTime = selectEntity(selectSuspendedPenalty(id, "deadline"));
        return dt.full(endTime);
      },
      logic: composeCellLogic<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>([TypographyCell]),
    },
  });

  return <PenaltyDataTable columns={["identifier", "penalty", "note", "deadline"]} data={suspendedPenaltyIds} />;
}
