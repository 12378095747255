import { Grid as MuiGrid } from "@material-ui/core";
import { mdiNote } from "@mdi/js";

import { useData } from "@sinch/core";
import { Fk, PositionAttendance, selectPositionAttendance } from "@sinch/entity";
import { TextInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Button, Card, useSpacing } from "@sinch/ui";
import React, { ReactElement, useState } from "react";
import { requestPresenceView } from "../../api";
import { useUpdatePresence } from "../../hooks";

export function NoteEdit({ id }: { id: Fk<PositionAttendance> }): ReactElement {
  const { selectEntity } = useData(requestPresenceView);
  const [, inner] = useSpacing();
  const { setNote } = useUpdatePresence(id);
  const { note } = selectEntity(selectPositionAttendance(id));
  const [noteState, setNoteState] = useState<string>(note || "");

  const setAbsenceNote = () => {
    setNote(noteState);
  };

  return (
    <Card>
      <MuiGrid container direction="column" spacing={inner}>
        <MuiGrid item>
          <TextInputBase
            dense={false}
            label={t("Shift.closingMissNote")}
            multiline
            name="note"
            onChange={(e) => setNoteState(e.currentTarget.value)}
            value={noteState}
          />
        </MuiGrid>
        <MuiGrid item>
          <Button action={setAbsenceNote} color="success" icon={mdiNote} variant="contained">
            {t("Shift.closing.sendNote")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Card>
  );
}
