import { useData } from "@sinch/core";
import { selectSnippet } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Grid, useMobileLayout } from "@sinch/ui";
import { isEmpty, length, map, pipe, sum } from "ramda";
import { isNonEmptyArray } from "ramda-adjunct";
import React, { ReactElement, useState } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../components";
import { getNoticesStrips } from "../components";
import { AgendaCardList } from "./AgendaCardList";
import { AgendaCondensedList } from "./AgendaCondensedList";
import { requestDashboardAttendanceList } from "./api";

type FilteredFields = "place" | "contact";

export function IncomingPositions(): ReactElement {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { incomingPositionIds, applicantPositionIds, incomingAppointmentIds, noticeIds } = selectResult();
  const [view, setView] = useState<PositionView>(PositionView.Standard);
  const [fields, setFields] = useState<FilteredFields[]>([]);
  const mobile = useMobileLayout();

  if (
    isEmpty(incomingPositionIds || []) &&
    isEmpty(incomingAppointmentIds || []) &&
    isEmpty(applicantPositionIds || []) &&
    isNonEmptyArray(noticeIds)
  ) {
    return (
      <Grid spacing="inner" vertical>
        {getNoticesStrips(selectEntity(selectSnippet(noticeIds)))}
      </Grid>
    );
  }

  const itemsLength = pipe(
    map(length),
    sum
  )([incomingPositionIds || [], incomingAppointmentIds || [], applicantPositionIds || []]);

  if (!mobile) {
    return (
      <AgendaCardList
        applicants={applicantPositionIds}
        appointments={incomingAppointmentIds}
        positions={incomingPositionIds}
      />
    );
  }

  return (
    <>
      <PositionListViewSelectHeader<FilteredFields>
        count={itemsLength}
        fieldsKey="IncomingPositions"
        filterFields={{
          place: t("location"),
          contact: t("Position.display.contact"),
        }}
        onFieldsFilterChange={setFields}
        onViewChange={setView}
      />

      {view === PositionView.Condensed && <AgendaCondensedList fields={fields} />}
      {view === PositionView.Standard && (
        <AgendaCardList
          applicants={applicantPositionIds}
          appointments={incomingAppointmentIds}
          positions={incomingPositionIds}
        />
      )}
    </>
  );
}
