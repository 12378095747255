import { mdiBriefcase } from "@mdi/js";
import { Box } from "@material-ui/core";

import { useCurrentUser, useData } from "@sinch/core";
import {
  Fk,
  selectFeedback,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  Shift,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Avatar, Button, Text, useMobileLayout } from "@sinch/ui";
import { equals, filter, flatten, isEmpty, isNil, map, reject } from "ramda";
import React, { ReactElement } from "react";
import { WorkerList } from "../../components";
import { requestShiftFeedbackList } from "./api";
import { SelectFeedbackFn } from "./types";

interface WorkerFeedbackListProps {
  onSelect: SelectFeedbackFn;

  shift: Fk<Shift>;
}

export function WorkerFeedbackList({ onSelect, shift }: WorkerFeedbackListProps): ReactElement {
  const { id: currentUserId } = useCurrentUser();
  const { selectEntity } = useData(requestShiftFeedbackList);
  const mobile = useMobileLayout();

  const positions = selectEntity(selectPosition({ shift }, "id"));

  const filterByRole = (role: WorkerRole) =>
    filter((pos) => equals(selectEntity(selectPosition(pos, "role")), role), positions);

  // todo: move this to query functions, map is probably better?
  const positionsSorted = flatten([
    filterByRole(WorkerRole.Crewboss),
    filterByRole(WorkerRole.Worker),
    filterByRole(WorkerRole.Backup),
  ]);

  const shiftFeedback = selectEntity(selectFeedback({ from: currentUserId, shift, to: null }, "type"));

  const shiftListItem = {
    id: "S",
    name: t("Feedback.entireShift"),
    avatar: <Avatar icon={mdiBriefcase} />,
    workerItems: [
      <Box display="flex" justifyContent="flex-end">
        {isEmpty(shiftFeedback) ? (
          <Button action={() => onSelect("S")} color="info" stretch variant="outlined">
            {t("Feedback.action.add")}
          </Button>
        ) : (
          <Button color="info" disabled stretch variant="text">
            {t("Feedback.sent")}
          </Button>
        )}
      </Box>,
    ],
  };

  const workerListItems = map((position) => {
    const workers = selectEntity(selectPositionAttendance({ position }, "worker"));
    if (isEmpty(workers)) {
      return [];
    }

    const chipListItem = {
      chip: true,
      position,
    };

    const workerItems = map((worker) => {
      const { name, avatar, phone, surname } = selectEntity(selectWorker(worker));

      const workerFeedback = selectEntity(selectFeedback({ from: currentUserId, shift, to: worker }, "type"));

      const action =
        /* eslint-disable-next-line no-nested-ternary */
        currentUserId !== worker && isEmpty(workerFeedback) ? (
          <Button action={() => onSelect(worker)} color="info" stretch variant="outlined">
            {t("Feedback.action.add")}
          </Button>
        ) : (
          ""
        );

      return {
        disabled: currentUserId === worker || !isEmpty(workerFeedback),
        id: worker,
        avatar,
        name,
        surname,
        workerItems: reject(isNil, [
          !isNil(phone) ? (
            <Box display="flex" justifyContent={mobile ? undefined : "flex-end"}>
              <Text>{phone}</Text>
            </Box>
          ) : undefined,
          <Box display="flex" justifyContent="flex-end">
            {action}
          </Box>,
        ]),
      };
    }, workers);

    return [chipListItem, workerItems];
  }, positionsSorted);

  const listItems = flatten([shiftListItem, workerListItems]);

  return <WorkerList data={listItems} />;
}
