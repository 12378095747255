import { Box, Grid, Typography } from "@material-ui/core";
import { mdiPlayCircleOutline } from "@mdi/js";
import { useBusinessRules, useRequest } from "@sinch/core";
import { Fk, Position, Location } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import { Action, Button, DialogControl, ModalDialog, Text, useDialog, useMobileLayout } from "@sinch/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { requestClockIn } from "../api";
import { StaffClockInGeolocation } from "./StaffClockInGeolocation";
import { GeolocationPayload } from "./types";

interface ModalDialogResultProps {
  dateTime: Date;
  dialog: DialogControl;
}

const ModalDialogResult: React.FC<ModalDialogResultProps> = ({ dateTime, dialog }) => {
  const { t } = useTranslation();
  const { dt } = useFormat();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Text>
          {t<string>("StaffClockIn.clockInSuccess", { time: dt.time(dateTime) })}
        </Text>
      </Grid>
      <Grid item style={{ marginTop: 18 }}>
        <Button action={dialog.close} color="info" large variant="contained">
          {t<string>("action.confirm")}
        </Button>
      </Grid>
    </Grid>
  );
};

interface ModalDialogClockInProps {
  location?: Location;
  dialog: DialogControl;
  position: Fk<Position>;
  setLoading: (loading: boolean) => void;
}

const ModalDialogClockIn: React.FC<ModalDialogClockInProps> = ({ location, dialog, setLoading, position }) => {
  const { t } = useTranslation();
  const { clockInText } = useBusinessRules();
  const dispatch = useRequest();

  const handleClockIn: Action = async () => {
    setLoading(true);
    await dispatch(requestClockIn({ positionId: position }));
    setLoading(false);
  };

  const handleGeolocationClockIn = async (data: GeolocationPayload) => {
    setLoading(true);
    await dispatch(
      requestClockIn({
        positionId: position,
        ...data.point,
        errorCode: data.error?.code,
        accuracy: data.accuracy,
      })
    );
    setLoading(false);
  };

  if (location) {
    return (
      <StaffClockInGeolocation
        formContent={<Typography align="center">{clockInText}</Typography>}
        location={location}
        onCancel={dialog.close}
        onConfirm={handleGeolocationClockIn}
      />
    );
  }
  return (
    <Grid container direction="column" spacing={2}>
      {clockInText && (
        <Grid item>
          <Typography align="center">{clockInText}</Typography>
        </Grid>
      )}
      <Grid item style={{ marginTop: 18 }}>
        <Button action={handleClockIn} color="info" large variant="contained">
          {t<string>("action.confirm")}
        </Button>
      </Grid>
      <Grid item>
        <Button action={dialog.close} color="error" large variant="text">
          {t<string>("action.cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};

interface ClockInButtonProps {
  fullWidth?: boolean;
  position: Fk<Position>;
  dateTime?: Date;
  location?: Location;
}

export const ClockInButton: React.FC<ClockInButtonProps> = ({ fullWidth, position, dateTime, location }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const mobile = useMobileLayout();

  return (
    <Box onClick={(e) => e.preventDefault()} py={2}>
      <Button
        action={dialog.open}
        color="success"
        icon={mdiPlayCircleOutline}
        large
        loading={loading}
        stretch={fullWidth === true ? false : !mobile}
        variant="contained"
      >
        {t<string>("StaffClockIn.clockIn")}
      </Button>
      <ModalDialog onClose={dialog.close} open={dialog.state} title={t<string>("StaffClockIn.clockIn")} width="xs">
        {dateTime ? (
          <ModalDialogResult dateTime={dateTime} dialog={dialog} />
        ) : (
          <ModalDialogClockIn dialog={dialog} location={location} position={position} setLoading={setLoading} />
        )}
      </ModalDialog>
    </Box>
  );
};
