import { useBusinessRules, useData } from "@sinch/core";
import { Fk, Reimbursement, selectReimbursement } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Box, List, Text, useDialog, useSpacing, useTitleBar } from "@sinch/ui";
import { pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement, useMemo, useState } from "react";
import { ReimbursementStatusElement, SearchParamsPagination } from "../../components";
import { requestReimbursementList } from "./api";
import { ReimbursementDetail } from "./ReimbursementDetail";

export function MobileReimbursementList(): ReactElement {
  const { curr, dt } = useFormat();
  const [, , data] = useSpacing();

  useTitleBar({
    container: "core:wallet/reimbursements",
    locationTitle: t("Reimbursement.titlePlural"),
    showBack: true,
  });

  const dialog = useDialog();
  const [detailId, setDetailId] = useState<number | null>(null);

  const { selectEntity, selectResult } = useData(requestReimbursementList);
  const { allowWorkerReimbursementRequest } = useBusinessRules();
  const { reimbursementIds } = selectResult();

  useMemo(() => {
    setDetailId(null);
  }, [reimbursementIds]);

  const sortedReimbursementIds: number[] = pipe<Reimbursement[], Reimbursement[], number[], number[]>(
    sortBy(prop("created")),
    pluck("id"),
    reverse
  )(selectEntity(selectReimbursement(reimbursementIds)));

  return (
    <>
      <List
        center
        getAction={
          allowWorkerReimbursementRequest
            ? (reimbursement: Fk<Reimbursement>) => () => {
                setDetailId(reimbursement);
                dialog.open();
              }
            : undefined
        }
        getBody={(reimbursement: Fk<Reimbursement>) => {
          const { created, amount, status } = selectEntity(selectReimbursement(reimbursement));

          return (
            <>
              <Box display="flex" justifyContent="space-between" mb={data}>
                <Text bold>{curr(amount)}</Text>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                <Text>{dt(created!)}</Text>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <ReimbursementStatusElement status={status} />
              </Box>
            </>
          );
        }}
        items={sortedReimbursementIds}
      />
      <SearchParamsPagination />

      {detailId && dialog.state && <ReimbursementDetail dialog={dialog} reimbursementId={detailId} />}
    </>
  );
}
