import { ErrorBoundary } from "@sinch/ui";
import { ChildrenProps, composeElements } from "@sinch/utils";
import React, { ReactElement } from "react";
import { RequestCreator } from "../contract";
import { BackendProvider, BackendProviderProps } from "./BackendProvider";
import { CachedResponseProvider, CacheStateProvider } from "./CachedResponseProvider";
import { CascadeRefreshProvider, CascadeRefreshProviderProps } from "./CascadeRefreshProvider";
import { FallbackProvider, FallbackProviderProps } from "./FallbackProvider";
import { HandlerProvider, HandlerProviderProps } from "./HandlerProvider";
import { ProgressProvider, ProgressProviderProps } from "./ProgressProvider";
import { RequestScopeProvider, RequestScopeProviderProps } from "./RequestScopeProvider";
import { ResponseProvider, ResponseProviderProps } from "./ResponseProvider";

interface DataProviderProps<TCreator extends RequestCreator>
  extends Partial<BackendProviderProps>,
    Partial<FallbackProviderProps>,
    Partial<HandlerProviderProps>,
    Partial<ResponseProviderProps<TCreator>>,
    Partial<CascadeRefreshProviderProps>,
    Partial<ProgressProviderProps>,
    Partial<RequestScopeProviderProps>,
    ChildrenProps {}

/**
 * todo: implement ErrorBoundary fallback component with "Retry" button
 *  to allow user resetting individual request state
 */
export function DataProvider<TCreator extends RequestCreator>({
  backend,
  children,
  fallback,
  handler,
  refresh,
  request,
  refresher,
  progress,
  cache,
  scope,
}: DataProviderProps<TCreator>): ReactElement {
  return composeElements([
    <ProgressProvider progress={progress} />,
    <ErrorBoundary />,
    scope && <RequestScopeProvider scope={scope} />,
    backend && <BackendProvider backend={backend} />,
    handler && <HandlerProvider handler={handler} />,
    fallback && <FallbackProvider fallback={fallback} />,
    backend && <CachedResponseProvider />,
    request && <CacheStateProvider request={request} />,
    request && <ResponseProvider cache={cache} refresh={refresh} request={request} />,
    refresher && <CascadeRefreshProvider refresher={refresher} />,
    children,
  ]);
}
