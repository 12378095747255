import { useIntl } from "@sinch/intl";
import { Header } from "@sinch/ui";
/* eslint-disable-next-line import/no-internal-modules -- todo: fix properly (export relevant ui components) */
import { ToolbarButton } from "@sinch/ui/ApplicationLayout/TitleBar";
import { always, equals, ifElse, toUpper } from "ramda";
import React, { ReactElement, useCallback, useEffect } from "react";

const isBaseTrigger = equals("Alt");
const isDictTrigger = equals("Shift");

const getLabel = ifElse(equals("cimode"), always("CI"), toUpper);

interface LanguageToggleProps {
  baseLng?: string;

  dictLng?: string;

  enabled?: boolean;

  transLng?: string;
}

/**
 * todo: extract as separate plugin to be used when translating the app
 *  (enables translator to quickly detect dictionary key)
 *  - read configuration props from env variables
 */
export function LanguageToggle({
  baseLng = "en",
  dictLng = "cimode",
  enabled = false,
  transLng = "cs",
}: LanguageToggleProps): ReactElement {
  const nextLng = useCallback(
    (lng) => (equals(lng, baseLng) ? transLng : baseLng),
    [transLng, baseLng]
  );

  const { language: lng, setLanguage: setLng } = useIntl();

  const toggleLng = useCallback(() => setLng(nextLng(lng)), [
    lng,
    setLng,
    nextLng,
  ]);

  const handleKeyDown = useCallback(
    ({ key }) => {
      if (isBaseTrigger(key)) setLng(baseLng);
      if (isDictTrigger(key)) setLng(dictLng);
    },
    [baseLng, dictLng, setLng]
  );

  const handleKeyUp = useCallback(
    ({ key }) => {
      if (isBaseTrigger(key)) setLng(transLng);
      if (isDictTrigger(key)) setLng(transLng);
    },
    [transLng, setLng]
  );

  useEffect(() => {
    if (!enabled) return;
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    /* eslint-disable-next-line consistent-return */
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [enabled, handleKeyDown, handleKeyUp]);

  return (
    <ToolbarButton onClick={toggleLng}>
      <Header>{getLabel(lng)}</Header>
    </ToolbarButton>
  );
}
