import { Theme } from "@material-ui/core";
import { Palette, PaletteColor, SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { CSSProperties } from "@material-ui/styles/withStyles";
import { capitalize } from "@sinch/utils";
import { either, isEmpty, isNil, map, mergeAll, xprod } from "ramda";

/**
 * todo: can we get valid colors directly from theme palette keys?
 */
export type StyleColor =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "neutral"
  | "completed"
  | "note";

export type StyleVariant = string | null;

/**
 * todo: review default colors, use whole palette or just some?
 *  - maybe split to status colors, brand colors, text colors, etc (see StatusColor type)
 */
const defaultColors: StyleColor[] = [
  "primary",
  "secondary",
  "error",
  "warning",
  "info",
  "success",
  "neutral",
  "completed",
  "note",
];

const defaultVariants: StyleVariant[] = [null];

// todo: extract to ui/theme for reuse?
type StyleRules<TKey extends string> = Record<TKey | "default", CSSProperties>;

type StyleTemplateParams = {
  color?: StyleColor;
  variant?: StyleVariant;
  theme?: Theme;
};

type StyleTemplate = (paletteColor: PaletteColor, { color, variant, theme }: StyleTemplateParams) => CSSProperties;

// todo: extract to utils as isEmpty
const empty = either(isNil, isEmpty);

export function styleKey<TKey extends string = string>(variant: StyleVariant, color: StyleColor): TKey {
  return (empty(variant) ? color : `${variant}${capitalize(color)}`) as TKey;
}

type StylePair = [StyleVariant, StyleColor];

function pairs(colors: StyleColor[], variants: StyleVariant[]): StylePair[] {
  return xprod(variants, colors);
}

function styleCreator(
  template: StyleTemplate,
  theme: Theme
): <TKey extends string>(pairs: StylePair[]) => StyleRules<TKey>[] {
  function pairToStyle<TKey extends string>([variant, color]: StylePair): StyleRules<TKey> {
    const paletteColor = theme.palette[color];
    const params = { color, variant, theme };

    return {
      [styleKey<TKey>(variant, color)]: template(paletteColor, params),
    } as StyleRules<TKey>;
  }

  return map(pairToStyle);
}

function createStyles<TKey extends string>(
  colors: StyleColor[],
  variants: StyleVariant[],
  template: StyleTemplate,
  theme: Theme
): StyleRules<TKey> {
  const creator = styleCreator(template, theme);
  const styles = creator<TKey>(pairs(colors, variants));

  return mergeAll(styles);
}

type PaletteStylesFromTheme<TKey extends string> = (theme: Theme) => StyleRules<TKey>;

type PaletteStylesFromTemplate<TKey extends string> = (template: StyleTemplate) => PaletteStylesFromTheme<TKey>;

type ConfigurePaletteStylesParams = {
  colors?: StyleColor[];
  variants?: StyleVariant[];
};

/**
 * todo: TKey needs to be derived from combinations of colors and variants
 *  - is it possible to make typescript infer correct type?
 */
export function configurePaletteStyles<TKey extends string = string>({
  colors = defaultColors,
  variants = defaultVariants,
}: ConfigurePaletteStylesParams): PaletteStylesFromTemplate<TKey> {
  return (template) => (theme) => createStyles(colors, variants, template, theme);
}

export function createPaletteStyles(template: StyleTemplate): PaletteStylesFromTheme<StyleColor> {
  return configurePaletteStyles<StyleColor>({})(template);
}

type CapitalizedIdentifier = Capitalize<keyof Palette["identifiers"]>;
export type IdentifierColorKey = `color${CapitalizedIdentifier}`;

type EntityColors = Record<IdentifierColorKey, { color: string }>;

export function getEntityColors(theme: Theme, background = false): EntityColors {
  const colorsObject: Partial<EntityColors> = Object.entries(theme.palette.identifiers).reduce<Partial<EntityColors>>(
    (colors, [key, { main: color, contrastText }]: [string, SimplePaletteColorOptions]) => ({
      ...colors,
      [`color${capitalize(key)}`]: background
        ? { backgroundColor: color, color: contrastText || theme.palette.getContrastText(color) }
        : { color },
    }),
    {}
  );
  // @ts-ignore
  return colorsObject;
}

export const identifiersStylesCreator = (
  template: (colorProps: { color: string; backgroundColor: string }, theme: Theme) => CSSProperties
): ((theme: Theme) => Record<IdentifierColorKey, CSSProperties>) => (
  theme: Theme
  // @ts-ignore
): Record<IdentifierColorKey, CSSProperties> => map((item) => template(item, theme))(getEntityColors(theme, true));
