import { useEffect } from "react";

export function useConditionalEffect(
  condition: boolean,
  callback: (...cbArgs: any[]) => void,
  ...args: any[]
): void {
  useEffect(() => {
    if (condition) {
      callback(...args);
    }
  }, [condition, callback, ...args]);
}
