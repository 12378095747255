import { Box, Tooltip, Typography } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import {
  mdiAlertOutline,
  mdiCar,
  mdiCash,
  mdiChartTimelineVariant,
  mdiClockOutline,
  mdiNoteTextOutline,
  mdiTimerOutline,
  mdiTimerSandFull,
} from "@mdi/js";

import { useCurrentUser, useData } from "@sinch/core";
import {
  PositionQ,
  PositionStatus,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  selectTransport,
  selectUser,
  selectWalletTransaction,
  sOr,
  Transport,
  TransportDirection,
  WalletTransactionType,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Button,
  Card,
  DataList,
  GridLayout,
  Icon,
  InformationDialog,
  NumberStatusColor,
  Text,
  TimeRange,
  useDialog,
  useMobileLayout,
} from "@sinch/ui";
import { isDefined } from "@sinch/utils";
import { max } from "date-fns";
import { filter, find, head, isNil, map, propEq, reject } from "ramda";
import { isNotNil, isNotNilOrEmpty } from "ramda-adjunct";
import React, { useMemo } from "react";
import { transactionTypes } from "../../Wallet/Wallet/common";
import { requestPositionDetail } from "./api";

const wageTypeWage = () => t("Wallet.wage");
const wageTypePenalty = () => t("Wallet.penalty");

const wageResult = (wage, type, description) => {
  const mobile = useMobileLayout();
  return (
    <Tooltip title={description}>
      <Box>
        <div>{`${wage} (${type})`}</div>
        {mobile && (
          <Typography display="block" gutterBottom variant="caption">
            {description}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

function ResultsItem({ label, value, severe = false }) {
  return (
    <Text key={label} color={severe ? "error" : "initial"}>
      {value}
    </Text>
  );
}

export function PositionDetailResults({ positionId }) {
  const { curr, dt } = useFormat();
  const { selectEntity, queryEntity, selectResult } = useData(requestPositionDetail);
  const currentUser = useCurrentUser();
  const dialog = useDialog(false);

  const { walletHistory, clockOutMessage } = selectResult();
  const walletTransactions = selectEntity(selectWalletTransaction({ archived: false }));
  const transportIds = queryEntity(PositionQ.Shift.getTransports(positionId));

  const [transportThere, transportBack] = useMemo(() => {
    let there: Transport | null = null;
    let back: Transport | null = null;

    transportIds.forEach((id) => {
      const transport = selectEntity(selectTransport(id));
      if (!transport) {
        return;
      }
      if (transport.direction === TransportDirection.There) {
        there = transport;
      } else {
        back = transport;
      }
    });
    return [there, back];
  }, [transportIds]);

  // todo: extract into query
  if (
    !queryEntity(
      sOr(
        PositionQ.User.hasAttendancePresent,
        PositionQ.User.hasAttendanceAbsent,
        PositionQ.User.hasAttendanceExcused,
        PositionQ.User.hasAttendanceLate
      )(positionId)
    )
  )
    return null;

  // todo: extract PositionQ.User.getAttendance query
  const attendance = selectEntity(selectPositionAttendance({ position: positionId, worker: currentUser.id }));
  if (!isDefined(attendance)) return null;
  const { startTime, endTime, rating, wage, penalty, startTimeSelfClocked, endTimeSelfClocked } = head(attendance);
  const { startTime: positionStartTime } = selectEntity(selectPosition(positionId));
  const realStartTime = max([positionStartTime, startTime]);

  const isAbsentOrExcused = queryEntity(
    sOr(PositionQ.User.hasAttendanceAbsent, PositionQ.User.hasAttendanceExcused)(currentUser.id)
  );
  const { shift, status } = selectEntity(selectPosition(positionId));
  const { closeTime, closedBy } = selectEntity(selectShift(shift));
  const { name } = isDefined(closedBy) ? selectEntity(selectUser(closedBy)) : { name: undefined };

  const parameters = filter((x) => x, [
    !isNil(transportThere) && {
      label: t("Transport.there"),
      values: [
        {
          value: <TimeRange endTime={transportThere.endTime} showLength startTime={transportThere.startTime} />,
        },
      ],
      icon: mdiCar,
    },
    {
      label: t("Position.hoursWorked"),
      values: [
        {
          value: !isAbsentOrExcused ? (
            <TimeRange
              endTime={endTime}
              endTimeSuffix={endTimeSelfClocked ? `(${t<string>("StaffClockIn.staffClockIn")})` : undefined}
              showLength
              startTime={realStartTime}
              startTimeSuffix={startTimeSelfClocked ? `(${t<string>("StaffClockIn.staffClockIn")})` : undefined}
            />
          ) : (
            t("hoursCount", { hours: "0" })
          ),
        },
      ],
      icon: mdiTimerOutline,
    },
    !isNil(transportBack) && {
      label: t("Transport.back"),
      values: [
        {
          value: <TimeRange endTime={transportBack.endTime} showLength startTime={transportBack.startTime} />,
        },
      ],
      icon: mdiCar,
    },
    isDefined(rating) && {
      label: status < PositionStatus.SupervisorClosed ? t("Position.proposedRating") : t("Position.receivedRating"),
      values: [
        {
          value: <NumberStatusColor value={rating}>{rating > 0 ? `+${rating}` : rating}</NumberStatusColor>,
          severe: rating < 0,
        },
      ],
      icon: mdiChartTimelineVariant,
    },
    clockOutMessage !== null && {
      label: t("note"),
      values: [
        {
          value: clockOutMessage,
        },
      ],
      icon: mdiNoteTextOutline,
    },
    status < PositionStatus.SupervisorClosed &&
      isNotNil(attendance[0]) && {
        label: t("Position.proposedWage"),
        values: [
          {
            value: curr(attendance[0].wage),
          },
        ],
        icon: mdiCash,
      },
    status >= PositionStatus.SupervisorClosed &&
      !isNil(wage) && {
        label: t("Position.receivedWage"),
        values: filter(isDefined, [
          ...map(
            ({ value, type, description }) => ({
              value: wageResult(curr(value), transactionTypes()[type].label, description),
            }),
            reject(propEq("type", WalletTransactionType.Penalty))(walletTransactions)
          ),
          isDefined(penalty) && penalty !== 0
            ? {
                value: wageResult(curr(penalty), wageTypePenalty()),
                severe: true,
              }
            : undefined,
        ]),
        icon: mdiCash,
      },
    isDefined(name) && {
      label: t("Position.closedBy"),
      values: [{ value: name }],
      icon: mdiTimerSandFull,
    },
    isDefined(closeTime) && {
      label: t("Position.closedTime"),
      values: [{ value: dt.full(closeTime) }],
      icon: mdiClockOutline,
    },
  ]);

  const data = parameters.map(({ label, values, icon }) => ({
    content: values.map(({ value, severe = false }) => (
      <ResultsItem key={value} icon={icon} severe={severe} value={value} />
    )),
    label,
    icon,
  }));

  return (
    <GridLayout.Item>
      <Card>
        <DataList data={data} />
        {isNotNilOrEmpty(walletHistory) && (
          <Alert
            action={
              <Button action={dialog.open} variant="text">
                {t("view")}
              </Button>
            }
            color="warning"
            icon={<Icon icon={mdiAlertOutline} />}
            variant="outlined"
          >
            {t("Position.reopenedWarning")}
          </Alert>
        )}
      </Card>
      <InformationDialog control={dialog} hashKey="walletHistory" title={t("Position.walletHistory")} width="xs">
        <WalletHistory />
      </InformationDialog>
    </GridLayout.Item>
  );
}

function WalletHistory() {
  const { curr, dt } = useFormat();
  const { selectResult, selectEntity } = useData(requestPositionDetail);
  const { walletHistory } = selectResult();

  const data = walletHistory.map(({ date, walletId }) => ({
    content: walletId.map((walletId) => {
      const { description, type, value } = selectEntity(selectWalletTransaction(walletId));
      const typeObj = find(propEq("value", type), transactionTypes());
      const typeStr = typeObj ? `(${typeObj.label})` : "";
      return (
        <MuiGrid container spacing={1}>
          <MuiGrid item xs="auto">
            <Text color={value < 0 ? "error" : undefined}>{curr(value)}</Text>
          </MuiGrid>
          <MuiGrid item xs="auto">
            {` - `}
          </MuiGrid>
          <MuiGrid item xs>{`${description} ${typeStr}`}</MuiGrid>
        </MuiGrid>
      );
    }),
    label: dt.full(date),
    divider: true,
  }));

  return <DataList data={data} disableIcons disablePadding />;
}
