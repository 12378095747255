import React, { ReactElement } from "react";
import { DataTableBodyProps, DataTableDisplay } from "./types";

/* eslint-disable react/no-array-index-key */

export function DataTableBody<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>({ table }: DataTableBodyProps<TData, TColumn, TDisplay>): ReactElement {
  const { data, display, logic } = table;
  const { Body } = display;
  const RowLogic = logic;

  /*
   * todo: consider using data id as a key
   *  (might use idSelector prop, use array index if not defined)
   */
  return (
    <Body>
      {data.map((el, i) => (
        <RowLogic key={i} row={i} table={table} />
      ))}
    </Body>
  );
}
