export enum WalletTransactionType {
  Wage = 0,

  Penalty = 1,

  Service = 2,

  Other = 3,

  Payout = 4,

  Bonus = 5,

  Correction = 6,

  Equipment = 7,
}
