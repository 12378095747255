import { DataProvider, ScrollHistory } from "@sinch/core";
import { useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useParams } from "react-router-dom";
import { requestPageView } from "../contract";
import { Page } from "./Page";

/**
 * todo: set viewed page name as current location title
 *  instead of generic Pages
 */
export function PageViewContainer(): ReactElement {
  useTitleBar({
    container: "core:page",
  });

  const { page, topic } = useParams();
  const slug = topic || page;

  const request = useMemo(() => requestPageView(slug), [slug]);

  return (
    <DataProvider request={request}>
      <ScrollHistory>
        <Page />
      </ScrollHistory>
    </DataProvider>
  );
}
