import { defineRequest } from "@sinch/core";
import { Fk, Shift } from "@sinch/entity";
import { AttendanceClosingFormState } from "./form";

/**
 * todo: use IdParams<Shift> instead of `shift: Fk<Shift>`
 */
export const requestShiftAttendanceClose = defineRequest("Shifts/AttendanceClose/:id")
  .withUriParams({ id: ["params", "shift"] })
  .withParams<AttendanceClosingFormState & { shift: Fk<Shift> }>()
  .withEmptyResponse()
  .withCreator((params) => ({
    params,
  }));

/**
 * todo: how to define file targets?
 *  define additional prop on RequestCreator for this
 */

export const ShiftAttendanceCloseFilesTarget = "worker/Shifts/AttendanceCloseFiles";
