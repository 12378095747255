import { useFormikContext } from "formik";
import { SetStateAction } from "react";

export function useFormValues<TValues>(): TValues {
  const { values } = useFormikContext<TValues>();
  return values;
}

export function useFormUpdate<TValues>(): (
  values: SetStateAction<TValues>,
  validate?: boolean
) => void {
  const { setValues } = useFormikContext<TValues>();
  return setValues;
}
