import { defineRequest } from "@sinch/core";

export interface ReferralInfo {
  name: string;

  /**
   * Either array of two numbers representing attendance status (3 of 10),
   * or true if referral is already complete.
   */
  status: [number, number] | true;
}

export const requestProfileReferralList = defineRequest("Profiles/ReferralList")
  .withEmptyParams()
  .withResponse<{ referrals: ReferralInfo[] }>()
  .withDefaultCreator();
