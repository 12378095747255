import {AutocompleteInputBase, AutocompleteInputChangeReason, ManagedInputProps, useFormField} from "@sinch/forms";
import {t} from "@sinch/intl";
import {MapClient, MapItem} from "@sinch/mapycz";
import {Nullable, Transform} from "@sinch/types";
import {Grid, Text} from "@sinch/ui";
import {debounce} from "debounce";
import {eqBy, equals, identity, includes, is, isNil, map, pick, pipe, prepend, prop, unless} from "ramda";
import {lengthGt} from "ramda-adjunct";
import React, {ReactElement, ReactNode, useMemo, useState} from "react";

export type MapPlace = Pick<MapItem, "id" | "label" | "point">;

const equalById: (a: MapPlace, b: MapPlace) => boolean = eqBy(prop<"id", number>("id"));

const extractPlaces: Transform<MapItem[], MapPlace[]> = map(pick(["id", "label", "point"]));

const placeToString: Transform<MapPlace, string> = pipe(prop("label"), (result) => {
  if (isNil(result)) {
    return t("error");
  }
  if (is(String, result)) {
    return result;
  }
  const [first, second] = result;
  return `${first} (${second})`;
});

const placeToOption: Transform<MapPlace, ReactNode> = ({ label }) => {
  if (isNil(label)) {
    return t("error");
  }
  const [first, second] = label;
  return (
  <Grid vertical>
    <Text bold>{first}</Text>
    {second && <Text small>{second}</Text>}
  </Grid>
);
};

const isInputType = equals<AutocompleteInputChangeReason>("input");

const isValidSearch = lengthGt(3);

export interface MapPlaceInputProps extends ManagedInputProps {
  mapClient: MapClient;
}

export function MapPlaceInput(props: MapPlaceInputProps): ReactElement {
  const [{ mapClient, ...baseProps }, { setValue }] = useFormField<Nullable<MapPlace>, MapPlaceInputProps>(props);

  const { value } = baseProps;

  const prependValue: Transform<MapPlace[]> = useMemo(
    () => (value ? unless(includes(value), prepend(value)) : identity),
    [value]
  );

  const [options, setOptions] = useState<MapPlace[]>([]);

  /**
   * trigger new search when input field is changed when:
   * - input string is longer than 3 characters
   * - input was changed by the user (not select/reset/clear event)
   * - debounce interval passed from last search
   */
  const handleInputChange = useMemo(
    () =>
      debounce(
        async (input: string, reason: AutocompleteInputChangeReason) =>
          isInputType(reason) && isValidSearch(input) && setOptions(extractPlaces(await mapClient.search(input))),
        300
      ),
    [mapClient]
  );

  return (
    <AutocompleteInputBase<MapPlace>
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      freeSolo
      getOptionLabel={placeToString}
      getOptionSelected={equalById}
      onChange={setValue}
      onInputChange={handleInputChange}
      options={prependValue(options)}
      renderOption={placeToOption}
    />
  );
}
