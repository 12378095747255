import MuiGrid from "@material-ui/core/Grid";
import {
  GridItemsAlignment as MuiGridItemsAlignment,
  GridSpacing as MuiGridSpacing,
} from "@material-ui/core/Grid/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ChildrenProps } from "@sinch/utils";
import { equals } from "ramda";
import { isNumber, isPlainObj, isTrue } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { GridItemSize } from "../Grid";
import { GridSpacingType, useSpacing } from "../UiProvider";

/* @ts-expect-error */
const isAuto: (size: unknown) => size is "auto" = equals("auto");

/**
 * todo: merge logic with Grid implementation and support "auto" layout properly
 */
function getSize(size: boolean | number | GridItemSize | "auto"): GridItemSize {
  if (isAuto(size)) return { xs: "auto", sm: "auto", md: "auto", lg: "auto" };
  if (isTrue(size)) return { xs: true, sm: true, md: true, lg: true };

  // size = 6
  // this item has the same size on any screen width
  if (isNumber(size)) {
    /* @ts-expect-error todo: narrow type or simplify size options */
    return { xs: size, sm: false, md: false, lg: false };
  }

  // size = { sm: 6, md: 4, lg: 3 }
  // this item has varying size dependent on screen width
  if (isPlainObj(size)) {
    return {
      xs: size.xs || false,
      md: size.md || false,
      lg: size.lg || false,
    };
  }

  throw new Error("Invalid size prop provided to GridLayoutItem component");
}

const useStyles = makeStyles({
  item: {
    flexGrow: 1,
  },
});

export interface GridLayoutProps extends ChildrenProps {
  alignItems?: MuiGridItemsAlignment;

  errorBoundary?: boolean;

  spacing?: GridSpacingType | MuiGridSpacing;
}

/**
 * todo: refactor and reuse code with Grid component
 *
 * todo: review vertical functionality
 *
 * todo: remove ErrorBoundary in production build?
 */
export function GridLayout({
  alignItems,
  children,
  spacing: spacingProp = 0,
}: GridLayoutProps): ReactElement {
  const spacingRecord = useSpacing(true);
  const spacing = isNumber(spacingProp)
    ? spacingProp
    : spacingRecord[spacingProp];

  return (
    <MuiGrid alignItems={alignItems ?? "stretch"} container spacing={spacing}>
      {children}
    </MuiGrid>
  );
}

export interface GridLayoutItemProps extends ChildrenProps {
  size?: boolean | number | GridItemSize | "auto";
}

function GridLayoutItem({
  children,
  size = 12,
}: GridLayoutItemProps): ReactElement {
  const styles = useStyles();
  const { xs, md, lg } = getSize(size);

  return (
    <MuiGrid className={styles.item} item lg={lg} md={md} xs={xs}>
      {children}
    </MuiGrid>
  );
}

GridLayout.Item = GridLayoutItem;
