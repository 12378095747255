// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { useData } from "@sinch/core";
import { Appointment, Fk, Position, selectAppointment } from "@sinch/entity";
import {
  ChipedList,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedEndStatus,
  CondensedHeader,
  CondensedStatus,
  CondensedTitle,
  ConditionalWrapper,
  DateChip,
  DisableStyle,
  ListDelimiter,
  ListItemContent,
  SimpleListItem,
  SpacingBox,
  Text,
} from "@sinch/ui";
import { startOfDay } from "date-fns";
import { groupBy, head, pipe, prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppointmentCapacity,
  AppointmentPlace,
  AppointmentStatusIcons,
  AppointmentTime,
  SearchParamsPagination,
} from "../../components";
import { requestAppointmentList } from "./api";
import { APPOINTMENT_LIST_DEFAULT_LIMIT } from "./AppointmentListContainer";

export function AppoitnmentListCondensed(): ReactElement {
  const { selectEntity, selectResult } = useData(requestAppointmentList);
  const { appointmentIds } = selectResult();

  const appointments = pipe<Appointment[], Appointment[], Record<string, Appointment[]>>(
    sortBy(prop("startTime")),
    groupBy<Appointment, string>(({ startTime }): string => `${startOfDay(startTime).getTime()}`)
  )(selectEntity(selectAppointment(appointmentIds)));

  return (
    <SpacingBox mx={-1}>
      <ChipedList>
        {Object.entries(appointments).map(([index, appointmentList]) => (
          <React.Fragment key={index}>
            {head(appointmentList) && (
              <ListDelimiter key={`chip-${index}`}>
                <DateChip color="primary" date={head(appointmentList)!.startTime} />
              </ListDelimiter>
            )}
            {appointmentList.map(({ id }: Appointment) => (
              <AppointmentListRow key={id} id={id} />
            ))}
          </React.Fragment>
        ))}
      </ChipedList>
      <SearchParamsPagination defaultLimit={APPOINTMENT_LIST_DEFAULT_LIMIT} />
    </SpacingBox>
  );
}
function AppointmentListRow({ id }: { id: Fk<Position> }): ReactElement {
  const { selectEntity } = useData(requestAppointmentList);
  const navigate = useNavigate();

  const { name, freeCapacity = 0 } = selectEntity(selectAppointment(id));

  return (
    <SimpleListItem onClick={() => navigate(`/appointment/${id}`)}>
      <ListItemContent>
        <ConditionalWrapper
          condition={freeCapacity <= 0}
          wrapper={(children) => <DisableStyle>{children}</DisableStyle>}
        >
          <CondensedCard>
            <CondensedHeader>
              <CondensedTitle>
                <Text bold>{name}</Text>
              </CondensedTitle>
              <CondensedStatus>
                <AppointmentStatusIcons id={id} />
              </CondensedStatus>
            </CondensedHeader>
            <CondensedBody>
              <CondensedContent>
                <AppointmentTime id={id} />
                <AppointmentPlace id={id} />
              </CondensedContent>
              <CondensedEndStatus>
                <AppointmentCapacity id={id} />
              </CondensedEndStatus>
            </CondensedBody>
          </CondensedCard>
        </ConditionalWrapper>
      </ListItemContent>
    </SimpleListItem>
  );
}
