import { Callback } from "@sinch/types";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";
import { useMobileLayout } from "../../UiProvider";

interface MenuContextState {
  mobile: boolean;

  onClose: Callback;

  opened: boolean;

  /**
   * Show icons and dividers, hidden on mobile.
   */
  showIcon: boolean;

  /**
   * Show title on mobile or opened drawer on desktop if true.
   * Display tooltip when false.
   *
   * Also controls displaying expandable sections.
   */
  showText: boolean;
}

const MenuContext = createCheckedContext<MenuContextState>("MenuContext");
export const { use: useMenuContext } = MenuContext;

interface MenuContextProviderProps extends ChildrenProps {
  onClose: Callback;

  opened: boolean;
}

export function MenuContextProvider({
  children,
  onClose,
  opened,
}: MenuContextProviderProps): ReactElement {
  const mobile = useMobileLayout();

  const menuContext = useMemo(
    () => ({
      mobile,
      opened,
      showIcon: !mobile,
      showText: mobile || opened,
      onClose,
    }),
    [mobile, opened, onClose]
  );

  return <MenuContext value={menuContext}>{children}</MenuContext>;
}
