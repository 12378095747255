/* eslint-disable @typescript-eslint/no-explicit-any */

import { identity, prop } from "ramda";

/**
 * create reducer callback converting items to entries based on given selectors
 * and appending them to accumulator object
 *
 * @param getValue {function} maps actual item to value in accumulator, returns
 *   the item by default (identity)
 * @param getKey {function} maps actual item to key in accumulator, returns
 *   item.id by default (idSelector)
 * @return {function(object, *): object} callback for Array#reduce
 */
function toEntry(
  getValue: (val: any) => any = identity,
  getKey: (val: any) => string = prop("id")
) {
  return (acc: any, item: any) => ({
    ...acc,
    [getKey(item)]: getValue(item),
  });
}

/**
 * create reducer function reducing given array to object using given callback
 *
 * @param callback {function(Object, *): Object} callback for Array#reduce
 * @return {function(Array): Object} prepared reducer function
 */
function reduceUsing(callback: any) {
  return (array: any[]) => array.reduce(callback, {});
}

export const entitiesToMapWithKey = (key: string): any =>
  reduceUsing(toEntry(identity, prop(key)));
