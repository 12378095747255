import { OptionsObject, useSnackbar as useNotistack, VariantType } from "notistack";
import { ReactNode, useCallback } from "react";

export type SnackbarVariant = VariantType;

export type DispatchSnackbar = (variant: SnackbarVariant, message: string | ReactNode, options?: OptionsObject) => void;

export function useSnackbar(): DispatchSnackbar {
  const { enqueueSnackbar } = useNotistack();

  return useCallback(
    (variant, message: string | ReactNode, options: OptionsObject = {}) =>
      enqueueSnackbar(message, { variant, ...options }),
    [enqueueSnackbar]
  );
}
