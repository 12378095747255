import React, { ReactElement } from "react";
import { Divider } from "../../Divider";
import { useMenuContext } from "./context";

export function MenuDivider(): ReactElement | null {
  const { showIcon } = useMenuContext();

  if (!showIcon) return null;

  return <Divider />;
}
