import { DataProvider, responseHandlerKey } from "@sinch/core";
import { Form, SelectInput, TextInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { DialogControl, ModalDialog, Text, useSnackbar } from "@sinch/ui";
import { useFormikContext } from "formik";
import { map } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { requestSuggestionsSubmit } from "./api";

const getSuggestionsPurposes = () => [
  t("Suggestion.purpose.bug"),
  t("Suggestion.purpose.feature"),
  t("Suggestion.purpose.other"),
];

export function Suggestions({ dialog }: { dialog: DialogControl }): ReactElement {
  const snackbar = useSnackbar();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestSuggestionsSubmit,
        () => {
          snackbar("success", t("Suggestion.sendMessage"));
          dialog.close();
        },
        () => {
          snackbar("error", t("Suggestion.sendMessageError"));
        }
      ),
    [snackbar, dialog]
  );

  return (
    <DataProvider handler={responseHandler}>
      <Form initialValues={{ type: "", text: "" }} submitRequest={requestSuggestionsSubmit}>
        <SuggestionDialogForm dialog={dialog} />
      </Form>
    </DataProvider>
  );
}

function SuggestionDialogForm({ dialog }: { dialog: DialogControl }) {
  const { submitForm } = useFormikContext();
  return (
    <ModalDialog
      actions={{
        cancel: { label: t("action.cancel"), action: dialog.close },
        confirm: { label: t("action.submit"), action: submitForm },
      }}
      hashKey="reportIssueForm"
      onClose={dialog.close}
      open={dialog.state}
      title={t("Suggestion.suggestionTitle")}
      width="xs"
    >
      <Text>{t("Suggestion.description")}</Text>
      <SelectInput
        label={t("Suggestion.purposeLabel")}
        name="type"
        options={map((purpose) => ({ label: purpose, value: purpose }), getSuggestionsPurposes())}
        required
      />
      <TextInput label={t("Suggestion.textLabel")} multiline name="text" required />
    </ModalDialog>
  );
}
