import { AutocompleteChangeReason, AutocompleteInputChangeReason } from "@material-ui/lab/Autocomplete";
import { BiConsumer, Key, Nullable } from "@sinch/types";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { DataProvider, DataSelectors, RequestCreatorAny, ResponseOf, useData } from "../../core";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";
import { AutocompleteInputBase } from "./AutocompleteInput";

export interface AsyncAutocompleteInputProps<TOpt = Key, TRequest = RequestCreatorAny> extends ManagedInputProps {
  getOptionLabel: (option: TOpt) => string;

  getOptionSelected: (option: TOpt, value: TOpt) => boolean;

  onInputChange: (value: string, reason: AutocompleteInputChangeReason) => void;

  renderOption: (option: TOpt) => ReactNode;

  freeSolo?: boolean;

  request: (query?: string) => unknown;

  getOptions: (data: DataSelectors<ResponseOf<TRequest>>) => TOpt[];
}

export function AsyncAutocompleteInput<TOpt = Key, TRequest = RequestCreatorAny>(
  props: AsyncAutocompleteInputProps<TOpt, TRequest>
): ReactElement {
  const [baseProps, { setValue }] = useFormField<Nullable<TOpt>, AsyncAutocompleteInputProps<TOpt, TRequest>>(props);

  return (
    <AsyncAutocompleteInputBase<TOpt, TRequest>
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      onChange={setValue}
    />
  );
}

export function AsyncAutocompleteInputBase<TOpt = Key, TRequest = RequestCreatorAny>({
  request: asyncRequest,
  dense,
  disabled,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  error,
  getOptionLabel,
  getOptionSelected,
  label,
  name,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  note,
  onChange,
  onInputChange,
  onInvalid,
  placeholder,
  renderOption,
  value,
  freeSolo,
  getOptions,
}: BaseInputProps<
  Nullable<TOpt>,
  AsyncAutocompleteInputProps<TOpt, TRequest>,
  BiConsumer<Nullable<TOpt>, AutocompleteChangeReason>
>): ReactElement {
  const [inputVal, changeInputVal] = useState<string>();
  const [request, setRequest] = useState(asyncRequest);

  useEffect(() => {
    const timeOutId = setTimeout(() => setRequest(asyncRequest(inputVal)), 500);
    return () => clearTimeout(timeOutId);
  }, [inputVal]);

  return (
    /* @ts-ignore */
    <DataProvider request={request}>
      <AsyncAutocompleteInputBaseData<TOpt>
        dense={dense}
        disabled={disabled}
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        getOptions={getOptions}
        getOptionSelected={getOptionSelected}
        label={label}
        name={name}
        onChange={onChange}
        onInputChange={(inputValue, reason) => {
          onInputChange(inputValue, reason);
          changeInputVal(inputValue);
        }}
        onInvalid={onInvalid}
        placeholder={placeholder}
        renderOption={renderOption}
        request={asyncRequest}
        required
        value={value}
      />
    </DataProvider>
  );
}

function AsyncAutocompleteInputBaseData<TOpt = Key, TRequest = RequestCreatorAny>({
  request,
  dense,
  disabled,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  error,
  getOptionLabel,
  getOptionSelected,
  label,
  name,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  note,
  onChange,
  onInputChange,
  onInvalid,
  placeholder,
  renderOption,
  value,
  freeSolo,
  getOptions,
  required = false,
}: BaseInputProps<
  Nullable<TOpt>,
  AsyncAutocompleteInputProps<TOpt, TRequest>,
  BiConsumer<Nullable<TOpt>, AutocompleteChangeReason>
>): ReactElement {
  // @ts-ignore
  const data = useData<TRequest>(request);
  const options = getOptions(data);
  return (
    <AutocompleteInputBase<TOpt>
      dense={dense}
      disabled={disabled}
      freeSolo={freeSolo}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      label={label}
      name={name}
      onChange={onChange}
      onInputChange={onInputChange}
      onInvalid={onInvalid}
      options={options}
      placeholder={placeholder}
      renderOption={renderOption}
      required={required}
      value={value}
    />
  );
}
