// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import "intro.js/introjs.css";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import introJs from "intro.js";
import { pipe, prop, sortBy } from "ramda";
import { mapIndexed } from "ramda-adjunct";
import React, {
  Dispatch,
  isValidElement,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// eslint-disable-next-line import/no-internal-modules
import { renderToStaticMarkup } from "react-dom/server";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".introjs-tooltipReferenceLayer": {
      zIndex: 1010,
    },
    ".introjs-overlay,.introjs-disableInteraction": {
      zIndex: "1009 !important",
    },
    ".introjs-helperLayer": {
      zIndex: 1008,
    },
    ".introjs-showElement": {
      zIndex: "inherit !important",
    },
  },
  tourTooltip: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.getContrastText(theme.palette.info.main),
    fontSize: 12,
    minWidth: "150px",
    marginTop: "-15px",
    "& .introjs-skipbutton": {
      color: theme.palette.getContrastText(theme.palette.info.main),
      padding: 0,
    },
    "& .introjs-arrow": {
      borderBottomColor: theme.palette.info.main,
      "&.top-right": {
        right: "20px",
      },
    },
    "& .introjs-tooltip-header": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      "& .introjs-tooltip-title": {
        fontSize: 12,
      },
    },
    "& .introjs-tooltiptext": {
      padding: `0px ${theme.spacing(1)}px`,
    },
    "& .introjs-tooltipbuttons": {
      border: "none",
      paddingTop: 0,
      "& .introjs-button": {
        float: "none",
        fontSize: 12,
        backgroundColor: theme.palette.info.main,
        color: theme.palette.getContrastText(theme.palette.info.main),
        borderColor: theme.palette.getContrastText(theme.palette.info.main),
        textShadow: "none",
        padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
        marginLeft: theme.spacing(1) / 2,
        "&:focus": {
          borderColor: theme.palette.getContrastText(theme.palette.info.main),
          boxShadow: "none",
        },
      },
    },
  },
  tourHighlight: {
    visibility: "hidden",
  },
}));

export interface Step extends Omit<introJs.Step, "intro"> {
  intro: ReactNode;
}

interface Tour {
  setSteps: Dispatch<SetStateAction<Step[]>>;
}

const TourContext = createCheckedContext<Tour>("Tour");
export const { use: useTour } = TourContext;

export interface TourProviderProps extends ChildrenProps {
  /**
   * Call after user close tour in any way
   */
  onFinish: Callback;
  /**
   * if true, tour is enabled
   */
  enabled?: boolean;
}

export function TourProvider({ children, onFinish, enabled }: TourProviderProps): ReactElement {
  const classes = useStyles();
  const introJsObj = useRef<introJs.IntroJs>();
  const [steps, setSteps] = useState<Step[]>([]);
  const context = useMemo<Tour>(
    () => ({
      setSteps,
    }),
    []
  );

  const renderAndIndexSteps = pipe(
    mapIndexed(
      (step: Step, ix): introJs.Step => {
        if (isValidElement(step.intro)) {
          return {
            step: ix,
            ...step,
            intro: renderToStaticMarkup(step.intro),
          };
        }
        return step as introJs.Step;
      }
    ),
    sortBy(prop("step"))
  );

  useEffect(() => {
    if (!introJsObj.current) {
      introJsObj.current = introJs();
      // set initial options
      introJsObj.current.setOptions({
        hidePrev: true,
        hideNext: false,
        showStepNumbers: false,
        disableInteraction: true,
        showProgress: false,
        showBullets: false,
        // @ts-expect-error
        nextToDone: true,
        tooltipClass: classes.tourTooltip,
        highlightClass: classes.tourHighlight,
        exitOnEsc: false,
        exitOnOverlayClick: false,
        nextLabel: t("next"),
        prevLabel: t("action.back"),
        doneLabel: t("tourDone"),
      });
      introJsObj.current.onexit(onFinish);
    }
  }, []);

  useEffect(() => {
    if (enabled && introJsObj.current) {
      introJsObj.current
        .setOptions({
          steps: renderAndIndexSteps(steps),
        })
        .start();
    }
  }, [steps, enabled]);

  return <TourContext value={context}>{children}</TourContext>;
}
