import { useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectUser } from "@sinch/entity";
import { t } from "@sinch/intl";
import { isArray } from "@sinch/utils";
import { has, ifElse, pipe, prop, props } from "ramda";
import React, { ReactElement } from "react";

export function PositionContact({ id }: { id: Fk<Position> }): ReactElement {
  // @ts-expect-error
  const { selectEntity } = useData();
  const { contacts } = selectEntity(selectPosition(id));

  const getDisplayContact = ifElse(
    has("userId"),
    pipe(
      // contact is User reference
      prop("userId"),
      selectUser,
      selectEntity,
      props<string, Position>(["name", "phone"])
    ),
    // contact is just plain text
    props(["name", "phone"])
  );

  return (
    <>
      {isArray(contacts) && contacts.length > 0
        ? contacts
            .map((contact) => getDisplayContact(contact))
            .map(([name, number]) => (
              <React.Fragment key={name + number}>
                {name} [{number}]
              </React.Fragment>
            ))
        : t<string>("Position.noContact")}
    </>
  );
}
