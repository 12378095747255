import { mdiCalendarBlank, mdiCalendarToday, mdiSend } from "@mdi/js";
import MuiGrid from "@material-ui/core/Grid";

import { useBusinessRules, useData, useHashNavigate } from "@sinch/core";
import { t } from "@sinch/intl";
import { Button, ButtonCard, Paper, routerLink, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "./api";

/**
 * todo: display differently for desktop/mobile
 *  desktop: calendar, available appointments, invite friend
 *  mobile: available positions, available appointments
 *  consider making ui consistent...
 *  - available positions can be separate button in both
 *  - calendar can be button in both
 */
export function Shortcuts(): ReactElement {
  const hashNav = useHashNavigate();
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { availableAppointmentCount } = selectResult();
  const { referralProgram } = useBusinessRules();
  const [outer] = useSpacing();

  return (
    <MuiGrid container direction="column" spacing={outer}>
      <MuiGrid container item spacing={outer}>
        <MuiGrid item xs={6}>
          <ButtonCard action={routerLink("/calendar")} icon={mdiCalendarBlank}>
            {t("Calendar.title")}
          </ButtonCard>
        </MuiGrid>
        <MuiGrid item xs={6}>
          <ButtonCard action={routerLink("/appointment")} icon={mdiCalendarToday}>
            {t("Appointment.titlePlural")} ({availableAppointmentCount})
          </ButtonCard>
        </MuiGrid>
      </MuiGrid>
      {referralProgram ? (
        <MuiGrid item>
          <Paper>
            <Button action={() => hashNav("/referral")} color="info" icon={mdiSend} large variant="outlined">
              {t("Referral.action.invite")}
            </Button>
          </Paper>
        </MuiGrid>
      ) : (
        <></>
      )}
    </MuiGrid>
  );
}
