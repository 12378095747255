import { Box, Divider } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Appointment, Fk, Position, selectSnippet } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Grid, Header, SpacingBox, Text, useSpacing } from "@sinch/ui";
import { useLocalStorage } from "@sinch/utils";
import { isEmpty, length, map, pipe, sum } from "ramda";
import { isNonEmptyArray } from "ramda-adjunct";
import React, { ReactElement, useMemo } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../../components";
import { getNoticesStrips } from "../../components";
import { AgendaCardList } from "../AgendaCardList";
import { AgendaCondensedList } from "../AgendaCondensedList";
import { requestDashboardAttendanceList } from "../api";
import { SquareCard } from "./components";

type FilteredFields = "place" | "contact";

const PositionListView = React.memo(
  ({
    applicants,
    appointments,
    fields,
    positionListViewSelectHeader,
    positions,
    view,
  }: {
    view: PositionView;
    positionListViewSelectHeader: JSX.Element;
    fields: FilteredFields[];
    applicants: Fk<Position>[];
    appointments: Fk<Appointment>[];
    positions: Fk<Position>[];
  }) => {
    const [, , data] = useSpacing();
    return (
      <Box py={data}>
        {view === PositionView.Condensed && (
          <SquareCard>
            {positionListViewSelectHeader}
            <SpacingBox mx={-1}>
              <Divider variant="fullWidth" />
            </SpacingBox>
            <AgendaCondensedList fields={fields} />
          </SquareCard>
        )}
        {view === PositionView.Standard && (
          <Box px={data}>
            {positionListViewSelectHeader}
            <AgendaCardList applicants={applicants} appointments={appointments} positions={positions} />
          </Box>
        )}
      </Box>
    );
  }
);

export function MobileIncomingPositions(): ReactElement {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { incomingPositionIds, applicantPositionIds, incomingAppointmentIds, noticeIds } = selectResult();
  const [view, setView] = useLocalStorage<PositionView>("listView", PositionView.Standard);
  const [fields, setFields] = useLocalStorage(`listViewFields${"IncomingPositions" || ""}`, []);

  const itemsLength = pipe(
    map(length),
    sum
  )([incomingPositionIds || [], incomingAppointmentIds || [], applicantPositionIds || []]);
  const positionsCount = length(incomingPositionIds) + length(applicantPositionIds);
  const appointmentCount = length(incomingAppointmentIds);

  const positionListViewSelectHeader = useMemo(
    () => (
      <PositionListViewSelectHeader<FilteredFields>
        count={itemsLength}
        countLabel={() => (
          <Text separator={" "}>
            <Header component="span" level={2}>
              {t("Dashboard.myAgenda")}
            </Header>
            <Text color="neutral" separator=", " small>
              {positionsCount > 0 && t("Position.display.positionCount", { count: positionsCount })}
              {appointmentCount > 0 && t("Appointment.display.appointmentCount", { count: appointmentCount })}
            </Text>
          </Text>
        )}
        fieldsKey="IncomingPositions"
        filterFields={{
          place: t("location"),
          contact: t("Position.display.contact"),
        }}
        onFieldsFilterChange={setFields}
        onViewChange={setView}
      />
    ),
    [positionsCount, appointmentCount, setView, setFields]
  );

  if (
    isEmpty(incomingPositionIds || []) &&
    isEmpty(incomingAppointmentIds || []) &&
    isEmpty(applicantPositionIds || []) &&
    isNonEmptyArray(noticeIds)
  ) {
    return (
      <Grid spacing="inner" vertical>
        {getNoticesStrips(selectEntity(selectSnippet(noticeIds)))}
      </Grid>
    );
  }

  return (
    <PositionListView
      applicants={applicantPositionIds}
      appointments={incomingAppointmentIds}
      fields={fields}
      positionListViewSelectHeader={positionListViewSelectHeader}
      positions={incomingPositionIds}
      view={view}
    />
  );
}
