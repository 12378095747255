import { useScrollAnchor } from "@sinch/ui";
import React, { ReactElement, ReactNode, useCallback, useEffect } from "react";

export type ScrollAnchorParams = {
  id: string | number;
  children?: ReactNode;
  onScrollTo?: (id: string | number) => void;
};

export function ScrollAnchor({
  id,
  children,
  onScrollTo,
}: ScrollAnchorParams): ReactElement {
  const [scrollRef, scrolled, resetScrolled] = useScrollAnchor();

  useEffect(() => {
    if (scrolled && onScrollTo) {
      resetScrolled();
      onScrollTo(id);
    }
  }, [scrolled, onScrollTo]);

  return (
    /* @ts-expect-error */
    <div ref={scrollRef} id={id}>
      {children}
    </div>
  );
}
