import { mdiShuffleVariant, mdiCheck } from "@mdi/js";
import { Box } from "@material-ui/core";

import { useCurrentUser, useData } from "@sinch/core";
import { Appointment, Fk, selectAppointment, selectAppointmentAttendance } from "@sinch/entity";
import { Icon } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";

export function AppointmentStatusIcons({ id }: { id: Fk<Appointment> }): ReactElement {
  // @ts-expect-error
  const { selectEntity } = useData();
  const icons = [];
  const { id: currentWorkerId } = useCurrentUser();
  const {
    conflicting: { position, appointment },
  } = selectEntity(selectAppointment(id));
  const isAttending = !isEmpty(selectEntity(selectAppointmentAttendance({ appointment: id, worker: currentWorkerId })));

  if (!isEmpty(position) || !isEmpty(appointment))
    icons.push(<Icon key="conflicting" color="error" icon={mdiShuffleVariant} size="small" />);
  if (isAttending) icons.push(<Icon key="attending" color="success" icon={mdiCheck} size="small" />);

  return (
    <Box display="flex" flexWrap="noWrap">
      {icons}
    </Box>
  );
}
