import { makeStyles } from "@material-ui/core/styles";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { useMobileLayout } from "../../UiProvider";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: theme.spacing(7),

    "&:not($mobile)": {
      minWidth: theme.spacing(8),
    },
  },
  grow: {
    flexGrow: 1,
    justifyContent: "start",

    "&:not($mobile)": {
      padding: theme.spacing(0, 2),
    },
  },
  hidden: {
    display: "none",
  },
  mobile: {},
}));

interface ToolbarItemProps extends ChildrenProps {
  grow?: boolean;

  hidden?: boolean;
}

export function ToolbarItem({
  children,
  grow,
  hidden,
}: ToolbarItemProps): ReactElement {
  const styles = useStyles();
  const mobile = useMobileLayout();

  return (
    <div
      className={clsx(styles.item, {
        [styles.grow]: grow,
        [styles.hidden]: hidden,
        [styles.mobile]: mobile,
      })}
    >
      {children}
    </div>
  );
}
