import { ChildrenProps } from "@sinch/utils";
import { mergeRight } from "ramda";
import React, { Children, ReactElement } from "react";
import { useStyles } from "./styles";
import { Text } from "./Text";
import { TextParams } from "./TextParams";
import {
  TextParamsProvider,
  useTextParamsRequired,
} from "./TextParamsProvider";

/**
 * StructuredText component is designated to render text containing one or more
 * paragraphs. For rendering lists, labels and other non-structured text,
 * simple Text component should be used instead.
 */
export function StructuredText({
  children,
  ...textParams
}: TextParams & ChildrenProps): ReactElement {
  const styles = useStyles();

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <TextParamsProvider structured {...textParams}>
      <div className={styles.structuredContainer}>
        {Children.toArray(children)}
      </div>
    </TextParamsProvider>
  );
}

export function P({
  children,
  ...ownProps
}: TextParams & ChildrenProps): ReactElement {
  const contextParams = useTextParamsRequired("P", true);
  const textParams = mergeRight(contextParams, ownProps);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <Text {...textParams}>{children}</Text>;
}
