import { CircularProgress, LinearProgress } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { t } from "@sinch/intl";
import React, { ReactElement, ReactNode } from "react";
import { Header } from "../Text";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: fade(theme.palette.background.paper, 0.8),
    zIndex: 1600,
    cursor: "pointer",
  },
  overlayText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  linearBottom: {
    position: "fixed",
    top: "0px",
    width: "100vh",
    zIndex: "10010",
    left: "0px",
  },
}));

export function Loading(): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <MuiGrid alignItems="center" container direction="column">
        <MuiGrid item>
          <CircularProgress />
        </MuiGrid>
        <MuiGrid item>
          <Header>{t("loading")}</Header>
        </MuiGrid>
      </MuiGrid>
    </div>
  );
}

interface WithOverlayProps {
  children: ReactNode;
}

function WithOverlay({ children }: WithOverlayProps): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayText}>{children}</div>
    </div>
  );
}

export function LoadingOverlay(): ReactElement {
  return (
    <WithOverlay>
      <Loading />
    </WithOverlay>
  );
}

export function LoadingLinearTop(): ReactElement {
  const styles = useStyles();
  return <LinearProgress className={styles.linearBottom} />;
}
