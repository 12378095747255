import { Box, lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mdiCalendarCheck } from "@mdi/js";

// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { useCurrentUser, useData } from "@sinch/core";
import {
  Appointment,
  Fk,
  Id,
  Position,
  PositionAttendance,
  PositionQ,
  selectAppointment,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  ChipedList,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedHeader,
  CondensedStatus,
  CondensedTitle,
  Icon,
  ListItemBorder,
  ListItemContent,
  routerLink,
  SimpleListItem,
  SpacingBox,
  Text,
  useSpacing,
} from "@sinch/ui";
import clsx from "clsx";
import { concat, flatten, head, includes, isEmpty, prop, sortBy, values } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { AppointmentContact, AppointmentPlace, AppointmentTime } from "../../Appointment";
import { AttendanceConfirmButtons } from "../../components/AttendanceConfirmButtons";
import {
  PositionApplicant,
  PositionContact,
  PositionPlace,
  PositionRole,
  PositionStatusIcons,
  PositionTime,
} from "../../Position";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "./api";
import { DashboardPositionEmptyMessage } from "./DashboardEmptyMessage";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: `${theme.palette.text.disabled} !important`,
    // filter: "grayscale(100%)",
  },
  confirmButtons: {
    position: "absolute",
    height: "100%",
    right: "4px",
    top: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
    paddingLeft: "8px",
    boxShadow: `-5px 0px 5px 0px ${lighten(theme.palette.warning.main, 0.9)}`,
  },
  warning: {
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
  },
}));

type TypedIdItem = { id: Id; type: "position" | "appointment"; startTime: Date };

export interface PositionTableProps {
  fields: string[];
}

export function AgendaCondensedList({ fields }: PositionTableProps): ReactElement {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const {
    incomingPositionIds,
    applicantPositionIds,
    incomingAppointmentIds,
    confirmationApplicantPositionIds,
  } = selectResult();

  const isListEmpty =
    isEmpty(incomingPositionIds || []) && isEmpty(incomingAppointmentIds || []) && isEmpty(applicantPositionIds || []);

  if (isListEmpty) {
    return <DashboardPositionEmptyMessage />;
  }

  const appointmentList = incomingAppointmentIds ? selectEntity(selectAppointment(incomingAppointmentIds)) : [];
  const positionsList = selectEntity(selectPosition(concat(incomingPositionIds, applicantPositionIds)));
  const appointmentItems = appointmentList.map<TypedIdItem>(
    ({ id, startTime }: Pick<Appointment, "id" | "startTime">) => ({
      id,
      type: "appointment",
      startTime,
    })
  );
  const positionItems = positionsList.map<TypedIdItem>((position) => ({
    id: position.id,
    type: "position",
    startTime: position.startTime,
  }));

  const items = sortBy(prop("startTime"))(concat(appointmentItems, positionItems));

  return (
    <SpacingBox mx={-1}>
      <ChipedList>
        {items.map(({ id, type }) =>
          type === "position" ? (
            <PositionListRow
              key={id}
              fields={fields}
              id={id}
              isConfirmationApplicant={includes(id, flatten(values(confirmationApplicantPositionIds)))}
            />
          ) : (
            <AppointmentListRow key={id} fields={fields} id={id} />
          )
        )}
      </ChipedList>
    </SpacingBox>
  );
}

const PositionListRow = React.memo(
  ({
    id,
    fields,
    isConfirmationApplicant,
  }: { id: Fk<Position>; isConfirmationApplicant: boolean } & PositionTableProps): ReactElement => {
    const { id: workerId } = useCurrentUser();
    const classes = useStyles();
    const { selectResult, queryEntity, selectEntity } = useData(requestDashboardAttendanceList);
    const isDisabled = queryEntity(PositionQ.isCancelled(id));
    const [, , data] = useSpacing();

    const { applicantPositionIds, confirmationApplicantPositionIds } = selectResult();

    const groupedPositionIds =
      head(
        selectEntity<PositionAttendance["groupedPositionIds"]>(
          selectPositionAttendance({ position: id, worker: workerId }, "groupedPositionIds")
        ) ?? []
      ) ?? [];
    const isConfirmationRequiredInGroupedPosition =
      values(confirmationApplicantPositionIds).some((ids) => ids.includes(id) && ids.length > 1) ||
      (includes(id, groupedPositionIds) && groupedPositionIds.length > 1);

    const { role, shift } = selectEntity(selectPosition(id));
    const canOnSitePresence =
      role === WorkerRole.Crewboss &&
      !includes(id, applicantPositionIds) &&
      selectEntity(selectShift(shift, "presence"));

    return (
      <SimpleListItem
        action={routerLink(`/position/${id}`)}
        className={clsx(
          isDisabled && classes.disabled,
          queryEntity(PositionQ.User.isRequiredConfirmation(id)) && classes.warning
        )}
        // onClick={handleRedirect}
      >
        <ListItemBorder>
          {includes(id, applicantPositionIds) ? <PositionApplicant /> : <PositionRole id={id} />}
        </ListItemBorder>
        <ListItemContent>
          <PositionListItemContent fields={fields} id={id} />
          {canOnSitePresence && (
            <Box display="flex" justifyContent="flex-end" pt={data}>
              <Button action={routerLink(`/presence/${shift}`)} color="info" size="small" stretch variant="outlined">
                {t("Shift.closing.recordAttendanceButton")}
              </Button>
            </Box>
          )}
          {queryEntity(PositionQ.User.isRequiredConfirmation(id)) && (
            <Box className={classes.confirmButtons}>
              {isConfirmationRequiredInGroupedPosition ? (
                <Text>{t("Position.groupedPositionConfirmation")}</Text>
              ) : (
                <AttendanceConfirmButtons
                  condensed
                  confirmationType={isConfirmationApplicant ? "applicant" : "position"}
                  positionId={id}
                />
              )}
            </Box>
          )}
        </ListItemContent>
      </SimpleListItem>
    );
  }
);

function AppointmentListRow({ id, fields }: { id: Fk<Appointment> } & PositionTableProps): ReactElement {
  const { selectEntity } = useData(requestDashboardAttendanceList);
  const navigate = useNavigate();

  const { name } = selectEntity(selectAppointment(id));

  return (
    <SimpleListItem onClick={() => navigate(`/appointment/${id}`)}>
      <ListItemBorder>
        <Icon color="success" icon={mdiCalendarCheck} size="small" />
      </ListItemBorder>
      <ListItemContent>
        <CondensedCard>
          <CondensedHeader>
            <CondensedTitle>
              <Text bold>{name}</Text>
            </CondensedTitle>
          </CondensedHeader>
          <CondensedBody>
            <CondensedContent>
              <AppointmentTime id={id} />
              {includes("place", fields) && <AppointmentPlace id={id} />}
              {includes("contact", fields) && <AppointmentContact id={id} />}
            </CondensedContent>
          </CondensedBody>
        </CondensedCard>
      </ListItemContent>
    </SimpleListItem>
  );
}

const PositionListItemContent = React.memo(
  ({ id, fields }: { id: Fk<Position> } & PositionTableProps): ReactElement => (
    <CondensedCard>
      <CondensedHeader>
        <CondensedTitle>
          <Text bold>
            <PositionReference id={id} name noId title />
          </Text>
        </CondensedTitle>
        <CondensedStatus>
          <PositionStatusIcons id={id} statuses={["transport", "conflict", "cancelled"]} />
        </CondensedStatus>
      </CondensedHeader>
      <CondensedBody>
        <CondensedContent>
          <PositionTime id={id} />
          {includes("place", fields) && <PositionPlace id={id} />}
          {includes("contact", fields) && <PositionContact id={id} />}
        </CondensedContent>
      </CondensedBody>
    </CondensedCard>
  )
);
