import { makeStyles, Paper as MuiPaper } from "@material-ui/core";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";

const useStyles = makeStyles(({ mixins: { component }, spacing, zIndex }) => ({
  menuContainer: {
    flexShrink: 0,
    height: `calc(100vh - ${spacing(8)}px)`,
    minWidth: spacing(8),
    overflowY: "auto",
    position: "sticky",
    top: component.titleBar.large.height,
    zIndex: zIndex.drawer,
    maxWidth: "320px",
  },
}));

export function SideMenuDrawer({ children }: ChildrenProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiPaper className={styles.menuContainer} component="nav" square>
      {children}
    </MuiPaper>
  );
}
