/* eslint-disable import/no-extraneous-dependencies */
import { useCurrentUser } from "@sinch/core";
import { useCallback } from "react";
import {
  RequestCreatorAny,
  ResponseData,
  ResponseOf,
  ServerMessage,
} from "../contract";
import { useResponse } from "./ResponseProvider";

/**
 * todo: resolve naming conflict with EntitySelector from entity package
 */
export type EntitySelector<TResponse extends ResponseData, TOut> = (
  entities: TResponse["entities"]
) => TOut;

export interface SelectEntity<TResponse extends ResponseData> {
  (): TResponse["entities"];

  <TOut>(selector: EntitySelector<TResponse, TOut>): TOut;
}

export type MessageSelector<TOut> = (messages: ServerMessage[]) => TOut;

export interface SelectMessage {
  (): ServerMessage[];

  <TOut>(selector: MessageSelector<TOut>): TOut;
}

export type MetaSelector<TResponse extends ResponseData, TOut> = (
  meta: TResponse["meta"]
) => TOut;

export interface SelectMeta<TResponse extends ResponseData> {
  (): TResponse["meta"];

  <TOut>(selector: MetaSelector<TResponse, TOut>): TOut;
}

export type ResultSelector<TResponse extends ResponseData, TOut> = (
  result: TResponse["result"]
) => TOut;

export interface SelectResult<TResponse extends ResponseData> {
  (): TResponse["result"];

  <TOut>(selector: ResultSelector<TResponse, TOut>): TOut;
}

export type ResponseSelector<TResponse extends ResponseData, TOut> = (
  response: Required<TResponse>
) => TOut;

export interface SelectResponse<TResponse extends ResponseData> {
  (): Required<TResponse>;

  <TOut>(selector: ResponseSelector<TResponse, TOut>): TOut;
}

export type EntityQuery<TResponse extends ResponseData, TOut> = (
  entities: TResponse["entities"],
  context: { currentUser: ReturnType<typeof useCurrentUser> }
) => TOut;

/**
 * @deprecated see {@link DataSelectors.queryEntity}
 */
export type QueryEntity<TResponse extends ResponseData> = <TOut>(
  query: EntityQuery<TResponse, TOut>
) => TOut;

export interface DataSelectors<TResponse extends ResponseData> {
  select: SelectResponse<TResponse>;

  selectEntity: SelectEntity<TResponse>;

  selectMessage: SelectMessage;

  selectMeta: SelectMeta<TResponse>;

  selectResult: SelectResult<TResponse>;

  /**
   * @deprecated should be used only for status queries,
   *  not to retrieve individual entity data (use selectEntity for that)
   *
   * todo: remove when new selector api is implemented
   */
  queryEntity: QueryEntity<TResponse>;
}

/**
 * todo: consider renaming to useResponse for consistency with
 *  useRequest and useRequestState? discuss proper naming...
 *
 * todo: consider supporting string params in selectors working like ramda
 *  prop/path to significantly simplify usage
 *  (selector could accept function, single string or string array)
 *
 * todo: remove query usage and replace with new selector api implementation
 *  - pass application context at the layer resolving the request
 *    (ViewComponent? DataProvider?) -> fixes dependency problem
 *  - consider supporting mixed selectors accessing whole response at once, eg:
 *    `const selectFinishedPositions =
 *      ({ entities, result }) => pick(result.finished, entities.Position)`
 *
 * todo: consider if resource accessors should be defined as named functions
 *  on individual view containers, or used directly in underlying components?
 */
export function useData<TCreator extends RequestCreatorAny>(
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  creator: TCreator
): DataSelectors<ResponseOf<TCreator>> {
  const response = useResponse();
  const { entities, messages, meta, result } = response;

  const select = useCallback(
    <TOut,>(selector?: ResponseSelector<ResponseOf<TCreator>, TOut>) =>
      selector
        ? selector(response as Required<ResponseOf<TCreator>>)
        : response,
    [response]
  );

  const selectEntity = useCallback(
    <TOut,>(selector?: EntitySelector<ResponseOf<TCreator>, TOut>) =>
      selector ? selector(entities) : entities,
    [entities]
  );

  const selectMessage = useCallback(
    <TOut,>(selector?: MessageSelector<TOut>) =>
      selector ? selector(messages) : messages,
    [messages]
  );

  const selectMeta = useCallback(
    <TOut,>(selector?: MetaSelector<ResponseOf<TCreator>, TOut>) =>
      selector ? selector(meta) : meta,
    [meta]
  );

  const selectResult = useCallback(
    <TOut,>(selector?: ResultSelector<ResponseOf<TCreator>, TOut>) =>
      selector ? selector(result) : result,
    [result]
  );

  const currentUser = useCurrentUser();

  /**
   * @deprecated see {@link DataSelectors.queryEntity}
   */
  const queryEntity = useCallback(
    <TOut,>(selector: EntityQuery<ResponseOf<TCreator>, TOut>) =>
      selector(entities, { currentUser }),
    [currentUser, entities]
  );

  return {
    select,
    selectEntity,
    selectMessage,
    selectMeta,
    selectResult,
    queryEntity,
  };
}
