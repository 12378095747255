import { DataProvider, HashRoute, responseHandlerKey, responseHandlerSequence, useRequest } from "@sinch/core";
import { Fk, Payout, PayoutMethod } from "@sinch/entity";
import { t } from "@sinch/intl";
import { InformationDialog, useSnackbar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PayoutRequestCancel,
  PayoutRequestCreate,
  PayoutRequestViewDialog,
  requestWalletPayoutCancel,
  requestWalletPayoutCreate,
} from "../PayoutRequest";
import { requestWalletPayoutDetail } from "./api";
import { useWalletContext } from "./WalletContext";

export interface PayoutDetailContainerParams {
  payout: string;

  balance: number;

  availablePayout: Fk<PayoutMethod>[];
}

function PayoutDetailContainer({ payout, availablePayout, balance }: PayoutDetailContainerParams): ReactElement {
  const id = parseInt(payout, 10);
  const request = useMemo(() => requestWalletPayoutDetail(id), [id]);

  return (
    <DataProvider request={request}>
      <PayoutRequestViewDialog availablePayout={availablePayout} balance={balance} id={id} />
    </DataProvider>
  );
}

export interface PayoutCancelParamContainerParams {
  payout: Fk<Payout>;
}

function PayoutCancelParamContainer({ payout }: PayoutCancelParamContainerParams): ReactElement {
  const dispatch = useRequest();
  return (
    <InformationDialog
      actions={{ confirm: { action: () => dispatch(requestWalletPayoutCancel(payout)), color: "error" } }}
      denyFullscreen
      title={t("Wallet.action.cancelPayout")}
      width="xs"
    >
      <PayoutRequestCancel />
    </InformationDialog>
  );
}

export interface PayoutRequestContainerParams {
  balance: number;

  availablePayout: Fk<PayoutMethod>[];
}

export function PayoutRequestContainer({ balance, availablePayout }: PayoutRequestContainerParams): ReactElement {
  // todo: simplify closing modal navigation
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // todo: pass parent container refresh token

  const { refresh } = useWalletContext();

  const snackbar = useSnackbar();
  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestWalletPayoutCreate,
          ({ request: { params } }) => {
            snackbar(
              "success",
              params.payout ? t("PayoutRequest.payoutRequestEdited") : t("PayoutRequest.payoutRequestSent")
            );
            navigate(pathname);
            refresh();
          },
          ({ request: { params } }) => {
            snackbar(
              "error",
              params.payout ? t("PayoutRequest.payoutRequestNotEdited") : t("PayoutRequest.payoutRequestNotSent")
            );
            refresh();
          }
        ),
        responseHandlerKey(
          requestWalletPayoutCancel,
          () => {
            snackbar("success", t("PayoutRequest.payoutRequestCanceled"));
            navigate(pathname);
            refresh();
          },
          () => {
            snackbar("error", t("PayoutRequest.payoutRequestNotCanceled"));
            refresh();
          }
        ),
      ]),
    [navigate, pathname, refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <HashRoute path="payout/:payout">
        {/* @ts-expect-error */}
        <PayoutDetailContainer availablePayout={availablePayout} balance={balance} />
      </HashRoute>
      <HashRoute path="payout/:payout/cancel">
        {/* @ts-expect-error */}
        <PayoutCancelParamContainer />
      </HashRoute>
      <HashRoute path="payout">
        <InformationDialog title={t("Wallet.action.requestPayout") as string} width="xs">
          <PayoutRequestCreate availablePayout={availablePayout} balance={balance} />
        </InformationDialog>
      </HashRoute>
    </DataProvider>
  );
}
