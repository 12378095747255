import { isDate } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { DateFormatVariant } from "./dateTime";
import { useFormat } from "./FormatProvider";
import { RelativeTimeUnit } from "./relativeTime";

interface FormatBaseProps {
  curr?: never;

  dt?: never;

  num?: never;

  rt?: never;
}

interface CurrencyFormatProps extends Omit<FormatBaseProps, "curr"> {
  curr: number;
}

interface DateTimeFormatProps extends Omit<FormatBaseProps, "dt"> {
  dt: Date | [start: Date, end: Date];

  variant?: DateFormatVariant;
}

interface NumberFormatProps extends Omit<FormatBaseProps, "num"> {
  num: number;
}

interface RelativeTimeFormatProps extends Omit<FormatBaseProps, "rt"> {
  rt: number;

  unit: RelativeTimeUnit;
}

type FormatProps =
  | CurrencyFormatProps
  | DateTimeFormatProps
  | NumberFormatProps
  | RelativeTimeFormatProps;

const hasCurr = (props: FormatProps): props is CurrencyFormatProps =>
  "curr" in props;

const hasDt = (props: FormatProps): props is DateTimeFormatProps =>
  "dt" in props;

const hasNum = (props: FormatProps): props is NumberFormatProps =>
  "num" in props;

const hasRt = (props: FormatProps): props is RelativeTimeFormatProps =>
  "rt" in props;

export function Format(props: FormatProps): ReactElement {
  const { curr, dt, num, rt } = useFormat();

  if (hasCurr(props)) {
    const value = props.curr;

    return <span>{curr(value)}</span>;
  }

  if (hasDt(props)) {
    const { variant = "date" } = props;
    const format = dt[variant];
    const value = props.dt;

    if (isDate(value)) {
      return <span>{format(value)}</span>;
    }

    const [start, end] = value;

    return (
      <span>
        {format(start)} - {format(end)}
      </span>
    );
  }

  if (hasNum(props)) {
    const value = props.num;

    return <span>{num(value)}</span>;
  }

  if (hasRt(props)) {
    const { unit } = props;
    const value = props.rt;

    return <span>{rt(value, unit)}</span>;
  }

  throw new Error("Invalid Format props");
}
