import { Grid as MuiGrid } from "@material-ui/core";
import { mdiChevronLeft } from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import { useProgress } from "@sinch/core/backend/provider/ProgressProvider";
import { UserRoles } from "@sinch/core/contract";
import {
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Consumer } from "@sinch/types";
import {
  Button,
  LoadingOverlay,
  ModalDialog,
  Paper,
  useDialog,
  useMobileLayout,
  useScroll,
  useSpacing,
} from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import { isEmpty, isNil } from "ramda";
import React, { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { requestPresenceView } from "./api";
import {
  FullEdit,
  FullEditProps,
  PresenceWorkerDetailEdit,
  PresenceWorkerDetailInfo,
  PresenceWorkerDetailStatus,
} from "./Detail";

export function PresenceDetailWorker({ id }: { id: Fk<PositionAttendance> }): ReactElement {
  const [outer] = useSpacing();
  const scrollTo = useScroll();
  const mobile = useMobileLayout();
  const { inProgress } = useProgress();
  const [show, , setEditVisibility] = useToggleState();
  const navigate = useNavigate();
  const { currentRole } = useCurrentUser();

  const handleEdit = () => {
    setEditVisibility(true);
  };

  useEffect(() => {
    if (mobile) {
      scrollTo("top");
    }
  }, []);

  return (
    <>
      {inProgress && <LoadingOverlay />}
      <MuiGrid container direction="column" spacing={outer}>
        {mobile && currentRole === UserRoles.Admin && (
          <MuiGrid item>
            <Paper>
              <Button action={() => navigate(-1)} icon={mdiChevronLeft} stretch variant="text">
                {t("action.back")}
              </Button>
            </Paper>
          </MuiGrid>
        )}
        <MuiGrid item>
          <PresenceWorkerDetailInfo id={id} />
        </MuiGrid>
        <MuiGrid item>
          <PresenceWorkerDetailStatus id={id} onEdit={handleEdit} />
        </MuiGrid>
        <MuiGrid item>
          <FullEditForm id={id} setEditVisibility={setEditVisibility} show={show} />
        </MuiGrid>
        <MuiGrid item>
          <PresenceWorkerDetailEdit id={id} />
        </MuiGrid>
      </MuiGrid>
    </>
  );
}

function FullEditForm({
  id,
  show,
  setEditVisibility,
}: {
  id: Fk<PositionAttendance>;
  show: boolean;
  setEditVisibility: Consumer<boolean>;
}): ReactElement | null {
  const { selectEntity } = useData(requestPresenceView);
  const dialog = useDialog();
  const mobile = useMobileLayout();
  const { workBreak, position, progressStatus } = selectEntity(selectPositionAttendance(id));
  const { workBreak: shiftWorkBreak, role } = selectEntity(selectPosition(position));
  const backup = role === WorkerRole.Backup;

  const initialWorkBreak = useMemo(
    () =>
      workBreak || shiftWorkBreak
        ? {
            startTime: workBreak?.startTime || shiftWorkBreak?.startTime || new Date(),
            endTime: workBreak?.endTime || shiftWorkBreak?.endTime || new Date(),
          }
        : undefined,
    [workBreak, shiftWorkBreak]
  );

  let editItems: FullEditProps["items"] = [];
  if (progressStatus === ClosingProgress.Present) {
    editItems = ["startTime"];
  } else if (progressStatus === ClosingProgress.Break) {
    editItems = ["breakStart"];
  } else if (progressStatus === ClosingProgress.AfterBreak) {
    editItems = ["breakStart", "breakEnd"];
  } else if (!backup && progressStatus === ClosingProgress.Finished) {
    editItems = ["startTime", "endTime", "breakStart", "breakEnd"];
  } else if (backup && progressStatus === ClosingProgress.Finished) {
    editItems = ["startTime"];
  }

  if (!show || isEmpty(editItems)) {
    return null;
  }

  return (
    <ModalDialog onClose={() => dialog.close()} open title={t("Shift.closing.editTime")} width={mobile ? "lg" : "xs"}>
      <FullEdit
        attendance={id}
        hasBreak={!isNil(initialWorkBreak)}
        items={editItems}
        onCancel={() => setEditVisibility(false)}
      />
    </ModalDialog>
  );
}
