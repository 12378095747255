import { useTheme } from "@material-ui/core";
import { Maybe } from "@sinch/types";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMobileLayout } from "./UiProvider";

export function useScrollAnchor(): [
  MutableRefObject<Maybe<HTMLElement>>,
  boolean,
  () => void
] {
  const { hash, state, ...location } = useLocation();
  const mobile = useMobileLayout();
  const {
    mixins: { component, onLarge },
    spacing,
  } = useTheme();
  const navigate = useNavigate();
  const ref = useRef<HTMLElement>();

  const [scrolled, setScrolled] = useState(false);

  /* @ts-expect-error - todo: type properly together with scroll anchor */
  const scrollToken = state?.scrollToken;

  useEffect(() => {
    if (ref.current && `#${ref.current.id}` === hash) {
      ref.current.scrollIntoView();
      if (mobile) {
        const { height } = {
          ...component.titleBar.small,
          ...onLarge({
            ...component.titleBar.large,
          }),
        };
        window.scrollBy(0, -height - spacing(1));
      }
      navigate(location, { replace: true, state });
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [hash, scrollToken]);

  const resetScrolled = useCallback(() => setScrolled(false), [scrolled]);

  return [ref, scrolled, resetScrolled];
}
