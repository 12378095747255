import { defineRequest } from "@sinch/core";
import { ClosingProgress, Fk, PositionAttendance, Shift } from "@sinch/entity";
import { AttendancePresenceFormState } from "./form";

export const requestShiftAttendancePresence = defineRequest("Shifts/Presence")
  .withParams<AttendancePresenceFormState>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftNoteEdit = defineRequest("Shifts/NoteEdit")
  .withParams<{ shift: Fk<Shift>; note: string }>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceArrived = defineRequest("Presences/PresenceArrived")
  .withParams<Record<Fk<PositionAttendance>, Date>>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresencePending = defineRequest("Presences/PresencePending")
  .withParams<{ attendanceId: Fk<PositionAttendance> }>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceResetBreak = defineRequest("Presences/PresenceResetBreak")
  .withParams<{ attendanceId: Fk<PositionAttendance> }>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceBreak = defineRequest("Presences/PresenceBreak")
  .withParams<Record<Fk<PositionAttendance>, Date>>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceBreakEnd = defineRequest("Presences/PresenceBreakEnd")
  .withParams<Record<Fk<PositionAttendance>, Date>>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceAbsent = defineRequest("Presences/PresenceAbsent")
  .withParams<{ attendanceId: Fk<PositionAttendance> }>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceFinished = defineRequest("Presences/PresenceFinished")
  .withParams<Record<Fk<PositionAttendance>, Date>>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceNote = defineRequest("Presences/PresenceNote")
  .withParams<{ attendanceId: Fk<PositionAttendance>; note: string }>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceTimes = defineRequest("Presences/PresenceTime")
  .withParams<
    Record<
      Fk<PositionAttendance>,
      {
        startTime?: Date;
        endTime?: Date;
        breakStart?: Date;
        breakEnd?: Date;
        hasBreak?: boolean;
      }
    >
  >()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));

export const requestShiftPresenceMoveToPosition = defineRequest("Presences/MoveToPosition")
  .withParams<{
    attendanceId: Fk<PositionAttendance>;
    positionId: string;
    time: Date;
    progressStatus: ClosingProgress;
  }>()
  .withEmptyResponse()
  .withResponseMeta<{ redirect?: { type: string; id: number } }>()
  .withCreator((params) => ({
    params,
  }));
