import {
  Box,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  fade,
  Grid as MuiGrid,
  makeStyles,
} from "@material-ui/core";
import { mdiAccountClock, mdiCar, mdiCheckboxBlankCircle, mdiStopCircleOutline } from "@mdi/js";

/* eslint-disable import/no-internal-modules,import/no-extraneous-dependencies */
import { useData } from "@sinch/core";
import { PositionQ, PositionStatus, selectPosition, selectShift, WorkerRole } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import {
  Button,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedHeader,
  CondensedTitle,
  Header,
  Icon,
  IconText,
  routerLink,
  Text,
} from "@sinch/ui";
import { CardActionArea } from "@sinch/ui/Card/Card";
import clsx from "clsx";
import { differenceInMinutes } from "date-fns";
import { includes } from "ramda";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  PositionApplicant,
  PositionContact,
  PositionPlace,
  PositionRole,
  PositionTime,
  StaffClockIn,
} from "../../../components";
import { PositionReference } from "../../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "../api";

const useStyles = makeStyles((theme) => ({
  success: {
    background: fade(theme.palette.success.light, 0.1),
  },
  info: {
    background: fade(theme.palette.info.light, 0.1),
  },
  headerAction: {
    margin: 0,
  },
  cardAction: {
    justifyContent: "flex-end",
  },
  smallGutters: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

/**
 * Mobile position in progress element, needs DashboardAttendanceList context
 * @context DashboardAttendanceList
 */
export function MobilePositionInProgress({ positionId }: { positionId: number }): ReactElement {
  const styles = useStyles();
  const { selectEntity, selectResult, queryEntity } = useData(requestDashboardAttendanceList);
  const { applicantPositionIds } = selectResult();
  const { startTime, shift, status, role, clockInState } = selectEntity(selectPosition(positionId));
  const { t } = useTranslation();
  const { dur } = useFormat();
  const location = queryEntity(PositionQ.isGeolocationActive(positionId))
    ? queryEntity(PositionQ.Location.getLocation(positionId))
    : undefined;

  const startTimeSelfClocked = queryEntity(PositionQ.User.getSelfClockedStartTime(positionId));
  const endTimeSelfClocked = queryEntity(PositionQ.User.getSelfClockedEndTime(positionId));

  const canOnSitePresence =
    role === WorkerRole.Crewboss &&
    !includes(positionId, applicantPositionIds) &&
    selectEntity(selectShift(shift, "presence"));

  function getTime() {
    if (status === PositionStatus.Running) {
      return t("rightNow");
    }
    if (status === PositionStatus.Open) {
      dur.narrowShort(differenceInMinutes(startTime, new Date()) * 60);
    }
    return null;
  }

  return (
    <MuiCard square={false}>
      <CardActionArea action={routerLink(`/position/${positionId}`)}>
        <CardHeader
          action={getTime()}
          classes={{ action: styles.headerAction }}
          className={clsx(styles.smallGutters, styles[status === PositionStatus.Finished ? "info" : "success"])}
          disableTypography
          title={
            <MuiGrid container spacing={1} wrap="nowrap">
              <MuiGrid item>
                <Icon
                  color={status === PositionStatus.Finished ? "info" : "success"}
                  icon={status === PositionStatus.Finished ? mdiStopCircleOutline : mdiCheckboxBlankCircle}
                  size="small"
                />
              </MuiGrid>
              <MuiGrid item>
                <Header level={2}>
                  <>
                    {status === PositionStatus.Open && t("Dashboard.progressCard.beginSoon")}
                    {status === PositionStatus.Running && t("Position.status.inProgress")}
                    {status === PositionStatus.Finished && t("Dashboard.progressCard.recentlyFinished")}
                  </>
                </Header>
              </MuiGrid>
            </MuiGrid>
          }
        />
        <CardContent className={styles.smallGutters}>
          <MuiGrid container spacing={1} wrap="nowrap">
            <MuiGrid item xs="auto">
              {includes(positionId, applicantPositionIds) ? <PositionApplicant /> : <PositionRole id={positionId} />}
            </MuiGrid>
            <MuiGrid item xs>
              <CondensedCard wrap>
                <CondensedHeader>
                  <CondensedTitle wrap>
                    <Text bold>
                      <PositionReference id={positionId} name noId title />
                    </Text>
                  </CondensedTitle>
                </CondensedHeader>
                <CondensedBody>
                  <CondensedContent>
                    <Box whiteSpace="nowrap">
                      <PositionTime id={positionId} withMeetup />
                    </Box>
                    <PositionPlace id={positionId} />
                    <PositionContact id={positionId} />
                    {queryEntity(PositionQ.hasTransport(positionId)) && (
                      <IconText icon={mdiCar}>{t<string>("display.transport")}</IconText>
                    )}
                  </CondensedContent>
                </CondensedBody>
              </CondensedCard>
            </MuiGrid>
          </MuiGrid>
        </CardContent>
        {(canOnSitePresence || clockInState > 0) && (
          <CardActions className={styles.cardAction} disableSpacing>
            <MuiGrid container justify="flex-end" spacing={1}>
              {clockInState > 0 && (
                <MuiGrid item xs={12}>
                  <StaffClockIn
                    // canAddFiles={}
                    clockInState={clockInState}
                    endTimeClocked={endTimeSelfClocked}
                    location={location}
                    position={positionId}
                    positionStartTime={startTime}
                    startTimeClocked={startTimeSelfClocked}
                  />
                </MuiGrid>
              )}
              {canOnSitePresence && (
                <MuiGrid item>
                  <Button
                    action={routerLink(`/presence/${shift}`)}
                    color="info"
                    icon={mdiAccountClock}
                    size="small"
                    stretch
                    variant="outlined"
                  >
                    {t<string>("Shift.closing.recordAttendanceButton")}
                  </Button>
                </MuiGrid>
              )}
            </MuiGrid>
          </CardActions>
        )}
      </CardActionArea>
    </MuiCard>
  );
}
