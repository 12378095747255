import { eqBy, length } from "ramda";

type HasLength = string | unknown[] | Record<"length", unknown>;

interface EqByLengthFn {
  (a: HasLength, b: HasLength): boolean;

  (a: HasLength): (b: HasLength) => boolean;
}

/* @ts-expect-error */
export const eqByLength: EqByLengthFn = eqBy(length);
