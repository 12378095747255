import { ChildrenProps } from "@sinch/utils";
import React, { createContext, useContext, useEffect, useState } from "react";

const OnlineStatusContext = createContext({ isOnline: true, setStatus: (status, timeout?) => {}, timeout: undefined });

export const OnlineStatusProvider = ({ children }: ChildrenProps) => {
  const [timeout, setTimeout] = useState<number | undefined>(undefined);
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const setOffline = () => {
    setOnlineStatus(false);
  };

  const setOnline = () => {
    setOnlineStatus(true);
  };

  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  const handleNetworkError = (status: boolean, retryTimeout?: number) => {
    setOnlineStatus(status);
    setTimeout(status ? undefined : retryTimeout);
  };

  return (
    <OnlineStatusContext.Provider value={{ isOnline: onlineStatus, setStatus: handleNetworkError, timeout }}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};
