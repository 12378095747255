// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { Box, Collapse, ListItem, ListSubheader } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import MuiList from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { mdiLink } from "@mdi/js";

import { ChildrenProps, ClassProps } from "@sinch/utils";
import clsx from "clsx";
import { length, slice } from "ramda";
import React, { Children, ReactElement, SyntheticEvent, useState } from "react";
import { Action, resolveAction } from "../actions";
import { Icon } from "../Icon";
import { Center } from "../Layout";
import { useSpacing } from "../UiProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  linkedListItem: {
    paddingLeft: theme.spacing(3),
  },
  fullHeight: {
    height: "100%",
  },
  dottedHorizontalLine: {
    borderLeftWidth: "1px",
    borderLeftStyle: "dotted",
    borderLeftColor: theme.palette.info.main,
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
  },
  shorterSpacing: {
    width: "calc(100% + 4px)",
    margin: "-2px",
    "& > .MuiGrid-item": {
      padding: "2px",
    },
  },
  overflowNoWrap: {
    overflow: "hidden",
  },
  dividerAtLast: {
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  simpleListItemContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "100%",
  },
}));

export function ChipedList({ children }: ChildrenProps): ReactElement {
  const classes = useStyles();

  return (
    <MuiList className={classes.root} subheader={<li />}>
      {children}
    </MuiList>
  );
}

export function ListDelimiter({ children }: ChildrenProps): ReactElement {
  const [, , data] = useSpacing();
  return (
    <ListSubheader>
      <Box py={data}>
        <Center>{children}</Center>
      </Box>
    </ListSubheader>
  );
}

export function SimpleListItem({
  children,
  className,
  onClick,
  action,
}: ChildrenProps & ClassProps & { action?: Action; onClick?: (e: SyntheticEvent) => void }): ReactElement {
  const classes = useStyles();
  return (
    <ListItem
      button
      className={clsx(classes.listItem, className)}
      dense
      disableGutters
      divider
      onClick={onClick}
      {...resolveAction(action)}
    >
      <div className={classes.simpleListItemContainer}>
        <MuiGrid className={classes.shorterSpacing} container wrap="nowrap">
          {children}
        </MuiGrid>
      </div>
    </ListItem>
  );
}

export function LinkedSimpleListItem({
  children,
  className,
  onClick,
}: ChildrenProps & ClassProps & { onClick?: (e: SyntheticEvent) => void }): ReactElement {
  const classes = useStyles();
  const [, , data] = useSpacing();
  return (
    <ListItem
      button
      className={clsx(classes.listItem, classes.linkedListItem, className, classes.dividerAtLast)}
      dense
      disableGutters
      divider
      onClick={onClick}
    >
      <Box px={data} width="100%">
        <MuiGrid className={classes.shorterSpacing} container>
          {children}
        </MuiGrid>
      </Box>
    </ListItem>
  );
}

export function LinkedListContainer({
  children,
  showLabel,
  hideLabel,
}: ChildrenProps & { showLabel: string; hideLabel?: string }): ReactElement {
  const classes = useStyles();
  const [, , data] = useSpacing();
  const [open, setOpen] = useState(false);

  const childrenList = Children.toArray(children);

  return (
    <>
      {slice(0, 3, childrenList).map((child) => child)}
      <Collapse in={open} timeout="auto" unmountOnExit>
        {slice(3, Infinity, childrenList).map((child) => child)}
      </Collapse>
      {length(childrenList) > 3 && (
        <ListItem
          className={clsx(classes.linkedListItem, classes.listItem)}
          dense
          disableGutters
          divider
          onClick={() => setOpen((state) => !state)}
        >
          <Box px={data} width="100%">
            <MuiGrid className={classes.shorterSpacing} container>
              <ListItemBorder>
                <Icon color="info" icon={mdiLink} size="small" />
              </ListItemBorder>
              <ListItemContent>{open ? hideLabel || showLabel : showLabel}</ListItemContent>
            </MuiGrid>
          </Box>
        </ListItem>
      )}
    </>
  );
}

export function ListItemBorder({ children }: ChildrenProps): ReactElement {
  return (
    <MuiGrid item xs="auto">
      {children}
    </MuiGrid>
  );
}
export function ListItemContent({ children }: ChildrenProps): ReactElement {
  const classes = useStyles();

  return (
    <MuiGrid className={classes.overflowNoWrap} item xs>
      {children}
    </MuiGrid>
  );
}

export function ConnectedMark(): ReactElement {
  const classes = useStyles();
  return (
    <MuiGrid item xs="auto">
      <MuiGrid alignItems="center" className={classes.fullHeight} container direction="column">
        <MuiGrid item xs="auto">
          <Icon color="info" icon={mdiLink} size="small" />
        </MuiGrid>
        <MuiGrid className={classes.dottedHorizontalLine} item xs />
      </MuiGrid>
    </MuiGrid>
  );
}
