import { makeStyles } from "@material-ui/core/styles";
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { isElement } from "react-dom/test-utils";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import { Icon } from "../Icon";
import { createPaletteStyles, getEntityColors, IdentifierColorKey } from "../paletteStyles";
import { useSpacing } from "../UiProvider";
import { CardList, CardListProps } from "./CardList";
import "react-swipeable-list/dist/styles.css";
import { Box, Collapse } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import clsx from "clsx";
import { Color, IconId } from "@sinch/types";
import { difference, includes, intersection, uniq } from "ramda";

const paletteStyles = createPaletteStyles(({ main, contrastText }) => ({
  color: contrastText,
  backgroundColor: main,
}));

const useStyles = makeStyles((theme) => ({
  swipeableCardList: {
    "& .swipeable-list": { overflowY: "initial" },
    "& .swipeable-list-item": {
      overflow: "initial",
    },
    "& .swipeable-list-item__content": {
      display: "block",
    },
    "& .swipeable-list-item__trailing-actions": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    "& .swipeable-list-item__leading-actions": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    "& .swipe-action__trailing > * > *": {
      justifyContent: "flex-end",
    },
    "& .swipe-action__trailing .ActionItemHorizontal-swipeActionContent": {
      flexDirection: "row-reverse",
      textAlign: "end",
      justifyContent: "flex-start",
    },
  },
  ...paletteStyles(theme),
  ...getEntityColors(theme, true),
}));

export interface SwipeableCardListProps<T> extends Omit<CardListProps<T>, "decorator"> {
  leadingAction?: ReactNode | ((item: T) => ReactNode);
  trailingAction?: ReactNode | ((item: T) => ReactNode);
  leadingClick?: (item: T) => void;
  trailingClick?: (item: T) => void;
}

export function SwipeableCardList<T>({
  leadingAction,
  trailingAction,
  leadingClick,
  trailingClick,
  onClick,
  onLongTouch,
  items,
  ...props
}: SwipeableCardListProps<T>): ReactElement {
  const styles = useStyles();
  const [blockEvent, setBlockEvent] = useState(false);
  const [hiddenItems, setHiddenItems] = useState<T[]>([]);
  const [removedItems, setRemovedItems] = useState<T[]>([]);

  // Dirty, but works
  const blockEventRef = useRef(blockEvent);
  blockEventRef.current = blockEvent;

  const leadingActions = (item: T) => {
    // @ts-ignore
    const element = isElement(leadingAction) ? leadingAction : leadingAction(item);
    const handleSwipe = () => {
      setHiddenItems((hidden) => uniq([...hidden, item]));
      return leadingClick && leadingClick(item);
    };
    return (
      element && (
        <LeadingActions>
          <SwipeAction destructive onClick={handleSwipe}>
            {element}
          </SwipeAction>
        </LeadingActions>
      )
    );
  };

  const trailingActions = (item: T) => {
    // @ts-ignore
    const element = isElement(trailingAction) ? trailingAction : trailingAction(item);
    const handleSwipe = () => {
      setHiddenItems((hidden) => uniq([...hidden, item]));
      return trailingClick && trailingClick(item);
    };
    return (
      element && (
        <TrailingActions>
          <SwipeAction destructive onClick={handleSwipe}>
            {element}
          </SwipeAction>
        </TrailingActions>
      )
    );
  };

  function handleLongTouch(item: T) {
    if (onLongTouch && !blockEventRef.current) {
      onLongTouch(item);
    }
  }

  function handleClick(item: T) {
    if (onClick && !blockEventRef.current) {
      onClick(item);
    }
  }

  useEffect(() => {
    setHiddenItems((hidden) => intersection(hidden, items));
    setRemovedItems((hidden) => intersection(hidden, items));
  }, [items]);

  return (
    <div className={styles.swipeableCardList}>
      <SwipeableList type={ListType.ANDROID}>
        <CardList<T>
          decorator={(children, item, i) => (
            <Collapse
              in={!includes(item, hiddenItems)}
              onExited={() => setRemovedItems((removed) => uniq([...removed, item]))}
              timeout={{ appear: 0, enter: 0, exit: 500 }}
            >
              <SwipeableListItem
                key={i}
                leadingActions={leadingActions(item)}
                onSwipeEnd={() => setBlockEvent(false)}
                onSwipeStart={() => setBlockEvent(true)}
                threshold={0.2}
                trailingActions={trailingActions(item)}
              >
                {children}
              </SwipeableListItem>
            </Collapse>
          )}
          items={difference(items, removedItems)}
          onClick={handleClick}
          onLongTouch={handleLongTouch}
          {...props}
        />
      </SwipeableList>
    </div>
  );
}

interface ActionItemProps {
  label?: string;
  icon?: IconId;
  color?: Color | IdentifierColorKey;
  subtitle?: string;
}

export const ActionItemHorizontal = ({ label, icon, color, subtitle }: ActionItemProps) => {
  const styles = useStyles();
  const [, , data] = useSpacing();
  return (
    <Box alignItems="center" className={clsx(color && styles[color])} display="flex" padding={data}>
      <MuiGrid
        alignItems="center"
        className="ActionItemHorizontal-swipeActionContent"
        container
        spacing={data}
        wrap="nowrap"
      >
        {icon && (
          <MuiGrid item>
            <Icon icon={icon} />
          </MuiGrid>
        )}
        <MuiGrid item>
          <MuiGrid container direction="column">
            {label && (
              <MuiGrid item xs="auto">
                {label}
              </MuiGrid>
            )}
            {subtitle && (
              <MuiGrid item xs="auto">
                {subtitle}
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};

export const ActionItemVertical = ({ label, icon, color }: ActionItemProps) => {
  const styles = useStyles();
  const [, , data] = useSpacing();
  return (
    <Box alignItems="center" className={clsx(color && styles[color])} display="flex" padding={data}>
      <MuiGrid container>
        <MuiGrid item>
          <MuiGrid alignItems="center" container direction="column">
            {icon && (
              <MuiGrid item xs="auto">
                <Icon icon={icon} />
              </MuiGrid>
            )}
            {label && (
              <MuiGrid item xs="auto">
                {label}
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};
