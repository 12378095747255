import { Box } from "@material-ui/core";
import { mdiCancel, mdiCheckboxMarkedCircle, mdiClockOutline } from "@mdi/js";
/* eslint-disable import/no-extraneous-dependencies */
import { useData } from "@sinch/core";
import {
  PositionAttendanceStatus,
  PositionQ,
  PositionStatus,
  selectPosition,
  selectPositionAttendance,
  selectTransportAttendance,
  selectWorker,
  WorkerRole,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Icon, IconText, PhoneLink, Text, useMobileLayout } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { concat, equals, map, pipe, prop, reduce, sortBy, uniqBy } from "ramda";
import { concatAll, isNotNilOrEmpty } from "ramda-adjunct";
import React from "react";
import { SmsButton, WorkerList } from "../../components";
import { TransportRoleOptions, WorkerRoleOptions } from "../../Shift";
import { requestPositionDetail } from "./api";

const filterPositionsByRole = (positions, selectEntity) => (role) =>
  positions.filter((shiftPosition) => equals(selectEntity(selectPosition(shiftPosition)).role, role));

export function WorkersFeedbackDialog({ position }) {
  const { dt } = useFormat();
  const mobile = useMobileLayout();
  const { selectEntity, queryEntity, select } = useData(requestPositionDetail);
  const shiftPositions = queryEntity(PositionQ.Shift.getPositions(position));
  const roleFilter = filterPositionsByRole(shiftPositions, selectEntity);

  function getChipData(chip, chipPosition) {
    const isCrewboss = queryEntity(PositionQ.User.isCrewboss(chipPosition));
    return {
      chip,
      position: chipPosition,
      headerAction: isCrewboss ? (
        <SmsButton position={chipPosition}>{mobile ? t("sms") : t("Position.SmsSend")}</SmsButton>
      ) : undefined,
    };
  }

  const positionsStatuses = selectEntity(selectPosition(shiftPositions, "status"));
  const shiftStatus = Math.min(...positionsStatuses);

  const sortedShiftPositions = concatAll([
    roleFilter(WorkerRole.Crewboss),
    roleFilter(WorkerRole.Worker),
    roleFilter(WorkerRole.Backup),
  ]);

  const makeData = pipe(
    // Add workers to every role
    map((rolePosition) => ({
      workers: selectEntity(selectPositionAttendance({ position: rolePosition }, "worker")),
      rolePosition,
    })),
    // create chips
    reduce((acc, { workers, rolePosition }) => {
      const { role, startTime, endTime, title, freeCapacity } = selectEntity(selectPosition(rolePosition));
      return concat(acc, [
        { role, chip: true, startTime, endTime, title, workerPosition: rolePosition },
        ...sortBy(
          prop("surname"),
          workers.map((worker) => ({
            id: worker,
            surname: selectEntity(selectWorker(worker, "surname")),
            role,
            workerPosition: rolePosition,
          }))
        ),
        ...[...Array(Math.max(0, freeCapacity)).keys()].map(() => ({
          role,
          empty: true,
          workerPosition: rolePosition,
        })),
      ]);
    }, [])
  );

  const dataRaw = makeData(sortedShiftPositions);

  const data = map(({ id, role, chip, startTime, endTime, title, workerPosition, empty }) => {
    if (empty) {
      return { empty: true };
    }

    if (chip) {
      return getChipData(selectEntity, workerPosition, role, title, chip, startTime, endTime, workerPosition);
    }

    const { name, phone, avatar, surname } = selectEntity(selectWorker(id));
    const { startTime: arrival, endTime: departure, status, newbie } = selectEntity(
      selectPositionAttendance({ worker: id, position: workerPosition })
    )[0];
    const isClosed = shiftStatus >= PositionStatus.CrewbossClosed;

    let workerListInfo;
    if (isClosed && arrival && departure) {
      switch (status) {
        case PositionAttendanceStatus.Present:
          workerListInfo = (
            <IconText color="primary" icon={mdiCheckboxMarkedCircle}>
              <>
                {t("Shift.display.workerListInfo.present", {
                  arrival: dt.time(arrival),
                  departure: dt.time(departure),
                })}
              </>
            </IconText>
          );
          break;
        case PositionAttendanceStatus.Late:
          workerListInfo = (
            <IconText color="warning" icon={/* ICON */ mdiClockOutline}>
              <>
                {t("Shift.display.workerListInfo.late", {
                  arrival: dt.time(arrival),
                  departure: dt.time(departure),
                })}
              </>
            </IconText>
          );
          break;
        case PositionAttendanceStatus.Excused:
        case PositionAttendanceStatus.Absent:
          workerListInfo = (
            <IconText color="error" icon={/* ICON */ mdiCancel}>
              <>{t("Shift.display.workerListInfo.absent")}</>
            </IconText>
          );
          break;
        default:
          break;
      }
    }

    let transportRole;
    const { TransportAttendance: transportAttendancesEntities } = selectEntity();
    if (isNotNilOrEmpty(transportAttendancesEntities)) {
      const transportAttendances = selectEntity(selectTransportAttendance({ worker: id, positionId: workerPosition }));
      if (isNotNilOrEmpty(transportAttendances)) {
        transportRole = (
          <Box display="flex" justifyContent={mobile ? undefined : "flex-end"}>
            {uniqBy(prop("role"))(transportAttendances).map(({ role }) => (
              <Icon
                color="primary"
                icon={TransportRoleOptions()[role].icon}
                tooltip={TransportRoleOptions()[role].label}
              />
            ))}
          </Box>
        );
      }
    }

    return {
      id,
      role: WorkerRoleOptions()[role].label,
      name: newbie ? (
        <Text>
          {name}
          <Text color="error">*</Text>
        </Text>
      ) : (
        name
      ),
      surname,
      avatar,
      dataVertical: mobile,
      workerItems: rejectFalsy([
        phone && (
          <Box display="flex" justifyContent={mobile ? undefined : "flex-end"}>
            <PhoneLink phone={phone}>{phone}</PhoneLink>
          </Box>
        ),
        workerListInfo,
        transportRole,
      ]),
    };
  }, dataRaw);

  return <WorkerList data={data} />;
}
