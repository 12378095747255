import { ChildrenProps, createCheckedContext, rejectFalsy } from "@sinch/utils";
import React, { ReactElement, useState } from "react";

interface ScrollHistoryContextProps {
  scrollHistory: Record<string, number>;
  setScroll: (key: string, scrollPosition: number | null) => void;
}

const ScrollHistoryContext = createCheckedContext<ScrollHistoryContextProps>("ScrollHistory");
export const { use: useScrollHistory } = ScrollHistoryContext;

/**
 * ScrollHistory provider provide global store of scroll position for restoring when navigate in browser
 */
export function ScrollHistoryProvider({ children }: ChildrenProps): ReactElement {
  const [scrollHistory, setScrollHistory] = useState<Record<string, number>>({});
  const context = {
    scrollHistory,
    setScroll: (key: string, scrollPosition: number | null) =>
      setScrollHistory((state) => rejectFalsy({ ...state, [key]: scrollPosition })),
  };
  return <ScrollHistoryContext value={context}>{children}</ScrollHistoryContext>;
}
