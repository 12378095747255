/* eslint-disable import/no-internal-modules,import/no-extraneous-dependencies */
import { AccordionProps, Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useCurrentUser, useData, useInstanceSettings } from "@sinch/core";
import {
  PersonalAttributeCitizenship,
  ProfileAttribute,
  selectProfileAttribute,
  selectSnippet,
  Snippet,
} from "@sinch/entity";
import { Form } from "@sinch/forms";
import { useFormContext } from "@sinch/forms/Form/FormContext";
import { t } from "@sinch/intl";
import { Button, LoadingOverlay, routerLink, useMobileLayout, useTitleBar } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import {
  assoc,
  compose,
  defaultTo,
  equals,
  filter,
  findLast,
  head,
  isEmpty,
  map,
  pipe,
  prop,
  propEq,
  reduce,
  sortBy,
} from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useState } from "react";
import { useLocation } from "react-router";
import { Notice } from "../../../Dashboard/components";
import { ProfileAttributeData, requestProfileAttributeList, requestProfileAttributeUpdate } from "../api";
import { useInvalidFormWarning } from "../hooks";
import { parseInitialProfileValue } from "../utils";
import { EditAttributes } from "./EditAttributes";

const useAccordionExpanded = (initGroup: string): [string, (group: string) => AccordionProps["onChange"]] => {
  const [expanded, setExpanded] = useState(initGroup);
  const handleExpansionChange = (group: string): AccordionProps["onChange"] => (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      setExpanded(group);
    }
  };
  return [expanded, handleExpansionChange];
};

export function EditProfileAttributes(): ReactElement {
  const { selectEntity, selectResult } = useData(requestProfileAttributeList);
  const { attributes } = selectResult();
  const { citizenship: userCitizenship } = useCurrentUser();
  const { country } = useInstanceSettings();
  const isMobile = useMobileLayout();

  const allAttributes = selectEntity(selectProfileAttribute({}));
  const snippets = selectEntity(selectSnippet({}));

  const initial = {
    dynamicAttributes: reduce(
      (acc, { id, type }) => {
        const savedValue = findLast(propEq("id", id))(attributes);
        return assoc(
          id as string,
          { id, value: parseInitialProfileValue(undefined, type), expiration: null, ...savedValue },
          acc
        );
      },
      {},
      allAttributes
    ),
  };

  return (
    <>
      {filter<Snippet>(propEq("name", "profile_attributes_edit"), snippets).map(({ color, body, id, style }, idx) => (
        <Box pb={1} pt={isMobile ? 0 : 1}>
          <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
        </Box>
      ))}
      <Form
        blockRedirect
        initialValues={initial}
        submitRequest={({ dynamicAttributes }) =>
          requestProfileAttributeUpdate({
            dynamicAttributes,
          })
        }
        validate={({ dynamicAttributes }: { dynamicAttributes: ProfileAttributeData[] }) => {
          const result = rejectFalsy(
            map(({ value, id, expiration }) => {
              const { mandatory, expirable, citizenship: attributeCitizenship } = selectEntity(
                selectProfileAttribute(id)
              );

              const isHidden =
                (equals(attributeCitizenship, PersonalAttributeCitizenship.Home) && country !== userCitizenship) ||
                (equals(attributeCitizenship, PersonalAttributeCitizenship.Other) && country === userCitizenship);

              if (isHidden) {
                return null;
              }
              if (mandatory && isNilOrEmpty(value)) {
                return { value: t("mandatoryField") };
              }
              if (expirable && !isNilOrEmpty(value) && isNilOrEmpty(expiration)) {
                return { expiration: t("mandatoryField") };
              }
              return null;
            }, dynamicAttributes)
          );
          if (isEmpty(result)) {
            return null;
          }
          return { dynamicAttributes: result };
        }}
        validateOnMount
      >
        <EditProfileAttributesFormContent />
      </Form>
    </>
  );
}

function EditProfileAttributesFormContent(): ReactElement {
  const mobile = useMobileLayout();
  const {
    submit,
    status: { submitting },
  } = useFormContext();
  useTitleBar(
    mobile
      ? {
          container: "profile:editProfileAttributes",
          submitHandler: submit,
          showBack: true,
          confirmExit: true,
          locationTitle: t("Profile.editProfileAttributes"),
        }
      : { container: "profile:editProfileAttributes" }
  );

  const { selectEntity, selectResult } = useData(requestProfileAttributeList);

  const { attributes: attributesData } = selectResult();
  const profileAttributes = selectEntity(selectProfileAttribute({}));

  const { hash } = useLocation();

  useInvalidFormWarning();

  const firstBlock = pipe<
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    Pick<ProfileAttribute, "blockId" | "blockName">[],
    Pick<ProfileAttribute, "blockId" | "blockName">[]
  >(
    defaultTo([]),
    sortBy(compose(defaultTo(Infinity), prop("blockSortNumber"))),
    map(prop("blockId")),
    head
  )(profileAttributes);

  const hashString = hash.substr(1);
  const [expanded, handleExpansionChange] = useAccordionExpanded(isEmpty(hashString) ? firstBlock || "" : hashString);

  return (
    <>
      {submitting && <LoadingOverlay />}
      <EditAttributes
        attributes={profileAttributes}
        attributesValues={attributesData}
        expanded={expanded}
        handleExpansionChange={handleExpansionChange}
      />
      {!mobile && (
        <Box my={1}>
          <MuiGrid container spacing={1}>
            <MuiGrid item md={3} xs={6}>
              <Button action={() => submit()} color="secondary" type="button">
                {t("action.submit")}
              </Button>
            </MuiGrid>
            <MuiGrid item md={3} xs={6}>
              <Button action={routerLink("/profile")} variant="outlined">
                {t("action.cancel")}
              </Button>
            </MuiGrid>
          </MuiGrid>
        </Box>
      )}
    </>
  );
}
