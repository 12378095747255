import { mdiAlert, mdiAlertCircle, mdiCheckCircle, mdiInformation, mdiNoteOutline } from "@mdi/js";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Snippet } from "@sinch/entity";
import { IconId } from "@sinch/types";
import { HtmlContent, Strip, StripBase } from "@sinch/ui";
import React, { ReactElement } from "react";

interface NoticeProps extends Omit<Snippet, "name"> {}

/**
 * todo: implement content truncation with "show more" button
 *  - how to apply truncate/fade only to long content?
 *  - possibly using layout effect to update after render
 */
export function Notice({ body, color, style }: NoticeProps): ReactElement {
  if (style === null) {
    return <HtmlContent html={body} />;
  }
  if (style === "note") {
    return (
      <StripBase color={color}>
        <HtmlContent html={body} />
      </StripBase>
    );
  }

  let icon: IconId = mdiNoteOutline;
  switch (color) {
    case "error":
      icon = mdiAlertCircle;
      break;
    case "info":
      icon = mdiInformation;
      break;
    case "success":
      icon = mdiCheckCircle;
      break;
    case "warning":
      icon = mdiAlert;
      break;
    default:
      break;
  }

  return (
    <Strip color={color} icon={icon}>
      <HtmlContent html={body} />
    </Strip>
  );
}

export function getNoticesStrips(notices: Snippet[]): ReactElement[] {
  return notices.map(({ color, body, id, style }, idx) => (
    <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
  ));
}
