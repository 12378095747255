import { Func, Maybe } from "@sinch/types";
import { always, bind, filter, head, ifElse, isNil, last, prop, propEq, when } from "ramda";
import { isNotNil } from "ramda-adjunct";
import { FormatNumber } from "./number";

export interface FormatCurrency extends FormatNumber {
  code: string;

  prefix?: string;

  suffix?: string;
}

const getAffix: Func<Maybe<Intl.NumberFormatPart>, Maybe<string>> = when(
  isNotNil,
  ifElse(propEq("type", "currency"), prop("value"), always(undefined))
);

export function currencyFormatter(locale: string, currency: string): FormatCurrency {
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  });
  const format = bind(formatter.format, formatter);

  // iOS12 fix
  if (isNil(formatter.formatToParts)) {
    const ltnFormatter = new Intl.NumberFormat(locale, {
      currency,
      style: "currency",
      // @ts-ignore
      numberingSystem: "latn",
    });
    const formatted = ltnFormatter.format(0);

    const prefixRegex = /^[^\d\s]+/gmu;
    const prefixMatch = prefixRegex.exec(formatted);

    const suffixRegex = /[^\d\s]+$/gmu;
    const suffixMatch = suffixRegex.exec(formatted);

    const params = {
      code: currency,
      ...filter(isNotNil, {
        prefix: prefixMatch ? prefixMatch[0] : null,
        suffix: suffixMatch ? suffixMatch[0] : null,
      }),
    };

    return Object.assign(format, params);
  }

  const parts = formatter.formatToParts(0);
  const params = {
    code: currency,
    ...filter(isNotNil, {
      prefix: getAffix(head(parts)),
      suffix: getAffix(last(parts)),
    }),
  };

  return Object.assign(format, params);
}
