import { Box, Grow } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { mdiClose } from "@mdi/js";
import React, { MouseEventHandler } from "react";
import { Icon } from "../Icon";
import { Image } from "../Image";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    alignItems: "flex-end",
    backgroundColor: "rgba(0,0,0,0.9)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    maxWidth: "100vw",
  },
  loadingImg: {
    backgroundColor: "transparent",
  },
  dialogBackground: {
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  toolbar: {
    justifyContent: "flex-end",
  },
}));

const Transition = React.forwardRef((props, ref) => <Grow ref={ref} {...props} />);

interface ImagePreviewProps {
  src: string;
  onClose?: MouseEventHandler | undefined;
}

export function ImagePreview({ src, onClose }: ImagePreviewProps) {
  const classes = useStyles();

  const handleClose: MouseEventHandler = (e) => {
    onClose?.(e);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogBackground }}
      fullScreen
      onClose={handleClose}
      open
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <IconButton color="inherit" onClick={handleClose}>
          <Icon icon={mdiClose} />
        </IconButton>
      </AppBar>
      <Box className={classes.content} onClick={handleClose}>
        <Image
          classes={{ ready: classes.img, loading: classes.loadingImg }}
          onClick={(e) => e.stopPropagation()}
          src={src}
        />
      </Box>
    </Dialog>
  );
}
