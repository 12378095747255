import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import { useData } from "@sinch/core";
import {
  Fk,
  Position,
  PositionAttendance,
  selectPosition,
  selectTransport,
  selectTransportAttendance,
  Transport,
  TransportAttendance,
  TransportAttendanceRole,
} from "@sinch/entity";
import { Form, SubmitButton, useFormUpdate, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import {
  Button,
  ComplementaryDialog,
  Divider,
  Identifier,
  Spacer,
  Text,
  TimeRange,
  useSnackbar,
  useSpacing,
} from "@sinch/ui";
import { min, sub, subMinutes } from "date-fns";
import {
  filter,
  flatten,
  head,
  includes,
  isNil,
  length,
  map,
  mergeDeepRight,
  pipe,
  pluck,
  prop,
  reduce,
  uniq,
  values,
} from "ramda";
import React, { ReactElement, useCallback, useMemo } from "react";
import {
  AttendanceClosingFormState,
  AttendanceClosingPositionState,
  AttendanceClosingTransportState,
  PositionEditFormState,
  requestShiftAttendanceView,
  TransportEditFormState,
} from "./api";
import { WorkerRoleOptions } from "./options";
import { PositionEditForm, TransportEditForm } from "./PositionEditForm";

function updateValues(
  parentValues: AttendanceClosingFormState,
  values: PositionEditFormState,
  attendances: Fk<PositionAttendance>[]
): AttendanceClosingFormState {
  const { endTime, startTime } = values;

  const positionAttendance: Record<Fk<PositionAttendance>, Partial<AttendanceClosingPositionState>> = {};
  if (!isNil(startTime)) {
    attendances.forEach((attendance) => {
      positionAttendance[attendance] = {
        startTime,
      };
    });
  }
  if (!isNil(endTime)) {
    attendances.forEach((attendance) => {
      positionAttendance[attendance] = { ...(positionAttendance[attendance] || {}), endTime };
    });
  }

  return mergeDeepRight(parentValues, {
    positionAttendance,
  }) as AttendanceClosingFormState;
}

interface PositionEditDialogProps {
  onClose: Callback;

  positions: Fk<Position>[];
}

export function PositionEditDialog({ positions, onClose }: PositionEditDialogProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);
  const snackbar = useSnackbar();
  const [, inner] = useSpacing();

  const { startTime: clientStartTime } = useFormValues<AttendanceClosingFormState>();

  const positionsList = selectEntity(selectPosition(positions));

  const meetingTimes = reduce(
    (acc, { startTime, meetingTimeInterval }) => [...acc, min([subMinutes(startTime, meetingTimeInterval), startTime])],
    [],
    positionsList
  );

  const hasMeetingTimesConflict = length(uniq(meetingTimes)) !== 1;
  const meetingTime = hasMeetingTimesConflict ? null : head(uniq(meetingTimes));
  const maxEnds = pluck("endTime", positionsList);
  const hasEndTimeConflict = length(uniq(maxEnds)) !== 1;
  const maxEnd = hasEndTimeConflict ? null : head(uniq(maxEnds));

  const hasTimesConflicts = hasMeetingTimesConflict || hasEndTimeConflict;

  const attendances = selectEntity(
    pipe(
      prop("PositionAttendance"),
      filter(({ position }) => includes(position, positions)),
      pluck("id"),
      values
    )
  );

  const parentValues = useFormValues<AttendanceClosingFormState>();
  const setValues = useFormUpdate<AttendanceClosingFormState>();

  const handleSubmit = useCallback(
    (values) => {
      setValues(updateValues(parentValues, values, attendances));

      snackbar("success", `${t("Shift.closing.attendanceChangedMultiple")} (${length(attendances)})`);

      onClose();
    },
    [attendances, onClose, parentValues, setValues, snackbar]
  );

  const minTime = sub(min([...meetingTimes, clientStartTime]), { hours: 1 });

  const formState: PositionEditFormState = useMemo(
    () => ({
      endTime: undefined,
      startTime: undefined,
    }),
    [attendances]
  );

  return (
    <ComplementaryDialog onClose={onClose} title={t("Shift.closing.attendanceChange")} width="xs">
      <Form<PositionEditFormState> initialValues={formState} submitHandler={handleSubmit}>
        <MuiGrid container direction="column" spacing={inner}>
          <MuiGrid item>
            <Box display="flex" flexDirection="column">
              <Text bold>{t("Position.title")}</Text>
              {positionsList.map(({ id: positionId, role, title, endTime, startTime }) => (
                <Text>
                  <Spacer dropEmpty separator=" - ">
                    <Identifier entity="position" id={positionId} />
                    {WorkerRoleOptions()[role].label}
                    {title}
                  </Spacer>
                  |
                  <TimeRange endTime={endTime} showLength startTime={startTime} />
                </Text>
              ))}
            </Box>
          </MuiGrid>
          <MuiGrid item>
            <Box display="flex" flexDirection="column">
              <Text bold>{t("display.workers")}</Text>
              <Text>{attendances.length}</Text>
            </Box>
          </MuiGrid>
          {hasTimesConflicts && (
            <MuiGrid item>
              <Alert severity="warning" variant="outlined">
                {t("Shift.closing.attendanceTimesChangeWarning")}
              </Alert>
            </MuiGrid>
          )}
          <MuiGrid item>
            <PositionEditForm maxTime={head(uniq(maxEnds))} minTime={minTime} />
          </MuiGrid>
        </MuiGrid>
        <MuiGrid container justify="flex-end" spacing={2} wrap="nowrap">
          <MuiGrid item>
            <Button action={onClose} variant="outlined">
              {t("action.cancel")}
            </Button>
          </MuiGrid>
          <MuiGrid item>
            <SubmitButton label={t("action.confirm")} />
          </MuiGrid>
        </MuiGrid>
      </Form>
    </ComplementaryDialog>
  );
}

function updateValuesTransport(
  parentValues: AttendanceClosingFormState,
  values: TransportEditFormState,
  attendances: Fk<TransportAttendance>[]
): AttendanceClosingFormState {
  const { endTime, startTime } = values;

  const transportAttendance: Record<Fk<TransportAttendance>, Partial<AttendanceClosingTransportState>> = {};

  attendances.forEach((attendance) => {
    transportAttendance[attendance] = {
      startTime,
      endTime,
    };
  });

  return mergeDeepRight(parentValues, {
    transportAttendance,
  }) as AttendanceClosingFormState;
}

interface TransportGroupEditDialogProps {
  transport: Fk<Transport>;

  onClose: Callback;

  positions: Fk<Position>[];
}

export function TransportGroupEditDialog({
  onClose,
  transport,
  positions,
}: TransportGroupEditDialogProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);
  const snackbar = useSnackbar();
  const [, inner] = useSpacing();

  const { startTime, endTime } = selectEntity(selectTransport(transport));

  // @ts-expect-error
  const attendances: Fk<TransportAttendance>[] = flatten(
    map((position) => selectEntity(selectTransportAttendance({ positionId: position, transport }, "id")), positions)
  );

  const parentValues = useFormValues<AttendanceClosingFormState>();
  const setValues = useFormUpdate<AttendanceClosingFormState>();

  const handleSubmit = useCallback(
    (values) => {
      setValues(updateValuesTransport(parentValues, values, attendances));

      snackbar("success", `${t("Shift.closing.attendanceChangeMultiple")} (${length(attendances)})`);

      onClose();
    },
    [attendances, onClose, parentValues, setValues, snackbar]
  );

  const formState: TransportEditFormState = useMemo(
    () => ({
      endTime,
      startTime,
    }),
    [endTime, startTime]
  );

  return (
    <ComplementaryDialog onClose={onClose} title={t("Shift.closing.changeAttendanceEntireTransport")} width="xs">
      <Form<TransportEditFormState> initialValues={formState} submitHandler={handleSubmit}>
        <MuiGrid container direction="column" spacing={inner}>
          <MuiGrid item>
            <Box display="flex" flexDirection="column">
              <Text bold>{t("display.transport")}</Text>
              <Text>
                <Spacer dropEmpty separator=" - ">
                  <Identifier entity="transport" id={transport} />
                  {TransportAttendanceRole[formState.role]}
                </Spacer>
                <TimeRange endTime={endTime} startTime={startTime} />
              </Text>
            </Box>
          </MuiGrid>
          <MuiGrid item>
            <Box display="flex" flexDirection="column">
              <Text bold>{t("worker", { count: attendances.length })}</Text>
              <Text>{attendances.length}</Text>
            </Box>
          </MuiGrid>
          <MuiGrid item>
            <TransportEditForm />
          </MuiGrid>
        </MuiGrid>
        <Divider />
        <MuiGrid container justify="flex-end" spacing={2} wrap="nowrap">
          <MuiGrid item>
            <Button action={onClose} variant="outlined">
              {t("action.cancel")}
            </Button>
          </MuiGrid>
          <MuiGrid item>
            <SubmitButton label={t("action.confirm")} />
          </MuiGrid>
        </MuiGrid>
      </Form>
    </ComplementaryDialog>
  );
}
