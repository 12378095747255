import { HashRoute } from "@sinch/core";
import { ErrorHandledRoutes } from "@sinch/core/router";
import { t } from "@sinch/intl";
import { useTitleBar } from "@sinch/ui";
import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import { PhoneVerification } from "./components/PhoneVerification";
import { Hibernate } from "./Hibernate";
import { PasswordUpdateContainer } from "./PasswordUpdate";
import { PortraitUpdateContainer } from "./PortraitUpdate";
import { Profile, ProfileStatusContainer } from "./Profile";
import { ProfileContextProvider } from "./ProfileContext";
import { ProfileEdit } from "./ProfileEdit";
import { NotificationSettingsContainer } from "./ProfileNotificationSettings";

/**
 * todo: extract NotificationList and NotificationSettings to core package
 */
export function ProfileContainer(): ReactElement {
  useTitleBar({
    container: "core:profile",
    locationTitle: t("Profile.title"),
  });

  return (
    <ProfileContextProvider>
      <>
        <ErrorHandledRoutes>
          <Route element={<Profile />} path="*" />
          <Route element={<ProfileEdit />} path="/edit/*" />
          <Route element={<NotificationSettingsContainer />} path="/notificationSettings" />
        </ErrorHandledRoutes>
        <HashRoute path="uploadPortrait">
          {/* todo: temp hack - reorganize container hierarchy */}
          <ProfileStatusContainer>
            <PortraitUpdateContainer />
          </ProfileStatusContainer>
        </HashRoute>
        <HashRoute path="passwordUpdate">
          <PasswordUpdateContainer />
        </HashRoute>
        <HashRoute path="hibernate">
          <Hibernate />
        </HashRoute>
        <HashRoute path="phoneVerification">
          <ProfileStatusContainer>
            <PhoneVerification />
          </ProfileStatusContainer>
        </HashRoute>
      </>
    </ProfileContextProvider>
  );
}
