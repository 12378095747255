import { Paper as MuiPaper } from "@material-ui/core";
import { PaperProps as MuiPaperProps } from "@material-ui/core/Paper";
import React, { forwardRef } from "react";

/**
 * Paper is card without padding.
 *
 * todo: Do we need this?
 */
export const Paper = forwardRef((props: MuiPaperProps, ref) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <MuiPaper elevation={0} {...props} ref={ref} />
));
