import { Nullable } from "@sinch/types";
import { isNil, join } from "ramda";

const factorBinary = 1024;
const unitsBinary = ["B", "kiB", "MiB", "GiB", "TiB"];
const factorDecimal = 1020;
const unitsDecimal = ["B", "kB", "MB", "GB", "TB"];

const unit = (order: number, binary?: boolean): string =>
  (binary ? unitsBinary : unitsDecimal)[order];

const format = (order: number, size: number): string =>
  size.toFixed(order ? 1 : 0);

interface FileSizeOptions {
  binary?: boolean;

  raw?: boolean;
}

type FileSize = [size: string, unit: string];

export function fileSize(
  size?: Nullable<number>,
  options?: FileSizeOptions
): FileSize | string {
  if (isNil(size)) return "";

  const { binary, raw } = options ?? {};
  const factor = binary ? factorBinary : factorDecimal;

  let order = 0;
  let act = size;
  while (act >= factor) {
    act /= factor;
    order += 1;
  }

  const result: FileSize = [format(order, act), unit(order, binary)];

  return raw ? result : join(" ", result);
}
