import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataProvider, HashRoute, useData } from "@sinch/core";
import { Badge, Fk, selectBadge, selectBadgeGroup } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  Card,
  Center,
  Divider,
  Grid,
  Header,
  HtmlContent,
  Image,
  InformationDialog,
  routerLink,
  Text,
  useMobileLayout,
  useTitleBar,
} from "@sinch/ui";
import { isEmpty, map, prop, sortBy } from "ramda";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { requestProfileBadgeList } from "./api";
import { BadgeEmptyBox } from "./BadgeEmptyBox";

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: "100%",
    "& .MuiButton-label": {
      height: "100%",
      "text-align": "center",
    },
  },
}));

export type BadgeItemParams = {
  badge: Fk<Badge>;
};

export type BadgeDetailParams = {
  badge: Fk<Badge>;
};

export function BadgeDetail({ badge }: BadgeDetailParams): ReactElement {
  const { selectEntity } = useData(requestProfileBadgeList);
  const { image, description, name } = selectEntity(selectBadge(badge));

  return (
    <InformationDialog width="xs">
      <Center>
        <Image file={image} scaling="fitWidth" />
      </Center>
      <Center>
        <Header>{name}</Header>
      </Center>
      <Divider />
      <HtmlContent html={description} />
    </InformationDialog>
  );
}

function BadgeItem({ badge }: BadgeItemParams): ReactElement {
  const { selectEntity } = useData(requestProfileBadgeList);
  const { image, name } = selectEntity(selectBadge(badge));
  const styles = useStyles();
  const mobile = useMobileLayout();

  const badgeBox = (
    <Box alignItems="center" className={styles.fullHeight} display="flex" flexDirection="column" padding={1}>
      <Image file={image} scaling="fitWidth" />
      <Text bold>{name}</Text>
    </Box>
  );

  if (mobile) {
    return <Card action={routerLink(`#/badge/${badge}`)}>{badgeBox}</Card>;
  }
  return (
    <Button action={routerLink(`#/badge/${badge}`)} className={styles.fullHeight} variant="outlined">
      {badgeBox}
    </Button>
  );
}

export function BadgeList(): ReactElement {
  const mobile = useMobileLayout();
  useTitleBar({
    container: "core:badges",
    locationTitle: t("Badge.titlePlural"),
    showBack: mobile,
  });

  const { selectEntity, selectResult } = useData(requestProfileBadgeList);
  const groups = selectEntity(selectBadgeGroup(selectResult().groups));

  if (isEmpty(selectEntity(selectBadge({})))) {
    return <BadgeEmptyBox />;
  }

  return (
    <>
      <Grid spacing="data">
        {map(({ name, id }) => {
          const badges = selectEntity(selectBadge({ group: id }, "id"));
          return (
            <Grid key={name} vertical>
              <Box my={1}>
                <Center>
                  <Header>{name}</Header>
                </Center>
              </Box>
              <Grid sizes={mobile ? 6 : 3} spacing="data">
                {map(
                  /* eslint-disable-next-line @typescript-eslint/no-shadow */
                  (id) => (
                    <BadgeItem key={id} badge={id} />
                  ),
                  badges
                )}
              </Grid>
            </Grid>
          );
        }, sortBy(prop("sort"), sortBy(prop("name"), groups)))}
      </Grid>
      <HashRoute path="badge/:badge">
        {/* @ts-expect-error */}
        <BadgeDetail />
      </HashRoute>
    </>
  );
}

export type BadgeListContainerParams = {
  children: ReactNode;
};

export function BadgeListContainer({ children }: BadgeListContainerParams): ReactElement {
  const request = useMemo(() => requestProfileBadgeList(), []);

  return <DataProvider request={request}>{children}</DataProvider>;
}
