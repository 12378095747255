import { Callback } from "@sinch/types";
import { init, last, reduceRight } from "ramda";
import React, {
  Children,
  cloneElement,
  createElement,
  ElementType,
  Fragment,
  FunctionComponent,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";

/*
 * todo: consider providing OneOrManyChild helper type
 *  ```
 *  // type ReactChild = ReactElement | ReactText;
 *  type ReactChildren = ReactChild | ReactChild[];
 *  ```
 */

/**
 * Props containing `children?: T` if `undefined extends T` or
 * required variant `children: T` when `T` may not be `undefined`.
 */
export type ChildrenProps<T = ReactNode> = undefined extends T ? { children?: T } : { children: T };

export type ClassProps = {
  className?: string;
};

/* eslint-disable-next-line @typescript-eslint/ban-types */
export function withProps<P extends {}>(
  component: ElementType<P>,
  setProps: Partial<P>,
  override?: boolean
): FunctionComponent<P> {
  return (ownProps: PropsWithChildren<P>) =>
    createElement(component, override ? { ...ownProps, ...setProps } : { ...setProps, ...ownProps }, ownProps.children);
}

export type ReactTransform<TIn extends ReactNode = ReactNode, TOut extends ReactNode = TIn> = (node: TIn) => TOut;

/**
 * todo: this definition is wrong, since it returns Array type, we need singular
 */
export type ReactRenderNode = ReturnType<typeof Children.toArray>;

export type ReactMaybeElement = ReactElement | null | false | undefined;

/**
 * Can be used to typecast Children.toArray in order to narrow output types
 */
export type ReactRenderTransform = ReactTransform<ReactNode, ReactRenderNode>;

export function isFragment(node: ReactNode): node is ReactElement {
  return isValidElement(node) && node.type === Fragment;
}

export function toElement(node: ReactNode): ReactElement {
  return isValidElement(node) ? node : <>{node}</>;
}

function composeElementsReducer(parent: ReactMaybeElement, child: ReactNode) {
  return parent ? cloneElement(parent, undefined, child) : child;
}

/**
 * Elements are composed from right to left, meaning the first item in the
 * array will be at root of the returned component while the last one will be
 * rendered as children at the bottom.
 *
 * Elements with non-render values (null|undefined|boolean) are ignored.
 *
 * If the composed {@link React.ReactNode} is not an {@link
 * React.ReactElement} it is wrapped in {@link React.Fragment} before return.
 */
export function composeElements(elements: [...ReactMaybeElement[], ReactNode]): ReactElement {
  return toElement(reduceRight(composeElementsReducer, last(elements), init(elements as ReactMaybeElement[])));
}

/**
 * Create function to check mounted state of current component.
 *
 * Optional callback argument is called only if component is mounted.
 */
export function useMounted(): (callback?: Callback) => boolean {
  const ref = useRef(true);

  useEffect(
    () => () => {
      ref.current = false;
    },
    []
  );

  return useCallback((callback) => {
    if (callback && ref.current) callback();
    return ref.current;
  }, []);
}
