import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Payout, PayoutMethod, PayoutPaymentType, selectPayout, selectPayoutMethod } from "@sinch/entity";
import {
  CurrencyInput,
  FileInput,
  Form,
  FormDependant,
  FormDependantCond,
  formValidator,
  SelectInput,
  SubmitButton,
} from "@sinch/forms";
import { t } from "@sinch/intl";
import { DataList, DataListDefaultItem, Divider, ExpandableArea, Grid, Header, SpacingBox } from "@sinch/ui";
import { flatList } from "@sinch/utils";
import { map } from "ramda";
import { isNotNil } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo } from "react";
import { requestWalletPayoutDetail } from "../Wallet";
import { PayoutParams, requestWalletPayoutCreate, WalletPayoutCreateFilesTarget } from "./api";
import { CalculationTableContainer as CalculationTable } from "./CalculationTable";

export const defaultPaymentType = PayoutPaymentType.Transfer;

const methodsToOptions = map(({ name, id }: PayoutMethod) => ({
  label: name,
  value: id,
}));

interface PayoutRequestFormProps {
  availablePayout: Fk<PayoutMethod>[];

  balance: number;

  initialValues: PayoutParams;

  overview: DataListDefaultItem[];

  payout?: Fk<Payout>;
}

export function PayoutRequestForm({
  availablePayout,
  balance,
  initialValues,
  overview,
  payout,
}: PayoutRequestFormProps): ReactElement {
  const { selectEntity } = useData(requestWalletPayoutDetail);

  const { method: payoutMethod = availablePayout, grossAmount: payoutAmount = 0 } = payout
    ? selectEntity(selectPayout(payout))
    : {};

  const methodOptions = useMemo(() => methodsToOptions(selectEntity(selectPayoutMethod(flatList(payoutMethod)))), [
    payoutMethod,
    selectEntity,
  ]);

  const validate = useMemo(
    () =>
      formValidator<PayoutParams>({
        grossAmount: (value, { method }) => {
          const { maxAmount, minAmount } = selectEntity(selectPayoutMethod(method));

          const min = minAmount ?? 0;
          const max = Math.min(maxAmount ?? Number.POSITIVE_INFINITY, balance + payoutAmount);

          if (value > max) return `${t("PayoutRequest.maximalAvailablePayout")} ${max}`;
          if (value < min) return `${t("PayoutRequest.minimalAvailablePayout")} ${min}`;

          return undefined;
        },
      }),
    [balance, payoutAmount, selectEntity]
  );

  const showAttachment = useCallback<FormDependantCond<PayoutParams>>(
    ({ method }) => selectEntity(selectPayoutMethod(method, "fileAttachment")),
    [selectEntity]
  );

  return (
    <Form<PayoutParams> initialValues={initialValues} submitRequest={requestWalletPayoutCreate} validate={validate}>
      <Grid spacing="data" vertical>
        <SpacingBox mx={-2}>
          <ExpandableArea title={t("PayoutRequest.overview")}>
            <DataList data={overview} disablePadding />
          </ExpandableArea>
        </SpacingBox>
        <Divider spacing={[0, -3, 0]} />
        <Grid spacing="data" vertical>
          <Header level={3}>{t("request")}</Header>
          <CurrencyInput label={t("amount")} min={0} name="grossAmount" required />
          <SelectInput
            disabled={isNotNil(payout)}
            label={t("PayoutRequest.payoutType")}
            name="method"
            options={methodOptions}
          />
          <FormDependant<PayoutParams> cond={showAttachment}>
            <FileInput label={t("attachment")} name="files" target={WalletPayoutCreateFilesTarget} />
          </FormDependant>
          <Divider spacing={[0, -3, 0]} />
          <SpacingBox mx={-2}>
            <CalculationTable />
          </SpacingBox>
          <Divider spacing={[0, -3, 0]} />
        </Grid>
        <Box pt={2}>
          <SubmitButton label={t("action.submit")} />
        </Box>
      </Grid>
    </Form>
  );
}
