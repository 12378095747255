import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { isNil } from "ramda";
import React, { ReactElement, useState } from "react";
import { RequestCreator, RequestOf } from "../contract";
import { RequestState } from "./useRequestState";

/**
 * Cache requests responses
 */

const CachedResponseContext = createCheckedContext("CachedResponse");
export const { use: useCachedResponse } = CachedResponseContext;

export interface CachedResponseProviderProps extends ChildrenProps {}

export function CachedResponseProvider({ children }: CachedResponseProviderProps): ReactElement {
  const state = useState<Record<string, RequestState<any>>>({});

  return <CachedResponseContext value={state}>{children}</CachedResponseContext>;
}

const CacheStateContext = createCheckedContext("CacheState");
export const { use: useCacheState } = CacheStateContext;
export interface CacheStateProviderProps<TCreator extends RequestCreator> extends ChildrenProps {
  request: RequestOf<TCreator>;
}

export function CacheStateProvider<TCreator extends RequestCreator = RequestCreator>({
  children,
  request,
}: CacheStateProviderProps<TCreator>): ReactElement {
  const [cache] = useCachedResponse();

  return <CacheStateContext value={!isNil(cache[request.requestHash])}>{children}</CacheStateContext>;
}
