import { isString, ReactRenderNode, ReactRenderTransform } from "@sinch/utils";
import { anyPass, both, concat, head, ifElse, map, prepend, reduceRight, startsWith, tail, when } from "ramda";
import { lengthGt } from "ramda-adjunct";
import React, { Children, ReactNode } from "react";

export interface SeparatorParams {
  separator?: string | ReactNode | null;

  containedSeparators?: string[];
}

const separatorDefault = " ";
const containedSeparatorsDefault = [".", ",", ":", ";"];

/**
 * todo: consider extracting as top level export of ui package
 */
export function createSeparator({
  separator = separatorDefault,
  containedSeparators = containedSeparatorsDefault,
}: SeparatorParams): ReactRenderTransform {
  if (!separator) {
    return Children.toArray as ReactRenderTransform;
  }

  /**
   * predicate: string starts with any of contained separators
   */
  const startsWithContained = anyPass(map(startsWith, containedSeparators));

  /**
   * predicate: node is string and starts with contained separator
   */
  const containsSeparator = both(isString, startsWithContained);

  /**
   * return function updating given array, prepending either current node alone,
   * or adding it with separator if no separator is already contained in it
   */
  const resolveSingle = ifElse(
    containsSeparator,
    (node) => prepend(node),
    (node) => concat([separator, node])
  );

  /**
   * reduce given array adding separators between appropriate elements
   */
  const resolveAll = reduceRight((node: ReactRenderNode, result: ReactRenderNode[]) => resolveSingle(node)(result), []);

  /**
   * resolve separators for given array, reduce its tail and merge head back
   * afterwards, effectively preventing prepending separator to the first node
   */
  const resolveNode = (nodes: ReactRenderNode[]): ReactRenderNode =>
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    prepend(head(nodes)!, resolveAll(tail(nodes)));

  /**
   * apply separators on arrays with more than one node
   */
  const applySeparators = when(lengthGt(1), resolveNode);

  return function separate(node: ReactNode): ReactRenderNode {
    return applySeparators(
      map<ReactRenderNode, ReactRenderNode>((nodeItem) => nodeItem, Children.toArray(node)) as ReactRenderNode[]
    );
  };
}
