import { Color, IconId, Nullable } from "@sinch/types";
import { ActionContainer, Grid, GridLayout, IdentifierColorKey, Strip } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { flatten, isEmpty } from "ramda";
import React, { ReactElement, ReactNode } from "react";

export interface StatusProps {
  action?: ActionContainer;

  color: Color | IdentifierColorKey;

  content: ReactNode | unknown;

  icon: IconId;

  /**
   * todo: use "id" instead to avoid react reserved prop name
   */
  key: string;

  secondaryAction?: ActionContainer;

  title?: string;
}

export interface StatusListProps {
  status: (StatusProps | StatusProps[] | false | undefined)[];
}

/**
 * todo: should we wrap by grid layout item externally instead of internally?
 */
export function StatusList({ status: statusProp }: StatusListProps): Nullable<ReactElement> {
  const status = flatten(rejectFalsy(statusProp));

  if (isEmpty(status)) {
    return null;
  }

  return (
    <GridLayout.Item>
      <Grid spacing="data" vertical>
        {status.map(({ action, color, content, icon, key, secondaryAction }) => (
          <Strip key={key} color={color} icon={icon} primaryAction={action} secondaryAction={secondaryAction}>
            {content}
          </Strip>
        ))}
      </Grid>
    </GridLayout.Item>
  );
}
