import { Consumer } from "@sinch/types";
import { pick } from "ramda";
import {
  Backend,
  FileUploadProgress,
  FileUploadRequest,
  FileUploadResponse,
  RequestCreator,
  RequestOf,
  ResponseOf,
  statusName,
  uploadProgressPercent,
} from "../contract";

const pickFileInfo = pick(["name", "size", "type"]);

export function backendLogger(backend: Backend): Backend {
  let counter = 0;

  function nextId() {
    counter += 1;
    return counter;
  }

  async function resolveRequest<TCreator extends RequestCreator>(
    request: RequestOf<TCreator>
  ): Promise<ResponseOf<TCreator>> {
    const requestId = nextId();

    const { key, params, meta } = request;

    console.log("REQ", requestId, key, { params, meta });

    const response = await backend.resolveRequest(request);

    console.log("RES", requestId, statusName(response.status), key, response);

    return response;
  }

  async function uploadFile(
    request: FileUploadRequest,
    onProgress?: Consumer<FileUploadProgress>
  ): Promise<FileUploadResponse> {
    const requestId = nextId();

    const { target, file } = request;

    console.log("FILE", requestId, target, pickFileInfo(file));

    function onProgressLogger(progress: FileUploadProgress): void {
      const { loaded, total } = progress;
      console.log(
        "FILE",
        requestId,
        "progress",
        `${uploadProgressPercent(progress)}%`,
        `(${Math.round(loaded)} of ${total})`
      );

      onProgress?.(progress);
    }

    const response = await backend.uploadFile(request, onProgressLogger);

    console.log(
      "FILE",
      requestId,
      statusName(response.status),
      target,
      response
    );

    return response;
  }

  return {
    ...backend,
    resolveRequest,
    uploadFile,
  };
}
