import { GridProps, makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";

const useStyles = makeStyles({
  columnGrid: {
    columnCount: ({ columns }: { columns: number }) => columns,
    display: "block !important", // safari fix
  },
});

interface FluidColumnsGridProps extends GridProps {
  children: ReactNode;
  columns: number;
}

export function FluidColumnsGrid({ children, columns, className, ...gridProps }: FluidColumnsGridProps): ReactElement {
  const styles = useStyles({ columns });

  return (
    <MuiGrid {...gridProps} className={clsx(styles.columnGrid, className)} container>
      {children}
    </MuiGrid>
  );
}
