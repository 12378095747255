import { makeStyles } from "@material-ui/core/styles";
import { useFormat } from "@sinch/intl";
import { assocPath } from "ramda";
import React, { ComponentProps, ReactElement } from "react";
import { DataTableCell } from "../DataTableCell";
import { DataTableCellProps, DataTableDisplay, DataTableProps } from "../types";

const useStyles = makeStyles((theme) => ({
  currencyCell: {
    whiteSpace: "nowrap",
  },
}));

export function currencyLogic<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>() {
  return function CurrencyLogic({ table, row, column }: DataTableCellProps<TData, TColumn, TDisplay>): ReactElement {
    const styles = useStyles();
    const { curr } = useFormat();

    const { config, display } = table;
    const { Cell } = display;
    // todo: set defaults on container initialization
    const CellLogic = config[column].logic || DataTableCell;

    const DisplayCell = ({ children, ...props }: ComponentProps<TDisplay["Cell"]>) => (
      <Cell className={styles.currencyCell} {...props}>
        {curr(children as number)}
      </Cell>
    );

    const updateDisplay: (
      props: DataTableProps<TData, TColumn, TDisplay>
    ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(["display", "Cell"], DisplayCell);

    return <CellLogic column={column} row={row} table={updateDisplay(table)} />;
  };
}
