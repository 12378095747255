import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from "@material-ui/core";
import { Consumer, FileHash, Nullable } from "@sinch/types";
import { flatList } from "@sinch/utils";
import { isEmpty, prepend, when } from "ramda";
import React, { ReactElement, SetStateAction, useCallback, useEffect } from "react";
import { Overwrite } from "utility-types";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { FileInputProps } from "./FileInput";
import { FileInputButton } from "./FileInputButton";
import { FileInputProvider, useFileInputContext } from "./FileInputProvider";
import { ImageInputItems, ImageInputItemsProps } from "./ImageInputItems";

/**
 * todo: support min/max image dimensions prop
 */
export interface ImageInputProps extends FileInputProps, ImageInputItemsProps {}

export function ImageInput(props: ImageInputProps): ReactElement {
  const [{ multiple, value, ...baseProps }, { setValue, setError }] = useFormField<
    Nullable<FileHash> | FileHash[],
    ImageInputProps
  >(props);

  const onError = useCallback(
    (error) => {
      setError(error);
    },
    [setError]
  );

  return (
    <ImageInputBase
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      multiple={multiple as never}
      onError={onError}
      onChange={setValue as never}
      value={value as never}
    />
  );
}

type ImageInputSingleBaseProps = BaseInputProps<
  Nullable<FileHash>,
  Overwrite<ImageInputProps, { multiple?: false }>,
  Consumer<SetStateAction<Nullable<FileHash>>>
>;

type ImageInputMultiBaseProps = BaseInputProps<
  FileHash[],
  Overwrite<ImageInputProps, { multiple: true }>,
  Consumer<SetStateAction<FileHash[]>>
>;

export function ImageInputBase(props: ImageInputSingleBaseProps): ReactElement;

export function ImageInputBase(props: ImageInputMultiBaseProps): ReactElement;

export function ImageInputBase({
  accept: acceptProp,
  cellHeight,
  cols,
  disabled,
  error: formError,
  extensions,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  label,
  maxSize,
  multiple,
  name,
  note,
  onChange,
  onError,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  onInvalid,
  target,
  value,
  required,
}: ImageInputSingleBaseProps | ImageInputMultiBaseProps): ReactElement {
  const accept = when(isEmpty, prepend("image/*"), flatList(acceptProp));
  const requiredSymbol = required ? "*" : "";

  return (
    <FileInputProvider
      accept={accept}
      extensions={extensions}
      maxSize={maxSize}
      multiple={multiple as never}
      onChange={onChange as never}
      target={target}
      value={value as never}
    >
      <FormControl error={formError} fullWidth margin="normal" variant="outlined">
        <InputLabel shrink>
          {label} {requiredSymbol}
        </InputLabel>
        <OutlinedInput
          fullWidth
          inputComponent={() => (
            <Box m={1} width="inherit">
              <ImageInputItems cellHeight={cellHeight} center cols={cols} />
              <Box pt={value && !isEmpty(value) ? 1 : 0} width="inherit">
                <FileInputButton disabled={disabled} error={formError} name={name} />
              </Box>
            </Box>
          )}
          label={label + requiredSymbol}
          notched
        />
        <FormHelperText>{note}</FormHelperText>
        <UploadErors onError={onError} />
      </FormControl>
    </FileInputProvider>
  );
}

function UploadErors({ onError }): ReactElement {
  const { errors } = useFileInputContext();

  useEffect(() => {
    if (!isEmpty(errors)) {
      onError(errors.join("\n"));
    }
  }, [errors]);
  return <></>;
}
