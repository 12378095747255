import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { useSnackbar } from "@sinch/ui";
import { ChildrenProps, composeElements, createCheckedContext } from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";
import { DataProvider, responseHandlerKey, useRefresh } from "../backend";
import { requestLogOut, requestSession } from "../contract";
import { BusinessRulesProvider } from "./BusinessRules";
import { CurrentUserProvider } from "./CurrentUser";
import { InitIntl } from "./InitIntl";
import { InstanceSettingsProvider } from "./InstanceSettings";
import { MenuItem } from "./MenuItem";
import { PushNotificationsProvider } from "./PushNotifications";
import { UserPreferencesProvider } from "./UserPreferencesProvider";

interface Session {
  refresh: Callback;
}

const SessionContext = createCheckedContext<Session>("Session");
export const { use: useSession, check: sessionExists } = SessionContext;

interface SessionProviderProps extends ChildrenProps {
  menu?: MenuItem[];
}

/**
 * Session provider provide application settings for nested components such as theme, logged user or settings of application
 */
export function SessionProvider({ children }: SessionProviderProps): ReactElement {
  const { refresh, refreshToken } = useRefresh({ interval: 30 * 60 * 1000 });
  const request = useMemo(() => requestSession(), []);
  const snackbar = useSnackbar();

  const context = useMemo(() => ({ refresh }), [refresh, refreshToken]);

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestLogOut,
        () => {
          snackbar("success", t("Application.logOutSuccessful"));
          refresh();
        },
        () => {
          snackbar("error", t("Application.logOutFailed"));
          refresh();
        }
      ),
    [refresh, snackbar]
  );

  return composeElements([
    // Data provider load settings from server
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request} />,
    // Session context provide data fetched from server
    <SessionContext value={context} />,
    // InitIntl component initialize Intl settings by data received from server
    <InitIntl />,
    // Instance settings provider allow access instance settings data as currency, language, timezone etc.
    <InstanceSettingsProvider />,
    // Business rules provider allow access business rules data as shift closing interval, allowing payment request etc.
    <BusinessRulesProvider />,
    // UserPrefereces context
    <UserPreferencesProvider />,
    // Current user provider hold and allow access to currently logged user data,
    <CurrentUserProvider />,
    // Push notifications context
    <PushNotificationsProvider />,
    children,
  ]);
}
