import { Box, DialogTitle } from "@material-ui/core";
import MuiAppBar from "@material-ui/core/AppBar";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import { mdiCheck, mdiClose } from "@mdi/js";

import { useInstanceSettings } from "@sinch/core";
import React, { ReactElement } from "react";
import { resolveAction } from "../../actions";
import { Icon } from "../../Icon";
import { Header } from "../../Text";
import { pickBasedOnBgColor } from "../../theme/helpers";
import { useSpacing } from "../../UiProvider";
import { ToolbarButton, ToolbarItem } from "../TitleBar";
import { DialogInternalProps } from "./DialogProps";
import { resolveDialogActions } from "./resolveDialogActions";

/**
 * todo: export toolbar height constants to be shared
 *  with ToolbarItem and MainMenu styles
 *  (consider constants vs custom theme variables?)
 */
const useStyles = makeStyles((theme) => ({
  container: {
    border: "none",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: ({ toolbarColor }: { toolbarColor: string }) =>
      theme.palette.type === "dark" ? theme.palette.background.default : toolbarColor,
  },
  toolbar: {
    alignItems: "stretch",
    height: theme.spacing(8),
  },
  fullScreenPadding: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export function FullscreenDialog({
  actions,
  children,
  onClose,
  open,
  subtitle,
  title,
  className,
}: Omit<DialogInternalProps, "hashKey">): ReactElement {
  const {
    theme: { toolbarColor },
  } = useInstanceSettings();
  const styles = useStyles({ toolbarColor });
  const [, inner] = useSpacing();

  const { cancel, confirm } = resolveDialogActions(onClose, actions);

  return (
    <MuiDialog className={className} fullScreen open={open}>
      <MuiAppBar
        className={styles.container}
        color={pickBasedOnBgColor(toolbarColor, "primary", "default")}
        position="sticky"
      >
        <MuiToolbar className={styles.toolbar} disableGutters variant="dense">
          <ToolbarItem hidden={!cancel}>
            <ToolbarButton onClick={resolveAction(cancel?.action).onClick}>
              <Icon icon={mdiClose} />
            </ToolbarButton>
          </ToolbarItem>

          <ToolbarItem grow>
            <Box px={inner}>
              <Header>{title}</Header>
            </Box>
          </ToolbarItem>

          <ToolbarItem hidden={!confirm}>
            <ToolbarButton disabled={confirm?.disabled} onClick={resolveAction(confirm?.action).onClick}>
              <Icon icon={mdiCheck} />
            </ToolbarButton>
          </ToolbarItem>
        </MuiToolbar>
      </MuiAppBar>
      {subtitle && (
        <DialogTitle className={styles.fullScreenPadding}>
          <Header level={3}>{subtitle}</Header>
        </DialogTitle>
      )}
      <MuiDialogContent className={styles.fullScreenPadding}>{children}</MuiDialogContent>
    </MuiDialog>
  );
}
