import { useIntl } from "@sinch/intl";
import { ChildrenProps, toElement } from "@sinch/utils";
import { ReactElement, useEffect } from "react";
import { useData } from "../backend";
import { requestSession } from "../contract";

export function InitIntl({ children }: ChildrenProps): ReactElement {
  const { selectResult } = useData(requestSession);
  const {
    currentUser: { language: sessionLanguage },
    instanceSettings: {
      currency: sessionCurrency,
      timeZone: sessionTimeZone,
      locale: sessionLocale,
    },
  } = selectResult();

  const {
    currency,
    language,
    locale,
    setCurrency,
    setLanguage,
    setLocale,
    setTimeZone,
    timeZone,
  } = useIntl();

  useEffect(() => {
    if (
      currency !== sessionCurrency ||
      language !== sessionLanguage ||
      locale !== sessionLocale ||
      timeZone !== sessionTimeZone
    ) {
      setCurrency(sessionCurrency);
      setLanguage(sessionLanguage);
      setLocale(sessionLocale || locale);
      setTimeZone(sessionTimeZone);
    }
  }, [
    currency,
    language,
    locale,
    sessionCurrency,
    sessionLanguage,
    sessionLocale,
    sessionTimeZone,
    setCurrency,
    setLanguage,
    setLocale,
    setTimeZone,
    timeZone,
  ]);

  return toElement(children);
}
