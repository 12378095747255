import { useData } from "@sinch/core";
import { selectSnippet } from "@sinch/entity";
import { Grid, useMobileLayout } from "@sinch/ui";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Notice } from "../components";
import { requestSuspendedPenaltyList } from "./api";
import { SuspendedPenaltyCardList } from "./SuspendedPenaltyCardList";
import { SuspendedPenaltyTable } from "./SuspendedPenaltyTable";

export function ExcusablePenaltyList(): ReactElement {
  const mobile = useMobileLayout();
  const { selectEntity } = useData(requestSuspendedPenaltyList);
  const notice = selectEntity(selectSnippet({ name: "conditional_penalty_agreement_text" }));
  return (
    <Grid spacing="inner" vertical>
      {mobile ? <SuspendedPenaltyCardList /> : <SuspendedPenaltyTable />}
      {isNotNilOrEmpty(notice) && (
        <Notice body={notice[0].body} color={notice[0].color} id={notice[0].id} style={notice[0].style} />
      )}
    </Grid>
  );
}
