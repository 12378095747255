import { defineRequest, FilesMeta, IdParams } from "@sinch/core";
import { Fk, Payout, PayoutContainer, PayoutMethodContainer } from "@sinch/entity";

export const requestWalletPayoutDetail = defineRequest("Wallets/PayoutDetail/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Payout>>()
  .withResponse<{ payoutId: Fk<Payout> }>()
  .withResponseMeta<FilesMeta>()
  .withResponseEntity<PayoutContainer & PayoutMethodContainer>()
  .withCreator((id: Fk<Payout>) => ({ params: { id } }));
