import { ChildrenProps } from "@sinch/utils";
import React, { createContext, ReactElement, useContext, useMemo } from "react";
import { DateInputProps } from "./DateInput.props";
import { DateTimeInputProps } from "./DateTimeInput.props";

/*
 * todo: consider implementation of generic InputPresetProvider
 */

type Preset<TProps extends DateInputProps | DateTimeInputProps> = Record<
  string,
  Partial<TProps>
> & {
  default?: Partial<TProps>;
};

interface DateInputPresetState {
  DateInput?: Preset<DateInputProps>;

  DateTimeInput?: Preset<DateTimeInputProps>;
}

const DateInputPresetContext = createContext<DateInputPresetState>({});

export interface PresetProps {
  preset?: string;
}

export function useDateTimePreset(
  component: "DateInput",
  preset?: string
): Partial<DateInputProps>;
export function useDateTimePreset(
  component: "DateTimeInput",
  preset?: string
): Partial<DateTimeInputProps>;
export function useDateTimePreset(
  component: "DateInput" | "DateTimeInput",
  preset = "default"
): Partial<DateInputProps> | Partial<DateTimeInputProps> {
  const contextState = useContext(DateInputPresetContext);

  return contextState?.[component]?.[preset] ?? {};
}

export function DateInputPresetProvider({
  children,
  DateInput,
  DateTimeInput,
}: DateInputPresetState & ChildrenProps): ReactElement {
  const context = useMemo(() => ({ DateInput, DateTimeInput }), [
    DateInput,
    DateTimeInput,
  ]);

  return (
    <DateInputPresetContext.Provider value={context}>
      {children}
    </DateInputPresetContext.Provider>
  );
}
