import { useData } from "@sinch/core";
import { selectSnippet } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { GridLayout } from "@sinch/ui";
import React, { ReactElement } from "react";
import { Notice } from "../../Dashboard/components";
import { requestAppointmentDetail } from "./api";

export function AppointmentDetailSnippets(): Nullable<ReactElement> {
  const { selectEntity } = useData(requestAppointmentDetail);
  const notices = selectEntity(selectSnippet({}));
  return (
    <GridLayout.Item>
      {notices.map(({ body, color, id, style }) => (
        <Notice body={body} color={color} id={id} style={style} />
      ))}
    </GridLayout.Item>
  );
}
