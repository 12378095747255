import { ButtonProps } from "@material-ui/core/Button";
import { Callback, Color, IconId } from "@sinch/types";
import { isFunction, isPlainObj } from "ramda-adjunct";
import { ElementType } from "react";
import { ExternalLink, externalLinkProps, isExternalLink } from "./ExternalLink";
import { isRouterLink, RouterLink, routerLinkProps } from "./RouterLink";

interface ComponentProps {
  onClick?: Callback;

  component?: ElementType;
}

export type Action = Callback | RouterLink | ExternalLink;

export interface ActionContainer {
  action: Action;

  color?: Color;

  disabled?: boolean;

  label: string;

  icon?: IconId;

  btnProps?: ButtonProps & { loading: boolean };
}

/**
 * todo: consider extending link actions with onClick handler if needed
 *  (simpler solution than composing multiple actions together)
 *
 * todo: consider renaming to actionProps
 *
 * todo: add support to handle ActionContainer as well
 *  (resolve action first and then append other props)
 *  - then simple Action and named ActionContainer might
 *    be merged into single union type for most usages
 */
export function resolveAction(action?: Action): ComponentProps {
  if (isFunction(action)) {
    return { onClick: action };
  }

  if (isPlainObj(action)) {
    if (isRouterLink(action)) return routerLinkProps(action);
    if (isExternalLink(action)) return externalLinkProps(action);
  }

  return {};
}
