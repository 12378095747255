import { defineRequest, IdParams } from "@sinch/core";
import { Fk, PositionContainer, ShiftContainer, WalletTransaction, WalletTransactionContainer } from "@sinch/entity";

export interface WalletCompositionRecord {
  name: string;
  description?: string;
  wageHour: number;
  wageFix: number;
  amount: number;
  unit?: string;
  total: number;
  wagePercentage: number;
}

interface WalletViewResponse {
  /**
   * Wallet composition records
   */
  records: WalletCompositionRecord[];
  /**
   * Total amount of wallet
   */
  total: number;
  /**
   * Wallet id
   */
  wallet: Fk<WalletTransaction>;
}

/**
 * Request for wallet detail
 */
export const requestWalletView = defineRequest("Wallets/View/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<WalletTransaction>>()
  .withResponse<WalletViewResponse>()
  .withResponseEntity<WalletTransactionContainer & PositionContainer & ShiftContainer>()
  .withCreator((id: Fk<WalletTransaction>) => ({ params: { id } }));
