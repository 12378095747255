import { AccordionProps } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useData } from "@sinch/core";
import { CompoundedInput, SelectInput, TextInput, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { SelectFieldOption } from "@sinch/types";
import { isNil, map } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { BankAccountFormat, requestProfilePersonal } from "../api";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { useSystemAttributes } from "../hooks";
import { parseSelectedBankAccountFormat, useFieldListMeta } from "../utils";

const parseBankAccountFormats = (bankAccountFormats: BankAccountFormat[]): SelectFieldOption[] =>
  map(({ id, label }) => ({ value: id, label }), bankAccountFormats);

export interface EditBankParams {
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
}

export function EditBank({ expanded, onExpansionChange }: EditBankParams): ReactElement | null {
  const { selectMeta } = useData(requestProfilePersonal);

  const { bankAccountFormats } = selectMeta();
  const { bankAccountFormat } = useFormValues();
  const selectedBankAccountFormat = parseSelectedBankAccountFormat(bankAccountFormats, bankAccountFormat);

  // hardcoded
  const selectedBankAccountSegments = !isNil(selectedBankAccountFormat) ? selectedBankAccountFormat.segments : [];

  const { isBlockEmpty, isVisible, isRequired } = useSystemAttributes();
  const { total, filled, errors, fields } = useFieldListMeta(
    isVisible("Workerinfo.bank_account_number")
      ? ["bankAccountFormat", "bankAccountSegments", "bankAccountHolderName"]
      : []
  );

  const empty = isBlockEmpty(["Workerinfo.bank_account_number"]);

  const content = useMemo(
    () => (
      <MuiGrid container direction="column">
        <MuiGrid item>
          <div key="format">
            <SelectInput
              key="bankAccountFormat"
              label={t("field.bankAccountFormat")}
              name="bankAccountFormat"
              options={parseBankAccountFormats(bankAccountFormats)}
              placeholder={t("field.bankAccountFormat")}
            />
          </div>
          <div key="segments">
            <CompoundedInput
              name="bankAccountSegments"
              required={isRequired("Workerinfo.bank_account_number")}
              schema={selectedBankAccountSegments}
            />
          </div>
        </MuiGrid>
        <MuiGrid item>
          <TextInput
            key="bankAccountHolderName"
            label={t("field.bankAccountHolderName")}
            name="bankAccountHolderName"
            placeholder={t("field.bankAccountHolderName")}
            required={isRequired("Workerinfo.bank_account_number")}
          />
        </MuiGrid>
      </MuiGrid>
    ),
    [filled, total, errors, fields]
  );

  if (empty) {
    return null;
  }

  return (
    <ProfileSettingAccordionItem
      error={errors > 0}
      expanded={expanded}
      filled={filled}
      label={t("Profile.display.bankInfo")}
      onExpansionChange={onExpansionChange}
      total={total}
    >
      {content}
    </ProfileSettingAccordionItem>
  );
}
