import { mdiPencil } from "@mdi/js";
import { ResponseOf, SelectEntity, useInstanceSettings } from "@sinch/core";
import { PersonalAttributeCitizenship, ProfileAttributeType, selectPersonalAttribute } from "@sinch/entity";
import { t, useFormat, useIntl } from "@sinch/intl";
import { FileHash, Key, Nullable, SelectFieldOption } from "@sinch/types";
import { Button, routerLink, Text, useMobileLayout } from "@sinch/ui";
import { isDefined, logError, parseExactDate, rejectFalsy } from "@sinch/utils";
import { isBefore } from "date-fns";
import { useFormikContext } from "formik";
import {
  defaultTo,
  equals,
  filter,
  find,
  head,
  is,
  isEmpty,
  isNil,
  join,
  length,
  map,
  pipe,
  propEq,
  reduce,
  reject,
} from "ramda";
import { isUndefined } from "ramda-adjunct";
import React, { ReactElement } from "react";
import {
  Address,
  BankAccountFormat,
  ProfileAttributeValue,
  ProfilePersonalMeta,
  ProfilePersonalResult,
  requestProfilePersonal,
  Sex,
} from "./api";
import { countryOptions } from "./countries";

export function useFieldListMeta(fields: string[], prefix = "", suffix = "") {
  const { getFieldMeta } = useFormikContext();
  const meta = { filled: 0, total: fields.length, errors: 0, fields };
  fields.forEach((field) => {
    const { error, value } = getFieldMeta(`${prefix}${field}${suffix}`);
    meta.filled += isEmpty(value) || isNil(value) ? 0 : 1;
    meta.errors += isNil(error) ? 0 : 1;
  });
  return meta;
}

export type StaticAttributeValue = {
  name: string;
  value: unknown;
  type: ProfileAttributeType;
  params?: { options?: SelectFieldOption[] };
  citizenship?: PersonalAttributeCitizenship;
  id?: number | string;
  label: string;
  hash?: string;
  description?: string;
  required?: boolean;
};

export const getFilled = (values: ProfileAttributeValue[] | StaticAttributeValue[]): number =>
  isDefined(values)
    ? length(
        filter(
          ({ value }: ProfileAttributeValue | StaticAttributeValue) => isDefined(value),
          /* @ts-expect-error */
          values
        )
      )
    : 0;

export const isExpired = (expirationTime: Date): boolean =>
  isDefined(expirationTime) && isBefore(expirationTime, new Date());

const createAttribute = (
  name: string,
  label: string,
  value: unknown,
  type: ProfileAttributeType,
  params: { options?: SelectFieldOption[]; additionalAttributes?: Record<string, string> } = {},
  citizenship?: PersonalAttributeCitizenship,
  id?: number | string
) => {
  if (isUndefined(value)) return null;
  return {
    name,
    value,
    type,
    params,
    citizenship,
    label,
    id,
  };
};

const parseAddress = ({ street, city, zip, region }: Address, name: string, label: string) => ({
  name,
  value: reject<string>(isEmpty)([
    street,
    // @ts-ignore
    pipe<string>(
      reject<string>((item) => isEmpty(item) || isNil(item)),
      join(", ")
    )([zip, city, region]),
  ]),
  type: ProfileAttributeType.Text,
  label,
  street,
  zip,
  city,
  region,
  id: name,
});

export const bankViewData = (
  selectedBankAccountFormat: BankAccountFormat | undefined,
  bank: StaticAttributeValue[],
  bankAccountHolderName: string
): StaticAttributeValue[] => {
  if (!selectedBankAccountFormat || !bank[0].value) {
    return [];
  }

  return rejectFalsy([
    createAttribute(
      bank[0].name,
      bank[0].label,
      /* @ts-ignore */
      reduce((acc, next) => acc + next, "", Object.values(bank[0].value)),
      ProfileAttributeType.Text,
      undefined,
      undefined,
      "bankAccountSegments"
    ),
    createAttribute(
      "bankAccountHolderName",
      t("field.bankAccountHolderName"),
      bankAccountHolderName,
      ProfileAttributeType.Text,
      undefined,
      undefined,
      "bankAccountHolderName"
    ),
  ]);
};

export const parseSelectedBankAccountFormat = (
  bankAccountFormats: BankAccountFormat[],
  bankAccountFormat: string
): BankAccountFormat | undefined => head(filter(({ id }) => equals(id, bankAccountFormat), bankAccountFormats));

const isVisibleGroup = (systemAttributes: ProfilePersonalResult["systemAttributes"], items: string[]): boolean =>
  items.reduce((acc, next) => {
    if (systemAttributes.find(({ name }) => equals(name, next))) {
      return true;
    }
    return acc;
  }, false);

export const parsePersonal = (
  {
    bankAccountFormat,
    bankAccountNumber,
    birthDate,
    citizenship,
    correspondenceAddress,
    email,
    firstName,
    lastName,
    permanentAddress,
    personalAttributes,
    phone,
    sex,
    permanentAddressPreferred,
    localAddressPreferred,
    preferredPlaces,
    isPermanentResident,
    permanentResident,
    systemAttributes,
  }: ProfilePersonalResult,
  { bankAccountFormats }: ProfilePersonalMeta,
  selectEntity: SelectEntity<ResponseOf<typeof requestProfilePersonal>>
): {
  basic: StaticAttributeValue[];
  contact: StaticAttributeValue[];
  residence: Address[];
  bank: StaticAttributeValue[];
  additional: StaticAttributeValue[];
  citizenship?: string;
  selectedBankAccountFormat: BankAccountFormat;
  preferredLocation: StaticAttributeValue[];
} => {
  const { locale } = useIntl();
  const { dt } = useFormat();
  const { country } = useInstanceSettings();
  const basic = rejectFalsy([
    createAttribute(
      "firstName",
      t("field.firstName"),
      firstName,
      ProfileAttributeType.Text,
      undefined,
      undefined,
      "firstName"
    ),
    createAttribute(
      "lastName",
      t("field.lastName"),
      lastName,
      ProfileAttributeType.Text,
      undefined,
      undefined,
      "lastName"
    ),
    createAttribute(
      "sex",
      t("Worker.gender"),
      sex,
      ProfileAttributeType.Select,
      {
        options: [
          { value: Sex.Male, label: t("Worker.genderMale") },
          { value: Sex.Female, label: t("Worker.genderFemale") },
          { value: Sex.NotSpecified, label: t("Worker.genderUnspecified") },
        ],
      },
      undefined,
      "sex"
    ),
    createAttribute(
      "birthDate",
      t("Worker.birthDate"),
      birthDate,
      ProfileAttributeType.Date,
      undefined,
      undefined,
      "birthDate"
    ),
    // todo add all countries as options
    createAttribute(
      "citizenship",
      t("Worker.nationality"),
      citizenship,
      ProfileAttributeType.Select,
      {
        options: countryOptions(locale, country),
      },
      undefined,
      "citizenship"
    ),
    isPermanentResident &&
      createAttribute(
        "isPermanentResident",
        t("Worker.isPermanentResident"),
        isPermanentResident,
        ProfileAttributeType.Bool,
        undefined,
        undefined,
        "isPermanentResident"
      ),
    isPermanentResident &&
      createAttribute(
        "permanentResident",
        t("Worker.permanentResident"),
        <Text separator=" - ">
          {permanentResident?.from ? dt(parseExactDate(permanentResident.from)) : null}
          {permanentResident?.to ? dt(parseExactDate(permanentResident.to)) : null}
        </Text>,
        ProfileAttributeType.Text,
        undefined,
        undefined,
        "permanentResident"
      ),
  ]);

  const contact = rejectFalsy([
    createAttribute("phone", t("field.phone"), phone, ProfileAttributeType.Phone, undefined, undefined, "phone"),
    createAttribute(
      "email",
      t("email"),
      email,
      ProfileAttributeType.Text,
      { additionalAttributes: { "data-cy": "email-field" } },
      undefined,
      "email"
    ),
  ]);

  const residence = rejectFalsy([
    permanentAddress && parseAddress(permanentAddress, "permanentAddress", t("Worker.permanentAddress")),
    correspondenceAddress && parseAddress(correspondenceAddress, "correspondenceAddress", t("Worker.contactAddress")),
  ]);

  const profession = [
    createAttribute(
      "permanentAddressPreferred",
      t("Profile.permanentAdressPreferredLabel"),
      permanentAddressPreferred,
      ProfileAttributeType.Bool,
      undefined,
      undefined,
      "permanentAddressPreferred"
    ),
  ];

  const preferredLocation = rejectFalsy([
    permanentAddress &&
      createAttribute(
        "permanentAddressPreferred",
        t("Profile.permanentAdressPreferredLabel"),
        permanentAddressPreferred,
        ProfileAttributeType.Bool,
        undefined,
        undefined,
        "permanentAddressPreferred"
      ),
    correspondenceAddress &&
      createAttribute(
        "localAddressPreferred",
        t("Profile.contactAddressPreferredLabel"),
        localAddressPreferred,
        ProfileAttributeType.Bool,
        undefined,
        undefined,
        "localAddressPreferred"
      ),
    createAttribute(
      "preferredPlaces",
      t("Profile.preferredLocationsLabel"),
      preferredPlaces,
      ProfileAttributeType.Text,
      undefined,
      undefined,
      "preferredPlaces"
    ),
  ]);

  const selectedBankAccountFormat = parseSelectedBankAccountFormat(bankAccountFormats, bankAccountFormat);

  const bank = rejectFalsy([
    createAttribute("bankAccountSegments", t("field.bankAccount"), bankAccountNumber, ProfileAttributeType.Text),
  ]);

  const additional = map(({ value, id }) => {
    /* eslint-disable-next-line @typescript-eslint/no-shadow */
    const { name, type, params, citizenship } = selectEntity(selectPersonalAttribute(id));
    return createAttribute(`personalAttributes.${id}`, name, value, type, params, citizenship, id);
  }, personalAttributes);

  const visibleGroups = {
    basic: isVisibleGroup(systemAttributes, [
      "User.name",
      "User.surname",
      "User.birthdate",
      "Workerinfo.sex",
      "Workerinfo.citizenship",
      "Workerinfo.permanent_resident",
      "Workerinfo.permanent_duration_from",
      "Workerinfo.permanent_duration_to",
    ]),
    residence: isVisibleGroup(systemAttributes, [
      "Workerinfo.address",
      "Workerinfo.city",
      "Workerinfo.zip",
      "Workerinfo.country",
      "Workerinfo.region",
      "Workerinfo.address_local",
      "Workerinfo.city_local",
      "Workerinfo.region_local",
      "Workerinfo.zip_local",
      "Workerinfo.country_local",
    ]),
    preferredLocation: isVisibleGroup(systemAttributes, ["WorkerPreferredAddress.preferred_address"]),
    bank: isVisibleGroup(systemAttributes, ["Workerinfo.bank_account_number"]),
  };

  return {
    basic,
    contact,
    residence,
    preferredLocation,
    bank,
    additional,
    citizenship,
    /* @ts-expect-error */
    selectedBankAccountFormat,
    profession,
    visibleGroups,
  };
};

export const defaultMultiSelect = defaultTo([]);

export const defaultText = defaultTo("");
export const defaultNumber = defaultTo("");
export const defaultSelect = defaultTo("");
export const defaultDate = defaultTo(null);

export const getBoolSelectOptions = () => [
  { value: 0, label: t("no") },
  { value: 1, label: t("yes") },
];

export const parseBoolValue = (value?: boolean): Key | boolean => {
  if (!isDefined(value)) return "";
  return value;
};
export const parseBoolLabel = (value?: boolean): Key => {
  if (!isDefined(value)) return "";
  const item = find<{ value: number | boolean; label: string }>(
    propEq("value", is(String, value) ? parseInt(value, 10) : +value),
    getBoolSelectOptions()
  );
  return item?.label || "";
};

/**
 * todo: data received from backend should be validated on http client layer,
 *  log error with fallback to default value to prevent failure in production
 *  (optionally combine with React Suspense to split error boundaries)
 */
export function parseSelectValue(options: SelectFieldOption[], value: never): string {
  const selected = find((option: SelectFieldOption) => propEq("value", value, option), options);

  if (selected) return selected.label;

  logError("SelectInput value not found in provided options, returning undefined", { options, value });

  return t("undefined");
}

export type ProfileEditButtonParams = {
  edit: string;
  label: string;
};

export function ProfileEditButton({ edit, label }: ProfileEditButtonParams): ReactElement {
  const mobile = useMobileLayout();
  return (
    <Button
      action={routerLink({
        pathname: `/profile/edit/${edit}`,
        hash: `#${label}`,
      })}
      color="neutral"
      icon={mobile ? mdiPencil : undefined}
      stretch
      variant="text"
    >
      {!mobile && t("action.edit")}
    </Button>
  );
}

export const parseInitialProfileValue = (
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
  value: any,
  type: ProfileAttributeType
): unknown => {
  switch (type) {
    case ProfileAttributeType.Number:
      return defaultNumber(value);
    case ProfileAttributeType.Text:
      return defaultText(value);
    case ProfileAttributeType.Bool:
      return parseBoolValue(value);
    case ProfileAttributeType.Select:
      return defaultSelect(value);
    case ProfileAttributeType.MultiSelect:
      return defaultMultiSelect(value);
    case ProfileAttributeType.Level:
      return defaultSelect(value);
    case ProfileAttributeType.File:
      return (value || null) as Nullable<FileHash>;
    case ProfileAttributeType.Image:
      return (value || null) as Nullable<FileHash>;
    case ProfileAttributeType.Gallery:
      return (value || []) as FileHash[];
    case ProfileAttributeType.LongText:
      return defaultText(value);
    case ProfileAttributeType.Date:
      return (value || null) as Nullable<FileHash>;
    default:
      return defaultText(value);
  }
};
