import { defineRequest, IdParams } from "@sinch/core";
import {
  FeedbackContainer,
  Fk,
  PositionAttendanceContainer,
  PositionContainer,
  ProfessionContainer,
  Shift,
  ShiftContainer,
  WorkerContainer,
} from "@sinch/entity";

export const requestShiftFeedbackList = defineRequest("Shifts/FeedbackList/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Shift>>()
  .withResponse<{ shiftId: Fk<Shift> }>()
  .withResponseEntity<
    FeedbackContainer &
      PositionContainer &
      PositionAttendanceContainer &
      ProfessionContainer &
      ShiftContainer &
      WorkerContainer
  >()
  .withCreator((id: Fk<Shift>) => ({ params: { id } }));
