// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataSelectors, RequestCreatorAny, ResponseOf, useData } from "@sinch/core";
import { Fk, Position, PositionContainer, PositionQ, sAnd, selectPosition, sNot, sOr } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  ChipedList,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedEndStatus,
  CondensedHeader,
  CondensedStatus,
  CondensedTitle,
  ConnectedMark,
  DateChip,
  LinkedListContainer,
  LinkedSimpleListItem,
  ListDelimiter,
  ListItemBorder,
  ListItemContent,
  SimpleListItem,
  SpacingBox,
  Text,
} from "@sinch/ui";
import clsx from "clsx";
import { startOfDay } from "date-fns";
import { groupBy, head, includes, isEmpty, isNil, pipe, prop, sortBy } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router";
import {
  PositionCapacity,
  PositionPlace,
  PositionProfession,
  PositionRole,
  PositionStatusIcons,
  PositionTime,
} from "../../components";
import { PositionReference } from "../Detail/PositionReference";
import { requestPositionList } from "./api";
import { FeaturedChip } from "./components";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: `${theme.palette.neutral.main} !important`,
  },
  assigned: {
    backgroundColor: theme.palette.completed.main,
  },
  confirmationRequired: {
    backgroundColor: theme.palette.confirmationNeeded.main,
  },
  applied: {
    backgroundColor: theme.palette.applied.main,
  },
}));

export interface PositionTableProps<TField extends string = string> {
  fields: TField[];
}

export function PositionListCondensed<TField extends string>({ fields }: PositionTableProps<TField>): ReactElement {
  const { selectEntity, selectResult } = useData(requestPositionList);
  const { positionIds, featuredPositionIds } = selectResult();

  // Get all non-featured positions, sort them and group them by start time
  const positions = pipe<Position[], Position[], Record<string, Position[]>>(
    sortBy(prop("startTime")),
    groupBy<Position, string>(({ startTime }): string => `${startOfDay(startTime).getTime()}`)
  )(selectEntity(selectPosition(positionIds)));

  // get all featured positions
  const featured = pipe<Position[], Position[]>(sortBy(prop("startTime")))(
    selectEntity(selectPosition(featuredPositionIds))
  );

  return (
    <SpacingBox mx={-1}>
      <ChipedList>
        {!isEmpty(featured) && (
          <>
            <React.Fragment key="featured">
              <ListDelimiter key="chip-featured">
                <FeaturedChip />
              </ListDelimiter>
              {featured.map(({ id }: Position) => (
                <PositionListRow key={id} fields={fields} id={id} />
              ))}
            </React.Fragment>
          </>
        )}
        {Object.entries(positions).map(([index, positionList]) => (
          <React.Fragment key={index}>
            {head(positionList) && (
              <ListDelimiter key={`chip-${index}`}>
                <DateChip color="primary" date={head(positionList)!.startTime} />
              </ListDelimiter>
            )}
            {positionList.map(({ id }: Position) => (
              <PositionListRow key={id} fields={fields} id={id} />
            ))}
          </React.Fragment>
        ))}
      </ChipedList>
    </SpacingBox>
  );
}

function isDisabled(id: number, queryEntity: DataSelectors<ResponseOf<RequestCreatorAny>>["queryEntity"]) {
  const hasApplicants = (positionId: Fk<Position>) => (container: PositionContainer) =>
    selectPosition(positionId, "applicants")(container);

  return queryEntity(
    sOr(
      PositionQ.isCancelled,
      sAnd(
        sNot(PositionQ.User.isAssigned),
        sNot(hasApplicants),
        sOr(PositionQ.isFull, PositionQ.User.hasRequirementsFailed, PositionQ.User.hasConflicting)
      )
    )(id)
  );
}

function PositionListRow({ id, fields }: { id: Fk<Position> } & PositionTableProps): ReactElement {
  const classes = useStyles();
  const { selectEntity, queryEntity } = useData(requestPositionList);
  const { connected } = selectEntity(selectPosition(id));
  const disabled = isDisabled(id, queryEntity);
  const navigate = useNavigate();
  const isAssigned = queryEntity(PositionQ.User.isAssigned(id));
  const isConfirmationRequired = queryEntity(PositionQ.User.isRequiredConfirmation(id));
  const isApplied = queryEntity(PositionQ.User.isApplicant(id));

  return useMemo(
    () => (
      <>
        <SimpleListItem
          className={clsx(
            disabled && classes.disabled,
            isAssigned && !isConfirmationRequired && classes.assigned,
            isConfirmationRequired && classes.confirmationRequired,
            isApplied && classes.applied
          )}
          onClick={() => navigate(`/position/${id}`)}
        >
          <ListItemBorder>
            <PositionRole id={id} />
          </ListItemBorder>
          <ListItemContent>
            <PositionListItemContent fields={fields} id={id} />
          </ListItemContent>
        </SimpleListItem>
        {!isNil(connected) && !isEmpty(connected) && <LinkedListItem fields={fields} positionsIds={connected} />}
      </>
    ),
    [id, fields, connected]
  );
}

function LinkedListItem({ positionsIds, fields }: { positionsIds: Fk<Position>[] } & PositionTableProps): ReactElement {
  const classes = useStyles();
  const { selectEntity, queryEntity } = useData(requestPositionList);
  const positions = pipe(sortBy(prop("startTime")))(selectEntity(selectPosition(positionsIds)));
  const navigate = useNavigate();

  return (
    <Box fontSize="0.75rem">
      <LinkedListContainer
        hideLabel={t("Position.hideConnectedShifts")}
        showLabel={t("Position.showConnectedShifts", { count: positions.length - 3 })}
      >
        {positions.map(({ id }) => {
          const disabled = isDisabled(id, queryEntity);
          const isAssigned = queryEntity(PositionQ.User.isAssigned(id));
          return (
            <LinkedSimpleListItem
              key={id}
              className={clsx(disabled && classes.disabled, isAssigned && classes.assigned)}
              onClick={() => navigate(`/position/${id}`)}
            >
              <ConnectedMark />
              <ListItemContent>
                <PositionListItemContent fields={fields} id={id} />
              </ListItemContent>
            </LinkedSimpleListItem>
          );
        })}
      </LinkedListContainer>
    </Box>
  );
}

function PositionListItemContent({ id, fields }: { id: Fk<Position> } & PositionTableProps) {
  return (
    <CondensedCard>
      <CondensedHeader>
        <CondensedTitle>
          <Text bold>
            <PositionReference id={id} name noId title />
          </Text>
        </CondensedTitle>
        <CondensedStatus>
          <PositionStatusIcons id={id} />
        </CondensedStatus>
      </CondensedHeader>
      <CondensedBody>
        <CondensedContent>
          <PositionTime id={id} />
          {includes("place", fields) && <PositionPlace id={id} />}
          {includes("profession", fields) && <PositionProfession id={id} />}
        </CondensedContent>
        <CondensedEndStatus>
          <PositionCapacity id={id} />
        </CondensedEndStatus>
      </CondensedBody>
    </CondensedCard>
  );
}
