import { Func, Nullable } from "@sinch/types";
import { toDate, zonedTimeToUtc } from "date-fns-tz";
import { formatISOWithOptions, isAfter, isBefore, parseISO, set } from "date-fns/fp";
import { when } from "ramda";
import { isDate, isString } from "ramda-adjunct";
import { isValue } from "./is";

export function parseDate(val: Date): Date;
export function parseDate(val: string): Date;
export function parseDate(val?: undefined): undefined;
export function parseDate(val?: string | Date): Date | undefined;
export function parseDate(val?: string | Date): Date | undefined {
  if (isDate(val)) return val;
  if (isString(val)) return parseISO(val);
  return undefined;
}

export function parseExactDate(val: Date): Date;
export function parseExactDate(val: string): Date;
export function parseExactDate(val?: undefined): null;
export function parseExactDate(val?: string | Date): Date | null;
export function parseExactDate(val?: string | Date): Date | null {
  if (isDate(val)) return zonedTimeToUtc(val, "GMT");
  if (isString(val)) return toDate(val, { timeZone: "GMT" });
  return null;
}

export const dayEnd = set({
  hours: 23,
  minutes: 59,
  seconds: 59,
  milliseconds: 999,
});

export const dayStart = set({
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});

export function isFuture(date: Date): boolean {
  return isAfter(new Date(), date);
}

export function isPast(date: Date): boolean {
  return isBefore(new Date(), date);
}

type SerializeDate = Func<Date, string>;

export const serializeDate: SerializeDate = formatISOWithOptions({
  representation: "date",
});

export const serializeTime: SerializeDate = formatISOWithOptions({
  representation: "time",
});

export const serializeDateTime: SerializeDate = formatISOWithOptions({
  representation: "complete",
});

/**
 * Parse string time, can convert partial time input to full time
 * @return [hour: number, minute: number]
 */
export const parseTime = (time: string): number[] => {
  let hour;
  let minute;
  const pm = time.match(/p/i) !== null;
  const num = time.replace(/[^0-9]/g, "");

  // Parse for hour and minute
  switch (num.length) {
    case 4:
      hour = parseInt(num[0] + num[1], 10);
      minute = parseInt(num[2] + num[3], 10);
      break;
    case 3:
      hour = parseInt(num[0], 10);
      minute = parseInt(num[1] + num[2], 10);
      break;
    case 2:
    case 1:
      hour = parseInt(num[0] + (num[1] || ""), 10);
      minute = 0;
      break;
    default:
      return [0, 0];
  }

  // Make sure hour is in 24 hour format
  if (pm && hour > 0 && hour < 12) {
    hour += 12;
  }

  // Keep within range
  if (hour <= 0 || hour >= 24) {
    hour = 0;
  }
  if (minute < 0 || minute > 59) {
    minute = 0;
  }

  return [hour, minute];
};

/* @ts-expect-error */
export const dateToUrl: (date: Nullable<Date> | undefined) => string | undefined = when(isValue, serializeDateTime);
