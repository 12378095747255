import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useMemo } from "react";
import { useMenuContext } from "./context";

export const SIDEBAR_WIDTH_COLLAPSED = 8;
export const SIDEBAR_WIDTH_EXPANDED = 32;

/**
 * todo: split and collocate with individual components
 */
export const useStyles = makeStyles((theme) => ({
  item: {
    height: theme.spacing(6),
  },
  desktopClosed: {
    // width: theme.spacing(SIDEBAR_WIDTH_COLLAPSED),
  },
  desktopOpened: {
    // width: theme.spacing(SIDEBAR_WIDTH_EXPANDED),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  /**
   * todo: get height value for underBar from shared constant
   */
  underBar: {
    height: theme.spacing(8),
  },
  underBarMobile: {
    height: theme.spacing(6),
  },
  popupMenu: {
    width: theme.spacing(32),
    marginLeft: theme.spacing(1),
  },
  popupMenuItem: {
    paddingRight: theme.spacing(2),
  },
  mobilePaper: {
    width: "100%",
    display: "block",
    background: theme.palette.grey[200],
  },
}));

export function useListItemStyle(nested = false): string {
  const styles = useStyles();
  const { mobile, opened } = useMenuContext();

  return useMemo(
    () =>
      clsx(
        styles.item,
        !mobile && (opened ? styles.desktopOpened : styles.desktopClosed),
        nested && styles.nested
      ),
    [mobile, nested, opened, styles]
  );
}
