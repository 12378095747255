import { t } from "@sinch/intl";
import { Grid, Text } from "@sinch/ui";
import React, { ReactElement } from "react";

export function PayoutRequestCancel(): ReactElement {
  return (
    <Grid spacing="inner" vertical>
      <Text bold>{t("PayoutRequest.cancelPayoutConfirmation")}</Text>
    </Grid>
  );
}
