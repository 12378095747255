import { WorkerRole } from "@sinch/entity";

/**
 * Create complementary selector returning negated value.
 */
export function sNot(selectorFunction) {
  return (...params) => (container, appContext) =>
    !selectorFunction(...params)(container, appContext);
}

/**
 * Create combined selector returning AND value of selectors.
 */
export function sAnd(...selectors) {
  return (...params) => (container, appContext) => {
    for (let i = 0; i < selectors.length; i += 1) {
      const selector = selectors[i];
      const result = selector(...params)(container, appContext);
      if (!result) {
        return false;
      }
    }
    return true;
  };
}

/**
 * Create combined selector returning OR value of selectors.
 */
export function sOr(...selectors) {
  return (...params) => (container, appContext) => {
    for (let i = 0; i < selectors.length; i += 1) {
      const selector = selectors[i];
      const result = selector(...params)(container, appContext);
      if (result) {
        return true;
      }
    }
    return false;
  };
}


/**
 * Sort positions
 */
export const sortPosition = (selectors) => {
  return selectors.sort((a, b) => {
    const order = [WorkerRole.Crewboss, WorkerRole.Worker, WorkerRole.Backup];
    // First, sort by role: 1, 0, 2
    if (a.role !== b.role) {
      return order.indexOf(a.role) - order.indexOf(b.role);
    }

    // If roles are equal, sort by startTime
    if (a.startTime !== b.startTime) {
      return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    }

    // If startTimes are equal, sort by endTime, shortest first
    return new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
  })
}
