import { Callback } from "@sinch/types";
import {
  ChildrenProps,
  composeElements,
  createCheckedContext,
} from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";

interface CascadeRefresh {
  cascadeRefresh: Callback;
}

const CascadeRefreshContext = createCheckedContext<CascadeRefresh>(
  "StatusUpdate"
);
export const { use: useCascadeRefresh } = CascadeRefreshContext;

export interface CascadeRefreshProviderProps extends ChildrenProps {
  refresher: Callback;
}

export function CascadeRefreshProvider({
  children,
  refresher,
}: CascadeRefreshProviderProps): ReactElement {
  const context = useMemo<CascadeRefresh>(
    () => ({
      cascadeRefresh: refresher,
    }),
    [refresher]
  );

  return composeElements([<CascadeRefreshContext value={context} />, children]);
}
