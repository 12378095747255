import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import {
  Box,
  Collapse as MuiCollapse,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
} from "@material-ui/core";

/* eslint-disable import/no-extraneous-dependencies */
import { IdParams, useData } from "@sinch/core";
import { Id, Menu, selectMenu } from "@sinch/entity";
import { Callback } from "@sinch/types";
import { Card, externalLink, IconButton, resolveAction, routerLink } from "@sinch/ui";
import { isDefined, useToggleState } from "@sinch/utils";
import { find, includes, isEmpty, pluck, propSatisfies, values } from "ramda";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { requestPageView } from "../contract";

/*
 * todo: clean disabled eslint errors
 */

function getAction(item: Menu) {
  const { page, url }: Menu = item;
  if (isDefined(url)) {
    return externalLink(url);
  }
  if (isDefined(page)) {
    return routerLink(`/page/${page}/`);
  }
  return undefined;
}

interface CollapsibleListItemParams {
  item: Menu;

  path: Menu[];

  children: ReactElement;
}

function isSelected(item: Menu, pathname: string) {
  return pathname.endsWith(`/page/${item.page}`) || pathname.endsWith(`/page/${item.page}/`);
}

function CollapsibleListItem({ item, path, children }: CollapsibleListItemParams): ReactElement {
  const { pathname } = useLocation();
  const [open, toggleOpen, setOpen] = useToggleState(includes(item.id)(pluck("id", path || [])));
  const action = getAction(item);

  const selected = isSelected(item, pathname);

  return (
    <>
      <MuiListItem
        button
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...resolveAction(action)}
        onClick={() => toggleOpen()}
        selected={selected}
      >
        <MuiListItemText>{item.name}</MuiListItemText>
        <IconButton
          action={
            ((e: Event) => {
              toggleOpen();
              e.preventDefault();
              e.stopPropagation();
            }) as Callback
          }
          icon={open ? mdiChevronUp : mdiChevronDown}
        />
      </MuiListItem>
      <MuiCollapse in={open}>{children}</MuiCollapse>
    </>
  );
}

interface PageNestedMenuProps {
  id: Id;

  path: Menu[];
}

function PageNestedMenu({ id, path }: PageNestedMenuProps): ReactElement {
  const { pathname } = useLocation();
  const { selectEntity } = useData(requestPageView);
  const { menu = [] } = selectEntity(selectMenu(id));

  const list = selectEntity(selectMenu(menu));

  return (
    <MuiList>
      {list.map((item, i) => {
        /* eslint-disable-next-line @typescript-eslint/no-shadow */
        const { id, name, menu }: Menu = item;
        const action = getAction(item);
        const selected = isSelected(item, pathname);

        if (!isEmpty(menu)) {
          return (
            <CollapsibleListItem key={id} item={item} path={path}>
              <Box pl={4}>
                <PageNestedMenu id={item.id} path={path} />
              </Box>
            </CollapsibleListItem>
          );
        }
        return (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <MuiListItem key={id || i} button selected={selected} {...resolveAction(action)}>
            <MuiListItemText>{name}</MuiListItemText>
          </MuiListItem>
        );
      })}
    </MuiList>
  );
}

interface PageMenuParams extends IdParams<Menu> {}

export function PageMenu({ id }: PageMenuParams): ReactElement {
  const { selectEntity } = useData(requestPageView);

  /* eslint-disable-next-line @typescript-eslint/no-shadow */
  const path = selectEntity<Menu[]>(({ Menu }) => {
    const list = [Menu[id]];
    let currentId = id;
    let found: Menu | undefined;
    do {
      const propSatisfy = propSatisfies(includes(currentId), "menu");
      found = find<Menu>(propSatisfy, values(Menu));
      if (found) {
        currentId = found.id;
        list.unshift(found);
      }
    } while (isDefined(found));
    return list;
  });

  const rootId = path[0].id;

  return (
    <Card>
      <PageNestedMenu id={rootId} path={path} />
    </Card>
  );
}
