import { ButtonProps as MuiButtonProps, CircularProgress, Tooltip } from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import { fade, makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { Color, IconId } from "@sinch/types";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import { equals, mergeAll } from "ramda";
import React, { ReactElement } from "react";
import { Action, resolveAction } from "../actions";
import { Icon, IconProps } from "../Icon";
import { configurePaletteStyles, IdentifierColorKey, identifiersStylesCreator, styleKey } from "../paletteStyles";

const variants = ["text", "outlined", "contained"];

const isOutlined = equals(variants[1]);
const isContained = equals(variants[2]);

/**
 * todo: infer stylesheet names from variable+color using template strings
 */
const createPaletteStyles = configurePaletteStyles({ variants });
const paletteStyles = createPaletteStyles(({ main, dark, contrastText }, { variant, theme }) =>
  /* @ts-expect-error */
  mergeAll([
    {
      color: main,
    },
    /* @ts-expect-error */
    isContained(variant) && {
      color: contrastText,
      backgroundColor: main,
    },
    /* @ts-expect-error */
    isOutlined(variant) && {
      border: "1px solid",
      borderColor: fade(main, 0.5),
    },
    {
      "&:hover": mergeAll([
        {
          /* @ts-expect-error */
          backgroundColor: fade(main, theme.palette.action.hoverOpacity),
        },
        /* @ts-expect-error */
        isContained(variant) && {
          backgroundColor: dark,
          color: contrastText,
        },
        /* @ts-expect-error */
        isOutlined(variant) && {
          border: "1px solid",
          borderColor: main,
        },
        /*
        {
          // todo: reset on touch devices
          //  (needs to be handled for each variant separately)
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
         */
      ]),
    },
  ])
);

const identifierStyles = identifiersStylesCreator(({ color, backgroundColor }, theme) => ({
  "&.MuiButton-text": {
    color: backgroundColor,
  },
  "&.MuiButton-contained": {
    color,
    backgroundColor,
  },
  "&.MuiButton-outlined": {
    border: "1px solid",
    borderColor: fade(backgroundColor, 0.5),
  },
  "&:hover": {
    "&.MuiButton-text": {
      backgroundColor: fade(backgroundColor, theme.palette.action.hoverOpacity),
    },
    "&.MuiButton-contained": {
      backgroundColor: darken(backgroundColor, theme.palette.action.hoverOpacity), // dark,
      color,
    },
    "&.MuiButton-outlined": {
      border: "1px solid",
      borderColor: backgroundColor,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  disableUppercase: {
    textTransform: "none",
  },
  matchInput: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
    "& .MuiButton-label": {
      height: "1.1876em",
    },
  },
  button: {
    minWidth: "initial",
  },
  iconButton: {
    paddingLeft: "6px",
    paddingRight: "6px",
  },
  ...paletteStyles(theme),
  ...identifierStyles(theme),
}));

export interface ButtonProps extends ChildrenProps {
  action?: Action;

  color?: Color | IdentifierColorKey;

  disabled?: boolean;

  disableUppercase?: boolean;

  icon?: IconId;

  large?: boolean;

  stretch?: boolean;

  type?: "button" | "reset" | "submit";

  variant?: "text" | "outlined" | "contained";

  matchInput?: boolean;

  tooltip?: ReactNode;

  size?: MuiButtonProps["size"];

  className?: string;
  iconProps?: IconProps;
  loading?: boolean;
}

export function Button({
  action,
  children,
  color,
  disabled,
  disableUppercase,
  icon,
  large,
  stretch,
  type = "button",
  variant = "contained",
  matchInput,
  tooltip,
  size,
  className,
  iconProps,
  loading,
  ...props
}: ButtonProps): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(action);
  const isJustIcon = !children && icon;
  const button = (
    <MuiButton
      className={clsx(
        className,
        /* @ts-expect-error */
        color && (styles[styleKey(variant, color)] || styles[color]),
        disableUppercase && styles.disableUppercase,
        matchInput && styles.matchInput,
        isJustIcon && styles.iconButton,
        styles.button
      )}
      color="default"
      disabled={disabled}
      disableElevation
      fullWidth={!stretch}
      size={size || (large ? "large" : "medium")}
      startIcon={!isJustIcon && icon ? <Icon {...iconProps} icon={icon} /> : null}
      type={type}
      variant={variant}
      /* eslint-disable react/jsx-props-no-spreading */
      {...actionProps}
      {...props}
    >
      {loading ? (
        <CircularProgress style={{ height: 15, width: 15, color: "#3333", margin: "0 20px" }} />
      ) : isJustIcon && icon ? (
        <Icon {...iconProps} icon={icon} />
      ) : (
        children
      )}
    </MuiButton>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{button}</Tooltip>;
  }
  return button;
}
