import { useBusinessRules } from "@sinch/core";
import { t } from "@sinch/intl";
import { GridLayout, TwoColumnLayout, useMobileLayout, useTitleBar } from "@sinch/ui";
import React, { ReactElement } from "react";
import { NotFound } from "../../NotFound";
import { DesktopWalletPaymentList } from "./DesktopWalletPaymentList";
import { WalletContextProvider } from "./WalletContext";
import { WalletStatus } from "./WalletStatus";
import { WalletStatusContainer } from "./WalletStatusContainer";

export function WalletLayout(): ReactElement {
  const mobile = useMobileLayout();

  useTitleBar({
    container: "core:wallet",
    locationTitle: t("Wallet.title"),
  });
  const { hideFinances } = useBusinessRules();
  if (hideFinances) {
    return <NotFound />;
  }

  return (
    <WalletContextProvider>
      {mobile ? (
        <WalletStatusContainer>
          <WalletStatus />
        </WalletStatusContainer>
      ) : (
        <WalletStatusContainer>
          <TwoColumnLayout>
            <GridLayout spacing="outer">
              <WalletStatus />
            </GridLayout>
            <DesktopWalletPaymentList />
          </TwoColumnLayout>
        </WalletStatusContainer>
      )}
    </WalletContextProvider>
  );
}
