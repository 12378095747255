import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";

/**
 * Set requestScope of app, worker or admin
 */

const RequestScopeContext = createCheckedContext("RequestScope");
export const { use: useRequestScope } = RequestScopeContext;

export interface RequestScopeProviderProps extends ChildrenProps {
  scope?: "admin" | "worker";
}

export function RequestScopeProvider({ children, scope = "worker" }: RequestScopeProviderProps): ReactElement {
  return <RequestScopeContext value={scope}>{children}</RequestScopeContext>;
}
