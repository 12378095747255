import { Box, CardContent, CardHeader } from "@material-ui/core";
import MuiCard from "@material-ui/core/Card";
import MuiGrid from "@material-ui/core/Grid";
import { fade, makeStyles } from "@material-ui/core/styles";
import { mdiCar, mdiCheckboxBlankCircle } from "@mdi/js";

/* eslint-disable import/no-internal-modules,import/no-extraneous-dependencies */
import { useCurrentUser, useData } from "@sinch/core";
import { PositionAttendance, PositionQ, PositionStatus, selectPosition, selectPositionAttendance } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  CardUnderlay,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedHeader,
  CondensedTitle,
  Header,
  Icon,
  IconText,
  routerLink,
  Text,
} from "@sinch/ui";
import { CardActionArea } from "@sinch/ui/Card/Card";
import clsx from "clsx";
import { differenceInMinutes } from "date-fns";
import { head, includes, values } from "ramda";
import React, { ReactElement } from "react";
import { PositionApplicant, PositionContact, PositionPlace, PositionRole, PositionTime } from "../../../components";
import { AttendanceConfirmButtons } from "../../../components/AttendanceConfirmButtons";
import { PositionReference } from "../../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "../api";

const useStyles = makeStyles((theme) => ({
  success: {
    background: fade(theme.palette.success.light, 0.1),
  },
  info: {
    background: fade(theme.palette.info.light, 0.1),
  },
  warning: {
    background: fade(theme.palette.warning.light, 0.1),
  },
  headerAction: {
    margin: 0,
  },
  cardAction: {
    justifyContent: "flex-end",
  },
  card: {
    zIndex: 0,
  },
  smallGutters: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

function getTime(status: PositionStatus, startTime: Date) {
  const { dur } = useFormat();

  if (status === PositionStatus.Running) {
    return t("rightNow");
  }
  if (status === PositionStatus.Open) {
    dur.narrowShort(differenceInMinutes(startTime, new Date()) * 60);
  }
  return null;
}

/**
 * Mobile position in progress element, needs DashboardAttendanceList context
 * @context DashboardAttendanceList
 */
export function PositionConfirmation({
  positionId,
  size,
  isApplicantConfirmation,
}: {
  positionId: number;
  size: number;
  isApplicantConfirmation: boolean;
}): ReactElement {
  const { id: workerId } = useCurrentUser();
  const styles = useStyles();
  const { selectEntity, selectResult, queryEntity } = useData(requestDashboardAttendanceList);
  const { applicantPositionIds, confirmationApplicantPositionIds } = selectResult();
  const { startTime, status } = selectEntity(selectPosition(positionId));

  const groupedPositionIds =
    head(
      selectEntity<PositionAttendance["groupedPositionIds"]>(
        selectPositionAttendance({ position: positionId, worker: workerId }, "groupedPositionIds")
      ) ?? []
    ) ?? [];

  const isConfirmationRequiredInGroupedPosition =
    values(confirmationApplicantPositionIds).some((ids) => ids.includes(positionId) && ids.length > 1) ||
    (includes(positionId, groupedPositionIds) && groupedPositionIds.length > 1);

  const card = (
    <MuiCard className={styles.card} square={false}>
      <CardActionArea action={routerLink(`/position/${positionId}`)}>
        <CardHeader
          action={getTime(status, startTime)}
          classes={{ action: styles.headerAction }}
          className={clsx(styles.smallGutters, styles.warning)}
          disableTypography
          title={
            <MuiGrid container spacing={1} wrap="nowrap">
              <MuiGrid item>
                <Icon color="warning" icon={mdiCheckboxBlankCircle} size="small" />
              </MuiGrid>
              <MuiGrid item>
                <Header level={2}>{t("Dashboard.shiftsToConfirmTitle")}</Header>
              </MuiGrid>
            </MuiGrid>
          }
        />
        <CardContent className={styles.smallGutters}>
          <MuiGrid container spacing={1} wrap="nowrap">
            <MuiGrid item xs="auto">
              {includes(positionId, applicantPositionIds) ? <PositionApplicant /> : <PositionRole id={positionId} />}
            </MuiGrid>
            <MuiGrid item xs>
              <CondensedCard wrap>
                <CondensedHeader>
                  <CondensedTitle wrap>
                    <Text bold>
                      <PositionReference id={positionId} name noId title />
                    </Text>
                  </CondensedTitle>
                </CondensedHeader>
                <CondensedBody>
                  <CondensedContent>
                    {isApplicantConfirmation && <Box>{t("Position.youHaveBeenSelectedAsApplicant")}</Box>}
                    <Box whiteSpace="nowrap">
                      <PositionTime id={positionId} withMeetup />
                    </Box>
                    <PositionPlace id={positionId} />
                    <PositionContact id={positionId} />
                    {queryEntity(PositionQ.hasTransport(positionId)) && (
                      <IconText icon={mdiCar}>{t("display.transport")}</IconText>
                    )}
                    <Box pt={1}>
                      {isConfirmationRequiredInGroupedPosition ? (
                        <Text>{t("Position.groupedPositionConfirmation")}</Text>
                      ) : (
                        <AttendanceConfirmButtons
                          confirmationType={isApplicantConfirmation ? "applicant" : "position"}
                          positionId={positionId}
                        />
                      )}
                    </Box>
                  </CondensedContent>
                </CondensedBody>
              </CondensedCard>
            </MuiGrid>
          </MuiGrid>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );

  if (size > 1) {
    return (
      <CardUnderlay
        card={card}
        header={
          <Text bold small>
            {t("Position.display.otherShiftsToConfirm", {
              count: size - 1,
            })}
          </Text>
        }
      />
    );
  }
  return card;
}
