import { Box } from "@material-ui/core";
import { mdiAlertCircleOutline } from "@mdi/js";

import { DataProvider, PagingParams, ScrollHistory, useCurrentUser, useData, useSearchParamsCustom } from "@sinch/core";
import { useCacheState } from "@sinch/core/backend/provider/CachedResponseProvider";
import { useProgress } from "@sinch/core/backend/provider/ProgressProvider";
import { t } from "@sinch/intl";
import {
  Center,
  Grid,
  Loading,
  LoadingOverlay,
  Strip,
  Text,
  useFullWidthContent,
  useMobileLayout,
  useTitleBar,
} from "@sinch/ui";
import { LoadingLinearTop } from "@sinch/ui/Loading/Loading";
import { isEmpty } from "ramda";
import React, { PropsWithChildren, ReactElement, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PositionSearchParams, PositionSearchUrlParams } from "../Search";
import { requestPositionList } from "./api";
import { ListMobile } from "./Mobile/ListMobile";
import { PositionListDesktop } from "./PositionListDesktop";
import { PositionListEmptyMessage } from "./PositionListEmptyMessage";
import { PositionListSearchBar } from "./PositionListSearchBar";

function PositionList(): ReactElement {
  useFullWidthContent();
  const mobile = useMobileLayout();
  const { inProgress } = useProgress();
  const isCached = useCacheState();

  const { selectResult } = useData(requestPositionList);
  const { positionIds, featuredPositionIds } = selectResult();

  return (
    <>
      <PositionListSearchBar />
      {/* eslint-disable-next-line no-nested-ternary */}
      {isEmpty(positionIds) && isEmpty(featuredPositionIds) ? <PositionListEmptyMessage /> : mobile ? <ListMobile /> : <PositionListDesktop />}
      {!isCached && inProgress && <LoadingOverlay />}
      {isCached && inProgress && <LoadingLinearTop />}
    </>
  );
}

export function PositionListContainer({
  children,
  defaultParams = {},
  useOverlayLoading = true,
}: PropsWithChildren<{ defaultParams?: Record<string, any>; useOverlayLoading?: boolean }>): ReactElement {
  const navigate = useNavigate();
  const {
    restrictions: { positionAccess },
  } = useCurrentUser();

  // @ts-ignore
  const { searchParams } = useSearchParamsCustom<PositionSearchUrlParams & PagingParams>(
    Object.entries({ limit: "100", ...defaultParams, page: "1" })
  );

  const request = useMemo(
    () => requestPositionList(PositionSearchParams.toRequest(searchParams), PagingParams.pick(searchParams)),
    [searchParams]
  );

  return (
    <>
      {positionAccess === true ? (
        <DataProvider cache fallback={useOverlayLoading ? <LoadingOverlay /> : <Loading />} request={request}>
          {children}
        </DataProvider>
      ) : (
        <Center horizontal>
          <Box>
            <Strip
              color="warning"
              icon={mdiAlertCircleOutline}
              primaryAction={{
                action: () => navigate("/dashboard"),
                label: t("goToDashboard"),
              }}
            >
              <Grid spacing="data" vertical>
                <Text>{positionAccess}</Text>
              </Grid>
            </Strip>
          </Box>
        </Center>
      )}
    </>
  );
}

export function Positions() {
  useTitleBar({
    container: "core:position-list",
    locationTitle: t("Position.display.availablePositions"),
  });

  return (
    <PositionListContainer defaultParams={{ attend: true }}>
      <ScrollHistory>
        <PositionList />
      </ScrollHistory>
    </PositionListContainer>
  );
}
