import { Callback } from "@sinch/types";
import { Button, Grid, Text } from "@sinch/ui";
import { useFormikContext } from "formik";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFormContext } from "./FormContext";
import { formStatusDisplayProps } from "./FormStatus";

interface SubmitButtonProps {
  disabled?: boolean;

  label: string;

  onClick?: Callback;

  ignoreInvalidState?: boolean;

  onInvalid?: () => void;
}

export function SubmitButton({
  disabled,
  label: buttonLabel,
  ignoreInvalidState = false,
  onInvalid,
  onClick,
  ...props
}: SubmitButtonProps): ReactElement {
  const [submitted, setSubmitted] = useState(false);
  const {
    messages,
    status: { ready, ...state },
    submit,
  } = useFormContext();
  const { isSubmitting } = useFormikContext();

  const action = useCallback(() => {
    onClick?.();
    submit();
    setSubmitted(true);
  }, [onClick, submit]);

  const { color, icon, label: stateLabel } = formStatusDisplayProps(
    ignoreInvalidState
      ? { ...state, invalid: false, submitting: isSubmitting || state.submitting }
      : { ...state, submitting: isSubmitting || state.submitting }
  );

  const isInvalid = state.invalid;
  useEffect(() => {
    if (submitted && isInvalid && onInvalid) {
      onInvalid();
      setSubmitted(false);
    }
  }, [isInvalid, submitted, onInvalid]);

  return (
    <Grid spacing="inner" vertical>
      {messages.map((message) => (
        <Text key={message} color="error">
          {message}
        </Text>
      ))}
      <Button
        action={action}
        color={color}
        disabled={disabled || (!ready && !ignoreInvalidState)}
        icon={icon}
        type="submit"
        {...props}
      >
        {(ignoreInvalidState || ready) && !isSubmitting ? buttonLabel : stateLabel}
      </Button>
    </Grid>
  );
}
