import {
  AutocompleteInputChangeReason,
  AutocompleteMultipleInputBase,
  ManagedInputProps,
  useFormField,
} from "@sinch/forms";
import { t } from "@sinch/intl";
import { createMapClient, MapClientConfig, MapItem, MapSearchParams } from "@sinch/mapycz";
import { Nullable } from "@sinch/types";
import { debounce } from "debounce";
import { equals, includes } from "ramda";
import { ensureArray, lengthGt } from "ramda-adjunct";
import React, { ReactElement, useMemo, useState } from "react";

export type MapPlace = Pick<MapItem, "id" | "label" | "point">;

const isInputType = equals<AutocompleteInputChangeReason>("input");

const isValidSearch = lengthGt(1);

export interface MapMultiplePlaceInputProps<TMap> extends ManagedInputProps {
  mapClientOptions: MapClientConfig & MapSearchParams;
  extractPlaces: (placeArray: MapItem[]) => TMap[];
  getOptionLabel: (place: TMap) => string;
  renderOption: (places: TMap | TMap[]) => string;
}

export function MapMultiplePlaceInput<TMap>(props: MapMultiplePlaceInputProps<TMap>): ReactElement {
  const [{ mapClientOptions, extractPlaces, getOptionLabel, renderOption, ...baseProps }, { setValue }] = useFormField<
    Nullable<TMap | TMap[]>,
    MapMultiplePlaceInputProps<TMap>
  >(props);

  const mapClient = useMemo(() => createMapClient(mapClientOptions), [mapClientOptions]);

  const [options, setOptions] = useState<TMap[]>([]);

  /**
   * trigger new search when input field is changed when:
   * - input string is longer than 3 characters
   * - input was changed by the user (not select/reset/clear event)
   * - debounce interval passed from last search
   */
  const handleInputChange = useMemo(
    () =>
      debounce(
        async (input: string, reason: AutocompleteInputChangeReason) =>
          isInputType(reason) && isValidSearch(input) && setOptions(extractPlaces(await mapClient.search(input))),
        300
      ),
    [mapClient]
  );

  return (
    <AutocompleteMultipleInputBase
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(a, b) => includes(b, ensureArray(a))}
      label={`${t("Profile.form.preferredPlaces")} ${baseProps.required ? "*" : ""}`}
      multiple
      noOptionsText={t("Profile.form.writeToFindPlace")}
      onChange={setValue}
      onInputChange={handleInputChange}
      options={options}
      renderOption={renderOption}
    />
  );
}
