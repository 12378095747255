import { requestHibernate, useCurrentUser } from "@sinch/core";
import { DateInput, Form, SubmitButton, SwitchInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { RowsGrid, useSpacing } from "@sinch/ui";
import { isNil } from "ramda";
import React, { ReactElement, useState } from "react";

interface HibernateFormProps {
  hibernation: boolean;

  expiration?: string;
}

export function HibernateForm(): ReactElement {
  const {
    restrictions: { hibernated },
  } = useCurrentUser();
  const isHibernated = !isNil(hibernated) && hibernated !== false;
  const [, inner] = useSpacing();
  const [hibernateDateAllow, setHibernateDateAllow] = useState(false);

  const initialValues: HibernateFormProps = {
    hibernation: !isHibernated,
    expiration: undefined,
  };

  return (
    <Form<HibernateFormProps>
      initialValues={initialValues}
      submitRequest={({ hibernation, expiration }) => requestHibernate(hibernation, expiration)}
    >
      <RowsGrid spacing={inner}>
        {t("Profile.hibernationDescription")}
        {t("Profile.hibernationEndTimeDescription")}
        <RowsGrid>
          <SwitchInputBase
            label={t("Profile.setHibernationEndDate")}
            name="hibernateEndDateAllow"
            // @ts-ignore
            onChange={(_: unknown, state: boolean) => setHibernateDateAllow(state)}
            value={hibernateDateAllow}
          />
          {hibernateDateAllow && <DateInput name="expiration" />}
        </RowsGrid>
        <SubmitButton label={t("Profile.hibernate")} />
      </RowsGrid>
    </Form>
  );
}
