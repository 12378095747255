import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Feedback, PositionQ, selectPosition, selectPositionAttendance, selectWorker } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Button, Card, Center, DataList, Divider, GridLayout, routerLink, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { feedbackColor, feedbackIcon } from "../../Shift";
import { requestPositionDetail } from "./api";

export function PositionDetailFeedback(): Nullable<ReactElement> {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId } = selectResult();

  const canFeedback = queryEntity(PositionQ.User.canFeedback(positionId));

  if (!canFeedback) {
    return null;
  }

  const feedback = queryEntity(PositionQ.Shift.getFeedback(positionId));
  const shift = selectEntity(selectPosition(positionId, "shift"));
  const positionsAttendance = selectEntity(selectPositionAttendance({}));
  const isAllFeedbacksSend = positionsAttendance.length <= feedback.length;

  const colorSelector = ({ type }: Feedback) => feedbackColor(type);
  const contentSelector = ({ to }: Feedback) => (to ? selectEntity(selectWorker(to, "name")) : t("Shift.entireShift"));
  const iconSelector = ({ type }: Feedback) => feedbackIcon(type);

  return (
    <GridLayout.Item>
      <Card>
        <Text bold>{t("Feedback.titlePlural")}</Text>
        {feedback.length ? (
          <DataList
            colorSelector={colorSelector}
            contentSelector={contentSelector}
            data={feedback}
            disablePadding
            iconSelector={iconSelector}
          />
        ) : (
          <Box textAlign="center">
            <br />
            <Center>
              <Text color="secondary">{t("Feedback.youNotSendYet")}</Text>
            </Center>
            <Center>
              <Text color="secondary">{t("Feedback.feedbackSuggestionNotice")}</Text>
            </Center>
            <br />
          </Box>
        )}
        {!isAllFeedbacksSend && (
          <>
            <Divider />
            <Button action={routerLink(`#/shift/${shift}/feedback/`)} stretch variant="text">
              {!feedback.length ? t("Feedback.action.submitAnother") : t("Feedback.action.submit")}
            </Button>
          </>
        )}
      </Card>
    </GridLayout.Item>
  );
}
