import React, { ReactElement } from "react";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputTextProps } from "./InternalInput";

interface PasswordInputProps
  extends Omit<InternalInputTextProps, "multiline" | "type"> {}

export function PasswordInput(props: PasswordInputProps): ReactElement {
  const [baseProps] = useFormField<string, PasswordInputProps>(props);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <PasswordInputBase {...baseProps} />;
}

export function PasswordInputBase(
  props: BaseInputProps<string, PasswordInputProps>
): ReactElement {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <InternalInput type="password" {...props} />;
}
