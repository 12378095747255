import { ErrorBoundary } from "@sinch/ui";
import { composeElements } from "@sinch/utils";
import { concat, startsWith, unless } from "ramda";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { BrowserRouter, Outlet, useRoutes } from "react-router-dom";

export interface RouteConfig {
  children?: RouteConfig[];

  element: ReactNode;

  path: string;
}

interface RouterProps {
  basePath: string;

  layout?: ReactElement[];

  routes: RouteConfig[];
}

const prefix = unless(startsWith("/"), concat("/"));

export function Router({ basePath: basePathProp, layout, routes }: RouterProps): ReactElement {
  const basePath = useMemo(() => prefix(basePathProp), [basePathProp]);

  return composeElements([<BrowserRouter basename={basePath} />, <Routes layout={layout} routes={routes} />]);
}

function Routes({ layout = [], routes }: Omit<RouterProps, "basePath">): ReactElement {
  const rootElement = useMemo(() => composeElements([...layout, <ErrorBoundary />, <Outlet />]), [layout]);

  const rootRoute = useMemo(
    () => [
      {
        children: routes,
        element: rootElement,
      },
    ],
    [rootElement, routes]
  );

  const content = useRoutes(rootRoute);

  return <ErrorBoundary resetDependencies={[content]}>{content}</ErrorBoundary>;
}
