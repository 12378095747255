import { mdiMenu } from "@mdi/js";
import { Callback, IconId } from "@sinch/types";
import React, { ReactElement } from "react";
import { Icon } from "../../Icon";
import { ToolbarButton } from "./ToolbarButton";

interface MenuButtonProps {
  onMenuClick: Callback;
  icon?: IconId;
}

export function MenuButton({ onMenuClick, icon }: MenuButtonProps): ReactElement {
  return (
    /* @ts-expect-error */
    <ToolbarButton color="inherit" onClick={onMenuClick}>
      <Icon icon={icon || mdiMenu} />
    </ToolbarButton>
  );
}
