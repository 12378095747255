import { DataProvider, requestUserPreferenceUpdate, responseHandlerKey, useSession } from "@sinch/core";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";

export function UserPreferenceContainer({ children }: ChildrenProps): ReactElement {
  const { refresh } = useSession();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(requestUserPreferenceUpdate, () => {
        refresh();
      }),
    [refresh]
  );

  return <DataProvider handler={responseHandler}>{children}</DataProvider>;
}
