import { DataProvider, PagingParams, ScrollHistory, useSearchParamsCustom } from "@sinch/core";
import { LoadingOverlay } from "@sinch/ui";
import { dateToUrl } from "@sinch/utils";
import { isValid, parseISO } from "date-fns";
import React, { ReactElement, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { requestWalletPayoutList, WalletPayoutListParams } from "./api";
import { useWalletSearchInitialParams } from "./WalletSearchForm";

export type WalletPayoutListContainerParams = {
  children: ReactElement;
};

export function WalletPayoutListContainer({ children }: WalletPayoutListContainerParams): ReactElement {
  const { pathname } = useLocation();
  const { from, to, ...initial } = useWalletSearchInitialParams("payout");
  const { searchParams } = useSearchParamsCustom<WalletPayoutListParams & PagingParams>({
    ...initial,
    from: dateToUrl(from),
    to: dateToUrl(to),
    limit: 25,
  });

  if (searchParams.to && !isValid(parseISO(searchParams.to))) {
    searchParams.to = null;
  }

  if (searchParams.from && !isValid(parseISO(searchParams.from))) {
    searchParams.from = null;
  }

  const request = useMemo(() => requestWalletPayoutList(searchParams, PagingParams.pick(searchParams)), [
    searchParams,
    pathname,
  ]);

  return (
    <DataProvider progress={<LoadingOverlay />} request={request}>
      <ScrollHistory>{children}</ScrollHistory>
    </DataProvider>
  );
}
