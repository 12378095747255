import { Box } from "@material-ui/core";
import MuiAvatar from "@material-ui/core/Avatar";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { mdiAccount, mdiLogoutVariant } from "@mdi/js";

import { requestLogOut, useCurrentUser, useRequest } from "@sinch/core";
import { IconId } from "@sinch/types";
import { ChildrenProps } from "@sinch/utils";
import { juxt } from "ramda";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Action, externalLink, resolveAction, routerLink } from "../../actions";
import { AvatarChip } from "../../Chip";
import { Divider } from "../../Divider";
import { Icon } from "../../Icon";
import { ThemeSwitcher } from "../components/ThemeSwitcher";
import { useExclusiveToggle } from "../ExclusiveToggleContext";
import { PopperMenu, usePopperMenuContext } from "../PopperMenu";
import { ToolbarButton } from "./ToolbarButton";

interface UserMenuItemProps extends ChildrenProps {
  action: Action;

  icon: IconId;

  text: string;
}

export function UserMenuItem({ action, icon, text, ...props }: UserMenuItemProps): ReactElement {
  const { close } = usePopperMenuContext();
  const actionProps = resolveAction(action);

  const handleClick = actionProps.onClick || (() => {});

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <MuiMenuItem {...actionProps} onClick={juxt([handleClick, close])} {...props}>
      <MuiListItemIcon>
        <Icon icon={icon} />
      </MuiListItemIcon>
      <MuiListItemText>{text}</MuiListItemText>
    </MuiMenuItem>
  );
}

interface UserMenuProps {
  userBlocked: boolean;
}

/**
 * todo: popper menu should support style props so user menu
 *  doesnt have fixed width, also fixes margin problem
 *  (see popper menu style todo for more info)
 */
export function UserMenu({ userBlocked }: UserMenuProps): ReactElement | null {
  const currentUser = useCurrentUser();
  const dispatch = useRequest();
  const { t } = useTranslation();
  const toggleControl = useExclusiveToggle("UserMenu");

  if (!currentUser.id) return null;
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { avatar, possessed, name } = currentUser;

  return (
    <PopperMenu toggleControl={toggleControl}>
      <ToolbarButton data-cy="worker-account-btn">
        {possessed ? (
          <AvatarChip
            avatarUrl={avatar?.url}
            label={
              <Box textAlign="left">
                {t<string>("User.loggedAs")}
                <br />
                {name}
              </Box>
            }
          />
        ) : (
          <MuiAvatar src={avatar?.url} />
        )}
      </ToolbarButton>
      {!userBlocked && (
        <UserMenuItem
          action={routerLink("/profile")}
          data-cy="worker-profile-link"
          icon={mdiAccount}
          text={t("Profile.title")}
        />
      )}
      {possessed && (
        <UserMenuItem
          action={externalLink(`${window.location.origin}/users/logBack`, { target: "_self" })}
          icon={mdiLogoutVariant}
          text={t("User.backToOriginalUser")}
        />
      )}
      <UserMenuItem
        action={() => {
          dispatch(requestLogOut());
        }}
        icon={mdiLogoutVariant}
        text={t("logout")}
      />
      <Divider />
      <MuiMenuItem>
        <ThemeSwitcher />
      </MuiMenuItem>
    </PopperMenu>
  );
}
