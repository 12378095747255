import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, PushNotifications, selectPushNotifications } from "@sinch/entity";
import { useTranslation } from "@sinch/intl";
import { composeCellLogic, composeRowLogic, DataTableRow, dateTimeLogic, Header, MuiDataTable } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";
import { SearchParamsPagination } from "../../../components";
import { requestPushNotificationsList } from "../api";
import { PUSH_NOTIFICATION_LIST_DEFAULT_LIMIT } from "./PushNotificationsListContainer";

type PushNotificationsColumn = "title" | "content" | "created";

export function PushNotificationsList(): ReactElement {
  const t = useTranslation();
  const { selectEntity, selectResult } = useData(requestPushNotificationsList);
  const { newsIds } = selectResult();

  const PushNotificationsDataTable = MuiDataTable.withConfig<Fk<PushNotifications>, PushNotificationsColumn>({
    title: {
      title: t("title"),
      selector: (id) => selectEntity(selectPushNotifications(id, "title")),
    },
    content: {
      title: t("message"),
      selector: (id) => selectEntity(selectPushNotifications(id, "content")),
    },
    created: {
      title: t("date"),
      selector: (id) => selectEntity(selectPushNotifications(id, "created")),
      logic: composeCellLogic([dateTimeLogic("full")]),
    },
  }).withLogic(composeRowLogic([DataTableRow]));

  return (
    <>
      {isEmpty(newsIds) ? (
        <Box alignItems="center" display="flex" flexDirection="column" height="24em" justifyContent="center">
          <Header color="neutral">{t("PushNotifications.emptyListNotice")}</Header>
        </Box>
      ) : (
        <PushNotificationsDataTable columns={["title", "content", "created"]} data={newsIds}>
          <SearchParamsPagination defaultLimit={PUSH_NOTIFICATION_LIST_DEFAULT_LIMIT} />
        </PushNotificationsDataTable>
      )}
    </>
  );
}
