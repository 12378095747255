import { Func, Predicate, Updater } from "@sinch/types";
import { both, filter, pipe, prop, propEq, propSatisfies } from "ramda";
import { isNilOrEmpty, isNotNilOrEmpty } from "ramda-adjunct";

export const ServerLvlToType = {
  INFO: "info",
  WARN: "warning",
  ERROR: "error",
  SUCCESS: "success",
  DEBUG: "default",
};

export type ServerMessageLevel = "INFO" | "WARN" | "ERROR" | "DEBUG";

export interface ServerMessage {
  level: ServerMessageLevel;

  source?: string;

  text: string;
}

type MessagePred = Predicate<ServerMessage>;
type MessageFilter = Updater<ServerMessage[]>;
type MessageSelector = (obj: { messages: ServerMessage[] }) => ServerMessage[];

const isInfo: MessagePred = propEq("level", "INFO");
const isError: MessagePred = propEq("level", "ERROR");
const isWarn: MessagePred = propEq("level", "WARN");

const isGlobal: MessagePred = propSatisfies(isNilOrEmpty, "source");
const isSource: MessagePred = propSatisfies(isNotNilOrEmpty, "source");

const select: Func<MessageFilter, MessageSelector> = (f) => pipe(prop("messages"), f);

export const selectGlobalInfoMessages: MessageSelector = select(filter(both(isInfo, isGlobal)));

export const selectSourceInfoMessages: MessageSelector = select(filter(both(isInfo, isSource)));

export const selectGlobalErrorMessages: MessageSelector = select(filter(both(isError, isGlobal)));

export const selectSourceErrorMessages: MessageSelector = select(filter(both(isError, isSource)));

export const selectGlobalWarnMessages: MessageSelector = select(filter(both(isWarn, isGlobal)));

export const selectSourceWarnMessages: MessageSelector = select(filter(both(isWarn, isSource)));
