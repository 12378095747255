import { Fk, News, Notification, selectNews, selectNotification } from "@sinch/entity";
import { Callback, Consumer } from "@sinch/types";
import { useSnackbar } from "@sinch/ui";
import { ChildrenProps, composeElements, createCheckedContext } from "@sinch/utils";
import { evolve, map } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { DataProvider, responseHandlerKey, responseHandlerSequence, useData, useRefresh, useRequest } from "../backend";
import { Indicator, requestNotificationRead, requestNotificationReadAll, requestStatus } from "../contract";

interface Status {
  newsIds: News[];

  indicators: Indicator[];

  notificationIds: Notification[];

  unreadNotifications: number;
}

const StatusContext = createCheckedContext<Status>("Status");
export const { use: useStatus } = StatusContext;

/**
 * Get notification and announcements data and create list for context
 */
function StatusResolver({ children }: ChildrenProps): ReactElement {
  const { selectEntity, selectResult } = useData(requestStatus);
  const result = selectResult();

  const context = useMemo(() => {
    const resolve = evolve({
      newsIds: map((id: Fk<News>) => selectEntity(selectNews(id))),
      notificationIds: map((id: Fk<Notification>) => selectEntity(selectNotification(id))),
    });

    return resolve(result);
  }, [result, selectEntity]);

  return <StatusContext value={context}>{children}</StatusContext>;
}

interface StatusUpdate {
  readNotification: Consumer<Fk<Notification>>;

  refresh: Callback;
}

const StatusUpdateContext = createCheckedContext<StatusUpdate>("StatusUpdate");
export const { use: useStatusUpdate } = StatusUpdateContext;

interface StatusUpdateProviderProps extends ChildrenProps {
  refresh: Callback;
}

/**
 * Provider for updating status (eg. when user accept announcement)
 */
function StatusUpdateProvider({ children, refresh }: StatusUpdateProviderProps): ReactElement {
  const dispatch = useRequest();

  const context = useMemo<StatusUpdate>(
    () => ({
      readNotification: (id) => dispatch(requestNotificationRead(id)),
      refresh,
    }),
    [dispatch, refresh]
  );

  return composeElements([<StatusUpdateContext value={context} />, children]);
}

/**
 * Provide context for handling application status as notifications or badges in menu.
 */
export function StatusProvider({ children }: ChildrenProps): ReactElement {
  const { refresh, refreshToken } = useRefresh({ interval: 10 * 60 * 1000 });
  const request = useMemo(() => requestStatus(), []);
  const snackbar = useSnackbar();

  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(requestNotificationRead, () => {
          refresh();
        }),
        responseHandlerKey(requestNotificationReadAll, () => {}),
      ]),
    [refresh]
  );

  return composeElements([
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request} />,
    <StatusUpdateProvider refresh={refresh} />,
    <StatusResolver />,
    children,
  ]);
}
