import { Callback } from "@sinch/types";
import { useToggleState } from "@sinch/utils";

export interface DialogControl {
  (): void;

  close: Callback;

  open: Callback;

  state: boolean;

  toggle: Callback;
}

export function useDialog(initOpen?: boolean): DialogControl {
  const [open, toggleOpen, setOpen] = useToggleState(initOpen ?? false);

  const control = () => toggleOpen();
  control.toggle = () => toggleOpen();
  control.close = () => setOpen(false);
  control.open = () => setOpen(true);
  control.state = open;

  return control;
}
