import { Accordion, Container } from "@material-ui/core";
import { mdiAccountMultipleOutline, mdiBagPersonal } from "@mdi/js";

// @ts-nocheck todo: enable type checking and fix errors
import { useCurrentUser, useData } from "@sinch/core";
import { t } from "@sinch/intl";
import { TabsNavigation, Text, Toolbar, useMobileLayout } from "@sinch/ui";
import { isNil } from "ramda";
import React, { ReactElement } from "react";
import { useRoutes } from "react-router-dom";
import { requestProfileStatus } from "./api";
import { PersonalContainer } from "./PersonalAttributes";
import { ProfileStatusContainer } from "./Profile";
import { ProfileAttributeListContainer } from "./ProfileAttributes";
import { PersonalUpdateContainer } from "./ProfileEdit/PersonalUpdateContainer";
import { ProfileAttributeUpdateContainer } from "./ProfileEdit/ProfileAttributeUpdateContainer";

export function ProfileEditTabs(): ReactElement {
  const mobile = useMobileLayout();
  const { selectResult } = useData(requestProfileStatus);
  const { availableProfileAttributesCount } = selectResult();

  const {
    restrictions: { incompleteAttributesCounts },
  } = useCurrentUser();

  // "open" prop away from container
  const tabsNavigation = (
    <TabsNavigation
      replace
      tabs={[
        {
          icon: mobile ? undefined : mdiAccountMultipleOutline,
          label: t("Profile.display.personalAttributes"),
          path: "personal",
          badge:
            isNil(incompleteAttributesCounts) || incompleteAttributesCounts.personal === 0
              ? undefined
              : {
                  // @ts-ignore
                  color: "error",
                  badgeContent: <Text bold>!</Text>,
                },
        },
        {
          icon: mobile ? undefined : mdiBagPersonal,
          label: t("Profile.display.profileAttributes"),
          path: "attributes",
          badge:
            isNil(incompleteAttributesCounts) || incompleteAttributesCounts.profile === 0
              ? undefined
              : {
                  // @ts-ignore
                  color: "error",
                  badgeContent: <Text bold>!</Text>,
                },
        },
      ]}
    />
  );

  return (
    <>
      {availableProfileAttributesCount > 0 &&
        (mobile ? <Toolbar position="top">{tabsNavigation}</Toolbar> : <Accordion square>{tabsNavigation}</Accordion>)}
      {useRoutes([
        {
          element: (
            <PersonalContainer>
              <PersonalUpdateContainer />
            </PersonalContainer>
          ),
          path: "personal",
        },
        {
          element: (
            <ProfileAttributeListContainer>
              <ProfileAttributeUpdateContainer />
            </ProfileAttributeListContainer>
          ),
          path: "attributes",
        },
      ])}
    </>
  );
}

export function ProfileEdit(): ReactElement {
  const mobile = useMobileLayout();
  return (
    <ProfileStatusContainer>
      <Container disableGutters={mobile} maxWidth="md">
        <ProfileEditTabs />
      </Container>
    </ProfileStatusContainer>
  );
}
