import { Box, Grid as MuiGrid, InputAdornment } from "@material-ui/core";
import { mdiClockOutline } from "@mdi/js";

/* eslint-disable react/jsx-props-no-spreading */
import { useBusinessRules, useData } from "@sinch/core";
import { OnsiteArrivalTime } from "@sinch/core/contract";
import {
  ClosingProgress,
  Fk,
  Position,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  Shift,
  WorkerRole,
} from "@sinch/entity";
import { SelectInputBase, SelectTimeInputBase } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Button, Card, Icon, Identifier, useSpacing } from "@sinch/ui";
import { sub } from "date-fns";
import { countBy, filter, head, length, pipe, prop, propEq, reverse, sortBy } from "ramda";
import React, { ReactElement, useState } from "react";
import { requestPresenceView } from "../../api";
import { useClosingStatusProps, useUpdatePresence } from "../../hooks";

export function BackupEdit({ id }: { id: Fk<Shift> }): ReactElement {
  const [, inner] = useSpacing();
  const { dt } = useFormat();
  const { onsiteArrivalTime } = useBusinessRules();
  const { selectEntity } = useData(requestPresenceView);
  const { getProps } = useClosingStatusProps();
  const position = selectEntity(selectPositionAttendance(id, "position"));
  const { shift, role: currentRole, startTime, meetingTimeInterval } = selectEntity(selectPosition(position));

  const [time, setTime] = useState(
    onsiteArrivalTime === OnsiteArrivalTime.MeetingTime ? sub(startTime, { minutes: meetingTimeInterval }) : new Date()
  );
  const { moveToPosition } = useUpdatePresence(id);

  const { name } = selectEntity(selectShift(shift));
  const positions = selectEntity(selectPosition({ shift }));
  const isNotBackup = currentRole !== WorkerRole.Backup;
  const positionOptions = positions
    .filter(
      ({ role, id: filteredPositionId }) =>
        position !== filteredPositionId && role !== WorkerRole.Crewboss && currentRole !== role
    )
    .map(({ id: positionId, title, startTime, endTime }) => ({
      label: (
        <>
          <Identifier entity="position" id={positionId} />
          {` ${dt.time(startTime)} - ${dt.time(endTime)} ${title || name} `}
        </>
      ),
      value: positionId,
    }));

  // Get first worker position with highest count of absent workers
  const sortByAbsents = sortBy(({ id: positionId }: Position): number => {
    const positionAttendances = selectEntity(selectPositionAttendance({ position: positionId }));
    const counts = countBy<PositionAttendance>(prop("progressStatus"))(positionAttendances);
    // @ts-ignore
    return prop(ClosingProgress.Absent)(counts);
  });
  const workerPositionId = pipe<Position[], Position[], Position[], Position[], Position, Position["id"]>(
    sortByAbsents,
    reverse,
    filter(propEq("role", isNotBackup ? WorkerRole.Backup : WorkerRole.Worker)),
    head,
    prop("id")
  )(positions);

  const [newPosition, setNewPosition] = useState(workerPositionId);

  const handleMove = () => {
    moveToPosition(newPosition, time);
  };

  return (
    <Card title={isNotBackup ? t("Shift.closing.moveToBackup") : t("Shift.closing.moveBackupToPosition")}>
      <MuiGrid container direction="column" spacing={inner}>
        {length(positionOptions) > 1 && (
          <MuiGrid item xs>
            <Box display="grid">
              <SelectInputBase
                dense={false}
                label={t("Position.title")}
                onChange={(event) => setNewPosition(event.target.value)}
                options={positionOptions}
                value={newPosition}
              />
            </Box>
          </MuiGrid>
        )}
        <MuiGrid item>
          <SelectTimeInputBase
            adornment={
              <InputAdornment position="end">
                <Icon icon={mdiClockOutline} />
              </InputAdornment>
            }
            dense={false}
            fullWidth
            label={t("Shift.closing.arrival")}
            onChange={setTime}
            required
            textAlign="center"
            value={time}
          />
        </MuiGrid>
        <MuiGrid item>
          <Button action={handleMove} {...getProps(ClosingProgress.Present, ["icon", "color"])} variant="contained">
            {isNotBackup ? t("Shift.closing.markAsBackup") : t("Shift.closing.markAsWorking")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Card>
  );
}
