import { Nullable } from "@sinch/types";
import { useSnackbar } from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement, useMemo, useState } from "react";
import { DataProvider, RequestResponse, responseHandler } from "../backend";
import { ErrorDialog } from "./ErrorDialog";

function openLoginPage() {
  const { location } = window;
  const rootUrl = `${location.protocol}//${location.hostname}?loginRedirect=${location.pathname}${location.search}${location.hash}`;
  location.assign(rootUrl);
}

/**
 * Main error handler for application, handle requests and redirect user to login page if user is not logged in
 */
export function RootErrorHandler({ children }: ChildrenProps): ReactElement {
  const snackbar = useSnackbar();
  const [errorData, setErrorData] = useState<Nullable<RequestResponse>>();

  const handler = useMemo(
    () =>
      responseHandler("error dialog", null, (reqres: RequestResponse) => {
        if (reqres.response.status === 200) {
          snackbar("warning", `Unhandled response for request ${reqres.request.key}`);
          return;
        }

        if (reqres.response.status === 401) {
          snackbar("warning", "User logged out");
          openLoginPage();
          return;
        }

        setErrorData(reqres);
      }),
    [snackbar]
  );

  return (
    <DataProvider handler={handler}>
      {!errorData && children}
      {errorData && <ErrorDialog onReset={() => setErrorData(null)} reqres={errorData} />}
    </DataProvider>
  );
}
