import MuiRadio from "@material-ui/core/Radio";
import React, { ReactElement } from "react";

export interface RadioProps {
  checked?: boolean;

  value?: unknown;
}

export function Radio({ checked, value }: RadioProps): ReactElement {
  return <MuiRadio checked={checked} value={value} />;
}
