import { Box } from "@material-ui/core";
import {
  DataProvider,
  responseHandlerKey,
  responseHandlerSequence,
  ScrollHistory,
  ServerLvlToType,
  ServerMessage,
  useCascadeRefresh,
  useData,
  useIntParams,
  useRefresh,
} from "@sinch/core";
import { ErrorHandledRoutes } from "@sinch/core/router";
import { ClosingProgress, Fk, Position, selectPositionAttendance, selectWorker } from "@sinch/entity";
import { t } from "@sinch/intl";
import { useMobileLayout, useSnackbar } from "@sinch/ui";
import { useRedirect } from "@sinch/utils";
import { useSnackbar as useNotistack, VariantType } from "notistack";
import { has, isEmpty, keys, map } from "ramda";
import { ensureArray } from "ramda-adjunct";
import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Route } from "react-router-dom";
import {
  requestPresenceView,
  requestShiftNoteEdit,
  requestShiftPresenceAbsent,
  requestShiftPresenceArrived,
  requestShiftPresenceBreak,
  requestShiftPresenceBreakEnd,
  requestShiftPresenceFinished,
  requestShiftPresenceMoveToPosition,
  requestShiftPresenceNote,
  requestShiftPresencePending,
  requestShiftPresenceResetBreak,
  requestShiftPresenceTimes,
} from "./api";
import { PresenceRollback } from "./components";
import { useClosingStatusProps } from "./hooks";
import { PresenceDetailWorkerContainer } from "./PresenceDetailWorkerContainer";
import { PresenceListDesktop } from "./PresenceListDesktop";
import { PresenceListMobile } from "./PresenceListMobile";

export function PresenceContainer(): ReactElement {
  const { refresh, refreshToken } = useRefresh({ interval: 60000 });
  const { shift } = useIntParams();

  const request = useMemo(() => requestPresenceView(shift), [shift]);

  return (
    <DataProvider refresh={refreshToken} refresher={refresh} request={request}>
      <CheckAccess>
        <PresenceHandlerContainer />
      </CheckAccess>
    </DataProvider>
  );
}

function CheckAccess({ children }: PropsWithChildren<unknown>) {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const snackbar = useSnackbar();
  const { selectMeta, selectMessage } = useData(requestPresenceView);
  const { redirect } = selectMeta();
  const redirectTo = useRedirect();

  useEffect(() => {
    if (shouldRedirect && redirect) {
      selectMessage().forEach(({ level, text }) => {
        snackbar(ServerLvlToType[level] as VariantType, text);
      });
      redirectTo(redirect.type, redirect.id);
    }
  }, [redirect, redirectTo, shouldRedirect]);

  if (!shouldRedirect && redirect) {
    setShouldRedirect(true);
    return null;
  }
  return <>{children}</>;
}

function PresenceHandlerContainer(): ReactElement {
  const { cascadeRefresh } = useCascadeRefresh();
  const snackbar = useSnackbar();
  const { closeSnackbar } = useNotistack();
  const mobile = useMobileLayout();
  const { getLabel } = useClosingStatusProps();
  const { selectEntity } = useData(requestPresenceView);
  const redirectTo = useRedirect();

  const showUndoSnackbar = useCallback(
    (sentAttendances: number[], position?: Fk<Position>, progressStatus?: ClosingProgress) => {
      const positionAttendances = selectEntity(selectPositionAttendance(sentAttendances));
      const { id } = positionAttendances[0];

      const progressStatusLabel = getLabel(progressStatus);
      let name = `${t("Shift.closingWorkerCount")}: ${sentAttendances.length}`;
      if (sentAttendances.length === 1 && id) {
        const worker = selectEntity(selectPositionAttendance(id, "worker"));
        name = selectEntity(selectWorker(worker, "name"));
      }
      closeSnackbar();
      snackbar(
        "default",
        <Box display="flex" flexDirection="column">
          <span>
            {progressStatusLabel
              ? t("Shift.statusChangedTo", { status: progressStatusLabel })
              : t("Shift.presenceChanged")}
          </span>
          <span>{name}</span>
        </Box>,
        {
          action: (
            <PresenceRollback position={position} positionAttendances={positionAttendances} refresh={cascadeRefresh} />
          ),
          autoHideDuration: 5000,
        }
      );
    },
    [cascadeRefresh, getLabel, selectEntity, snackbar]
  );

  const redirectByResponse = (response) => {
    const { redirect } = response.meta || {};
    if (redirect) {
      (response.messages || []).forEach(({ level, text }) => {
        snackbar(ServerLvlToType[level] as VariantType, text);
      });
      redirectTo(redirect.type, redirect.id);
      return true;
    }
    return false;
  };

  const onSuccess = ({ response }) => {
    if (!redirectByResponse(response)) {
      cascadeRefresh();
      snackbar("success", t("Shift.presenceChanged") as string);
    }
  };

  const onSuccessWithUndo = (progressStatus: ClosingProgress) => ({ request: { params }, response }) => {
    if (redirectByResponse(response)) {
      return;
    }

    const { positionId = undefined } = params;
    let sentAttendances = [];
    if (has("attendanceId", params)) {
      const { attendanceId: requestAttendances } = params;
      sentAttendances = ensureArray(requestAttendances);
    } else {
      sentAttendances = keys(params);
    }

    cascadeRefresh();
    if (isEmpty(sentAttendances)) {
      return;
    }

    showUndoSnackbar(map(parseInt, sentAttendances), positionId, progressStatus);
  };
  const onError = ({ response }) => {
    if (!isEmpty(response?.messages)) {
      response.messages.forEach(({ level, text }: ServerMessage) => {
        snackbar(ServerLvlToType[level] as VariantType, text);
      });
    } else {
      snackbar("error", t("Shift.presenceFailed"));
    }
    cascadeRefresh();
  };

  const responseHandler = useMemo(
    () =>
      responseHandlerSequence<any>([
        responseHandlerKey(requestShiftPresenceNote, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceTimes, onSuccess, onError),
        responseHandlerKey(requestShiftNoteEdit, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceArrived, onSuccessWithUndo(ClosingProgress.Present), onError),
        responseHandlerKey(requestShiftPresencePending, onSuccessWithUndo(ClosingProgress.Pending), onError),
        responseHandlerKey(requestShiftPresenceBreak, onSuccessWithUndo(ClosingProgress.Break), onError),
        responseHandlerKey(requestShiftPresenceBreakEnd, onSuccessWithUndo(ClosingProgress.AfterBreak), onError),
        responseHandlerKey(requestShiftPresenceAbsent, onSuccessWithUndo(ClosingProgress.Absent), onError),
        responseHandlerKey(requestShiftPresenceFinished, onSuccessWithUndo(ClosingProgress.Finished), onError),
        responseHandlerKey(requestShiftPresenceResetBreak, onSuccessWithUndo(ClosingProgress.Present), onError),
        responseHandlerKey(requestShiftPresenceMoveToPosition, onSuccessWithUndo(ClosingProgress.Present), onError),
      ]),
    [cascadeRefresh, snackbar, showUndoSnackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <ErrorHandledRoutes>
        <Route element={<PresenceDetailWorkerContainer />} path="detail/:id" />
        <Route
          element={<ScrollHistory>{mobile ? <PresenceListMobile /> : <PresenceListDesktop />}</ScrollHistory>}
          path="*"
        />
      </ErrorHandledRoutes>
    </DataProvider>
  );
}
