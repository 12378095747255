import { mdiMagnify, mdiClose } from "@mdi/js";
import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { useData, useSearchParamsCustom } from "@sinch/core";
import { SelectInputBase } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Callback } from "@sinch/types";
import {
  Action,
  B,
  Button,
  ComplementaryDialog,
  FloatingActions,
  IconButton,
  Text,
  Toolbar,
  useDialog,
  useMobileLayout,
  useSnackbar,
  useSpacing,
} from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { omit, pick } from "ramda";
import { isNotEmpty, lengthGt } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo } from "react";
import { WorkerRoleOptions } from "../../Shift";
import { PositionSearch, PositionSearchParams, PositionSearchUrlParams } from "../Search";
import { requestPositionList } from "./api";

const useStyles = makeStyles(() => ({
  textNoWrap: {
    whiteSpace: "nowrap",
  },
}));

interface PositionListSearchBarButtonProps {
  action: Action;

  active: boolean;

  onReset: Callback;
}

function SearchParamsButton({ action, active, onReset }: PositionListSearchBarButtonProps): ReactElement {
  const { dt } = useFormat();
  const { selectMeta } = useData(requestPositionList);
  const { count } = selectMeta();
  const [, , data] = useSpacing();
  const styles = useStyles();

  const { distance, from, place, query, role, profession, to, ignoreConflict } = PositionSearchParams.toForm(
    useSearchParamsCustom<PositionSearchUrlParams>().searchParams
  );

  const queryBold = query ? <b>{query}</b> : "";
  const placeBold = place ? <B>{place.label}</B> : "";
  const countBold = <B>{count}</B>;
  const fromBold = from ? <b>{dt(from)}</b> : "";
  const toBold = to ? <b>{dt(to)}</b> : "";
  const rolesBold = <b>{role.map((r) => WorkerRoleOptions()[r].label).join(", ")}</b>;
  return (
    <MuiGrid alignItems="center" container spacing={data} wrap="nowrap">
      <MuiGrid item>
        <Button action={action} color="info" disableUppercase icon={mdiMagnify} large stretch variant="contained">
          <Text className={styles.textNoWrap}>{t("searchShifts")}</Text>
        </Button>
      </MuiGrid>
      <MuiGrid item>
        {active && (from || to || query || place || ignoreConflict || isNotEmpty(role) || isNotEmpty(profession)) && (
          <Text>
            <Text>
              {t("PositionSearch.foundPositions", {
                count: countBold,
              })}
              :
            </Text>
            <Text separator="; ">
              {rejectFalsy([
                place && (
                  <Text separator={" "}>
                    {t("PositionSearch.placeDistance", {
                      place: placeBold,
                      distance,
                    })}
                  </Text>
                ),
                from && (
                  <>
                    {t("dateFromWithDate", {
                      date: fromBold,
                    })}
                  </>
                ),
                to && (
                  <>
                    {t("dateToWithDate", {
                      date: toBold,
                    })}
                  </>
                ),
                lengthGt(0, role) && (
                  <>
                    {t("PositionSearch.roleList", {
                      count: role.length,
                      roles: rolesBold,
                    })}
                  </>
                ),
                lengthGt(0, profession) && (
                  <>
                    {t("PositionSearch.profession", {
                      count: profession.length,
                    })}
                  </>
                ),
                query && (
                  <>
                    {t("PositionSearch.keywords", {
                      query: queryBold,
                    })}
                  </>
                ),
                ignoreConflict && <Text>{t("PositionSearch.excludeConflictTitle")}</Text>,
              ])}
            </Text>
          </Text>
        )}
      </MuiGrid>
      {active && (
        <MuiGrid item xs="auto">
          <IconButton action={onReset} icon={mdiClose} size="small" />
        </MuiGrid>
      )}
    </MuiGrid>
  );
}

const isSearchActive = (params: PositionSearchUrlParams): boolean =>
  isNotEmpty(omit(["ignoreCapacity", "ignoreRating", "page", "limit"], params));

export function PositionListSearchBar(): ReactElement {
  const mobile = useMobileLayout();
  const snackbar = useSnackbar();

  const [ignoreCapacityOptions, ignoreRatingOptions] = useMemo(
    () => [
      [
        {
          value: false,
          label: t("filter.onlyFree"),
        },
        { value: true, label: t("filter.showAll") },
      ],
      [
        { value: false, label: t("filter.availableOnly") },
        { value: true, label: t("filter.showAll") },
      ],
    ],
    []
  );

  const dialog = useDialog();

  const { searchParams, updateSearchParams } = useSearchParamsCustom<PositionSearchUrlParams>();

  const active = isSearchActive(searchParams);

  const changeSearchParams = useCallback(
    ({ target: { name, value } }) => {
      snackbar("info", t("validation.filterApplied"));
      updateSearchParams({ [name]: value });
    },
    [snackbar, updateSearchParams]
  );

  const reset = useCallback(() => {
    snackbar("info", t("validation.filterReset"));
    updateSearchParams(pick(["ignoreCapacity", "ignoreRating"]));
  }, [snackbar, updateSearchParams]);

  const { ignoreCapacity = false, ignoreRating = false } = searchParams;

  return (
    <>
      <Toolbar behavior="scrollable" position="top">
        <Box mx={3} my={1} width="100%">
          <MuiGrid alignItems="center" container justify="center" spacing={1}>
            {!mobile && (
              <MuiGrid item xs>
                <SearchParamsButton action={dialog} active={active} onReset={reset} />
              </MuiGrid>
            )}
            <MuiGrid item md={2} xs={6}>
              <SelectInputBase
                dense
                label={t("Position.size")}
                name="ignoreCapacity"
                onChange={changeSearchParams}
                options={ignoreCapacityOptions}
                /* @ts-expect-error */
                value={ignoreCapacity}
              />
            </MuiGrid>
            <MuiGrid item md={2} xs={6}>
              <SelectInputBase
                dense
                label={t("Rating.title")}
                name="ignoreRating"
                onChange={changeSearchParams}
                options={ignoreRatingOptions}
                /* @ts-expect-error */
                value={ignoreRating}
              />
            </MuiGrid>
          </MuiGrid>
        </Box>
      </Toolbar>
      <ComplementaryDialog control={dialog}>
        <PositionSearch />
      </ComplementaryDialog>
      {mobile &&
        (active ? (
          <Toolbar position="bottom">
            <Button action={reset} color="neutral" large variant="text">
              <Box py={1}>{t("PositionSearch.action.clearSearch")}</Box>
            </Button>
            <Button action={dialog} color="primary" large variant="text">
              <Box py={1}>{t("PositionSearch.action.editSearch")}</Box>
            </Button>
          </Toolbar>
        ) : (
          <FloatingActions
            buttons={{
              action: dialog,
              color: "info",
              icon: mdiMagnify,
            }}
          />
        ))}
    </>
  );
}
