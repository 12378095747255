import { mdiPencil } from "@mdi/js";
import { makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";

import { WorkerRole } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Action, Box, Button, Center, Chip, Spacer, TimeRange, useMobileLayout, useSpacing } from "@sinch/ui";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { WorkerRoleOptions } from "../options";

const useStyles = makeStyles((theme) => ({
  textEllipsis: {
    textOverflow: "ellipsis",
    width: `calc(100% - ${theme.spacing(4)})`,
    overflow: "hidden",
  },
  closeChip: {
    maxWidth: "100%",
    "& .MuiChip-label": {
      // width: "100%",
    },
    "& .MuiChip-deleteIcon": {
      // width: "initial",
    },
  },
  toCenter: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "calc(100vw - 100px)",
  },
  toRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  invisible: {
    visibility: "hidden",
  },
}));

interface ClosingChipProps {
  action: Action;
  role: WorkerRole;
  title?: string;
  startTime: Date;
  endTime: Date;
}

export function ClosingChip({ action, role, title, startTime, endTime }: ClosingChipProps): ReactElement {
  const styles = useStyles();
  const [, , data] = useSpacing();
  const mobile = useMobileLayout();
  return (
    <>
      <Center horizontal>
        <Box width="100%">
          <MuiGrid alignItems="center" container spacing={data}>
            <MuiGrid item md={3} xs="auto">
              <Button
                action={action}
                className={clsx(styles.invisible)}
                color="info"
                icon={mdiPencil}
                size="small"
                stretch
                variant="outlined"
              >
                {!mobile && (t("Shift.closing.bulkEdit") as string)}
              </Button>
            </MuiGrid>
            <MuiGrid className={styles.toCenter} item md={6} xs>
              <Chip
                action={action}
                className={styles.closeChip}
                color="default"
                label={
                  <MuiGrid alignItems="center" container spacing={0} wrap="nowrap">
                    <MuiGrid className={styles.textEllipsis} item>
                      <Spacer dropEmpty separator=" - ">
                        {WorkerRoleOptions()[role].label}
                        {title}
                      </Spacer>
                    </MuiGrid>
                    <MuiGrid item>
                      {" | "}
                      <TimeRange endTime={endTime} startTime={startTime} />
                    </MuiGrid>
                  </MuiGrid>
                }
                large
              />
            </MuiGrid>
            <MuiGrid className={styles.toRight} item md={3} xs="auto">
              <Button action={action} color="info" icon={mdiPencil} size="small" stretch variant="outlined">
                {!mobile && (t("Shift.closing.bulkEdit") as string)}
              </Button>
            </MuiGrid>
          </MuiGrid>
        </Box>
      </Center>
    </>
  );
}
