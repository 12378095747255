import { Fk, Menu, MenuContainer, UserContainer } from "@sinch/entity";
import { parseDate } from "@sinch/utils";
import { isNil } from "ramda";
import { isString } from "ramda-adjunct";
import { defineRequest, FilesMeta } from "../../backend";
import { BusinessRules } from "./BusinessRules";
import { CurrentUser } from "./CurrentUser";
import { InstanceSettings } from "./InstanceSettings";
import { UserPreferences } from "./UserPreferences";

export const requestSession = defineRequest("Application/Context")
  .withEmptyParams()
  .withResponse<{
    businessRules: BusinessRules;
    currentUser: CurrentUser;
    instanceSettings: InstanceSettings;
    menu: Fk<Menu>[];
    userPreferences: UserPreferences;
  }>({
    currentUser: (currentUser: CurrentUser) => {
      if (!isNil(currentUser.restrictions.hibernated) && isString(currentUser.restrictions.hibernated)) {
        // eslint-disable-next-line no-param-reassign
        currentUser.restrictions.hibernated = parseDate(currentUser.restrictions.hibernated);
      }
      if (!isNil(currentUser.language) && isString(currentUser.language)) {
        // eslint-disable-next-line no-param-reassign
        currentUser.language = currentUser.language.replace("_", "-");
      }
      return currentUser;
    },
  })
  .withResponseMeta<FilesMeta>()
  .withResponseEntity<MenuContainer & UserContainer>()
  .withDefaultCreator();
