import { ResponseData } from "./ResponseData";
import { isExpectedErrorStatus } from "./Status";

export interface FileUploadRequest {
  file: File;

  target: string;
}

export interface FileUploadProgress {
  loaded: number;

  total: number;
}

export interface FileUploadResult {
  expiration: Date;

  hash: string;

  url: string;
}

export type FileUploadResponse = ResponseData<FileUploadResult> | ResponseData<never>;

export const isUploadErrorResponse = (response: FileUploadResponse): response is ResponseData<never> =>
  isExpectedErrorStatus(response.status);

export const uploadProgressPercent = ({ loaded, total }: FileUploadProgress): number =>
  Math.round((loaded / total) * 100);
