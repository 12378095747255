import { DataProvider, responseHandlerKey, useCascadeRefresh, useRefresh } from "@sinch/core";
import { Fk, Shift } from "@sinch/entity";
import { t } from "@sinch/intl";
import { useSnackbar } from "@sinch/ui";
import { asInt } from "@sinch/utils";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { requestShiftFeedbackList, requestShiftFeedbackSubmit } from "./api";
import { ShiftFeedbackDialog } from "./ShiftFeedbackDialog";
import { SelectedFeedbackState } from "./types";

export interface ShiftFeedbackContainerProps {
  shift: Fk<Shift>;
}

export function ShiftFeedbackContainer({ shift: shiftString }: ShiftFeedbackContainerProps): ReactElement {
  const shift = asInt(shiftString);
  const { cascadeRefresh } = useCascadeRefresh();

  const [selected, setSelected] = useState<SelectedFeedbackState>(null);
  const select = useCallback((target: SelectedFeedbackState) => setSelected(target), []);

  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh();
  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestShiftFeedbackSubmit,
        ({ request: { params } }) => {
          snackbar("success", t("Feedback.sendSuccess"));
          select(null);
          refresh();
          cascadeRefresh();
        },
        (reqres) => {
          snackbar("error", t("Feedback.sendError"));
          refresh();
          cascadeRefresh();
        }
      ),
    [refresh, select, snackbar]
  );

  const request = useMemo(() => requestShiftFeedbackList(shift), [shift]);

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request}>
      <ShiftFeedbackDialog onSelect={select} selected={selected} shift={shift} />
    </DataProvider>
  );
}
