import { AccordionProps } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useInstanceSettings } from "@sinch/core";
import { SwitchInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { MapItem, MapLang } from "@sinch/mapycz";
import { rejectFalsy } from "@sinch/utils";
import { isEmpty, join, map, pipe, reject, uniq } from "ramda";
import React, { ReactElement } from "react";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { useSystemAttributes } from "../hooks";
import { MapMultiplePlaceInput } from "../MapMultiplePlaceInput";
import { useFieldListMeta } from "../utils";

export interface EditPreferredResidenceParams {
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
}

export function EditPreferredResidence({
  expanded,
  onExpansionChange,
}: EditPreferredResidenceParams): ReactElement | null {
  const { isBlockEmpty, isVisible, isRequired } = useSystemAttributes();
  const { filled, total, errors } = useFieldListMeta(
    rejectFalsy([isVisible("WorkerPreferredAddress.preferred_address") && `preferredPlaces`])
  );
  const { position: { lat, lng } = { lat: null, lng: null }, locale } = useInstanceSettings();

  if (!isVisible("WorkerPreferredAddress.preferred_address")) {
    // preferred addresses are turned off
    return null;
  }

  const localEmpty = isBlockEmpty([
    "Workerinfo.address_local",
    "Workerinfo.city_local",
    "Workerinfo.zip_local",
    "Workerinfo.country_local",
  ]);

  return (
    <ProfileSettingAccordionItem
      error={errors > 0}
      expanded={expanded}
      filled={filled}
      label={t("Profile.preferredLocationLabel")}
      onExpansionChange={onExpansionChange}
      total={total}
    >
      <MuiGrid container direction="column">
        <MuiGrid item>
          <SwitchInput
            label={t("Profile.permanentResidenceLabel")}
            name="permanentAddressPreferred"
            note={t("Profile.permanentAddressNote")}
          />
        </MuiGrid>
        {!localEmpty && (
          <MuiGrid item>
            <SwitchInput
              label={t("Worker.contactAddress")}
              name="localAddressPreferred"
              note={t("Profile.contactAddressNote")}
            />
          </MuiGrid>
        )}
        <MuiGrid item>
          <MapMultiplePlaceInput<string>
            extractPlaces={(result): string[] => {
              const customPlaceLabel = pipe<MapItem[], string[][], string[][], string[], string[], string[]>(
                // @ts-ignore
                map(({ address: { municipality, region, district } }) => [municipality, district, region]),
                map(uniq),
                map(join(", ")),
                uniq,
                reject(isEmpty)
              )(result);
              if (isEmpty(customPlaceLabel)) {
                return pipe<MapItem[], string[][], string[][], string[], string[], string[]>(
                  // @ts-ignore
                  map(({ label }) => label),
                  map(uniq),
                  map(join(", ")),
                  uniq,
                  reject(isEmpty)
                )(result);
              }
              return customPlaceLabel;
            }}
            getOptionLabel={(item) => item}
            mapClientOptions={{
              debug: { req: true },
              lang: locale ? (locale.substr(0, 2) as MapLang) : undefined,
              limit: 5,
              type: ["regional.municipality", "poi"],
              ...(lat && lng && { lat, lon: lng }),
            }}
            name="preferredPlaces"
            // @ts-expect-error
            renderOption={(item) => item}
            required={isRequired("WorkerPreferredAddress.preferred_address")}
          />
        </MuiGrid>
      </MuiGrid>
    </ProfileSettingAccordionItem>
  );
}
