import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { ReactElement, ReactNode } from "react";

const useStyles = makeStyles({
  "@keyframes pulse": {
    "50%": { opacity: 0.1 },
  },
  pulse: {
    animationName: "$pulse",
    animation: "blink 3s infinite",
  },
});

interface AnimateProps {
  animation: "pulse";
  children: ReactNode;
}
export function Animate({ animation, children }: AnimateProps): ReactElement {
  const styles = useStyles();
  return <Box className={styles[animation]}>{children}</Box>;
}
