import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { t } from "@sinch/intl";
import { Identifier, InformationDialog, routerLink, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestPositionDetail } from "../../Position";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { PositionDetailContent } from "../PositionDetailContent";

export function CalendarPositionDetailDialog(): ReactElement {
  const { selectResult } = useData(requestPositionDetail);
  const { positionId } = selectResult();

  return (
    <InformationDialog
      actions={{
        cancel: false,
        confirm: {
          action: routerLink(`/position/${positionId}`),
          label: t("action.detail"), // Detail
        },
      }}
      denyFullscreen
      title={
        <Box display="flex" flexDirection="column">
          <Text small>
            {t("Position.title")} <Identifier entity="position" id={positionId} />
          </Text>
          <PositionReference id={positionId} name noId title />
        </Box>
      }
      width="xs"
    >
      <PositionDetailContent position={positionId} />
    </InformationDialog>
  );
}
