import { makeStyles } from "@material-ui/core/styles";
import MuiTab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";
import { Consumer, IconId } from "@sinch/types";
import { Badge, BadgeProps, Icon } from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement, useCallback } from "react";
import { isElement } from "react-dom/test-utils";
import { Optional } from "utility-types";

export interface TabProps {
  element: ReactElement;

  icon?: ReactElement | IconId;

  label?: string;

  path: string;

  badge?: BadgeProps;
}

interface TabsBaseProps extends ChildrenProps {
  onSelect: Consumer<string>;

  selected: string | false;

  tabs: Optional<TabProps, "element">[];
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    flex: "0 0 100%",
  },
  content: {
    padding: theme.spacing(2),
    "&:empty": {
      padding: 0,
    },
  },
}));

export function TabsBase({ children, onSelect, selected, tabs }: TabsBaseProps): ReactElement {
  const handleChange = useCallback((event: unknown, value: string) => onSelect(value), [onSelect]);
  const styles = useStyles();

  const IconElement: React.FC<{ icon: ReactElement | IconId }> = ({ icon }) =>
    isElement(icon) ? (icon as ReactElement) : <Icon icon={icon as IconId} />;

  return (
    <>
      {tabs.length > 1 && (
        <MuiTabs
          className={styles.fullWidth}
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          value={selected}
          variant="fullWidth"
        >
          {tabs.map(({ icon, label, path, badge }) => (
            <MuiTab
              key={path}
              icon={
                icon &&
                (badge ? (
                  <Badge {...badge}>
                    <IconElement icon={icon} />
                  </Badge>
                ) : (
                  <IconElement icon={icon} />
                ))
              }
              label={label}
              value={path}
            />
          ))}
        </MuiTabs>
      )}
      {children && <div className={styles.content}>{children}</div>}
    </>
  );
}
