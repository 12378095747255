import React, { ReactElement } from "react";
import { useFormField, validator } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputTextProps } from "./InternalInput";

interface EmailInputProps
  extends Omit<InternalInputTextProps, "multiline" | "type"> {}

const regExp = new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9.-]+$/);
const isEmail = (val: string) => regExp.test(val);

const config = {
  validate: validator(isEmail, "Please provide a valid email address."),
};

/**
 * todo: use `pattern` prop for native validation (needs react-hooks-form)
 *  - not compatible with formik onChange (overrides the error in next render)
 *  - not connected to material input - needs to be propagated via error handler
 */
export function EmailInput(props: EmailInputProps): ReactElement {
  const [baseProps] = useFormField<string, EmailInputProps>(props, config);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <EmailInputBase {...baseProps} />;
}

export function EmailInputBase(
  props: BaseInputProps<string, EmailInputProps>
): ReactElement {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <InternalInput type="email" {...props} />;
}
