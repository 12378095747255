import { mdiAlertCircleOutline } from "@mdi/js";
import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { Button, Header, Icon, ListBase } from "@sinch/ui";
import { ModalDialog } from "@sinch/ui/ApplicationLayout/Dialog/ModalDialog";
import { prop } from "ramda";
import React, { ReactElement } from "react";
import { RequestResponse, statusName } from "../backend";

const useStyles = makeStyles((theme) => ({
  large: {
    fontSize: theme.typography.pxToRem(120),
  },
}));

export interface ErrorDialogProps {
  reqres: RequestResponse;

  onReset: Callback;
}

export function ErrorDialog({
  reqres: {
    response: { messages, status },
  },
  onReset,
}: ErrorDialogProps): ReactElement {
  const styles = useStyles();
  return (
    <ModalDialog onClose={onReset} open>
      <Box p={4}>
        <MuiGrid alignItems="center" container direction="column" spacing={4}>
          <MuiGrid item>
            <Header level={0}>{`${t("error")} ${status} - ${statusName(status)}`}</Header>
          </MuiGrid>
          <MuiGrid item>
            <Icon className={styles.large} color="error" icon={mdiAlertCircleOutline} size="large" />
          </MuiGrid>
          <MuiGrid item>
            <Header level={2}>{t("serverReturnResponse")}:</Header>
          </MuiGrid>
          <MuiGrid item>
            <Alert icon={false} severity="error" variant="outlined">
              <ListBase contentSelector={prop("text")} data={messages} />
            </Alert>
          </MuiGrid>
          <MuiGrid container item justify="space-between">
            <MuiGrid item>
              <Button action={onReset} color="default" large variant="outlined">
                {t("action.cancel")}
              </Button>
            </MuiGrid>
            <MuiGrid item>
              <Button action={() => (window.location.href = "/")} color="info" large variant="contained">
                {t("backToMainPage")}
              </Button>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </Box>
    </ModalDialog>
  );
}
