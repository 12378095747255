import { Box, Grid as MuiGrid, makeStyles } from "@material-ui/core";
import { mdiClose, mdiPencil } from "@mdi/js";

/* eslint-disable react/jsx-props-no-spreading */
import { useProgress } from "@sinch/core/backend/provider/ProgressProvider";
import { ClosingProgress, Fk, PositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, Card, IconButton, LoadingOverlay, Toolbar, useSpacing } from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import { includes } from "ramda";
import React, { ReactElement, useState } from "react";
import { EditMenu } from "../CardList/Work/EditMenu";
import { SetClosingStateDialog, SetStateButtons, StateMenuProps } from "../components";
import { useSelectionSet } from "../context";
import { FullEdit, FullEditProps } from "../Detail";

const useStyles = makeStyles((theme) => ({
  maxWidthMd: {
    width: "100%",
    maxWidth: `380px`,
  },
}));

interface SelectionMenuProps {
  closingStatus: string | number;
}

export function PresenceMultiselect({ closingStatus }: SelectionMenuProps): ReactElement {
  const styles = useStyles();
  const [, inner, data] = useSpacing();
  const [show, , setEditVisibility] = useToggleState();
  const { selected, setSelected } = useSelectionSet<Fk<PositionAttendance>>();
  const { inProgress } = useProgress();

  const [stateDialog, setStateDialog] = useState<{ status: ClosingProgress; inputName?: string } | undefined>();
  const handleSetChangeState: StateMenuProps["onSetState"] = (status, inputName) => {
    setStateDialog({ status, inputName });
  };

  const handleEdit = () => {
    setEditVisibility(true);
  };

  const handleDialogClose = () => setStateDialog(undefined);

  let editItems: FullEditProps["items"] = [];
  if (closingStatus === ClosingProgress.Present) {
    editItems = ["startTime"];
  } else if (closingStatus === ClosingProgress.Break) {
    editItems = ["breakStart"];
  } else if (closingStatus === ClosingProgress.AfterBreak) {
    editItems = ["breakStart", "breakEnd"];
  } else if (closingStatus === ClosingProgress.Finished) {
    editItems = ["startTime", "endTime", "breakStart", "breakEnd"];
  }

  return (
    <Toolbar paper={false} position="bottom">
      {inProgress && <LoadingOverlay />}
      <Box bottom={0} display="flex" justifyContent="center" position="sticky">
        <Box className={styles.maxWidthMd} px={data} py={inner}>
          <Card>
            <MuiGrid container direction="column" spacing={inner}>
              <MuiGrid alignItems="center" container item justify="space-between">
                <MuiGrid item>
                  {t("selectedWorkers")}: {selected.length}
                </MuiGrid>
                <MuiGrid item>
                  <IconButton action={() => setSelected([])} icon={mdiClose} />
                </MuiGrid>
              </MuiGrid>
              {!show && (
                <>
                  <MuiGrid item>
                    <SetStateButtons closingStatus={closingStatus} onSetState={handleSetChangeState} />
                  </MuiGrid>
                  {closingStatus !== ClosingProgress.Pending && (
                    <MuiGrid item>
                      <MuiGrid container item justify="space-between">
                        {includes(closingStatus, [
                          ClosingProgress.Present,
                          ClosingProgress.Break,
                          ClosingProgress.Finished,
                        ]) && (
                          <MuiGrid item xs>
                            <Button action={handleEdit} color="primary" icon={mdiPencil} stretch variant="text">
                              {t("editTime")}
                            </Button>
                          </MuiGrid>
                        )}
                        <MuiGrid item>
                          <EditMenu attendances={selected} target={closingStatus} />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  )}
                </>
              )}
              {show && (
                <MuiGrid item>
                  <FullEdit
                    attendance={selected}
                    hasBreak={false}
                    items={editItems}
                    onCancel={() => setEditVisibility(false)}
                  />
                </MuiGrid>
              )}
            </MuiGrid>
            {stateDialog && <SetClosingStateDialog {...stateDialog} onClose={handleDialogClose} selected={selected} />}
          </Card>
        </Box>
      </Box>
    </Toolbar>
  );
}
