import { getMapyCzApiKey } from "@sinch/worker";
import { extractResult } from "./result";
import { MapSearchFn, MapSearchParams } from "./search";

export interface MapClient {
  search: MapSearchFn;
}

export interface MapClientConfig {
  debug?: boolean | { req?: boolean; res?: boolean };

  timeout?: number;
}

export const createMapClient = (params: MapSearchParams) => {
  const apiKey = getMapyCzApiKey();
  const search = async (input: string) => {
    const searchParams = new URLSearchParams({
      query: input,
      limit: params.limit?.toString() || "5",
      apikey: apiKey || "",
      ...(params.lang && { lang: params.lang }),
    });
    params.type?.forEach((type) => searchParams.append("type", type));
    if (params.lat && params.lon) {
      searchParams.append("preferNear", params.lon.toString());
      searchParams.append("preferNear", params.lat.toString());
    }
    const response = await (
      await fetch(`https://api.mapy.cz/v1/suggest?${searchParams}`, {
        method: "GET",
      })
    ).json();
    return extractResult(response.items);
  };

  return {
    search,
  };
};
