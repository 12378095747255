import { mdiChevronDown, mdiAlertCircle } from "@mdi/js";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";

import { t } from "@sinch/intl";
import { Icon, Text, useMobileLayout } from "@sinch/ui";
import { useFormikContext } from "formik";
import { isEmpty, pick } from "ramda";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  accordionError: {
    "& .MuiAccordionSummary-root": {
      borderColor: theme.palette.error.main,
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "& + .MuiAccordion-root:before": {
      top: 0,
    },
  },
}));

function hasSomeFieldError(fields: string[]): boolean {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { errors } = useFormikContext<unknown>();
  return !isEmpty(pick(fields, errors));
}

interface ProfileSettingAccordionItemParams {
  total: number;
  filled: number;
  label: string;
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
  children?: React.ReactNode;
  error?: boolean;
}

export function ProfileSettingAccordionItem({
  total,
  filled,
  label,
  expanded,
  onExpansionChange,
  children,
  error,
}: ProfileSettingAccordionItemParams): ReactElement {
  const classes = useStyles();

  return (
    <Accordion
      className={error ? classes.accordionError : undefined}
      expanded={expanded}
      onChange={onExpansionChange}
      square
    >
      <AccordionSummary expandIcon={<Icon icon={mdiChevronDown} />}>
        <ProfileEditGroupHeader error={error} filled={filled} label={label} total={total} />
      </AccordionSummary>
      <AccordionDetails>{expanded && children}</AccordionDetails>
    </Accordion>
  );
}

function ProfileEditGroupHeader({
  error,
  label,
  total,
  filled,
}: {
  error?: boolean;
  label: string;
  total: number;
  filled: number;
}) {
  const mobile = useMobileLayout();
  const isFilledCompletely = filled === total;

  const headerLabel = (
    <>
      {error ? (
        <MuiGrid alignItems="center" container spacing={1}>
          <MuiGrid item>
            <Icon color="error" icon={mdiAlertCircle} />
          </MuiGrid>
          <MuiGrid item>
            <Text bold color="error">
              {label}
            </Text>
          </MuiGrid>
        </MuiGrid>
      ) : (
        <Text bold>{label}</Text>
      )}
    </>
  );

  return (
    <MuiGrid container direction={mobile ? "column" : "row"} justify="space-between">
      <MuiGrid item>{headerLabel}</MuiGrid>
      <MuiGrid item>
        {total > 0 && (
          <Text color={isFilledCompletely ? "success" : "warning"} small={mobile}>
            {t("Profile.form.filled", {
              filled,
              total,
            })}
          </Text>
        )}
      </MuiGrid>
    </MuiGrid>
  );
}
