import { Callback, Consumer, Maybe, Provider } from "@sinch/types";
import { not } from "ramda";
import { ChangeEvent, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

/**
 * todo: consider supporting other value types
 *  (select input can use numbers and booleans as well)
 */
export function useInputState(initialState = ""): [string, (e: ChangeEvent<HTMLInputElement>) => void] {
  const [value, setValue] = useState(initialState);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);
  return [value, handleChange];
}

export function useToggleState(initialState = false): [boolean, Callback, Consumer<boolean>] {
  const [value, setValue] = useState(initialState);
  const toggleValue = useCallback(() => setValue((val) => !val), []);
  return [value, toggleValue, setValue];
}

type ToggleState = [
  value: boolean,
  update: Consumer<SetStateAction<boolean>> & {
    set: Callback;
    toggle: Callback;
    unset: Callback;
  }
];

/**
 * todo: replace usage with this improved version
 */
export function useToggleStateObj(initial: boolean | Provider<boolean> = false): ToggleState {
  const [value, setValue] = useState(initial);
  const update = useMemo(
    () =>
      Object.assign(setValue, {
        set: () => setValue(true),
        toggle: () => setValue(not),
        unset: () => setValue(false),
      }),
    []
  );
  return [value, update];
}

export function usePersist(key: string, value: unknown): void {
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
    }
  }, [key, value]);
}

export function loadPersisted<T>(key: string): Maybe<T> {
  try {
    const stored = window.localStorage.getItem(key);
    return stored ? JSON.parse(stored) : undefined;
  } catch (e) {
    return undefined;
  }
}
