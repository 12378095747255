import React, { ReactChild, ReactElement } from "react";
import { Grid } from "../Grid";

export interface TwoColumnLayoutProps {
  children: [ReactChild, ReactChild];
}

/**
 * todo: consider having vertical grid as default wrapper for children columns?
 */
export function TwoColumnLayout({
  children,
}: TwoColumnLayoutProps): ReactElement {
  return (
    <Grid sizes={[{ lg: 4 }, { lg: 8 }]} spacing="outer">
      {children}
    </Grid>
  );
}
