import { CardActionAreaProps as MuiCardActionAreaProps, CardProps as MuiCardProps, lighten } from "@material-ui/core";
import MuiCard from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import { Color, IconId, Nullable } from "@sinch/types";
import { capitalize, toElement, useLongPress } from "@sinch/utils";
import clsx from "clsx";
import { flatten, is, isEmpty, map, reduce } from "ramda";
import { isNotNil } from "ramda-adjunct";
import React, { isValidElement, ReactChild, ReactElement, ReactNode, TouchEventHandler } from "react";
import { isElement } from "react-dom/test-utils";
import { Action, resolveAction } from "../actions";
import { Icon } from "../Icon";
import { createPaletteStyles, IdentifierColorKey, identifiersStylesCreator } from "../paletteStyles";
import { Text } from "../Text";

const paletteStyles = createPaletteStyles(({ main, contrastText }) => ({
  "&$cardShadow": {
    "&::before": {
      boxShadow: `-5px 0 0 0 ${main}`,
    },
  },
  "&$cardLabel": {
    color: contrastText,
    backgroundColor: main,
  },
  "&$cardHeader": {
    backgroundColor: lighten(main, 0.8),
  },
}));

const identifiersPaletteStyles = identifiersStylesCreator(({ color, backgroundColor }) => ({
  "&$cardShadow": {
    "&::before": {
      boxShadow: `-5px 0 0 0 ${backgroundColor}`,
    },
  },
  "&$cardLabel": {
    color,
    backgroundColor,
  },
}));

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    // whiteSpace: "nowrap", // todo: needs review (fixes weird bottom padding)
  },
  cartGuttersNormal: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cartGuttersVerticalNormal: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "&.MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  cartGuttersSmall: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cartGuttersVerticalSmall: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&.MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  cartGuttersNone: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  cartGuttersVerticalNone: {
    paddingTop: 0,
    paddingBottom: 0,
    "&.MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },

  cardShadow: {
    "&::before": {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "5px",
      content: "''",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.background.default}`,
  },
  cardHeaderTitle: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardHeaderExpand: {
    padding: theme.spacing(0, 2),
    paddingTop: "0.35em",
  },
  cardLabel: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 3),
    borderRight: `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px 0 0 0",
    "& > :nth-child(2)": {
      marginLeft: theme.spacing(2),
    },
  },
  cardContentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },
  cardContent: {
    "&.MuiCardContent-root": {
      borderTop: `1px solid ${theme.palette.dividerDark.main}`,
    },
    "&.MuiCardContent-root:first-child": {
      borderTop: `none`,
    },
  },
  expandableSectionTitle: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  ...paletteStyles(theme),
  ...identifiersPaletteStyles(theme),
}));

interface CardLabelProps {
  color?: Color | IdentifierColorKey;

  icon?: IconId | ReactChild;

  label?: string | ReactChild;

  textColor?: Color;
}

function CardLabel({ color, icon, label, textColor }: CardLabelProps): Nullable<ReactElement> {
  const styles = useStyles({});

  return label || icon ? (
    <div className={clsx(styles.cardLabel, color && styles[color])}>
      {icon && isValidElement(icon) && icon}
      {icon && !isValidElement(icon) && <Icon icon={icon as IconId} size="small" />}
      {label && (
        <Text bold color={textColor}>
          {label}
        </Text>
      )}
    </div>
  ) : null;
}

interface CardHeaderProps extends CardLabelProps {
  expanded?: boolean | null;

  title?: string | ReactNode;

  textColor?: Color;
  headerColor?: Color;

  content?: ReactNode;
}

function CardHeader({
  color,
  expanded,
  icon,
  label,
  title,
  textColor,
  content,
  headerColor,
}: CardHeaderProps): Nullable<ReactElement> {
  const styles = useStyles({});

  return title || label || icon || content ? (
    <div className={clsx(styles.cardHeader, headerColor && styles[headerColor])}>
      {content || (
        <>
          <CardLabel color={color} icon={icon} label={label} textColor={textColor} />
          <div className={styles.cardHeaderTitle}>
            <Text bold>{title}</Text>
          </div>
        </>
      )}
      {isNotNil(expanded) && (
        <div className={styles.cardHeaderExpand}>
          <Icon icon={expanded ? mdiChevronDown : mdiChevronUp} />
        </div>
      )}
    </div>
  ) : null;
}

interface CardActionAreaProps extends Omit<MuiCardActionAreaProps, "action" | "children"> {
  action?: Action;
  children: ReactNode;
}

export function CardActionArea({ action, children, ...props }: CardActionAreaProps): ReactElement {
  const eventProps = { ...(action ? resolveAction(action) : {}), ...props };
  if (isEmpty(eventProps)) return toElement(children);
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <MuiCardActionArea {...eventProps}>{children}</MuiCardActionArea>;
}

export interface CardProps extends Omit<MuiCardProps, "title"> {
  action?: Action;

  center?: boolean;

  color?: Color | IdentifierColorKey;

  icon?: IconId | ReactChild;
  selected?: boolean;
  label?: string | ReactChild;

  small?: boolean;

  title?: ReactNode;

  header?: ReactNode;

  textColor?: Color;

  onLongTouch?: TouchEventHandler;

  headerColor?: Color;

  gutters?: "none" | "small" | "normal";
  guttersVertical?: "none" | "small" | "normal";
}

/**
 * todo:
 *  - support icon buttons?
 *  - can we render with status only (no title)?
 *  - unify with Strip to use single base component?
 *  - better action area handling?
 *  - unify button with card and dialog
 *  - wrap with text component if only single string child
 *  - fix button positions same as in Strip
 *  - review interaction implementation
 */
export function Card({
  action,
  center,
  children,
  color,
  icon,
  selected,
  label,
  small,
  title,
  textColor,
  header,
  headerColor,
  onLongTouch,
  gutters = "normal",
  guttersVertical = "normal",
}: CardProps): ReactElement {
  const styles = useStyles({ gutters, guttersVertical });

  const { onClick = () => {} } = resolveAction(action);
  const touchHoldEvents = useLongPress(onLongTouch || (() => {}), onClick, {
    shouldPreventDefault: false,
    delay: 1000,
  });

  const cardContentClass = clsx(
    center && styles.cardContentCenter,
    styles[`cartGutters${capitalize(gutters)}`],
    styles[`cartGuttersVertical${capitalize(guttersVertical)}`],
    styles.cardContent
  );

  const isCardContentArray =
    is(Array, children) &&
    reduce<typeof children, boolean>(
      (acc, child) => acc && isElement(child) && (child as ReactElement).type === MuiCardContent,
      true,
      flatten(children as ReactElement[])
    );
  return (
    <MuiCard
      className={clsx(styles.card, color && styles[color], small && styles.cardShadow, selected && styles.selected)}
      elevation={0}
    >
      <CardActionArea action={onLongTouch ? () => {} : action} {...(onLongTouch ? touchHoldEvents : {})}>
        <CardHeader
          color={small ? undefined : color}
          content={header}
          headerColor={headerColor}
          icon={small ? undefined : icon}
          label={small ? undefined : label}
          textColor={textColor}
          title={title}
        />
        {isCardContentArray ? (
          map<ReactElement, ReactElement>(
            (child: ReactElement) => React.cloneElement(child, { className: cardContentClass }),
            flatten(children as ReactElement[])
          )
        ) : (
          <MuiCardContent className={cardContentClass}>{children}</MuiCardContent>
        )}
      </CardActionArea>
    </MuiCard>
  );
}
