import { noop } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useMobileLayout } from "../../UiProvider";
import { BottomDrawer } from "./BottomDrawer";
import { DialogContextProvider } from "./DialogContext";
import { DialogProps } from "./DialogProps";
import { ModalDialog } from "./ModalDialog";

/**
 * todo: rename to TemporaryDialog
 *  (or maybe parametrize as single component?)
 *  - enforce temporary dialog to have control or onClose?
 */
export function ComplementaryDialog({
  children,
  control,
  onClose: onCloseProp,
  open: openProp,
  ...props
}: DialogProps): ReactElement {
  const mobile = useMobileLayout();

  const onClose = control?.close ?? onCloseProp ?? noop;
  // const onOpen = control?.open ?? noop;
  const open = control?.state ?? openProp ?? true;

  /* eslint-disable react/jsx-props-no-spreading */

  return (
    <DialogContextProvider onClose={onClose}>
      {mobile ? (
        <BottomDrawer onClose={onClose} open={open} {...props}>
          {children}
        </BottomDrawer>
      ) : (
        <ModalDialog onClose={onClose} open={open} {...props}>
          {children}
        </ModalDialog>
      )}
    </DialogContextProvider>
  );
}
