/* eslint-disable-next-line import/no-extraneous-dependencies */
import { lighten, makeStyles } from "@material-ui/core";
import { makeSearchQuery, useData, useSearchParamsCustom } from "@sinch/core";
import { CalendarEntryType, Id, selectCalendarEntriesInInterval } from "@sinch/entity";
import { useIntl } from "@sinch/intl";
import { useMobileLayout } from "@sinch/ui";
import { rejectFalsy, serializeDate, serializeDateTime } from "@sinch/utils";
import { add, endOfDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { isEmpty, isNil } from "ramda";
import React, { ReactElement } from "react";
import { Event, stringOrDate } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { CalendarParams, requestCalendar } from "./api";
import { BigCalendar } from "./BigCalendar";
import { CalendarEvent } from "./CalendarEvent";
import { makeEventPropGetter } from "./calendarPropGetters";
import { CalendarToolbar } from "./CalendarToolbar";
import { DynamicWeekView } from "./components";

const useEventStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: 0,
  },
  [CalendarEntryType.Unavailable]: {
    backgroundColor: "#d9d9d9 !important",
    color: "#4F4F4F !important",
    opacity: 0.3,
  },
  [CalendarEntryType.Available]: {
    backgroundColor: "#FFCC00 !important",
    color: "#4F4F4F !important",
    opacity: 0.3,
  },
  [CalendarEntryType.Position]: {
    backgroundColor: "#b8dae6 !important",
    color: "#002533 !important",
  },
  [CalendarEntryType.Applicant]: {
    backgroundColor: "#afa3cc !important",
    color: "#002533 !important",
  },
  [CalendarEntryType.Appointment]: {
    backgroundColor: "#b8e6cd !important",
    color: "#002533 !important",
  },
  [CalendarEntryType.Transport]: {
    backgroundColor: "#a3b8cc !important",
    color: "#002533 !important",
  },
  [CalendarEntryType.Confirmation]: {
    backgroundColor: `${lighten(palette.warning.main, 0.9)} !important`,
    backgroundImage: `repeating-linear-gradient(45deg, transparent, transparent 5px, ${lighten(
      palette.warning.light,
      0.7
    )} 0px, ${lighten(palette.warning.light, 0.7)} 10px)`,
    color: "#002533 !important",
  },
  warning: {
    backgroundColor: `${lighten(palette.warning.main, 0.9)} !important`,
    backgroundImage: `repeating-linear-gradient(45deg, transparent, transparent 5px, ${lighten(
      palette.warning.light,
      0.7
    )} 0px, ${lighten(palette.warning.light, 0.7)} 10px)`,
  },
}));

export interface GenEvent extends Event {
  id: Id;

  type: CalendarEntryType;
}

const warning = false;

export function CalendarBigCalendar({ events }: { events: GenEvent[] }): ReactElement {
  const mobile = useMobileLayout();
  const eventStyles = useEventStyles();
  const navigate = useNavigate();
  const { selectEntity } = useData(requestCalendar);
  const { timeZone } = useIntl();

  const { searchParams, updateSearchParams } = useSearchParamsCustom();

  const handleSelectEvent = ({ id, type }: GenEvent) => {
    navigate({
      search: makeSearchQuery(searchParams),
      hash: `#/detail/${type}/${id}`,
    });
  };

  const handleSelectSlot = ({ start, end }: Event) => {
    // @ts-ignore
    const selected = selectEntity(selectCalendarEntriesInInterval(start, end));

    if (!isNil(start) && !isNil(end)) {
      const dateRangeSearchQuery = makeSearchQuery({
        startTime: serializeDateTime(zonedTimeToUtc(start, timeZone)),
        endTime: serializeDateTime(add(zonedTimeToUtc(end, timeZone), { seconds: -1 })),
      });

      if (isEmpty(selected)) {
        navigate({
          search: makeSearchQuery(searchParams),
          hash: `#/availability/add${dateRangeSearchQuery}`,
        });
      } else {
        navigate({
          search: makeSearchQuery(searchParams),
          hash: `#/selection${dateRangeSearchQuery}`,
        });
      }
    }
  };

  const handleRangeChange = (dates: stringOrDate[] | { start: stringOrDate; end: stringOrDate }) => {
    let dateArray = dates;
    if (typeof dates === "object") {
      dateArray = Object.values(dates);
    }
    // @ts-ignore
    const to = new Date(Math.max(...dateArray));
    to.setDate(to.getDate() + 1);
    // @ts-ignore
    const from = new Date(Math.min(...dateArray));

    updateSearchParams({ from: serializeDate(from), to: serializeDate(to) });
  };

  const eventPropGetter = makeEventPropGetter(eventStyles, warning);

  const { from = new Date(), to = new Date() }: CalendarParams = searchParams;
  const middate = new Date((new Date(from).getTime() + new Date(to).getTime()) / 2);
  return (
    <BigCalendar
      components={{
        /* @ts-expect-error */
        event: CalendarEvent,
        toolbar: CalendarToolbar,
      }}
      date={middate}
      dayLayoutAlgorithm="no-overlap"
      drilldownView={null}
      eventPropGetter={eventPropGetter}
      events={events}
      handleDragStart={() => {}}
      longPressThreshold={1000}
      onNavigate={() => {}}
      onRangeChange={handleRangeChange}
      onSelectEvent={handleSelectEvent}
      onSelectSlot={handleSelectSlot}
      onShowMore={(events, date) => {
        const modifiedEvents = events.splice(0, events.length);
        for (const modifiedEvent of modifiedEvents) {
          events.push({
            ...modifiedEvent,
            showEnd: true,
            showDate: modifiedEvent.start?.getTime() < date || modifiedEvent.start?.getDate() > endOfDay(date),
          });
        }
      }}
      popup
      selectable
      showMultiDayTimes
      style={{ height: "72vh" }}
      tooltipAccessor={undefined}
      views={rejectFalsy({
        month: true,
        week: !mobile,
        "3 days": mobile && DynamicWeekView,
        day: true,
      })}
    />
  );
}
