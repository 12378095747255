import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { mdiMapMarkerAlertOutline, mdiMapMarkerCheckOutline, mdiMapMarkerOffOutline } from "@mdi/js";
import { useFiles } from "@sinch/core";
import { Location } from "@sinch/entity";
import { Button, Icon } from "@sinch/ui";
import { palette } from "@sinch/ui/theme/palette";
import { useGeolocation } from "@sinch/utils";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GeolocationPayload } from "./types";

interface StaffClockInGeolocationProps {
  location: Location;
  formContent: React.ReactNode;
  onConfirm: (data: GeolocationPayload) => void;
  onCancel: () => void;
}

export const StaffClockInGeolocation: React.FC<StaffClockInGeolocationProps> = ({
  location,
  formContent,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { error, clear, loading, inRadius, data, getLocation, retryLocation, retryCount } = useGeolocation(location);

  const { status } = useFiles();

  useEffect(() => {
    getLocation();
    return () => {
      clear();
    };
  }, []);

  const handleConfirm = () => {
    clear();
    onConfirm({ ...data, error });
  };

  const handleCancel = () => {
    clear();
    onCancel();
  };

  if (loading) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid alignItems="center" container direction="row" justify="center">
          <CircularProgress style={{ height: 12, width: 12, marginRight: 5 }} />
          <Typography component="span" style={{ fontWeight: 600 }}>
            {t<string>("StaffClockIn.fetchingLocation")}
          </Typography>
        </Grid>
        <Grid item>{formContent}</Grid>
        <Grid item style={{ marginTop: 18 }}>
          <Button color="info" disabled large variant="contained">
            {t<string>("action.confirm")}
          </Button>
        </Grid>
        <Grid item>
          <Button action={onCancel} color="error" large variant="text">
            {t<string>("action.cancel")}
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (error && error.code === error.PERMISSION_DENIED) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid alignItems="center" container direction="row" justify="center">
          <Icon color="error" icon={mdiMapMarkerOffOutline} />
          <Typography color="error" component="span" style={{ fontWeight: 600 }}>
            {t<string>("StaffClockIn.gpsNotAvailable")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">{t<string>("StaffClockIn.gpsNotAvailableText")}</Typography>
        </Grid>
        <Grid item>{formContent}</Grid>
        {retryCount < 1 ? (
          <Grid item style={{ marginTop: 18 }}>
            <Button action={retryLocation} color="info" large variant="contained">
              {t<string>("action.tryAgain")}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Button action={retryLocation} color="info" large variant="text">
                {t<string>("action.tryAgain")}
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 18 }}>
              <Button action={handleConfirm} color="info" disabled={status !== "ready"} large variant="contained">
                {t<string>("action.confirmAnyway")}
              </Button>
            </Grid>
          </>
        )}
        <Grid item>
          <Button action={onCancel} color="error" large variant="text">
            {t<string>("action.cancel")}
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (data && !inRadius) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid alignItems="center" container direction="row" justify="center">
          <Icon color="error" icon={mdiMapMarkerAlertOutline} />
          <Typography color="error" component="span" style={{ fontWeight: 600 }}>
            {t<string>("StaffClockIn.outsideLocation", { locationName: location.name })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">
            {t<string>("StaffClockIn.outsideLocationText", { radius: location.geolocationRadius })}
          </Typography>
        </Grid>
        <Grid item>{formContent}</Grid>
        <Grid item style={{ marginTop: 18 }}>
          <Button action={handleConfirm} color="info" disabled={status !== "ready"} large variant="contained">
            {t<string>("action.confirmAnyway")}
          </Button>
        </Grid>
        <Grid item>
          <Button action={onCancel} color="error" large variant="text">
            {t<string>("action.cancel")}
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (data && inRadius) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid alignItems="center" container direction="row" justify="center">
          <Icon color="success" icon={mdiMapMarkerCheckOutline} />
          {/* @ts-ignore */}
          <Typography component="span" style={{ fontWeight: 600, color: palette.success.main }}>
            {t<string>("StaffClockIn.insideLocation", { locationName: location.name })}
          </Typography>
        </Grid>
        <Grid item>{formContent}</Grid>
        <Grid item style={{ marginTop: 18 }}>
          <Button action={handleConfirm} color="info" disabled={status !== "ready"} large variant="contained">
            {t<string>("action.confirm")}
          </Button>
        </Grid>
        <Grid item>
          <Button action={onCancel} color="error" large variant="text">
            {t<string>("action.cancel")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid alignItems="center" container direction="row" justify="center">
        <Icon color="error" icon={mdiMapMarkerOffOutline} />
        <Typography color="error" component="span" style={{ fontWeight: 600 }}>
          {t<string>("StaffClockIn.locationError")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center">{t<string>("StaffClockIn.locationErrorText")}</Typography>
      </Grid>
      <Grid item>{formContent}</Grid>
      <Grid item style={{ marginTop: 18 }}>
        {retryCount < 1 ? (
          <Grid item style={{ marginTop: 18 }}>
            <Button action={retryLocation} color="info" large variant="contained">
              {t<string>("action.tryAgain")}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Button action={retryLocation} color="info" large variant="text">
                {t<string>("action.tryAgain")}
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 18 }}>
              <Button action={handleConfirm} color="info" disabled={status !== "ready"} large variant="contained">
                {t<string>("action.confirmAnyway")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item>
        <Button action={handleCancel} color="error" large variant="text">
          {t<string>("action.cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};
