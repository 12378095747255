import { mdiClose } from "@mdi/js";
import MuiDrawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";

import React, { ReactElement } from "react";
import { IconButton } from "../../Button";
import { Header } from "../../Text";
import { DialogActions } from "./DialogActions";
import { DialogInternalProps } from "./DialogProps";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
  handle: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      width: theme.spacing(8),
      height: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.neutral.main,
    },
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      padding: 0,
    },
  },
}));

/**
 * todo: implement SwipeableDrawer and replace close button with standard handle
 *  (Swipeable requires onOpen callback so state should be handled externally)
 *  - make onOpen optional and fallback to onClose as default?
 */
export function BottomDrawer({ actions, children, onClose, open, subtitle, title }: DialogInternalProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiDrawer anchor="bottom" onClose={onClose} open={open} PaperProps={{ className: styles.paper }}>
      {/*
      <div className={styles.handle}>
        <div />
      </div>
      */}
      <div className={styles.close}>
        <IconButton action={() => onClose()} icon={mdiClose} size="small" />
      </div>
      {title && <Header level={2}>{title}</Header>}
      {subtitle && <Header level={3}>{subtitle}</Header>}
      {children}
      {actions && <DialogActions actions={actions} onClose={onClose} />}
    </MuiDrawer>
  );
}
