import { Box } from "@material-ui/core";
import { mdiAccountStar, mdiBriefcaseOutline, mdiCalendarOutline, mdiCheckAll, mdiHomeOutline } from "@mdi/js";

import { useData } from "@sinch/core";
import { Position, PositionStatus, selectPosition } from "@sinch/entity";
import { useTranslation } from "@sinch/intl";
import { SwiperPage, SwiperPages, useSpacing } from "@sinch/ui";
import { length, reject } from "ramda";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { CalendarContent } from "../../../Calendar/CalendarContainer";
import { requestDashboardAttendanceList } from "../api";
import { SwipeUpdateTitle } from "./components";
import { MobileDashboardOverview } from "./MobileDashboardOverview";
import { MobileFeedbackTab } from "./MobileFeedbackTab";
import { MobileFinishedTab } from "./MobileFinishedTab";
import { MobileIncomingPositions } from "./MobileIncomingPositions";

export function MobileDashboard(): ReactElement {
  const t = useTranslation();
  const [, , data] = useSpacing();
  const { pathname } = useLocation();

  const { selectResult, selectEntity } = useData(requestDashboardAttendanceList);
  const {
    incomingPositionIds,
    feedbackPositionIds,
    positionIdsToClose,
    incomingAppointmentIds,
    applicantPositionIds,
    confirmationPositionIds,
  } = selectResult();

  const incoming = length(incomingPositionIds);
  const applicantCount = length(applicantPositionIds);
  const feedback = length(feedbackPositionIds);
  const finished = positionIdsToClose ? length(positionIdsToClose) : 0;
  const appointments = incomingAppointmentIds ? length(incomingAppointmentIds) : 0;

  const unclosedShifts = positionIdsToClose
    ? reject(({ status }: Position) => status >= PositionStatus.CrewbossClosed)(
        selectEntity(selectPosition(positionIdsToClose))
      )
    : [];
  const unclosed = length(unclosedShifts);

  return (
    <Box marginLeft={-data} marginTop={-data}>
      <SwiperPages active={pathname.split("/").slice(-1)[0] || "overview"}>
        <SwiperPage icon={mdiHomeOutline} id="overview" label={t("Dashboard.title") as string}>
          <SwipeUpdateTitle title={t("Dashboard.title")} />
          <MobileDashboardOverview />
        </SwiperPage>
        <SwiperPage
          badge={{
            content: length(confirmationPositionIds),
            color: length(confirmationPositionIds) > 0 ? "warning" : "info",
          }}
          icon={mdiBriefcaseOutline}
          id="incoming"
          label={`${t<string>("Dashboard.myAgenda")} (${incoming + applicantCount + appointments})`}
        >
          <SwipeUpdateTitle title={t("Dashboard.myAgenda")} />
          <MobileIncomingPositions />
        </SwiperPage>
        {finished > 0 && (
          <SwiperPage
            badge={unclosed > 0 ? { content: unclosed, color: "error" } : undefined}
            icon={mdiCheckAll}
            id="finished"
            label={`${t<string>("Dashboard.finished")} (${finished})`}
          >
            <SwipeUpdateTitle title={t("Dashboard.finished")} />
            <MobileFinishedTab />
          </SwiperPage>
        )}
        <SwiperPage icon={mdiAccountStar} id="feedback" label={`${t<string>("Feedback.titlePlural")} (${feedback})`}>
          <SwipeUpdateTitle title={t("Dashboard.finished")} />
          <MobileFeedbackTab />
        </SwiperPage>
        <SwiperPage icon={mdiCalendarOutline} id="calendar" label={t("Calendar.title") as string} lazy>
          <SwipeUpdateTitle title={t("Calendar.title")} />
          <Box px={data} py={data}>
            <CalendarContent />
          </Box>
        </SwiperPage>
      </SwiperPages>
    </Box>
  );
}
