import { BreakpointsOptions } from "@material-ui/core/styles/createBreakpoints";

/**
 * todo: consider renaming keys to more explicit identifiers
 *  https://material-ui.com/customization/breakpoints/#custom-breakpoints
 */
export const breakpoints: BreakpointsOptions = {
  keys: ["xs", "sm", "md", "lg"],
  values: {
    // @ts-ignore
    xs: 0,
    sm: 764,
    /**
     * 0-764 px
     * small layout (default mobile)
     *
     * we might support additional breakpoint at 360 px for tablets
     */
    md: 764 /**/,
    /**
     * 764-1366 px
     * single column layout (small desktop)
     *
     * screen space for single column with 24 px gutters and 64 px sidebar
     *   content width cannot get under 828 px
     */
    lg: 1280,
    /**
     * 1366 px or more
     * two column layout (default desktop)
     * screen space for 402 and 828 px columns with 24 px gutters
     *  and 64 px sidebar
     *
     * we might support additional breakpoint at 1920 px for admins
     */
  },
};

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    //xs: false;

    xl: false;
  }
}
