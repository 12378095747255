import { selectUser, User as UserEntity } from "@sinch/entity";
import { ChildrenProps } from "@sinch/utils";
import React, { createContext, ReactElement, useContext, useMemo } from "react";
import TagManager from "react-gtm-module";
import { FileInfo, selectFileInfo, useData } from "../backend";
import { CurrentUser as UserSession, requestSession } from "../contract";
import { setUser } from "../services";

/*
 * todo: implement mapped type converting FileHash props to FileInfo?
 */

export interface CurrentUser extends UserSession, Pick<UserEntity, "email" | "name" | "phone" | "citizenship"> {
  avatar?: FileInfo;
}

/**
 * todo: replace with safe context leaving initial value undefined
 *  (now we have to provide value because useData reads from current user
 *   context to enable legacy entity query selector - to be removed later)
 */
const CurrentUserContext = createContext({} as CurrentUser);
export const useCurrentUser = (): CurrentUser => useContext(CurrentUserContext);
// const CurrentUserContext = createSafeContext<CurrentUser>("CurrentUser");
// export const { use: useCurrentUser } = CurrentUserContext;

export function CurrentUserProvider({ children }: ChildrenProps): ReactElement {
  const { selectEntity, selectMeta, selectResult } = useData(requestSession);
  const { currentUser } = selectResult();

  const context = useMemo(() => {
    const { id } = currentUser;
    const { avatar, email, name, phone } = selectEntity(selectUser(id));
    // @ts-expect-error
    setUser({ id, username: name, email });

    TagManager.dataLayer({
      dataLayer: {
        userId: id,
      },
    });

    return {
      ...currentUser,
      avatar: selectMeta(selectFileInfo(avatar)),
      email,
      name,
      phone,
    };
  }, [currentUser, selectEntity, selectMeta]);

  return <CurrentUserContext.Provider value={context}>{children}</CurrentUserContext.Provider>;
}
