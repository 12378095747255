import { useData } from "@sinch/core";
import { Fk, Position, PositionQ, selectPosition, selectShift, selectTransport, Transport } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Card, GridLayout } from "@sinch/ui";
import React, { ReactElement } from "react";
import { Section } from "../../components";
import { requestPositionDetail } from "./api";

interface TransportCardProps {
  label: string;

  position: Fk<Position>;

  transport: Fk<Transport>;
}

function TransportCard({ label, position, transport }: TransportCardProps): ReactElement | null {
  const { selectEntity, queryEntity } = useData(requestPositionDetail);

  const { description, driverDescription } = selectEntity(selectTransport(transport));

  const isDriver = queryEntity(PositionQ.User.isTransportDriver(position));

  if (!description && (!isDriver || !driverDescription)) return null;

  /**
   * todo: use Grid with auto size when available
   */
  return (
    <GridLayout.Item>
      <Card>
        <GridLayout>
          {description && (
            <GridLayout.Item size>
              <Section html title={t("Transport.description", { label })}>
                {description}
              </Section>
            </GridLayout.Item>
          )}
          {isDriver && driverDescription && (
            <GridLayout.Item size>
              <Section html title={t("Transport.driverDescription")}>
                {driverDescription}
              </Section>
            </GridLayout.Item>
          )}
        </GridLayout>
      </Card>
    </GridLayout.Item>
  );
}

export function PositionDetailTransport(): Nullable<ReactElement> {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId } = selectResult();

  const { shift } = selectEntity(selectPosition(positionId));
  const { transport } = selectEntity(selectShift(shift));

  if (!queryEntity(PositionQ.hasTransport(positionId)) || (!transport?.to && !transport?.from)) {
    return null;
  }

  return (
    <>
      {transport?.to && <TransportCard label={t("Transport.there")} position={positionId} transport={transport.to} />}
      {transport?.from && (
        <TransportCard label={t("Transport.back")} position={positionId} transport={transport.from} />
      )}
    </>
  );
}
