import { makeStyles } from "@material-ui/core/styles";
import {
  mdiAccountCancel,
  mdiCancel,
  mdiCar,
  mdiCheck,
  mdiHumanGreetingVariant,
  mdiLock,
  mdiShuffleVariant,
} from "@mdi/js";

import { iconPersonPending } from "@sinch/assets/img/customIcons";
import { useData } from "@sinch/core";
import { Fk, Position, PositionQ, selectPosition } from "@sinch/entity";
import { Icon } from "@sinch/ui";
import { includes, isEmpty } from "ramda";
import React, { useCallback } from "react";

const useStyles = makeStyles(() => ({
  containerBox: {
    display: "flex",
    flexWrap: "noWrap",
  },
}));

export type AvailableStatuses =
  | "applicant"
  | "assigned"
  | "locked"
  | "requirements"
  | "conflict"
  | "full"
  | "transport"
  | "cancelled";

export function PositionStatusIcons({ id, statuses = [] }: { id: Fk<Position>; statuses?: AvailableStatuses[] }) {
  const classes = useStyles();
  // @ts-expect-error
  const { selectEntity, queryEntity } = useData();
  const icons = [];
  const { applicants, locked, requirementsFailed } = selectEntity(selectPosition(id));

  const allowed = useCallback((status: AvailableStatuses) => includes(status, statuses) || isEmpty(statuses), [
    statuses,
  ]);

  if (allowed("applicant") && !queryEntity(PositionQ.User.isAssigned(id)) && applicants)
    icons.push(<Icon key="hasApplicant" color="colorApplicant" icon={mdiHumanGreetingVariant} size="small" />);

  if (
    allowed("assigned") &&
    queryEntity(PositionQ.User.isAssigned(id)) &&
    !queryEntity(PositionQ.User.isRequiredConfirmation(id))
  )
    icons.push(<Icon key="isAssigned" color="success" icon={mdiCheck} size="small" />);

  if (allowed("assigned") && queryEntity(PositionQ.User.isRequiredConfirmation(id)))
    icons.push(<Icon key="isConfirmationRequired" color="warning" icon={iconPersonPending} size="small" />);

  if (allowed("locked") && !applicants && locked) icons.push(<Icon key="isLocked" icon={mdiLock} size="small" />);

  if (allowed("requirements") && !applicants && requirementsFailed)
    icons.push(<Icon key="metRequirements" color="error" icon={mdiCancel} size="small" />);

  if (allowed("conflict") && queryEntity(PositionQ.User.hasConflicting(id)))
    icons.push(<Icon key="inConflict" color="error" icon={mdiShuffleVariant} size="small" />);

  if (
    allowed("full") &&
    !queryEntity(PositionQ.User.isAssigned(id)) &&
    !applicants &&
    queryEntity(PositionQ.isFull(id))
  )
    icons.push(<Icon key="isFull" icon={mdiAccountCancel} size="small" />);

  if (allowed("transport") && queryEntity(PositionQ.hasTransport(id))) {
    icons.push(<Icon key="transport" icon={mdiCar} size="small" />);
  }

  if (allowed("cancelled") && queryEntity(PositionQ.isCancelled(id))) {
    icons.push(<Icon key="cancelled" color="error" icon={mdiCancel} size="small" />);
  }

  return <div className={classes.containerBox}>{icons}</div>;
}
