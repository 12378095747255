import {
  DataProvider,
  responseHandlerKey,
  responseHandlerSequence,
  ScrollHistory,
  useIntParams,
  useRefresh,
} from "@sinch/core";
import { t } from "@sinch/intl";
import { GridLayout, TwoColumnLayout, useSnackbar, useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestAppointmentAttendanceJoin, requestAppointmentAttendanceLeave, requestAppointmentDetail } from "./api";
import { AppointmentDetailAcceptForm } from "./AppointmentDetailAcceptForm";
import { AppointmentDetailCapacity } from "./AppointmentDetailCapacity";
import { AppointmentDetailDescription } from "./AppointmentDetailDescription";
import { AppointmentDetailHeader } from "./AppointmentDetailHeader";
import { AppointmentDetailParametersList } from "./AppointmentDetailParametersList";
import { AppointmentDetailSnippets } from "./AppointmentDetailSnippets";
import { AppointmentDetailStatus } from "./AppointmentDetailStatus";

export function AppointmentDetail(): ReactElement {
  return (
    <TwoColumnLayout>
      <GridLayout spacing="outer">
        <AppointmentDetailHeader />
        <AppointmentDetailCapacity />
        <AppointmentDetailStatus />
        <AppointmentDetailSnippets/>
      </GridLayout>
        <GridLayout spacing="outer">
          <AppointmentDetailParametersList />
          <AppointmentDetailDescription />
        <AppointmentDetailAcceptForm />
      </GridLayout>
    </TwoColumnLayout>
  );
}

export function AppointmentDetailContainer(): ReactElement {
  useTitleBar({
    container: "core:appointment-detail",
    locationTitle: t("Appointment.title"),
    showBack: true,
  });

  const { appointment } = useIntParams();

  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh({ interval: 60000 });
  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestAppointmentAttendanceJoin,
          () => {
            snackbar("success", t("Appointment.signUpSuccess"));
            refresh();
          },
          () => {
            snackbar("error", t("Appointment.signUpFail"));
            refresh();
          }
        ),
        responseHandlerKey(
          requestAppointmentAttendanceLeave,
          () => {
            snackbar("success", t("Appointment.signOffSuccess"));
            refresh();
          },
          () => {
            snackbar("error", t("Appointment.signUpFail"));
            refresh();
          }
        ),
      ]),
    [refresh, snackbar]
  );

  const request = useMemo(() => requestAppointmentDetail(appointment), [appointment]);

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request}>
      <ScrollHistory>
        <AppointmentDetail />
      </ScrollHistory>
    </DataProvider>
  );
}
