import { DataProvider } from "@sinch/core";
import { t } from "@sinch/intl";
import { InformationDialog } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestSuspendedPenaltyList } from "./api";
import { ExcusablePenaltyList } from "./ExcusablePenaltyList";

export function SuspendedPenaltyListContainer(): ReactElement {
  const request = useMemo(() => requestSuspendedPenaltyList(), []);

  return (
    <DataProvider request={request}>
      <InformationDialog title={t("Penalty.conditionalPenalty")}>
        <ExcusablePenaltyList />
      </InformationDialog>
    </DataProvider>
  );
}
