import { useSnackbar } from "@sinch/ui";
import {
  ChildrenProps,
  getActiveSubscription,
  requestCreateSubscription,
  requestDeleteSubscription,
  requestGetAllUsersSubscriptions,
  urlBase64ToUint8Array,
} from "@sinch/utils";
import { VariantType } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useInstanceSettings } from "./InstanceSettings";

export type PushNotificationBrowserStatus = "incompatible" | "denied" | "compatible" | "granted";
export type PushNotificationStatus = PushNotificationBrowserStatus | "active" | "loading";

export interface PushNotificationsContextProps {
  status: PushNotificationStatus;
  hasActiveSubscription: "loading" | boolean;
  isModuleActive: boolean;
  subscribe: () => Promise<boolean>;
  unsubscribe: () => Promise<boolean>;
}

const PushNotificationsContext = createContext({} as PushNotificationsContextProps);

export const getNotificationBrowserStatus = (): PushNotificationBrowserStatus => {
  if (
    !("PushManager" in window) ||
    !("serviceWorker" in navigator) ||
    !("showNotification" in ServiceWorkerRegistration.prototype)
  ) {
    console.warn("Push notifications are not supported by this browser");
    return "incompatible";
  }

  if (Notification.permission === "denied") {
    console.warn("Notifications are denied by the user");
    return "denied";
  }

  if (Notification.permission === "granted") {
    return "granted";
  }

  return "compatible";
};

export const usePushSubscription = () => useContext(PushNotificationsContext);

export const PushNotificationsProvider: React.FC<ChildrenProps> = ({ children }) => {
  const { push_notification_public_key: pushNotificationPublicKey } = useInstanceSettings();
  const isModuleActive = !!pushNotificationPublicKey;
  const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean | "loading">("loading");
  const [status, setStatus] = useState<PushNotificationBrowserStatus | "active" | "loading">("loading");
  const snackbar = useSnackbar();
  const handleMessage = (type: VariantType, message?: string) => {
    snackbar(type, message);
  };

  useEffect(() => {
    const browserStatus = getNotificationBrowserStatus();
    (async () => {
      if (!isModuleActive) {
        return;
      }
      const activeSubscription = await requestGetAllUsersSubscriptions();
      if (activeSubscription) {
        setHasActiveSubscription(true);
      } else {
        setHasActiveSubscription(false);
      }
      if (browserStatus === "granted") {
        if (await getActiveSubscription()) {
          setStatus("active");
        } else {
          setStatus("granted");
        }
      } else {
        setStatus(browserStatus);
      }
    })();
  }, []);

  const subscribe = async (): Promise<boolean> => {
    setStatus("loading");
    const notificationGranted = (await Notification.requestPermission()) === "granted";
    if (notificationGranted) {
      const activeSubscription = await getActiveSubscription();
      if (activeSubscription) {
        setStatus("active");
        return true;
      }

      const registration = await navigator.serviceWorker.register("/serviceWorker.js");
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(pushNotificationPublicKey),
      });

      if (!subscription) {
        setStatus(getNotificationBrowserStatus());
        return false;
      }

      const result = await requestCreateSubscription(subscription, handleMessage);
      if (result) {
        setStatus("active");
      } else {
        setStatus(getNotificationBrowserStatus());
      }

      return !!result;
    }
    setStatus("denied");
    const activeSubscriptions = await requestGetAllUsersSubscriptions();
    if (activeSubscriptions) {
      setHasActiveSubscription(true);
    } else {
      setHasActiveSubscription(false);
    }
    return false;
  };

  const unsubscribe = async (): Promise<boolean> => {
    setStatus("loading");
    const activeSubscription = await getActiveSubscription();

    if (!activeSubscription) {
      return true;
    }

    const result = await requestDeleteSubscription(activeSubscription, handleMessage);
    if (result) {
      const unsubscribed = await activeSubscription.unsubscribe();
      if (unsubscribed) {
        setStatus(getNotificationBrowserStatus());
        return true;
      }
      setStatus("granted");
      return true;
    }
    const activeSubscriptions = await requestGetAllUsersSubscriptions();
    if (activeSubscriptions) {
      setHasActiveSubscription(true);
    } else {
      setHasActiveSubscription(false);
    }
    return false;
  };

  const context: PushNotificationsContextProps = {
    status,
    isModuleActive,
    hasActiveSubscription,
    subscribe,
    unsubscribe,
  };

  return <PushNotificationsContext.Provider value={context}>{children}</PushNotificationsContext.Provider>;
};
