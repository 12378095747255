import { Callback, Nullable } from "@sinch/types";
import { ChildrenProps, noop } from "@sinch/utils";
import React, { createContext, ReactElement, useContext, useMemo } from "react";

interface DialogContextState {
  close: Callback;

  isDialog: boolean;
}

const DialogContext = createContext<Nullable<DialogContextState>>(null);

const emptyContext: DialogContextState = {
  close: noop,
  isDialog: false,
};

export function useDialogContext(): DialogContextState {
  const context = useContext(DialogContext);
  return context ?? emptyContext;
}

interface DialogContextProviderProps extends ChildrenProps {
  onClose: Callback;
}

export function DialogContextProvider({
  children,
  onClose,
}: DialogContextProviderProps): ReactElement {
  const context = useMemo(
    () => ({
      close: onClose,
      isDialog: true,
    }),
    [onClose]
  );

  return (
    <DialogContext.Provider value={context}>{children}</DialogContext.Provider>
  );
}
