/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { useData } from "@sinch/core";
import { Fk, selectPosition, selectPositionAttendance, selectShift, Shift, sortPosition, Transport, WorkerRole } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Card } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement, useState } from "react";
import { requestShiftAttendanceView } from "../api";
import { CardShiftHeader } from "../components";
import { PositionEditDialog, TransportGroupEditDialog } from "../PositionEditDialog";
import { PositionAttendances } from "./PositionAttendances";
import { TransportAttendances } from "./TransportAttendances";

interface WorkTabProps {
  shift: Fk<Shift>;
}

function WorkTab({ shift }: WorkTabProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);

  const positions = sortPosition(selectEntity(selectPosition({ shift })))
    .map((shift) => shift.id)
    .filter((id) => !isEmpty(selectEntity(selectPositionAttendance({ position: id }, "id"))));

  const [groupEdit, setGroupEdit] = useState<number[] | null>(null);

  return (
    <Card
      gutters="none"
      guttersVertical="none"
      header={<CardShiftHeader action={() => setGroupEdit(positions)} title={t("Shift.closing.timesheet")} />}
    >
      {positions.map((position) => (
        <PositionAttendances key={position} openDialog={() => setGroupEdit([position])} position={position} />
      ))}

      {groupEdit && <PositionEditDialog onClose={() => setGroupEdit(null)} positions={groupEdit} />}
    </Card>
  );
}

interface TransportTabProps {
  shift: Fk<Shift>;

  transport: Fk<Transport>;
}

function TransportTab({ transport, shift }: TransportTabProps): ReactElement {
  const [groupEdit, setGroupEdit] = useState<number[] | null>(null);

  const { selectEntity } = useData(requestShiftAttendanceView);

  const positions = sortPosition(selectEntity(selectPosition({ shift })))
    .map((shift) => shift.id)
    .filter((id) => !isEmpty(selectEntity(selectPositionAttendance({ position: id }, "id"))));

  return (
    <Card
      gutters="none"
      guttersVertical="none"
      header={<CardShiftHeader action={() => setGroupEdit(positions)} title={t("display.transport")} />}
    >
      {positions.map((position) => (
        <TransportAttendances
          key={position}
          openDialogAction={() => setGroupEdit([position])}
          position={position}
          transport={transport}
        />
      ))}
      {groupEdit && (
        <TransportGroupEditDialog onClose={() => setGroupEdit(null)} positions={groupEdit} transport={transport} />
      )}
    </Card>
  );
}

export enum AttendanceFormMobileViews {
  Work = "shiftClosing.attendanceForm.work",
  TransportThere = "shiftClosing.attendanceForm.transportThere",
  TransportBack = "shiftClosing.attendanceForm.transportBack",
}

interface AttendanceFormMobileProps {
  shift: Fk<Shift>;
  selectedView?: AttendanceFormMobileViews;
}

export function AttendanceFormMobile({ shift, selectedView }: AttendanceFormMobileProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);

  const transport = selectEntity(selectShift(shift, "transport"));
  const { from = null, to = null } = transport ?? {};
  return (
    <>
      {selectedView === AttendanceFormMobileViews.Work && <WorkTab shift={shift} />}
      {selectedView === AttendanceFormMobileViews.TransportThere && to && <TransportTab shift={shift} transport={to} />}
      {selectedView === AttendanceFormMobileViews.TransportBack && from && (
        <TransportTab shift={shift} transport={from} />
      )}
    </>
  );
}
