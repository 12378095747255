import { Grid as MuiGrid, makeStyles } from "@material-ui/core";
import { mdiAlert } from "@mdi/js";

import { useBusinessRules, useData } from "@sinch/core";
import {
  Id,
  selectPayoutMethod,
  selectWalletTransaction,
  WalletTransaction,
  WalletTransactionType,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Box, IconText, Link, List, RowsGrid, SpacingBox, Text } from "@sinch/ui";
import { subMonths } from "date-fns";
import { map, pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
/* eslint-disable-next-line import/no-internal-modules -- todo: extract shared func */
import { SearchParamsPagination } from "../../components";
import { requestWalletTransactionList } from "./api";
import { WalletChip, WalletTransactionHeader } from "./components";

const useStyles = makeStyles((theme) => ({
  archived: {
    backgroundColor: theme.palette.background.default,
    "& .SinchMobileWalletTransaction-content": {
      textDecoration: "line-through",
    },
  },
  normal: {
    textDecoration: "none",
  },
  greyBg: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface TransactionsSearchParams {
  from?: Date;

  to?: Date;

  type?: WalletTransactionType;
}

function applyDefaults(
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  params: TransactionsSearchParams
): TransactionsSearchParams {
  return {
    from: subMonths(new Date(), 1),
    to: new Date(),
    type: WalletTransactionType.Wage,
  };
}

export function MobileWalletTransactions(): ReactElement {
  const { curr, dt } = useFormat();
  const styles = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { selectEntity, selectResult } = useData(requestWalletTransactionList);
  const { walletTransactionIds } = selectResult();
  const { allowWorkerPaymentRequest } = useBusinessRules();

  const sortedWalletTransactionIds: number[] = pipe<WalletTransaction[], WalletTransaction[], number[], number[]>(
    sortBy(prop("accountingDate")),
    pluck("id"),
    reverse
  )(selectEntity(selectWalletTransaction(walletTransactionIds)));

  return (
    <RowsGrid>
      <SpacingBox className={styles.greyBg} mx={-1} px={1}>
        <WalletTransactionHeader />
      </SpacingBox>
      <List
        center
        getAction={(id: Id) => () => {
          const { payout, position } = selectEntity(selectWalletTransaction(id));
          if (payout && allowWorkerPaymentRequest) {
            navigate({ search, hash: `#/payout/${payout}` });
            return;
          }

          if (position) {
            navigate({ search, hash: `#/detail/${id}` });
          }
        }}
        getBody={(id: Id) => {
          const {
            accountingDate,
            description,
            tags,
            type,
            value,
            archived,
            position,
            payout,
            payoutMethod,
          } = selectEntity(selectWalletTransaction(id));

          // todo: consider extracting to currency formatter
          const valueSign = value > 0 ? "+" : "-";
          const valueText = curr(value > 0 ? value : -value);

          return (
            <>
              {archived && (
                <IconText color="neutral" icon={mdiAlert}>
                  {t<string>("Wallet.display.archived")}
                </IconText>
              )}
              <MuiGrid className="SinchMobileWalletTransaction-content" container direction="column">
                <MuiGrid item>
                  <Box display="flex" justifyContent="space-between">
                    <Text bold>{`${valueSign} ${valueText}`}</Text>
                    <Text>{dt(accountingDate)}</Text>
                  </Box>
                </MuiGrid>
                <MuiGrid container direction="column" item>
                  <MuiGrid item>
                    <Text separator=" - ">
                      <Text bold>{WalletTransactionType[type]}</Text>
                      <Text bold>
                        {payoutMethod
                          ? selectEntity(selectPayoutMethod(payoutMethod, "name"))
                          : t("Wallet.display.unassignedType")}
                      </Text>
                    </Text>
                  </MuiGrid>
                  {description && (
                    <MuiGrid item>
                      <Text>{description}</Text>
                    </MuiGrid>
                  )}
                </MuiGrid>
                <MuiGrid container>
                  <Text separator={" "}>
                    {map(
                      (tag) => (
                        <WalletChip key={tag.name} tag={tag} />
                      ),
                      tags || []
                    )}
                  </Text>
                </MuiGrid>
              </MuiGrid>
              {(position || payout) && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link onClick={() => navigate({ search, hash: `#/detail/${id}` })}>{t("showDetail")}</Link>
              )}
            </>
          );
        }}
        getStyles={(id) => {
          const { archived } = selectEntity(selectWalletTransaction(id));
          return archived ? styles.archived : "";
        }}
        items={sortedWalletTransactionIds}
      />
      <SearchParamsPagination />
    </RowsGrid>
  );
}
