import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import {
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  Collapse as MuiCollapse,
  makeStyles,
} from "@material-ui/core";
import MuiTypography from "@material-ui/core/Typography";

import { Callback, Color, IconId } from "@sinch/types";
import { ChildrenProps, useToggleState } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement, ReactNode, useCallback } from "react";
import { Badge, BadgeProps } from "../Badge";
import { Icon } from "../Icon";
import { Text } from "../Text";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    display: "flex",
    padding: theme.spacing(2),
    "& > svg:first-child, & > .MuiBadge-root:first-child": {
      marginRight: theme.spacing(1),
    },
    "& > svg:last-child": {
      marginLeft: theme.spacing(1),
    },
  },
  dense: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  large: {
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  asButton: {
    textTransform: "uppercase",
  },
}));

export interface ExpandableAreaProps extends ChildrenProps {
  badge?: BadgeProps;

  icon?: IconId;

  large?: boolean;

  onClick?: Callback;

  open?: boolean;

  title: ReactNode;
  titleOpened?: string;
  dense?: boolean;

  style?: "button" | "initial";
  color?: Color;
}

export function ExpandableArea({
  children,
  icon,
  badge,
  large,
  onClick: onClickProp,
  open: openProp,
  title,
  titleOpened,
  dense,
  style,
  color,
}: ExpandableAreaProps): ReactElement {
  const styles = useStyles();

  const [openState, toggleOpen] = useToggleState(openProp);
  const onClick = useCallback(() => {
    toggleOpen();
    onClickProp?.();
  }, [onClickProp, toggleOpen]);

  const open = openProp ?? openState;

  return (
    <>
      <MuiCardActionArea
        className={clsx(styles.actionArea, large && styles.large, dense && styles.dense)}
        onClick={onClick}
      >
        {icon &&
          (badge ? (
            <Badge {...badge}>
              <Icon icon={icon} />
            </Badge>
          ) : (
            <Icon icon={icon} />
          ))}
        <MuiTypography
          className={clsx(styles.title, style === "button" && styles.asButton)}
          variant={large ? "h3" : "h4"}
        >
          <Text bold={style === "button"} color={color}>
            {openState ? titleOpened || title : title}
          </Text>
        </MuiTypography>
        <Icon color={color} icon={open ? mdiChevronUp : mdiChevronDown} />
      </MuiCardActionArea>
      <MuiCollapse in={open} unmountOnExit>
        {children}
      </MuiCollapse>
    </>
  );
}

export function ExpandableCard({ children, ...props }: ExpandableAreaProps): ReactElement {
  return (
    <MuiCard elevation={0}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ExpandableArea large {...props}>
        {children}
      </ExpandableArea>
    </MuiCard>
  );
}
