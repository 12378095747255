import { ComponentsProps } from "@material-ui/core/styles/props";

/**
 * keep classes alphabetically sorted
 */
export const props: ComponentsProps = {
  /**
   * disable ripple
   */
  MuiButtonBase: {
    disableRipple: true,
  },
};
