export enum PenaltyType {
  /**
   * {@link Worker} has arrived late and is penalized.
   */
  Late = 1,

  /**
   * {@link Worker} has been absent and is penalized.
   */
  Absent = 2,
}
