/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 */
import { Blocker, Transition } from "history";
import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext, useInRouterContext } from "react-router-dom";

function invariant(cond: boolean, message: string): void {
  if (!cond) throw new Error(message);
}

export function useBlocker(blocker: Blocker, when = true): void {
  invariant(
    useInRouterContext(),
    // router loaded. We can help them understand how to avoid that.
    `useBlocker() may be used only in the context of a <Router> component.`
  );

  const navigator = useContext(UNSAFE_NavigationContext).navigator as Navigator;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
