import { Chip, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { WalletTag } from "@sinch/entity";
import React from "react";

const useStyles = makeStyles((theme) => ({
  walletChip: {
    backgroundColor: ({ color }: { color: string }) => color,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
}));

export function WalletChip({ tag }: { tag: WalletTag }) {
  const { name, description, color } = tag;
  const styles = useStyles({ color });

  const chip = <Chip className={styles.walletChip} label={name} size="small" />;

  return description ? <Tooltip title={description}>{chip}</Tooltip> : chip;
}
