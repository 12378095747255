import { useErrorHandler } from "@sinch/ui";
import { useMounted } from "@sinch/utils";
import { useMemo } from "react";
import { RequestCreator, RequestOf, ResponseOf, withResponseDefaults } from "../contract";
import { ResponseHandler, responseHandlerSequence } from "../handler";
import { useBackend } from "./BackendProvider";
import { useHandler } from "./HandlerProvider";
import { useOnlineStatus } from "./OnlineStatusProvider";
import { parseResponse } from "./parseResponse";
import { useProgress } from "./ProgressProvider";
import { useRequestScope } from "./RequestScopeProvider";

const repeatTimeoutSeconds = 15;

/**
 * todo: consider supporting { onResolve, onSuccess, onError }
 *  for better custom response handling if strict public api not required
 */
export type RequestDispatcher = <TCreator extends RequestCreator>(
  request: RequestOf<TCreator>,
  onResponse?: ResponseHandler<TCreator>
) => Promise<ResponseOf<TCreator>>;

/**
 * todo: use stale-while-revalidate swr library
 *  (handles request caching, sharing and dedupe, and also auto refresh)
 *
 * todo: can we solve this without explicitly passing response handler?
 *  - maybe just simplify to notify callback `onDone?: Callback`?
 *  - consider proxying public export without onResponse param for strict api
 *
 * todo: extract env config enable/disable response handler logging
 */
export function useRequest(): RequestDispatcher {
  const mounted = useMounted();
  const parentHandler = useHandler();
  const { isOnline, setStatus } = useOnlineStatus();
  const { resolveRequest } = useBackend();
  const { setInProgress } = useProgress();
  const { setError } = useErrorHandler();
  const scope = useRequestScope();

  const handleDispatch = async <TCreator extends RequestCreator>(
    request: RequestOf<TCreator>,
    onResponse?: ResponseHandler<TCreator>
  ) => {
    setInProgress(true);

    const response = withResponseDefaults<ResponseOf<TCreator>>(parseResponse(request, await resolveRequest(request)));

    const handler = onResponse ? responseHandlerSequence([onResponse, parentHandler]) : parentHandler;

    mounted(() => handler({ request, response }));

    setInProgress(false);
    return response;
  };

  // @ts-ignore
  return useMemo(
    () =>
      function dispatch<TCreator extends RequestCreator>(
        request: RequestOf<TCreator>,
        onResponse?: ResponseHandler<TCreator>
      ) {
        if (!isOnline) {
          return false;
        }

        const requestKey = `${scope}/${request.key}`;
        async function tryRequest(resolve) {
          try {
            resolve(await handleDispatch({ ...request, key: requestKey }, onResponse));
            setStatus(true);
          } catch (error) {
            if (typeof error === "object" && error?.message === "Network Error") {
              setStatus(false, repeatTimeoutSeconds);
              setTimeout(() => {
                tryRequest(resolve);
              }, repeatTimeoutSeconds * 1000);
            } else {
              setError(error);
              resolve(error);
            }
          }
        }
        return new Promise((resolve) => {
          tryRequest(resolve);
        });
      },
    [mounted, resolveRequest, parentHandler]
  );
}
