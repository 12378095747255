import { mdiCheck, mdiAlertCircle, mdiTimerSandEmpty } from "@mdi/js";
import { useData } from "@sinch/core";
import { Fk, Position, PositionQ } from "@sinch/entity";
import { Icon } from "@sinch/ui";
import React, { ReactElement } from "react";

export function PositionClosingStatusIcons({ id }: { id: Fk<Position> }): ReactElement {
  // @ts-expect-error
  const { queryEntity } = useData();

  if (queryEntity(PositionQ.isStatusCrewbossClosed(id))) {
    return <Icon color="success" icon={mdiCheck} size="small" />;
  }
  if (queryEntity(PositionQ.isStatusUnclosedLate(id))) {
    return <Icon color="error" icon={mdiAlertCircle} size="small" />;
  }
  return <Icon color="warning" icon={mdiTimerSandEmpty} size="small" />;
}
