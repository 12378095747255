import { Box } from "@material-ui/core";
import { mdiAccountStar, mdiBriefcase, mdiCheckAll } from "@mdi/js";
import { useData } from "@sinch/core";
import { Position, PositionStatus, selectPosition } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Paper, TabsNavigation } from "@sinch/ui";
import { head, isEmpty, keys, length, reject, values } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "./api";
import { PositionConfirmation } from "./components";
import { FeedbackPositions } from "./FeedbackPositions";
import { FinishedPositions } from "./FinishedPositions";
import { IncomingPositions } from "./IncomingPositions";

export function AttendanceTabs(): ReactElement {
  const { selectResult, selectEntity } = useData(requestDashboardAttendanceList);
  const {
    incomingPositionIds,
    feedbackPositionIds,
    positionIdsToClose,
    incomingAppointmentIds,
    applicantPositionIds,
    confirmationPositionIds,
    confirmationApplicantPositionIds,
  } = selectResult();

  const incoming = length(incomingPositionIds);
  const feedback = length(feedbackPositionIds);
  const finished = positionIdsToClose ? length(positionIdsToClose) : 0;
  const appointments = incomingAppointmentIds ? length(incomingAppointmentIds) : 0;

  const unclosedShifts = positionIdsToClose
    ? reject(({ status }: Position) => status >= PositionStatus.CrewbossClosed)(
        selectEntity(selectPosition(positionIdsToClose))
      )
    : [];
  const unclosed = length(unclosedShifts);

  const showFinished = finished > 0;

  return (
    <>
      {(isNotNilOrEmpty(confirmationPositionIds) || isNotNilOrEmpty(confirmationApplicantPositionIds)) && (
        <Box pb={2}>
          <PositionConfirmation
            isApplicantConfirmation={isEmpty(confirmationPositionIds) && !isEmpty(confirmationApplicantPositionIds)}
            positionId={head(confirmationPositionIds) ?? head(values(confirmationApplicantPositionIds)[0] ?? [])}
            size={length([...confirmationPositionIds, ...keys(confirmationApplicantPositionIds)])}
          />
        </Box>
      )}
      <Paper>
        <TabsNavigation
          replace
          tabs={[
            {
              element: <IncomingPositions />,
              icon: mdiBriefcase,
              label: `${t("Dashboard.myAgenda")} (${incoming + applicantPositionIds.length + appointments})`, // Přihlášené práce
              path: "incoming",
              badge:
                length(confirmationPositionIds) + length(keys(confirmationApplicantPositionIds)) > 0
                  ? {
                      color: "warning",
                      badgeContent: length(confirmationPositionIds) + length(keys(confirmationApplicantPositionIds)),
                    }
                  : null,
            },
            ...(showFinished
              ? [
                  {
                    element: <FinishedPositions />,
                    icon: mdiCheckAll,
                    label: `${t("Dashboard.shiftsToClose")} (${finished})`, // Práce k uzavření
                    path: "finished",
                    badge: unclosed > 0 ? { color: "error", badgeContent: unclosed } : null,
                  },
                ]
              : []),
            {
              element: <FeedbackPositions />,
              icon: mdiAccountStar,
              label: `${t("Feedback.titlePlural")} (${feedback})`, // Feedbacky
              path: "feedback",
            },
          ]}
        />
      </Paper>
    </>
  );
}
