import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import { Fk, Rating, RatingContainer } from "@sinch/entity";

export const requestProfileRatingList = defineRequest("Ratings/index")
  .withEmptyParams()
  .withMeta<PagingParams>()
  .withResponse<{
    ratings: Fk<Rating>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<RatingContainer>()
  .withCreator((paging: PagingParams) => ({
    meta: paging,
  }));
