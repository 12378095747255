import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiGrid from "@material-ui/core/Grid";
import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { mdiClose } from "@mdi/js";

import clsx from "clsx";
import React, { ReactElement } from "react";
import { Icon } from "../../Icon";
import { Header } from "../../Text";
import { DialogActions } from "./DialogActions";
import { DialogInternalProps } from "./DialogProps";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  hidden: {
    display: "none",
  },
  content: {
    paddingTop: 0,
  },
  reducedMargin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paperFullWidthReducedMargin: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
}));

export function ModalDialog({
  actions,
  children,
  onClose,
  open,
  subtitle,
  title,
  width = "md",
  hideClose,
  className,
  slots,
  disableBackdropClick,
  disableEscapeKeyDown,
}: Omit<DialogInternalProps, "hashKey">): ReactElement {
  const styles = useStyles();

  return (
    <MuiDialog
      classes={{ paper: styles.reducedMargin, paperFullWidth: styles.paperFullWidthReducedMargin }}
      className={className}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullWidth
      maxWidth={width}
      onClose={onClose}
      open={open}
    >
      <MuiDialogTitle
        className={clsx(styles.title, {
          [styles.hidden]: !title && !subtitle,
        })}
        disableTypography
      >
        <MuiGrid container direction="column" justify="center">
          {title && (
            <MuiGrid item>
              <Header>{title}</Header>
            </MuiGrid>
          )}
          {subtitle && (
            <MuiGrid item>
              <Header level={2}>{subtitle}</Header>
            </MuiGrid>
          )}
        </MuiGrid>
        {!hideClose && (
          <MuiIconButton edge="end" onClick={onClose}>
            <Icon icon={mdiClose} />
          </MuiIconButton>
        )}
      </MuiDialogTitle>
      <MuiDialogContent dividers>{children}</MuiDialogContent>
      {!slots?.actionArea && actions && <DialogActions actions={actions} onClose={onClose} />}
      {slots?.actionArea && <MuiDialogActions>{slots?.actionArea}</MuiDialogActions>}
    </MuiDialog>
  );
}
