import { HashRoute } from "@sinch/core";
import React, { ReactElement } from "react";
import { WalletDetail } from "./WalletDetail";

export function WalletDetailContainer(): ReactElement {
  return (
    <HashRoute path="detail/:id">
      <WalletDetail id="" />
    </HashRoute>
  );
}
