import { omit, pick } from "ramda";

type PickSchemaProps<TSchema> = <TObj extends Partial<TSchema>>(
  obj: TObj
) => Pick<TObj, keyof TSchema>;

type OmitSchemaProps<TSchema> = <TObj extends Partial<TSchema>>(
  obj: TObj
) => Omit<TObj, keyof TSchema>;

interface Schema<TSchema> {
  props: (keyof TSchema)[];

  pick: PickSchemaProps<TSchema>;

  omit: OmitSchemaProps<TSchema>;
}

export function defineSchema<TSchema>(
  keys: (string & keyof TSchema)[]
): Schema<TSchema> {
  return {
    props: keys,
    pick: pick(keys) as PickSchemaProps<TSchema>,
    omit: omit(keys) as OmitSchemaProps<TSchema>,
  };
}
