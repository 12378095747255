import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { isElement } from "react-dom/test-utils";

const ProgressContext = createCheckedContext<{
  inProgress: boolean;
  setInProgress: Dispatch<SetStateAction<boolean>>;
}>("Progress");
export const { use: useProgress } = ProgressContext;

export interface ProgressProviderProps extends ChildrenProps {
  progress?: ReactElement;
}

export function ProgressProvider({ children, progress: Progress }: ProgressProviderProps): ReactElement {
  const [inProgress, setInProgress] = useState(false);

  const progressEl = useMemo(() => isElement(Progress) && inProgress && Progress, [inProgress, Progress]);

  useEffect(
    () => () => {
      setInProgress(false); // This worked for me
    },
    []
  );

  return <ProgressContext value={{ inProgress, setInProgress }}>{[progressEl, children]}</ProgressContext>;
}
