import { useFiles } from "@sinch/core";
import { Callback, Consumer } from "@sinch/types";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useMemo } from "react";
import { FormAction, FormStatus } from "./FormStatus";
import { FormValues } from "./Values";

interface FormControl {
  reset: Callback;

  submit: Callback;
}

interface FormState {
  messages: string[];

  status: FormStatus;
}

const FormContext = createCheckedContext<FormControl & FormState>("FormContext");
export const { use: useFormContext } = FormContext;

interface FormContextProviderProps extends FormState, ChildrenProps {
  updateStatus: Consumer<FormAction>;
}

export function FormContextProvider<TValues extends FormValues>({
  children,
  messages,
  status,
  updateStatus,
}: FormContextProviderProps): ReactElement {
  let currentUploadStatus = null;
  try {
    const { status: uploadStatus } = useFiles();
    currentUploadStatus = uploadStatus;
  } catch (e) {
    currentUploadStatus = null;
  }
  const { isValid, resetForm: reset, submitForm: submit } = useFormikContext<TValues>();

  useEffect(() => {
    updateStatus({ invalid: !isValid });
  }, [isValid, updateStatus]);

  useEffect(() => {
    updateStatus({ uploading: currentUploadStatus === "uploading" });
  }, [updateStatus, currentUploadStatus]);

  const context = useMemo(
    () => ({
      messages,
      reset,
      status,
      submit,
    }),
    [messages, reset, status, submit]
  );

  return <FormContext value={context}>{children}</FormContext>;
}
