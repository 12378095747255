import { ListItem as MuiListItem } from "@material-ui/core";
import MuiList from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { mdiBugOutline } from "@mdi/js";
import { HashRoute, PluginSlot, Suggestions } from "@sinch/core";
import { ScrollHistoryProvider } from "@sinch/core/context/ScrollHistory";
import { t } from "@sinch/intl";
import { useDialog } from "@sinch/ui";
import { MainMenu } from "@sinch/ui/ApplicationLayout/MainMenu";
import { useMenuContext } from "@sinch/ui/ApplicationLayout/MainMenu/context";
import { MenuDivider } from "@sinch/ui/ApplicationLayout/MainMenu/Divider";
import { MenuIcon } from "@sinch/ui/ApplicationLayout/MainMenu/MenuIcon";
import { Title } from "@sinch/ui/ApplicationLayout/MainMenu/Title";
import { Tooltip } from "@sinch/ui/ApplicationLayout/MainMenu/Tooltip";
import { TitleBar, TitleBarProvider } from "@sinch/ui/ApplicationLayout/TitleBar";
import { ToolbarPortal, ToolbarPortalProvider } from "@sinch/ui/ApplicationLayout/Toolbar";
import { ViewContentSlot } from "@sinch/ui/ApplicationLayout/ViewContentSlot";
import { useMobileLayout } from "@sinch/ui/UiProvider";
import { ChildrenProps, loadPersisted, useConditionalEffect, usePersist, useToggleStateObj } from "@sinch/utils";
import React, { Children, ReactElement, ReactNode, useEffect } from "react";

import { NotificationIndicator } from "../plugins";
import { ReferralDialogContainer } from "../Profile";
import { staticMenu } from "../staticMenu";

const lsKeyMenuOpen = "MainMenu.open";

const useStyles = makeStyles(({ mixins: { component, onLarge } }) => ({
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: component.titleBar.small.height,
    ...onLarge({
      flexGrow: 1,
      marginTop: component.titleBar.large.height,
    }),
  },

  root: {
    display: "flex",
    minHeight: "100vh",
  },
}));

interface ApplicationLayoutProps extends ChildrenProps {
  plugins?: Partial<Record<PluginSlot, ReactNode[]>>;

  rootElement: HTMLElement;
}

/**
 * Main Application layout
 */
export function ApplicationLayout({ children, plugins = {}, rootElement }: ApplicationLayoutProps): ReactElement {
  const styles = useStyles();
  const mobile = useMobileLayout();

  useEffect(() => {
    rootElement.classList.add(styles.root);
    return () => rootElement.classList.remove(styles.root);
  }, [rootElement, styles.root]);

  // TODO: better way to save persistent state
  const [menuOpen, { toggle: toggleMenu, unset: closeMenu }] = useToggleStateObj(
    () => loadPersisted(lsKeyMenuOpen) ?? true
  );
  usePersist(lsKeyMenuOpen, menuOpen);

  // close menu when layout changed from desktop to mobile
  useConditionalEffect(mobile, closeMenu);

  // reset scroll
  // useScrollResetOnNavigation();

  return (
    <TitleBarProvider>
      <ScrollHistoryProvider>
        <TitleBar
          menuOpen={menuOpen}
          notificationIndicator={<NotificationIndicator />}
          onMenuClick={toggleMenu}
          plugins={plugins.titleBar}
        />
        <MainMenu bottomMenu={<BottomMenuPart />} onClose={closeMenu} opened={menuOpen} staticMenu={staticMenu()} />
        <main className={styles.content}>
          <ToolbarPortalProvider>
            <ToolbarPortal position="top" />
            <ViewContentSlot>{children}</ViewContentSlot>
            <ToolbarPortal position="bottom" />
            {Children.toArray(plugins.contentFooter)}
          </ToolbarPortalProvider>
        </main>
        {/* Make referral dialog global, should be in worker folder instead of ui folder, this layout should not be in ui too anyway */}
        <HashRoute path="referral">
          <ReferralDialogContainer />
        </HashRoute>
      </ScrollHistoryProvider>
    </TitleBarProvider>
  );
}

function BottomMenuPart(): ReactElement | null {
  const { mobile } = useMenuContext();

  const dialog = useDialog();

  if (mobile) return null;

  return (
    <>
      <MuiList>
        <MenuDivider />

        <Tooltip title={t("Application.reportIssue")}>
          <MuiListItem
            button
            onClick={() => {
              dialog.open();
            }}
          >
            <MenuIcon icon={mdiBugOutline} />
            <Title title={t("Suggestion.suggestionTitle")} />
          </MuiListItem>
        </Tooltip>
      </MuiList>
      {dialog.state && <Suggestions dialog={dialog} />}
    </>
  );
}
