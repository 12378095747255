/* eslint-disable import/no-extraneous-dependencies */
import { Link as MuiLink } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { has, mergeDeepRight, T } from "ramda";
import React, {
  ComponentProps,
  ReactElement,
  useCallback,
  useRef,
} from "react";
import { useHref } from "react-router";
import { Action, resolveAction } from "../../actions";
import {
  DataTableDisplay,
  DataTableProps,
  DataTableRowLogic,
  DataTableRowProps,
} from "../types";

/* eslint-disable react/jsx-props-no-spreading */

const useStyles = makeStyles({
  clickable: {
    cursor: "pointer",
  },
  hidden: {
    display: "none",
  },
});

export function createClickableRow<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>(
  action: (el: TData) => Action,
  enabled: (el: TData) => boolean = T
): DataTableRowLogic<TData, TColumn, TDisplay> {
  return function ClickableRow({
    table,
    row,
  }: DataTableRowProps<TData, TColumn, TDisplay>): ReactElement {
    const styles = useStyles();

    const { data, display, logic } = table;
    const { Row } = display;
    const RowLogic = logic;

    const current = data[row];

    const tableNext = (mergeDeepRight(table, {
      display: {
        // todo: can we support generics to type display component props?
        // todo: is there any pattern to simplify defining props in this way?
        Row: ({ className, ...props }: ComponentProps<TDisplay["Row"]>) => {
          const isRouterAction = has("to", action(current));
          // @ts-ignore-error
          const pathname = useHref(isRouterAction ? action(current).to : "");
          /**
           * todo: this is a hack, see Dialog for more info
           */
          const linkRef = useRef<HTMLLinkElement>();
          const onClick = useCallback(
            (e) => {
              if (e.ctrlKey && isRouterAction) {
                window.open(pathname, "_blank");
              } else {
                linkRef.current?.click();
              }
            },
            [linkRef]
          );
          const onMouseUp = useCallback(
            (e) => {
              if (e.button === 1 && isRouterAction) {
                window.open(pathname, "_blank");
              }
            },
            [linkRef]
          );

          const linkProps = resolveAction(action(current));

          return (
            <>
              <Row
                {...props}
                className={clsx(className, styles.clickable)}
                hover
                onClick={onClick}
                onMouseUp={onMouseUp}
              />
              <tr className={styles.hidden}>
                <td>
                  {/* @ts-expect-error */}
                  <MuiLink {...linkProps} ref={linkRef}>
                    Click
                  </MuiLink>
                </td>
              </tr>
            </>
          );
        },
      },
    }) as unknown) as DataTableProps<TData, TColumn, TDisplay>;

    return <RowLogic row={row} table={enabled(current) ? tableNext : table} />;
  };
}
