import { Box, Grid as MuiGrid } from "@material-ui/core";
import { t } from "@sinch/intl";
import { Action, Button, Header, useMobileLayout, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";

interface ShiftHeaderProps {
  title: string;
  action?: Action;
}

export const CardShiftHeader = ({ action, title }: ShiftHeaderProps): ReactElement => {
  const [, inner] = useSpacing();
  const mobile = useMobileLayout();

  return (
    <Box p={mobile ? inner : 0} width="100%">
      <MuiGrid container wrap="nowrap">
        <MuiGrid item xs>
          <Header level={2}>{title}</Header>
        </MuiGrid>
        <MuiGrid item xs="auto">
          <Button action={action} color="info" size="small" stretch variant="text">
            {t("Shift.closing.bulkEditAll")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};
