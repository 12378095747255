import { CalendarEntryType } from "@sinch/entity";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import clsx from "clsx";
import { eachDayOfInterval, getDayOfYear } from "date-fns";
import { flatten, groupBy, identity, includes, is, map, pipe, prop, sortBy, uniq } from "ramda";

/**
 * Return props for event container since it can't be customized directly.
 */
export function makeEventPropGetter(styles, warning = false) {
  return ({ type }) => ({
    className: clsx(styles.root, styles[type], {
      [styles.warning]: warning,
    }),
  });
}

const getDaysOfEntries = pipe(map(eachDayOfInterval), flatten, map(getDayOfYear), uniq, sortBy(identity));

export function makeDayPropGetter(styles, events) {
  const eventsByType = groupBy(prop("type"), events);

  const unavailableDays = is(Array, eventsByType[CalendarEntryType.Unavailable])
    ? getDaysOfEntries(eventsByType[CalendarEntryType.Unavailable])
    : [];
  const availableDays = is(Array, eventsByType[CalendarEntryType.Available])
    ? getDaysOfEntries(eventsByType[CalendarEntryType.Available])
    : [];

  const makeProps = (type) => ({
    className: styles[type],
  });

  return (date) => {
    const dayOfYear = getDayOfYear(date);
    if (includes(dayOfYear, unavailableDays)) {
      return makeProps(CalendarEntryType.Unavailable);
    }
    if (includes(dayOfYear, availableDays)) {
      return makeProps(CalendarEntryType.Available);
    }
    return {};
  };
}
