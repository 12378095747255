import { Box } from "@material-ui/core";
import { mdiCalendarToday, mdiCar, mdiCrown, mdiHumanGreetingVariant, mdiInformation, mdiMapMarker } from "@mdi/js";
import { useBusinessRules, useData } from "@sinch/core";
import { PositionQ, selectLocation, selectPosition, selectShift, selectTransport, selectUser } from "@sinch/entity";
import { Format, t, useFormat } from "@sinch/intl";
import { B, DataGrid, DataGridItem, Divider, Grid, PhoneLink, Text, Timeline, WrapWords } from "@sinch/ui";
import { Icon } from "@sinch/ui/Icon/Icon";
import { isArray, isValue } from "@sinch/utils";
import { subMinutes } from "date-fns";
import { always, apply, both, cond, filter, has, head, ifElse, map, pipe, prop, props, T, when } from "ramda";
import React from "react";
import { requestPositionDetail } from "../Position";

function getApplicantStatus() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectResult } = useData(requestPositionDetail);
  const { signedAsApplicant, isApplicantRequiredConfirmation } = selectResult();

  return signedAsApplicant
    ? {
        icon: mdiHumanGreetingVariant,
        content: (
          <b>
            {isApplicantRequiredConfirmation
              ? t("Position.youHaveBeenSelectedAsApplicant")
              : t("Position.signedAsApplicantNoticeTitle")}
          </b>
        ),
        color: "colorApplicant",
      }
    : null;
}

function getCrewbossStatus(query, position) {
  const isCrewboss = query(PositionQ.User.isCrewboss(position));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { crewbossName: crewboss } = useBusinessRules();

  return isCrewboss
    ? {
        icon: mdiCrown,
        content: <b>{t("Position.youAreCrewboss", { crewboss })}</b>,
        color: "colorCrewboss",
      }
    : null;
}

function getDate(query, position) {
  const startTime = query(selectPosition(position, "startTime"));

  return {
    icon: mdiCalendarToday,
    content: <Format dt={startTime} />,
  };
}

function getTime(query, position) {
  const { startTime, endTime, workBreak, shift, meetingTimeInterval } = query(selectPosition(position));
  const { transport } = query(selectShift(shift));
  const transportElement = (element) => prop(element, transport) && query(selectTransport(prop(element, transport)));
  const transportTo = transportElement("to");
  const transportFrom = transportElement("from");

  let workTimelineItem = [{ startTime, endTime, title: t("work") }];
  if (workBreak) {
    workTimelineItem = [
      { startTime, endTime: workBreak.startTime, title: t("work") },
      {
        startTime: workBreak.startTime,
        endTime: workBreak.endTime,
        title: t("break"),
      },
      { startTime: workBreak.endTime, endTime, title: t("work") },
    ];
  }

  const timeline = [
    transport &&
      transport.to &&
      transportTo.meetingTimeInterval !== 0 && {
        startTime: subMinutes(transportTo.startTime, transportTo.meetingTimeInterval),
        title: t("Transport.meetup"),
      },
    transport && transport.to && { ...transportTo, title: t("Transport.there") },
    {
      startTime: subMinutes(startTime, meetingTimeInterval),
      title: t("Position.meetupBefore"),
    },
    ...workTimelineItem,
    transport &&
      transport.from &&
      transportFrom.meetingTimeInterval !== 0 && {
        startTime: subMinutes(transportFrom.startTime, transportFrom.meetingTimeInterval),
        title: t("Transport.meetup"),
      },
    transport &&
      transport.from && {
        ...transportFrom,
        title: t("Transport.back"),
      },
  ];

  return {
    content: <Timeline steps={timeline} />,
  };
}

function getLocation(query, position) {
  const location = query(selectPosition(position, "location"));
  const { address } = query(selectLocation(location));

  return {
    icon: mdiMapMarker,
    content: [<WrapWords>{address}</WrapWords>],
  };
}

/**
 * todo: can Position have no contact?
 *  -> simplify code if there will always be at least one
 */
function getContact(query, position) {
  const contacts = query(selectPosition(position, "contacts"));
  const contact = when(isArray, head, contacts);

  if (!contact) {
    return null;
  }

  const getDisplayValue = ifElse(
    has("userId"),
    pipe(
      // contact is User reference
      prop("userId"),
      selectUser,
      query,
      props(["name", "phone"]),
      apply((name, phone) => [name, <PhoneLink phone={phone}>{phone}</PhoneLink>])
    ),
    // contact is just plain text
    ifElse(
      has("text"),
      prop("text"),
      pipe(
        props(["name", "phone"]),
        apply((name, phone) => [name, <PhoneLink phone={phone}>{phone}</PhoneLink>])
      )
    )
  );

  return {
    icon: <Icon color="colorCrewboss" icon={mdiCrown} size="small" />,
    content: getDisplayValue(contact) || t("Position.noContact"),
  };
}

/**
 * todo: hide label completely when no transport?
 */
function getTransport(query, position) {
  const shift = query(selectPosition(position, "shift"));
  const transport = query(selectShift(shift, "transport"));

  const label = cond([
    [both(has("to"), has("from")), always(t("Transport.thereAndBack"))], // Transport there and back
    [has("to"), always(t("Transport.there"))], // Transport there
    [has("from"), always(t("Transport.back"))], // Transport back
    [T, always(t("Transport.noTransport"))],
  ]);

  return {
    icon: /* ICON */ mdiCar,
    content: label(transport),
  };
}

const cardContentSelectors = [
  getApplicantStatus,
  getCrewbossStatus,
  getDate,
  getTime,
  getLocation,
  getTransport,
  getContact,
  /* */
];

export function PositionDetailContent({ position }) {
  const { dt } = useFormat();
  const { queryEntity, selectResult } = useData(requestPositionDetail);

  const { signedAsApplicant, isApplicantRequiredConfirmation } = selectResult();

  const resolve = map((selector) => selector(queryEntity, position));

  const data = filter(isValue, resolve(cardContentSelectors));

  const canSignOff = queryEntity(PositionQ.User.canSignOff(position));
  const signOffTime = queryEntity(PositionQ.User.getSignOffTime(position));

  const signOffTimeBold = <B>{dt.full(signOffTime)}</B>;
  return (
    <>
      {isApplicantRequiredConfirmation && (
        <Box pb={1}>
          <DataGridItem content={t("Position.youHaveBeenSelectedAsApplicant")} icon={mdiInformation} />
        </Box>
      )}
      <DataGrid data={data} small />
      {!signedAsApplicant && (
        <>
          <Divider />
          {canSignOff ? (
            <Text>
              {t("Position.signOffLimitNotice", {
                signOffTimeBold,
              })}
            </Text>
          ) : (
            <Grid spacing="inner" vertical>
              <Text>
                <B>{t("Position.cannotSignOffNotice")}</B>
                <B>
                  {t("Position.signOffLimitAfter", {
                    time: signOffTimeBold,
                  })}
                </B>
              </Text>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
