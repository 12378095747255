import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";
import { Backend } from "../contract";

const BackendContext = createCheckedContext<Backend>("Backend");
export const { use: useBackend } = BackendContext;

export interface BackendProviderProps extends ChildrenProps {
  /**
   * Backend instance for this Provider and its child component tree.
   */
  backend: Backend;
}

export function BackendProvider({
  backend,
  children,
}: BackendProviderProps): ReactElement {
  return <BackendContext value={backend}>{children}</BackendContext>;
}
