import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  MenuItem as MuiMenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "@sinch/intl";
import { Key, SelectFieldOption } from "@sinch/types";
import { Icon, Text } from "@sinch/ui";
import { indexByProp } from "@sinch/utils";
import clsx from "clsx";
import { values } from "ramda";
import { isEmptyString } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";

export const indexByValue = indexByProp("value");

export interface SelectInputProps extends ManagedInputProps {
  hideOptionLabel?: boolean;

  hideValueLabel?: boolean;

  nullable?: boolean;

  options: SelectFieldOption[];
}

/**
 * todo: select input should support options with boolean values
 *
 * todo: consider if empty string should be converted to null value
 *  and vice versa when using nullable input variant
 */
export function SelectInput(props: SelectInputProps): ReactElement {
  const [baseProps] = useFormField<Key, SelectInputProps>(props);

  const { error, note, onChange, value } = baseProps;

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return useMemo(() => <SelectInputBase {...baseProps} />, [error, note, onChange, value, baseProps]);
}

const useStyles = makeStyles((theme) => ({
  denseIcon: {
    marginLeft: theme.spacing(1),
  },
  inputValue: {
    display: "flex",
    alignItems: "center",
    height: "16.625px", // force same height as regular text input
  },
  selectNoLabel: {
    "& > .MuiSelect-select": {
      paddingRight: theme.spacing(1),
    },
  },
  ellipsis: {
    "&  [class*=MuiListItemText]": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& . ": {
      maxWidth: "calc(100% - 30px)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  placeholderEllipsis: {
    "& .MuiTypography-root": {
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}));

interface SelectInputOptionProps extends Pick<SelectFieldOption, "color" | "icon" | "label" | "secondaryLabel"> {
  hideLabel?: boolean;
}

/**
 * todo: render item without icon as item text but on menu item
 *  with gutters disabled
 */
function SelectInputOption({ color, hideLabel, icon, label, secondaryLabel }: SelectInputOptionProps): ReactElement {
  const styles = useStyles();

  return (
    <>
      {icon && (
        <MuiListItemIcon>
          <Icon color={color} icon={icon} />
        </MuiListItemIcon>
      )}
      <MuiListItemText className={styles.denseIcon} primary={hideLabel ? "" : label} secondary={secondaryLabel} />
    </>
  );
}

export function SelectInputBase({
  dense,
  disabled,
  error,
  hideOptionLabel,
  hideValueLabel,
  label,
  name,
  note,
  nullable,
  onChange,
  onInvalid,
  options: optionsList,
  placeholder,
  value,
  required,
  SelectDisplayProps,
}: BaseInputProps<Key, SelectInputProps>): ReactElement {
  const styles = useStyles();
  const indexedOptions = useMemo(() => indexByValue(optionsList), [optionsList]);
  const nullElement = useMemo(() => <Text color="neutral">{placeholder ?? t("SelectInput.noneValue")}</Text>, [
    placeholder,
  ]);

  const renderValue = useCallback(
    (opt) => (
      <div className={styles.inputValue}>
        {isEmptyString(opt) ? (
          nullElement
        ) : (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <SelectInputOption hideLabel={hideValueLabel} {...indexedOptions[opt]} />
        )}
      </div>
    ),
    [hideValueLabel, nullElement, indexedOptions, styles.inputValue]
  );

  return (
    <MuiFormControl
      disabled={disabled}
      error={error}
      fullWidth
      margin={dense ? "dense" : "normal"}
      placeholder={placeholder}
      required={required}
      variant="outlined"
    >
      <MuiInputLabel required={required} shrink>
        {label}
      </MuiInputLabel>
      <MuiSelect
        className={clsx(styles.placeholderEllipsis, hideValueLabel ? styles.selectNoLabel : "", styles.ellipsis)}
        displayEmpty
        input={<MuiOutlinedInput label={label} notched />}
        MenuProps={{ variant: "menu" }}
        name={name}
        onChange={onChange}
        onInvalid={onInvalid}
        renderValue={renderValue}
        required={required}
        SelectDisplayProps={SelectDisplayProps}
        value={value}
      >
        {nullable && <MuiMenuItem value="">{nullElement}</MuiMenuItem>}
        {values(optionsList).map(
          ({
            color,
            disabled: optionDisabled,
            icon,
            label: optionLabel,
            value: optionValue,
            secondaryLabel,
            ...optionProps
          }) => (
            <MuiMenuItem key={optionValue} disabled={optionDisabled} value={optionValue} {...optionProps}>
              <SelectInputOption
                color={color}
                hideLabel={hideOptionLabel}
                icon={icon}
                label={optionLabel}
                secondaryLabel={secondaryLabel}
              />
            </MuiMenuItem>
          )
        )}
      </MuiSelect>
      {note && <MuiFormHelperText>{note}</MuiFormHelperText>}
    </MuiFormControl>
  );
}
