import MuiGrid from "@material-ui/core/Grid";
import { useData } from "@sinch/core";
import { ContactTypeLabel, PositionContact, selectPosition, selectUser } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { Avatar, Grid, PhoneLink, StripBase, Text, useSpacing } from "@sinch/ui";
import { isNil } from "ramda";
import React, { ReactElement } from "react";
import { requestPositionDetail } from "./api";

interface ContactCardProps {
  contact: PositionContact;
}

function ContactCard({
  contact: { text, phone: phoneClient, name: nameClient, type, userId },
}: ContactCardProps): ReactElement {
  const { selectEntity } = useData(requestPositionDetail);

  const { avatar, name, phone } = userId
    ? selectEntity(selectUser(userId))
    : { name: nameClient, avatar: undefined, phone: phoneClient };

  return (
    <StripBase
      label={<Avatar file={{ file: avatar as string, variant: "thumbnail_200x200" }} withPreview="thumbnail_500x500" />}
    >
      <Grid spacing="data">
        <Text bold>{ContactTypeLabel()[type]}</Text>
        <Text>{name}</Text>
        {phone && (
          <Text>
            <PhoneLink phone={phone} />
          </Text>
        )}
        {text && <Text>{text}</Text>}
      </Grid>
    </StripBase>
  );
}

export function PositionDetailContacts(): Nullable<ReactElement> {
  const { selectEntity, selectResult } = useData(requestPositionDetail);
  const { positionId } = selectResult();
  const [outer] = useSpacing();

  const { contacts } = selectEntity(selectPosition(positionId));
  if (isNil(contacts) || !contacts.length) {
    return null;
  }

  return (
    <MuiGrid container direction="column" spacing={outer}>
      {contacts.map((contact, index) => (
        <MuiGrid key={index} item>
          <ContactCard key={contact.userId ?? contact.text} contact={contact} />
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
