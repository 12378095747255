import {
  createEntitySelector,
  EntityContainer,
  EntitySelector,
} from "../entity";
import { Snippet } from "./Snippet";

export type SnippetContainer = EntityContainer<Snippet, "Snippet">;

export const selectSnippet: EntitySelector<Snippet> = createEntitySelector<
  Snippet,
  SnippetContainer
>("Snippet");
