import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { Callback } from "@sinch/types";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}));

interface ToolbarButtonProps extends ChildrenProps {
  onClick?: Callback;
  disabled?: boolean;
}

export function ToolbarButton({ onClick, disabled, children, ...props }: ToolbarButtonProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiIconButton className={styles.button} color="inherit" disabled={disabled} onClick={onClick} {...props}>
      {children}
    </MuiIconButton>
  );
}
