import * as Sentry from "@sentry/react";
import { User } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export const initErrorMonitor = ({ errorMonitorParams, revisionInfo }) => {
  const { ERROR_MONITOR_API_KEY, ERROR_MONITOR_DEBUG, ERROR_MONITOR_ENV } = errorMonitorParams;
  const { version } = revisionInfo;
  if (!ERROR_MONITOR_API_KEY) {
    return;
  }
  Sentry.init({
    dsn: ERROR_MONITOR_API_KEY,
    debug: ERROR_MONITOR_DEBUG || false,
    environment: ERROR_MONITOR_ENV || "development",
    release: version || undefined,
    ignoreErrors: [
      "Non-Error promise rejection captured", // caused by some browser plugins (LastPass, Outlook link check, etc...)
      // Random plugins/extensions
      'Could not load "stats".', // Caused by adblocks which blocking google maps
    ],
    denyUrls: [
      /maps\.googleapis\.com\//i, // block errors in google maps api (caused by adblocks or other plugins)
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 0,
  });
};

export const setUser = (user: User) => {
  Sentry.setUser(user);
};
