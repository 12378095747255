import { Box, lighten, makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useCurrentUser, useData } from "@sinch/core";
import { PositionAttendance, selectPositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import { DialogButton, Identifier, InformationDialog, routerLink, Text } from "@sinch/ui";
import { head, includes, values } from "ramda";
import React, { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AttendanceConfirmButtons } from "../../components/AttendanceConfirmButtons";
import { requestPositionDetail } from "../../Position";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { PositionDetailContent } from "../PositionDetailContent";

const useStyles = makeStyles(({ palette }) => ({
  confirmationHeader: {
    "& .MuiDialogTitle-root": {
      backgroundColor: `${lighten(palette.warning.main, 0.9)} !important`,
    },
  },
}));

export function CalendarConfirmationDetailDialog(): ReactElement {
  const { id: workerId } = useCurrentUser();
  const styles = useStyles();
  const { selectResult, selectEntity } = useData(requestPositionDetail);
  const { positionId, isApplicantRequiredConfirmation, groupedApplicantConfirmations } = selectResult();
  const groupedPositionIds =
    head(
      selectEntity<PositionAttendance["groupedPositionIds"]>(
        selectPositionAttendance({ position: positionId, worker: workerId }, "groupedPositionIds")
      ) ?? []
    ) ?? [];
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const isConfirmationRequiredInGroupedPosition =
    values(groupedApplicantConfirmations).some((ids) => ids.includes(positionId) && ids.length > 1) ||
    (includes(positionId, groupedPositionIds) && groupedPositionIds.length > 1);

  return (
    <InformationDialog
      className={styles.confirmationHeader}
      denyFullscreen
      slots={{
        actionArea: (
          <MuiGrid alignItems="center" container spacing={2}>
            <MuiGrid item xs>
              <DialogButton action={routerLink(`/position/${positionId}`)} label={t("action.detail")} />
            </MuiGrid>
            <MuiGrid item xs="auto">
              {isConfirmationRequiredInGroupedPosition ? (
                <Text>{t("Position.groupedPositionConfirmation")}</Text>
              ) : (
                <AttendanceConfirmButtons
                  confirmationType={isApplicantRequiredConfirmation ? "applicant" : "position"}
                  onSuccess={() => navigate({ pathname, search })}
                  positionId={positionId}
                />
              )}
            </MuiGrid>
          </MuiGrid>
        ),
      }}
      title={
        <Box display="flex" flexDirection="column">
          <Text small>
            {t("Position.title")} <Identifier entity="position" id={positionId} />
          </Text>
          <PositionReference id={positionId} name noId title />
        </Box>
      }
      width="xs"
    >
      <PositionDetailContent position={positionId} />
    </InformationDialog>
  );
}
