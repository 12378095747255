import { Tooltip } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useHashNavigate } from "@sinch/core";
import { Color, IconId } from "@sinch/types";
import clsx from "clsx";
import { isNil } from "ramda";
import React, { ReactElement } from "react";
import { Icon } from "../Icon";
import { createPaletteStyles, IdentifierColorKey, identifiersStylesCreator } from "../paletteStyles";
import { Text } from "../Text";

const paletteStyles = createPaletteStyles(({ main, contrastText }) => ({
  color: fade(main, 0.5),

  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: main,
    color: contrastText,
  },
}));

const identifierStyles = identifiersStylesCreator(({ color, backgroundColor }) => ({
  color: fade(backgroundColor, 0.5),

  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor,
    color,
  },
}));

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    width: "100%",
  },
  toggleButton: {
    flexGrow: 1,
    maxWidth: "100%",
    flexBasis: 0,
    backgroundColor: theme.palette.background.paper,

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      marginLeft: 0,
    },
    "& .MuiToggleButton-label": {
      display: "flex",
      flexDirection: "column",
    },
  },
  ...paletteStyles(theme),
  ...identifierStyles(theme),
}));

export interface ToggleButtonProps<T> {
  value: T;
  label: string | number;
  color: Color | IdentifierColorKey;
  icon: IconId;
  tooltip?: string;
}

export interface ToggleButtonSwitchesProps<T> {
  items: ToggleButtonProps<T>[];
  value: T;
  onSelect?: (value: T) => void;
  fullWidth?: boolean;
}

export function ToggleButtonSwitches<T extends string | number>({
  items,
  value,
  onSelect = () => {},
  fullWidth,
}: ToggleButtonSwitchesProps<T>): ReactElement {
  const styles = useStyles();
  const hashNavigate = useHashNavigate();

  const [selection, setSelected] = React.useState<T>(value);

  const handleSelection = (event: React.MouseEvent<HTMLElement>, newSelection: T) => {
    if (isNil(newSelection)) {
      return;
    }
    setSelected(newSelection);
    onSelect(newSelection);
    hashNavigate(`${newSelection}`);
  };

  return (
    <ToggleButtonGroup
      className={clsx(fullWidth && styles.buttonGroup)}
      exclusive
      onChange={handleSelection}
      size="small"
      value={selection}
    >
      {items.map(({ value: val, icon, label, color, tooltip }: ToggleButtonProps<T>) => {
        const toggleButton = (
          <ToggleButton
            key={val}
            className={clsx(styles.toggleButton, color && styles[color])}
            selected={val === selection}
            value={val}
          >
            <Icon icon={icon} />
            <Text>{label}</Text>
          </ToggleButton>
        );
        return tooltip ? <Tooltip title={tooltip}>{toggleButton}</Tooltip> : toggleButton;
      })}
    </ToggleButtonGroup>
  );
}
