import { useCurrentUser, useData, useRequest } from "@sinch/core";
import { AppointmentQ, selectAppointment, selectAppointmentAttendance, sOr } from "@sinch/entity";
import { SelectInputBase } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Card, ConfirmationPanel, Grid, GridLayout, Text } from "@sinch/ui";
import { isDefined, parseDate } from "@sinch/utils";
import { isBefore } from "date-fns";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useState } from "react";
import { requestAppointmentAttendanceJoin, requestAppointmentAttendanceLeave, requestAppointmentDetail } from "./api";

function SignOffForm(): ReactElement {
  const { dt } = useFormat();
  const dispatch = useRequest();

  const { selectResult, queryEntity } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const signOffTime = <b>{dt.full(queryEntity(AppointmentQ.getSignOffTime(appointmentId)))}</b>;

  return (
    <ConfirmationPanel
      acceptText={t("Appointment.label.signOff") /* Chci se odhlásit z této události */}
      buttonText={t("Appointment.action.signOff") /* ODHLÁSIT SE Z UDÁLOSTI */}
      color="error"
      onConfirm={() => dispatch(requestAppointmentAttendanceLeave(appointmentId))}
    >
      <Text>
        {t("Appointment.signOffLimitNotice", {
          signOffTime, // Z této události se lze odhlásit do {{signOffTime}}.
        })}
      </Text>
    </ConfirmationPanel>
  );
}

function SignUpForm(): Nullable<ReactElement> {
  const { dt } = useFormat();
  const dispatch = useRequest();

  const { selectResult, selectEntity, queryEntity } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const { attendanceTimeSlots } = selectEntity(selectAppointment(appointmentId));

  if (queryEntity(sOr(AppointmentQ.isFull, AppointmentQ.User.hasConflicting)(appointmentId))) return null;
  const selectTime = isDefined(attendanceTimeSlots);

  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const [readyToConfirm, setReadyToConfirm] = useState(!selectTime);
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const [chosenTime, setChosenTime] = useState("");

  const getTimeSelectInput = () => {
    const selectTimeOptions = selectTime
      ? attendanceTimeSlots?.map((item) => ({
          label: dt.time(item),
          value: item,
        }))
      : [];

    /* @ts-expect-error */
    const onChangeTime = ({ target: { value } }) => {
      setReadyToConfirm(true);
      setChosenTime(value);
    };
    return (
      <SelectInputBase
        name="attendanceTime"
        onChange={onChangeTime}
        /* @ts-expect-error */
        options={selectTimeOptions}
        placeholder={t("Appointment.selectTimeSlot")}
        value={chosenTime}
      />
    );
  };

  return (
    <ConfirmationPanel
      acceptText={t("Appointment.label.singUp")}
      buttonText={t("Appointment.action.signUp")}
      color={selectTime ? "secondary" : "success"}
      confirmationContent={selectTime ? getTimeSelectInput() : undefined}
      onConfirm={() => dispatch(requestAppointmentAttendanceJoin(appointmentId, parseDate(chosenTime)))}
      readyToConfirm={readyToConfirm}
    >
      {selectTime && (
        <Text>
          {
            t(
              "Appointment.selectTimeNotice"
            ) /* Pro přihlášení na tuto událost je nutné vybrat čas, kdy na událost dorazíte. */
          }
        </Text>
      )}
    </ConfirmationPanel>
  );
}

interface CannotChangeProps {
  attending: boolean;

  interval: number;
}

function CannotChange({ attending, interval }: CannotChangeProps): ReactElement {
  const intervalBold = <b>{t("hoursCount", { hours: interval })}</b>;
  if (attending) {
    return (
      <Card>
        <Grid spacing="data" vertical>
          <Text bold>{t("Appointment.cannotSignOffNotice") /* Z této události se již nelze odhlásit */}</Text>
          <Text>
            {
              t("Appointment.signOffLimitAfter", {
                interval: intervalBold,
              }) /* Limit pro odhlášení byl <b>{interval} h</b> před začátkem události. */
            }
          </Text>
          <Text>
            {
              t(
                "Appointment.contactAdminAtProblemNotice"
              ) /* Pokud máš závažné důvody proč na událost nemůžeš dorazit, kontaktuj, prosím, administrátory. */
            }
          </Text>
        </Grid>
      </Card>
    );
  }

  return (
    <Card>
      <Grid spacing="data" vertical>
        <Text bold>{t("Appointment.cannotSignUpNotice") /* Na tuto událost se již nelze přihlásit. */}</Text>
        <Text>
          {t(
            "Appointment.signUpLimitNotice", // Limit pro přihlášení byl <b>{interval} h</b> před začátkem události.
            { interval: intervalBold }
          )}
        </Text>
      </Grid>
    </Card>
  );
}

function CanNotAttend() {
  return (
    <GridLayout.Item>
      <Card>
        <Text bold color="error">
          {t("Appointment.cannotSignUpNotice") /* Na tuto událost se nelze přihlásit. */}
        </Text>
      </Card>
    </GridLayout.Item>
  );
}

export function AppointmentDetailAcceptForm(): Nullable<ReactElement> {
  const currentUser = useCurrentUser();

  const { selectResult, selectEntity, queryEntity } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const { startTime, signOffTimeInterval, signInTimeInterval } = selectEntity(selectAppointment(appointmentId));

  if (isBefore(startTime, new Date())) {
    return null;
  }

  const attendances = selectEntity(selectAppointmentAttendance({ worker: currentUser.id, appointment: appointmentId }));
  const attending = isNotNilOrEmpty(attendances);

  const cannotAttend =
    !attending &&
    (queryEntity(sOr(AppointmentQ.isFull, AppointmentQ.User.hasConflicting)(appointmentId)) ||
      isBefore(parseDate(startTime), new Date()));

  const signInTime = queryEntity(AppointmentQ.getSignInTime(appointmentId));
  const signOffTime = queryEntity(AppointmentQ.getSignOffTime(appointmentId));
  const cannotSignIn = isBefore(signInTime, new Date());
  const cannotSignOff = isBefore(signOffTime, new Date());

  if (cannotAttend) {
    return (
      <GridLayout.Item>
        <CanNotAttend />
      </GridLayout.Item>
    );
  }

  if (cannotSignIn) {
    return (
      <GridLayout.Item>
        <CannotChange attending={false} interval={signInTimeInterval} />
      </GridLayout.Item>
    );
  }
  if (attending && cannotSignOff) {
    return (
      <GridLayout.Item>
        <CannotChange attending interval={signOffTimeInterval} />
      </GridLayout.Item>
    );
  }
  return <GridLayout.Item>{attending ? <SignOffForm /> : <SignUpForm />}</GridLayout.Item>;
}
