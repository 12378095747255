import { InputAdornment } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { mdiClockOutline } from "@mdi/js";

import { useData } from "@sinch/core";
import { CalendarEntry, CalendarEntryType, Fk, selectCalendarEntry } from "@sinch/entity";
import {
  DateInput,
  Form,
  FormDependant,
  SelectDateTimeInput,
  SelectInput,
  SelectTimeInput,
  SubmitButton,
  SwitchInput,
  TextInput,
} from "@sinch/forms";
import { t } from "@sinch/intl";
import { Icon, InformationDialog, useSpacing } from "@sinch/ui";
import { parseDate, serializeDate, serializeTime } from "@sinch/utils";
import { isNil, pick } from "ramda";
import { isNaN } from "ramda-adjunct";
import React, { ReactElement, useMemo } from "react";
import { requestCalendar, requestCalendarAvailabilitySet } from "./api";

const availabilityType = () => [
  {
    value: CalendarEntryType.Available,
    label: t("Calendar.available"),
    "data-cy": "availableOption",
  },
  {
    value: CalendarEntryType.Unavailable,
    label: t("Calendar.notAvailable"),
  },
];

const mergeDateTime = (date: Date, time: Date): Date => parseDate(`${serializeDate(date)}T${serializeTime(time)}`);

interface AvailabilityFormState {
  dailyEndTime: Date;

  dailyStartTime: Date;

  endTime: Date;

  entry?: Fk<CalendarEntry>;

  repeat: boolean;

  startTime: Date;

  title: string;

  type: CalendarEntryType;
}

interface AvailabilityFormProps {
  endTime?: string;

  id?: Fk<CalendarEntry>;

  startTime?: string;
}

export function AvailabilityForm({
  endTime: selectionEndTime,
  id: selectedEntry,
  startTime: selectionStartTime,
}: AvailabilityFormProps): ReactElement {
  const { selectEntity } = useData(requestCalendar);
  const [, , data] = useSpacing();

  const initialValues: AvailabilityFormState = useMemo(() => {
    const entityData = selectedEntry
      ? pick(["endTime", "startTime", "title", "type"], selectEntity(selectCalendarEntry(selectedEntry)) || {})
      : {};

    const now = new Date();
    const endTime = parseDate(selectionEndTime) ?? now;
    const startTime = parseDate(selectionStartTime) ?? now;

    return {
      dailyEndTime: endTime,
      dailyStartTime: startTime,
      endTime,
      entry: selectedEntry,
      repeat: false,
      startTime,
      title: "",
      type: CalendarEntryType.Unavailable,
      ...entityData,
    };
  }, [selectEntity, selectedEntry, selectionEndTime, selectionStartTime]);

  return (
    <InformationDialog
      title={
        selectedEntry
          ? t("Calendar.editAvailability") // Upravit dostupnost
          : t("Calendar.createAvailability") // Vytvořit záznam o dostupnosti
      }
      width="xs"
    >
      <Form<AvailabilityFormState>
        initialValues={initialValues}
        submitRequest={({ dailyEndTime, dailyStartTime, endTime, entry, repeat, startTime, title, type }) =>
          requestCalendarAvailabilitySet({
            endTime: repeat ? mergeDateTime(endTime, dailyEndTime) : endTime,
            entry,
            repeat,
            startTime: repeat ? mergeDateTime(startTime, dailyStartTime) : startTime,
            title,
            type,
          })
        }
      >
        <MuiGrid container direction="column" spacing={data}>
          <MuiGrid item>
            <SelectInput
              label={t("Calendar.form.availabilityType") /* Typ události */}
              name="type"
              options={availabilityType()}
              SelectDisplayProps={{ "data-cy": "availabilityDropdownOption" }}
            />
          </MuiGrid>
          <MuiGrid item>
            <FormDependant<AvailabilityFormState>
              render={({ entry, repeat }) => (
                <>
                  {(isNaN(entry) || isNil(entry)) && (
                    <SwitchInput
                      label={t("Calendar.repeatedEvent") /* Opakovaná událost */}
                      name="repeat"
                      note={t("Calendar.form.repeatDescription")}
                    />
                  )}
                  <MuiGrid container direction="column">
                    {((!isNaN(entry) && !isNil(entry)) || !repeat) && (
                      <MuiGrid container item spacing={data}>
                        <MuiGrid item xs>
                          <SelectDateTimeInput
                            dimmed={{ date: false }}
                            inputProps={{ "data-cy": "dates" }}
                            label={t("fromDate")}
                            name="startTime"
                          />
                        </MuiGrid>
                        <MuiGrid item xs>
                          <SelectDateTimeInput
                            dimmed={{ date: false }}
                            inputProps={{ "data-cy": "dates" }}
                            label={t("toDate")}
                            name="endTime"
                          />
                        </MuiGrid>
                      </MuiGrid>
                    )}
                    {(isNaN(entry) || isNil(entry)) && repeat && (
                      <>
                        <MuiGrid container item spacing={data}>
                          <MuiGrid item xs>
                            <DateInput label={t("repeatFrom")} name="startTime" />
                          </MuiGrid>
                          <MuiGrid item xs>
                            <DateInput label={t("repeatTo")} name="endTime" />
                          </MuiGrid>
                        </MuiGrid>
                        <MuiGrid container item spacing={data}>
                          <MuiGrid item xs>
                            <SelectTimeInput
                              adornment={
                                <InputAdornment position="end">
                                  <Icon icon={mdiClockOutline} />
                                </InputAdornment>
                              }
                              dense={false}
                              label={t("dailyFrom")}
                              name="dailyStartTime"
                            />
                          </MuiGrid>
                          <MuiGrid item xs>
                            <SelectTimeInput
                              adornment={
                                <InputAdornment position="end">
                                  <Icon icon={mdiClockOutline} />
                                </InputAdornment>
                              }
                              dense={false}
                              label={t("dailyTo")}
                              name="dailyEndTime"
                            />
                          </MuiGrid>
                        </MuiGrid>
                      </>
                    )}
                  </MuiGrid>
                </>
              )}
            />
          </MuiGrid>
          <MuiGrid item>
            <TextInput label={t("note")} name="title" />
          </MuiGrid>
          <MuiGrid item>
            <SubmitButton data-cy="confirmButton" label={t("action.confirm")} />
          </MuiGrid>
        </MuiGrid>
      </Form>
    </InformationDialog>
  );
}
