import { Box, CardContent as MuiCardContent, Grid as MuiGrid } from "@material-ui/core";
import { mdiFilterPlusOutline, mdiTimerSandEmpty, mdiWallet } from "@mdi/js";

// eslint-disable-next-line import/no-internal-modules
// @ts-expect-error
import walletIllustration from "@sinch/assets/img/wallet.png";
import { useBusinessRules, useData } from "@sinch/core";
import { selectPayout, selectPayoutMethod } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Button,
  Card,
  Center,
  GridLayout,
  Header,
  IconText,
  Image,
  routerLink,
  Spacer,
  Text,
  useSpacing,
} from "@sinch/ui";
import { isNil, map } from "ramda";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { ReimbursementRequest } from "../Reimbursement";
import { requestWalletStatus } from "./api";
import { CreatePayoutButton } from "./CreatePayoutButton";

interface WalletHeaderProps {
  balance: number;
}

export function WalletHeader({ balance }: WalletHeaderProps): ReactElement {
  const { curr } = useFormat();
  const { search } = useLocation();
  const [, inner, data] = useSpacing();
  const { selectResult, selectEntity } = useData(requestWalletStatus);
  const { payoutDistribution } = selectResult();

  const { allowWorkerPaymentRequest, allowWorkerReimbursementRequest } = useBusinessRules();

  return (
    <GridLayout.Item>
      <Card>
        <MuiCardContent key="main">
          <Box textAlign="center">
            <MuiGrid alignItems="stretch" container direction="column" spacing={inner}>
              <MuiGrid item>
                <Image src={walletIllustration} />
              </MuiGrid>
              <MuiGrid item>
                <Center>
                  <Text>{t("Wallet.display.walletBalance")}</Text>
                </Center>
                <Center>
                  <Header>{curr(balance)}</Header>
                </Center>
              </MuiGrid>
              {allowWorkerReimbursementRequest && (
                <MuiGrid item>
                  <ReimbursementRequest variant="outlined" />
                </MuiGrid>
              )}
            </MuiGrid>
          </Box>
        </MuiCardContent>
        {payoutDistribution.length > 0 &&
          map(({ amount, method, payout }) => {
            const { name = null } = method ? selectEntity(selectPayoutMethod(method)) : {};
            const grossAmount = payout ? selectEntity(selectPayout(payout, "grossAmount")) : 0;
            return (
              <MuiCardContent key={method}>
                <MuiGrid container direction="column" spacing={data}>
                  <MuiGrid container item justify="space-between" wrap="nowrap">
                    <MuiGrid item>
                      <Box alignItems="center" display="flex" height="100%">
                        <Header level={2}>{name || t("Wallet.display.unassignedType")}</Header>
                      </Box>
                    </MuiGrid>
                    <MuiGrid item>
                      <Button
                        action={routerLink({
                          pathname: "/wallet/transaction",
                          search: `payoutMethod=${method || -1}`,
                        })}
                        icon={mdiFilterPlusOutline}
                        tooltip={t("Wallet.filterByThisPayoutMethod")}
                        variant="text"
                      />
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid item>
                    <IconText icon={mdiWallet}>{curr(amount)}</IconText>
                  </MuiGrid>
                  {method &&
                    allowWorkerPaymentRequest &&
                    (grossAmount !== 0 ? (
                      <MuiGrid item>
                        <Text bold>
                          <IconText icon={mdiTimerSandEmpty}>
                            <Spacer separator=" ">
                              {t("Wallet.display.payoutRequested")}
                              {curr(grossAmount)}
                            </Spacer>
                          </IconText>
                        </Text>
                        <Box display="flex" justifyContent="flex-end" ml={-data} pt={data}>
                          <Box pr={data}>
                            <Button
                              action={routerLink({ search, hash: `#/payout/${payout}` })}
                              color="info"
                              size="small"
                              stretch
                              variant="contained"
                            >
                              {t("action.edit")}
                            </Button>
                          </Box>
                          <Button
                            action={routerLink({ search, hash: `#/payout/${payout}/cancel` })}
                            color="error"
                            size="small"
                            stretch
                            variant="outlined"
                          >
                            {t("action.cancel")}
                          </Button>
                        </Box>
                      </MuiGrid>
                    ) : (
                      <MuiGrid item>
                        <Box ml={-data} pt={data} textAlign="end">
                          <CreatePayoutButton
                            color="info"
                            disabled={isNil(method)}
                            method={method}
                            stretch
                            variant="contained"
                          />
                        </Box>
                      </MuiGrid>
                    ))}
                </MuiGrid>
              </MuiCardContent>
            );
          }, payoutDistribution)}
      </Card>
    </GridLayout.Item>
  );
}
