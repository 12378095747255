import React, { ReactElement, ReactNode } from "react";
import { Text } from "../Text";

/**
 * Color text by value, negative value color text with red, neutral value (zero or none) do not color and positive value color text green
 */
export function NumberStatusColor({
  value,
  children,
}: {
  value: number | undefined;
  children: ReactNode;
}): ReactElement {
  if (!value) {
    return <Text>{children}</Text>;
  }
  if (value < 0) {
    return <Text color="error">{children}</Text>;
  }
  if (value > 0) {
    return <Text color="success">{children}</Text>;
  }
  return <Text>{children}</Text>;
}
