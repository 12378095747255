import { useBusinessRules } from "@sinch/core";
import { t } from "@sinch/intl";

/**
 * Type of referenced {@link PositionContact contact}.
 */
export enum ContactType {
  /**
   * {@link PositionContact} to client employee responsible for actual
   * {@link Shift}.
   */
  Client = 0,

  /**
   * {@link PositionContact} to supervisor of actual {@link Shift}.
   */
  Supervisor = 1,

  /**
   * {@link PositionContact} to crewboss of actual {@link Shift}.
   */
  Crewboss = 2,
}

export const ContactTypeLabel = () => {
  const { crewbossName } = useBusinessRules();
  return {
    [ContactType.Client]: t("ContactType.client"),
    [ContactType.Supervisor]: t("ContactType.supervisor"),
    [ContactType.Crewboss]: crewbossName,
  };
};
