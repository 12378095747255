import { Box, Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectProfession } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Header, Icon, Identifier, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";
import { CheckboxInputBase } from "../../../../forms";
import { WorkerRoleOptions } from "../../Closing";
import { requestPresenceView } from "../api";

interface PositionHeaderProps {
  position: Fk<Position>;
  selected: boolean;
  onSelectAll: (checked: boolean) => void;
  count: number;
}

export const PositionHeader = ({ position, selected, onSelectAll, count }: PositionHeaderProps): ReactElement => {
  const { dt } = useFormat();
  const [, inner, data] = useSpacing();
  const { selectEntity } = useData(requestPresenceView);
  const { id, role, startTime, endTime, profession, title } = selectEntity(selectPosition(position));
  const { name: professionName } = selectEntity(selectProfession(profession));
  return (
    <Box pt={inner} px={inner} width="100%">
      <MuiGrid container direction="column">
        <MuiGrid container item spacing={data} wrap="nowrap">
          <MuiGrid item xs="auto">
            <Icon color={WorkerRoleOptions()[role].color} icon={WorkerRoleOptions()[role].icon} />
          </MuiGrid>
          <MuiGrid item>
            <Header level={3}>
              <Identifier bold entity="position" id={position} />
              {` ${WorkerRoleOptions()[role].label} - ${professionName}${title ? ` - ${title}` : ""}`}
            </Header>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid alignItems="center" container item justify="space-between">
          <MuiGrid item>
            <CheckboxInputBase
              label={`${count} ${t("worker", { count })}`}
              name={`shift${id}`}
              // @ts-expect-error
              onChange={(e, checked: boolean) => {
                onSelectAll(checked);
              }}
              value={selected}
            />
          </MuiGrid>
          <MuiGrid item>
            {dt.time(startTime)} - {dt.time(endTime)}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};
