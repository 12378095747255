import MuiTextField from "@material-ui/core/TextField";
import { t } from "@sinch/intl";
import React, { ReactElement, useState } from "react";
import { BaseInputProps, ManagedInputProps } from "../Input";

export interface ReadOnlyCopyInputProps extends ManagedInputProps {}

export function ReadOnlyCopyInput({
  dense,
  value,
  ...props
}: Omit<BaseInputProps<string, ReadOnlyCopyInputProps>, "onChange">): ReactElement {
  const [copied, setCopiedStatus] = useState(false);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { target } = event;
    target.focus();
    target.setSelectionRange(0, value.length);
    /* Copy the text inside the text field */
    document.execCommand("copy");
    setCopiedStatus(true);
  };

  const handleBlur = () => {
    setCopiedStatus(false);
  };

  return (
    <MuiTextField
      fullWidth
      helperText={copied ? t("CopyInput.copiedToClipboard") : t("CopyInput.ClickToCopy")}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: true,
      }}
      margin={dense ? "dense" : "normal"}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={value}
      variant="outlined"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  );
}
