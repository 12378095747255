import MuiChip from "@material-ui/core/Chip";
import MuiGrid from "@material-ui/core/Grid";
import { useBusinessRules, useData } from "@sinch/core";
import { PositionQ, sAnd, selectPosition } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Card, FileList, GridLayout, HtmlContent, useSpacing } from "@sinch/ui";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Section } from "../../components";
import { requestPositionDetail } from "./api";

export function PositionDetailDescription(): ReactElement {
  const { crewbossName: crewboss } = useBusinessRules();
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId, publicDescriptionFiles, crewbossDescriptionFiles } = selectResult();
  const [, , data] = useSpacing();
  const { crewbossName } = useBusinessRules();

  const { crewbossDescription, description, tags } = selectEntity(selectPosition(positionId));
  const showCrewboss = queryEntity(sAnd(PositionQ.User.isAssigned, PositionQ.User.isCrewboss)(positionId));

  const hasDescription = !isNilOrEmpty(description);
  const hasPublicFiles = !isNilOrEmpty(publicDescriptionFiles);
  const hasCrewbossDescription = !isNilOrEmpty(crewbossDescription);
  const hasCrewbossFiles = !isNilOrEmpty(crewbossDescriptionFiles);

  return (
    <>
      {(hasDescription || hasPublicFiles || !isNilOrEmpty(tags)) && (
        <GridLayout.Item>
          <Card>
            <MuiGrid container direction="column" spacing={data}>
              <MuiGrid item>
                <Section html title={t("description")}>
                  {description}
                </Section>
              </MuiGrid>
              {tags && (
                <MuiGrid item>
                  {tags?.map(({ name: tagName, description: tagDescription }) => [
                    <MuiChip key={tagName} label={tagName} size="small" />,
                    <HtmlContent html={tagDescription} />,
                  ])}
                </MuiGrid>
              )}
              {hasPublicFiles && (
                <MuiGrid item>
                  <Section title={t("attachments")}>
                    <FileList files={publicDescriptionFiles} />
                  </Section>
                </MuiGrid>
              )}
            </MuiGrid>
          </Card>
        </GridLayout.Item>
      )}
      {(hasCrewbossDescription || hasCrewbossFiles) && showCrewboss && (
        <GridLayout.Item>
          <Card>
            {hasCrewbossDescription && (
              <Section html title={t("Position.descriptionForCrewboss", { crewboss })}>
                {crewbossDescription}
              </Section>
            )}
            {hasCrewbossFiles && (
              <Section title={t("Position.attachmentsForCrewboss", { crewboss: crewbossName })}>
                <FileList
                  // @ts-expect-error
                  files={crewbossDescriptionFiles}
                />
              </Section>
            )}
          </Card>
        </GridLayout.Item>
      )}
    </>
  );
}
