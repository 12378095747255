import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";
import { RequestCreator, RequestOf, ResponseData } from "../contract";
import { useFallback } from "./FallbackProvider";
import { RefreshToken } from "./useRefresh";
import { useRequestState } from "./useRequestState";

const ResponseContext = createCheckedContext<ResponseData>("Response");
export const { use: useResponse } = ResponseContext;

export interface ResponseProviderProps<TCreator extends RequestCreator> extends ChildrenProps {
  /**
   * Refresh token created by {@link useRefresh}.
   */
  refresh?: RefreshToken;

  /**
   * Requested data to be resolved and wait for.
   */
  request: RequestOf<TCreator>;

  cache?: boolean;
}

/**
 * todo: use React Suspense when available
 *
 * todo: consider checking if not trying to access data for other contract
 *  than actually stored in DataProvider (probably using contract key)
 *  (also can enable access to responses for other requests in current context)
 */
export function ResponseProvider<TCreator extends RequestCreator = RequestCreator>({
  children,
  refresh,
  request,
  cache,
}: ResponseProviderProps<TCreator>): ReactElement {
  const fallback = useFallback();
  const state = useRequestState(request, refresh, cache);

  if (!state) return fallback;

  return <ResponseContext value={state}>{children}</ResponseContext>;
}
