/* eslint-disable react/jsx-props-no-spreading */
import { InputBase, Popper } from "@material-ui/core";
import { PopperProps } from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete/Autocomplete";
import { useFormat, useIntl } from "@sinch/intl";
import { parseTime } from "@sinch/utils";
import clsx from "clsx";
import {
  add,
  differenceInMinutes,
  getMinutes,
  max,
  set as dateSet,
  setMilliseconds,
  setMinutes,
  setSeconds,
  sub,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isEmpty, isNil } from "ramda";
import React, { ReactElement, ReactNode, useState } from "react";
import { DateTimeInputNative } from "./DateTimeInputNative";

const styles = {
  popper: {
    maxWidth: "fit-content",
    maxHeight: "200px",
  },
};

const useStyles = makeStyles((theme) => ({
  selectDatePart: {
    color: theme.palette.grey[500],
    paddingRight: theme.spacing(1),
  },
  selectDiffPart: {
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(1),
  },
  timeInput: {
    "& .MuiOutlinedInput-inputMarginDense.MuiInputBase-input": {
      paddingLeft: theme.spacing(1),
      paddingRight: 0,
    },
  },
  textCenter: {
    textAlign: "center",
    paddingLeft: "calc(48.5px + 1.5rem)",
  },
  textLeft: {
    textAlign: "left",
  },
  autocompletePaper: {
    maxHeight: "200px",
  },
}));

interface TimeInputPartProps {
  value?: Date;
  partialDateBase?: Date;
  onChange: (date: Date | undefined) => void;
  timePoint?: Date;
  name: string;
  initValue?: Date;
  step: number;
  minTime?: Date;
  maxTime?: Date;
  useNative?: boolean;
  adornment?: ReactNode;
  dense?: boolean;
  textAlign?: "right" | "center" | "left";
  placeholder?: string;
}

/**
 * Autocomplete popper
 */
const DateTimePopper = ({ children, ...props }: PopperProps) => (
  <Popper {...props} placement="bottom-start" style={styles.popper}>
    {children}
  </Popper>
);

function roundTime(time: Date, step: number): Date {
  const timeToReturn = time;

  timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / step) * step);
  return timeToReturn;
}

/**
 * Get list of dates for autocomplete
 */
function getOptions(
  value: Date,
  { step = 15, minTime, maxTime }: Pick<TimeInputPartProps, "step" | "minTime" | "maxTime">,
  initValue: Date = new Date()
) {
  const options = [];
  const date = sub(
    dateSet(value, {
      hours: initValue.getHours(),
      minutes: initValue.getMinutes(),
    }),
    { hours: 12 }
  );

  const startTime = minTime ? max([minTime, date]) : date;
  const min = getMinutes(startTime);
  const roundedTime = min - (min % step);
  const startDate = setMilliseconds(setSeconds(setMinutes(startTime, roundedTime), 0), 0);

  const duration = maxTime ? differenceInMinutes(maxTime, startTime) : 60 * 24;
  for (let i = 0; i < duration; i += step) {
    options.push(add(startDate, { minutes: i }));
  }
  return options;
}

/**
 * Time part of SelectDateTimeInput
 */
export function TimeInputPart({
  value,
  onChange,
  timePoint,
  name,
  initValue,
  step,
  minTime,
  maxTime,
  useNative,
  adornment,
  dense = true,
  textAlign = "right",
  placeholder,
  inputProps,
  partialDateBase,
}: TimeInputPartProps): ReactElement {
  const { dt, dur } = useFormat();
  const classes = useStyles();
  const { timeZone } = useIntl();

  const [inputValue, setInputValue] = useState<string>(initValue ? dt.time(initValue) : "");
  const options = getOptions(value || minTime || new Date(), { step, minTime, maxTime }, initValue);
  /**
   * Parse partial time from input eg. 1200 convert to 12:00
   */
  const parsePartialTime = () => {
    if (isEmpty(inputValue)) {
      onChange(undefined);
      return;
    }

    const [hours, minutes] = parseTime(inputValue);
    const zoned = utcToZonedTime(value || partialDateBase || minTime || new Date(), timeZone);
    const newDate = zonedTimeToUtc(dateSet(zoned, { hours, minutes, seconds: 0 }), timeZone);
    if (maxTime && newDate.getTime() > maxTime.getTime()) {
      onChange(maxTime);
      return;
    }
    onChange(newDate);
  };

  /**
   * Function for render select options
   */
  const renderOptions = (option: Date) => (
    <div style={{ whiteSpace: "nowrap" }}>
      <span className={classes.selectDatePart}>{dt.chip(option)}</span>
      {dt.time(option)}
      {timePoint && (
        <small className={classes.selectDiffPart}>
          {/** @ts-ignore */}
          {`(${dur.narrow(differenceInMinutes(option, timePoint) * 60)})`}
        </small>
      )}
    </div>
  );

  /**
   * render input for autocomplete
   */
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <InputBase
      ref={params.InputProps.ref}
      classes={{
        input: clsx(
          dense && "MuiOutlinedInput-inputMarginDense",
          "MuiOutlinedInput-input",
          textAlign === "center" ? classes.textCenter : textAlign === "left" && classes.textLeft
        ),
      }}
      endAdornment={adornment}
      fullWidth
      inputProps={{ ...inputProps, ...params.inputProps }}
      name={name}
      onBlur={parsePartialTime}
      placeholder={placeholder}
    />
  );

  return useNative ? (
    <DateTimeInputNative
      className={classes.timeInput}
      name={`${name}.time`}
      onChange={(val) => {
        const inputVal = val || new Date();
        const [hours, minutes] = [inputVal.getUTCHours(), inputVal.getUTCMinutes()];
        const zoned = utcToZonedTime(value || minTime || new Date(), timeZone);
        const newDate = zonedTimeToUtc(dateSet(zoned, { hours, minutes, seconds: 0 }), timeZone);
        // because setting hours or is set to current timezone, we need to correct UTC time
        onChange(newDate);
      }}
      placeholder={placeholder}
      type="time"
      value={value}
    />
  ) : (
    <Autocomplete
      classes={{ paper: classes.autocompletePaper, listbox: classes.autocompletePaper }}
      className={classes.timeInput}
      disableClearable
      filterOptions={(opts) => opts}
      freeSolo
      fullWidth
      getOptionLabel={(option) => dt.time(option)}
      getOptionSelected={(optionItem, currentItem) => optionItem.getTime() === roundTime(currentItem, step).getTime()}
      inputValue={inputValue}
      onChange={(event, newValue: string | Date) => {
        onChange(new Date(newValue));
      }}
      onInputChange={(event, newInputValue) => {
        if (!isNil(event)) {
          setInputValue(newInputValue);
        }
      }}
      openOnFocus
      options={options}
      placeholder={placeholder}
      PopperComponent={DateTimePopper}
      renderInput={renderInput}
      renderOption={renderOptions}
      value={value}
    />
  );
}
