import { Box, Divider } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { Fk, Position, PositionAttendance } from "@sinch/entity";
import { ChildrenProps } from "@sinch/utils";
import { difference, intersection, uniq } from "ramda";
import React, { ReactElement } from "react";
import { useSelectionSet } from "../context";
import { PositionHeader } from "./PositionHeader";

interface PresenceCardListProps extends ChildrenProps {
  attendancesIds: number[];
  position: Fk<Position>;
}

export function ShiftCardWrapper({ attendancesIds, children, position }: PresenceCardListProps): ReactElement {
  const { selected, setSelected } = useSelectionSet<Fk<PositionAttendance>>();
  return (
    <MuiGrid container direction="column">
      <MuiGrid item>
        <PositionHeader
          count={attendancesIds.length}
          onSelectAll={(checked: boolean) => {
            if (checked) {
              setSelected(uniq([...selected, ...attendancesIds]));
            } else {
              setSelected(difference(selected, attendancesIds));
            }
          }}
          position={position}
          selected={intersection(attendancesIds, selected).length === attendancesIds.length}
        />

        <Divider variant="fullWidth" />
      </MuiGrid>
      <MuiGrid item>
        <Box p={1}>{children}</Box>
      </MuiGrid>
    </MuiGrid>
  );
}
