import { TypographyStyle } from "@material-ui/core";
import { createMuiTheme, Theme } from "@material-ui/core/styles";
import {
  TypographyOptions,
  TypographyStyleOptions,
} from "@material-ui/core/styles/createTypography";

const fontWeightRegular = 400;
const fontWeightMedium = 600;

export const {
  typography: { pxToRem },
}: Theme = createMuiTheme({});

function fontStyles(
  size: number,
  bold = false,
  optional: TypographyStyleOptions = {}
): TypographyStyleOptions {
  return {
    fontSize: pxToRem(size),
    fontWeight: bold ? fontWeightMedium : fontWeightRegular,
    ...optional,
  };
}

const uppercase = { textTransform: "uppercase" } as const;

/**
 * todo: consider overriding some other unused typography variants
 *  instead of creating new ones (if they are really unused!),
 *  alternatively augment also Variant type to allow others
 */
export const typography: TypographyOptions = {
  fontFamily: "Open Sans, sans-serif",
  // fontSize: 14,
  // htmlFontSize: 16,
  // fontWeightLight: fontWeightRegular,
  // fontWeightRegular,
  // fontWeightMedium,
  // fontWeightBold: fontWeightMedium,
  allVariants: {
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  h1: fontStyles(24),
  h2: fontStyles(20, true),
  h3: fontStyles(16, true),
  h4: fontStyles(14, true),
  body1: fontStyles(14),
  body2: fontStyles(12),
  button: fontStyles(14, true, uppercase),
  tab: fontStyles(13, true, uppercase),
  chip: fontStyles(12),
  label: fontStyles(11),
  /*
   * h5, h6, subtitle1, subtitle2, caption and overline are not used
   *
   * todo: form label text is affected by transform (shrink param)
   *  we need to override the component to use explicit typography
   */
  bold: {
    fontWeight: fontWeightMedium,
  },
  italic: {
    fontStyle: "italic",
  },
  underline: {
    textDecoration: "underline",
  },
  strikethrough: {
    textDecoration: "line-through",
  },
};

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    tab: TypographyStyle;

    chip: TypographyStyle;

    label: TypographyStyle;

    bold: TypographyStyle;

    italic: TypographyStyle;

    underline: TypographyStyle;

    strikethrough: TypographyStyle;
  }

  interface TypographyOptions {
    tab: TypographyStyleOptions;

    chip: TypographyStyleOptions;

    label: TypographyStyleOptions;

    bold: TypographyStyleOptions;

    italic: TypographyStyleOptions;

    underline: TypographyStyleOptions;

    strikethrough: TypographyStyleOptions;
  }
}
