import { TableCellProps } from "@material-ui/core";
import { assocPath } from "ramda";
import React, { ComponentProps, ReactElement } from "react";
import { DataTableCell } from "../DataTableCell";
import { DataTableCellProps, DataTableDisplay, DataTableProps } from "../types";

/* eslint-disable react/jsx-props-no-spreading */

export function createTextAlignCell<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>(align: TableCellProps["align"]) {
  return function TextAlignCell({
    table,
    row,
    column,
  }: DataTableCellProps<TData, TColumn, TDisplay>): ReactElement {
    const { config, display } = table;
    const { Cell } = display;
    // todo: set defaults on container initialization
    const CellLogic = config[column].logic || DataTableCell;

    const DisplayCell = (props: ComponentProps<TDisplay["Row"]>) => (
      <Cell {...props} align={align} />
    );

    const updateDisplay: (
      props: DataTableProps<TData, TColumn, TDisplay>
    ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(
      ["display", "Cell"],
      DisplayCell
    );

    return <CellLogic column={column} row={row} table={updateDisplay(table)} />;
  };
}
