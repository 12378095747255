import { useFiles } from "@sinch/core";
import { FileInputButton, FileInputProvider, useFileInputContext } from "@sinch/forms";
import { Consumer, FileHash, Nullable } from "@sinch/types";
import { Center, Grid } from "@sinch/ui";
import React, { ReactElement, SetStateAction, useMemo, useState } from "react";
import ReactCrop, { Crop as ReactImageCrop } from "react-image-crop";
/* eslint-disable import/no-internal-modules */
import "react-image-crop/dist/ReactCrop.css";
import { isEmpty } from "ramda";
import { FormHelperText } from "@material-ui/core";

export type CropData = Required<ReactImageCrop>;
export type Crop = Nullable<CropData>;

export function useCropState(init?: CropData): [Crop, Consumer<Crop>] {
  return useState<Crop>(init ?? null);
}

export interface ImageCropInputProps {
  crop: Crop;

  cropProps?: {
    keepSelection?: boolean;
    maxHeight?: number;
    maxWidth?: number;
    minHeight?: number;
    minWidth?: number;
  };

  file: Nullable<FileHash>;

  maxFileSize?: number;

  onCropChange: Consumer<Crop>;

  onFileChange: (v: SetStateAction<Nullable<string>>, progress?: string) => void;

  target: string;
}

export function ImageCropInput({
  crop,
  cropProps = {},
  file,
  maxFileSize,
  onCropChange,
  onFileChange,
  target,
}: ImageCropInputProps): ReactElement {
  const storage = useFiles();
  const src = useMemo(() => {
    try {
      return file ? storage(file).url : "";
    } catch (e) {
      return "";
    }
  }, [file, storage]);

  return (
    <FileInputProvider accept="image/*" maxSize={maxFileSize} onChange={onFileChange} target={target} value={file}>
      <ImageCropInputContent crop={crop} cropProps={cropProps} file={file} onCropChange={onCropChange} src={src} />
    </FileInputProvider>
  );
}

function ImageCropInputContent({
  crop,
  cropProps,
  file,
  onCropChange,
  src,
}: Omit<ImageCropInputProps, "target" | "onFileChange"> & { src: string }): ReactElement {
  const { errors } = useFileInputContext();
  return (
    <Grid spacing="inner" vertical>
      <Center>
        {file && (
          <ReactCrop
            crop={crop ?? undefined}
            keepSelection
            onChange={(_, percentCrop) => onCropChange(percentCrop as Crop)}
            src={src}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...cropProps}
          />
        )}
        {!isEmpty(errors) && errors.map((errMsg) => <FormHelperText error>{errMsg}</FormHelperText>)}
      </Center>
      <FileInputButton variant="outlined" />
    </Grid>
  );
}
