import { Func } from "@sinch/types";
import { RequestCreator, RequestResponse } from "../contract";

/**
 * todo: for future type-safety consider using promise-like wrapper
 *  for passing response through handler sequence instead of returning itself
 *  - forces explicit handling, prevent errors
 *  - not a burden if proper response factories are provided
 *  - more logging capabilities if required
 *  low level api example:
 *  ```
 *  (response) => {
 *    if (ok)
 *      response.resolve()
 *    else
 *      response.pass()
 *  }
 *  ```
 *
 * todo: it might be useful to allow handler modify response data before
 *  returning to the application - that way we might support various
 *  transformation pipelines with simple configuration
 *  - wrapper have to be implemented, solving also the problem above
 *  - when the response is resolved, output data is passed back to the app
 *  ```
 *  (response) => response.resolve(selectMessages(response))
 *  ```
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type ResponseHandlerFunc<TCreator extends RequestCreator = any> = Func<
  RequestResponse<TCreator>,
  RequestResponse<TCreator> | void
>;

export interface ResponseHandlerInfo {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  readonly params: any;

  readonly type: string;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export interface ResponseHandler<TCreator extends RequestCreator = any>
  extends ResponseHandlerFunc<TCreator>,
    ResponseHandlerInfo {}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function responseHandler<TCreator extends RequestCreator = any>(
  type: string,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
  params: any,
  onResponse: ResponseHandlerFunc<TCreator>
): ResponseHandler<TCreator> {
  const handler = (reqres: RequestResponse<TCreator>) => onResponse(reqres);
  handler.type = type;
  handler.params = params;
  return handler;
}
