import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Reimbursement, selectReimbursement, selectShift } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  createTextAlignCell,
  currencyLogic,
  dateTimeLogic,
  Identifier,
  MuiDataTable,
  MuiDataTableDisplay,
  Text,
  typographyLogic,
  useDialog,
} from "@sinch/ui";
import { isEmpty, pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement, useMemo, useState } from "react";
import { ReimbursementStatusElement, SearchParamsPagination } from "../../components";
import { requestReimbursementList } from "./api";
import { ReimbursementDetail } from "./ReimbursementDetail";
import { ReimbursementEmptyMessage } from "./ReimbursementEmptyMessage";

type ReimbursementColumn = "created" | "title" | "amount" | "status";

const TextAlignRightCell = createTextAlignCell("right");

export function ReimbursementTable(): ReactElement {
  const dialog = useDialog();
  const [detailId, setDetailId] = useState<number | null>(null);

  const { selectEntity, selectResult } = useData(requestReimbursementList);
  const { reimbursementIds } = selectResult();

  const sortedReimbursementIds: number[] = pipe<Reimbursement[], Reimbursement[], number[], number[]>(
    sortBy(prop("created")),
    pluck("id"),
    reverse
  )(selectEntity(selectReimbursement(reimbursementIds)));

  useMemo(() => {
    setDetailId(null);
  }, [reimbursementIds]);

  const ClickableRow = createClickableRow((id: Fk<Reimbursement>) => () => {
    setDetailId(id);
    dialog.open();
  });

  const ReimbursementsDataTable = MuiDataTable.withConfig<Fk<Reimbursement>, ReimbursementColumn>({
    created: {
      title: t("date"),
      selector: (id) => selectEntity(selectReimbursement(id, "created")),
      logic: composeCellLogic<Fk<Reimbursement>, ReimbursementColumn, MuiDataTableDisplay>([dateTimeLogic()]),
    },
    title: {
      title: t("Shift.title"),
      selector: (id) => {
        const shift = selectEntity(selectReimbursement(id, "shift"));
        const { id: shiftId, name } = selectEntity(selectShift(shift));
        return (
          shift && (
            <Text separator={" "}>
              <Identifier entity="shift" id={shiftId} />
              {name}
            </Text>
          )
        );
      },
    },
    amount: {
      title: t("amount"),
      titleCellProps: { align: "right" },
      selector: (id) => selectEntity(selectReimbursement(id, "amount")),
      logic: composeCellLogic<Fk<Reimbursement>, ReimbursementColumn, MuiDataTableDisplay>([
        TextAlignRightCell as never,
        currencyLogic(),
      ]),
    },
    status: {
      title: t("Reimbursement.approval"),
      selector: (id) => {
        const status = selectEntity(selectReimbursement(id, "status"));
        return (
          <Box whiteSpace="nowrap">
            <ReimbursementStatusElement status={status} />
          </Box>
        );
      },
    },
  }).withLogic(composeRowLogic([ClickableRow, typographyLogic()]));

  return (
    <>
      {isEmpty(reimbursementIds) ? (
        <ReimbursementEmptyMessage />
      ) : (
        <ReimbursementsDataTable columns={["created", "title", "amount", "status"]} data={sortedReimbursementIds}>
          <SearchParamsPagination />
        </ReimbursementsDataTable>
      )}

      {detailId && dialog.state && <ReimbursementDetail dialog={dialog} reimbursementId={detailId} />}
    </>
  );
}
