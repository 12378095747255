import { Box } from "@material-ui/core";
import { DateFormatVariant, useFormat } from "@sinch/intl";
import { assocPath } from "ramda";
import React, { ComponentProps, ReactElement } from "react";
import { DataTableCell } from "../DataTableCell";
import { DataTableCellProps, DataTableDisplay, DataTableProps } from "../types";

/* eslint-disable react/jsx-props-no-spreading */

export function dateTimeLogic<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>(
  format: DateFormatVariant = "date"
) {
  return function DateTimeLogic({ table, row, column }: DataTableCellProps<TData, TColumn, TDisplay>): ReactElement {
    const { dt } = useFormat();
    const { config, display } = table;
    const { Cell } = display;
    // todo: set defaults on container initialization
    const CellLogic = config[column].logic || DataTableCell;

    const DisplayCell = ({ children, ...props }: ComponentProps<TDisplay["Cell"]>) => (
      <Cell {...props}>
        <Box whiteSpace="nowrap">{dt[format](children as Date)}</Box>
      </Cell>
    );

    const updateDisplay: (
      props: DataTableProps<TData, TColumn, TDisplay>
    ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(["display", "Cell"], DisplayCell);

    return <CellLogic column={column} row={row} table={updateDisplay(table)} />;
  };
}
