import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en"; // locale-data for en
import "@formatjs/intl-relativetimeformat/locale-data/cs"; // locale-data for en
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // locale-data for en
import "@formatjs/intl-pluralrules/locale-data/cs"; // locale-data for en
import { bind, indexOf, is, isNil, last, pipe, propEq, reduce, reject } from "ramda";

export type RelativeTimeVariant = "short" | "standard";

export type RelativeTimeUnit = "year" | "month" | "week" | "day" | "hour" | "minute" | "second";

export type ObjectEntriesArr<T> = [string, T | undefined];

const getMaxUnit = (items: Duration) => {
  const priorities = ["seconds", "minutes", "hours", "days", "months", "years"];
  // match id of object to required index and insert
  const sortInsert = (acc: ObjectEntriesArr<number>[], cur: ObjectEntriesArr<number>) => {
    const toIdx = indexOf(cur[0], priorities);
    acc[toIdx] = cur;
    return acc;
  };
  // @ts-expect-error
  const [unit, value] = pipe(reduce(sortInsert, []), reject(isNil), reject(propEq(1, 0)), last)(Object.entries(items));
  return { value, unit };
};

export type FormatRelativeTimeNative = (value: number, unit: RelativeTimeUnit) => string;

export type FormatRelativeTime = (value: number | Duration, unit?: RelativeTimeUnit) => string;

export function relativeTimeFormatter(
  locale: string
): FormatRelativeTime & Record<RelativeTimeVariant, FormatRelativeTime> {
  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });
  const short = new Intl.RelativeTimeFormat(locale, {
    style: "short",
    numeric: "always",
  });

  const format = (fn: FormatRelativeTimeNative): FormatRelativeTime => (value, unit) => {
    if (is(Object, value)) {
      const { value: val, unit: timeUnit } = getMaxUnit(value as Duration);
      return fn(val, unit || timeUnit);
    }
    return fn(value as number, unit as RelativeTimeUnit);
  };

  return Object.assign(format(bind(formatter.format, formatter)), {
    short: format(bind(short.format, short)),
    standard: format(bind(formatter.format, formatter)),
  });
}
