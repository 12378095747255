import { t } from "@sinch/intl";
import { Box, Header } from "@sinch/ui";
import React, { ReactElement } from "react";

export function PositionHistoryEmptyMessage(): ReactElement {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" height="24em" justifyContent="center">
      <Header color="neutral">{t("Position.noFinishedJobs")}</Header>
    </Box>
  );
}
