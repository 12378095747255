import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useBusinessRules, useData, useRequest } from "@sinch/core";
import { PositionQ, Reimbursement, ReimbursementStatus, selectPosition, selectReimbursement } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Button,
  Card,
  ExpandableArea,
  Grid,
  GridLayout,
  InformationDialog,
  List,
  Text,
  useDialog,
  useSpacing,
} from "@sinch/ui";
import React, { ReactElement, useEffect, useState } from "react";
import { ReimbursementStatusElement } from "../../../components";
import {
  ReimbursementDeleteContainer,
  ReimbursementDetail,
  ReimbursementRequest,
  requestReimbursementDelete,
} from "../../../Wallet";

import { requestPositionDetail } from "../api";

function ReimbursementDeleteButton({ reimbursementId }: { reimbursementId: number }): ReactElement {
  const dispatch = useRequest();
  const dialog = useDialog(false);

  return (
    <>
      <Button action={() => dialog.open()} color="error" variant="text">
        {t("action.delete")}
      </Button>
      <InformationDialog
        control={dialog}
        hashKey="reimbursementConfirmation"
        title={t("Reimbursement.deleteReimbursement")}
        width="xs"
      >
        <Grid spacing="inner" vertical>
          <Text bold>{t("Reimbursement.deleteReimbursementConfirmation")}</Text>
          <Button
            action={() => {
              dispatch(requestReimbursementDelete(reimbursementId));
              dialog.close();
            }}
            color="secondary"
            type="submit"
          >
            {t("action.delete")}
          </Button>
        </Grid>
      </InformationDialog>
    </>
  );
}

export function Reimbursements(): ReactElement | null {
  const detailDialog = useDialog();

  const [detailId, setDetailId] = useState<number | null>(null);

  const [, innerSpacing, data] = useSpacing();
  const { curr, dt } = useFormat();

  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId, reimbursementIds: reimbursementsIds } = selectResult();

  const { allowWorkerReimbursementRequest } = useBusinessRules();

  useEffect(() => {
    if (detailId) {
      detailDialog.open();
    }
  }, [detailId]);

  if (!allowWorkerReimbursementRequest || !queryEntity(PositionQ.User.isAssigned(positionId))) {
    return null;
  }

  const { shift } = selectEntity(selectPosition(positionId));
  const reimbursements = selectEntity(selectReimbursement(reimbursementsIds));

  const openDetail = (itemId: number) => () => {
    if (itemId === detailId) {
      detailDialog.open();
    }
    setDetailId(itemId);
  };

  return (
    <GridLayout.Item>
      {reimbursements.length ? (
        <Card>
          <ReimbursementRequest shift={shift} />

          <Box mx={-innerSpacing}>
            <ExpandableArea
              title={`${t("Reimbursement.showRequestExpandButtonLabel")} (${reimbursementsIds.length})`}
              titleOpened={`${t("Reimbursement.hideRequestExpandButtonLabel")} (${reimbursementsIds.length})`}
            >
              <List
                center
                disableGutters
                getBody={({ id: reimbursementId, amount, status, created }: Reimbursement) => (
                  <Box pr={innerSpacing}>
                    <MuiGrid container direction="column" spacing={data}>
                      <MuiGrid container item justify="space-between">
                        <MuiGrid item>
                          <Text bold>{curr(amount)}</Text>
                        </MuiGrid>
                        <MuiGrid item>{dt(created)}</MuiGrid>
                      </MuiGrid>
                      <MuiGrid item>
                        <ReimbursementStatusElement status={status} />
                      </MuiGrid>
                      <MuiGrid container item justify="space-between">
                        <MuiGrid item>
                          <Box ml={-data}>
                            <Button action={openDetail(reimbursementId)} color="info" variant="text">
                              {t("detail")}
                            </Button>
                          </Box>
                        </MuiGrid>
                        {status === ReimbursementStatus.Pending && (
                          <MuiGrid item>
                            <ReimbursementDeleteContainer>
                              <ReimbursementDeleteButton reimbursementId={reimbursementId} />
                            </ReimbursementDeleteContainer>
                          </MuiGrid>
                        )}
                      </MuiGrid>
                    </MuiGrid>
                  </Box>
                )}
                items={reimbursements}
              />
            </ExpandableArea>
          </Box>
        </Card>
      ) : (
        <ReimbursementRequest shift={shift} />
      )}

      {detailId && <ReimbursementDetail dialog={detailDialog} reimbursementId={detailId} />}
    </GridLayout.Item>
  );
}
