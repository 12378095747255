import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement, useMemo, useState } from "react";

export type ToolbarPosition = "bottom" | "top";

const ToolbarPortalContext = createCheckedContext<Record<ToolbarPosition, any>>(
  "ToolbarPortal"
);

/** Initialization step receives ref with empty value. */
export function useToolbarPortal(
  position: ToolbarPosition,
  initialize: true
): unknown;
/** Application usage always receives portal element ref or throws error if not initialized. */
export function useToolbarPortal(
  position: ToolbarPosition,
  initialize?: false
): unknown;
export function useToolbarPortal(
  position: ToolbarPosition,
  initialize?: boolean
): Record<ToolbarPosition, any> | null {
  const { [position]: ref } = ToolbarPortalContext.use();

  const [portal, setPortal] = ref;
  if (initialize) {
    return setPortal;
  }

  if (initialize || portal) return portal;

  return null;
}

export function ToolbarPortalProvider({
  children,
}: ChildrenProps): ReactElement {
  const [topPortalElement, setTopPortalElement] = useState(null);
  const [bottomPortalElement, setBottomTopPortalElement] = useState(null);
  const context = useMemo(
    () => ({
      bottom: [bottomPortalElement, setBottomTopPortalElement],
      top: [topPortalElement, setTopPortalElement],
    }),
    [topPortalElement, bottomPortalElement]
  );

  return (
    <ToolbarPortalContext value={context}>{children}</ToolbarPortalContext>
  );
}
