import { Nullable } from "@sinch/types";
import { toElement } from "@sinch/utils";
import parse, {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from "html-react-parser";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DomElement } from "htmlparser2";
import { createElement, ReactElement, ReactNode } from "react";
import { isTagType, isTextType, TagElement, TextElement } from "./dom";
import { replaceRules } from "./rules";

function replaceText({ data }: TextElement): Nullable<ReactElement> {
  const text = data.trim();
  if (!text) return null;

  // console.log("OK txt", text);

  return toElement(text);
}

function replaceTag({
  name,
  attribs,
  children,
}: TagElement): ReactElement | null {
  const replacement = replaceRules[name] || null;

  if (!replacement) {
    console.warn("Unhandled tag", `<${name}>`);
    return null;
  }

  // console.log(`OK <${name}> ${children.length}`);

  return createElement(
    replacement,
    attributesToProps(attribs),
    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    domToReact(children, options)
  );
}

function replace(node: DomElement): ReactElement | null {
  if (isTextType(node)) return replaceText(node);
  if (isTagType(node)) return replaceTag(node);

  // console.warn("Ignored element", `<${node.name}>`);

  return null;
}

const options: HTMLReactParserOptions = {
  replace,
  trim: true,
};

export function parseHtml(input: string): ReactNode {
  return parse(input, options);
}
