import { useData } from "@sinch/core";
import { t } from "@sinch/intl";
import { Box, Button, Header, routerLink } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "./api";

export function DashboardAppointmentEmptyMessage(): ReactElement {
  /**
   * todo: read available appointments from application status
   */
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { availableAppointmentCount: count } = selectResult();

  return (
    <Box alignItems="center" display="flex" flexDirection="column" height="24em" justifyContent="center">
      <Header color="neutral">{t("Dashboard.noAppointmentSignUp")}</Header>
      <Box height="2em" />
      <Header color="neutral" level={2}>
        <Button action={routerLink("/appointment")} variant="text">
          {t("Appointment.action.showAvailable", { count })}
        </Button>
      </Header>
    </Box>
  );
}

/**
 * todo: display link to all available positions
 */
export function DashboardPositionEmptyMessage(): ReactElement {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" height="24em" justifyContent="center">
      <Header color="neutral">{t("Dashboard.noPositionSignUp")}</Header>
    </Box>
  );
}
