import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";

const FallbackContext = createCheckedContext<ReactElement>("Fallback");
export const { use: useFallback } = FallbackContext;

export interface FallbackProviderProps extends ChildrenProps {
  /**
   * Component displayed as a placeholder when request is in loading state.
   *
   * todo: consider using render function with request passed in as argument
   */
  fallback: ReactElement;
}

export function FallbackProvider({
  children,
  fallback,
}: FallbackProviderProps): ReactElement {
  return <FallbackContext value={fallback}>{children}</FallbackContext>;
}
