import { Fk, News, NewsContainer, Notification, NotificationContainer } from "@sinch/entity";
import { defineRequest, IdParams, PagingParams, PagingResult } from "../../backend";
import { Indicator } from "./Indicator";

/**
 * Global application status not related to currently active view module.
 */
export interface StatusResult {
  /**
   * Pending news.
   *
   * This is not the equivalent of announcement list, this response contains
   * only announcements which are unseen and/or not confirmed by current user.
   */
  newsIds: Fk<News>[];

  /**
   * Indicators for main navigation menu (displayed as badges) and other
   * counters not strictly bound to particular application view.
   */
  indicators: Indicator[];

  /**
   * Notifications sent to current user.
   */
  notificationIds: Fk<Notification>[];

  unreadNotifications: number;
}

/**
 * todo: change key to `core/Status`
 */
export const requestStatus = defineRequest("Application/Status")
  .withEmptyParams()
  .withResponse<StatusResult>()
  .withResponseEntity<NewsContainer & NotificationContainer>()
  .withDefaultCreator();

/**
 * Mark individual notification as read
 */
export const requestNotificationRead = defineRequest("Notifications/Read/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<Partial<IdParams<Notification>>>()
  .withEmptyResponse()
  .withCreator((id?: Fk<Notification>) => ({ params: { id } }));

/**
 * Mark all pending notifications as read.
 */
export const requestNotificationReadAll = defineRequest("Notifications/ReadAll")
  .withEmptyParams()
  .withEmptyResponse()
  .withDefaultCreator();

export const requestNotificationList = defineRequest("Notifications/Index")
  .withEmptyParams()
  .withMeta<PagingParams>()
  .withResponse<{
    notificationIds: Fk<Notification>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<NotificationContainer>()
  .withCreator((paging: PagingParams) => ({
    meta: paging,
  }));
