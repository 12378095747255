export enum NotificationType {
  General = 0,

  Shift = 1,

  Position = 2,

  Appointment = 3,

  Transport = 4,

  News = 5,
}
