import { mdiShuffleVariant, mdiAccountCancel, mdiCheck } from "@mdi/js";
import { useData } from "@sinch/core";
import { AppointmentQ, selectAppointment } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Identifier, P, routerLink, Spacer, StructuredText, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { StatusList, StatusListProps, StatusProps } from "../../components";
import { requestAppointmentDetail } from "./api";

const prefix = "appointment-";

export function AppointmentDetailStatus(): ReactElement {
  const { dt } = useFormat();
  const { selectResult, queryEntity, selectEntity } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();
  const { conflicting } = selectEntity(selectAppointment(appointmentId));

  const conflictingAppointments: StatusProps[] | undefined = conflicting?.appointment?.map((conflict) => {
    const appointmentIdLabel = (
      <Text bold>
        <Identifier entity="appointment" id={conflict} />
      </Text>
    );
    return {
      key: `${prefix}conflicting`,
      icon: mdiShuffleVariant,
      color: "error",
      content: (
        <Text>
          {t("Appointment.alreadySignUpAppointmentInSameTime", {
            appointmentIdLabel,
          })}
        </Text>
      ),
      action: {
        action: routerLink(`/appointment/${conflict}`),
        label: t("action.show"),
      },
    };
  });

  const conflictingPositions: StatusProps[] | undefined = conflicting?.position?.map((conflict) => {
    const positionIdLabel = (
      <Text bold>
        <Identifier entity="position" id={conflict} />
      </Text>
    );
    return {
      key: `${prefix}conflicting`,
      icon: mdiShuffleVariant,
      color: "error",
      content: (
        <Text>
          {t("Appointment.alreadySignUpPositionInSameTime", {
            positionIdLabel,
          })}
        </Text>
      ),
      action: {
        action: routerLink(`/position/${conflict}`),
        label: t("action.show"),
      },
    };
  });

  const isAttendingInTime =
    queryEntity(AppointmentQ.User.isAttending(appointmentId)) && queryEntity(AppointmentQ.isTimeSlot(appointmentId));

  const appointmentTime = (
    <Text bold>
      {isAttendingInTime && dt.time(queryEntity(AppointmentQ.User.getAttendanceStartTime(appointmentId)))}
    </Text>
  );
  const status: StatusListProps["status"] = [
    conflictingAppointments,
    conflictingPositions,
    queryEntity(AppointmentQ.isFull(appointmentId)) &&
      !queryEntity(AppointmentQ.User.isAttending(appointmentId)) && {
        key: `${prefix}full`,
        icon: mdiAccountCancel,
        color: "neutral",
        content: t("Appointment.noFreePositions"),
      },
    queryEntity(AppointmentQ.User.isAttending(appointmentId)) &&
      !queryEntity(AppointmentQ.isTimeSlot(appointmentId)) && {
        key: `${prefix}attending`,
        icon: mdiCheck,
        color: "success",
        content: t("Appointment.alreadySignUp"),
      },
    isAttendingInTime && {
      key: `${prefix}attending-time-slot`,
      icon: mdiCheck,
      color: "success",
      content: (
        <StructuredText>
          <P>
            <Spacer>
              {t("Appointment.signUpForTimeNotice", {
                time: appointmentTime,
              })}
            </Spacer>
          </P>
          <P>{t("Appointment.comeAtTime")}</P>
        </StructuredText>
      ),
    },
  ];

  return <StatusList status={status} />;
}
