import { Box } from "@material-ui/core";
import { selectPosition, selectShift, Shift } from "@sinch/entity";
import { AsyncAutocompleteInput, CurrencyInput, FileInput, Form, SubmitButton, TextInput } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Grid, Identifier, identifierPrefix, Text } from "@sinch/ui";
import { min } from "date-fns";
import { T, values } from "ramda";
import React, { ReactElement } from "react";
import { requestPositionHistory } from "../../Position";
import { ReimbursementCreateFilesTarget, requestReimbursementCreate } from "./api";

interface ReimbursementFormProps {
  shiftId?: number;
}

export function ReimbursementForm({ shiftId }: ReimbursementFormProps): ReactElement {
  const { dt } = useFormat();
  return (
    <Form
      initialValues={{
        amount: 0,
        attachment: [],
        description: "",
        shift: shiftId || null,
      }}
      submitRequest={(formData) =>
        requestReimbursementCreate({
          ...formData,
          // @ts-ignore
          shift: shiftId || formData.shift?.id || null,
        })
      }
    >
      <Grid divider spacing="data" vertical>
        <Grid spacing="data" vertical>
          <CurrencyInput label={t("amount")} min={0} name="amount" required />
          <FileInput
            label={t("Reimbursement.proofOfExpenseLabel")}
            maxFiles={10}
            multiple
            name="attachment"
            note={t("Reimbursement.proofOfExpenseNote")}
            required
            target={ReimbursementCreateFilesTarget}
          />
          {!shiftId && (
            <AsyncAutocompleteInput<Shift & { startTime?: Date }, typeof requestPositionHistory>
              getOptionLabel={({ id, name }) => `${identifierPrefix("shift")}${id} ${name}`}
              getOptions={({ selectEntity, selectResult }): Shift[] => {
                const { positionIds } = selectResult();
                const shifts = selectEntity(selectPosition(positionIds, "shift"));
                return values(
                  selectEntity(selectShift(shifts)).map(({ id, ...props }) => {
                    const positionsDates = selectEntity(selectPosition({ shift: id }, "startTime"));
                    return { id, ...props, startTime: min(positionsDates) };
                  })
                ) as Shift[];
              }}
              getOptionSelected={({ id }, { id: val }) => id === val}
              label={t("Shift.title")}
              name="shift"
              onInputChange={T}
              renderOption={({ id, name, startTime }) => (
                <Box display="flex" flexDirection="column">
                  {/* @ts-ignore */}
                  <span>{`${dt.full(startTime)}`}</span>
                  <Text separator={" "}>
                    <Identifier entity="shift" id={id} />
                    <span>{`${name}`}</span>
                  </Text>
                </Box>
              )}
              request={(query) => requestPositionHistory({ searchQuery: query }, { page: 0, limit: 25 })}
              required
            />
          )}
          <TextInput
            label={t("description")}
            multiline
            name="description"
            note={t("Reimbursement.requestDescriptionInputNote")}
          />
        </Grid>
        <SubmitButton label={t("action.submit")} />
      </Grid>
    </Form>
  );
}
