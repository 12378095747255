import { cs, de, enGB, es, fr, it, nl, pl, pt, sk, uk } from "date-fns/locale";

// TODO: create dynamic locale load in webpack
export const availableLocales = {
  cs,
  "en-GB": enGB,
  en: enGB,
  de,
  es,
  "es-ES": es,
  fr,
  it,
  nl,
  pl,
  pt,
  "pt-PT": pt,
  sk,
  uk,
};
