import { mdiLink } from "@mdi/js";
import MuiGrid from "@material-ui/core/Grid";

import { isNil } from "ramda";
import React, { isValidElement, ReactElement, ReactNode } from "react";
import { Card, CardUnderlay } from "../Card";
import { CardProps } from "../Card/Card";
import { ListBase } from "../ListBase";
import { Text } from "../Text";
import { ConditionalWrapper } from "../Utils";

export interface CardListProps<T> {
  items: T[];
  cardProps?: (item: T) => CardProps;
  content: (item: T) => ReactNode | undefined;
  connectedCards?: (item: T) => T[];
  connectedTitle?: ReactNode | string;
  onClick?: (item: T) => void;
  separator?: (item: T, i: number) => ReactNode | undefined;
  decorator?: (children: ReactNode, item: T, i?: string | number) => ReactNode;
  onLongTouch?: (item: T) => void;
  itemId?: (item: T) => unknown;
}

export function CardList<T>({
  items,
  cardProps,
  content,
  connectedCards,
  connectedTitle,
  onClick,
  separator,
  decorator,
  onLongTouch,
  itemId,
}: CardListProps<T>): ReactElement {
  const handleClick = (item: T) => (): void => {
    if (onClick) onClick(item);
  };
  const handleLongTouch = (item: T) => (): void => {
    if (onLongTouch) onLongTouch(item);
  };

  return (
    <MuiGrid container direction="column" spacing={1} wrap="nowrap">
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {separator && <MuiGrid item>{separator(item, i)}</MuiGrid>}
          <MuiGrid
            // @ts-expect-error
            key={itemId ? itemId(item) : i}
            item
          >
            <ConditionalWrapper
              condition={!isNil(decorator)}
              // @ts-ignore
              wrapper={(children) => decorator(children, item, i)}
            >
              {connectedCards ? (
                <ConnectedCardList
                  action={handleClick}
                  connectedCards={connectedCards}
                  connectedTitle={connectedTitle}
                  content={content}
                  parentItem={item}
                >
                  <Card
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(cardProps ? cardProps(item) : {})}
                    action={handleClick(item)}
                    onLongTouch={handleLongTouch(item)}
                    small
                  >
                    {content(item)}
                  </Card>
                </ConnectedCardList>
              ) : (
                <Card
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(cardProps ? cardProps(item) : {})}
                  action={handleClick(item)}
                  onLongTouch={handleLongTouch(item)}
                  small
                >
                  {content(item)}
                </Card>
              )}
            </ConditionalWrapper>
          </MuiGrid>
        </React.Fragment>
      ))}
    </MuiGrid>
  );
}

interface ConnectedCardListProps<T> {
  children: ReactElement;
  connectedCards: (item: T) => T[];
  connectedTitle: ReactNode;
  action: (item: T) => () => void;
  content: CardListProps<T>["content"];
  parentItem: T;
}

function ConnectedCardList<T>({
  children,
  connectedCards,
  connectedTitle,
  action,
  content,
  parentItem,
}: ConnectedCardListProps<T>): ReactElement {
  return (
    <CardUnderlay
      card={children}
      header={
        <>
          {isValidElement(connectedTitle) ? (
            connectedTitle
          ) : (
            <Text bold small>
              {connectedTitle}
            </Text>
          )}
        </>
      }
      icon={mdiLink}
    >
      <ListBase<typeof parentItem>
        actionSelector={(item) => action(item)}
        contentSelector={(item) => <div>{content(item)}</div>}
        data={connectedCards(parentItem)}
        dense
        disablePadding
        // dividerSelector={T}
        // @ts-ignore
        idSelector={(item) => item}
      />
    </CardUnderlay>
  );
}
