import MuiGrid from "@material-ui/core/Grid";
import {
  mdiCalendarToday,
  mdiClockOutline,
  mdiMapMarker,
  mdiAccountMultiple,
  mdiCrown,
  mdiAccount,
  mdiCancel,
  mdiAmbulance,
  mdiTimerSandFull,
  mdiTimerSandEmpty,
  mdiCurrencyUsd,
  mdiStar,
} from "@mdi/js";

// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { QueryEntity, ResponseOf, useCurrentUser, useData } from "@sinch/core";
import {
  Fk,
  Position,
  PositionAttendanceStatus,
  PositionStatus,
  selectLocation,
  selectPosition,
  selectPositionAttendance,
  selectProfession,
  WorkerRole,
} from "@sinch/entity";
import { Format, t } from "@sinch/intl";
import { Card, Grid, IconText, routerLink, Spacer, TimeRange, useSpacing } from "@sinch/ui";

import { rejectFalsy } from "@sinch/utils";
import { max } from "date-fns";
import { equals, isNil, map, pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { SearchParamsPagination } from "../../components";
import { WorkerRoleOptions } from "../../Shift";
import { PositionReference } from "../Detail/PositionReference";
import { requestPositionHistory } from "./api";

type PositionQuery = QueryEntity<ResponseOf<typeof requestPositionHistory>>;

function getName(query: PositionQuery, position: Fk<Position>): ReactNode {
  return <PositionReference id={position} name noId title />;
}

function getDate(query: PositionQuery, position: Fk<Position>) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentUser = useCurrentUser();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, inner] = useSpacing();
  const { startTime, endTime } = query(
    selectPositionAttendance({
      position,
      worker: currentUser.id,
    })
  )[0];
  const { startTime: positionStartTime } = query(selectPosition(position));
  const realStartTime = max([positionStartTime, startTime]);

  return (
    <MuiGrid container spacing={inner}>
      <MuiGrid item xs="auto">
        <IconText icon={mdiCalendarToday}>
          <Format dt={realStartTime} />
        </IconText>
      </MuiGrid>
      <MuiGrid item xs>
        <IconText icon={mdiClockOutline}>
          <TimeRange endTime={endTime} showLength startTime={realStartTime} />
        </IconText>
      </MuiGrid>
    </MuiGrid>
  );
}

function getLocation(query: PositionQuery, position: Fk<Position>) {
  const location = query(selectPosition(position, "location"));
  const name = query(selectLocation(location, "name"));

  return <IconText icon={mdiMapMarker}>{name}</IconText>;
}

function getProfession(query: PositionQuery, position: Fk<Position>) {
  const { profession, role } = query(selectPosition(position));
  const professionName = query(selectProfession(profession, "name"));
  const roleName = WorkerRoleOptions()[role].label;
  const content = (
    <Spacer dropEmpty separator=" - ">
      {professionName}
      {roleName}
    </Spacer>
  );
  if (equals(role, WorkerRole.Backup))
    return (
      <IconText color="secondary" icon={mdiAccountMultiple}>
        {content}
      </IconText>
    );
  if (equals(role, WorkerRole.Crewboss))
    return (
      <IconText color="colorCrewboss" icon={mdiCrown}>
        {content}
      </IconText>
    );
  if (equals(role, WorkerRole.Worker)) return <IconText icon={mdiAccount}>{content}</IconText>;
  return undefined;
}

export function getStatus(query: PositionQuery, position: Fk<Position>): ReactElement | undefined {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentUser = useCurrentUser();

  const presence = query(
    selectPositionAttendance(
      {
        position,
        worker: currentUser.id,
      },
      "status"
    )
  )[0];
  switch (presence) {
    case PositionAttendanceStatus.Late:
      return (
        <IconText color="error" icon={mdiClockOutline}>
          {t("Attendance.presence.late") as string}
        </IconText>
      );
    case PositionAttendanceStatus.Absent:
      return (
        <IconText color="error" icon={mdiCancel}>
          {t("Attendance.presence.miss") as string}
        </IconText>
      );
    case PositionAttendanceStatus.Excused:
      return <IconText icon={mdiAmbulance}>{t("Attendance.presence.excused") as string}</IconText>;
    default:
      return undefined;
  }
}

function getPositionStatus(query: PositionQuery, position: Fk<Position>): ReactElement | undefined {
  const status = query(selectPosition(position, "status"));

  switch (status) {
    case PositionStatus.Finished:
      return (
        <IconText color="error" icon={mdiTimerSandFull}>
          {t("Position.closedStatusFinished") as string}
        </IconText>
      );
    case PositionStatus.CrewbossClosed:
      return (
        <IconText color="warning" icon={mdiTimerSandEmpty}>
          {t("Position.closedStatusCrewbossComplete") as string}
        </IconText>
      );
    default:
      return undefined;
  }
}

function getWageAndRating(query: PositionQuery, position: Fk<Position>): ReactElement {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentUser = useCurrentUser();
  const { wage, rating, status } = query(
    selectPositionAttendance({
      position,
      worker: currentUser.id,
    })
  )[0];

  return (
    <MuiGrid container>
      <MuiGrid item xs>
        <IconText color={status === PositionAttendanceStatus.Absent ? "error" : undefined} icon={mdiCurrencyUsd}>
          <Format curr={wage || 0} />
        </IconText>
      </MuiGrid>
      {!isNil(rating) && rating !== 0 && (
        <MuiGrid item xs>
          <IconText color={rating < 0 ? "error" : "success"} icon={mdiStar}>
            {((rating || 0) > 0 ? "+" : "") + rating}
          </IconText>
        </MuiGrid>
      )}
    </MuiGrid>
  );
}

const cardContentSelectors = [getPositionStatus, getDate, getLocation, getProfession, getStatus, getWageAndRating];

interface PositionProps {
  position: Fk<Position>;
}

function PositionCardContent({ position }: PositionProps): ReactElement {
  const { selectEntity } = useData(requestPositionHistory);

  const data = rejectFalsy(map((selector) => selector(selectEntity, position), cardContentSelectors));

  return (
    <>
      {data.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MuiGrid key={i} container spacing={1}>
          <MuiGrid item xs>
            {item}
          </MuiGrid>
        </MuiGrid>
      ))}
    </>
  );
}

const getAction = (query: PositionQuery, position: Fk<Position>) => routerLink(`/history/${position}`);

const getContent = (query: PositionQuery, position: Fk<Position>) => <PositionCardContent position={position} />;

/**
 * todo: fix pagination positioning (collides with floating button)
 *
 * todo: consider hiding rows per page select in mobile view
 *  since there is not much screen space available
 */
export function PositionHistoryMobile(): ReactElement {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionHistory);
  const { positionIds } = selectResult();

  const sortedPositionsIds = pipe<Position[], Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    reverse,
    pluck("id")
  )(selectEntity(selectPosition(positionIds)));

  return (
    <Grid spacing="outer" vertical>
      {sortedPositionsIds.map((position) => [
        <Card key={position} action={getAction(queryEntity, position)} small title={getName(selectEntity, position)}>
          {getContent(queryEntity, position)}
        </Card>,
      ])}
      <SearchParamsPagination />
    </Grid>
  );
}
