import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useData } from "../backend";
import { BusinessRules, requestSession } from "../contract";

const BusinessRulesContext = createCheckedContext<BusinessRules>(
  "BusinessRules"
);
export const { use: useBusinessRules } = BusinessRulesContext;

export function BusinessRulesProvider({
  children,
}: ChildrenProps): ReactElement {
  const { selectResult } = useData(requestSession);
  const { businessRules } = selectResult();

  return (
    <BusinessRulesContext value={businessRules}>
      {children}
    </BusinessRulesContext>
  );
}
