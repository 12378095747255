/* eslint-disable react/jsx-props-no-spreading */
import { Grid as MuiGrid } from "@material-ui/core";
import { useBusinessRules, useData } from "@sinch/core";
import {
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, useSpacing } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { includes } from "ramda";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../api";
import { useClosingStatusProps, useUpdatePresence } from "../hooks";

export function PresenceActions({
  id,
  onProgress,
}: {
  id: Fk<PositionAttendance>;
  onProgress: (id: Fk<PositionAttendance>) => void;
}): ReactElement {
  const [, , data] = useSpacing();
  const { setArrival, setBreak, setAbsent, setBreakEnd, setFinished } = useUpdatePresence(id);
  const { getProps, getLabel } = useClosingStatusProps();
  const { shiftClosingWorkBreaks } = useBusinessRules();
  const { selectEntity } = useData(requestPresenceView);
  const { progressStatus: status, position } = selectEntity(selectPositionAttendance(id));
  const { role, startTime } = selectEntity(selectPosition(position));

  const backup = role === WorkerRole.Backup;

  const btnProps = (closingStatus: ClosingProgress) => getProps(closingStatus, ["color", "icon"]);

  const setStatusPresent = () => {
    onProgress(id);
    setArrival();
  };

  const setStatusAbsent = () => {
    onProgress(id);
    setAbsent();
  };

  const setStatusBreak = () => {
    onProgress(id);
    setBreak();
  };

  const setStatusStopBreak = () => {
    onProgress(id);
    setBreakEnd();
  };

  const setStatusFinished = () => {
    onProgress(id);
    setFinished();
  };

  const buttons = rejectFalsy([
    (status === ClosingProgress.Pending || status === ClosingProgress.Absent) && (
      <Button
        action={setStatusPresent}
        {...btnProps(ClosingProgress.Present)}
        stretch
        tooltip={t<string>("Shift.closing.clockIn")}
        variant="contained"
      />
    ),
    status === ClosingProgress.Pending && (
      <Button
        action={setStatusAbsent}
        {...btnProps(ClosingProgress.Absent)}
        stretch
        tooltip={t<string>("Shift.closing.noShow")}
        variant="contained"
      />
    ),
    status === ClosingProgress.Present && shiftClosingWorkBreaks && !backup && (
      <Button
        action={setStatusBreak}
        {...btnProps(ClosingProgress.Break)}
        stretch
        tooltip={getLabel(ClosingProgress.Break)}
        variant="contained"
      />
    ),
    includes(status, [ClosingProgress.Present, ClosingProgress.AfterBreak]) && (
      <Button
        action={setStatusFinished}
        {...btnProps(ClosingProgress.Finished)}
        stretch
        tooltip={t<string>("Shift.closing.clockOut")}
        variant="contained"
      />
    ),
    status === ClosingProgress.Break && (
      <Button
        action={setStatusStopBreak}
        {...btnProps(ClosingProgress.AfterBreak)}
        stretch
        tooltip={t<string>("Shift.closing.endBreak")}
        variant="contained"
      />
    ),
  ]);

  return (
    <MuiGrid container justify="flex-end" spacing={data}>
      {buttons.map((button, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MuiGrid key={i} item>
          {button}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
