import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useData } from "@sinch/core";
import { ReadOnlyCopyInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Button, externalLink, InformationDialog, routerLink, useMobileLayout, useSpacing } from "@sinch/ui";
import * as Bowser from "bowser";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { requestCalendar } from "./api";

export function CalendarExportDialog(): ReactElement {
  const mobile = useMobileLayout();
  const { selectResult } = useData(requestCalendar);
  const { exportUrl } = selectResult();
  const [, inner] = useSpacing();

  const { pathname } = useLocation();
  const bowser = Bowser.getParser(window.navigator.userAgent); // Todo: make hook
  const isWebcalSupported = bowser.getOSName() !== "Android";

  return (
    <InformationDialog
      actions={
        mobile
          ? undefined
          : {
              cancel: false,
              confirm: {
                action: routerLink(pathname),
                label: t("action.close"),
              },
            }
      }
      title={t("Calendar.action.export")}
    >
      <MuiGrid container direction={mobile ? "column" : "row"} spacing={inner}>
        <MuiGrid item xs>
          <ReadOnlyCopyInput label={t("Calendar.syncUrl") /* Url pro synchronizaci */} name="title" value={exportUrl} />
        </MuiGrid>
        {isWebcalSupported && (
          <MuiGrid item>
            <Box mt={2}>
              <Button action={externalLink(`webcal:${exportUrl.replace(/^http(s?):/, "")}`)} color="secondary" large>
                <Box py={1}>{t("Calendar.openInCalendarApp")}</Box>
              </Button>
            </Box>
          </MuiGrid>
        )}
      </MuiGrid>
    </InformationDialog>
  );
}
