import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, PayoutMethod, selectPayoutMethod, selectSnippet, Snippet } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { ErrorInfoStrip } from "@sinch/ui";
import { filter, find, map, pluck, propEq } from "ramda";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Notice } from "../../Dashboard/components";
import { requestWalletStatus } from "../Wallet";
import { PayoutParams } from "./api";
import { PayoutRequestForm } from "./PayoutRequestForm";

interface PayoutRequestCreateProps {
  availablePayout: Fk<PayoutMethod>[];

  balance: number;
}

export function PayoutRequestCreate({ availablePayout, balance }: PayoutRequestCreateProps): ReactElement {
  const { curr } = useFormat();
  const { selectEntity } = useData(requestWalletStatus);

  const methods = selectEntity(selectPayoutMethod(availablePayout));
  const snippets = selectEntity(selectSnippet({}));

  const { state = {} } = useLocation();
  const { method: payoutMethod } = state || {};

  const overview = [
    {
      label: t("Wallet.display.walletBalance"),
      content: curr(balance),
    },
    {
      label: t("PayoutRequest.totalPayoutMonth"),
      content: map(({ currentlyPaid, name }) => `${curr(currentlyPaid ?? 0)} (${name})`, methods),
    },
    {
      label: t("Wallet.display.validContracts"),
      content: pluck("name", methods),
    },
  ];

  const findedMethod = find(propEq("id", payoutMethod))(methods) || methods[0];
  if (!findedMethod) {
    return <ErrorInfoStrip />;
  }
  const { maxAmount, id: method } = findedMethod;
  const grossAmount = Math.min(balance, maxAmount ?? Number.POSITIVE_INFINITY);

  const initialValues: PayoutParams = {
    files: null,
    grossAmount,
    method: payoutMethod || method,
  };

  return (
    <>
      {filter<Snippet>(propEq("name", "wallet_payout_request"), snippets).map(({ color, body, id, style }, idx) => (
        <Box pb={1}>
          <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
        </Box>
      ))}
      <PayoutRequestForm
        availablePayout={availablePayout}
        balance={balance}
        initialValues={initialValues}
        overview={overview}
      />
    </>
  );
}
