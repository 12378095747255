import { Box } from "@material-ui/core";
import { mdiAmbulance, mdiCancel, mdiClockOutline, mdiTimerSandEmpty, mdiTimerSandFull } from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import {
  Fk,
  Position,
  PositionAttendanceStatus,
  PositionStatus,
  selectLocation,
  selectPosition,
  selectPositionAttendance,
  selectProfession,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  createTextAlignCell,
  dateTimeLogic,
  Icon,
  Identifier,
  MuiDataTable,
  MuiDataTableDisplay,
  NumberStatusColor,
  Paper,
  routerLink,
  Spacer,
  Text,
  TypographyCell,
  useFullWidthContent,
} from "@sinch/ui";
import { differenceInSeconds, max } from "date-fns";
import { isEmpty, pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { rejectFalsy } from "../../../utils";
import { SearchParamsPagination } from "../../components";
import { PresenceOptions, WorkerRoleOptions } from "../../Shift/Closing/options";
import { PositionReference } from "../Detail/PositionReference";
import { requestPositionHistory } from "./api";

type PositionHistoryColumn = "date" | "hours" | "location" | "name" | "profession" | "rating" | "status" | "wage";

const TextAlignRightCell = createTextAlignCell("right");

/*
 * todo: how to properly specify hover color?
 */
const ClickableRow = createClickableRow((position: Fk<Position>) => routerLink(`/history/${position}`));

export function PositionHistory(): ReactElement {
  useFullWidthContent();
  const { curr, dt, dur } = useFormat();
  const currentUser = useCurrentUser();

  const { selectEntity, selectResult } = useData(requestPositionHistory);
  const { positionIds } = selectResult();

  const PositionHistoryDataTable = MuiDataTable.withConfig<Fk<Position>, PositionHistoryColumn>({
    name: {
      title: t("Shift.title"),
      selector: (id) => {
        const shift = selectEntity(selectPosition(id, "shift"));
        return (
          <Text bold>
            <Spacer dropEmpty separator=" ">
              <Identifier entity="shift" id={shift} />
              <PositionReference id={id} name noId title />
            </Spacer>
          </Text>
        );
      },
      logic: composeCellLogic<Fk<Position>, PositionHistoryColumn, MuiDataTableDisplay>([TypographyCell]),
    },
    date: {
      title: t("date"),
      selector: (id) => selectEntity(selectPosition(id, "startTime")),
      logic: composeCellLogic<Fk<Position>, PositionHistoryColumn, MuiDataTableDisplay>([dateTimeLogic()]),
    },
    hours: {
      title: t("time"),
      selector: (id) => {
        const { startTime, endTime } = selectEntity(
          selectPositionAttendance({
            position: id,
            worker: currentUser.id,
          })
        )[0];
        const { startTime: positionStartTime } = selectEntity(selectPosition(id));
        const realStartTime = max([positionStartTime, startTime]);
        return (
          <Box whiteSpace="nowrap">{`${dt.time(realStartTime)} - ${dt.time(endTime)} (${dur.narrow(
            differenceInSeconds(endTime, realStartTime, { roundingMethod: "ceil" })
          )})`}</Box>
        );
      },
    },
    location: {
      title: t("location"),
      selector: (id) => {
        const location = selectEntity(selectPosition(id, "location"));
        return selectEntity(selectLocation(location, "name"));
      },
    },
    profession: {
      title: t("Position.professionAndRole"),
      selector: (id) => {
        const { profession, role } = selectEntity(selectPosition(id));
        const professionName = selectEntity(selectProfession(profession, "name"));
        const roleName = WorkerRoleOptions()[role].label;
        return (
          <Spacer dropEmpty separator=" - ">
            {professionName}
            {roleName}
          </Spacer>
        );
      },
    },
    wage: {
      title: t("Wallet.wage"),
      titleCellProps: { align: "right" },
      selector: (id) => {
        const wage = selectEntity(
          selectPositionAttendance(
            {
              position: id,
              worker: currentUser.id,
            },
            "wage"
          )
        )[0];
        return <Box whiteSpace="nowrap">{wage ? curr(wage) : undefined}</Box>;
      },
      logic: composeCellLogic<Fk<Position>, PositionHistoryColumn, MuiDataTableDisplay>([TextAlignRightCell as never]),
    },
    rating: {
      title: t("Rating.title"),
      titleCellProps: { align: "right" },
      selector: (id) => {
        const rating = selectEntity(
          selectPositionAttendance(
            {
              position: id,
              worker: currentUser.id,
            },
            "rating"
          )
        )[0];
        return (
          <NumberStatusColor value={rating}>
            {rating ? (rating > 0 ? "+" : "") + rating : <Text color="neutral">+0</Text>}
          </NumberStatusColor>
        );
      },
      logic: composeCellLogic<Fk<Position>, PositionHistoryColumn, MuiDataTableDisplay>([TextAlignRightCell as never]),
    },
    status: {
      selector: (id) => {
        const response = [];
        const presence = selectEntity(
          selectPositionAttendance(
            {
              position: id,
              worker: currentUser.id,
            },
            "status"
          )
        )[0];
        const status = selectEntity(selectPosition(id, "status"));
        switch (presence) {
          case PositionAttendanceStatus.Late:
            response.push(
              <Icon
                color="error"
                icon={mdiClockOutline}
                tooltip={PresenceOptions()[PositionAttendanceStatus.Late].label}
              />
            );
            break;
          case PositionAttendanceStatus.Absent:
            response.push(
              <Icon color="error" icon={mdiCancel} tooltip={PresenceOptions()[PositionAttendanceStatus.Absent].label} />
            );
            break;
          case PositionAttendanceStatus.Excused:
            response.push(
              <Icon
                color="error"
                icon={mdiAmbulance}
                tooltip={PresenceOptions()[PositionAttendanceStatus.Excused].label}
              />
            );
            break;
          default:
            break;
        }

        if (status === PositionStatus.Finished) {
          response.push(<Icon color="error" icon={mdiTimerSandFull} tooltip={t("Position.closedStatusFinished")} />);
        } else if (status === PositionStatus.CrewbossClosed) {
          response.push(
            <Icon color="warning" icon={mdiTimerSandEmpty} tooltip={t("Position.closedStatusCrewbossComplete")} />
          );
        }
        return (
          <Box display="flex" flexWrap="noWrap">
            {response}
          </Box>
        );
      },
      logic: composeCellLogic<Fk<Position>, PositionHistoryColumn, MuiDataTableDisplay>([TextAlignRightCell as never]),
    },
  }).withLogic(composeRowLogic([ClickableRow]));

  const sortedPositionsIds = pipe<Position[], Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    reverse,
    pluck("id")
  )(selectEntity(selectPosition(positionIds)));

  const haveWage = !isEmpty(rejectFalsy(selectEntity(selectPositionAttendance({}, "wage"))));

  return (
    <Paper>
      <PositionHistoryDataTable
        columns={rejectFalsy([
          "name",
          "date",
          "hours",
          "location",
          "profession",
          haveWage && "wage",
          "rating",
          "status",
        ])}
        data={sortedPositionsIds}
        size="small"
      >
        <SearchParamsPagination />
      </PositionHistoryDataTable>
    </Paper>
  );
}
