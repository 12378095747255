import { makeStyles } from "@material-ui/core/styles";
import { Theme as DefaultTheme } from "@material-ui/core/styles/createMuiTheme";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Styles } from "@material-ui/styles/withStyles";
import { isString } from "@sinch/utils";
import clsx from "clsx";
import { assocPath, filter, map } from "ramda";
import React, { ComponentProps, ReactElement } from "react";
import { DataTableCell } from "../DataTableCell";
import {
  DataTableCellLogic,
  DataTableCellProps,
  DataTableDisplay,
  DataTableProps,
} from "../types";

// todo: fix extraneous dependencies warning

/* eslint-disable react/jsx-props-no-spreading */

/**
 * At the moment supporting only `makeStyles` where the passed `styles`
 * do not depend on props (see {@link makeStyles} docs for implementation).
 *
 * todo: classKeySelector doesn't accept information about column index
 *  so at the moment this is effectively the same as RowLogic
 *  -> update selector params, maybe pass (row, column, data)?
 */
export function createMuiStyleCell<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>,
  Theme = DefaultTheme,
  ClassKey extends string = string
>(
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  stylesheetFactory: Styles<Theme, {}, ClassKey>,
  classKeySelector: (
    row: number,
    column: TColumn,
    data: TData[]
  ) => (ClassKey | false | null | undefined)[]
): DataTableCellLogic<TData, TColumn, TDisplay> {
  const useStyles = makeStyles(stylesheetFactory);

  return function MuiStyleCell({
    table,
    row,
    column,
  }: DataTableCellProps<TData, TColumn, TDisplay>): ReactElement {
    const styles = useStyles();
    const toClassName = (key: ClassKey) => styles[key];

    const { config, data, display } = table;
    const { Cell } = display;
    // todo: set defaults on container initialization
    const CellLogic = config[column].logic || DataTableCell;

    const classKeys = classKeySelector(row, column, data);

    const classNames = map(
      toClassName,
      filter(isString, classKeys as ClassKey[])
    );

    /*
     * todo: can we support generics to type display component props?
     *
     * todo: is there any pattern to simplify defining props in this way?
     */
    const DisplayCell = (props: ComponentProps<TDisplay["Row"]>) => (
      /* eslint-disable-next-line react/destructuring-assignment */
      <Cell {...props} className={clsx(props.className, classNames)} />
    );
    const updateDisplay: (
      props: DataTableProps<TData, TColumn, TDisplay>
    ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(
      ["display", "Cell"],
      DisplayCell
    );

    return <CellLogic column={column} row={row} table={updateDisplay(table)} />;
  };
}
