import { defineRequest } from "@sinch/core";
import {
  Appointment,
  AppointmentAttendanceContainer,
  AppointmentContainer,
  CompanyContainer,
  Fk,
  LocationContainer,
  Position,
  PositionContainer,
  ProfessionContainer,
  ShiftContainer,
  Snippet,
  SnippetContainer,
  UserContainer,
} from "@sinch/entity";

/**
 * todo: weird response props, either make optional all lists or none
 *
 * todo: AttendanceList request can be independent context
 *       (does not need to be contained in Dashboard)
 */
export const requestDashboardAttendanceList = defineRequest("Dashboard/AttendanceList")
  .withEmptyParams()
  .withResponse<{
    availableAppointmentCount: number;
    availablePositionsCount: number;
    confirmationPositionIds: Fk<Position>[];
    confirmationApplicantPositionIds: Record<Fk<Position>, Fk<Position>[]>;
    feedbackPositionIds: Fk<Position>[];
    positionIdsToClose?: Fk<Position>[];
    incomingAppointmentIds?: Fk<Appointment>[];
    incomingPositionIds: Fk<Position>[];
    applicantPositionIds: Fk<Position>[];
    noticeIds?: Fk<Snippet>[];
    nearestPositions: Fk<Position>[];
  }>()
  .withResponseEntity<
    AppointmentContainer &
      AppointmentAttendanceContainer &
      CompanyContainer &
      LocationContainer &
      PositionContainer &
      ProfessionContainer &
      ShiftContainer &
      SnippetContainer &
      UserContainer
  >()
  .withDefaultCreator();
