import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";
import { currencyFormatter, FormatCurrency } from "./currency";
import { DateFormatVariant, dateTimeFormatter, FormatDate } from "./dateTime";
import { DurationFormatVariant, durationTimeFormatter, FormatDuration } from "./durationTime";
import { FormatNumber, numberFormatter, NumberFormatVariant } from "./number";
import { FormatRelativeTime, relativeTimeFormatter } from "./relativeTime";

interface Format {
  curr: FormatCurrency;

  dt: FormatDate & Record<DateFormatVariant, FormatDate>;

  num: FormatNumber & Record<NumberFormatVariant, FormatNumber>;

  rt: FormatRelativeTime;

  dur: FormatDuration & Record<DurationFormatVariant, FormatDuration>;
}

const FormatContext = createCheckedContext<Format>("Format");
export const { use: useFormat } = FormatContext;

/**
 * todo: make types strict using literal unions of supported values
 */
interface FormatProviderProps extends ChildrenProps {
  currency: string;

  locale: string;

  timeZone: string;

  language: string;
}

export function FormatProvider({ children, currency, locale, timeZone, language }: FormatProviderProps): ReactElement {
  const curr = useMemo(() => currencyFormatter(locale, currency), [currency, locale]);
  const dt = useMemo(() => dateTimeFormatter(locale, language, timeZone), [locale, language, timeZone]);
  const num = useMemo(() => numberFormatter(locale), [locale]);
  const rt = useMemo(() => relativeTimeFormatter(locale), [locale]);

  const dur = useMemo(() => durationTimeFormatter(locale), [locale]);

  const context = useMemo(() => ({ curr, dt, num, rt, dur }), [curr, dt, num, rt, dur]);

  return <FormatContext value={context}>{children}</FormatContext>;
}
