import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiMenuItem from "@material-ui/core/MenuItem";
import MuiMenuList from "@material-ui/core/MenuList";
import MuiPopper, { PopperProps } from "@material-ui/core/Popper";
import { Indicator, isPageItem, isRouteItem, MenuNavigationItem, useStatus } from "@sinch/core";
import { Callback } from "@sinch/types";
import { find, propEq } from "ramda";
import React, { MutableRefObject, ReactElement } from "react";
import { externalLink, resolveAction, routerLink } from "../../actions";
import { Chip } from "../../Chip";
import { Paper } from "../../Paper";
import { useStyles } from "./style";

interface SectionPopupProps {
  anchorRef: MutableRefObject<PopperProps["anchorEl"]>;

  items: MenuNavigationItem[];

  onClose: Callback;

  open: boolean;
}

export function SectionPopup({ anchorRef, items, onClose, open }: SectionPopupProps): ReactElement {
  const styles = useStyles();
  // const withClose = prepend<Action>(() => onClose());
  const { indicators } = useStatus();

  return (
    <MuiPopper anchorEl={anchorRef.current} onMouseLeave={onClose} open={open} placement="right-start">
      <Paper className={styles.popupMenu}>
        <MuiClickAwayListener onClickAway={onClose}>
          <MuiMenuList autoFocusItem={open}>
            {items.map((item) => {
              const { id, name } = item;

              /* eslint-disable-next-line no-nested-ternary */
              const action = isRouteItem(item)
                ? routerLink(item.path)
                : isPageItem(item)
                ? routerLink(`/page/${item.page}`)
                : externalLink(item.url);

              const actionProps = resolveAction(action);

              const indicator = find<Indicator>(propEq("key", id), indicators);

              if (indicator?.hidden) {
                return null;
              }

              /* eslint-disable react/jsx-props-no-spreading */
              return (
                <MuiMenuItem className={styles.popupMenuItem} disableGutters {...actionProps} key={id}>
                  <MuiListItemText>{name}</MuiListItemText>
                  {indicator && <Chip color={indicator.color} label={indicator.content} />}
                </MuiMenuItem>
              );
            })}
          </MuiMenuList>
        </MuiClickAwayListener>
      </Paper>
    </MuiPopper>
  );
}
