import {
  DataProvider,
  requestLanguageUpdate,
  responseHandlerKey,
  useCurrentUser,
  useInstanceSettings,
  useRequest,
  useSession,
} from "@sinch/core";
import { SelectInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Key, SelectFieldOption } from "@sinch/types";
import { useSnackbar } from "@sinch/ui";
import { map } from "ramda";
import React, { ReactElement, useMemo } from "react";

interface LanguageSelectProps {
  options: SelectFieldOption[];

  value: Key;
}

function LanguageSelectInput({ options, value }: LanguageSelectProps): ReactElement {
  const dispatch = useRequest();

  return (
    <SelectInputBase
      label={t("language")}
      name="language"
      onChange={(event) => {
        dispatch(requestLanguageUpdate(event.target.value));
      }}
      options={options}
      value={value.replace("-", "_")}
    />
  );
}

/**
 * todo: response handler for core requests should be probably defined
 *  in root provider instead so we don't have to refresh session explicitly
 */
export function LanguageSelect(): ReactElement {
  const snackbar = useSnackbar();
  const { refresh } = useSession();
  const { language: value } = useCurrentUser();
  const { languages } = useInstanceSettings();

  const options = useMemo(
    () =>
      map(
        ({ name, code }) => ({
          label: name,
          value: code,
        }),
        languages
      ),
    [languages]
  );

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestLanguageUpdate,
        () => {
          snackbar("success", t("Profile.languageChangeSuccess"));
          refresh();
        },
        () => {
          snackbar("error", t("Profile.languageChangeError"));
        }
      ),
    [refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <LanguageSelectInput options={options} value={value} />
    </DataProvider>
  );
}
