import { mdiClose } from "@mdi/js";
import { darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { FileInfo, useFiles } from "@sinch/core";
import { Callback, FileHash, Nullable, OneOrMore } from "@sinch/types";
import { ChildrenProps, fileSize, flatList } from "@sinch/utils";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Optional } from "utility-types";
import { Anchor } from "./Anchor";
import { IconButton } from "./Button";
import { FileIcon } from "./FileIcon";
import { Grid } from "./Grid";
import { Text } from "./Text";

const useStyles = makeStyles(({ palette, spacing }) => ({
  item: {
    display: "flex",
    alignItems: "stretch",
    backgroundColor: palette.background.default,

    "&:hover": {
      backgroundColor: darken(palette.background.default, 0.05),

      "& > a": {
        color: palette.primary.main,
      },
    },

    "& button": {
      "&:hover": {
        color: palette.error.dark,
      },
    },

    "& > *": {
      padding: spacing(1),
    },

    "& > a": {
      flexGrow: 1,

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      color: palette.secondary.main,

      "& > :nth-child(2)": {
        flexGrow: 1,

        marginLeft: spacing(1),
      },

      "& > :nth-child(3)": {
        color: palette.text.hint,
      },
    },
  },
  alwaysBreak: {
    wordBreak: "break-all",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

export interface FileListItemProps extends Optional<FileInfo, "hash" | "size" | "type"> {
  /**
   * Wrap item with HTML anchor element.
   */
  href?: boolean;

  onRemove?: Callback;

  progress?: number;
}

export function FileListItem({ href, name, onRemove, progress, size, type, url }: FileListItemProps): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.item}>
      <Anchor href={href ? url : undefined}>
        <FileIcon type={type} />
        <Text bold className={styles.alwaysBreak}>
          {name}
        </Text>
        <Text className={styles.noWrap} small>
          {fileSize(size)}
          {progress && `(${progress} %)`}
        </Text>
      </Anchor>
      {onRemove && <IconButton action={onRemove} icon={mdiClose} size="small" />}
    </div>
  );
}

export function FileListContainer({ children }: ChildrenProps): Nullable<ReactElement> {
  if (isNilOrEmpty(children)) return null;

  return (
    <Grid spacing="data" vertical>
      {children}
    </Grid>
  );
}

export interface FileListProps {
  files: OneOrMore<FileHash>;
}

export function FileList({ files }: FileListProps): ReactElement {
  const storage = useFiles();

  return (
    <FileListContainer>
      {flatList(files).map((file) => (
        <FileListItem
          key={file}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...storage(file)}
          href
        />
      ))}
    </FileListContainer>
  );
}
