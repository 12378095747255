import { Fk, Menu, selectMenu } from "@sinch/entity";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { evolve, flatten, map } from "ramda";
import { isNonEmptyArray } from "ramda-adjunct";
import React, { ReactElement, useMemo } from "react";
import { Overwrite } from "utility-types";
import { FileInfo, useData, useFiles } from "../backend";
import { InstanceSettings as InstanceSettingsSession, InstanceTheme, requestSession } from "../contract";
import { MenuItem } from "./MenuItem";

interface InstanceSettings extends Omit<InstanceSettingsSession, "menu" | "theme"> {
  menu: MenuItem[];
  theme: Overwrite<
    InstanceTheme,
    {
      logo: FileInfo;
      mobileLogo: FileInfo;
      toolbarColor: string;
    }
  >;
}

const InstanceSettingsContext = createCheckedContext<InstanceSettings>("InstanceSettings");
export const { use: useInstanceSettings } = InstanceSettingsContext;

interface InstanceSettingsProviderProps extends ChildrenProps {
  menu?: MenuItem[];
}

export function InstanceSettingsProvider({ children }: InstanceSettingsProviderProps): ReactElement {
  const staticMenuList: [] = [];

  const storage = useFiles();
  const { selectEntity, selectResult } = useData(requestSession);
  const { instanceSettings, menu: menuList } = selectResult();

  const context = useMemo(() => {
    /**
     * todo: normalize/denormalize functionality can be integrated directly
     *  into entity definitions after migrating from interfaces to runtime
     *  objects containing selectors, references, validators, formatters, etc.
     */
    function denormalize(id: Fk<Menu>): MenuItem {
      const item = selectEntity(selectMenu(id));

      const { menu } = item;

      const submenu = isNonEmptyArray(menu) ? { menu: map(denormalize, menu) } : undefined;

      return {
        ...item,
        ...submenu,
      } as MenuItem;
    }

    const { theme, ...settings } = instanceSettings;
    const menuTree = map(denormalize, menuList);

    const resolveFiles = evolve({
      logo: storage,
      mobileLogo: storage,
    });

    return {
      ...settings,
      menu: flatten([staticMenuList, { divider: true }, menuTree]) as MenuItem[],
      theme: resolveFiles(theme),
    };
  }, [storage, instanceSettings, selectEntity, staticMenuList]);

  return <InstanceSettingsContext value={context}>{children}</InstanceSettingsContext>;
}
