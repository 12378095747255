import { useData } from "@sinch/core";
import { selectAppointment } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Card, GridLayout, Header } from "@sinch/ui";
import { isNil, max } from "ramda";
import React, { ReactElement } from "react";
import { requestAppointmentDetail } from "./api";

export function AppointmentDetailCapacity(): ReactElement | null {
  const { selectEntity, selectResult } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const { freeCapacity, totalCapacity } = selectEntity(selectAppointment(appointmentId));

  if (isNil(freeCapacity) || isNil(totalCapacity)) {
    return null;
  }

  const capacity = (
    <span>
      <Header component="span">{max(0, totalCapacity - freeCapacity)}/</Header>
      {totalCapacity}
    </span>
  );

  return (
    <GridLayout.Item>
      <Card center>
        <Header level={2}>
          {t("Appointment.capacityHeader", {
            capacity,
          })}
        </Header>
      </Card>
    </GridLayout.Item>
  );
}
