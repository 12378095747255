import { makeStyles } from "@material-ui/core/styles";
import { Color, IconId, OneOrMore } from "@sinch/types";
import React, { Children, ReactChild, ReactElement } from "react";
import { isElement } from "react-dom/test-utils";
import { Grid } from "../Grid";
import { Icon } from "../Icon";
import { IdentifierColorKey } from "../paletteStyles";
import { Text } from "../Text";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
  },
  icon: {
    position: "relative",
    top: "-0.1em",
    paddingRight: theme.spacing(1),
    // todo: review color
    color: theme.palette.grey[400],
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

export interface DataGridItemProps {
  color?: Color | IdentifierColorKey;

  content?: OneOrMore<ReactChild>;

  icon?: ReactElement | IconId;
}

export function DataGridItem({ color, content, icon }: DataGridItemProps): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.item}>
      {icon && (
        <div className={styles.icon}>{isElement(icon) ? icon : <Icon color={color} icon={icon} size="small" />}</div>
      )}
      <div className={styles.content}>
        {Children.map(content, (row) => (
          <div>
            <Text color={color} small>
              {row}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

export interface DataGridProps {
  data: DataGridItemProps[];

  small?: boolean;
}

/**
 * todo: support content formatting and icon colors
 *
 * todo: consider extracting icon box to share with data list?
 *
 * todo: support expansible content
 */
export function DataGrid({ data, small }: DataGridProps): ReactElement {
  return (
    <Grid sizes={small ? 12 : 4} spacing="data">
      {data.map(({ icon, content, color }, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <DataGridItem key={i} color={color} content={content} icon={icon} />
      ))}
    </Grid>
  );
}
