import { makeStyles } from "@material-ui/core/styles";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement } from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
  },
  horizontal: {
    justifyContent: "center",
  },
  vertical: {
    alignItems: "center",
  },
});

export interface CenterProps extends ChildrenProps {
  horizontal?: boolean;

  vertical?: boolean;
}

/**
 * @deprecated todo: replace with properly styled components
 */
export function Center({
  children,
  horizontal,
  vertical,
}: CenterProps): ReactElement {
  const styles = useStyles();

  const both = !horizontal && !vertical;

  return (
    <div
      className={clsx(styles.root, {
        [styles.horizontal]: horizontal || both,
        [styles.vertical]: vertical || both,
      })}
    >
      {children}
    </div>
  );
}
