import { ChildrenProps, isUndefined } from "@sinch/utils";
import { mergeRight } from "ramda";
import React, { ReactElement } from "react";
import { createSeparator } from "./createSeparator";
import { TextParams } from "./TextParams";
import { TextParamsProvider, useStructuredText, useTextParams } from "./TextParamsProvider";
import { Typography } from "./Typography";

/**
 * Text component is elementary display component meant to be used by
 * presentational components. Since the purpose of elementary components is to
 * define styles, they should apply theme typography directly instead of using
 * Text as intermediary component. Application components should ideally render
 * all text using only parametrized presentational components.
 *
 * todo: enable using regular (b|i|u|s) html components instead of enforcing
 *  custom react wrappers, implement replacing child components in Text context
 *  tree to enforce formatting in whole scope instead
 *  (see example code above)
 */
export function Text({ children, ...ownProps }: TextParams & ChildrenProps): ReactElement {
  /* eslint-disable no-nested-ternary,react/jsx-props-no-spreading */

  const structured = useStructuredText();
  const contextParams = useTextParams();
  const textParams = mergeRight(contextParams, ownProps);

  const separate = createSeparator(textParams);

  const isRoot = isUndefined(contextParams) || structured;
  const component = isRoot ? (structured ? "p" : "div") : "span";

  return (
    <TextParamsProvider {...textParams}>
      <Typography component={component} {...textParams}>
        {separate(children)}
      </Typography>
    </TextParamsProvider>
  );
}
