import { defineRequest } from "@sinch/core";
import { Fk, PayoutComponent, PayoutMethod } from "@sinch/entity";

export const requestWalletPayoutCalculation = defineRequest("Wallets/PayoutCalculation")
  .withParams<{
    grossAmount: number;
    method: Fk<PayoutMethod>;
  }>()
  .withResponse<{
    components: PayoutComponent[];
    grossAmount: number;
    netAmount: number;
  }>()
  .withCreator((method: Fk<PayoutMethod>, grossAmount: number) => ({
    params: { grossAmount, method },
  }));
