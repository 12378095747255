import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";
import { createSeparator } from "./createSeparator";
import { useStyles } from "./styles";
import { ColorParams } from "./TextParams";
import { useTextParamsRequired } from "./TextParamsProvider";

type StyleKey = keyof ReturnType<typeof useStyles>;

function createFormat(name: string, style: StyleKey) {
  return function Format({ children }: ChildrenProps): ReactElement {
    const styles = useStyles();
    const textParams = useTextParamsRequired(name);
    const separate = createSeparator(textParams);

    return <span className={styles[style]}>{separate(children)}</span>;
  };
}

export const B = createFormat("B", "bold");
export const I = createFormat("I", "italic");
export const U = createFormat("U", "underline");
export const S = createFormat("S", "strikethrough");

export function C({
  color,
  children,
}: Required<ColorParams> & ChildrenProps): ReactElement {
  const styles = useStyles();
  const textParams = useTextParamsRequired("C");
  const separate = createSeparator(textParams);

  return <span className={styles[color]}>{separate(children)}</span>;
}
