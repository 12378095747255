import { Nullable } from "@sinch/types";
import { useMounted } from "@sinch/utils";
import { useEffect, useMemo, useState } from "react";
import { RequestCreator, RequestOf, ResponseOf } from "../contract";
import { responseHandlerKey } from "../handler";
import { useCachedResponse } from "./CachedResponseProvider";
import { useOnlineStatus } from "./OnlineStatusProvider";
import { RefreshToken } from "./useRefresh";
import { useRequest } from "./useRequest";

export type RequestState<TCreator extends RequestCreator> = Nullable<ResponseOf<TCreator>>;

/**
 * Used by ResponseProvider to dispatch request and wait for the response.
 *
 * State is used to display loading/success/error component properly, as opposed
 * to regular {@link useRequest} with response handler allowing for async logic.
 */
export function useRequestState<TCreator extends RequestCreator>(
  request: RequestOf<TCreator>,
  refresh?: RefreshToken,
  cache?: boolean
): RequestState<TCreator> {
  const mounted = useMounted();
  const dispatch = useRequest();
  const { isOnline } = useOnlineStatus();

  const [cached, setCached] = useCachedResponse();
  const [state, setState] = useState<RequestState<TCreator>>(cache ? cached[request.requestHash] || null : null);

  const onResponse = useMemo(
    () =>
      responseHandlerKey({ key: request.key } as TCreator, ({ response }) => {
        mounted(() => {
          setState(response);
        });
      }),
    [mounted, request.key]
  );

  useEffect(() => {
    if (isOnline) {
      dispatch(request, onResponse);
    }
  }, [dispatch, onResponse, refresh, request]);

  useEffect(() => {
    if (cache) {
      setCached((cacheState) => ({ ...cacheState, [request.requestHash]: state }));
    }
  }, [cache, state]);

  return cached[request.requestHash] || state;
}
