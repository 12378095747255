import { Maybe, Nullable, Value } from "@sinch/types";
import { assoc } from "ramda";
import { isUndefined } from "ramda-adjunct";
import { createContext, Provider, useContext } from "react";

/**
 * Create a named context provider with associated hook to access the value
 * or throw an error when called outside of provider scope.
 */
export function createCheckedContext<T extends Nullable<Value>>(
  name: string
): Provider<T> & { use: () => T | never; check: () => boolean } {
  const Context = createContext<Maybe<T>>(undefined);
  Context.displayName = name;

  function useSafeContext() {
    const value = useContext(Context);

    if (isUndefined(value)) {
      throw new Error(`Cannot access context value outside of its Provider scope (${name})`);
    }

    return value;
  }

  function useCheckContext() {
    const value = useContext(Context);

    if (isUndefined(value)) {
      return false;
    }

    return true;
  }

  return assoc("check", useCheckContext, assoc("use", useSafeContext, Context.Provider as Provider<T>));
}
