import { Alert, AlertTitle } from "@material-ui/lab";
import { mdiAlert, mdiBugOutline } from "@mdi/js";

import * as Sentry from "@sentry/react";
import { ServerMessage, Suggestions } from "@sinch/core";
import { t } from "@sinch/intl";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { is } from "ramda";
import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { useDialog } from "../ApplicationLayout";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { RowsGrid } from "../Layout";

type FallbackError = boolean | string | { status: number; messages: ServerMessage[] };

interface ErrorHandlerContext {
  handleError: () => void;
}

const ErrorBoundaryContext = createCheckedContext<{
  error: FallbackError;
  setError: Dispatch<SetStateAction<FallbackError>>;
}>("ErrorBoundary");
export const { use: useErrorHandler } = ErrorBoundaryContext;

interface FallbackProps {
  error?: Error;
}

export function ErrorInfoStrip({ error }: FallbackProps): ReactElement {
  const dialog = useDialog();

  return (
    <>
      <Alert icon={<Icon icon={mdiAlert} size="large" />} severity="error">
        <RowsGrid spacing={2}>
          <AlertTitle>{t("Application.errorBoxCommon")}</AlertTitle>
          {error?.message}
          <Button action={dialog.open} color="error" icon={mdiBugOutline} stretch variant="outlined">
            {t("Suggestion.suggestionTitle")}
          </Button>
        </RowsGrid>
      </Alert>

      {dialog.state && <Suggestions dialog={dialog} />}
    </>
  );
}

interface ErrorBoundaryProps extends ChildrenProps {
  fallback?: (props: FallbackProps) => ReactElement;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  resetDependencies?: any[];
}

/**
 * todo: consider reexporting whole `react-error-boundary` instead
 */
export function ErrorBoundary({
  children,
  fallback = ({ error }) => <ErrorInfoStrip error={error} />,
  resetDependencies,
}: ErrorBoundaryProps): ReactElement {
  const [error, setError] = useState<FallbackError>(false);

  let errorString = "";
  if (is(String, error)) {
    errorString = error as string;
  } else if (is(Object, error)) {
    // @ts-ignore
    errorString = error?.messages?.map(({ text }) => text).join("\n");
  }

  return (
    <ErrorBoundaryContext value={{ error, setError }}>
      <Sentry.ErrorBoundary fallback={fallback}>
        {error ? fallback({ error: new Error(errorString) }) : children}
      </Sentry.ErrorBoundary>
    </ErrorBoundaryContext>
  );
}
