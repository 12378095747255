import { makeStyles } from "@material-ui/core";
import { Color } from "@sinch/types";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { createPaletteStyles, getEntityColors, IdentifierColorKey } from "../paletteStyles";

const paletteStyles = createPaletteStyles(({ main }) => ({
  backgroundColor: main,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(0.5),
    height: "100%",
    flexShrink: 0,
  },
  ...paletteStyles(theme),
  ...getEntityColors(theme, true),
}));

export interface StatusIndicatorProps {
  color: Color | IdentifierColorKey;
}

/**
 * todo: support horizontal and vertical variants
 */
export function StatusIndicator({ color }: StatusIndicatorProps): ReactElement {
  const styles = useStyles();

  return <div className={clsx(styles.root, color && styles[color])} />;
}
