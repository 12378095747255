import { darken, lighten, PaletteColorOptions, PaletteType } from "@material-ui/core";
import { PaletteColor, PaletteOptions } from "@material-ui/core/styles/createPalette";

/**
 * todo: review palette
 *  - try to override via material options (text, action)
 *  - minimize amount of custom colors
 *  - sort custom colors into categories
 *  https://material-ui.com/customization/palette/#adding-new-colors
 */
export const palette: PaletteOptions = {
  background: {
    default: "#f2f2f2",
    paper: "#ffffff",
  },
  divider: "#f2f2f2",
  text: {
    primary: "#404040",
    secondary: "#a0a0a0",
    disabled: "#a0a0a0",
    hint: "#a0a0a0",
  },

  action: {
    disabledBackground: "rgba(0,0,0,0.04)",
    hover: "rgba(0,0,0,0.08)",
    hoverOpacity: 0.08,
  },

  primary: {
    main: "#023746",
  },
  secondary: {
    main: "#026d7f",
  },

  error: {
    main: "#b00020",
  },
  warning: {
    main: "#cc6600",
  },
  success: {
    main: "#009933",
  },
  info: {
    main: "#1976d0",
  },
  neutral: {
    main: "#bcbcbc",
  },

  note: {
    main: "#eef0f0",
    contrastText: "inherit",
  },
  applied: {
    main: lighten("#8e44ad", 0.8),
  },
  confirmationNeeded: {
    main: lighten("#cc6600", 0.9),
  },
  completed: {
    main: "#e8f7e8",
  },
  dividerDark: {
    main: "#e4e4e4",
  },
  identifiers: {
    order: {
      main: "#E87E04",
    },
    job: {
      main: "#35aa47",
    },
    shift: {
      main: "#4B8DF8",
    },
    position: {
      main: "#8E44AD",
    },
    applicant: {
      main: "#8e44ad",
    },
    crewboss: {
      main: "#F9A602",
      contrastText: "#FFFFFF",
    },
    break: {
      main: "#9999cd",
      contrastText: "#FFFFFF",
    },
    pending: {
      main: "#8d8d8d",
      contrastText: "#000000",
    },
  },
};

export const darkPalette: PaletteOptions = {
  type: "dark",
  background: {
    default: "#303030",
    paper: "#191919",
  },

  text: {
    disabled: "#666666",
  },

  action: {
    disabledBackground: "rgba(255, 255, 255, 0.04)",
  },

  primary: {
    main: "#0583a6",
  },
  secondary: {
    main: "#04b9d9",
  },

  error: {
    main: "#b00020",
  },
  warning: {
    main: "#cc6600",
  },
  success: {
    main: "#009933",
  },
  info: {
    main: "#0583a6",
  },
  neutral: {
    main: "#bcbcbc",
  },

  note: {
    main: "#110f0f",
    contrastText: "inherit",
  },
  applied: {
    main: darken("#8e44ad", 0.7),
  },
  confirmationNeeded: {
    main: darken("#cc6600", 0.65),
  },
  completed: {
    main: "#123512",
  },
  dividerDark: {
    main: "rgba(255, 255, 255, 0.12)",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  identifiers: {
    order: {
      main: "#E87E04",
    },
    job: {
      main: "#35aa47",
    },
    shift: {
      main: "#4B8DF8",
    },
    position: {
      main: "#8E44AD",
    },
    applicant: {
      main: "#8e44ad",
    },
    crewboss: {
      main: "#cc6600",
      contrastText: "#FFFFFF",
    },
    break: {
      main: "#9999cd",
      contrastText: "#FFFFFF",
    },
    pending: {
      main: "#bcbcbc",
      contrastText: "#000000",
    },
  },
};

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    type: PaletteType;

    neutral: PaletteColor;

    note: PaletteColor;

    completed: PaletteColor;
    confirmationNeeded: PaletteColor;
    applied: PaletteColor;

    dividerDark: PaletteColor;

    identifiers: {
      order: PaletteColor;
      job: PaletteColor;
      shift: PaletteColor;
      position: PaletteColor;
      applicant: PaletteColor;
      crewboss: PaletteColor;
      break: PaletteColor;
      pending: PaletteColor;
    };
  }

  interface PaletteOptions {
    type?: PaletteType;

    neutral: PaletteColorOptions;

    note: PaletteColorOptions;

    completed: PaletteColorOptions;
    confirmationNeeded: PaletteColorOptions;
    applied: PaletteColorOptions;

    dividerDark: PaletteColorOptions;

    identifiers: {
      order: PaletteColorOptions;
      job: PaletteColorOptions;
      shift: PaletteColorOptions;
      position: PaletteColorOptions;
      applicant: PaletteColorOptions;
      crewboss: PaletteColorOptions;
      break: PaletteColorOptions;
      pending: PaletteColorOptions;
    };
  }
}
