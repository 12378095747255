import { Box, FormControl, FormHelperText } from "@material-ui/core";
import { mdiUpload } from "@mdi/js";
import { DataProvider, FileId, responseHandlerKey, responseHandlerSequence, useData, useRequest } from "@sinch/core";
import {
  BaseInputProps,
  FileInputButton,
  FileInputItems,
  FileInputProvider,
  FileValidatorParams,
  ManagedInputProps,
  useFileInputContext,
} from "@sinch/forms";
import { t } from "@sinch/intl";
import { Consumer, FileHash, Nullable } from "@sinch/types";
import { Card, ConditionalWrapper, GridLayout, Text, useSnackbar } from "@sinch/ui";
import { isEmpty } from "ramda";
import { isNotEmpty, isNotNil } from "ramda-adjunct";
import React, { FC, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { Overwrite } from "utility-types";
import { MAX_UPLOADED_FILES, PositionWorkerFilesTarget, requestFileRemove, requestPositionDetail } from "../api";

interface WorkerFilesProps {
  positionId: number;
}

export const WorkerFiles: FC<WorkerFilesProps> = ({ positionId }): ReactElement | null => {
  const { selectResult } = useData(requestPositionDetail);
  const { canWorkerUploadFiles, workerFiles } = selectResult();

  const [value, setValue] = useState(workerFiles);

  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestFileRemove,
          () => {},
          () => {}
        ),
      ]),
    []
  );

  useEffect(() => {
    setValue(workerFiles);
  }, [workerFiles]);

  return (
    <GridLayout.Item>
      <DataProvider handler={responseHandler}>
        {canWorkerUploadFiles && (
          <WorkerFileInput
            label={t("uploadAttachments")}
            maxFiles={MAX_UPLOADED_FILES}
            multiple
            name="files"
            onChange={setValue as never}
            target={`${PositionWorkerFilesTarget}/${positionId}`}
            value={value.filter(isNotNil) as never}
          />
        )}
        {!canWorkerUploadFiles && isNotEmpty(workerFiles) && (
          <Card>
            <ReadOnlyFiles value={workerFiles} />
          </Card>
        )}
      </DataProvider>
    </GridLayout.Item>
  );
};

export interface FileInputProps extends Omit<ManagedInputProps, "dense" | "placeholder">, FileValidatorParams {
  /**
   * Allow multiple files selected.
   */
  multiple?: boolean;

  /**
   * Backend target key for upload client.
   */
  target: string;
}
type FileInputSingleBaseProps = BaseInputProps<
  Nullable<FileHash>,
  Overwrite<FileInputProps, { multiple?: false }>,
  Consumer<SetStateAction<Nullable<FileHash>>>
>;

type FileInputMultiBaseProps = BaseInputProps<
  FileHash[],
  Overwrite<FileInputProps, { multiple: true }>,
  Consumer<SetStateAction<FileHash[]>>
>;

export function WorkerFileInput({
  accept,
  error: formError,
  extensions,
  maxSize,
  multiple,
  maxFiles,
  name,
  note,
  onChange,
  target,
  value,
  label,
}: FileInputSingleBaseProps | FileInputMultiBaseProps): ReactElement {
  const snackbar = useSnackbar();
  const dispatch = useRequest();

  const handleDeleteFile = (file) => dispatch(requestFileRemove(file));

  const handleAddFile = (v: SetStateAction<Nullable<FileId>>, progress?: string | undefined) => {
    if (progress === "success") {
      snackbar("success", t("fileUploaded"));
    }
    onChange(v, progress);
  };

  return (
    <FileInputProvider
      accept={accept}
      extensions={extensions}
      maxFiles={maxFiles}
      maxSize={maxSize}
      multiple={multiple as never}
      onChange={handleAddFile as never}
      target={target}
      value={value as never}
    >
      <FormControl error={formError} fullWidth margin="none" variant="outlined">
        <ConditionalWrapper
          condition={!isEmpty(value.filter(isNotNil))}
          wrapper={(children) => <Card>{children}</Card>}
        >
          <Box width="inherit">
            {(!value || (multiple && !maxFiles) || (multiple && value.length < maxFiles)) && (
              <Box pb={value && !isEmpty(value) ? 1 : 0}>
                <FileInputButton
                  color="info"
                  error={formError}
                  icon={mdiUpload}
                  label={label}
                  name={name}
                  size="large"
                  variant="contained"
                />
              </Box>
            )}
          </Box>
          <UploadErors />
          {!isEmpty(value) && <Text bold>{t("attachments")}</Text>}
          {note && <FormHelperText>{note}</FormHelperText>}
          <FileInputItems onDeleteFile={handleDeleteFile} />
        </ConditionalWrapper>
      </FormControl>
    </FileInputProvider>
  );
}

function ReadOnlyFiles({ value }): ReactElement {
  return (
    <FileInputProvider multiple value={value as never}>
      <Box pb={1}>
        <Text bold>{t("attachments")}</Text>
      </Box>
      <FileInputItems denyRemove />
    </FileInputProvider>
  );
}

function UploadErors(): ReactElement | null {
  const { errors } = useFileInputContext();

  if (isEmpty(errors)) {
    return null;
  }

  return (
    <FormHelperText error>
      <Text separator={<br />}>{errors}</Text>
    </FormHelperText>
  );
}
