import { Badge as MuiBadge, Box, Divider, makeStyles, MenuItem as MuiMenuItem } from "@material-ui/core";
import MuiMenuList from "@material-ui/core/MenuList";
import { mdiBell, mdiCircleSmall } from "@mdi/js";

/* eslint-disable import/no-extraneous-dependencies -- todo: fix properly (move notifications into worker) */
/* eslint-disable import/no-internal-modules -- todo: fix properly (export relevant ui components) */
import { requestNotificationReadAll, useRequest, useStatus, useStatusUpdate } from "@sinch/core";
import { Notification, NotificationStatus, NotificationType } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";

import {
  Button,
  Center,
  entityTypes,
  HtmlContent,
  Icon,
  InformationDialog,
  Text,
  useDialog,
  useMobileLayout,
} from "@sinch/ui";
import { useExclusiveToggle } from "@sinch/ui/ApplicationLayout/ExclusiveToggleContext";
import { PopperMenu, usePopperMenuContext } from "@sinch/ui/ApplicationLayout/PopperMenu";
import { ToolbarButton } from "@sinch/ui/ApplicationLayout/TitleBar";
import { length, pipe, prop, reverse, sortBy } from "ramda";
import { isString } from "ramda-adjunct";
import React, { ReactElement, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  notification: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    whiteSpace: "normal",
    padding: theme.spacing(0.5, 0),
    paddingLeft: theme.spacing(1),
  },
  notificationHead: {
    display: "flex",
    justifyContent: "space-between",
    "& > :first-child": {
      color: theme.palette.info.main,
    },
  },
  notificationBody: {
    textAlign: "justify",
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
    "& a *": {
      textDecoration: "none",
    },

    "& .subject": {
      display: "block",

      "& .from": {
        color: "#4B8DF8",
        fontWeight: 600,
      },

      "& .time": {
        fontWeight: 600,
        float: "right",
      },
    },
  },
}));

interface NotificationItemProps extends Notification {
  onClick: Callback;
}

function NotificationItem({ link, onClick, status, text, type, url }: NotificationItemProps): ReactElement {
  const navigate = useNavigate();
  const popperMenuContext = usePopperMenuContext();

  const handleClick = useCallback(() => {
    if (popperMenuContext) popperMenuContext.close();

    if (status !== NotificationStatus.Read) onClick();
  }, [link, navigate, onClick, popperMenuContext, status, type, url]);

  return <NotificationMenuItem link={link} onClick={handleClick} status={status} text={text} type={type} url={url} />;
}

function NotificationMenuItem({
  link,
  onClick,
  status,
  text,
  type,
  url,
}: Omit<NotificationItemProps, "id">): ReactElement {
  const styles = useStyles();

  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (type === NotificationType.General) {
      if (isString(url)) window.location.assign(url);
      return;
    }

    if (link) {
      navigate(`/${entityTypes[type]}/${link}`);
    }
    onClick();
  }, [link, navigate, onClick, status, type, url]);

  return (
    <MuiMenuItem divider onClick={handleClick} selected={status !== NotificationStatus.Read}>
      {status !== NotificationStatus.Read && (
        <Box left={0} position="absolute">
          <Icon color="secondary" icon={mdiCircleSmall} />
        </Box>
      )}
      <div className={styles.notification}>
        <div className={styles.notificationBody}>
          <HtmlContent html={text} />
          {url && <Text color="secondary">{url}</Text>}
        </div>
      </div>
    </MuiMenuItem>
  );
}

function ShowAllButton(): ReactElement {
  const navigate = useNavigate();
  const { close } = usePopperMenuContext();

  const handleClick = useCallback(() => {
    navigate("/communication/notifications");
    close();
  }, [close, navigate]);

  /**
   * todo: apply button typography via styles instead of rendering caps
   */
  return (
    <MuiMenuItem onClick={handleClick}>
      <Center>
        <Text>{t("Notification.action.showAll")}</Text>
      </Center>
    </MuiMenuItem>
  );
}

export function NotificationIndicator(): ReactElement {
  const { notificationIds, unreadNotifications } = useStatus();
  const { readNotification, refresh } = useStatusUpdate();
  const dispatch = useRequest();
  const navigate = useNavigate();
  const mobile = useMobileLayout();
  const modal = useDialog();
  const toggleControl = useExclusiveToggle("notificationPopper");
  const [popperOpened] = toggleControl;
  const { state } = modal;

  const sortedItems = pipe(sortBy(prop("createdDate")), reverse)(notificationIds);

  const readAllCallback = async () => {
    await dispatch(requestNotificationReadAll());
  };

  useEffect(() => {
    if (popperOpened && unreadNotifications > 0) {
      readAllCallback();
    } else if (!popperOpened && unreadNotifications > 0) {
      refresh();
    }
  }, [popperOpened]);

  useEffect(() => {
    if (state && unreadNotifications > 0) {
      readAllCallback();
    } else if (!state && unreadNotifications > 0) {
      refresh();
    }
  }, [state]);

  return (
    <>
      {!mobile && (
        <PopperMenu maxWidth="xs" toggleControl={toggleControl}>
          <ToolbarButton>
            <MuiBadge
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              badgeContent={unreadNotifications}
              color="error"
            >
              <Icon icon={mdiBell} />
            </MuiBadge>
          </ToolbarButton>
          {length(notificationIds) > 0 && (
            <>
              <Box maxHeight="50vh" overflow="auto">
                <MuiMenuList variant="menu">
                  {sortedItems.map((ntf) => (
                    <NotificationItem
                      key={ntf.id}
                      onClick={() => readNotification(ntf.id)}
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...ntf}
                    />
                  ))}
                </MuiMenuList>
              </Box>
              <Divider variant="fullWidth" />
            </>
          )}
          <ShowAllButton />
        </PopperMenu>
      )}
      {mobile && (
        <>
          <ToolbarButton onClick={modal.open}>
            <MuiBadge
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              badgeContent={unreadNotifications}
              color="error"
            >
              <Icon icon={mdiBell} />
            </MuiBadge>
          </ToolbarButton>
          <InformationDialog control={modal} hashKey="notifications" title={t("Notification.title")} width="xs">
            <Box display="flex" flexDirection="column" maxHeight="100%">
              {length(notificationIds) > 0 && (
                <>
                  <Box maxHeight="100%" overflow="auto">
                    <MuiMenuList variant="menu">
                      {sortedItems.map((ntf) => (
                        <NotificationMenuItem
                          key={ntf.id}
                          onClick={() => {
                            readNotification(ntf.id);
                            modal.close();
                          }}
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...ntf}
                        />
                      ))}
                      <MuiMenuItem
                        onClick={() => {
                          navigate("/communication/notifications");
                          modal.close();
                        }}
                      />
                    </MuiMenuList>
                  </Box>
                  <Divider variant="fullWidth" />
                </>
              )}
              <Box>
                <Button
                  action={() => {
                    navigate("/communication/notifications");
                    modal.close();
                  }}
                  variant="text"
                >
                  <Text>{t("Notification.action.showAll")}</Text>
                </Button>
              </Box>
            </Box>
          </InformationDialog>
        </>
      )}
    </>
  );
}
