import { ButtonProps } from "@material-ui/core/Button";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Callback, Color, IconId } from "@sinch/types";
import { isNil } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { isElement } from "react-dom/test-utils";
import { ActionContainer } from "../actions";
import { Button } from "../Button";
import { Grid } from "../Grid";
import { Icon } from "../Icon";
import { IdentifierColorKey } from "../paletteStyles";
import { StripBase } from "../StripBase";
import { Text } from "../Text";

const useStyles = makeStyles((theme) => ({
  primaryAction: {
    color: theme.palette.primary.main,
    transform: "translate(-6px, 6px)",
  },
  secondaryAction: {
    color: theme.palette.secondary.main,
    transform: "translate(6px, 6px)",
  },
  actionColor: {
    color: ({ color }: { color?: string }): string =>
      // @ts-ignore
      theme.palette[color]?.main || "inherit",
  },
}));

interface ActionButtonProps {
  action: ActionContainer & {
    btnProps?: ButtonProps & { loading: boolean };
  };

  primary?: boolean;
}

function ActionButton({ primary = false, action }: ActionButtonProps): ReactElement {
  const { color, action: btnAction, btnProps, label } = action;
  const classes = useStyles({ color });

  /* eslint-disable react/jsx-props-no-spreading */
  const actionClass = primary ? classes.primaryAction : classes.secondaryAction;

  return (
    <Button
      action={btnAction}
      className={!btnProps ? `${classes.actionColor} ${actionClass}` : ""}
      disabled={btnProps?.disabled || btnProps?.loading}
      variant="text"
      {...btnProps}
    >
      {label}
    </Button>
  );
}

interface StripActionsProps {
  primaryAction?: ActionContainer;

  secondaryAction?: ActionContainer;
}

function StripActions({ primaryAction, secondaryAction }: StripActionsProps): ReactElement {
  return (
    <MuiGrid container justify="space-between">
      {primaryAction && (
        <MuiGrid item xs="auto">
          <ActionButton action={primaryAction} primary />
        </MuiGrid>
      )}
      {secondaryAction && (
        <MuiGrid item xs="auto">
          <ActionButton action={secondaryAction} />
        </MuiGrid>
      )}
    </MuiGrid>
  );
}

export interface StripProps {
  children: ReactNode;

  color: Color | IdentifierColorKey | null;

  icon: IconId | ReactElement;

  primaryAction?: ActionContainer;

  secondaryAction?: ActionContainer;

  title?: string;

  /**
   * @deprecated todo: replace with Action API
   */
  onClick?: Callback;
  additionalAttributes?: Record<string, string>;
}

/* eslint-enable react/jsx-props-no-spreading */

/**
 * todo: review and improve logic for primary/secondary actions handling
 *
 * todo: get color for secondary button from theme
 *
 * todo: unify button with card and dialog
 *  consider extracting StripActions to common component?
 *  use Button or create DialogButton? we need Button without fullWidth..
 *
 * todo: get content grid spacing from theme/context
 */
export function Strip({
  children,
  color,
  icon,
  primaryAction,
  secondaryAction,
  title,
  onClick,
  additionalAttributes,
}: StripProps): ReactElement {
  const hasActions = !isNil(primaryAction) || !isNil(secondaryAction);

  return (
    <StripBase
      action={onClick}
      color={color}
      label={isElement(icon) ? icon : <Icon icon={icon as IconId} />}
      {...(additionalAttributes ?? {})}
    >
      <Grid spacing={1} vertical>
        {title && <Text bold>{title}</Text>}
        <Text>{children}</Text>
        {hasActions && <StripActions primaryAction={primaryAction} secondaryAction={secondaryAction} />}
      </Grid>
    </StripBase>
  );
}
