import { ListItem as MuiListItem, makeStyles } from "@material-ui/core";
import MuiDrawer from "@material-ui/core/Drawer";
import MuiGrid from "@material-ui/core/Grid";
import MuiList from "@material-ui/core/List";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { mdiBugOutline, mdiCog, mdiLogout, mdiLogoutVariant, mdiSend } from "@mdi/js";

import { requestLogOut, Suggestions, useBusinessRules, useCurrentUser, useHashNavigate, useRequest } from "@sinch/core";
import { t } from "@sinch/intl";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router";
import { externalLink } from "../../actions";
import { Avatar } from "../../Avatar";
import { Button } from "../../Button";
import { Divider } from "../../Divider";
import { Grid } from "../../Grid";
import { Icon } from "../../Icon";
import { Box } from "../../Layout";
import { Header } from "../../Text";
import { darkTheme } from "../../theme";
import { ThemeSwitcher } from "../components";
import { useDialog } from "../Dialog";
import { useTitleBar } from "../TitleBar";
import { useMenuContext } from "./context";
import { Title } from "./Title";

const useStyles = makeStyles(({ mixins: { component, onLarge, ...mixins }, zIndex, ...rest }) => ({
  mobilePaper: {
    border: "none",
    paddingTop: component.titleBar.small.height,
    ...onLarge({
      paddingTop: component.titleBar.large.height,
    }),
  },
}));

function TopMenuDrawerContent({ children }: ChildrenProps): ReactElement {
  const dispatch = useRequest();
  const hashNav = useHashNavigate();
  const navigate = useNavigate();
  const { avatar, name, possessed } = useCurrentUser();
  const { onClose, opened } = useMenuContext();
  const { referralProgram } = useBusinessRules();

  useTitleBar({
    container: "mainMenuMobile",
    locationTitle: opened ? t("menu") : undefined,
  });

  return (
    <MuiGrid container direction="column" wrap="nowrap">
      <MuiGrid item>{children}</MuiGrid>
      <MuiGrid item>
        <Divider />
      </MuiGrid>
      <MuiGrid item>
        <Button
          action={() => {
            navigate("/profile");
            onClose();
          }}
          variant="text"
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            paddingLeft={2}
            paddingRight={2}
            width="100%"
          >
            <Box alignItems="center" display="flex">
              <Avatar src={avatar?.url} />
              <Box width={1} />
              <Header level={2}>{name}</Header>
            </Box>
            <Icon icon={mdiCog} size="small" />
          </Box>
        </Button>
      </MuiGrid>
      <MuiGrid item>
        <Divider />
      </MuiGrid>
      <MuiGrid item>
        <MuiList>
          <ReportIssueMenuItem />
        </MuiList>
      </MuiGrid>
      <MuiGrid item>
        <Box paddingLeft={2} paddingRight={2}>
          <ThemeSwitcher />
        </Box>
      </MuiGrid>
      <MuiGrid item>
        <Divider />
      </MuiGrid>
      <MuiGrid item>
        <Box paddingLeft={2} paddingRight={2}>
          <Grid spacing="inner" vertical>
            {possessed && (
              <Button
                action={externalLink(`${window.location.origin}/users/logBack`, { target: "_self" })}
                color="warning"
                icon={mdiLogoutVariant}
                large
                variant="contained"
              >
                {t("User.backToOriginalUser")}
              </Button>
            )}
            {referralProgram && (
              <Button
                action={() => {
                  hashNav("/referral");
                  onClose();
                }}
                color="info"
                icon={mdiSend}
                large
                variant="contained"
              >
                {t("Referral.action.invite")}
              </Button>
            )}
            {/* <EvaluationButton /> */}
          </Grid>
        </Box>
      </MuiGrid>
      <MuiGrid item>
        <Divider />
      </MuiGrid>
      <MuiGrid item>
        <Box paddingLeft={2} paddingRight={2}>
          <Button
            action={() => {
              dispatch(requestLogOut());
            }}
            icon={mdiLogout}
            large
            variant="outlined"
          >
            {t("logout")}
          </Button>
        </Box>
      </MuiGrid>
      <MuiGrid item>
        <Box mb={3} />
      </MuiGrid>
    </MuiGrid>
  );
}

function ReportIssueMenuItem() {
  const dialog = useDialog();

  return (
    <>
      <MuiListItem button onClick={() => dialog.open()}>
        <MuiListItemIcon>
          <Icon icon={mdiBugOutline} />
        </MuiListItemIcon>
        <Title title={t("Suggestion.suggestionTitle")} />
      </MuiListItem>
      {dialog.state && <Suggestions dialog={dialog} />}
    </>
  );
}

export function TopMenuDrawer({ children }: ChildrenProps): ReactElement {
  const styles = useStyles();
  const { opened } = useMenuContext();

  return (
    <MuiThemeProvider theme={darkTheme}>
      <MuiDrawer
        anchor="top"
        open={opened}
        PaperProps={{ className: styles.mobilePaper, component: "nav" }}
        variant="persistent"
      >
        {opened && <TopMenuDrawerContent>{children}</TopMenuDrawerContent>}
      </MuiDrawer>
    </MuiThemeProvider>
  );
}
