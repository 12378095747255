import { makeStyles } from "@material-ui/core/styles";
import { Theme as DefaultTheme } from "@material-ui/core/styles/createMuiTheme";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Styles } from "@material-ui/styles/withStyles";
import { isString } from "@sinch/utils";
import clsx from "clsx";
import { assocPath, filter, map } from "ramda";
import React, { ComponentProps, ReactElement } from "react";
import {
  DataTableDisplay,
  DataTableProps,
  DataTableRowLogic,
  DataTableRowProps,
} from "../types";

// todo: fix extraneous dependencies warning

/* eslint-disable react/jsx-props-no-spreading */

/**
 * At the moment supporting only `makeStyles` where the passed `styles`
 * do not depend on props (see {@link makeStyles} docs for implementation).
 *
 * todo: consider if classKeySelector should get only current element as params?
 *  -> maybe pass (row, data) for more flexibility?
 */
export function createMuiStyleRow<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>,
  Theme = DefaultTheme,
  ClassKey extends string = string
>(
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  stylesheetFactory: Styles<Theme, {}, ClassKey>,
  classKeySelector: (el: TData) => (ClassKey | false | null | undefined)[]
): DataTableRowLogic<TData, TColumn, TDisplay> {
  const useStyles = makeStyles(stylesheetFactory);

  return function MuiStyleRow({
    table,
    row,
  }: DataTableRowProps<TData, TColumn, TDisplay>): ReactElement {
    const styles = useStyles();
    const toClassName = (key: ClassKey) => styles[key];

    const { data, display, logic } = table;
    const { Row } = display;
    const RowLogic = logic;

    const current = data[row];

    const classNames = map(
      toClassName,
      filter(isString, classKeySelector(current) as ClassKey[])
    );

    /*
     * todo: can we support generics to type display component props?
     *
     * todo: is there any pattern to simplify defining props in this way?
     */
    const DisplayRow = (props: ComponentProps<TDisplay["Row"]>) => (
      /* eslint-disable-next-line react/destructuring-assignment */
      <Row {...props} className={clsx(props.className, classNames)} />
    );
    const updateDisplay: (
      props: DataTableProps<TData, TColumn, TDisplay>
    ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(
      ["display", "Row"],
      DisplayRow
    );

    return <RowLogic row={row} table={updateDisplay(table)} />;
  };
}
