import { t } from "@sinch/intl";
import { Grid, TwoColumnLayout, useTitleBar } from "@sinch/ui";
import React, { ReactElement } from "react";
import { SnippetList } from "../SnippetList";
import { AttendanceTabs } from "./AttendanceTabs";
import { SearchTabs } from "./SearchTabs";
import { Shortcuts } from "./Shortcuts";

export function DesktopDashboard(): ReactElement {
  useTitleBar({
    container: "core:dashboard",
    locationTitle: t("Dashboard.title"),
  });

  return (
    <>
      <SnippetList />
      <TwoColumnLayout>
        <Grid spacing="outer" vertical>
          <SearchTabs />
          <Shortcuts />
        </Grid>
        <AttendanceTabs />
      </TwoColumnLayout>
    </>
  );
}
