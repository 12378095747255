import { defineRequest } from "@sinch/core";
import { Fk, ProfileAttribute } from "@sinch/entity";
import { format } from "date-fns";
import { map } from "ramda";
import { ProfileAttributeValue } from "./ProfileAttributeList";

export const requestProfileAttributeUpdate = defineRequest("Profiles/ProfileAttributeUpdate")
  .withParams<{ attributes: ProfileAttributeValue[] }>()
  .withEmptyResponse()
  .withCreator((attributes: ProfileAttributeValue) => {
    let modified = {};

    if (attributes.dynamicAttributes) {
      modified = {
        ...modified,
        dynamicAttributes: map(({ expiration, ...attr }) => {
          if (expiration) {
            return { ...attr, expiration: format(expiration, "yyyy-MM-dd") };
          }
          return { ...attr };
        }, attributes.dynamicAttributes),
      };
    }

    return { params: { attributes: { ...attributes, ...modified } } };
  });

export const profileProfileUpdateFilesTarget = (
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  id: Fk<ProfileAttribute>
): string => `worker/Profiles/ProfileUpdateFile`;

export const profileProfileUpdateFilesTargetImage = (
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  id: Fk<ProfileAttribute>
): string => `worker/Profiles/ProfileUpdateFile/1`;
