import { createEntitySelector, EntityContainer, EntitySelector } from "../entity";
import { PersonalAttribute } from "./PersonalAttribute";
import { ProfileAttribute } from "./ProfileAttribute";

export type ProfileAttributeContainer = EntityContainer<ProfileAttribute, "ProfileAttribute">;
export type PersonalAttributeContainer = EntityContainer<PersonalAttribute, "PersonalAttribute">;

export const selectProfileAttribute: EntitySelector<ProfileAttribute> = createEntitySelector<
  ProfileAttribute,
  ProfileAttributeContainer
>("ProfileAttribute");

export const selectPersonalAttribute: EntitySelector<PersonalAttribute> = createEntitySelector<
  PersonalAttribute,
  PersonalAttributeContainer
>("PersonalAttribute");
