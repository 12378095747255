import MuiGrid from "@material-ui/core/Grid";
import { ParamsOf, useCurrentUser, useData } from "@sinch/core";
import {
  FeedbackType,
  Fk,
  selectFeedback,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  Shift,
} from "@sinch/entity";
import { Form, SelectInput, TextInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Button } from "@sinch/ui";
import { flatten, isEmpty, map, uniq } from "ramda";
import React, { ReactElement } from "react";
import { requestShiftFeedbackList, requestShiftFeedbackSubmit } from "./api";
import { getFeedbackSelectOptions } from "./displayProps";
import { SelectedFeedbackState, SelectFeedbackFn } from "./types";

interface FeedbackFormState extends Pick<ParamsOf<typeof requestShiftFeedbackSubmit>, "text" | "type"> {
  to: NonNullable<SelectedFeedbackState>;
}

interface FeedbackFormProps {
  onSelect: SelectFeedbackFn;

  selected: NonNullable<SelectedFeedbackState>;

  shift: Fk<Shift>;
}

export function FeedbackForm({ onSelect, selected, shift }: FeedbackFormProps): ReactElement {
  const currentUser = useCurrentUser();
  const { selectEntity } = useData(requestShiftFeedbackList);

  const positions = selectEntity(selectPosition({ shift }, "id"));

  const workers = uniq(
    flatten(map((position) => selectEntity(selectPositionAttendance({ position }, "worker")), positions))
  );

  const shiftOption = isEmpty(selectEntity(selectFeedback({ from: currentUser.id, shift, to: null })))
    ? {
        label: t("Feedback.entireShift"),
        value: "S",
      }
    : [];

  const workerOptions = map((worker: number) => {
    if (currentUser.id === worker) return [];
    const { id, name } = selectEntity(selectWorker(worker));
    const feedback = selectEntity(selectFeedback({ from: currentUser.id, shift, to: worker }));
    // todo: use disabled instead of hiding option?
    return isEmpty(feedback) ? { label: name, value: id } : [];
  }, workers);

  const options = flatten([shiftOption, workerOptions]);

  return (
    <Form<Omit<FeedbackFormState, "type"> & { type: FeedbackType | "" }>
      initialValues={{
        text: "",
        to: selected,
        type: "",
      }}
      submitRequest={({ text, to: formTo, type }) =>
        // @ts-ignore
        requestShiftFeedbackSubmit(shift, type, text, formTo === "S" ? null : formTo)
      }
      validateOnBlur
      validateOnChange={false}
    >
      <SelectInput
        label={t("Feedback.type")}
        name="type"
        options={getFeedbackSelectOptions()}
        placeholder={t("select")}
        required
      />
      <SelectInput disabled label={t("worker", { count: 1 })} name="to" options={options} />
      <TextInput label={t("message")} multiline name="text" required />
      <MuiGrid container justify="flex-end" spacing={1}>
        <MuiGrid item>
          <Button action={() => onSelect(null)} variant="outlined">
            {t("action.back")}
          </Button>
        </MuiGrid>
        <MuiGrid item>
          <Button color="secondary" type="submit">
            {t("Feedback.action.submit")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Form>
  );
}
