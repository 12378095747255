import { makeStyles } from "@material-ui/core/styles";
import { Icon, Text } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";
import { useFormContext } from "./FormContext";
import { formStatusDisplayProps } from "./FormStatus";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {},
  content: {
    marginLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
}));

export function FormMessages(): ReactElement {
  const styles = useStyles();

  const { messages, status } = useFormContext();
  const { color, icon, label } = formStatusDisplayProps(status);

  return (
    <div className={styles.item}>
      <div className={styles.icon}>
        <Icon color={color} icon={icon} size="small" />
      </div>
      <div className={styles.content}>
        {isEmpty(messages) ? (
          <Text color={color}>{label}</Text>
        ) : (
          messages.map((message) => (
            <Text key={message} color={color}>
              {message}
            </Text>
          ))
        )}
      </div>
    </div>
  );
}
