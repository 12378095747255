import { InputLabel } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useData, useRequest } from "@sinch/core";
import { selectSnippet } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Box, Button, Checkbox, Divider, Grid, useMobileLayout, useSpacing } from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Notice } from "../components";
import { requestPenaltyAccept, requestSuspendedPenaltyList } from "./api";
import { PenaltyCardList } from "./PenaltyCardList";
import { PenaltyTable } from "./PenaltyTable";

export function LiablePenaltyList(): ReactElement {
  const mobile = useMobileLayout();
  const [, inner] = useSpacing();
  const [confirm, toggleConfirm] = useToggleState();
  const dispatch = useRequest();
  const { selectEntity } = useData(requestSuspendedPenaltyList);
  const notice = selectEntity(selectSnippet({ name: "penalty_agreement_text" }));

  return (
    <Grid spacing="inner" vertical>
      {mobile ? <PenaltyCardList /> : <PenaltyTable columns={["identifier", "startTime", "type", "penalty"]} />}
      {isNotNilOrEmpty(notice) && (
        <Notice body={notice[0].body} color={notice[0].color} id={notice[0].id} style={notice[0].style} />
      )}
      <Divider />
      <MuiGrid alignItems="center" container justify="space-between">
        <MuiGrid item md="auto" xs="auto">
          <Checkbox checked={confirm} id="penaltyConfirm" onChange={toggleConfirm} />
        </MuiGrid>
        <MuiGrid item md xs>
          <InputLabel htmlFor="penaltyConfirm">{t("Penalty.liableAgree")}</InputLabel>
        </MuiGrid>
        <MuiGrid item md="auto" xs={12}>
          <Box pt={mobile ? inner : 0}>
            <Button
              action={() => dispatch(requestPenaltyAccept())}
              color="primary"
              disabled={!confirm}
              large
              stretch={!mobile}
            >
              {t("action.confirm")}
            </Button>
          </Box>
        </MuiGrid>
      </MuiGrid>
    </Grid>
  );
}
