import { createMuiTheme } from "@material-ui/core/styles";
import { breakpoints } from "./breakpoints";
import { createMixins } from "./mixins";
import { createOverrides } from "./overrides";
import { darkPalette, palette } from "./palette";
import { props } from "./props";
import { typography } from "./typography";

const base = createMuiTheme({
  breakpoints,
  palette,
  props,
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  typography,
  zIndex: {
    snackbar: 1800,
  },
});

export const theme = createMuiTheme({
  ...base,
  mixins: createMixins(base),
  overrides: createOverrides(base),
  /*
   * disable transitions
   *
   * todo: make configurable (user preferences)
  transitions: {
    create: () => "none",
  },
  */
});

export const darkTheme = createMuiTheme({
  ...theme,
  palette: darkPalette,
  overrides: createOverrides({ ...theme, palette: { ...theme.palette, ...darkPalette } }, true),
  mixins: createMixins(base),
});

export const noLayoutTheme = createMuiTheme({
  ...theme,
  mixins: {
    ...createMixins(base),
    component: {
      titleBar: {
        large: {
          height: 0,
        },
        small: {
          height: 0,
        },
      },
      toolBar: {
        height: 0,
      },
    },
  },
});
