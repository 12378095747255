// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { useCurrentUser, useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectPositionAttendance } from "@sinch/entity";
import {
  ChipedList,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedHeader,
  CondensedStatus,
  CondensedTitle,
  ListItemBorder,
  ListItemContent,
  SimpleListItem,
  SpacingBox,
  Text,
} from "@sinch/ui";
import { includes, pipe, prop, reverse, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router";
import {
  PositionAttendanceStatusIcons,
  PositionAttendanceWageRating,
  PositionPlace,
  PositionRole,
  SearchParamsPagination,
} from "../../components";
import { PositionAttendanceTime } from "../../components/Position/AttendanceTime";
import { PositionReference } from "../Detail/PositionReference";
import { requestPositionHistory } from "./api";

export interface PositionTableProps<TField extends string = string> {
  fields: TField[];
}

export function PositionHistoryCondensed<TField extends string>({ fields }: PositionTableProps<TField>): ReactElement {
  const { selectEntity, selectResult } = useData(requestPositionHistory);
  const { positionIds } = selectResult();

  const positions = pipe<Position[], Position[], Position[]>(
    sortBy(prop("startTime")),
    reverse
  )(selectEntity(selectPosition(positionIds)));

  return (
    <SpacingBox mx={-1}>
      <ChipedList>
        {positions.map(({ id }: Position) => (
          <PositionListRow key={id} fields={fields} id={id} />
        ))}
      </ChipedList>
      <SearchParamsPagination />
    </SpacingBox>
  );
}

function PositionListRow({ id, fields }: { id: Fk<Position> } & PositionTableProps): ReactElement {
  const navigate = useNavigate();

  return (
    <SimpleListItem onClick={() => navigate(`/position/${id}`)}>
      <ListItemBorder>
        <PositionRole id={id} />
      </ListItemBorder>
      <ListItemContent>
        <PositionListItemContent fields={fields} id={id} />
      </ListItemContent>
    </SimpleListItem>
  );
}

function PositionListItemContent({ id, fields }: { id: Fk<Position> } & PositionTableProps): ReactElement {
  const { selectEntity, selectResult } = useData(requestPositionHistory);
  const { id: userId } = useCurrentUser();
  const { id: attendanceId } = selectEntity(selectPositionAttendance({ position: id, worker: userId }))[0];
  return (
    <CondensedCard>
      <CondensedHeader>
        <CondensedTitle>
          <Text bold>
            <PositionReference id={id} name noId title />
          </Text>
        </CondensedTitle>
        <CondensedStatus>
          <PositionAttendanceStatusIcons id={id} />
        </CondensedStatus>
      </CondensedHeader>
      <CondensedBody>
        <CondensedContent>
          <PositionAttendanceTime id={attendanceId} />
          {includes("place", fields) && <PositionPlace id={id} />}
          {includes("wageRating", fields) && <PositionAttendanceWageRating id={id} />}
        </CondensedContent>
      </CondensedBody>
    </CondensedCard>
  );
}
