import MuiGrid from "@material-ui/core/Grid";
import {
  mdiAccount,
  mdiCalendarBlank,
  mdiCancel,
  mdiCheck,
  mdiCheckboxMultipleMarkedOutline,
  mdiClockOutline,
  mdiHumanGreetingVariant,
  mdiLink,
  mdiMapMarker,
  mdiShuffleVariant,
} from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import { PositionQ, selectLocation, selectPosition, selectPositionAttendance, selectProfession } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Box,
  Button,
  ConditionalWrapper,
  DataGrid,
  DisableStyle,
  Divider,
  ExpandableArea,
  GridLayout,
  Header,
  Icon,
  IconText,
  ListBase,
  Paper,
  ScrollAnchor,
  StatusIndicator,
  Text,
  TimeRange,
  useMobileLayout,
  useScroll,
} from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import { flatten, head, includes, isEmpty, length, pipe, pluck, prop, sortBy, T, values } from "ramda";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Section } from "../../components";
import { WorkerRoleOptions } from "../../Shift";
import { requestPositionDetail } from "./api";
import { PositionReference } from "./PositionReference";

function getStatus({ isAttending, requirementsFailed, inConflict, isApplicant, isApplicantRequiredConfirmation }) {
  if (isAttending)
    return {
      color: "success",
      content: t("Position.youAreSignUp"),
      icon: mdiCheck,
    };

  if (requirementsFailed)
    return {
      color: "error",
      content: t("Requirement.notMet"),
      icon: mdiCancel,
    };

  if (inConflict)
    return {
      color: "error",
      content: t("Position.positionConflict"),
      icon: mdiShuffleVariant,
    };

  if (!isApplicantRequiredConfirmation && isApplicant)
    return {
      color: "colorApplicant",
      content: t("Position.signedAsApplicantNoticeTitle"),
      icon: mdiHumanGreetingVariant,
    };

  if (isApplicantRequiredConfirmation)
    return {
      color: "colorApplicant",
      content: t("Position.youHaveBeenSelectedAsApplicant"),
      icon: mdiHumanGreetingVariant,
    };

  return {};
}

function ConnectedPositionItem({ position }) {
  const mobile = useMobileLayout();
  const { id: workerId } = useCurrentUser();
  const { dt } = useFormat();
  const { selectEntity, queryEntity, selectResult } = useData(requestPositionDetail);

  const { role, profession, title, startTime, endTime, location, requirementsFailed, inConflict } = selectEntity(
    selectPosition(position)
  );

  const professionName = selectEntity(selectProfession(profession, "name"));
  const locationName = selectEntity(selectLocation(location, "name"));

  const isAttending = queryEntity(PositionQ.User.isAssigned(position));

  const {
    appliedPositions,
    availableInGroup,
    workerSignIn,
    applicantSignIn,
    groupedApplicantConfirmations,
  } = selectResult();

  const groupedPositionIds =
    head(
      selectEntity(selectPositionAttendance({ position: position, worker: workerId }, "groupedPositionIds")) ?? []
    ) ?? [];

  const isDisabled = !(
    includes(position, availableInGroup) ||
    includes(position, head(values(groupedApplicantConfirmations ?? {})) ?? []) ||
    includes(position, groupedPositionIds)
  );

  const isApplicant = includes(position, appliedPositions);

  const { color, content, icon } = getStatus({
    isAttending,
    requirementsFailed,
    inConflict,
    isApplicant,
    isApplicantRequiredConfirmation: includes(position, head(values(groupedApplicantConfirmations)) ?? []),
  });

  const contentData = [
    {
      icon: mdiCalendarBlank,
      content: dt(startTime),
    },
    {
      icon: mdiMapMarker,
      content: locationName,
    },
    {},
    {
      icon: mdiClockOutline,
      content: <TimeRange endTime={endTime} showLength startTime={startTime} />,
    },
    {
      icon: mdiAccount,
      content: (
        <Text separator=" - ">
          {professionName}
          {WorkerRoleOptions()[role].label}
          {title}
        </Text>
      ),
    },
  ];

  return (
    <ConditionalWrapper condition={isDisabled} wrapper={(children) => <DisableStyle>{children}</DisableStyle>}>
      <Box alignItems="stretch" display="flex">
        <Box marginY={-1}>
          <StatusIndicator color={color} />
        </Box>
        <Box paddingX={2}>
          <Section title={<PositionReference id={position} name noId title />}>
            {includes(position, availableInGroup) && includes(position, workerSignIn) && (
              <Box marginBottom={-1}>
                <IconText color="info" icon={mdiCheckboxMultipleMarkedOutline}>
                  {t("Position.signingAsWorker")}
                </IconText>
              </Box>
            )}
            {includes(position, availableInGroup) && includes(position, applicantSignIn) && (
              <Box marginBottom={-1}>
                <IconText color="colorApplicant" icon={/* ICON */ mdiHumanGreetingVariant}>
                  {t("Position.signingAsApplicant")}
                </IconText>
              </Box>
            )}
            {!isEmpty(content) && (
              <IconText color={color} icon={icon}>
                {content}
              </IconText>
            )}
            <DataGrid data={flatten(contentData)} small={mobile} />
          </Section>
        </Box>
      </Box>
    </ConditionalWrapper>
  );
}

export function PositionDetailConnectedPositionsList({ positionId }) {
  const { id: workerId } = useCurrentUser();
  const { selectEntity, queryEntity, selectResult } = useData(requestPositionDetail);
  const { availableInGroup, groupedApplicantConfirmations } = selectResult();
  const scrollTo = useScroll();
  const navigate = useNavigate();

  const [value, toggleValue, setValue] = useToggleState(true);
  const [showFull, toggleShowFull] = useToggleState(false);

  if (!queryEntity(PositionQ.hasConnected(positionId))) return null;

  const { connected } = selectEntity(selectPosition(positionId));

  const groupedPositionIds =
    head(
      selectEntity(selectPositionAttendance({ position: positionId, worker: workerId }, "groupedPositionIds")) ?? []
    ) ?? [];

  const unavailablePositions = connected.filter(
    (position) =>
      !includes(position, availableInGroup) &&
      !includes(position, head(values(groupedApplicantConfirmations ?? {})) ?? []) &&
      !includes(position, groupedPositionIds)
  );
  const filteredConnected = connected.filter(
    (position) =>
      showFull ||
      includes(position, availableInGroup) ||
      includes(position, head(values(groupedApplicantConfirmations ?? {})) ?? []) ||
      includes(position, groupedPositionIds)
  );

  const sortedPositions = pipe(
    sortBy(prop("startTime")),
    sortBy(({ id }) => !includes(id, availableInGroup)),
    pluck("id")
  )(selectEntity(selectPosition(filteredConnected)));

  return (
    <GridLayout.Item>
      <ScrollAnchor id="connected" onScrollTo={() => setValue(true)} />
      <Paper>
        <ExpandableArea
          onClick={toggleValue}
          open={value}
          title={
            <MuiGrid alignItems="center" container>
              <MuiGrid item>
                <Box marginRight={2}>
                  <Icon icon={/* ICON */ mdiLink} size="small" />
                </Box>
              </MuiGrid>
              <MuiGrid item>
                <Header level={3}>
                  {`+${t("Position.connectedPositions", {
                    count: length(connected),
                  })}`}
                </Header>
              </MuiGrid>
            </MuiGrid>
          }
        >
          <Divider spacing={0} />
          <ListBase
            actionSelector={(id) => () => {
              navigate(`/position/${id}`, { replace: true });
              scrollTo("top");
            }}
            contentSelector={(id) => <ConnectedPositionItem key={id} position={id} />}
            data={sortedPositions}
            disablePadding
            dividerSelector={T}
          />
          {length(unavailablePositions) > 0 && (
            <>
              <Divider spacing={0} />
              <Box pl={2} py={1}>
                <Button
                  action={(e) => {
                    toggleShowFull();
                    e.stopPropagation();
                  }}
                  disableUppercase
                  stretch
                  variant="text"
                >
                  {showFull ? t("Position.hidePositionsWithFullCapacity") : t("Position.showPositionsWithFullCapacity")}{" "}
                  ({length(unavailablePositions)})
                </Button>
              </Box>
            </>
          )}
        </ExpandableArea>
      </Paper>
    </GridLayout.Item>
  );
}
