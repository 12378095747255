export enum UserPreferencesKeys {
  uiTourNewCompactView = "UI_tourNewCompactView",
  uiColorTheme = "UI_colorTheme",
  positionListQuickFilter = "Filter_positionListQuickFilter",
}

export enum ColorTheme {
  Light = "light",
  Dark = "dark",
}
