/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { Box, FormControlLabel } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { SubmitButton } from "@sinch/forms";
import { useFormContext } from "@sinch/forms/Form/FormContext";
import { t } from "@sinch/intl";
import { Card, Checkbox, Divider, LoadingOverlay, Text, useMobileLayout, useSnackbar } from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import React, { ReactElement } from "react";

export function SubmitCard(): ReactElement {
  const mobile = useMobileLayout();
  const snackbar = useSnackbar();
  const [confirmed, toggleConfirmed, setConfirmed] = useToggleState(false);
  const { status } = useFormContext();

  return (
    <Card>
      {status.submitting && <LoadingOverlay />}
      <MuiGrid container direction={mobile ? "column" : "row"} spacing={1}>
        <MuiGrid item xs>
          <Text small>{t("Shift.closing.aboutClose")}</Text>
        </MuiGrid>
        <MuiGrid item xs>
          {mobile && <Divider />}
          <Box alignItems="center">
            <FormControlLabel
              control={<Checkbox checked={confirmed} onChange={toggleConfirmed} />}
              label={t("Shift.closing.readyClose")}
            />
          </Box>
          <Divider />
          <SubmitButton
            disabled={!confirmed}
            ignoreInvalidState
            label={t("Shift.action.closeShift")}
            onClick={() => setConfirmed(false)}
            onInvalid={() => snackbar("error", t("Shift.closingFailed"))}
          />
        </MuiGrid>
      </MuiGrid>
    </Card>
  );
}
