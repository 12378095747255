import { useData } from "@sinch/core";
import { Appointment, Fk, selectAppointment } from "@sinch/entity";
import { Text } from "@sinch/ui";
import { isNil } from "ramda";
import React, { ReactElement } from "react";

export function AppointmentCapacity({ id }: { id: Fk<Appointment> }): ReactElement | null {
  // @ts-expect-error
  const { selectEntity } = useData();
  const { freeCapacity, totalCapacity } = selectEntity(selectAppointment(id));

  if (isNil(freeCapacity) || isNil(totalCapacity)) {
    return null;
  }

  return <Text bold>{`${Math.max(0, totalCapacity - freeCapacity)}/${totalCapacity}`}</Text>;
}
