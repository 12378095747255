import { useData } from "@sinch/core";
import { ClosingProgress, PositionAttendance, selectWorker } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Color, IconId, Nullable } from "@sinch/types";
import { ActionItemHorizontal, IdentifierColorKey } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../../api";
import { useClosingStatusProps, useUpdatePresence } from "../../hooks";

interface UseLeadingActionProps {
  getLeadingElement: (item: PositionAttendance) => Nullable<ReactElement>;
  getLeadingEvent: (item: PositionAttendance) => void;
}

export function useLeadingAction(): UseLeadingActionProps {
  const { selectEntity } = useData(requestPresenceView);
  const { getProps } = useClosingStatusProps();
  const { setArrival, setFinished, setBreakEnd, setAttendanceIds } = useUpdatePresence([]);

  const getLeadingAction = (item: PositionAttendance): Nullable<ReactElement> => {
    const { worker, progressStatus } = item;
    const { name } = selectEntity(selectWorker(worker));

    let actionItem: { color: Color | IdentifierColorKey; icon: IconId; label: string };
    switch (progressStatus) {
      case ClosingProgress.Pending:
      case ClosingProgress.Absent:
        actionItem = { ...getProps(ClosingProgress.Present), label: t("Shift.closing.clockIn") };
        break;
      case ClosingProgress.Break:
        actionItem = {
          ...getProps(ClosingProgress.AfterBreak, ["color", "icon"]),
          label: t("Shift.closing.endBreak"),
        };
        break;
      case ClosingProgress.AfterBreak:
      case ClosingProgress.Present:
        actionItem = { ...getProps(ClosingProgress.Finished), label: t("Shift.closing.clockOut") };
        break;
      default:
        return null;
    }

    return <ActionItemHorizontal {...actionItem} label={name} subtitle={actionItem.label} />;
  };

  const handleSwipeLeading = (item: PositionAttendance): void => {
    const { id, progressStatus } = item;
    setAttendanceIds(id);

    switch (progressStatus) {
      case ClosingProgress.Pending:
      case ClosingProgress.Absent:
        setArrival();
        break;
      case ClosingProgress.Present:
        setFinished(new Date());
        break;
      case ClosingProgress.Break:
        setBreakEnd(new Date());
        break;
      default:
    }
  };
  return { getLeadingElement: getLeadingAction, getLeadingEvent: handleSwipeLeading };
}
