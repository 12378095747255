import { DataProvider } from "@sinch/core";
import React, { ReactElement, useMemo } from "react";
import { requestPositionRecentSearch } from "./api";
import { PositionSearch } from "./PositionSearch";

export function PositionSearchContainer(): ReactElement {
  const request = useMemo(() => requestPositionRecentSearch(), []);

  return (
    <DataProvider cache request={request}>
      <PositionSearch />
    </DataProvider>
  );
}
