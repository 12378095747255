import { HtmlString } from "@sinch/types";
import { Grid, Header, HtmlContent } from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
import React, {ReactElement, ReactNode} from "react";

interface SectionProps extends ChildrenProps {
  html?: boolean;

  title: ReactNode;
}

export function Section({ children, html, title }: SectionProps): ReactElement {
  return (
    <Grid spacing={1} vertical>
      <Header level={3}>{title}</Header>
      {html ? <HtmlContent html={children as HtmlString} /> : children}
    </Grid>
  );
}
