import { DataProvider, PagingParams, useSearchParamsCustom } from "@sinch/core";
import { useTranslation } from "@sinch/intl";
import { LoadingOverlay, useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestPushNotificationsList } from "../api";
import { PushNotificationsList } from "./PushNotificationsList";

export const PUSH_NOTIFICATION_LIST_DEFAULT_LIMIT = 25;

export function PushNotificationsListContainer(): ReactElement {
  const t = useTranslation();

  useTitleBar({
    container: "core:pushNotifications-list",
    locationTitle: t("PushNotifications.title"),
  });

  const { searchParams } = useSearchParamsCustom<PagingParams>({
    page: 0,
    limit: PUSH_NOTIFICATION_LIST_DEFAULT_LIMIT,
  });

  const request = useMemo(() => requestPushNotificationsList(PagingParams.pick(searchParams)), [searchParams]);

  return (
    <DataProvider progress={<LoadingOverlay />} request={request}>
      <PushNotificationsList />
    </DataProvider>
  );
}
