import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { mdiCancel, mdiCheck } from "@mdi/js";

/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { DataProvider, useData } from "@sinch/core";
import { Contract, Id, selectContract } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  B,
  composeRowLogic,
  createMuiStyleRow,
  DataTableRow,
  FileAnchor,
  Flex,
  Header,
  Icon,
  IconText,
  List,
  MuiDataTable,
  PaperContainer,
  Spacer,
  Text,
  useTitleBar,
} from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { all, isEmpty, isNil, map, pipe } from "ramda";
import { isNotNil, sortByProps } from "ramda-adjunct";
import React, { ReactElement, useMemo } from "react";
import { requestProfileContractList } from "./api";
import { ContractEmptyBox } from "./ContractEmptyBox";

// todo get rid of import/no-extraneous-dependencies,import/no-internal-modules

const useStyles = makeStyles((theme) => ({
  active: {},
  nonactive: { backgroundColor: theme.palette.background.default },
}));

export function MobileContractList(): ReactElement {
  const { curr, dur } = useFormat();
  useTitleBar({
    container: "core:contract",
    locationTitle: t("Contract.title"),
    showBack: true,
  });
  const styles = useStyles();
  const { dt } = useFormat();

  const { selectEntity } = useData(requestProfileContractList);
  const contracts = selectEntity(selectContract({}));

  const contractSorted = pipe(
    map(({ active, ...props }) => ({
      reversedActive: !active,
      active,
      ...props,
    })),
    sortByProps(["reversedActive", "startTime", "endTime"])
  )(contracts);

  return (
    <ContractListContainer>
      <PaperContainer>
        {isEmpty(contractSorted) && <ContractEmptyBox />}
        {!isEmpty(contractSorted) && (
          <List
            center
            disableGutters
            getBody={(contract: Contract) => {
              const { name, startTime, endTime, active, file, limits } = contract;
              return (
                <MuiGrid container direction="column" spacing={1}>
                  <MuiGrid item>
                    <Header level={2}>{name}</Header>
                    {limits?.map((limit, index) => (
                      <Box key={index} marginY={1}>
                        {all((value) => !isNil(value))([
                          limit.max_period_payment,
                          limit.payment_label,
                          limit.period_balance,
                        ]) && (
                          <Box>
                            <Text bold color="neutral" small>
                              {limit.payment_label}:{" "}
                            </Text>
                            <Text color="neutral" small>
                              {curr(limit.period_balance)}/{curr(limit.max_period_payment)}
                            </Text>
                          </Box>
                        )}
                        {all((value) => !isNil(value))([
                          limit.hours_label,
                          limit.max_period_hours,
                          limit.period_hours_balance,
                        ]) && (
                          <Box>
                            <Text bold color="neutral" small>
                              {limit.hours_label}:{" "}
                            </Text>
                            <Text color="neutral" small>
                              {dur.narrowShort(limit.period_hours_balance * 3600)}/
                              {dur.narrowShort(limit.max_period_hours * 3600)}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </MuiGrid>
                  <MuiGrid item>
                    <IconText color={active ? "success" : "neutral"} icon={active ? mdiCheck : mdiCancel}>
                      {active ? (t("status.active") as string) : (t("status.inactive") as string)}
                    </IconText>
                  </MuiGrid>
                  <MuiGrid item>
                    <Text>
                      <Spacer>
                        {`${t("display.validFrom")}:`}
                        <B>{isNotNil(startTime) ? dt(startTime) : t("Profile.display.unrestricted")}</B>
                      </Spacer>
                    </Text>
                  </MuiGrid>
                  <MuiGrid item>
                    <Text>
                      <Spacer>
                        {`${t("display.validTo")}:`}
                        <B>{isNotNil(endTime) ? dt(endTime) : t("Profile.display.unrestricted")}</B>
                      </Spacer>
                    </Text>
                  </MuiGrid>
                  <MuiGrid item>
                    <FileAnchor file={file} placeholder={t("action.show")} />
                  </MuiGrid>
                </MuiGrid>
              );
            }}
            getStyles={(contract: Id) => {
              const { active } = selectEntity(selectContract(contract));
              return active ? styles.active : styles.nonactive;
            }}
            items={contractSorted}
          />
        )}
      </PaperContainer>
    </ContractListContainer>
  );
}

type ContractColumn = "contract" | "file" | "from" | "status" | "to";

export function ContractList(): ReactElement {
  const { dt, curr, dur } = useFormat();
  const { selectEntity, selectResult } = useData(requestProfileContractList);
  const { contracts } = selectResult();
  const entities = selectEntity(selectContract(contracts));

  const entitiesSorted = pipe(
    map(({ active, ...props }) => ({
      reversedActive: !active,
      active,
      ...props,
    })),
    sortByProps(["reversedActive", "startDate", "endDate"])
  )(entities);

  const StatusColorRow = createMuiStyleRow(
    (theme) => ({
      active: {},
      nonactive: {
        backgroundColor: theme.palette.background.default,
      },
    }),
    ({ active }: Contract) => {
      if (active) return ["active"];
      return ["nonactive"];
    }
  );

  const ContractDataTable = MuiDataTable.withConfig<Contract, ContractColumn>({
    contract: {
      title: t("Contract.title"),
      selector: ({ name, active, limits }) => (
        <>
          <Text bold={active}>{name}</Text>
          {limits?.map((limit, index) => (
            <Box key={index} marginY={1}>
              {all((value) => !isNil(value))([limit.max_period_payment, limit.payment_label, limit.period_balance]) && (
                <Box>
                  <Text bold color="neutral" small>
                    {limit.payment_label}:{" "}
                  </Text>
                  <Text color="neutral" small>
                    {curr(limit.period_balance)}/{curr(limit.max_period_payment)}
                  </Text>
                </Box>
              )}
              {all((value) => !isNil(value))([
                limit.hours_label,
                limit.max_period_hours,
                limit.period_hours_balance,
              ]) && (
                <Box>
                  <Text bold color="neutral" small>
                    {limit.hours_label}:{" "}
                  </Text>
                  <Text color="neutral" small>
                    {dur.narrowShort(limit.period_hours_balance * 3600)}/
                    {dur.narrowShort(limit.max_period_hours * 3600)}
                  </Text>
                </Box>
              )}
            </Box>
          ))}
        </>
      ),
    },
    from: {
      title: t("display.validFrom"),
      selector: ({ startTime, active }) => (
        <Text bold={active}>{!isNil(startTime) ? dt.date(startTime) : t("Profile.display.unrestricted")}</Text>
      ),
    },
    to: {
      title: t("display.validTo"),
      selector: ({ endTime, active }) => (
        <Text bold={active}>{!isNil(endTime) ? dt.date(endTime) : t("Profile.display.unrestricted")}</Text>
      ),
    },
    status: {
      title: t("status.title"),
      selector: ({ active }) => (
        <Flex>
          {active ? <Icon color="success" icon={mdiCheck} /> : <Icon icon={mdiCancel} />}
          <Text bold={active}>{active ? t("status.active") : t("status.inactive")}</Text>
        </Flex>
      ),
    },
    file: {
      selector: ({ file, active }) => (
        <Text bold={active}>
          <FileAnchor file={file} placeholder={t("action.show")}>
            {t("noFile")}
          </FileAnchor>
        </Text>
      ),
    },
    // @ts-ignore
  }).withLogic(composeRowLogic([StatusColorRow, DataTableRow]));

  return (
    <>
      {isEmpty(entitiesSorted) && <ContractEmptyBox />}
      {!isEmpty(entitiesSorted) && (
        <ContractDataTable columns={["contract", "from", "to", "status", "file"]} data={entitiesSorted} />
      )}
    </>
  );
}

export function ContractListContainer({ children }: ChildrenProps): ReactElement {
  const request = useMemo(() => requestProfileContractList(), []);

  return <DataProvider request={request}>{children}</DataProvider>;
}
