import MuiAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import { mdiCheck, mdiChevronLeft, mdiClose } from "@mdi/js";

import { useCurrentUser, useInstanceSettings } from "@sinch/core";
import { Callback } from "@sinch/types";
import { ChildrenProps } from "@sinch/utils";
import { equals } from "ramda";
import { isNonEmptyString } from "ramda-adjunct";
import React, { Children, ReactElement, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoleSwitch } from "../../DevBar/RoleSwitch";
import { Icon } from "../../Icon";
import { Image } from "../../Image";
import { Header } from "../../Text";
import { pickBasedOnBgColor } from "../../theme/helpers";
import { useMobileLayout } from "../../UiProvider";
import { ExclusiveToggleContextProvider } from "../ExclusiveToggleContext";
import { MenuButton } from "./MenuButton";
import { useTitleBarParams } from "./TitleBarProvider";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarItem } from "./ToolbarItem";
import { UserMenu } from "./UserMenu";

const useStyles = makeStyles(({ mixins: { component, onLarge, ...mixins }, zIndex, palette }) => ({
  appBar: {
    zIndex: zIndex.drawer + 1,
    backgroundColor: ({ toolbarColor }: { toolbarColor: string }) =>
      palette.type === "dark" ? palette.background.default : toolbarColor,
  },
  titleBar: {
    ...component.titleBar.small,
    ...onLarge({
      ...component.titleBar.large,
    }),
  },
}));

function resolveWindowTitle(instanceName: string, locationTitle?: string): string {
  if (!locationTitle) return instanceName;
  if (equals(locationTitle, instanceName)) return locationTitle;
  return `${locationTitle} | ${instanceName}`;
}

function useWindowTitle(instanceName: string, locationTitle?: string): void {
  useEffect(() => {
    document.title = resolveWindowTitle(instanceName, locationTitle);
  }, [locationTitle, instanceName]);
}

interface TitleBarProps extends ChildrenProps {
  onMenuClick: Callback;
  menuOpen: boolean;
  plugins?: ReactNode[];
  notificationIndicator?: ReactNode;
}

/**
 * todo: is it possible to pass `clickLogoHandler` from outside?
 *  (so component doesnt have to depend on router useNavigate)
 *  => replace with navigateHome Callback prop
 */
export function TitleBar({ menuOpen, onMenuClick, plugins, notificationIndicator }: TitleBarProps): ReactElement {
  const mobile = useMobileLayout();
  const navigate = useNavigate();
  const { roles } = useCurrentUser();

  const user = useCurrentUser();
  const {
    name,
    theme: { logo, mobileLogo, toolbarColor },
  } = useInstanceSettings();

  const styles = useStyles({ toolbarColor });

  const { locationTitle, backHandler, submitHandler, confirmExit } = useTitleBarParams(mobile);

  useWindowTitle(name, typeof locationTitle === "object" ? "" : locationTitle);

  const userBlocked = isNonEmptyString(user.restrictions.access);

  /**
   * Left menu is displayed only on desktop layout.
   */
  const showLeftMenu = !mobile && !userBlocked;
  /**
   * Right menu is displayed only on mobile layout.
   *
   * It is replaced by dialog submit handler if present.
   */
  const showRightMenu = mobile && !submitHandler && !userBlocked;
  /**
   * Logo is displayed when no back handler present.
   */
  const showLogo = !backHandler;
  /**
   * Back button is always displayed for non-dialog views when handler present.
   *
   * For dialog views it is displayed only on mobile layout.
   */
  const showBack = backHandler && !userBlocked;
  /**
   * Submit button is displayed only for dialog views on mobile layout.
   */
  const showSubmit = submitHandler && !userBlocked;
  /**
   * User menu is displayed only on desktop layout.
   */
  const showUser = !user.id || !mobile;
  /**
   * Plugins are displayed only on desktop layout.
   *
   * todo: support setting by individual plugins
   *  (need to display notifications on mobile)
   */
  const showPlugins = !mobile && !userBlocked;

  return (
    <MuiAppBar
      className={styles.appBar}
      color={pickBasedOnBgColor(toolbarColor, "primary", "default")}
      position="fixed"
    >
      <MuiToolbar className={styles.titleBar} disableGutters variant={mobile ? "dense" : "regular"}>
        <ToolbarItem hidden={!showLeftMenu}>
          <MenuButton onMenuClick={onMenuClick} />
        </ToolbarItem>

        <ToolbarItem hidden={!showLogo}>
          <ToolbarButton onClick={() => navigate("/")}>
            {mobile ? (
              <Image fixed={{ height: "48px", width: "48px" }} src={mobileLogo.url} />
            ) : (
              <Image fixed={{ height: "64px" }} src={logo.url} />
            )}
          </ToolbarButton>
        </ToolbarItem>

        <ToolbarItem hidden={!showBack}>
          <ToolbarButton onClick={backHandler}>
            <Icon icon={confirmExit ? mdiClose : mdiChevronLeft} />
          </ToolbarButton>
        </ToolbarItem>

        <ToolbarItem grow>
          <Header level={mobile ? 2 : 1}>{locationTitle}</Header>
        </ToolbarItem>

        {Children.map(plugins, (plugin) => (
          <>{showPlugins && <ToolbarItem>{plugin}</ToolbarItem>}</>
        ))}

        <ExclusiveToggleContextProvider>
          {Object.entries(roles).length > 1 && (
            <ToolbarItem hidden={showSubmit}>
              <RoleSwitch />
            </ToolbarItem>
          )}
          {notificationIndicator && <ToolbarItem hidden={showSubmit}>{notificationIndicator}</ToolbarItem>}

          <ToolbarItem hidden={!showUser}>
            <UserMenu userBlocked={userBlocked} />
          </ToolbarItem>

          <ToolbarItem hidden={!showSubmit}>
            <ToolbarButton onClick={submitHandler}>
              <Icon icon={mdiCheck} />
            </ToolbarButton>
          </ToolbarItem>

          <ToolbarItem hidden={!showRightMenu}>
            <MenuButton icon={menuOpen ? mdiClose : undefined} onMenuClick={onMenuClick} />
          </ToolbarItem>
        </ExclusiveToggleContextProvider>
      </MuiToolbar>
    </MuiAppBar>
  );
}
