import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SpacingProps } from "@material-ui/system";
import { ChildrenProps, rejectFalsy } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  ignoreSpacing: {
    width: ({ mx }: { mx: SpacingBoxProps["mx"] }) => `calc(100% + ${theme.spacing(-mx) * 2}px)`,
  },
}));

type SpacingBoxProps = Pick<SpacingProps, "mx" | "my" | "px" | "py"> & ChildrenProps & { className?: string };

export function SpacingBox({ mx, my, px, py, className, children }: SpacingBoxProps): ReactElement {
  const styles = useStyles({ mx });
  const boxProps = rejectFalsy({
    mx,
    my,
    px,
    py,
  });
  return (
    <Box {...boxProps} className={clsx(className, mx < 0 && styles.ignoreSpacing)}>
      {children}
    </Box>
  );
}
