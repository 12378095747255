/**
 * Role of {@link Worker worker} towards given {@link Position position}.
 */
export enum WorkerRole {
  /**
   * Default role without any special meaning.
   */
  Worker = 0,

  /**
   * Crewboss role is responsible for other {@link Worker workers} in whole
   * {@link Shift shift}.
   */
  Crewboss = 1,

  /**
   * Backup role acts as a substitute when regular {@link Worker worker} is
   * delayed or absent.
   */
  Backup = 2,
}
