import { makeStyles } from "@material-ui/core/styles";
import { createPaletteStyles, getEntityColors } from "../paletteStyles";

const paletteStyles = createPaletteStyles(({ main }) => ({
  color: main,
}));

export const useStyles = makeStyles((theme) => ({
  structuredContainer: {
    "& > $paragraph:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  paragraph: {
    marginBottom: 0,
  },
  bold: theme.typography.bold,
  italic: theme.typography.italic,
  underline: theme.typography.underline,
  strikethrough: theme.typography.strikethrough,
  overline: theme.typography.overline,
  multiline: { whiteSpace: "pre-line" },
  ...paletteStyles(theme),
  ...getEntityColors(theme),
}));
