import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  FormLabel as MuiFormLabel,
  makeStyles,
} from "@material-ui/core";
import { Rating as MuiRating } from "@material-ui/lab";
import { Consumer } from "@sinch/types";
import React, { ReactElement, useCallback } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";

const useStyles = makeStyles(({ palette, spacing }) => ({
  control: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: spacing(1),
  },
  label: {
    color: palette.text.primary,
  },
  note: {
    margin: 0,
  },
}));

interface RatingInputProps
  extends Omit<ManagedInputProps, "dense" | "placeholder"> {}

export function RatingInput(props: RatingInputProps): ReactElement {
  const [baseProps, { setValue }] = useFormField<number, RatingInputProps>(
    props
  );

  const onChange = useCallback((next: number) => setValue(next), [setValue]);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <RatingInputBase {...baseProps} onChange={onChange} />;
}

export function RatingInputBase({
  disabled,
  error,
  label,
  name,
  note,
  onChange,
  onInvalid,
  value,
}: BaseInputProps<number, RatingInputProps, Consumer<number>>): ReactElement {
  const styles = useStyles();

  return (
    <MuiFormControl
      className={styles.control}
      disabled={disabled}
      error={error}
    >
      <MuiFormLabel className={styles.label}>{label}</MuiFormLabel>
      <MuiRating
        className={styles.input}
        name={name}
        onChange={(event, selected) => onChange(selected ?? 1)}
        onInvalid={onInvalid}
        value={value}
      />
      {note && (
        <MuiFormHelperText className={styles.note}>{note}</MuiFormHelperText>
      )}
    </MuiFormControl>
  );
}
