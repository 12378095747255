import { DataProvider, PagingParams, ScrollHistory, useData, useSearchParamsCustom } from "@sinch/core";
import { t } from "@sinch/intl";
import { LoadingOverlay, useMobileLayout, useTitleBar } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement, useMemo, useState } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../Position";
import { requestAppointmentList } from "./api";
import { AppointmentList } from "./AppointmentList";
import { AppoitnmentListCondensed } from "./AppointmentListCondensed";
import { AppointmentListEmptyMessage } from "./AppointmentListEmptyMessage";
import { AppointmentListMobile } from "./AppointmentListMobile";

export const APPOINTMENT_LIST_DEFAULT_LIMIT = 100;

export function AppointmentListContainer(): ReactElement {
  useTitleBar({
    container: "core:appointment-list",
    locationTitle: t("Appointment.listTitle"),
  });
  const mobile = useMobileLayout();

  const { searchParams } = useSearchParamsCustom<PagingParams>({ page: 0, limit: APPOINTMENT_LIST_DEFAULT_LIMIT });

  const request = useMemo(() => requestAppointmentList(PagingParams.pick(searchParams)), [searchParams]);

  return (
    <DataProvider progress={<LoadingOverlay />} request={request}>
      <ScrollHistory>{mobile ? <ListMobile /> : <AppointmentList />}</ScrollHistory>
    </DataProvider>
  );
}

function ListMobile(): ReactElement {
  const [view, setView] = useState<PositionView>(PositionView.Standard);
  const { selectResult } = useData(requestAppointmentList);
  const { appointmentIds } = selectResult();

  return isEmpty(appointmentIds) ? (
    <AppointmentListEmptyMessage />
  ) : (
    <>
      <PositionListViewSelectHeader
        countLabel={(count) => t("Appointment.display.appointmentCount", { count })}
        onViewChange={setView}
      />
      {view === PositionView.Condensed ? <AppoitnmentListCondensed /> : <AppointmentListMobile />}
    </>
  );
}
