import { Box, Grid as MuiGrid } from "@material-ui/core";
import { mdiCalendarToday, mdiClockOutline, mdiMapMarker, mdiPhone } from "@mdi/js";
import { useData } from "@sinch/core";
import { AppointmentQ, selectAppointment, selectAppointmentAttendance, selectLocation } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Card,
  DataList,
  Divider,
  GridLayout,
  Header,
  InformationDialog,
  Link,
  Map,
  Text,
  TimeRange,
  useDialog,
  useMobileLayout,
  WrapWords,
} from "@sinch/ui";
import { formatGeoPoint, isDefined, rejectFalsy } from "@sinch/utils";
import { isEmpty } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { getMapApiKey } from "../../env";
import { requestAppointmentDetail } from "./api";
import { AppointmentDetailWorkers } from "./AppointmentDetailWorkers";

const mapsSelector = (lat: number, lng: number): string =>
  // if (
  //   /* if we're on iOS, open in Apple Maps */
  //   navigator.platform.indexOf("iPhone") !== -1 ||
  //   navigator.platform.indexOf("iPad") !== -1 ||
  //   navigator.platform.indexOf("iPod") !== -1
  // ) {
  //   return `maps://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  //   /* else use Google */
  // }
  `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
export function AppointmentDetailParametersList(): ReactElement {
  const { dt } = useFormat();
  const mobile = useMobileLayout();
  const dialog = useDialog();
  const { selectEntity, selectResult, queryEntity } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const { startTime, endTime, location, contact } = selectEntity(selectAppointment(appointmentId));
  const { name: locationName, address: locationAddress, description: locationDescription, point } = selectEntity(
    selectLocation(location)
  );
  const workers = selectEntity(selectAppointmentAttendance({ appointment: appointmentId }, "worker"));

  const isAttending = queryEntity(AppointmentQ.User.isAttending(appointmentId));

  const hasWorkers = isNotNilOrEmpty(workers) && !(workers.length === 1 && isAttending);

  const parameters = rejectFalsy([
    {
      label: dt(startTime),
      icon: mdiCalendarToday,
    },
    {
      label: isDefined(endTime) ? <TimeRange endTime={endTime} startTime={startTime} /> : dt.time(startTime),
      icon: mdiClockOutline,
    },
    {
      label: locationName,
      content: [
        <WrapWords>{locationAddress}</WrapWords>,
        isDefined(point) && formatGeoPoint(point),
        <MuiGrid container spacing={2}>
          {point && (
            <MuiGrid item>
              <Link href={mapsSelector(point.lat, point.lng)} rel="noopener" target="_blank">
                {t("map")}
              </Link>
            </MuiGrid>
          )}
          <MuiGrid item>
            <Link onClick={() => dialog.open()}>{t("description")}</Link>
          </MuiGrid>
        </MuiGrid>,
      ],
      icon: mdiMapMarker,
      divider: true,
    },
    isNotNilOrEmpty(contact) && {
      label: t("Position.display.contact"),
      content: contact,
      icon: mdiPhone,
    },
  ]);

  const dialogData = [
    { label: t("address"), content: locationAddress },
    point && {
      label: t("gps"),
      content: (
        <Box display="flex" flexDirection="column">
          <Text>{formatGeoPoint(point)}</Text>
          <Link href={mapsSelector(point.lat, point.lng)} rel="noopener" target="_blank">
            {t("action.showOnMap")}
          </Link>
        </Box>
      ),
    },
    !isEmpty(locationDescription) && {
      label: t("description"),
      content: locationDescription,
    },
  ];

  return (
    <GridLayout.Item>
      <Card>
        <MuiGrid container direction={mobile ? "column" : "row"} spacing={3}>
          <MuiGrid item md={6} xs>
            <DataList data={rejectFalsy(parameters)} disablePadding />
            {hasWorkers && (
              <>
                <Divider />
                <AppointmentDetailWorkers />
              </>
            )}
          </MuiGrid>
          {!mobile && (
            <MuiGrid item md={6} xs>
              <Map apiKey={getMapApiKey() || undefined} point={point} />
            </MuiGrid>
          )}
        </MuiGrid>
      </Card>
      <InformationDialog control={dialog} hashKey="appointmentLocation" title={t("location")}>
        <Card>
          <Header level={1}>{locationName}</Header>
          <DataList data={dialogData} disableIcons disablePadding />
        </Card>
      </InformationDialog>
    </GridLayout.Item>
  );
}
