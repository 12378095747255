import { Link as MuiLink, LinkProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { ReactElement } from "react";

const useStyles = makeStyles(() => ({
  nativeLink: {
    color: "#1976D2 !important",
    cursor: "pointer",
    "&:active, &:visited": {
      color: "#1976D2 !important",
    },
  },
}));

// todo prop color is overwritten
export function Link({ children, className, ...props }: LinkProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiLink {...props} className={clsx(className, styles.nativeLink)}>
      {children}
    </MuiLink>
  );
}
