import { DataProvider, PagingParams, useSearchParamsCustom } from "@sinch/core";
import { t } from "@sinch/intl";
import { LoadingOverlay, useMobileLayout, useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestNewsList } from "../api";
import { NewsList } from "./NewsList";
import { NewsMobileList } from "./NewsMobileList";

export const NEWS_LIST_DEFAULT_LIMIT = 25;

export function NewsListContainer(): ReactElement {
  useTitleBar({
    container: "core:news-list",
    locationTitle: t("News.title"),
  });

  const mobile = useMobileLayout();
  const { searchParams } = useSearchParamsCustom<PagingParams>({ page: 0, limit: NEWS_LIST_DEFAULT_LIMIT });

  const request = useMemo(() => requestNewsList(PagingParams.pick(searchParams)), [searchParams]);

  return (
    <DataProvider progress={<LoadingOverlay />} request={request}>
      {mobile ? <NewsMobileList /> : <NewsList />}
    </DataProvider>
  );
}
