import { Box, Typography } from "@material-ui/core";
import { externalLink, Header, Link, ModalDialog } from "@sinch/ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface SupportedBrowsersDialogProps {
  dialogOpen: boolean;
  toggleDialog: () => void;
}

export const SupportedBrowsersDialog: React.FC<SupportedBrowsersDialogProps> = ({ dialogOpen, toggleDialog }) => {
  const { t } = useTranslation();
  const supportedBrowsers = [
    {
      type: t("PushNotifications.ios"),
      browsers: ["Safari 16.4"],
    },
    {
      type: t("PushNotifications.android"),
      browsers: ["Chrome 42"],
    },
    {
      type: t("PushNotifications.desktop"),
      browsers: ["Chrome 42", "Firefox 44", "Safari 16"],
    },
  ];
  return (
    <ModalDialog
      onClose={toggleDialog}
      open={dialogOpen}
      title={t("PushNotifications.supportedBrowsersTitle")}
      width="xs"
    >
      {supportedBrowsers.map((device) => (
        <Box mb={1}>
          <Header level={3}>{device.type}</Header>
          {device.browsers.map((browser) => (
            <Typography>{`${browser} ${t("PushNotifications.orNewer")}`}</Typography>
          ))}
        </Box>
      ))}
      <Box my={1} textAlign="center">
        <Link {...externalLink(" https://developer.mozilla.org/en-US/docs/Web/API/Push_API#browser_compatibility")} target="_blank" rel="noopener">
          {t("learnMore")}
        </Link>
      </Box>
    </ModalDialog>
  );
};
