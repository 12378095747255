import { mdiAccountMultiple } from "@mdi/js";
import { useData } from "@sinch/core";
import { selectAppointmentAttendance, selectWorker } from "@sinch/entity";
import { t } from "@sinch/intl";
import { ButtonCard, InformationDialog, useDialog } from "@sinch/ui";
import { map } from "ramda";
import React, { ReactElement } from "react";
import { WorkerList } from "../../components";
import { requestAppointmentDetail } from "./api";

export function AppointmentDetailWorkers(): ReactElement {
  const dialog = useDialog();

  const { selectEntity, selectResult } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const workers = selectEntity(selectAppointmentAttendance({ appointment: appointmentId }, "worker"));

  const data = map((worker) => {
    /* eslint-disable-next-line @typescript-eslint/no-shadow */
    const { id, name, avatar } = selectEntity(selectWorker(worker));
    return { id, name, avatar };
  }, workers);

  return (
    <>
      <ButtonCard action={dialog} icon={mdiAccountMultiple} showArrow>
        {t("Appointment.signUpWorkersLabel") /* Přihlášení lidé */} ({data.length})
      </ButtonCard>
      <InformationDialog
        control={dialog}
        hashKey="appointmentWorkerList"
        title={t("Appointment.signUpWorkersLabel") /* Přihlášení lidé */}
        width="xs"
      >
        <WorkerList data={data} />
      </InformationDialog>
    </>
  );
}
