import { defineRequest, FileHash } from "@sinch/core";
import { Fk, Payout, PayoutMethod } from "@sinch/entity";
import { Nullable } from "@sinch/types";

export interface PayoutParams {
  /**
   * todo: should be named `file` (singular) or `attachment`
   *  both in response schema and in {@link Payout} entity
   */
  files?: Nullable<FileHash>;

  grossAmount: number;

  method: Fk<PayoutMethod>;

  payout?: Fk<Payout>;
}

/**
 * todo: rename to Update since it handles modification as well?
 *  (or maybe just requestPayout?)
 */
export const requestWalletPayoutCreate = defineRequest("Wallets/PayoutCreate")
  .withParams<PayoutParams>()
  .withEmptyResponse()
  .withCreator((params: PayoutParams) => ({ params }));

export const WalletPayoutCreateFilesTarget = "worker/Wallets/PayoutCreateFiles";
