import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { mdiAccountMultiple, mdiCalendarToday, mdiClockOutline, mdiDomain, mdiMapMarker, mdiPhone } from "@mdi/js";

import { useBusinessRules, useCurrentUser, useData } from "@sinch/core";
import {
  ContactTypeLabel,
  Fk,
  Position,
  PositionContact,
  selectCompany,
  selectLocation,
  selectPosition,
  selectShift,
  selectUser,
  Shift,
  WorkerRole,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Button,
  Card,
  DataList,
  Divider,
  ExpandableArea,
  externalLink,
  FileList,
  GridLayout,
  Header,
  HtmlContent,
  Identifier,
  PhoneLink,
  routerLink,
  SpacingBox,
  Text,
  TimeRange,
  useMobileLayout,
  useSpacing,
  WrapWords,
} from "@sinch/ui";
import { isArray, isDefined, rejectFalsy } from "@sinch/utils";
import { subMinutes } from "date-fns";
import { equals, flatten, head, isEmpty, isNil, propEq, reject, sum } from "ramda";
import React, { ReactElement } from "react";
import { requestShiftAttendanceView } from "./api";

interface PositionInfoProps {
  position: Fk<Position>;
}

export function PositionInfo({ position }: PositionInfoProps): ReactElement {
  const { crewbossName: crewboss } = useBusinessRules();
  const { id: currentUserId } = useCurrentUser();

  const { dt } = useFormat();
  const { selectEntity, selectResult } = useData(requestShiftAttendanceView);
  const [, , data] = useSpacing();

  const {
    startTime,
    endTime,
    meetingTimeInterval,
    location,
    shift,
    crewbossDescription,
    workBreak,
    contacts: shiftContacts,
  } = selectEntity(selectPosition(position));
  const { crewbossDescriptionFiles } = selectResult();

  const contacts = reject(propEq("userId", currentUserId), shiftContacts);

  const { address: locationAddress, name: locationName, point, description: locationDescription } = selectEntity(
    selectLocation(location)
  );

  const { company } = selectEntity(selectShift(shift));
  const totalCapacity = sum(selectEntity(selectPosition({ shift }, "totalCapacity")));
  const freeCapacity = sum(selectEntity(selectPosition({ shift }, "freeCapacity")));
  const { name: companyName = "" } = company ? selectEntity(selectCompany(company)) : {};

  const meetingTime = dt.time(subMinutes(startTime, meetingTimeInterval));

  const getDisplayContact = ({ name, phone, type, userId }: PositionContact) => {
    if (userId) {
      const { name: userName, phone: userPhone } = selectEntity(selectUser(userId));
      return [userName, userPhone, type];
    }
    return [name, phone, type];
  };

  const parameters = [
    {
      label: dt(startTime),
      icon: mdiCalendarToday,
    },
    {
      label: `${t("Position.signUpWorkers")}: ${totalCapacity - freeCapacity}/${totalCapacity}`,
      icon: mdiAccountMultiple,
    },
    {
      label: [
        `${t("Position.meetingLabel")} ${meetingTime}`,
        <>
          {t("Shift.title")} <TimeRange endTime={endTime} showLength startTime={startTime} />
        </>,
        workBreak && (
          <>
            {t("Shift.plannedBreak")}
            <TimeRange endTime={workBreak.endTime} showLength startTime={workBreak.startTime} />
          </>
        ),
      ],
      icon: mdiClockOutline,
    },
    {
      label: locationName,
      content: [
        <WrapWords>{locationAddress}</WrapWords>,
        // isDefined(point) && formatGeoPoint(point),
        isDefined(point) && (
          <Box ml={-1}>
            <Button
              action={externalLink(`https://www.google.com/maps/search/?api=1&query=${point.lat},${point.lng}`)}
              color="info"
              stretch
              variant="text"
            >
              {t("action.showOnMap")}
            </Button>
            {!locationDescription && (
              <Box ml={-1}>
                <ExpandableArea color="info" dense style="button" title={t("action.showDescription")}>
                  <Box pl={2}>
                    <Text>{`fdsajkl fsdajkflf fjkfldsa jkl ;${locationDescription}`}</Text>
                  </Box>
                </ExpandableArea>
              </Box>
            )}
          </Box>
        ),
      ],
      // <Map markerText={locationName} defaultCenter={[lat, lng]} />
      icon: mdiMapMarker,
      divider: true,
    },
    company && {
      label: companyName,
      icon: mdiDomain,
    },
    isArray(contacts) && contacts.length > 0
      ? contacts.map((contact, index) => {
          const [name, phone, type] = getDisplayContact(contact);
          return {
            label: `${ContactTypeLabel()[type]} - ${name}`,
            icon: mdiPhone,
            divider: equals(index + 1, contacts.length),
            content: <PhoneLink phone={phone} />,
          };
        })
      : {
          label: t("Position.noContact"),
          icon: mdiPhone,
          divider: true,
        },
    ((!isNil(crewbossDescription) && !isEmpty(crewbossDescription)) || !isEmpty(crewbossDescriptionFiles)) && {
      label: t("Shift.closing.crewbossDescription", { crewboss }),
      content: (
        <MuiGrid container direction="column" spacing={data}>
          {crewbossDescription && (
            <MuiGrid item>
              <HtmlContent html={crewbossDescription} />
            </MuiGrid>
          )}
          {!isEmpty(crewbossDescriptionFiles) && (
            <MuiGrid item>
              <FileList files={crewbossDescriptionFiles} />
            </MuiGrid>
          )}
        </MuiGrid>
      ),
    },
  ];

  return <DataList data={rejectFalsy(flatten(parameters))} disablePadding />;
}

interface ShiftHeaderProps {
  shift: Fk<Shift>;
}

export function ShiftHeader({ shift }: ShiftHeaderProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);
  const { name } = selectEntity(selectShift(shift));
  const mobile = useMobileLayout();

  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  const position = head(selectEntity(selectPosition({ shift, role: WorkerRole.Crewboss }, "id")))!;

  const goToDetail = (
    <>
      <Divider />
      <Box pt={1}>
        <Button action={routerLink(`/position/${position}`)} color="info" size="small" variant="outlined">
          {t("Shift.goToDetail")}
        </Button>
      </Box>
    </>
  );

  return (
    <GridLayout.Item>
      <Card>
        <Header level={mobile ? 2 : 1}>{name}</Header>
        <Text small>
          {t("id")}: <Identifier entity="shift" id={shift} />
        </Text>

        <Divider />
        {mobile ? (
          <SpacingBox mx={-1}>
            <ExpandableArea dense title={t("detail")}>
              <PositionInfo position={position} />
              {goToDetail}
            </ExpandableArea>
          </SpacingBox>
        ) : (
          <>
            <PositionInfo position={position} />
            {goToDetail}
          </>
        )}
      </Card>
    </GridLayout.Item>
  );
}
