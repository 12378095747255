import MuiLink from "@material-ui/core/Link";
import MuiTable from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableFooter from "@material-ui/core/TableFooter";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";
import { PartialRecord } from "@sinch/types";
import { withProps } from "@sinch/utils";
import { keys, mergeAll } from "ramda";
import React, { Fragment, FunctionComponent } from "react";
import { AllowedAttribute } from "sanitize-html";
import { Divider } from "../Divider";
import { B, Header, I, P, S, U } from "../Text";
import { IMG, LI, OL, TD, UL } from "./components";
import { TagName } from "./dom";

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Rules = PartialRecord<TagName, FunctionComponent<any> | null>;

/**
 * todo: should we support smaller header font for h5-h6
 *  or disable those header levels in editor?
 */
const headerRules: Rules = {
  h1: withProps(Header, { level: 0 }),
  h2: withProps(Header, { level: 1 }),
  h3: withProps(Header, { level: 2 }),
  h4: withProps(Header, { level: 3 }),
  h5: withProps(Header, { level: 3 }),
  h6: withProps(Header, { level: 3 }),
};

/**
 * todo: implement image wrapper component?
 */
const imageRules: Rules = {
  img: IMG,
};

const layoutRules: Rules = {
  hr: Divider,
  span: Fragment,
  // div: null,
};

const linkRules: Rules = {
  a: MuiLink,
};

const listRules: Rules = {
  ul: UL,
  ol: OL,
  li: LI,
};

const tableRules: Rules = {
  table: MuiTable,
  thead: MuiTableHead,
  tbody: MuiTableBody,
  tfoot: MuiTableFooter,
  tr: MuiTableRow,
  th: TD,
  td: TD,
};

const textRules: Rules = {
  p: P,
  b: B,
  i: I,
  u: U,
  s: S,
  em: B,
  strong: B,
  br: () => <br />,
};

export const replaceRules: Rules = mergeAll([
  headerRules,
  imageRules,
  layoutRules,
  linkRules,
  listRules,
  tableRules,
  textRules,
]);

export const allowedTags: TagName[] = keys(replaceRules);

export const allowedAttributes: Record<string, AllowedAttribute[]> = {
  a: ["href", "name", "target"],
  img: ["src", "alt"],
};
