/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { AccordionProps, Box, InputLabel } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { useCurrentUser, useInstanceSettings } from "@sinch/core";
import { Fk, PersonalAttributeCitizenship, ProfileAttribute, ProfileAttributeType } from "@sinch/entity";
import {
  DateInput,
  FileInput,
  ImageInput,
  MultiSelectInput,
  NumberInput,
  SelectInput,
  TextInput,
  useFormValues,
} from "@sinch/forms";
import { t } from "@sinch/intl";
import { HtmlContent, ModalDialog, Text, useDialog } from "@sinch/ui";
import { useFormikContext } from "formik";
import {
  compose,
  defaultTo,
  equals,
  filter,
  findLast,
  groupBy,
  isEmpty,
  isNil,
  map,
  pick,
  pipe,
  pluck,
  prop,
  propEq,
  sortBy,
  uniq,
} from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useEffect } from "react";
import { ProfileAttributeData, profileProfileUpdateFilesTarget, profileProfileUpdateFilesTargetImage } from "../api";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { getBoolSelectOptions, parseInitialProfileValue, useFieldListMeta } from "../utils";

const useStyles = makeStyles(() => ({
  maxWidth: {
    maxWidth: "100%",
  },
}));

export function EditAttributes({
  attributesValues,
  attributes,
  expanded,
  handleExpansionChange,
}: {
  attributesValues: ProfileAttributeData[];
  attributes: ProfileAttribute[];
  expanded: string;
  handleExpansionChange: (group: string) => AccordionProps["onChange"];
}): ReactElement {
  const { citizenship: userCitizenship } = useCurrentUser();
  const { citizenship: selectedCitizenship = userCitizenship } = useFormValues();
  const { country } = useInstanceSettings();

  const blocks = pipe<
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    Pick<ProfileAttribute, "blockId" | "blockName">[],
    Pick<ProfileAttribute, "blockId" | "blockName">[]
  >(
    defaultTo([]),
    sortBy(compose(defaultTo(Infinity), prop("blockSortNumber"))),
    map(pick(["blockId", "blockName"])),
    uniq
  )(attributes);

  const attributesWithDefaults = pipe<
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    Record<string, ProfileAttribute[]>
  >(
    map(({ id, type, ...props }) => {
      const savedValue = findLast(propEq("id", id))(attributesValues);
      return { id, value: parseInitialProfileValue(null, type), type, ...props, ...savedValue };
    }),
    filter<ProfileAttribute>(
      ({ citizenship }) =>
        !isNil(citizenship) &&
        !isEmpty(citizenship) &&
        (equals(citizenship, PersonalAttributeCitizenship.Any) ||
          (equals(citizenship, PersonalAttributeCitizenship.Home) && equals(country, selectedCitizenship)) ||
          (equals(citizenship, PersonalAttributeCitizenship.Other) && !equals(country, selectedCitizenship)))
    ),
    sortBy(prop("sortNumber")),
    groupBy(prop("blockId"))
  )(attributes);

  return (
    <>
      {map(
        ({ blockId, blockName }) => (
          <DynamicAttributeItem
            key={blockId}
            attributes={attributesWithDefaults[blockId] || []}
            // @ts-ignore
            expanded={expanded === blockId}
            label={blockName}
            onExpansionChange={handleExpansionChange(blockId)}
          />
        ),
        blocks
      )}
    </>
  );
}

interface DynamicAttributeItemProps {
  label: string;
  attributes: ProfileAttribute[];
  onExpansionChange: AccordionProps["onChange"];
  expanded: boolean;
}

function DynamicAttributeItem({
  label,
  attributes,
  onExpansionChange,
  expanded,
}: DynamicAttributeItemProps): ReactElement | null {
  if (isEmpty(attributes)) {
    return null;
  }

  return (
    <EditAttributesItem
      attributes={attributes}
      expanded={expanded}
      label={label}
      namePrefix="dynamicAttributes"
      onExpansionChange={onExpansionChange}
    />
  );
}

interface EditProfileAttributesItemProps {
  label: string;
  expanded: boolean;
  onExpansionChange?: AccordionProps["onChange"];
  attributes: ProfileAttribute[];
  namePrefix?: string;
}

function EditAttributesItem({
  label: accordionHeadLabel,
  expanded,
  onExpansionChange,
  attributes,
  namePrefix,
}: EditProfileAttributesItemProps) {
  const fieldPrefix = namePrefix ? `${namePrefix}.` : "";
  // @ts-ignore
  const { filled, total, errors } = useFieldListMeta(pluck("id", attributes), fieldPrefix, ".value");
  const { errors: expirationErrors } = useFieldListMeta(pluck("id", attributes), fieldPrefix, ".expiration");

  return (
    <ProfileSettingAccordionItem
      error={errors + expirationErrors > 0}
      expanded={expanded}
      filled={filled}
      label={accordionHeadLabel}
      onExpansionChange={onExpansionChange}
      total={total}
    >
      <MuiGrid container direction="column">
        {map(
          ({ description, expirable, id, longDescription, mandatory, name, params, type }) => (
            <AttributeField
              description={description}
              expirable={expirable}
              fieldPrefix={fieldPrefix}
              id={id}
              longDescription={longDescription}
              mandatory={mandatory}
              name={name}
              params={params}
              type={type}
            />
          ),
          attributes
        )}
      </MuiGrid>
    </ProfileSettingAccordionItem>
  );
}

function AttributeField({
  description,
  expirable,
  fieldPrefix,
  id,
  longDescription,
  mandatory,
  name,
  params,
  type,
}: { fieldPrefix: string } & ProfileAttribute): ReactElement {
  const styles = useStyles();
  const { getFieldMeta, validateForm } = useFormikContext();
  const { value } = getFieldMeta(`${fieldPrefix}${id}.value`);

  useEffect(() => {
    validateForm();
  }, [value]);

  return (
    <>
      <MuiGrid key={id} className={styles.maxWidth} item>
        <MuiGrid container direction="column">
          <MuiGrid item>
            <GeneralProfileInput
              description={description}
              id={id}
              label={name}
              longDescription={longDescription}
              name={`${fieldPrefix}${id}.value`}
              params={params}
              required={mandatory}
              type={type}
            />
          </MuiGrid>
          {expirable && (
            <MuiGrid item>
              <MuiGrid container spacing={2}>
                <MuiGrid item md xs>
                  <Box pt="20px" textAlign="right">
                    <InputLabel>{t("Profile.expirationDate")}</InputLabel>
                  </Box>
                </MuiGrid>
                <MuiGrid item md={3} xs>
                  <DateInput
                    key={name}
                    dense
                    name={`${fieldPrefix}${id}.expiration`}
                    nullable
                    required={!isNilOrEmpty(value)}
                  />
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          )}
        </MuiGrid>
      </MuiGrid>
    </>
  );
}

function GeneralProfileInput({
  description,
  id,
  label,
  longDescription,
  name,
  params,
  required,
  type,
}: Partial<Omit<ProfileAttribute, "id" | "value" | "name">> & {
  id: Fk<ProfileAttribute>;
  name: string;
  required: boolean;
  label: string;
}) {
  const fullDescription = <AttributeDescription description={description} longDescription={longDescription} />;
  switch (type) {
    case ProfileAttributeType.Number:
      return (
        <NumberInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          placeholder={label}
          required={required}
        />
      );
    case ProfileAttributeType.Bool:
      return (
        <SelectInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          nullable
          options={getBoolSelectOptions()}
          placeholder={label}
          required={required}
        />
      );
    case ProfileAttributeType.Select:
      return (
        <SelectInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          nullable
          options={params?.options || []}
          placeholder={label}
          required={required}
        />
      );
    case ProfileAttributeType.Level:
      return (
        <SelectInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          nullable
          options={params?.options || []}
          placeholder={label}
          required={required}
        />
      );
    case ProfileAttributeType.MultiSelect:
      return (
        <MultiSelectInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          options={params?.options || []}
          placeholder={label}
          required={required}
        />
      );
    /* todo remove <div key={name}><Text>{name}</Text>...</div> labels and add form label to [File|Image]Input */
    case ProfileAttributeType.File:
      return (
        <div key={name}>
          <FileInput
            key={name}
            label={label}
            name={name}
            note={fullDescription}
            required={required}
            target={profileProfileUpdateFilesTarget(id)}
          />
        </div>
      );
    case ProfileAttributeType.Image:
      return (
        <div key={name}>
          <ImageInput
            key={name}
            cols={2}
            label={label}
            name={name}
            note={fullDescription}
            required={required}
            target={profileProfileUpdateFilesTargetImage(id)}
          />
        </div>
      );
    case ProfileAttributeType.Gallery:
      return (
        <div key={name}>
          <ImageInput
            key={name}
            cellHeight={120}
            cols={5}
            label={label}
            multiple
            name={name}
            note={fullDescription}
            required={required}
            target={profileProfileUpdateFilesTargetImage(id)}
          />
        </div>
      );
    case ProfileAttributeType.Text:
      return (
        <TextInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          placeholder={label}
          required={required}
        />
      );
    case ProfileAttributeType.LongText:
      return (
        <TextInput
          key={name}
          label={label}
          multiline
          name={name}
          note={fullDescription}
          placeholder={label}
          required={required}
        />
      );
    case ProfileAttributeType.Date:
      return (
        <DateInput
          key={name}
          label={label}
          name={name}
          note={fullDescription}
          nullable
          placeholder={label}
          required={required}
        />
      );
    default:
      return <Text key={name}>Type error</Text>;
  }
}

function AttributeDescription({
  description,
  longDescription,
}: Partial<Pick<ProfileAttribute, "description" | "longDescription">>): ReactElement {
  const dialog = useDialog(false);
  return !isEmpty(longDescription) && !isNil(longDescription) ? (
    <>
      <Text separator={" "}>
        {description}
        {description?.trim().endsWith(".") || isEmpty(description) ? "" : "."}
        <MuiLink onClick={() => dialog.open()}>{t("ProfileAttribute.showMoreDescription") as string}</MuiLink>
      </Text>
      <ModalDialog
        actions={{
          cancel: false,
          confirm: {
            action: () => dialog.close(),
            label: t("action.close"),
          },
        }}
        onClose={() => dialog.close()}
        open={dialog.state}
        width="md"
      >
        <HtmlContent html={longDescription} />
      </ModalDialog>
    </>
  ) : (
    <>{description}</>
  );
}
