import { makeStyles } from "@material-ui/core";
import { findIndex } from "ramda";
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { History } from "swiper";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ChildrenProps } from "@sinch/utils";
import { useScrollHistory } from "@sinch/core";

const useStyles = makeStyles(({ mixins: { component, onLarge, ...m }, ...o }) => ({
  swiper: {
    width: "100vw",
    // minHeight: "100vh",
    "& .swiper-wrapper": {
      height: `calc(100vh - ${component.titleBar.small.height}px - 56px)`,
      ...onLarge({
        height: `calc(100vh - ${component.titleBar.large.height}px - 56px)`,
      }),
    },
    "& .swiper-slide": {
      overflowY: "auto",
      height: `calc(100vh - ${component.titleBar.small.height}px - 56px)`,
      ...onLarge({
        height: `calc(100vh - ${component.titleBar.large.height}px - 56px)`,
      }),
    },
  },
  fullWidth: {
    width: "100vw",
  },
  badge: {
    bottom: "8px",
    right: "-8px",
  },
}));

export function SwiperPagesSwiper({ childrenProps, selected, onChange }) {
  const [loaded, setLoaded] = useState(false);
  const [loadedSlides, setLoadedSlides] = useState<Record<number, boolean[]>>([]);
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const classes = useStyles();
  const { scrollHistory, setScroll } = useScrollHistory();

  useEffect(() => {
    if (swiperRef) {
      const index = findIndex(({ dataset }) => dataset.history === selected, swiperRef.slides);
      swiperRef.slideTo(index);
    }
  }, [swiperRef, selected]);

  const handleSlideChange = useCallback(
    ({ activeIndex }: SwiperClass) => {
      if (swiperRef) {
        const { dataset } = swiperRef.slides[activeIndex];
        onChange(dataset.history);
      }
    },
    [selected, swiperRef]
  );

  useLayoutEffect(
    () => () => {
      if (swiperRef) {
        swiperRef.slides.forEach((slide, ix) => {
          setScroll(`swiperSlide${ix}`, slide.scrollTop); // set scroll position for history item
        });
      }
    },
    [swiperRef]
  );

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded && swiperRef) {
      swiperRef.slides.forEach((slide, ix) => {
        if (loadedSlides[ix] && scrollHistory[`swiperSlide${ix}`]) {
          slide.scroll({ top: scrollHistory[`swiperSlide${ix}`] || 0 }); // set scroll position for history item
          setScroll(`swiperSlide${ix}`, null);
        }
      });
    }
  }, [loaded, swiperRef, loadedSlides]);

  useEffect(() => {
    if (swiperRef && loadedSlides[swiperRef?.activeIndex]) {
      swiperRef.slides.forEach((slide, ix) => {
        if (!loadedSlides[ix]) {
          setTimeout(() => setLoadedSlides((slides) => ({ ...slides, [ix]: true })), 100);
        }
      });
    }
  }, [swiperRef, loadedSlides]);

  return (
    <Swiper
      className={classes.swiper}
      history={{
        replaceState: true,
        key: "dashboard",
      }}
      modules={[History]}
      onSlideChange={handleSlideChange}
      onSwiper={setSwiperRef}
      spaceBetween={30}
      updateOnWindowResize
    >
      {childrenProps.map(({ id, children: swiperChild, lazy }, index) => (
        <SwiperSlide key={id} data-history={id}>
          <SequentialRender
            loaded={loadedSlides[index]}
            setLoaded={(val) => setLoadedSlides((slides) => ({ ...slides, [index]: val }))}
          >
            {lazy ? <LazyLoadSwipe>{swiperChild}</LazyLoadSwipe> : swiperChild}
          </SequentialRender>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function SequentialRender({ children, loaded, setLoaded }: ChildrenProps): ReactNode | null {
  const { isActive, isNext, isPrev } = useSwiperSlide();
  useEffect(() => {
    if (isActive) {
      setLoaded(true);
    }
  }, [isActive]);

  if (!loaded) {
    return null;
  }
  return children;
}

function LazyLoadSwipe({ children }: ChildrenProps): ReactNode | null {
  const { isActive, isNext, isPrev } = useSwiperSlide();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && (isActive || isNext || isPrev)) {
      setTimeout(() => setLoaded(true), 1);
    }
  }, [isActive, isNext, isPrev]);

  if (!loaded) {
    return null;
  }
  return children;
}
