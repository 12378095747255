import { mdiAccountCancel, mdiAccountCheck, mdiCancel, mdiTimerSandEmpty } from "@mdi/js";
import { ClosingProgress, Fk, PositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, PopperMenu, UserMenuItem } from "@sinch/ui";
import { includes } from "ramda";
import React, { ReactElement } from "react";
import { useClosingStatusProps, useUpdatePresence } from "../../hooks";

export function EditMenu({
  target,
  attendances,
}: {
  target: string | number;
  attendances: Fk<PositionAttendance>[];
}): ReactElement {
  const { setPending, setArrival, setAbsent, resetBreak } = useUpdatePresence(attendances);
  const { getLabel } = useClosingStatusProps();

  return (
    <PopperMenu disablePortal>
      <Button stretch variant="text">
        {t("action.edit")}
      </Button>
      {includes(target, [ClosingProgress.Present, ClosingProgress.Absent]) && (
        <UserMenuItem action={() => setPending()} icon={mdiTimerSandEmpty} text={getLabel(ClosingProgress.Pending)} />
      )}
      {includes(target, [ClosingProgress.Present]) && (
        <UserMenuItem action={() => setAbsent()} icon={mdiAccountCancel} text={getLabel(ClosingProgress.Absent)} />
      )}
      {includes(target, [ClosingProgress.Break]) && (
        <UserMenuItem action={() => resetBreak()} icon={mdiCancel} text={t("Shift.closing.cancelBreak")} />
      )}
      {includes(target, [ClosingProgress.Finished]) && (
        <UserMenuItem action={() => setArrival()} icon={mdiAccountCheck} text={getLabel(ClosingProgress.Present)} />
      )}
    </PopperMenu>
  );
}
