import MuiCard from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import { makeStyles } from "@material-ui/core/styles";
import { Color } from "@sinch/types";
import { ChildrenProps, toElement } from "@sinch/utils";
import clsx from "clsx";
import { isNil } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { Action, resolveAction } from "../actions";
import { createPaletteStyles, getEntityColors, IdentifierColorKey } from "../paletteStyles";

const paletteStyles = createPaletteStyles(({ main, contrastText }) => ({
  color: contrastText,
  backgroundColor: main,
  border: "none",
}));

// todo: get actual spacing values from theme (see `useSpacing`)

const useStyles = makeStyles((theme) => ({
  content: {
    alignSelf: "center",
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowWrap: "break-word",
    hyphens: "auto",
    overflowX: "hidden",
    hyphenateLimitChars: 16,
  },
  emptyLabel: {},

  label: {
    alignItems: "center",
    borderRight: `1px solid ${theme.palette.background.default}`,
    display: "flex",
    flexShrink: 0,
    minWidth: theme.spacing(1),

    "&:not($emptyLabel)": {
      padding: theme.spacing(2),
    },
  },

  root: {
    maxWidth: "calc(100vw - 16px)",
    alignItems: "stretch",
    display: "flex",
  },

  ...paletteStyles(theme),
  ...getEntityColors(theme, true),
}));

interface ActionAreaProps {
  action?: Action;

  children: ReactNode;
}

function ActionArea({ action, children }: ActionAreaProps): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(action);
  return action ? (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <MuiCardActionArea className={styles.root} {...actionProps}>
      {children}
    </MuiCardActionArea>
  ) : (
    toElement(children)
  );
}

export interface StripBaseProps extends ChildrenProps {
  action?: Action;

  color?: Color | IdentifierColorKey | null;

  label?: ReactNode;
}

export function StripBase({ action, children, color, label, ...props }: StripBaseProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiCard className={clsx({ [styles.root]: isNil(action) })} elevation={0} {...props}>
      <ActionArea action={action}>
        {color && <div className={clsx(styles.label, !label && styles.emptyLabel, styles[color])}>{label}</div>}
        <div className={styles.content}>{children}</div>
      </ActionArea>
    </MuiCard>
  );
}
