import { Divider } from "@material-ui/core";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import { useData } from "@sinch/core";
import { Fk, Position, selectPositionAttendance, selectWorker } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Action, Button } from "@sinch/ui";
import { pipe, pluck, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { requestShiftAttendanceView } from "../api";
import { PositionHeader } from "../components";
import { WorkerPositionAttendanceListItem } from "./WorkerPositionAttendanceListItem";

interface PositionAttendancesProps {
  position: Fk<Position>;

  openDialog: Action;
}

export function PositionAttendances({ position, openDialog }: PositionAttendancesProps): ReactElement | null {
  const { selectEntity } = useData(requestShiftAttendanceView);

  const attendances = selectEntity(selectPositionAttendance({ position }));

  const sortedAttendanceIds = pipe(
    sortBy(({ worker }) => selectEntity(selectWorker(worker, "name"))),
    sortBy(({ worker }) => selectEntity(selectWorker(worker, "surname"))),
    pluck("id")
  )(attendances);

  return (
    <MuiList>
      {sortedAttendanceIds.length > 0 && (
        <MuiListItem>
          <PositionHeader position={position}>
            <Button action={openDialog} color="info" size="small" stretch variant="text">
              {t("Shift.closing.bulkEdit")}
            </Button>
          </PositionHeader>
        </MuiListItem>
      )}
      {sortedAttendanceIds.map((attendanceId) => (
        <React.Fragment key={attendanceId}>
          <Divider component="li" variant="fullWidth" />
          <WorkerPositionAttendanceListItem attendance={attendanceId} />
        </React.Fragment>
      ))}
    </MuiList>
  );
}
