import MuiFab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import MuiTooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { resolveAction } from "../actions";
import { Icon } from "../Icon";
import { createPaletteStyles } from "../paletteStyles";
import { IconButtonProps } from "./IconButton";

const paletteStyles = createPaletteStyles(({ main, dark, contrastText }) => ({
  color: contrastText,
  backgroundColor: main,
  "&:hover": {
    backgroundColor: dark,
  },
}));

const useStyles = makeStyles(paletteStyles);

export interface FloatingButtonProps
  extends Omit<IconButtonProps, "size" | "type"> {}

export function FloatingButton({
  action,
  color,
  disabled,
  icon,
  label,
}: FloatingButtonProps): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(action);

  return (
    <MuiTooltip arrow title={label ?? ""}>
      <MuiFab
        className={clsx(color && styles[color])}
        color="default"
        disabled={disabled}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...actionProps}
      >
        <Icon icon={icon} />
      </MuiFab>
    </MuiTooltip>
  );
}
