import { GridProps, makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";

const useStyles = makeStyles({
  columnItem: {
    columnBreakInside: "avoid",
  },
});

interface FluidColumnsGridItemProps extends GridProps {
  children: ReactNode;
}

export function FluidColumnsGridItem({
  children,
  className,
  ...gridProps
}: FluidColumnsGridItemProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiGrid {...gridProps} className={clsx(styles.columnItem, className)} item>
      {children}
    </MuiGrid>
  );
}
