import { DataProvider, responseHandlerKey } from "@sinch/core";
import { t } from "@sinch/intl";
import { InformationDialog, useSnackbar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requestReferralSubmit } from "./api";
import { ReferralForm } from "./ReferralForm";

export function ReferralDialogContainer(): ReactElement {
  const snackbar = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestReferralSubmit,
        ({ request: { params } }) => {
          snackbar("success", t("Referral.sendSuccess", { email: params.email }));
          navigate(pathname);
        },
        ({ request: { params } }) => {
          snackbar("error", t("Referral.sendFail", { email: params.email }));
        }
      ),
    [navigate, pathname, snackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <InformationDialog title={t("Referral.action.invite")} width="xs">
        <ReferralForm />
      </InformationDialog>
    </DataProvider>
  );
}
