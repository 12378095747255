/* eslint-disable react/jsx-props-no-spreading */
import { Grid as MuiGrid, GridProps } from "@material-ui/core";
import { ChildrenProps } from "@sinch/utils";
import React, { Children, ReactElement } from "react";

export function RowsGrid({
  children,
  itemProps,
  ...gridProps
}: ChildrenProps & GridProps & { itemProps?: GridProps }): ReactElement {
  const childrenList = Children.toArray(children);
  return (
    <MuiGrid container direction="column" {...gridProps}>
      {childrenList.map((child, i) => (
        <MuiGrid item {...itemProps} key={`rowsGrid-${i}`}>
          {child}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
