import { makeStyles } from "@material-ui/core";
import { Nullable, OneOrMore } from "@sinch/types";
import { ChildrenProps, flatList, useToggleStateObj } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement, ReactPortal, useEffect } from "react";
import { createPortal } from "react-dom";
import { FloatingButton, FloatingButtonProps } from "../../Button";
import { useToolbarPortal } from "./ToolbarProvider";

const useStyles = makeStyles(({ mixins: { component }, spacing, zIndex }) => ({
  floatingBar: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    gap: spacing(1),
    justifyContent: "flex-end",
    padding: spacing(1),
    position: "sticky",
    right: 0,
    zIndex: zIndex.appBar,

    "&$withToolbar": {
      bottom: component.toolBar.height,
    },
  },

  withToolbar: {},
}));

function FloatingBar({ children }: ChildrenProps): Nullable<ReactPortal> {
  const styles = useStyles();
  const element = useToolbarPortal("bottom");

  const [withToolbar, setWithToolbar] = useToggleStateObj();

  useEffect(() => {
    if (element) {
      setWithToolbar(element.childElementCount > 1);
    }
  });

  if (!element) {
    return null;
  }

  return createPortal(
    <span
      className={clsx(styles.floatingBar, withToolbar && styles.withToolbar)}
    >
      {children}
    </span>,
    element
  );
}

interface FloatingActionBarProps {
  buttons: OneOrMore<FloatingButtonProps>;
}

export function FloatingActions({
  buttons,
}: FloatingActionBarProps): ReactElement {
  return (
    <FloatingBar>
      {flatList(buttons).map(
        ({ action, color, disabled, icon, label }, idx) => (
          <FloatingButton
            key={icon ?? idx}
            action={action}
            color={color}
            disabled={disabled}
            icon={icon}
            label={label}
          />
        )
      )}
    </FloatingBar>
  );
}
