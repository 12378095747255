import { ImageGridContainer, ImageGridContainerProps, ImageGridItem } from "@sinch/ui";
import { flatList } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useFileInputContext } from "./FileInputProvider";

export interface ImageInputItemsProps extends ImageGridContainerProps {}

/**
 * todo: we need to have some standardized url params to access image thumbnails
 *  otherwise its impossible to display properly in image input because we dont
 *  know what sizes were generated on backend
 */
export function ImageInputItems({ cellHeight, cols, center }: ImageInputItemsProps): ReactElement {
  const { removeFile, progress, storage, value } = useFileInputContext();

  return (
    <ImageGridContainer cellHeight={cellHeight} center={center} cols={cols}>
      {value &&
        flatList(value).map((file) => {
          try {
            return (
              <ImageGridItem
                key={file}
                href
                onRemove={() => removeFile(file)}
                progress={progress(file)}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...storage(file)}
              />
            );
          } catch (e) {
            return null;
          }
        })}
    </ImageGridContainer>
  );
}
