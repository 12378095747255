/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import MuiGrid from "@material-ui/core/Grid";
import { FileInput, SelectDateTimeInput, SwitchInput, SwitchInputBase, TextInput, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { Card, Divider, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";
import { AttendanceClosingFormState, ShiftAttendanceCloseFilesTarget } from "./api";

interface ShiftOptionsProps {
  toggleFiles: Callback;

  toggleNote: Callback;

  withFiles: boolean;

  withNote: boolean;
}

export function ShiftOptions({ toggleFiles, toggleNote, withFiles, withNote }: ShiftOptionsProps): ReactElement {
  const [, innerSpacing, dataSpacing] = useSpacing();
  const { withStartTime } = useFormValues<AttendanceClosingFormState>();

  return (
    <Card>
      <MuiGrid container direction="column">
        <MuiGrid item>
          <SwitchInput label={t("Shift.closing.differentStart")} name="withStartTime" />
          {withStartTime && <SelectDateTimeInput fullWidth label={t("display.newDate")} name="startTime" />}
        </MuiGrid>
        <Divider spacing={[dataSpacing, -innerSpacing, 0]} />
        <MuiGrid item>
          <SwitchInputBase
            label={t("Shift.closing.noteShift")}
            name="withNote"
            onChange={toggleNote}
            value={withNote}
          />
          {withNote && <TextInput label={t("Shift.closing.noteShift")} multiline name="note" />}
        </MuiGrid>
        <Divider spacing={[dataSpacing, -innerSpacing, 0]} />
        <MuiGrid item>
          <SwitchInputBase
            label={t("Shift.closing.uploadFile")}
            name="withFiles"
            onChange={toggleFiles}
            value={withFiles}
          />
          {withFiles && (
            <FileInput label={t("action.selectFile")} multiple name="files" target={ShiftAttendanceCloseFilesTarget} />
          )}
        </MuiGrid>
      </MuiGrid>
    </Card>
  );
}
