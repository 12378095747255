import SvgIcon from "@material-ui/core/SvgIcon";
import { mdiBell, mdiNewspaper } from "@mdi/js";
import { usePushSubscription } from "@sinch/core";
import { SinchPushNotification } from "@sinch/icons";
import { useTranslation } from "@sinch/intl";
import { Paper, TabsNavigation, useMobileLayout } from "@sinch/ui";
import React from "react";
import { NotificationList } from "../Notification";
import { NewsList } from "./News";
import { PushNotificationsList } from "./PushNotifications";

export const CommunicationContainer: React.FC = () => {
  const t = useTranslation();
  const mobile = useMobileLayout();
  const { isModuleActive } = usePushSubscription();

  return (
    <Paper>
      <TabsNavigation
        replace
        tabs={[
          {
            element: <NewsList />,
            ...(mobile
              ? {
                  icon: mdiNewspaper,
                }
              : {
                  label: t("News.title"),
                }),
            path: "news",
          },
          {
            element: <NotificationList />,
            ...(mobile
              ? {
                  icon: mdiBell,
                }
              : {
                  label: t("Notification.titlePlural"),
                }),
            path: "notifications",
          },
          ...(isModuleActive
            ? [
                {
                  element: <PushNotificationsList />,
                  ...(mobile
                    ? {
                        icon: <SvgIcon component={SinchPushNotification} />,
                      }
                    : {
                        label: t("PushNotifications.title"),
                      }),
                  path: "pushNotifications",
                },
              ]
            : []),
        ]}
      />
    </Paper>
  );
};
