import { InputAdornment as MuiInputAdornment, TextField as MuiTextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isNumber, isTruthy } from "ramda-adjunct";
import React, { ReactElement, ReactNode } from "react";
import { BaseInputProps, ManagedInputProps } from "../Input";

interface InternalInputProps extends ManagedInputProps {
  /**
   * Text displayed on input left side.
   */
  prefix?: string | ReactNode;

  /**
   * Prevent form submit unless value provided.
   */
  required?: boolean;

  /**
   * Text displayed on input right side.
   */
  suffix?: string | ReactNode;

  /**
   * Native input type.
   */
  type: "email" | "number" | "password" | "search" | "tel" | "text";
}

export interface InternalInputNumberProps extends InternalInputProps {
  /**
   * Minimal numeric value.
   */
  max?: number;

  /**
   * Minimal numeric value.
   */
  min?: number;

  /**
   * Numeric value step.
   */
  step?: number | "any";

  /**
   * Native input type.
   */
  type: "number";
}

export interface InternalInputTextProps extends InternalInputProps {
  /**
   * Maximal text length.
   */
  maxLength?: number;

  /**
   * Maximal text length.
   */
  minLength?: number;

  /**
   * Pass `true` for flexible TextArea scaling up to 64 rows
   * or pass numeric value to set custom `rowsMax`.
   */
  multiline?: boolean | number;

  /**
   * Validation pattern as {@link RegExp} string.
   */
  pattern?: string;

  /**
   * Native input type.
   */
  type: "email" | "password" | "search" | "tel" | "text";
}

const useStyles = makeStyles(({ palette }) => ({
  backgroundColor: {
    background: palette.background.paper,
  },
}));

export function InternalInput(props: BaseInputProps<number, InternalInputNumberProps>): ReactElement;

export function InternalInput(props: BaseInputProps<string, InternalInputTextProps>): ReactElement;

/**
 * Base input component used by individual wrapper input components
 * specialized for specific value type.
 *
 * Internal only, should not be used directly in application code.
 */
export function InternalInput(
  props: BaseInputProps<number, InternalInputNumberProps> | BaseInputProps<string, InternalInputTextProps>
): ReactElement {
  const styles = useStyles();
  const {
    dense,
    disabled,
    error,
    label,
    max,
    maxLength,
    min,
    minLength,
    multiline,
    name,
    note,
    onChange,
    onInvalid,
    pattern,
    placeholder,
    prefix,
    required,
    step,
    suffix,
    type,
    value,
    onBlur,
    ...restProps
  } = { ...props };

  return (
    <MuiTextField
      className={styles.backgroundColor}
      disabled={disabled}
      error={error}
      fullWidth
      helperText={note}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: suffix && <MuiInputAdornment position="end">{suffix}</MuiInputAdornment>,
        inputProps: {
          max,
          maxLength,
          min,
          minLength,
          pattern,
          step,
          ...restProps,
        },
        startAdornment: prefix && <MuiInputAdornment position="start">{prefix}</MuiInputAdornment>,
      }}
      label={label}
      margin={dense ? "dense" : "normal"}
      multiline={isTruthy(multiline)}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInvalid={onInvalid}
      placeholder={placeholder}
      required={required}
      rows={isTruthy(multiline) ? 3 : undefined}
      rowsMax={isNumber(multiline) ? multiline : 64}
      type={type}
      value={value}
      variant="outlined"
      {...restProps}
    />
  );
}
