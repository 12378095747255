import { mdiMagnify } from "@mdi/js";
import { Box } from "@material-ui/core";

import { makeSearchQuery, useData } from "@sinch/core";
import { Id, RecentSearch, selectRecentSearch, WorkerRole } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Grid, Icon, List, routerLink, Text, useSpacing } from "@sinch/ui";
import { join, map, pipe, prop } from "ramda";
import { isNonEmptyArray } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { WorkerRoleOptions } from "../../Shift";
import { requestPositionRecentSearch } from "./api";
import { PositionSearchParams } from "./params";

/**
 * todo: extract to common enum utils
 */
const rolesText = pipe(
  map((r: WorkerRole) => WorkerRoleOptions()[r].label),
  join(", ")
);

function RecentSearchDisplay({ from, place, radius, role, query, to }: RecentSearch): ReactElement {
  const { dt } = useFormat();

  return (
    <Grid vertical>
      {query && (
        <Text>
          {t("PositionSearch.query")}: {query}
        </Text>
      )}
      <Text>{place}</Text>
      <Text>{radius && `${t("display.distance")} ${radius.distance}km`}</Text>
      <Text>
        {from && <Text>{t("dateFromWithDate", { date: dt(from) })}</Text>}
        {to && <Text>{t("dateToWithDate", { date: dt(to) })}</Text>}
      </Text>
      <Text>{isNonEmptyArray(role) ? rolesText(role) : t("PositionSearch.anyRole")}</Text>
    </Grid>
  );
}

const iconSelector = (): ReactElement => <Icon icon={mdiMagnify} />;

/* eslint-disable react/jsx-props-no-spreading */
export function RecentSearches(): ReactElement {
  const { selectResult, selectEntity } = useData(requestPositionRecentSearch);
  const [, innerSpacing] = useSpacing();

  const bodySelector = (id: Id): ReactElement => <RecentSearchDisplay {...selectEntity(selectRecentSearch(id))} />;

  const clickHandlerSelector = (id: Id) =>
    routerLink({
      pathname: "/position",
      search: makeSearchQuery(PositionSearchParams.fromRecentSearch(selectEntity(selectRecentSearch(id)))),
    });

  const searches = selectResult(prop("recentSearches"));

  return (
    <Box margin={-innerSpacing}>
      <List
        center
        clickable
        getAction={clickHandlerSelector}
        getBody={bodySelector}
        /* @ts-expect-error */
        getIcon={iconSelector}
        items={searches}
      />
    </Box>
  );
}
