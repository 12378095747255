import { t } from "@sinch/intl";
import { ChildrenProps, createCheckedContext, flatList, isFuture, parseDate } from "@sinch/utils";
import { filter, map, pipe, pluck, propEq } from "ramda";
import { isNotEmpty } from "ramda-adjunct";
import React, { ReactElement, useCallback, useEffect, useMemo, useReducer } from "react";
import { FileInfo, isUploadErrorResponse, ServerMessage } from "../contract";
import { useBackend } from "./BackendProvider";
import {
  FileHash,
  FileId,
  FileUploadHandlers,
  initUploadState,
  LocalFileKey,
  localFileKey,
  QueueFile,
  uploadReducer,
} from "./FileUploadReducer";

/** todo: extract to common selectors? */
const getErrorText = pipe<ServerMessage[], ServerMessage[], string[]>(filter(propEq("level", "ERROR")), pluck("text"));

export type GetFile = (key: FileId) => FileInfo;

type RemoveFile = (key: LocalFileKey) => void;

type StoreFile = (target: string, files: File[], handlers?: FileUploadHandlers) => (LocalFileKey | FileHash)[];

export type FileUploadStatus = "paused" | "ready" | "uploading";

export interface FileUpload {
  /**
   * Get file metadata.
   */
  get: GetFile;

  /**
   * Remove file from upload queue.
   */
  remove: RemoveFile;

  /**
   * Enqueue files for upload and return file cache keys.
   */
  store: StoreFile;

  status: FileUploadStatus;
}

const FileUploadContext = createCheckedContext<FileUpload>("FileUpload");
export const { use: useFileUpload } = FileUploadContext;

/**
 * Provide hooks for file uploads
 *
 * TODO: comment and maybe refactor
 */
export function FileUploadProvider({ children }: ChildrenProps): ReactElement {
  const { uploadFile } = useBackend();

  const [state, dispatch] = useReducer(uploadReducer, null, initUploadState);

  const handleUpload = useCallback(
    async ({
      file,
      key,
      handlers: { onError, onProgress, onSuccess },
      target,
    }: QueueFile) => {
      dispatch({ type: "start" });
      try {
        const response = await uploadFile({ target, file }, (progress) => onProgress?.(key, progress));

        if (isUploadErrorResponse(response)) {
          dispatch({ type: "error", payload: key });
          onError?.(key, getErrorText(response.messages));
        } else {
          const { result } = response;
          const { expiration: expirationRaw, hash, url } = result;
          const { name, size, type } = file;

          /* todo: parse dates in backend handler before passing into application */
          const expiration = parseDate(expirationRaw);

          dispatch({
            type: "success",
            payload: {
              expiration,
              hash,
              key,
              name,
              size,
              type,
              url,
            },
          });
          onSuccess?.(key, hash);
        }
      } catch (error) {
        dispatch({ type: "error", payload: key });
        onError?.(key, [t("FileInput.serverError")]);
      }
    },
    [uploadFile]
  );

  const { cache, index, queue, running, uploading } = state;

  const get = useCallback<GetFile>((key) => cache[key] ?? cache[index[key]], [
    cache,
    index,
  ]);

  const remove = useCallback<RemoveFile>((key) => {
    dispatch({ type: "remove", payload: key });
  }, []);

  const store = useCallback<StoreFile>(
    (target, files, handlers = {}): string[] =>
      map((file) => {
        const key = localFileKey(file);
        const cached = cache[index[key]];

        if (cached) {
          if (isFuture(cached.expiration)) return cached.hash;

          dispatch({ type: "prune" });
        }

        dispatch({
          type: "enqueue",
          payload: { file, handlers, target },
        });

        return key;
      }, flatList(files)),
    [cache, index]
  );

  const context = useMemo<FileUpload>(
    () => ({
      get,
      remove,
      store,
      /* eslint-disable-next-line no-nested-ternary */
      status: running ? (uploading ? "uploading" : "ready") : "paused",
    }),
    [get, remove, running, store, uploading]
  );

  useEffect(() => {
    if (!uploading && isNotEmpty(queue)) handleUpload(queue[0]);
  }, [handleUpload, queue, uploading]);

  return <FileUploadContext value={context}>{children}</FileUploadContext>;
}
