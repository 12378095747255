import { useNavigate } from "react-router-dom";

export const useRedirect = () => {
  const navigate = useNavigate();
  return (type: string, id: number) => {
    switch (type) {
      case "Position":
        navigate(`/position/${id}`);
        break;
      default:
        break;
    }
  };
};
