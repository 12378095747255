import { Box, Grid as MuiGrid } from "@material-ui/core";
import { ScrollHistory, useData } from "@sinch/core";
import { ErrorHandledRoutes } from "@sinch/core/router";
import { selectSnippet, Snippet } from "@sinch/entity";
import { PaperContainer, useMobileLayout, useSpacing } from "@sinch/ui";
import { head } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import { Notice } from "../../Dashboard/components";
import { MobileReimbursementList, ReimbursementListContainer } from "../Reimbursement";
import { requestWalletStatus } from "./api";
import { MobileSectionContainer } from "./MobileSectionContainer";
import { MobileWalletPaymentList } from "./MobileWalletPaymentList";
import { MobileWalletPayouts } from "./MobileWalletPayouts";
import { MobileWalletTransactions } from "./MobileWalletTransactions";
import { PayoutRequestContainer } from "./PayoutRequestContainer";
import { WalletDetailContainer } from "./WalletDetail";
import { WalletHeader } from "./WalletHeader";
import { WalletPayoutListContainer } from "./WalletPayoutListContainer";
import { WalletTransactionListContainer } from "./WalletTransactionListContainer";

export function WalletStatus(): ReactElement {
  const mobile = useMobileLayout();
  const [outer, , data] = useSpacing();

  const { selectResult, selectEntity } = useData(requestWalletStatus);

  const { balance, availablePayoutMethodIds } = selectResult();

  const notices = selectEntity(selectSnippet({ name: "wallet_main" }));

  const getNotice = (notice: Snippet[]) => {
    if (isNotNilOrEmpty(notice)) {
      // @ts-ignore
      const { body, color, id, style } = head<Snippet>(notice);
      return (
        <Box pb={outer}>
          <Notice body={body} color={color} id={id} style={style} />
        </Box>
      );
    }
    return null;
  };

  if (mobile)
    return (
      <>
        {getNotice(notices)}
        <ErrorHandledRoutes>
          <Route
            element={
              <div>
                <MuiGrid container direction="column" spacing={data}>
                  <MuiGrid item>
                    <WalletHeader balance={balance} />
                  </MuiGrid>
                  <MuiGrid item>
                    <ScrollHistory>
                      <MobileWalletPaymentList />
                    </ScrollHistory>
                  </MuiGrid>
                </MuiGrid>
              </div>
            }
            path="/*"
          />
          <Route
            element={
              <WalletTransactionListContainer>
                <MobileSectionContainer id="transactions" title="Transactions">
                  <PaperContainer>
                    <MobileWalletTransactions />
                  </PaperContainer>
                </MobileSectionContainer>
              </WalletTransactionListContainer>
            }
            path="/transaction/*"
          />
          <Route
            element={
              <ReimbursementListContainer>
                <MobileSectionContainer id="payouts" title="Payouts">
                  <PaperContainer>
                    <MobileReimbursementList />
                  </PaperContainer>
                </MobileSectionContainer>
              </ReimbursementListContainer>
            }
            path="/reimbursements/*"
          />
          <Route
            element={
              <WalletPayoutListContainer>
                <MobileSectionContainer id="payouts" title="Payouts">
                  <PaperContainer>
                    <MobileWalletPayouts />
                  </PaperContainer>
                </MobileSectionContainer>
              </WalletPayoutListContainer>
            }
            path="/payout/*"
          />
        </ErrorHandledRoutes>
        <PayoutRequestContainer availablePayout={availablePayoutMethodIds} balance={balance} />
        <WalletDetailContainer />
      </>
    );

  return (
    <>
      <WalletHeader balance={balance} />
      <PayoutRequestContainer availablePayout={availablePayoutMethodIds} balance={balance} />
      <WalletDetailContainer />
    </>
  );
}
