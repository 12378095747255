import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputTextProps } from "./InternalInput";

interface TextInputProps extends Omit<InternalInputTextProps, "type"> {}

export function TextInput(props: TextInputProps): ReactElement {
  const [{ value, onChange, ...baseProps }] = useFormField<string, TextInputProps>(props);
  const [inputEvent, setInputEvent] = useState<React.ChangeEvent<any>>();
  const [inputValue, setInputValue] = useState(value);
  const { isSubmitting } = useFormikContext();

  const timeout = useRef<number>();

  const onPostponeChange = (e: React.ChangeEvent<any>) => {
    setInputEvent(e);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }
    if (inputEvent) {
      timeout.current = window.setTimeout(() => onChange(inputEvent), 400);
    }
    return () => window.clearTimeout(timeout.current);
  }, [inputEvent]);

  useEffect(() => {
    if (isSubmitting && inputEvent) {
      onChange(inputEvent);
      window.clearTimeout(timeout.current);
    }
  }, [inputEvent, isSubmitting]);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <TextInputBase {...baseProps} onBlur={onChange} onChange={onPostponeChange} value={inputValue} />;
}

export function TextInputBase(props: BaseInputProps<string, TextInputProps>): ReactElement {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <InternalInput type="text" {...props} />;
}
