// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Position, selectPosition } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  ChipedList,
  CondensedBody,
  CondensedCard,
  CondensedContent,
  CondensedHeader,
  CondensedTitle,
  ListItemContent,
  routerLink,
  SimpleListItem,
  SpacingBox,
  Text,
  useSpacing,
} from "@sinch/ui";
import { prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { PositionPlace, PositionTime } from "../../components";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "./api";

export function FeedbackCondensedList(): ReactElement {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { feedbackPositionIds } = selectResult();

  const positionsList = selectEntity(selectPosition(feedbackPositionIds));

  const items = sortBy(prop("startTime"))(positionsList);

  return (
    <SpacingBox mx={-1}>
      <ChipedList>
        {items.map(({ id }: Position) => (
          <PositionListRow key={id} id={id} />
        ))}
      </ChipedList>
    </SpacingBox>
  );
}

function PositionListRow({ id }: { id: Fk<Position> }): ReactElement {
  const { selectEntity } = useData(requestDashboardAttendanceList);

  const { shift } = selectEntity(selectPosition(id));
  const [, , data] = useSpacing();

  return (
    <SimpleListItem action={routerLink(`/position/${id}`)}>
      <ListItemContent>
        <PositionListItemContent id={id} />
        <Box display="flex" justifyContent="flex-end" pt={data}>
          <Button action={routerLink(`#/shift/${shift}/feedback`)} color="info" size="small" stretch variant="outlined">
            {t("Feedback.action.submit")}
          </Button>
        </Box>
      </ListItemContent>
    </SimpleListItem>
  );
}

function PositionListItemContent({ id }: { id: Fk<Position> }) {
  return (
    <CondensedCard>
      <CondensedHeader>
        <CondensedTitle>
          <Text bold>
            <PositionReference id={id} name noId title />
          </Text>
        </CondensedTitle>
      </CondensedHeader>
      <CondensedBody>
        <CondensedContent>
          <PositionTime id={id} />
          <PositionPlace id={id} />
        </CondensedContent>
      </CondensedBody>
    </CondensedCard>
  );
}
