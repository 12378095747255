import { Box, Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectProfession } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import { Header, Icon, Identifier, useMobileLayout, useSpacing } from "@sinch/ui";
import React, { PropsWithChildren, ReactElement } from "react";
import { WorkerRoleOptions } from "../../Shift/Closing";

interface PositionHeaderProps {
  position: Fk<Position>;
}

export const PositionHeader = ({ position, children }: PropsWithChildren<PositionHeaderProps>): ReactElement => {
  const { dt } = useFormat();
  const mobile = useMobileLayout();
  const [, , data] = useSpacing();
  const { selectEntity } = useData();
  const { role, startTime, endTime, profession, title } = selectEntity(selectPosition(position));
  const { name: professionName } = selectEntity(selectProfession(profession));

  return (
    <MuiGrid container spacing={data}>
      <MuiGrid item xs="auto">
        <Box alignItems={mobile ? undefined : "center"} display="flex" height="100%">
          <Icon color={WorkerRoleOptions()[role].color} icon={WorkerRoleOptions()[role].icon} />
        </Box>
      </MuiGrid>
      <MuiGrid item xs>
        <Box alignItems="center" display="flex" height="100%">
          <MuiGrid container spacing={mobile ? 0 : data}>
            <MuiGrid item md="auto" xs={12}>
              <Header level={3}>
                <Identifier bold entity="position" id={position} />
                {` ${WorkerRoleOptions()[role].label} - ${professionName}${title ? ` - ${title}` : ""}`}
              </Header>
            </MuiGrid>
            <MuiGrid item md="auto" xs={12}>
              {dt.time(startTime)} - {dt.time(endTime)}
            </MuiGrid>
          </MuiGrid>
        </Box>
      </MuiGrid>
      <MuiGrid item xs="auto">
        <Box alignItems="center" display="flex" height="100%">
          {children}
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
};
