import {
  mdiAccount,
  mdiAmbulance,
  mdiCancel,
  mdiCarOff,
  mdiCheckCircle,
  mdiClockOutline,
  mdiCrown,
  mdiSeatPassenger,
  mdiSteering,
} from "@mdi/js";
import { useBusinessRules } from "@sinch/core";
import { PositionAttendanceStatus, TransportAttendanceRole, WorkerRole } from "@sinch/entity";
import { t } from "@sinch/intl";
import { replace, toUpper } from "ramda";

/*
 * todo: roles and attendance options should be read from entity enums,
 *  labels, icons and colors might be mapped here, but probably will
 *  be extracted to some shared module
 *
 * todo: read labels from enum instead
 */

export const WorkerRoleOptions = () => {
  const { crewbossName } = useBusinessRules();
  return {
    [WorkerRole.Worker]: {
      value: WorkerRole.Worker,
      label: t("worker", { count: 1 }),
      icon: mdiAccount,
      color: "default",
    },
    [WorkerRole.Crewboss]: {
      value: WorkerRole.Crewboss,
      label: replace(/^./, toUpper)(crewbossName),
      icon: mdiCrown,
      color: "colorCrewboss",
      disabled: true,
    },
    [WorkerRole.Backup]: {
      value: WorkerRole.Backup,
      label: t("Position.role.backup"),
      icon: mdiAccount,
      color: "info",
    },
  } as const;
};

export const TransportRoleOptions = () =>
  ({
    [TransportAttendanceRole.Passenger]: {
      value: TransportAttendanceRole.Passenger,
      label: t("Transport.role.passenger"),
      icon: mdiSeatPassenger,
      color: "info",
    },
    [TransportAttendanceRole.Driver]: {
      value: TransportAttendanceRole.Driver,
      label: t("Transport.role.driver"),
      icon: mdiSteering,
      color: "success",
    },
    [TransportAttendanceRole.NoTransport]: {
      value: TransportAttendanceRole.NoTransport,
      label: t("Transport.role.noTransportation"),
      icon: mdiCarOff,
      color: "neutral",
    },
  } as const);

export const PresenceOptions = () =>
  ({
    [PositionAttendanceStatus.Present]: {
      value: true,
      label: t("Attendance.presence.present"),
      icon: mdiCheckCircle,
      color: "success",
    },
    [PositionAttendanceStatus.Late]: {
      value: true,
      label: t("Attendance.presence.late"),
      icon: mdiClockOutline,
      color: "warning",
      noteLabel: t("Shift.closingLateNote"),
    },
    [PositionAttendanceStatus.Absent]: {
      value: false,
      label: t("Attendance.presence.miss"),
      icon: mdiCancel,
      color: "error",
      noteLabel: t("Shift.closingMissNote"),
    },
    [PositionAttendanceStatus.Excused]: {
      value: false,
      label: t("Attendance.presence.excused"),
      icon: mdiAmbulance,
      color: "error",
    },
  } as const);

export const PresenceOptionsOnTime = () => ({
  1: PresenceOptions()[PositionAttendanceStatus.Present],
  0: PresenceOptions()[PositionAttendanceStatus.Absent],
});

export const PresenceOptionsLate = () => ({
  1: PresenceOptions()[PositionAttendanceStatus.Late],
  0: PresenceOptions()[PositionAttendanceStatus.Absent],
});

export function isPresent(status: PositionAttendanceStatus): boolean {
  return status !== PositionAttendanceStatus.Absent && status !== PositionAttendanceStatus.Excused;
}
