import { mdiCalendarBlank, mdiAlert, mdiCurrencyUsd } from "@mdi/js";
import MuiGrid from "@material-ui/core/Grid";

import { useData } from "@sinch/core";
import { Penalty, PenaltyType, selectPenalty } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import { CardList, IconText, Identifier, Text } from "@sinch/ui";
import { prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { requestPenaltyList } from "./api";

export function PenaltyCardList(): ReactElement {
  const { selectEntity, selectResult } = useData(requestPenaltyList);
  const { penaltyIds } = selectResult();
  const penaltyEntities = sortBy(prop("startTime"), selectEntity(selectPenalty(penaltyIds)));

  return (
    <CardList<Penalty>
      cardProps={({ position, shift, name }) => ({
        title: (
          <Text bold>
            {position && (
              <>
                <Identifier entity="shift" id={shift} />
                -
                <Identifier entity="position" id={position} />
              </>
            )}
            <b>{name}</b>
          </Text>
        ),
      })}
      content={(penalty: Penalty) => <PenaltyCardContent penalty={penalty} />}
      items={penaltyEntities}
    />
  );
}

function PenaltyCardContent({ penalty }: { penalty: Penalty }): ReactElement {
  const { id } = penalty;
  const { curr, dt } = useFormat();

  const { selectEntity } = useData(requestPenaltyList);

  const { type, startTime, endTime, penalty: amount = 0 } = selectEntity(selectPenalty(id));

  return (
    <MuiGrid container direction="column" spacing={1}>
      <MuiGrid item>
        <IconText icon={mdiCalendarBlank}>
          <Text bold>
            {dt.full(startTime)} - {dt.time(endTime)}
          </Text>
        </IconText>
      </MuiGrid>
      <MuiGrid item>
        <IconText icon={mdiAlert}>{PenaltyType[type]}</IconText>
      </MuiGrid>
      <MuiGrid item>
        <IconText icon={mdiCurrencyUsd}>
          <Text color="error">{curr(amount)}</Text>
        </IconText>
      </MuiGrid>
    </MuiGrid>
  );
}
