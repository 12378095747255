/**
 * Transport attendance role.
 */
export enum TransportAttendanceRole {
  NoTransport = 0,

  Driver = 1,

  Passenger = 2,
}
