import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import { IconId } from "@sinch/types";
import React, { ReactElement } from "react";
import { Icon } from "../../Icon";
import { useMenuContext } from "./context";

interface MenuIconProps {
  icon: IconId;
}

export function MenuIcon({ icon }: MenuIconProps): ReactElement | null {
  const { showIcon } = useMenuContext();

  if (!showIcon) return null;

  return (
    <MuiListItemIcon>
      <Icon icon={icon} />
    </MuiListItemIcon>
  );
}
