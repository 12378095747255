import { Box } from "@material-ui/core";

import MuiGrid from "@material-ui/core/Grid";
import {
  mdiAccount,
  mdiAccountCancel,
  mdiAccountMultipleOutline,
  mdiCancel,
  mdiCar,
  mdiCheck,
  mdiCrown,
  mdiHumanGreetingVariant,
  mdiLock,
  mdiShuffleVariant,
} from "@mdi/js";
import * as Sentry from "@sentry/react";

import { iconPersonPending } from "@sinch/assets/img/customIcons";
import { useData } from "@sinch/core";
import {
  Fk,
  Position,
  PositionQ,
  selectAppointment,
  selectLocation,
  selectPosition,
  selectProfession,
  selectShift,
  sOr,
  WorkerRole,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Chip,
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  createDividerRow,
  createMuiStyleCell,
  createMuiStyleRow,
  createNestedRow,
  createTextAlignCell,
  dateTimeLogic,
  Icon,
  identifierPrefix,
  MuiDataTable,
  MuiDataTableDisplay,
  Paper,
  routerLink,
  Text,
  TimeRange,
  TypographyCell,
} from "@sinch/ui";
import { isUndefined } from "@sinch/utils";
import { isSameDay } from "date-fns";
import { always, pipe, pluck, prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { SearchParamsPagination } from "../../components";
import { WorkerRoleOptions } from "../../Shift";
import { PositionReference } from "../Detail/PositionReference";
import { requestPositionList } from "./api";
import { FeaturedChip } from "./components";

type PositionColumn = "capacity" | "date" | "location" | "name" | "profession" | "status" | "time" | "transport";

const TextAlignRightCell = createTextAlignCell("right");

function RoleIcon({ role }: { role: WorkerRole }) {
  switch (role) {
    case WorkerRole.Backup:
      return <Icon color="info" icon={mdiAccountMultipleOutline} />;
    case WorkerRole.Crewboss:
      return <Icon color="colorCrewboss" icon={mdiCrown} />;
    default:
      return <Icon color="primary" icon={mdiAccount} />;
  }
}

/*
 * todo: how to properly specify hover color?
 */
const ClickableRow = createClickableRow((position: Fk<Position>) => routerLink(`/position/${position}`));

/**
 * todo: improve icon rendering
 *  (should be centered by default to reduce boilerplate)
 */
export function PositionListDesktop(): ReactElement {
  const { dt } = useFormat();
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionList);

  const { positionIds, featuredPositionIds = [] } = selectResult();

  const DateDividerRow = createDividerRow((current: Fk<Position>, previous: Fk<Position>, row) => {
    if (isUndefined(previous) && row < featuredPositionIds.length) {
      return <FeaturedChip size="large" />;
    }

    const [currentStartTime, previousStartTime] = selectEntity(selectPosition([current, previous], "startTime"));

    const showDivider =
      row >= featuredPositionIds.length && (isUndefined(previous) || !isSameDay(currentStartTime, previousStartTime));

    return (
      showDivider && <Chip color="primary" label={`${dt.wday(currentStartTime)} ${dt.chip(currentStartTime)}`} large />
    );
  });

  const ConnectedPositionRow = createNestedRow(
    (id: Fk<Position>) => selectEntity(selectPosition(id, "connected")) ?? [],
    "name"
  );

  const StyledRow = createMuiStyleRow(
    // @ts-ignore
    (theme) => ({
      unavailable: {
        "& .MuiTableCell-body": {
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      assigned: {
        "& .MuiTableCell-body": {
          backgroundColor: theme.palette.completed.main,
        },
      },
      applied: {
        "& .MuiTableCell-body": {
          backgroundColor: theme.palette.applied.main,
        },
      },
      confirmationNeeded: {
        "& .MuiTableCell-body": {
          backgroundColor: theme.palette.confirmationNeeded.main,
        },
      },
    }),
    (id: Fk<Position>) => {
      if (queryEntity(PositionQ.User.isRequiredConfirmation(id))) {
        return ["confirmationNeeded"];
      }
      if (queryEntity(PositionQ.User.isAssigned(id))) {
        return ["assigned"];
      }
      if (queryEntity(PositionQ.User.isApplicant(id))) {
        return ["applied"];
      }
      if (queryEntity(sOr(PositionQ.isFull, PositionQ.User.hasRequirementsFailed, PositionQ.User.hasConflicting)(id))) {
        return ["unavailable"];
      }
      return [];
    }
  );

  const PositionDataTable = MuiDataTable.withConfig<Fk<Position>, PositionColumn>({
    name: {
      title: t("Position.title"),
      selector: (id) => (
        <Text bold>
          <PositionReference id={id} name noId title />
        </Text>
      ),
      logic: composeCellLogic<Fk<Position>, PositionColumn, MuiDataTableDisplay>([
        createMuiStyleCell(
          {
            name: {
              "& p": {
                fontWeight: "bold",
              },
            },
          },
          always(["name"])
        ),
        TypographyCell,
      ]),
    },
    date: {
      title: t("date"),
      selector: (id) => selectEntity(selectPosition(id, "startTime")),
      logic: composeCellLogic<Fk<Position>, PositionColumn, MuiDataTableDisplay>([
        dateTimeLogic(),
        TextAlignRightCell as never,
      ]),
    },
    time: {
      title: t("time"),
      selector: (id) => {
        const { startTime, endTime } = selectEntity(selectPosition(id));
        return <TimeRange endTime={endTime} showLength startTime={startTime} />;
      },
    },
    location: {
      title: t("location"),
      selector: (id) => {
        const location = selectEntity(selectPosition(id, "location"));
        return selectEntity(selectLocation(location, "name"));
      },
    },
    profession: {
      title: t("Position.professionAndRole"),
      selector: (id) => {
        const { profession, role } = selectEntity(selectPosition(id));
        const professionName = selectEntity(selectProfession(profession, "name"));
        const roleName = WorkerRoleOptions()[role].label;
        // todo: improve Grid api so we can use size=auto
        return (
          <Box display="flex">
            <RoleIcon role={role} />
            {professionName} - {roleName}
          </Box>
        );
      },
    },
    capacity: {
      title: t("Position.size"),
      selector: (id) => {
        const { totalCapacity, freeCapacity } = selectEntity(selectPosition(id));
        return `${Math.max(0, totalCapacity - freeCapacity)}/${totalCapacity}`;
      },
    },
    status: {
      selector: (id) => {
        const icons = [];
        const applicants = selectEntity(selectPosition(id, "applicants"));

        if (applicants && !queryEntity(PositionQ.User.isAssigned(id)))
          icons.push(
            <Icon
              key="hasApplicant"
              color="colorApplicant"
              icon={mdiHumanGreetingVariant}
              tooltip={t("Position.applicantTooltip")}
            />
          );

        if (queryEntity(PositionQ.User.isAssigned(id))) {
          if (queryEntity(PositionQ.User.isRequiredConfirmation(id))) {
            icons.push(
              <Icon
                key="isAssigned"
                color="warning"
                icon={iconPersonPending}
                tooltip={t("Dashboard.shiftsToConfirmTitle")}
              />
            );
          } else {
            icons.push(
              <Icon key="isAssigned" color="success" icon={mdiCheck} tooltip={t("Position.assignedStatusTooltip")} />
            );
          }
        }

        if (queryEntity(PositionQ.User.isApplicant(id))) {
          icons.push(
            <Icon
              key="isApplicant"
              color="colorApplicant"
              icon={mdiCheck}
              tooltip={t("Position.applicantStatusTooltip")}
            />
          );
        }

        if (!applicants && queryEntity(PositionQ.isLocked(id)))
          icons.push(<Icon key="isLocked" icon={mdiLock} tooltip={t("Position.locked")} />);

        if (queryEntity(PositionQ.User.hasRequirementsFailed(id)))
          icons.push(
            <Icon
              key="metRequirements"
              color="error"
              icon={mdiCancel}
              tooltip={t("Position.display.requirementsNotMet")}
            />
          );

        if (queryEntity(PositionQ.User.hasConflicting(id))) {
          const conflicting = selectEntity(selectPosition(id, "conflicting"));
          // @ts-expect-error
          const { position, appointment } = conflicting;
          const conflictingList: string[] = [];
          position.forEach((conflictingPositionId: Fk<Position>) => {
            try {
              const { shift } = selectEntity(selectPosition(conflictingPositionId));
              const { name = "test" } = selectEntity(selectShift(shift));
              conflictingList.push(`${identifierPrefix("position") + conflictingPositionId} ${name}`);
            } catch (error) {
              Sentry.captureException(error);
            }
          });
          appointment.forEach((conflictingAppointmentId: Fk<Position>) => {
            try {
              const { name } = selectEntity(selectAppointment(conflictingAppointmentId));
              conflictingList.push(`${identifierPrefix("appointment") + conflictingAppointmentId} ${name}`);
            } catch (error) {
              Sentry.captureException(error);
            }
          });

          icons.push(
            <Icon
              key="inConflict"
              color="error"
              icon={mdiShuffleVariant}
              tooltip={
                <MuiGrid container direction="column">
                  {[t("Position.display.conflictingJob"), ...conflictingList].map((item) => (
                    <MuiGrid item>{item}</MuiGrid>
                  ))}
                </MuiGrid>
              }
            />
          );
        }

        if (!applicants && queryEntity(PositionQ.isFull(id)))
          icons.push(<Icon key="isFull" icon={mdiAccountCancel} tooltip={t("Position.display.fullPosition")} />);

        return (
          <Box display="flex" flexWrap="noWrap">
            {icons}
          </Box>
        );
      },
    },
    transport: {
      selector: (id) => {
        const hasTransport = queryEntity(PositionQ.hasTransport(id));
        return hasTransport && <Icon icon={mdiCar} tooltip={t("Position.display.withTransport")} />;
      },
    },
  }).withLogic(composeRowLogic([DateDividerRow, ConnectedPositionRow, StyledRow, ClickableRow]));

  const sortedPositionsIds = pipe<Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    pluck("id")
  )(selectEntity(selectPosition(positionIds)));

  const sortedFeaturedPositionsIds = pipe<Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    pluck("id")
  )(selectEntity(selectPosition(featuredPositionIds)));

  return (
    <Paper>
      <PositionDataTable
        columns={["name", "date", "time", "location", "profession", "capacity", "status", "transport"]}
        data={[...sortedFeaturedPositionsIds, ...sortedPositionsIds]}
        size="small"
      >
        <SearchParamsPagination defaultLimit={100} />
      </PositionDataTable>
    </Paper>
  );
}
