import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import {
  mdiAccount,
  mdiAccountCancel,
  mdiAccountMultiple,
  mdiCalendarToday,
  mdiCancel,
  mdiCar,
  mdiCheck,
  mdiClockOutline,
  mdiCrown,
  mdiHumanGreetingVariant,
  mdiLink,
  mdiLock,
  mdiMapMarker,
  mdiShuffleVariant,
} from "@mdi/js";

// eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules
import { iconPersonPending } from "@sinch/assets/img/customIcons";
import { ParamsOf, QueryEntity, ResponseOf, useData, useSearchParamsCustom } from "@sinch/core";
import {
  Fk,
  Position,
  PositionQ,
  selectAppointment,
  selectLocation,
  selectPosition,
  selectProfession,
  selectShift,
  WorkerRole,
} from "@sinch/entity";
import { Format, t } from "@sinch/intl";
import { Color, Nullable } from "@sinch/types";
import {
  Card,
  CardUnderlay,
  Center,
  ConditionalWrapper,
  DateChip,
  DisableStyle,
  Grid,
  IconText,
  Identifier,
  IdentifierColorKey,
  ListBase,
  routerLink,
  Spacer,
  Text,
  TimeRange,
  useSpacing,
} from "@sinch/ui";

import { ChildrenProps, isUndefined, rejectFalsy } from "@sinch/utils";
import { isBefore, isSameDay } from "date-fns";
import {
  always,
  both,
  cond,
  equals,
  has,
  head,
  identity,
  isEmpty,
  isNil,
  length,
  pipe,
  pluck,
  prop,
  reduce,
  sortBy,
  T,
} from "ramda";
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
import { WorkerRoleOptions } from "../../Shift";
import { PositionReference } from "../Detail/PositionReference";
import { requestPositionList } from "./api";
import { FeaturedChip } from "./components";

type PositionQuery = QueryEntity<ResponseOf<typeof requestPositionList>>;

function getName(query: PositionQuery, position: Fk<Position>): ReactNode {
  return <PositionReference id={position} name noId title />;
}

function DateRow({ position }: { position: Fk<Position> }): ReactElement | null {
  const { queryEntity } = useData(requestPositionList);
  const { startTime, endTime } = queryEntity(selectPosition(position));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, inner] = useSpacing();

  return (
    <MuiGrid container spacing={inner}>
      <MuiGrid item xs="auto">
        <IconText icon={mdiCalendarToday}>
          <Format dt={startTime} />
        </IconText>
      </MuiGrid>
      <MuiGrid item xs>
        <IconText icon={mdiClockOutline}>
          <TimeRange endTime={endTime} showLength startTime={startTime} />
        </IconText>
      </MuiGrid>
    </MuiGrid>
  );
}

function LocationRow({ position }: { position: Fk<Position> }): ReactElement | null {
  const { queryEntity } = useData(requestPositionList);
  const location = queryEntity(selectPosition(position, "location"));
  const name = queryEntity(selectLocation(location, "name"));

  return useMemo(() => <IconText icon={mdiMapMarker}>{name}</IconText>, [name]);
}

function TransportRow({ position }: { position: Fk<Position> }): ReactElement | null {
  const { queryEntity: query } = useData(requestPositionList);
  const shift = query(selectPosition(position, "shift"));
  const transport = query(selectShift(shift, "transport"));

  const label = cond([
    [both(has("to"), has("from")), always(t("Transport.thereAndBack"))], // Transport there and back
    [has("to"), always(t("Transport.there"))], // Transport there
    [has("from"), always(t("Transport.back"))], // Transport back
    // [T, always(t("Transport.noTransport"))],
  ])(transport);

  return label ? <IconText icon={mdiCar}>{label}</IconText> : null;
}

const ProfessionLabel = React.memo(({ role, professionName }) => {
  const roleName = WorkerRoleOptions()[role].label;
  const content = (
    <Spacer dropEmpty separator=" - ">
      {professionName}
      {roleName}
    </Spacer>
  );
  let professionIconText;

  if (equals(role, WorkerRole.Backup)) {
    professionIconText = (
      <IconText color="secondary" icon={mdiAccountMultiple}>
        {content}
      </IconText>
    );
  }
  if (equals(role, WorkerRole.Crewboss)) {
    professionIconText = (
      <IconText color="colorCrewboss" icon={mdiCrown}>
        {content}
      </IconText>
    );
  }
  if (equals(role, WorkerRole.Worker)) {
    professionIconText = <IconText icon={mdiAccount}>{content}</IconText>;
  }

  return professionIconText;
});

function ProfessionRow({ position }: { position: Fk<Position> }): ReactElement | null {
  const { queryEntity: query } = useData(requestPositionList);
  const { profession, role } = query(selectPosition(position));
  const professionName = query(selectProfession(profession, "name"));

  return (
    <MuiGrid container>
      <MuiGrid item xs>
        <ProfessionLabel professionName={professionName} role={role} />
      </MuiGrid>
      <MuiGrid item>
        <Box alignItems="flex-end" display="flex" height="100%">
          <Text bold>{getCapacity(query, position)}</Text>
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
}

function getCapacity(query: PositionQuery, position: Fk<Position>) {
  const { freeCapacity, totalCapacity } = query(selectPosition(position));

  return `${Math.max(0, totalCapacity - freeCapacity)}/${totalCapacity}`;
}

function ApplicantRow({ position }: { position: Fk<Position> }): ReactElement | null {
  const { queryEntity } = useData(requestPositionList);
  const isApplicant =
    !queryEntity(PositionQ.User.isAssigned(position)) && queryEntity(selectPosition(position, "applicants"));
  return useMemo(() => {
    if (isApplicant) {
      return (
        <IconText color="colorApplicant" icon={mdiHumanGreetingVariant}>
          {t("Position.applicantTooltip") as string}
        </IconText>
      );
    }

    return null;
  }, [isApplicant]);
}

const SignUpStatus = React.memo(() => (
  <IconText color="success" icon={mdiCheck}>
    {t("Position.youAreSignUp") as string}
  </IconText>
));

const ConfirmationRequiredStatus = React.memo(() => (
  <IconText color="warning" icon={iconPersonPending}>
    {t("Dashboard.shiftsToConfirmTitle") as string}
  </IconText>
));

const LockStatus = React.memo(() => <IconText icon={mdiLock}>{t("Position.display.lockedJob") as string}</IconText>);

const ConflictStatus = React.memo(({ positionId }: { positionId: Fk<Position> }) => {
  const { selectEntity } = useData(requestPositionList);
  const { conflicting } = selectEntity(selectPosition(positionId));
  const { position: conflictedPositions, appointment: conflictedAppointments } = conflicting;
  const appointmentId = conflictedAppointments ? head(conflictedAppointments) : null;

  const hasConflictingPosition = conflictedPositions && !isEmpty(conflictedPositions);
  return (
    <IconText color="error" icon={mdiShuffleVariant}>
      <>
        {t("Position.display.conflictingJob") as string}{" "}
        {hasConflictingPosition && <PositionReference id={head(conflictedPositions)} short />}
        {!hasConflictingPosition && conflictedAppointments && !isEmpty(conflictedAppointments) && (
          <>
            <Identifier entity="appointment" id={appointmentId} />{" "}
            {selectEntity(selectAppointment(appointmentId, "name"))}
          </>
        )}
      </>
    </IconText>
  );
});

const RequirementStatus = React.memo(() => (
  <IconText color="error" icon={mdiCancel}>
    {t("Position.display.requirementsNotMet") as string}
  </IconText>
));

const FullStatus = React.memo(() => (
  <IconText color="neutral" icon={mdiAccountCancel}>
    {t("Position.display.fullPosition") as string}
  </IconText>
));

function StatusRow({ position }: { position: Fk<Position> }): ReactElement | null {
  const { queryEntity: query } = useData(requestPositionList);
  const applicants = query(selectPosition(position, "applicants"));

  if (query(PositionQ.User.isAssigned(position)) && !query(PositionQ.User.isRequiredConfirmation(position)))
    return <SignUpStatus />;

  if (query(PositionQ.User.isAssigned(position)) && query(PositionQ.User.isRequiredConfirmation(position)))
    return <ConfirmationRequiredStatus />;

  if (!applicants && query(PositionQ.isLocked(position))) return <LockStatus />;

  if (query(PositionQ.User.hasRequirementsFailed(position))) return <RequirementStatus />;

  if (query(PositionQ.User.hasConflicting(position))) return <ConflictStatus positionId={position} />;

  if (!applicants && !query(PositionQ.User.isAssigned(position)) && query(PositionQ.isFull(position)))
    return <FullStatus />;

  return null;
}

function getColor(query: PositionQuery, position: Fk<Position>): Nullable<Color | IdentifierColorKey> {
  const applicants = query(selectPosition(position, "applicants"));

  if (query(PositionQ.User.isAssigned(position)) && !query(PositionQ.User.isRequiredConfirmation(position)))
    return "success";
  if (query(PositionQ.User.isAssigned(position)) && query(PositionQ.User.isRequiredConfirmation(position)))
    return "warning";

  if (!applicants && query(PositionQ.isLocked(position))) return "neutral";

  if (!applicants && query(PositionQ.User.hasRequirementsFailed(position))) return "error";

  if (query(PositionQ.User.hasConflicting(position))) return "error";

  if (!applicants && query(PositionQ.isFull(position))) return "neutral";

  if (query(PositionQ.User.isApplicant(position))) return "colorApplicant";

  if (!query(PositionQ.isCancelled(position))) return "primary";

  return null;
}

function isNotAvailable(query: PositionQuery, position: Fk<Position>): boolean {
  return (
    !query(PositionQ.User.isAssigned(position)) &&
    (query(PositionQ.isCancelled(position)) ||
      query(PositionQ.isFull(position)) ||
      query(PositionQ.isLocked(position)) ||
      false)
  );
}

interface PositionProps {
  position: Fk<Position>;
}

function PositionCardContent({ position }: PositionProps): ReactElement {
  const { queryEntity } = useData(requestPositionList);

  const data = rejectFalsy([
    <ApplicantRow position={position} />,
    <StatusRow position={position} />,
    <DateRow position={position} />,
    <LocationRow position={position} />,
    <TransportRow position={position} />,
    <ProfessionRow position={position} />,
  ]);

  return (
    <>
      <Box display="flex" mb={1}>
        <Text bold>{getName(queryEntity, position)}</Text>
      </Box>
      {data.map((item, i) => (
        <MuiGrid key={i} container spacing={1}>
          <MuiGrid item xs>
            {item}
          </MuiGrid>
        </MuiGrid>
      ))}
    </>
  );
}

/**
 * Return true if position is not featured and should show date chip
 */
const showChip = (query: PositionQuery, [current, previous]: [Fk<Position>, Fk<Position>]): boolean => {
  const isFeatured = query(selectPosition(current, "featured"));
  if (isFeatured) return false;
  if (isUndefined(previous)) return true;

  const [currTime, prevTime] = query(selectPosition([current, previous], "startTime"));

  return !isSameDay(currTime, prevTime);
};

/**
 * Return true if position is featured and should show featured chip
 */
const showFeaturedChip = (query: PositionQuery, [current, previous]: [Fk<Position>, Fk<Position>]): boolean => {
  const isFeatured = query(selectPosition(current, "featured"));
  if (!isFeatured) return false;
  if (!isFeatured || isUndefined(previous)) return true;

  const [currFeatured, prevFeatured] = query(selectPosition([current, previous], "featured"));

  return currFeatured !== prevFeatured;
};

const getAction = (query: PositionQuery, position: Fk<Position>) => routerLink(`/position/${position}`);

function ConnectedPositionsWrapper({ children, position }: PositionProps & ChildrenProps<ReactElement>): ReactElement {
  const { selectEntity, queryEntity } = useData(requestPositionList);

  const { searchParams } = useSearchParamsCustom<ParamsOf<typeof requestPositionList>>();

  if (!queryEntity(PositionQ.hasConnected(position))) {
    return children;
  }

  const connected = selectEntity(selectPosition(position, "connected")) ?? [];
  const count = length(connected);

  const connectedNotMatching = reduce(
    (acc, connectedPosition) => {
      const startTime = selectEntity(selectPosition(connectedPosition, "startTime"));
      const isMatching =
        (isNil(searchParams.from) || isBefore(searchParams.from, startTime)) &&
        (isNil(searchParams.to) || isBefore(startTime, searchParams.to));
      return isMatching ? acc : acc + 1;
    },
    0,
    connected
  );

  return (
    <CardUnderlay
      card={children}
      header={
        <Spacer separator={" "}>
          <Text bold small>
            {t("Position.display.connectedPosition", {
              count,
            })}
          </Text>
          {connectedNotMatching > 0 && (
            <Text color="error" small>
              {t("Position.display.connectedNotMatching", {
                count: connectedNotMatching,
              })}
            </Text>
          )}
        </Spacer>
      }
      icon={mdiLink}
    >
      <ListBase
        actionSelector={(id) => routerLink(`/position/${id}`)}
        contentSelector={(id) => (
          <Box width="100%">
            <PositionCardContent position={id} />
          </Box>
        )}
        data={connected}
        dense
        disablePadding
        dividerSelector={T}
        idSelector={identity}
      />
    </CardUnderlay>
  );
}

/**
 * todo: fix pagination positioning (collides with floating button)
 *
 * todo: consider hiding rows per page select in mobile view
 *  since there is not much screen space available
 */
export function PositionListMobile(): ReactElement {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionList);
  const { positionIds, featuredPositionIds } = selectResult();
  const [batch, setBatch] = useState<number>(10);

  const sortedPositionsIds = pipe<Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    pluck("id")
  )(selectEntity(selectPosition(positionIds)));

  const sortedFeaturedPositionsIds = pipe<Position[], Position[], number[]>(
    sortBy(prop("startTime")),
    pluck("id")
  )(selectEntity(selectPosition(featuredPositionIds)));

  useEffect(() => {
    if (batch < length(sortedPositionsIds)) {
      setBatch((cur) => cur + 10);
    }
  }, [batch]);

  return (
    <Grid spacing="outer" vertical>
      {sortedFeaturedPositionsIds.map((position, i) => [
        showFeaturedChip(selectEntity, [sortedFeaturedPositionsIds[i], sortedFeaturedPositionsIds[i - 1]]) && (
          <Center key={`chip-${position}`}>
            <FeaturedChip />
          </Center>
        ),
        <ConnectedPositionsWrapper key={position} position={position}>
          <ConditionalWrapper
            condition={isNotAvailable(queryEntity, position) && !selectEntity(selectPosition(position, "applicants"))}
            wrapper={(children) => <DisableStyle>{children}</DisableStyle>}
          >
            <Card action={getAction(queryEntity, position)} color={getColor(queryEntity, position) ?? undefined} small>
              <PositionCardContent position={position} />
            </Card>
          </ConditionalWrapper>
        </ConnectedPositionsWrapper>,
      ])}
      {sortedPositionsIds.slice(0, batch).map((position, i) => [
        showChip(selectEntity, [sortedPositionsIds[i], sortedPositionsIds[i - 1]]) && (
          <Center key={`chip-${position}`}>
            <DateChip color="primary" data-cy="shiftDates" date={selectEntity(selectPosition(position, "startTime"))} />
          </Center>
        ),
        <PositionCard position={position} />,
      ])}
    </Grid>
  );
}

function PositionCard({ position }: { position: Fk<Position> }): ReactElement {
  const { selectEntity, queryEntity } = useData(requestPositionList);

  return useMemo(
    () => (
      <ConnectedPositionsWrapper key={position} position={position}>
        <ConditionalWrapper
          condition={isNotAvailable(queryEntity, position) && !selectEntity(selectPosition(position, "applicants"))}
          wrapper={(children) => <DisableStyle>{children}</DisableStyle>}
        >
          <Card action={getAction(queryEntity, position)} color={getColor(queryEntity, position) ?? undefined} small>
            <PositionCardContent position={position} />
          </Card>
        </ConditionalWrapper>
      </ConnectedPositionsWrapper>
    ),
    [position, selectEntity]
  );
}
