/**
 * Staff clock in state in {@link Position position}.
 */
export enum ClockInState {
  /**
   * Staff clock in is not active for this position.
   */
  Off = 0,

  /**
   * Staff clock-in is active for this position, but it's not possible to clock in at this moment.
   */
  NotReady = 1,

  /**
   * Staff clock-in is active for this position and it's possible to clock in.
   */
  On = 2,

  /**
   * Staff clock-in is finished for this position.
   */
  Finished = 3,
}
