import { DataProvider, responseHandlerKey, useRequest, useStatus, useStatusUpdate } from "@sinch/core";
import { Id, News } from "@sinch/entity";
import { CheckboxInputBase } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Grid, HtmlContent, InformationDialog, useSnackbar } from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import { find, isEmpty, propEq } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { requestNewsConfirm } from "../api";

export const NewsDialog = ({ id }: { id: Id }): Nullable<ReactElement> => {
  const dispatch = useRequest();

  const { newsIds } = useStatus();
  const { dt } = useFormat();
  const [confirmed, toggleConfirmed] = useToggleState();
  const news = find<News>(propEq("id", id))(newsIds);

  if (!news) {
    return null;
  }

  const { body, confirmation, publishedDate, title } = news;

  return (
    <InformationDialog
      actions={{
        cancel: false,
        confirm: {
          action: () => dispatch(requestNewsConfirm(id)),
          disabled: confirmation && !confirmed,
          label: confirmation ? t("action.confirm") : t("ok"),
        },
      }}
      disableBackdropClick={confirmation}
      disableEscapeKeyDown={confirmation}
      hideClose={confirmation}
      onClose={() => dispatch(requestNewsConfirm(id))}
      subtitle={dt(publishedDate)}
      title={title}
    >
      <Grid spacing="inner" vertical>
        <HtmlContent html={body} />
        {confirmation && (
          <CheckboxInputBase
            label={
              t("News.agreeTermsLabel")
              // I have read the announcement and agree with given terms
            }
            name="confirm"
            onChange={toggleConfirmed}
            value={confirmed}
          />
        )}
      </Grid>
    </InformationDialog>
  );
};

export function NewsIntercept(): Nullable<ReactElement> {
  const { newsIds } = useStatus();
  const snackbar = useSnackbar();
  const { refresh: refreshStatus } = useStatusUpdate();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestNewsConfirm,
        () => {
          snackbar("success", t("News.confirmed"));
          refreshStatus();
        },
        () => {
          snackbar("error", t("News.confirmFailed"));
          refreshStatus();
        }
      ),
    [snackbar, refreshStatus]
  );

  if (isEmpty(newsIds)) return null;

  const { id } = newsIds[0];

  return (
    <DataProvider handler={responseHandler}>
      <NewsDialog id={id} />
    </DataProvider>
  );
}
