import { useBusinessRules, useData } from "@sinch/core";
import { ClosingProgress, PositionAttendance, selectPosition, selectWorker, WorkerRole } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Color, IconId, Nullable } from "@sinch/types";
import { ActionItemHorizontal, IdentifierColorKey } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../../api";
import { useClosingStatusProps, useUpdatePresence } from "../../hooks";

interface UseTrailingActionProps {
  getTrailingElement: (item: PositionAttendance) => Nullable<ReactElement>;
  getTrailingEvent: (item: PositionAttendance) => void;
}

export function useTrailingAction(): UseTrailingActionProps {
  const { selectEntity } = useData(requestPresenceView);
  const { shiftClosingWorkBreaks } = useBusinessRules();
  const { getProps } = useClosingStatusProps();
  const { setPending, setAbsent, setBreak, setAttendanceIds } = useUpdatePresence([]);

  const handleSwipeTrailing = (item: PositionAttendance): void => {
    const { id, position, progressStatus } = item;
    setAttendanceIds(id);
    const { role } = selectEntity(selectPosition(position));

    const backup = role === WorkerRole.Backup;

    switch (progressStatus) {
      case ClosingProgress.Pending:
        setAbsent();
        break;
      case ClosingProgress.Present:
        if (!shiftClosingWorkBreaks || backup) {
          return;
        }
        setBreak(new Date());
        break;
      case ClosingProgress.Absent:
        setPending();
        break;
      default:
    }
  };

  const getTrailingAction = (item: PositionAttendance): Nullable<ReactElement> => {
    const { worker, progressStatus, position } = item;
    const { name } = selectEntity(selectWorker(worker));
    const { role } = selectEntity(selectPosition(position));

    const backup = role === WorkerRole.Backup;

    let actionItem: { color: Color | IdentifierColorKey; icon: IconId; label: string };
    switch (progressStatus) {
      case ClosingProgress.Pending:
        actionItem = {
          ...getProps(ClosingProgress.Absent),
          label: t("Shift.closing.noShow"),
        };
        break;
      case ClosingProgress.Present:
        if (!shiftClosingWorkBreaks || backup) {
          return null;
        }
        actionItem = {
          ...getProps(ClosingProgress.Break),
          label: t("Shift.closing.startBreak"),
        };
        break;
      case ClosingProgress.Absent:
        actionItem = getProps(ClosingProgress.Pending);
        break;
      default:
        return null;
    }

    return <ActionItemHorizontal {...actionItem} label={name} subtitle={actionItem.label} />;
  };
  return { getTrailingElement: getTrailingAction, getTrailingEvent: handleSwipeTrailing };
}
