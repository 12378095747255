import { Box, Divider } from "@material-ui/core";
import { useData } from "@sinch/core";
import { t } from "@sinch/intl";
import { Header, SpacingBox, Text, useSpacing } from "@sinch/ui";
import { useLocalStorage } from "@sinch/utils";
import { isEmpty, isNil, length } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../../components";
import { requestDashboardAttendanceList } from "../api";
import { PositionCardField } from "../components";
import { FeedbackCondensedList } from "../FeedbackCondensedList";
import { FeedbackPositions } from "../FeedbackPositions";
import { SquareCard } from "./components";

export function MobileFeedbackTab(): ReactElement {
  const [, , data] = useSpacing();
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { feedbackPositionIds } = selectResult();

  if (isNil(feedbackPositionIds) || isEmpty(feedbackPositionIds)) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="24em"
        justifyContent="center"
        textAlign="center"
      >
        <Header color="neutral">{t("Dashboard.noFeedbacks")}</Header>
      </Box>
    );
  }

  return (
    <Box pt={data}>
      <Feedback />
    </Box>
  );
}

const FeedbackList = React.memo(({ view, feedbackListViewSelectHeader }) => {
  const [, , data] = useSpacing();
  return (
    <>
      {view === PositionView.Condensed && (
        <SquareCard>
          {feedbackListViewSelectHeader}
          <SpacingBox mx={-data}>
            <Divider variant="fullWidth" />
          </SpacingBox>
          <FeedbackCondensedList />
        </SquareCard>
      )}
      {view === PositionView.Standard && (
        <Box px={data}>
          {feedbackListViewSelectHeader}
          <FeedbackPositions />
        </Box>
      )}
    </>
  );
});

function Feedback(): ReactElement | null {
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { feedbackPositionIds } = selectResult();
  const [view, setView] = useLocalStorage<PositionView>("listView", PositionView.Standard);

  const fields: PositionCardField[] = ["date", "location", "transport", "time", "contact"];

  fields.unshift("status");

  const feedbackListViewSelectHeader = useMemo(
    () => (
      <PositionListViewSelectHeader
        count={length(feedbackPositionIds || [])}
        countLabel={(count) => (
          <Text separator={" "}>
            <Header component="span" level={2}>
              {t("Feedback.title")}
            </Header>
            <Text color="neutral" small>
              {t("Position.display.positionCount", { count })}
            </Text>
          </Text>
        )}
        onViewChange={setView}
      />
    ),
    [feedbackPositionIds, setView]
  );

  if (isNil(feedbackPositionIds) || isEmpty(feedbackPositionIds)) {
    return null;
  }

  return <FeedbackList feedbackListViewSelectHeader={feedbackListViewSelectHeader} view={view} />;
}
