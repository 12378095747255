import { Transform } from "@sinch/types";
import { intersperse } from "ramda";
import React, { Children, ReactElement, ReactNode } from "react";

export interface SpacerProps {
  children: ReactNode;

  dropEmpty?: boolean;

  separator?: string;
}

export function Spacer({
  children,
  dropEmpty = false,
  separator = " ",
}: SpacerProps): ReactElement {
  /* @ts-expect-error */
  const separate: Transform<ReactNode> = intersperse(separator);

  return <>{separate(dropEmpty ? Children.toArray(children) : children)}</>;
}
