import MuiGrid from "@material-ui/core/Grid";
import React, { ReactElement, ReactNode } from "react";
import { Action } from "../actions";
import { Button } from "../Button";

interface ButtonBoxIconProps {
  action?: Action;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  icon: string | ReactNode;
}

/**
 * Button with title, subtitle and icon on a right side of button, used for status stats
 */
export function ButtonBoxIcon({ action, title, subtitle, icon }: ButtonBoxIconProps): ReactElement {
  return (
    <Button action={action} disableUppercase large variant="outlined">
      <MuiGrid container wrap={"nowrap"}>
        <MuiGrid container direction="column" item xs>
          <MuiGrid item>{title}</MuiGrid>
          {subtitle && <MuiGrid item>{subtitle}</MuiGrid>}
        </MuiGrid>
        <MuiGrid item xs="auto">
          {icon}
        </MuiGrid>
      </MuiGrid>
    </Button>
  );
}
