import { Box } from "@material-ui/core";
import { mdiAccountOutline, mdiBagPersonal, mdiBell, mdiFileDocument, mdiLock, mdiSleep } from "@mdi/js";

import { DataProvider, useCurrentUser, useData, usePathSection, useRefresh } from "@sinch/core";
import { ErrorHandledRoutes } from "@sinch/core/router";
import { selectSnippet, Snippet } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  ButtonCard,
  Divider,
  ExpandableCard,
  GridLayout,
  Header,
  routerLink,
  Text,
  TwoColumnLayout,
  useMobileLayout,
  useSpacing,
} from "@sinch/ui";
import { isPast } from "date-fns";
import { filter, isEmpty, isNil, propEq } from "ramda";
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Notice } from "../../Dashboard/components";
import { requestProfileStatus } from "./api";
import { BadgeList, BadgeListContainer } from "./BadgeList";
import { ContractListContainer, MobileContractList } from "./ContractList";
import { EndHibernateButton, HibernateContainer } from "./Hibernate";
import { LanguageSelect } from "./LanguageSelect";
import { PersonalAttributes, PersonalContainer } from "./PersonalAttributes";
import { ProfileAttributeListContainer, ProfileAttributes } from "./ProfileAttributes";
import { ProfileCard } from "./ProfileCard";
import { ProfileContent } from "./ProfileContent";
import { useProfileContext } from "./ProfileContext";
import { ProfileStatus } from "./ProfileStatus";
import { MobileRating, RatingListContainer } from "./Rating";
import { ReferralListContainer } from "./ReferralList";

function BottomButtonsMenu(): ReactElement {
  const {
    restrictions: { hibernated },
  } = useCurrentUser();
  const isHibernated = !isNil(hibernated) && hibernated !== false;
  return (
    <>
      <GridLayout.Item>
        <Button
          action={routerLink(`#/passwordUpdate`)}
          data-cy="change-password-btn"
          icon={mdiLock}
          large
          variant="outlined"
        >
          {t("Profile.action.changePassword")}
        </Button>
      </GridLayout.Item>
      <GridLayout.Item>
        <Button action={routerLink("notificationSettings")} icon={mdiBell} large variant="outlined">
          {t("NotificationSettings.title")}
        </Button>
      </GridLayout.Item>
      <GridLayout.Item>
        {isHibernated ? (
          <HibernateContainer>
            <EndHibernateButton />
          </HibernateContainer>
        ) : (
          <Button action={routerLink("#/hibernate")} icon={mdiSleep} large variant="outlined">
            {t("Profile.hibernate")}
          </Button>
        )}
      </GridLayout.Item>
      <GridLayout.Item>
        <LanguageSelect />
      </GridLayout.Item>
    </>
  );
}

function MobileProfile(): ReactElement {
  const navigate = useNavigate();
  const section = usePathSection();
  const [, inner] = useSpacing();
  const [open, setOpen] = useState(section);
  const { selectResult, selectEntity } = useData(requestProfileStatus);
  const { attributeExpiration, availableProfileAttributesCount } = selectResult();
  const snippets = selectEntity(selectSnippet({}));

  useEffect(() => {
    if (open !== section) {
      setOpen(section);
    }
  }, [section]);

  return (
    <ErrorHandledRoutes>
      <Route
        element={
          <GridLayout spacing="outer">
            {filter<Snippet>(propEq("name", "profile_view"), snippets).map(({ color, body, id, style }, idx) => (
              <GridLayout.Item>
                <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
              </GridLayout.Item>
            ))}
            <GridLayout.Item>
              <ProfileCard />
            </GridLayout.Item>
            <ProfileStatus />
            <GridLayout.Item>
              <ExpandableCard
                badge={
                  isNil(attributeExpiration?.personalAttribute) || isEmpty(attributeExpiration?.personalAttribute)
                    ? undefined
                    : {
                        // @ts-ignore
                        color: isPast(attributeExpiration.personalAttribute.expiration) ? "error" : "warning",
                        badgeContent: <Text bold>!</Text>,
                      }
                }
                icon={mdiAccountOutline}
                onClick={() => {
                  setOpen(open === "personal" ? null : "personal");
                  navigate("personal");
                }}
                open={section === "personal" && open === "personal"}
                title={t("Profile.display.personalAttributes")}
              >
                <PersonalContainer>
                  <Box m={inner}>
                    <PersonalAttributes edit="personal" />
                  </Box>
                </PersonalContainer>
              </ExpandableCard>
            </GridLayout.Item>
            {availableProfileAttributesCount > 0 && (
              <GridLayout.Item>
                <ExpandableCard
                  badge={
                    isNil(attributeExpiration?.profileAttribute) || isEmpty(attributeExpiration?.profileAttribute)
                      ? undefined
                      : {
                          // @ts-ignore
                          color: isPast(attributeExpiration.profileAttribute.expiration) ? "error" : "warning",
                          badgeContent: <Text bold>!</Text>,
                        }
                  }
                  icon={mdiBagPersonal}
                  onClick={() => {
                    setOpen(open === "attributes" ? null : "attributes");
                    navigate("attributes");
                  }}
                  open={section === "attributes" && open === "attributes"}
                  title={t("Profile.display.profileAttributes")}
                >
                  <ProfileAttributeListContainer>
                    <Box m={inner}>
                      <ProfileAttributes edit="attributes" />
                    </Box>
                  </ProfileAttributeListContainer>
                </ExpandableCard>
              </GridLayout.Item>
            )}
            <GridLayout.Item>
              <ButtonCard action={routerLink("contracts")} alignLeft icon={mdiFileDocument}>
                <Header level={2}>{t("Contract.titlePlural")}</Header>
              </ButtonCard>
            </GridLayout.Item>
            <ReferralListContainer />
            <GridLayout.Item>
              <Divider dark />
            </GridLayout.Item>
            <BottomButtonsMenu />
          </GridLayout>
        }
        path="*"
      />
      <Route
        element={
          <ContractListContainer>
            <MobileContractList />
          </ContractListContainer>
        }
        path="/contracts/*"
      />
      <Route
        element={
          <RatingListContainer>
            <MobileRating />
          </RatingListContainer>
        }
        path="/rating/*"
      />
      <Route
        element={
          <BadgeListContainer>
            <BadgeList />
          </BadgeListContainer>
        }
        path="/badges/*"
      />
    </ErrorHandledRoutes>
  );
}

function DesktopProfile(): ReactElement {
  return (
    <TwoColumnLayout>
      <GridLayout spacing="data">
        <GridLayout.Item>
          <ProfileCard />
        </GridLayout.Item>
        <ReferralListContainer />
        <GridLayout.Item>
          <Divider dark />
        </GridLayout.Item>
        <BottomButtonsMenu />
      </GridLayout>
      <ProfileContent />
    </TwoColumnLayout>
  );
}

export function Profile(): ReactElement {
  const mobile = useMobileLayout();
  return <ProfileStatusContainer>{mobile ? <MobileProfile /> : <DesktopProfile />}</ProfileStatusContainer>;
}

export type ProfileStatusContainerParams = {
  children: ReactNode;
};

export function ProfileStatusContainer({ children }: ProfileStatusContainerParams): ReactElement {
  const request = useMemo(() => requestProfileStatus(), []);
  const { refresh, refreshToken } = useRefresh();
  const { refreshToken: refreshProfileToken } = useProfileContext();

  useEffect(() => refresh(), [refreshProfileToken]);

  return (
    <DataProvider refresh={refreshToken} refresher={refresh} request={request}>
      {children}
    </DataProvider>
  );
}
