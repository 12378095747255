import { defineRequest } from "@sinch/core";
import {
  ApplicantAttendance,
  Appointment,
  CalendarEntry,
  CalendarEntryContainer,
  Fk,
  Position,
  WorkerContainer,
} from "@sinch/entity";

export interface CalendarParams {
  from?: Date;

  to?: Date;
}

export interface CalendarResponse {
  calendarEntryIds: Fk<CalendarEntry>[];
  positionIds: Fk<Position>[];
  appointmentIds: Fk<Appointment>[];
  applicantSlotsIds: Fk<Position>[];
  needApplicantConfirmationSlotIds: Record<FK<ApplicantAttendance>, Fk<Position>[]>;
  exportUrl: string;
}

/**
 * todo: update key to `worker/Calendars/View`
 *
 * todo: add date params to support filtering calendar entries
 */
export const requestCalendar = defineRequest("Calendars/calendar")
  .withParams<CalendarParams>()
  .withResponse<CalendarResponse>()
  .withResponseEntity<CalendarEntryContainer & WorkerContainer>()
  .withCreator((search: CalendarParams) => ({
    params: search,
  }));
