import { DataProvider } from "@sinch/core";
import React, { PropsWithChildren, ReactElement, useMemo } from "react";
import { requestWalletStatus } from "./api";
import { useWalletContext } from "./WalletContext";

export function WalletStatusContainer({ children }: PropsWithChildren<unknown>): ReactElement {
  const request = useMemo(() => requestWalletStatus(), []);

  const { refresh, refreshToken } = useWalletContext();

  return (
    <DataProvider refresh={refreshToken} refresher={refresh} request={request}>
      {children}
    </DataProvider>
  );
}
