import { t } from "@sinch/intl";
import { InformationDialog } from "@sinch/ui";
import React, { ReactElement } from "react";
import { HibernateContainer } from "./HibernateContainer";
import { HibernateForm } from "./HibernateForm";

export function Hibernate(): ReactElement {
  return (
    <HibernateContainer>
      <InformationDialog title={t("Profile.hibernateProfile")} width="xs">
        <HibernateForm />
      </InformationDialog>
    </HibernateContainer>
  );
}
