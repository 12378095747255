/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { AccordionProps, Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useData, useInstanceSettings } from "@sinch/core";
import { PersonalAttributeCitizenship, selectPersonalAttribute, selectSnippet, Snippet } from "@sinch/entity";
import { Form } from "@sinch/forms";
import { useFormContext } from "@sinch/forms/Form/FormContext";
import { t } from "@sinch/intl";
import { Button, LoadingOverlay, routerLink, useMobileLayout, useTitleBar } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import {
  assoc,
  assocPath,
  dissocPath,
  equals,
  filter,
  findLast,
  flatten,
  hasPath,
  includes,
  isEmpty,
  map,
  mapObjIndexed,
  mergeAll,
  pathSatisfies,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  unnest,
  values,
} from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useState } from "react";
import { useLocation } from "react-router";
import { Notice } from "../../../Dashboard/components";
import { ProfileAttributeData, requestProfilePersonal, requestProfilePersonalUpdate } from "../api";
import { useInvalidFormWarning } from "../hooks/invalidForm";
import { parseInitialProfileValue, StaticAttributeValue } from "../utils";
import { EditAttributes } from "./EditAttributes";
import { EditBank } from "./EditBank";
import { EditBasicProfile } from "./EditBasicProfile";
import { EditContacts } from "./EditContacts";
import { EditPreferredResidence } from "./EditPreferredResidence";
import { EditProfession } from "./EditProfession";
import { EditResidence } from "./EditResidence";

const useAccordionExpanded = (initGroup: string): [string, (group: string) => AccordionProps["onChange"]] => {
  const [expanded, setExpanded] = useState(initGroup);
  const handleExpansionChange = (group: string): AccordionProps["onChange"] => (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      setExpanded(group);
    }
  };
  return [expanded, handleExpansionChange];
};

function initialProfessions() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectResult } = useData(requestProfilePersonal);

  const { professions } = selectResult();
  return reduce((acc, { id, value }: StaticAttributeValue) => assoc(id, value, acc), {}, professions);
}

export function EditPersonalAttributes(): ReactElement {
  const { selectResult, selectEntity, selectMeta } = useData(requestProfilePersonal);
  const { personalAttributes, systemAttributes } = selectResult();
  const { country } = useInstanceSettings();
  const { countriesWithRegions } = selectMeta();
  const snippets = selectEntity(selectSnippet({}));
  const isMobile = useMobileLayout();

  const allAttributes = selectEntity(selectPersonalAttribute({}));
  const systemAttrsMapping = {
    "User.name": [["firstName"]],
    "User.surname": [["lastName"]],
    "User.birthdate": [["birthDate"]],
    "User.phone": [["phone"]],
    "Workerinfo.sex": [["sex"]],
    "Workerinfo.citizenship": [["citizenship"]],
    "Workerinfo.permanent_resident": [["isPermanentResident"]],
    "Workerinfo.permanent_duration_from": [["permanentResident", "from"]],
    "Workerinfo.permanent_duration_to": [["permanentResident", "from"]],
    "Workerinfo.bank_account_number": [["bankAccountFormat"], ["bankAccountHolderName"], ["bankAccountSegments"]],
    "Workerinfo.address": [["permanentAddress", "street"]],
    "Workerinfo.city": [["permanentAddress", "city"]],
    "Workerinfo.region": [["permanentAddress", "region"]],
    "Workerinfo.zip": [["permanentAddress", "zip"]],
    "Workerinfo.country": [["permanentAddress", "country"]],
    "Workerinfo.address_local": [["correspondenceAddress", "street"]],
    "Workerinfo.city_local": [["correspondenceAddress", "city"]],
    "Workerinfo.region_local": [["correspondenceAddress", "region"]],
    "Workerinfo.zip_local": [["correspondenceAddress", "zip"]],
    "Workerinfo.country_local": [["correspondenceAddress", "country"]],
    "WorkerPreferredAddress.preferred_address": [["preferredPlaces"]],
  };

  const initial = {
    ...selectResult(),
    dynamicAttributes: reduce(
      (acc, { id, type }) => {
        const savedValue = findLast(propEq("id", id))(personalAttributes);
        return assoc(
          id as string,
          { id, value: parseInitialProfileValue(undefined, type), expiration: null, ...savedValue },
          acc
        );
      },
      {},
      allAttributes
    ),
    professions: { ...initialProfessions() },
  };

  const filteredInitial = pipe(
    mapObjIndexed((paths, key) => {
      if (!findLast(propEq("name", key), systemAttributes)) {
        return paths;
      }
      return null;
    }),
    rejectFalsy,
    values,
    unnest,
    reduce((acc, path: string[]) => {
      if (hasPath(path, acc)) {
        return dissocPath(path, acc);
      }
      return acc;
    }, initial)
  )(systemAttrsMapping);

  return (
    <>
      {filter<Snippet>(propEq("name", "personal_attributes_edit"), snippets).map(({ color, body, id, style }, idx) => (
        <Box pb={1} pt={isMobile ? 0 : 1}>
          <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
        </Box>
      ))}
      <Form
        blockRedirect
        initialValues={filteredInitial}
        submitRequest={(attributes) =>
          requestProfilePersonalUpdate({
            attributes,
          })
        }
        validate={({ dynamicAttributes, citizenship, ...props }: { dynamicAttributes: ProfileAttributeData[] }) => {
          const filteredSystemAttributes = reject((attr) => {
            if (
              (attr.name === "Workerinfo.region" &&
                (!props?.permanentAddress?.country ||
                  !includes(props?.permanentAddress?.country, countriesWithRegions))) ||
              (attr.name === "Workerinfo.region_local" &&
                (!props?.correspondenceAddress?.country ||
                  !includes(props?.correspondenceAddress?.country, countriesWithRegions)))
            ) {
              return true;
            }
            if (
              (attr.name === "Workerinfo.permanent_resident" ||
                attr.name === "Workerinfo.permanent_duration_from" ||
                attr.name === "Workerinfo.permanent_duration_to") &&
              !props?.isPermanentResident
            ) {
              return true;
            }
            return false;
          }, systemAttributes);

          const systemValidation = pipe(
            mapObjIndexed((paths, key) => {
              const finded = findLast(propEq("name", key), filteredSystemAttributes);
              if (finded && !!prop("mandatory", finded)) {
                return map(
                  (path) =>
                    pathSatisfies(isNilOrEmpty, path, { citizenship, ...props })
                      ? assocPath(path, t("mandatoryField"), {})
                      : null,
                  paths
                );
              }
              return null;
            }),
            values,
            flatten,
            rejectFalsy,
            mergeAll
          )(systemAttrsMapping);

          const result = rejectFalsy(
            map(({ value, id, expiration }) => {
              const { mandatory, expirable, citizenship: attributeCitizenship } = selectEntity(
                selectPersonalAttribute(id)
              );

              const isHidden =
                (equals(attributeCitizenship, PersonalAttributeCitizenship.Home) && country !== citizenship) ||
                (equals(attributeCitizenship, PersonalAttributeCitizenship.Other) && country === citizenship);

              if (isHidden) {
                return null;
              }
              if (mandatory && isNilOrEmpty(value)) {
                return { value: t("mandatoryField") };
              }
              if (expirable && !isNilOrEmpty(value) && isNilOrEmpty(expiration)) {
                return { expiration: t("mandatoryField") };
              }
              return null;
            }, dynamicAttributes)
          );
          if (isEmpty(systemValidation) && isEmpty(result)) {
            return null;
          }
          const invalid = rejectFalsy({ ...systemValidation, dynamicAttributes: result });
          return invalid;
        }}
        validateOnMount
      >
        <EditPersonalAttributesFormContent />
      </Form>
    </>
  );
}

function EditPersonalAttributesFormContent(): ReactElement {
  const mobile = useMobileLayout();
  const { selectEntity, selectResult } = useData(requestProfilePersonal);
  const { personalAttributes } = selectResult();
  const attributes = selectEntity(selectPersonalAttribute({}));
  useInvalidFormWarning();

  const { hash } = useLocation();
  const hashString = hash.substr(1);
  const [expanded, handleExpansionChange] = useAccordionExpanded(isEmpty(hashString) ? "basic" : hashString);

  const {
    submit,
    status: { submitting },
  } = useFormContext();
  useTitleBar(
    mobile
      ? {
          container: "profile:editPersonalAttributes",
          submitHandler: submit,
          showBack: true,
          confirmExit: true,
          locationTitle: t("Profile.editPersonal"),
        }
      : { container: "profile:editPersonalAttributes" }
  );

  return (
    <>
      {submitting && <LoadingOverlay />}
      <EditBasicProfile expanded={expanded === "basic"} onExpansionChange={handleExpansionChange("basic")} />
      <EditContacts expanded={expanded === "contact"} onExpansionChange={handleExpansionChange("contact")} />
      <EditProfession expanded={expanded === "profession"} onExpansionChange={handleExpansionChange("profession")} />
      <EditResidence expanded={expanded === "residence"} onExpansionChange={handleExpansionChange("residence")} />
      <EditPreferredResidence
        expanded={expanded === "preferredResidence"}
        onExpansionChange={handleExpansionChange("preferredResidence")}
      />
      <EditBank expanded={expanded === "bank"} onExpansionChange={handleExpansionChange("bank")} />
      <EditAttributes
        attributes={attributes}
        attributesValues={personalAttributes}
        expanded={expanded}
        handleExpansionChange={handleExpansionChange}
      />
      {!mobile && (
        <Box my={1}>
          <MuiGrid container spacing={1}>
            <MuiGrid item md={3} xs={6}>
              <Button action={() => submit()} color="secondary" type="button">
                {t("action.save")}
              </Button>
            </MuiGrid>
            <MuiGrid item md={3} xs={6}>
              <Button action={routerLink("/profile")} variant="outlined">
                {t("action.cancel")}
              </Button>
            </MuiGrid>
          </MuiGrid>
        </Box>
      )}
    </>
  );
}
