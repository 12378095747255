import { map, pick, reduce } from "ramda";
import { RequestCreator, RequestResponse } from "../contract";
import { responseHandler, ResponseHandler } from "./ResponseHandler";

function handleStep<TCreator extends RequestCreator>(
  reqres: RequestResponse<TCreator> | void,
  handler: ResponseHandler<TCreator>
): RequestResponse<TCreator> | void {
  return reqres ? handler(reqres) : undefined;
}

const seqInfo = map(pick(["type", "params"]));

export function responseHandlerSequence<TCreator extends RequestCreator>(
  handlers: ResponseHandler<TCreator>[]
): ResponseHandler<TCreator> {
  return responseHandler("sequence", seqInfo(handlers), (reqres) =>
    reduce<ResponseHandler<TCreator>, RequestResponse<TCreator> | void>(
      handleStep,
      reqres,
      handlers
    )
  );
}
