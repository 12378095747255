import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import MuiTooltip from "@material-ui/core/Tooltip";
import { Color, IconId } from "@sinch/types";
import clsx from "clsx";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { createPaletteStyles, getEntityColors, IdentifierColorKey } from "../paletteStyles";

interface TooltipProps {
  children: ReactElement;

  title?: ReactNode;
}

function Tooltip({ children, title }: TooltipProps): ReactElement {
  return (
    <MuiTooltip enterTouchDelay={100} placement="right" title={title ?? ""}>
      {children}
    </MuiTooltip>
  );
}

const paletteStyles = createPaletteStyles(({ main }) => ({
  color: main,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    fontSize: theme.typography.pxToRem(24),
  },
  large: {
    fontSize: theme.typography.pxToRem(32),
  },
  small: {
    fontSize: theme.typography.pxToRem(20),
  },
  ...paletteStyles(theme),
  ...getEntityColors(theme),
}));

export type IconSize = "default" | "large" | "small";

type IconData = { icon: IconId; path?: never } | { icon?: never; path?: string };

export interface IconProps {
  color?: Color | IdentifierColorKey;

  size?: IconSize;

  tooltip?: ReactNode;

  className?: string;
  style?: React.CSSProperties;
}

export const Icon = React.memo(
  ({ color, icon, path, size, tooltip, className, ...props }: IconProps & IconData): ReactElement => {
    const styles = useStyles();

    const svgPath = useMemo(() => <path d={path ?? icon} />, [icon, path]);

    const iconEl = (
      <SvgIcon
        className={`${className} ${clsx(styles.root, color && styles[color], {
          [styles.large]: size === "large",
          [styles.small]: size === "small",
        })}`}
        {...props}
      >
        {svgPath}
      </SvgIcon>
    );

    if (tooltip) {
      return <Tooltip title={tooltip}>{iconEl}</Tooltip>;
    }

    return iconEl;
  }
);
