import { mdiSend } from "@mdi/js";
import { DataProvider, useBusinessRules, useData, useHashNavigate } from "@sinch/core";
import { t } from "@sinch/intl";
import { Button, Card, Divider, ExpandableArea, GridLayout, MuiDataTable, Text } from "@sinch/ui";
import { isArray } from "@sinch/utils";
import { length } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { Nullable } from "../../../types";
import { ReferralInfo, requestProfileReferralList, requestProfileStatus } from "./api";

export function ReferralList(): ReactElement {
  const hashNav = useHashNavigate();

  const { selectResult } = useData(requestProfileReferralList);
  const { referrals } = selectResult();

  const ReferralDataTable = MuiDataTable.withConfig({
    worker: {
      selector: ({ name }: ReferralInfo) => <Text bold>{name}</Text>,
    },
    status: {
      selector: ({ status }: ReferralInfo) => (
        <Text>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isArray(status)
            ? t("Referral.attributionProgress", {
                jobs: status[0],
                totalJobs: status[1],
              })
            : status
            ? t("Referral.attributed")
            : t("Referral.notAttributed")}
        </Text>
      ),
    },
  });

  const hasInvites = length(referrals) > 0;

  // todo extract invite button
  return (
    <>
      <GridLayout.Item>
        <Divider dark />
      </GridLayout.Item>
      {hasInvites ? (
        <GridLayout.Item>
          <Card>
            <Button action={() => hashNav("/referral")} color="info" icon={mdiSend} large variant="contained">
              {t("Referral.action.invite")}
            </Button>
            <ExpandableArea title={`${t("Referral.viewInvites")} (${length(referrals)})`}>
              <ReferralDataTable columns={["worker", "status"]} data={referrals} />
            </ExpandableArea>
          </Card>
        </GridLayout.Item>
      ) : (
        <GridLayout.Item>
          <Button action={() => hashNav("/referral")} color="info" icon={mdiSend} large variant="contained">
            {t("Referral.action.invite")}
          </Button>
        </GridLayout.Item>
      )}
    </>
  );
}

export function ReferralListContainer(): Nullable<ReactElement> {
  const request = useMemo(() => requestProfileReferralList(), []);
  const { referralProgram } = useBusinessRules();

  if (!referralProgram) {
    return null;
  }

  return (
    <DataProvider request={request}>
      <ReferralList />
    </DataProvider>
  );
}
