import { Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { selectPosition, selectWalletTransaction } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Divider, Header, Identifier, Link, RowsGrid, Text, useSpacing } from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { PositionReference } from "../../../Position/Detail/PositionReference";
import { requestWalletView } from "../api";

function WalletViewRow({ label, children }: { label: string } & ChildrenProps): ReactElement {
  return (
    <MuiGrid container justify="space-between">
      <MuiGrid item>{label}</MuiGrid>
      <MuiGrid item>{children}</MuiGrid>
    </MuiGrid>
  );
}

function DimmedZero({ val, children }: { val: number } & ChildrenProps): ReactElement {
  return <Text color={val === 0.0 ? "neutral" : undefined}>{children}</Text>;
}

export function WalletView(): ReactElement {
  const { curr, dt, num } = useFormat();
  const [, , data] = useSpacing();
  const navigate = useNavigate();

  const { selectResult, selectEntity } = useData(requestWalletView);
  const { records, total, wallet } = selectResult();

  const { position, accountingDate } = selectEntity(selectWalletTransaction(wallet));
  const getPositionName = () => {
    if (position) {
      const { shift } = selectEntity(selectPosition(position));
      return (
        <RowsGrid>
          {t("Shift.title")}
          <Text bold>
            <Identifier entity="shift" id={shift} />
            {" - "}
            <PositionReference id={position} name noId title />
          </Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={() => navigate(`/position/${position}`)}>{t("goToShiftDetail")}</Link>
        </RowsGrid>
      );
    }
    return null;
  };

  return (
    <RowsGrid spacing={data}>
      {position && getPositionName()}
      <RowsGrid>
        {t("Wallet.display.accountingDate")}
        <Text bold>{dt(accountingDate)}</Text>
      </RowsGrid>
      <Divider />
      {records.map(({ amount, total: rowTotal, name: recordName, unit, wageFix, wageHour, wagePercentage }) => (
        <RowsGrid spacing={data}>
          <Header level={3}>{recordName}</Header>
          <RowsGrid>
            {wageHour !== 0.0 && (
              <WalletViewRow label={`${t("Wallet.hourWage")}:`}>
                <DimmedZero val={wageHour}>{curr(wageHour)}</DimmedZero>
              </WalletViewRow>
            )}
            {wageFix !== 0.0 && (
              <WalletViewRow label={`${t("Wallet.fixWage")}:`}>
                <DimmedZero val={wageFix}>{curr(wageFix)}</DimmedZero>
              </WalletViewRow>
            )}
            {wagePercentage !== 0.0 && (
              <WalletViewRow label={`${t("Wallet.percentageRate")}:`}>
                <Text>{`${num.decShort(wagePercentage * 100)}%`}</Text>
              </WalletViewRow>
            )}
            {wageHour !== 0.0 && (
              <WalletViewRow label={`${t("Wallet.units")}:`}>
                <DimmedZero val={amount}>{`${amount} ${unit}`}</DimmedZero>
              </WalletViewRow>
            )}
            <WalletViewRow label={`${t("Wallet.total")}:`}>
              <Text bold>{curr(rowTotal)}</Text>
            </WalletViewRow>
          </RowsGrid>
          <Divider />
        </RowsGrid>
      ))}
      <Header level={2}>
        <WalletViewRow label={t("Wallet.total")}>{curr(total)}</WalletViewRow>
      </Header>
    </RowsGrid>
  );
}
