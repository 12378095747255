import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { mdiCoffeeOffOutline, mdiCoffeeOutline } from "@mdi/js";

/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { useBusinessRules, useData } from "@sinch/core";
import {
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  WorkerRole,
} from "@sinch/entity";
import { SelectDateTimeInput, SelectInput, TextInput, useFormUpdate, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Avatar, BorderColorListItem, IconButton, Text } from "@sinch/ui";
import { isAfter } from "date-fns/fp";
import { FieldArray, useFormikContext } from "formik";
import { last, values } from "ramda";
import React, { ReactElement, useEffect, useMemo } from "react";
import { AttendanceClosingFormState, AttendanceClosingPositionState, requestShiftAttendanceView } from "../api";
import { PresenceOptionsLate, PresenceOptionsOnTime, WorkerRoleOptions } from "../options";
import { getNextWorkBreak } from "../WorkerPositionAttendance";

interface WorkerPositionAttendanceProps extends Omit<AttendanceClosingPositionState, "startTime" | "endTime" | "note"> {
  attendance: Fk<PositionAttendance>;
  workerStartTime: Date;
  positionStartTime: Date;
}

export function WorkerPositionAttendanceListItem({ attendance }: WorkerPositionAttendanceProps): ReactElement {
  const {
    positionAttendance: {
      [attendance]: { role, presence, startTime: workerStartTime, positionAttendanceBreak },
    },
  } = useFormValues<AttendanceClosingFormState>();

  const { selectEntity } = useData(requestShiftAttendanceView);
  const { position } = selectEntity(selectPositionAttendance(attendance));
  const { startTime: positionStartTime } = selectEntity(selectPosition(position));

  const setValues = useFormUpdate<AttendanceClosingFormState>();

  const isLate = isAfter(positionStartTime, workerStartTime);
  const presenceOptions = isLate ? PresenceOptionsLate() : PresenceOptionsOnTime();
  const { color } = presenceOptions[presence ? 1 : 0];

  return useMemo(
    // bad practice,
    () => (
      <BorderColorListItem color={color}>
        <RowAttendanceContent
          attendance={attendance}
          positionAttendanceBreak={positionAttendanceBreak}
          positionStartTime={positionStartTime}
          presence={presence}
          role={role}
          setValues={setValues}
          workerStartTime={workerStartTime}
        />
      </BorderColorListItem>
    ),
    [role, presence, workerStartTime, positionAttendanceBreak]
  );
}

export function RowAttendanceContent({
  attendance,
  role,
  presence,
  workerStartTime,
  positionAttendanceBreak,
  positionStartTime,
}: WorkerPositionAttendanceProps): ReactElement {
  const { shiftClosingWorkBreaks } = useBusinessRules();
  const { selectEntity } = useData(requestShiftAttendanceView);

  const { worker, newbie } = selectEntity(selectPositionAttendance(attendance));

  const { avatar, name } = selectEntity(selectWorker(worker));

  const isCrewboss = role === WorkerRole.Crewboss;

  const isLate = isAfter(positionStartTime, workerStartTime);

  const presenceOptions = isLate ? PresenceOptionsLate() : PresenceOptionsOnTime();

  const { validateForm } = useFormikContext();
  const workerRoleOptions = values(WorkerRoleOptions());
  useEffect(() => {
    validateForm();
  }, [presence, isLate]);

  return (
    <Box width="100%">
      <MuiGrid alignItems="center" container spacing={1}>
        <MuiGrid item xs="auto">
          <Avatar file={{ file: avatar as string, variant: "thumbnail_200x200" }} withPreview="thumbnail_500x500" />
        </MuiGrid>
        <MuiGrid item>
          <Text>
            {name}
            {newbie && <Text color="error">*</Text>}
          </Text>
        </MuiGrid>
      </MuiGrid>
      <FieldArray
        name={`positionAttendance.${attendance}.positionAttendanceBreak`}
        render={(props) => (
          <>
            {presence && (
              <MuiGrid container spacing={1} wrap="nowrap">
                <MuiGrid item xs>
                  <SelectDateTimeInput name={`positionAttendance.${attendance}.startTime`} />
                </MuiGrid>
                <MuiGrid item xs>
                  <SelectDateTimeInput
                    minTime={workerStartTime}
                    name={`positionAttendance.${attendance}.endTime`}
                    timePoint={workerStartTime}
                  />
                </MuiGrid>
                {shiftClosingWorkBreaks && (
                  <MuiGrid item xs="auto">
                    <IconButton
                      action={() =>
                        props.push(
                          getNextWorkBreak(
                            workerStartTime,
                            positionAttendanceBreak?.length ? last(positionAttendanceBreak)?.end : undefined
                          )
                        )
                      }
                      icon={mdiCoffeeOutline}
                    />
                  </MuiGrid>
                )}
              </MuiGrid>
            )}

            {presence &&
              shiftClosingWorkBreaks &&
              Boolean(positionAttendanceBreak?.length) &&
              positionAttendanceBreak?.map((workBreak, index) => (
                <MuiGrid container spacing={1} wrap="nowrap">
                  <MuiGrid item xs={5}>
                    <SelectDateTimeInput
                      label={t("Shift.closing.breakFrom")}
                      name={`positionAttendance.${attendance}.positionAttendanceBreak.${index}.beginning`}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={5}>
                    <SelectDateTimeInput
                      label={t("Shift.closing.breakTo")}
                      minTime={workBreak.beginning}
                      name={`positionAttendance.${attendance}.positionAttendanceBreak.${index}.end`}
                      timePoint={workBreak.beginning}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={2}>
                    <MuiGrid item xs="auto">
                      <IconButton action={() => props.remove(index)} icon={mdiCoffeeOffOutline} />
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              ))}
          </>
        )}
      />
      <MuiGrid container spacing={1}>
        <MuiGrid item xs>
          <SelectInput dense name={`positionAttendance.${attendance}.presence`} options={values(presenceOptions)} />
        </MuiGrid>
        <MuiGrid item xs="auto">
          <SelectInput
            dense
            disabled={isCrewboss}
            hideValueLabel
            name={`positionAttendance.${attendance}.role`}
            options={workerRoleOptions}
          />
        </MuiGrid>
      </MuiGrid>
      {(!presence || isLate) && (
        <TextInput
          dense
          /* @ts-expect-error */
          label={presenceOptions[presence ? 1 : 0].noteLabel}
          name={`positionAttendance.${attendance}.note`}
          required
        />
      )}
    </Box>
  );
}
