import { Grid as MuiGrid } from "@material-ui/core";
import { useCurrentUser, useData } from "@sinch/core";
import { Fk, Position, PositionAttendanceStatus, selectPositionAttendance } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Text, useSpacing } from "@sinch/ui";
import { head, isNil } from "ramda";
import React, { ReactElement } from "react";

export function PositionAttendanceWageRating({ id }: { id: Fk<Position> }): ReactElement | null {
  // @ts-expect-error
  const { selectEntity } = useData();
  const { curr } = useFormat();
  const [, inner] = useSpacing();
  const { id: currentUserId } = useCurrentUser();
  const attendance = head(
    selectEntity(
      selectPositionAttendance({
        position: id,
        worker: currentUserId,
      })
    )
  );

  if (!attendance) {
    return null;
  }

  const { wage, rating, status } = attendance;

  if (!wage && !rating) {
    return null;
  }

  const isAbsent = status === PositionAttendanceStatus.Absent;

  return (
    <MuiGrid container spacing={inner}>
      {!isNil(wage) && (
        <MuiGrid item xs={4}>
          <Text bold={isAbsent} color={isAbsent ? "error" : undefined}>
            {curr(wage)}
          </Text>
        </MuiGrid>
      )}
      {!isNil(rating) && rating !== 0 && (
        <MuiGrid item xs>
          <Text bold={isAbsent || rating < 0} color={isAbsent || rating < 0 ? "error" : "success"}>{`${t(
            "Rating.title"
          )} ${rating > 0 ? "+" : ""}${rating}`}</Text>
        </MuiGrid>
      )}
    </MuiGrid>
  );
}
