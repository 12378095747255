import { defineRequest } from "@sinch/core";
import { Fk, ProfileAttribute } from "@sinch/entity";
import { format } from "date-fns";
import { map } from "ramda";
import { ProfilePersonalResult } from "./Personal";

export const requestProfilePersonalUpdate = defineRequest("Profiles/ProfilePersonalUpdate")
  .withParams<{ attributes: ProfilePersonalResult }>()
  .withEmptyResponse()
  .withCreator(({ attributes }: ProfilePersonalResult) => {
    let modified = {};

    if (attributes.birthDate) {
      modified = { ...modified, birthDate: format(attributes.birthDate, "yyyy-MM-dd") };
    }

    if (attributes.dynamicAttributes) {
      modified = {
        ...modified,
        dynamicAttributes: map(({ expiration, ...attr }) => {
          if (expiration) {
            return { ...attr, expiration: format(expiration, "yyyy-MM-dd") };
          }
          return { ...attr };
        }, attributes.dynamicAttributes),
      };
    }

    return { params: { attributes: { ...attributes, ...modified } } };
  });

/**
 * todo: implement backend targets associated to individual attributes
 *  to support granular validations
 *  `worker/Profiles/PersonalUpdate/personalAttributes.${id}`
 */
export const profilePersonalUpdateFilesTarget = (
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  id: Fk<ProfileAttribute>
): string => `worker/Profiles/PersonalUpdateFile`;
