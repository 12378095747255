import { mdiAlertCircleOutline } from "@mdi/js";
import { Box, Center, Grid, Strip, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

/**
 * todo: style properly and extract to somewhere else...
 */
export function NotFound(): ReactElement {
  const navigate = useNavigate();

  return (
    <Center horizontal>
      <Box width={64}>
        <Strip
          color="warning"
          icon={mdiAlertCircleOutline}
          primaryAction={{
            action: () => navigate("/dashboard"),
            label: "Go to Dashboard",
          }}
          secondaryAction={{
            action: () => navigate(-1),
            label: "Navigate Back",
          }}
          title="No Content"
        >
          <Grid spacing="data" vertical>
            <Text>The page you requested was not found.</Text>
            <Text color="info" small>
              {`(${window.location.href})`}
            </Text>
          </Grid>
        </Strip>
      </Box>
    </Center>
  );
}
