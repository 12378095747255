import { Box } from "@material-ui/core";
import { mdiAlert, mdiInformation } from "@mdi/js";
import { ClockInState, Fk, Location, Position } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import { DataGrid } from "@sinch/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { StaffClockInOutButtonsWrapper } from "./StaffClockInOutButtonsWrapper";

interface StaffClockInProps {
  position: Fk<Position>;
  clockInState: ClockInState;
  fullWidth?: boolean;
  startTimeClocked?: Date;
  endTimeClocked?: Date;
  positionFinishedLabel?: boolean;
  positionStartTime: Date;
  location?: Location;
  canAddFiles?: boolean;
}

export const StaffClockIn: React.FC<StaffClockInProps> = ({
  position,
  clockInState,
  fullWidth,
  startTimeClocked,
  endTimeClocked,
  positionFinishedLabel,
  positionStartTime,
  location,
  canAddFiles,
}) => {
  const { t } = useTranslation();
  const { dt } = useFormat();

  if (clockInState === ClockInState.Off) {
    return null;
  }

  if (clockInState === ClockInState.NotReady) {
    return (
      <Box display="flex" py={2}>
        <DataGrid data={[{ color: "warning", content: t<string>("StaffClockIn.isRequired"), icon: mdiAlert }]} small />
      </Box>
    );
  }

  if (positionFinishedLabel && startTimeClocked && endTimeClocked) {
    return (
      <Box display="flex" py={2}>
        <DataGrid
          data={[
            {
              color: "info",
              content: t<string>("StaffClockIn.finished", {
                clockInTime: dt.time(startTimeClocked),
                clockOutTime: dt.time(endTimeClocked),
              }),
              icon: mdiInformation,
            },
          ]}
          small
        />
      </Box>
    );
  }

  if (positionFinishedLabel && clockInState === ClockInState.Finished) {
    if (!startTimeClocked && !endTimeClocked) {
      return (
        <Box display="flex" py={2}>
          <DataGrid
            data={[{ color: "warning", content: t<string>("StaffClockIn.finishedNoClockIn"), icon: mdiAlert }]}
            small
          />
        </Box>
      );
    }
  }

  if (
    clockInState === ClockInState.Finished &&
    ((startTimeClocked && endTimeClocked) || (!startTimeClocked && !endTimeClocked))
  ) {
    return null;
  }

  return (
    <StaffClockInOutButtonsWrapper
      fullWidth={fullWidth}
      isEndTimeClockIn={!!endTimeClocked}
      isStartTimeClockIn={!!startTimeClocked}
      location={location}
      position={position}
      positionStartTime={positionStartTime}
    />
  );
};
