import { Box, Box as MuiBox, ButtonBase, Grid as MuiGrid } from "@material-ui/core";
import { mdiDotsVertical } from "@mdi/js";

import { FileHash, useData } from "@sinch/core";
import { Fk, PositionAttendance, selectPositionAttendance, selectWorker } from "@sinch/entity";
import { CheckboxInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Avatar, Button, InformationDialog, StatusIndicator, Text, useDialog } from "@sinch/ui";
import React, { ReactElement, useEffect } from "react";
import { requestPresenceView } from "../api";
import { PresenceStatusIcon } from "../components";
import { useClosingStatusProps } from "../hooks";
import { PresenceDetailWorker } from "../PresenceDetailWorker";
import { PresenceActions } from "./PresenceActions";

interface PresenceAttendanceItemProps {
  attendance: Fk<PositionAttendance>;
  selected: boolean;
  onSelect: (id: Fk<PositionAttendance>) => void;
  onProcess: (id: Fk<PositionAttendance>, inProcess) => void;
}

function AvatarName({ file, name }: { file?: FileHash; name: string }) {
  return (
    <MuiGrid alignItems="center" container wrap="nowrap">
      <MuiGrid item>
        <MuiBox mx={1} my={0.5}>
          <Avatar file={file} />
        </MuiBox>
      </MuiGrid>
      <MuiGrid item>
        <Box textAlign="left">
          <Text>{name}</Text>
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
}

function StatusRow({
  id,
  action,
  onQuickAction,
}: {
  id: number;
  action: () => void;
  onQuickAction: (id: Fk<PositionAttendance>) => void;
}) {
  return (
    <MuiGrid container direction="column" wrap="nowrap">
      <MuiGrid alignItems="center" container item wrap="nowrap">
        <MuiGrid item xs>
          <PresenceStatusIcon id={id} />
        </MuiGrid>
        <MuiGrid item xs="auto">
          <PresenceActions id={id} onProgress={onQuickAction} />
        </MuiGrid>
        <MuiGrid item xs="auto">
          <Button action={action} icon={mdiDotsVertical} stretch variant="text" />
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}

export function PresenceAttendanceItem({
  attendance,
  selected,
  onSelect,
  onProcess,
}: PresenceAttendanceItemProps): ReactElement {
  const modal = useDialog();

  useEffect(() => () => {
    onProcess(attendance, false);
  });

  return (
    <>
      <PresenceAttendanceFormContent
        attendance={attendance}
        modal={modal}
        onProcess={onProcess}
        onSelect={onSelect}
        selected={selected}
      />
      <InformationDialog
        control={modal}
        hashKey={`presenceAttendance-${attendance}`}
        title={t("Shift.closing.closingDetail")}
        width="xs"
      >
        <PresenceDetailWorker id={attendance} />
      </InformationDialog>
    </>
  );
}

function PresenceAttendanceFormContent({
  attendance,
  selected,
  onSelect,
  onProcess,
  modal,
}: PresenceAttendanceItemProps): ReactElement {
  const { selectEntity } = useData(requestPresenceView);

  const { id, worker, newbie } = selectEntity(selectPositionAttendance(attendance));

  const { avatar, name, phone } = selectEntity(selectWorker(worker));

  const { getForAttendance } = useClosingStatusProps();
  const { color } = getForAttendance(selectEntity(selectPositionAttendance(attendance)));

  return (
    <MuiBox display="flex" width="100%">
      <MuiBox alignItems="flex-start" display="flex">
        <StatusIndicator color={color || "neutral"} />
      </MuiBox>
      <MuiGrid container direction="column" wrap="nowrap">
        <MuiGrid item>
          <MuiGrid alignItems="center" container wrap="nowrap">
            <MuiGrid item>
              <CheckboxInputBase name={`positionSelect${id}`} onChange={() => onSelect(id)} value={selected} />
            </MuiGrid>
            <MuiGrid item xs={3}>
              <ButtonBase onClick={modal.open}>
                <AvatarName
                  file={avatar}
                  name={
                    newbie ? (
                      <Text>
                        {name.trim()}
                        <Text color="error">*</Text>
                      </Text>
                    ) : (
                      name
                    )
                  }
                />
              </ButtonBase>
            </MuiGrid>
            <MuiGrid item xs={2}>
              {phone ?? ""}
            </MuiGrid>
            <MuiGrid item xs={7}>
              <StatusRow
                action={() => {
                  modal.open();
                }}
                id={id}
                onQuickAction={(attendanceId) => onProcess(attendanceId, true)}
              />
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
}
