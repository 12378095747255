import { Callback, Nullable } from "@sinch/types";
import { mergeRight } from "ramda";
import { isFunction } from "ramda-adjunct";
import { ActionContainer } from "../../actions";
import { DialogActions } from "./DialogProps";

const cancelProps = {
  color: "neutral",
  label: "Cancel",
} as const;

const confirmProps = {
  color: "primary",
  label: "Confirm",
} as const;

function isDialogActionsObject(
  val: Callback | ActionContainer | DialogActions
): val is DialogActions {
  return "confirm" in val;
}

function resolveAction(
  action: Callback | ActionContainer,
  defaultProps: Omit<ActionContainer, "action">
): ActionContainer {
  return isFunction(action)
    ? { action, ...defaultProps }
    : mergeRight(defaultProps, action);
}

function resolveCancelAction(
  onClose: Callback,
  action: Callback | ActionContainer | boolean
): Nullable<ActionContainer> {
  if (!action) return null;
  if (action === true) return { action: onClose, ...cancelProps };

  return resolveAction(action, cancelProps);
}

interface ResolvedDialogActions {
  cancel: Nullable<ActionContainer>;

  confirm: Nullable<ActionContainer>;
}

export function resolveDialogActions(
  onClose: Callback,
  actions?: Callback | ActionContainer | DialogActions
): ResolvedDialogActions {
  if (!actions) {
    return {
      cancel: resolveCancelAction(onClose, true),
      confirm: null,
    };
  }

  return isDialogActionsObject(actions)
    ? {
        cancel: resolveCancelAction(onClose, actions.cancel ?? true),
        confirm: resolveAction(actions.confirm, confirmProps),
      }
    : {
        cancel: resolveCancelAction(onClose, true),
        confirm: resolveAction(actions, confirmProps),
      };
}
