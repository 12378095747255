import { mdiSleepOff } from "@mdi/js";
import { requestHibernate, useRequest } from "@sinch/core";
import { t } from "@sinch/intl";
import { Button } from "@sinch/ui";
import React from "react";

export function EndHibernateButton() {
  const dispatch = useRequest();
  return (
    <Button action={() => dispatch(requestHibernate(false))} icon={mdiSleepOff} large variant="outlined">
      {t("Hibernation.wakeFromHibernation")}
    </Button>
  );
}
