import { defineSchema } from "@sinch/types";

/**
 * Request meta containing pagination params.
 */
export interface PagingParams {
  page: number;

  limit: number;
}

/* eslint-disable-next-line @typescript-eslint/no-redeclare */
export const PagingParams = defineSchema<PagingParams>(["page", "limit"]);

/**
 * Response meta containing pagination result.
 */
export interface PagingResult {
  count: number;
}
