import { ParamsOf } from "@sinch/core";
import { Nullable } from "@sinch/types";
import { isUndefined, isValue, serializeDateTime } from "@sinch/utils";
import { reject, when } from "ramda";
import { requestCalendar } from "./api";

const valuesOnly = reject(isUndefined);

export interface CalendarRangeUrlParams {
  from?: Date;
  to?: Date;
}

export const CalendarRangeParams = {
  toRequest({ from, to }: CalendarRangeUrlParams): ParamsOf<typeof requestCalendar> {
    return valuesOnly({
      from,
      to,
    });
  },
};
