import { Box, Paper } from "@material-ui/core";
import { useSpacing } from "@sinch/ui";
import React, { ReactElement, ReactNode } from "react";

export function SquareCard({ children }: { children: ReactNode }): ReactElement {
  const [, , data] = useSpacing();
  return (
    <Paper elevation={0} square>
      <Box px={data}>{children}</Box>
    </Paper>
  );
}
