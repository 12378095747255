import { Box as MuiBox } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { mdiCoffeeOffOutline, mdiCoffeeOutline } from "@mdi/js";

/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { useBusinessRules, useData } from "@sinch/core";
import {
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  WorkerRole,
} from "@sinch/entity";
import { SelectDateTimeInput, SelectInput, TextInput, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Avatar, BorderColorListItem, Box, Flex, IconButton, Text } from "@sinch/ui";
import { add, differenceInMinutes, min, sub } from "date-fns";
import { addMinutes, isAfter } from "date-fns/fp";
import { FieldArray, useFormikContext } from "formik";
import { head, last, pipe, pluck, prop, reject, reverse, sortBy, values } from "ramda";
import React, { ReactElement, useEffect, useMemo } from "react";
import { AttendanceClosingFormState, AttendanceClosingPositionState, requestShiftAttendanceView } from "./api";
import { PresenceOptionsLate, PresenceOptionsOnTime, WorkerRoleOptions } from "./options";

export function getNextWorkBreak(attendanceStart: Date, lastBreakEnd?: Date): { beginning: Date; end: Date } {
  const breakStart = lastBreakEnd || attendanceStart;
  const breakEnd = addMinutes(30, breakStart);
  return { beginning: breakStart, end: breakEnd };
}
const usePresenceOptions = (attendance: Fk<PositionAttendance>) => {
  const { selectEntity } = useData(requestShiftAttendanceView);
  const { lateArrivalRules } = useBusinessRules();
  const { position } = selectEntity(selectPositionAttendance(attendance));
  const {
    positionAttendance: {
      [attendance]: { startTime: workerStartTime },
    },
    startTime,
    withStartTime,
  } = useFormValues<AttendanceClosingFormState>();
  const { meetingTimeInterval } = selectEntity(selectPosition(position));
  const { startTime: positionStartTime } = selectEntity(selectPosition(position));
  const meetingTime = sub(withStartTime && startTime ? startTime : positionStartTime, {
    minutes: meetingTimeInterval || 0,
  });
  const minLateMinutes = Math.min(...pluck("time", lateArrivalRules));
  const isLate = isAfter(add(meetingTime, { minutes: minLateMinutes }), workerStartTime);
  return isLate ? PresenceOptionsLate() : PresenceOptionsOnTime();
};

interface WorkerPositionAttendanceProps extends Omit<AttendanceClosingPositionState, "startTime" | "endTime" | "note"> {
  attendance: Fk<PositionAttendance>;
  workerStartTime: Date;
  positionStartTime: Date;
}

export function WorkerPositionAttendance({ attendance }: WorkerPositionAttendanceProps): ReactElement {
  const {
    positionAttendance: {
      [attendance]: { role, presence, startTime: workerStartTime, positionAttendanceBreak },
    },
    withStartTime,
    startTime,
  } = useFormValues<AttendanceClosingFormState>();

  const { selectEntity } = useData(requestShiftAttendanceView);
  const { position } = selectEntity(selectPositionAttendance(attendance));
  const { startTime: positionStartTime } = selectEntity(selectPosition(position));

  const presenceOptions = usePresenceOptions(attendance);

  return useMemo(
    // bad practice,
    () => (
      <BorderColorListItem color={presenceOptions[presence ? 1 : 0].color}>
        <AttendanceRow
          attendance={attendance}
          positionAttendanceBreak={positionAttendanceBreak}
          positionStartTime={withStartTime && startTime ? startTime : positionStartTime}
          presence={presence}
          role={role}
          workerStartTime={workerStartTime}
        />
      </BorderColorListItem>
    ),
    [role, presence, workerStartTime, positionAttendanceBreak, withStartTime]
  );
}

function AttendanceRow({
  attendance,
  role,
  presence,
  workerStartTime,
  positionAttendanceBreak,
  positionStartTime,
}: WorkerPositionAttendanceProps): ReactElement {
  const { shiftClosingWorkBreaks, lateArrivalRules } = useBusinessRules();

  const { selectEntity } = useData(requestShiftAttendanceView);

  const { startTime: clientStartTime } = useFormValues<AttendanceClosingFormState>();

  const { worker, position, newbie } = selectEntity(selectPositionAttendance(attendance));
  const { meetingTimeInterval } = selectEntity(selectPosition(position));

  const { avatar, name } = selectEntity(selectWorker(worker));

  const isCrewboss = role === WorkerRole.Crewboss;

  const meetingTime = sub(positionStartTime, { minutes: meetingTimeInterval || 0 });

  const minLateMinutes = Math.min(...pluck("time", lateArrivalRules));
  const isLate = isAfter(add(meetingTime, { minutes: minLateMinutes }), workerStartTime);
  const lateDiff = differenceInMinutes(workerStartTime, meetingTime);

  const workerRoleOptions = values(WorkerRoleOptions());
  // get lowest late interval
  const skipLateNote = pipe(
    // @ts-ignore
    reject(({ time }) => time > lateDiff),
    sortBy(prop("time")),
    reverse,
    head,
    prop("skipLateNote")
    // @ts-ignore
  )(lateArrivalRules);

  const presenceOptions = usePresenceOptions(attendance);

  const minTime = sub(min([meetingTime, clientStartTime || meetingTime]), { hours: 1 });

  const { validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, [presence, isLate]);

  return (
    <>
      <MuiBox alignItems="flex-start" display="flex" flexGrow={2} ml={-1}>
        <Flex alignCenter>
          <Box mx={1} my={0.5}>
            <Avatar file={{ file: avatar as string, variant: "thumbnail_200x200" }} withPreview="thumbnail_500x500" />
          </Box>
          {newbie ? (
            <Text>
              {name}
              <Text color="error">*</Text>
            </Text>
          ) : (
            <Text>{name}</Text>
          )}
        </Flex>
      </MuiBox>
      <Box minWidth="600px" width="600px">
        <MuiGrid container spacing={1}>
          <FieldArray
            name={`positionAttendance.${attendance}.positionAttendanceBreak`}
            render={(props) => (
              <>
                <MuiGrid item>
                  <MuiGrid container spacing={1} wrap="nowrap">
                    <MuiGrid item xs={3}>
                      <Box visibility={presence ? "visible" : "hidden"}>
                        <SelectDateTimeInput
                          minDate={minTime}
                          minTime={minTime}
                          name={`positionAttendance.${attendance}.startTime`}
                        />
                      </Box>
                    </MuiGrid>
                    <MuiGrid item xs={3}>
                      <Box visibility={presence ? "visible" : "hidden"}>
                        <SelectDateTimeInput
                          minDate={workerStartTime}
                          minTime={workerStartTime}
                          name={`positionAttendance.${attendance}.endTime`}
                          timePoint={workerStartTime}
                        />
                      </Box>
                    </MuiGrid>
                    {shiftClosingWorkBreaks && presence && (
                      <MuiGrid item xs={1}>
                        <IconButton
                          action={() =>
                            props.push(
                              getNextWorkBreak(
                                workerStartTime,
                                positionAttendanceBreak?.length ? last(positionAttendanceBreak)?.end : undefined
                              )
                            )
                          }
                          icon={mdiCoffeeOutline}
                          label={t("Shift.closingAddBreak")}
                        />
                      </MuiGrid>
                    )}
                    <MuiGrid item xs>
                      <SelectInput
                        dense
                        name={`positionAttendance.${attendance}.presence`}
                        options={values(presenceOptions)}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={2}>
                      <SelectInput
                        dense
                        disabled={isCrewboss}
                        hideValueLabel
                        name={`positionAttendance.${attendance}.role`}
                        options={workerRoleOptions}
                      />
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
                {presence && shiftClosingWorkBreaks && Boolean(positionAttendanceBreak?.length) && (
                  <MuiGrid item xs>
                    {positionAttendanceBreak?.map((workBreak, index) => (
                      <MuiGrid container spacing={1}>
                        <MuiGrid item xs={3}>
                          <SelectDateTimeInput
                            label={t("Shift.closing.breakFrom")}
                            minDate={workerStartTime}
                            minTime={workerStartTime}
                            name={`positionAttendance.${attendance}.positionAttendanceBreak.${index}.beginning`}
                          />
                        </MuiGrid>
                        <MuiGrid item xs={3}>
                          <SelectDateTimeInput
                            label={t("Shift.closing.breakTo")}
                            minDate={workBreak.beginning}
                            minTime={workBreak.beginning}
                            name={`positionAttendance.${attendance}.positionAttendanceBreak.${index}.end`}
                            timePoint={workBreak.beginning}
                          />
                        </MuiGrid>
                        <MuiGrid item xs={1}>
                          <IconButton
                            action={() => props.remove(index)}
                            icon={mdiCoffeeOffOutline}
                            label={t("Shift.closingRemoveBreak")}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    ))}
                  </MuiGrid>
                )}
                {(!presence || (isLate && !skipLateNote)) && (
                  <TextInput
                    dense
                    // @ts-expect-error
                    label={presenceOptions[presence ? 1 : 0].noteLabel}
                    name={`positionAttendance.${attendance}.note`}
                    required
                  />
                )}
              </>
            )}
          />
        </MuiGrid>
      </Box>
    </>
  );
}
