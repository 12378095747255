// import commonDefault from "../i18n/en/common.json";
import { common } from "../i18n";

/**
 * Load static bundled language
 */

export default {
  common,
};
