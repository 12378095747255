import React, { ReactElement, ReactNode } from "react";
import { DataTableDisplay, DataTableRowLogic, DataTableRowProps } from "../types";

export function createDividerRow<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>(
  dividerSelector: (current: TData, previous: TData, row: number) => ReactNode
): DataTableRowLogic<TData, TColumn, TDisplay> {
  return function DividerRow({ table, row }: DataTableRowProps<TData, TColumn, TDisplay>): ReactElement {
    const { data, columns = [], display, logic } = table;
    const { Row, Cell } = display;
    const RowLogic = logic;

    const current = data[row];
    const previous = data[row - 1];

    const divider = dividerSelector(current, previous, row);

    return (
      <>
        {divider && (
          <Row>
            {/* @ts-expect-error todo: add generics for display components and remove */}
            <Cell align="center" colSpan={columns.length}>
              {divider}
            </Cell>
          </Row>
        )}
        <RowLogic row={row} table={table} />
      </>
    );
  };
}
