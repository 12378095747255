import {
  createEntitySelector,
  EntityContainer,
  EntitySelector,
} from "../entity";
import { Penalty } from "./Penalty";

export type PenaltyContainer = EntityContainer<Penalty, "Penalty">;

export const selectPenalty: EntitySelector<Penalty> = createEntitySelector<
  Penalty,
  PenaltyContainer
>("Penalty");
