/* eslint-disable import/no-internal-modules,import/no-extraneous-dependencies */
import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { mdiAlertOutline } from "@mdi/js";

import { Fk, Position } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Avatar, ListBase, Text } from "@sinch/ui";
import { T } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { isElement } from "react-dom/test-utils";
import { PositionHeader } from "./Position";

const useStyles = makeStyles((theme) => ({
  disabledItem: {
    opacity: 0.5,
  },
  missingItem: {
    color: theme.palette.error.main,
  },
  missingItemAvatar: {
    backgroundColor: theme.palette.error.main,
  },
}));

interface WorkerListItemProps {
  avatar?: string | ReactElement;

  chip: boolean;

  disabled?: boolean;

  endTime: Date;

  name: string;

  role: string;

  startTime: Date;

  workerItems?: ReactNode[];

  dataVertical: boolean;

  capacity?: string;

  position?: Fk<Position>;

  headerAction?: ReactNode;
}

/**
 * todo: type properly
 *
 * todo: consider implementing specialized ui component
 */
function WorkerListItem({
  avatar,
  chip,
  disabled,
  name,
  workerItems,
  dataVertical,
  position,
  headerAction,
}: WorkerListItemProps): ReactElement {
  const classes = useStyles();

  if (chip && position) {
    return (
      <Box pt={2} width="100%">
        <PositionHeader position={position}>{headerAction}</PositionHeader>
      </Box>
    );
  }

  return (
    <MuiGrid
      alignItems="center"
      className={disabled ? classes.disabledItem : ""}
      container
      justify="space-between"
      spacing={2}
      wrap="nowrap"
    >
      <MuiGrid alignItems="center" container item spacing={2} wrap="nowrap" xs>
        <MuiGrid item xs="auto">
          {isElement(avatar) ? (
            avatar
          ) : (
            <Avatar file={{ file: avatar as string, variant: "thumbnail_200x200" }} withPreview="thumbnail_500x500" />
          )}
        </MuiGrid>
        <MuiGrid container direction="column" item xs>
          <MuiGrid item>
            <Text>{name}</Text>
          </MuiGrid>
          {dataVertical && workerItems && workerItems.map((item) => <MuiGrid item>{item}</MuiGrid>)}
        </MuiGrid>
      </MuiGrid>
      {!dataVertical &&
        workerItems &&
        workerItems.map((item, index) => (
          <MuiGrid key={index} item xs>
            {item}
          </MuiGrid>
        ))}
    </MuiGrid>
  );
}

function EmptyItem(): ReactElement {
  const classes = useStyles();

  return (
    <MuiGrid
      alignItems="center"
      className={classes.missingItem}
      container
      justify="space-between"
      spacing={2}
      wrap="nowrap"
    >
      <MuiGrid alignItems="center" container item spacing={2} wrap="nowrap" xs>
        <MuiGrid item xs="auto">
          <Avatar className={classes.missingItemAvatar} icon={mdiAlertOutline} />
        </MuiGrid>
        <MuiGrid container direction="column" item xs>
          <MuiGrid item>
            <Text>{t("Shift.missingWorker")}</Text>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}

interface WorkerListProps {
  /* eslint-disable-next-line @typescript-eslint/ban-types */
  data: object[];
}

export function WorkerList({ data }: WorkerListProps): ReactElement {
  const contentSelector = ({
    avatar,
    chip,
    disabled,
    endTime,
    name,
    role,
    startTime,
    workerItems,
    dataVertical,
    capacity,
    empty,
    position,
    headerAction,
  }: /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  any) =>
    empty ? (
      <EmptyItem />
    ) : (
      <WorkerListItem
        avatar={avatar}
        capacity={capacity}
        dataVertical={dataVertical}
        disabled={disabled}
        endTime={endTime}
        headerAction={headerAction}
        chip={chip}
        name={name}
        position={position}
        role={role}
        startTime={startTime}
        workerItems={workerItems}
      />
    );

  return <ListBase contentSelector={contentSelector} data={data} dense disablePadding dividerSelector={T} />;
}
