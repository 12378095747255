import { ResponseData, selectSourceErrorMessages } from "@sinch/core";
import { assocPath, evolve, map, reduce, split } from "ramda";
import { FormErrors, FormValues } from "./Values";

type ErrorMessage = { source: string; text: string };
type ParsedError = { source: string[]; text: string };

const splitPaths = map(evolve({ source: split(".") }));

const toFormErrors: <TValues extends FormValues>(
  fieldErrors: ParsedError[]
) => FormErrors<TValues> = reduce(
  (result, { source, text }) => assocPath(source, text, result),
  {}
);

export function selectFieldErrors<TValues extends FormValues>(
  response: ResponseData
): FormErrors<TValues> {
  const fieldErrors = splitPaths(
    selectSourceErrorMessages(response) as ErrorMessage[]
  );

  return toFormErrors(fieldErrors);
}
