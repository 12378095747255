import { TypographyVariant } from "@material-ui/core/styles";
import MuiTypography from "@material-ui/core/Typography";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import React, { ElementType, ReactElement } from "react";
import { useStyles } from "./styles";
import { ColorParams, FormatParams } from "./TextParams";
import { useStructuredText } from "./TextParamsProvider";

type HeaderLevel = 0 | 1 | 2 | 3;

interface HeaderParams {
  level?: HeaderLevel;
  component?: ElementType<any>;
}

type HeaderProps = HeaderParams & ColorParams & Pick<FormatParams, "strikethrough"> & ChildrenProps;

const levelToVariant: Record<HeaderLevel, TypographyVariant> = {
  0: "h1",
  1: "h2",
  2: "h3",
  3: "h4",
};

export function Header({ level = 1, color, children, strikethrough, component }: HeaderProps): ReactElement {
  const styles = useStyles();
  const structured = useStructuredText();

  return (
    <MuiTypography
      className={clsx(color && styles[color], strikethrough && styles.strikethrough)}
      gutterBottom={structured}
      variant={levelToVariant[level]}
      // @ts-ignore
      component={component}
    >
      {children}
    </MuiTypography>
  );
}
