import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import { Func, IconId, Key, Maybe } from "@sinch/types";
import { isDefined, undef } from "@sinch/utils";
import clsx from "clsx";
import { identity, prop } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { Action, resolveAction } from "../actions";
import { Icon } from "../Icon";

export interface ListProps<T> {
  center?: boolean;

  dense?: boolean;

  getAction?: Func<T, Maybe<Action>>;

  getBody?: Func<T, ReactNode>;

  getIcon?: Func<T, Maybe<IconId>>;

  getId?: Func<T, Key>;

  getStyles?: Func<T, Maybe<string>>;

  items: T[];

  disableGutters?: boolean;
}

/**
 * todo: unify List components
 *
 * todo: do we need to support avatar?
 *
 * todo: rename getBody to getContent?
 */
export function List<T>({
  center,
  dense,
  getAction = undef,
  getBody = identity,
  getIcon = undef,
  getStyles = undef,
  /* @ts-expect-error */
  getId = prop("id"),
  items,
  disableGutters,
}: ListProps<T>): ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <MuiList dense={dense}>
      {items.map((item, i) => {
        const action = getAction(item);
        const body = getBody(item);
        const icon = getIcon(item);
        const id = getId(item) || i;
        const styles = getStyles(item) || false;

        return (
          <MuiListItem
            key={id}
            alignItems={center ? "center" : "flex-start"}
            /* @ts-expect-error */
            button={isDefined(action)}
            className={clsx(styles)}
            disableGutters={disableGutters}
            divider
            {...resolveAction(action)}
          >
            {icon && (
              <MuiListItemIcon>
                <Icon icon={icon} />
              </MuiListItemIcon>
            )}
            <MuiListItemText disableTypography primary={body} />
          </MuiListItem>
        );
      })}
    </MuiList>
  );
}
