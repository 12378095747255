import { defineRequest, FileHash } from "@sinch/core";
import { Fk, Shift } from "@sinch/entity";
import { Nullable } from "@sinch/types";

export interface ReimbursementParams {
  amount: number;

  description?: string;

  attachment: Nullable<FileHash>;

  shift: Fk<Shift>;
}

export const requestReimbursementCreate = defineRequest("Wallets/ReimbursementCreate")
  .withParams<ReimbursementParams>()
  .withEmptyResponse()
  .withCreator((params: ReimbursementParams) => ({ params }));

export const ReimbursementCreateFilesTarget = "worker/Wallets/ReimbursementCreateFiles";
