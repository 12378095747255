import { defineRequest } from "../../backend";
import { NotificationSettingsRecord, NotificationSettingsSection } from "./NotificationSettings";

export const requestPasswordUpdate = defineRequest("Profiles/ProfilePasswordUpdate")
  .withParams<{ current: string; next: string }>()
  .withEmptyResponse()
  .withCreator((current: string, next: string) => ({
    params: {
      current,
      next,
    },
  }));

export const requestHibernate = defineRequest("Profiles/Hibernate")
  .withParams<{ hibernation: boolean; expiration?: string }>()
  .withEmptyResponse()
  .withCreator((hibernation: boolean, expiration?: string) => ({
    params: {
      hibernation,
      expiration,
    },
  }));

export const requestLanguageUpdate = defineRequest("Profiles/LanguageUpdate")
  .withParams<{ language: string }>()
  .withEmptyResponse()
  .withCreator((language: string) => ({ params: { language } }));

/*
 * todo: change keys to "core/NotificationSettings(Update)"
 *
 * todo: fix typo with singular/plural form
 */

export const requestNotificationSettings = defineRequest("Notifications/Setting")
  .withEmptyParams()
  .withResponse<{
    sections: NotificationSettingsSection[];
  }>()
  .withDefaultCreator();

export const requestNotificationSettingsUpdate = defineRequest("Notifications/SettingsUpdate")
  .withParams<{ items: NotificationSettingsRecord }>()
  .withEmptyResponse()
  .withCreator((items: NotificationSettingsRecord) => ({ params: { items } }));
