import { concat, includes } from "ramda";

export type SuccessStatus = 200;
export type ErrorStatus = 400 | 401 | 404 | 409 | 500 | 501;
export type Status = SuccessStatus | ErrorStatus;

const successCodes: SuccessStatus[] = [200];
const errorCodes: ErrorStatus[] = [400, 401, 404, 409];
const fatalCodes: ErrorStatus[] = [500, 501];
const statusCodes: Status[] = concat(successCodes, concat(errorCodes, fatalCodes));

export const isSuccessStatus = (status: Status): status is SuccessStatus => includes(status, successCodes);

export const isExpectedErrorStatus = (status: Status): status is ErrorStatus => includes(status, errorCodes);
export const isUnexpectedErrorStatus = (status: Status): status is ErrorStatus => includes(status, fatalCodes);

export const isSupported = (status: number): status is Status => includes(status, statusCodes);

/**
 * todo: consider using enum (but then all number values are allowed)
 */
const statusNames: Record<Status, string> = {
  200: "OK",
  // 201: "Created",
  400: "Bad Request",
  401: "Unauthorized",
  // 403: "Forbidden",
  404: "Not Found",
  409: "Conflict",
  500: "Internal Server Error",
  501: "Not Implemented",
};

export const statusName = (status: Status): string => statusNames[status];
