import { useData, useStatusUpdate } from "@sinch/core";
import { Fk, selectShift, Shift } from "@sinch/entity";
import { t } from "@sinch/intl";
import { InformationDialog } from "@sinch/ui";
import React, { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requestShiftFeedbackList } from "./api";
import { FeedbackForm } from "./FeedbackForm";
import { SelectedFeedbackState, SelectFeedbackFn } from "./types";
import { WorkerFeedbackList } from "./WorkerFeedbackList";

interface ShiftFeedbackDialogProps {
  onSelect: SelectFeedbackFn;

  selected: SelectedFeedbackState;

  shift: Fk<Shift>;
}

export function ShiftFeedbackDialog({ onSelect, selected, shift }: ShiftFeedbackDialogProps): ReactElement | null {
  const { selectEntity } = useData(requestShiftFeedbackList);
  const { refresh: refreshStatus } = useStatusUpdate();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const shiftEntity = selectEntity(selectShift(shift));
  if (!shiftEntity) {
    refreshStatus();
    return null;
  }
  const { name } = shiftEntity;

  return (
    <InformationDialog
      onClose={() => navigate(pathname, { replace: true })}
      open
      subtitle={name}
      title={t("Feedback.title")}
    >
      {selected ? (
        <FeedbackForm onSelect={() => onSelect(null)} selected={selected} shift={shift} />
      ) : (
        <WorkerFeedbackList onSelect={onSelect} shift={shift} />
      )}
    </InformationDialog>
  );
}
