import { Box, Box as MuiBox, useTheme } from "@material-ui/core";
import MuiTypography from "@material-ui/core/Typography";
import { useData } from "@sinch/core";
import { selectProfession, selectUser } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  ButtonBoxIcon,
  Card,
  Center,
  ConditionalWrapper,
  Grid,
  Header,
  Image,
  routerLink,
  Spacer,
  Text,
} from "@sinch/ui";
import { isDefined } from "@sinch/utils";
import React, { ReactElement } from "react";
import { requestProfileStatus } from "./api";

function StarSvg() {
  return (
    <svg height="38" viewBox="0 0 40 38" width="40" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 4" id="Group_4" transform="translate(-27 -56)">
        <path
          d="M22,32.54,34.36,40,31.08,25.94,42,16.48,27.62,15.24,22,2,16.38,15.24,2,16.48l10.9,9.46L9.64,40Z"
          fill="#fc0"
          id="star"
          transform="translate(25 54)"
        />
        <path
          d="M-560.64,92h0L-573,84.54l-3.915,2.363A58.871,58.871,0,0,0-570.5,60v-.111l3.12,7.351L-553,68.48l-10.921,9.46L-560.64,92Z"
          data-name="Subtraction 17"
          fill="#d6ac01"
          id="Subtraction_17"
          transform="translate(620 2)"
        />
      </g>
    </svg>
  );
}

function BadgeSvg() {
  return (
    <svg height="40" viewBox="0 0 32 40" width="32" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 5" id="Group_5" transform="translate(-81 -56)">
        <path
          d="M19,1,3,8.273V19.182C3,29.273,9.827,38.709,19,41c9.173-2.291,16-11.727,16-21.818V8.273Z"
          fill="#1d83d5"
          id="badge2"
          transform="translate(78 55)"
        />
        <path
          d="M-578,94h0a18.311,18.311,0,0,1-2.58-.854A59.206,59.206,0,0,0-570.5,60c0-.869-.019-1.749-.057-2.617l8.557,3.89V72.182c0,10.16-6.879,19.54-16,21.818Z"
          data-name="Subtraction 16"
          fill="#0a5ea1"
          id="Subtraction_16"
          transform="translate(675 2)"
        />
      </g>
    </svg>
  );
}

function ProfileImage(): ReactElement {
  const theme = useTheme();
  const { selectResult } = useData(requestProfileStatus);
  const { portrait, portraitEditable, portraitMandatory } = selectResult();
  return (
    <ConditionalWrapper
      condition={portraitEditable}
      wrapper={(children) => (
        <Button action={routerLink(`#/uploadPortrait`)} variant="text">
          {children}
        </Button>
      )}
    >
      {portrait ? (
        <Box maxWidth="200px">
          <Image file={portrait} round scaling="fitWidth" />
        </Box>
      ) : (
        <MuiBox bgcolor={theme.palette.grey[100]} p={3} width="100%">
          <MuiTypography align="center" variant="h2">
            {portraitMandatory ? t("Profile.profilePictureMandatory") : t("Profile.uploadProfilePicture")}
          </MuiTypography>
          <Box pt={2}>
            <Button color="info" variant="contained">
              {t("Profile.uploadProfilePicture")}
            </Button>
          </Box>
        </MuiBox>
      )}
    </ConditionalWrapper>
  );
}

export function ProfileCard(): ReactElement {
  const { selectResult, selectEntity } = useData(requestProfileStatus);
  const { rating, maxRating, badgeCount, userId, professionIds } = selectResult();
  const { name, email } = selectEntity(selectUser(userId));
  const professions = selectEntity(selectProfession(professionIds, "name"));

  return (
    <Card>
      <Grid spacing="data">
        <Center>
          <ProfileImage />
        </Center>
        <Center>
          <Header level={0}>{name}</Header>
        </Center>
        <Center>
          <Text>
            <Spacer separator=", ">{professions}</Spacer>
          </Text>
        </Center>
        <Center>{isDefined(email) && <Text small>{email}</Text>}</Center>
        <Grid sizes={6} spacing="outer">
          <ButtonBoxIcon
            action={routerLink("rating")}
            icon={<StarSvg />}
            subtitle={
              <>
                <MuiTypography component="span" variant="h1">
                  <strong>{rating}</strong>
                </MuiTypography>
                <MuiTypography color="textSecondary" component="span" variant="h6">
                  /{maxRating}
                </MuiTypography>
              </>
            }
            title={<Text>{t("Rating.title")}</Text>}
          />
          <ButtonBoxIcon
            action={routerLink("badges")}
            icon={<BadgeSvg />}
            subtitle={
              <MuiTypography component="span" variant="h1">
                <strong>{badgeCount}</strong>
              </MuiTypography>
            }
            title={<Text>{t("Badge.titlePlural")}</Text>}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
