import { mdiChevronRight } from "@mdi/js";
import MuiCard from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

import { IconId } from "@sinch/types";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { Action, resolveAction } from "../actions";
import { Icon } from "../Icon";

const useStyles = makeStyles(({ typography, spacing }) => ({
  flex: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  flexLeft: {
    alignItems: "left",
    display: "flex",
    justifyContent: "left",
  },
  card: {
    // todo: review spacing, get value from theme?
    padding: spacing(2),
    "&$showArrow": {
      paddingRight: spacing(1),
    },
  },
  content: {
    ...typography.bold,
    padding: 0,
    flexGrow: 1,
    "& > svg:first-child": {
      marginRight: spacing(1),
    },
    "&$showArrow": {
      paddingRight: spacing(1),
    },
  },
  showArrow: {},
}));

export interface ButtonCardProps extends ChildrenProps {
  action?: Action;

  disabled?: boolean;

  icon?: IconId;

  showArrow?: boolean;

  // we probably want to remove this prop later and do it the right way
  alignLeft?: boolean;
}

/**
 * todo: could be showArrow derived from action type?
 */
export function ButtonCard({ action, children, disabled, icon, showArrow, alignLeft }: ButtonCardProps): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(action);

  return (
    <MuiCard elevation={0}>
      <MuiCardActionArea
        className={clsx(
          !alignLeft && styles.flex,
          alignLeft && styles.flexLeft,
          styles.card,
          showArrow && styles.showArrow
        )}
        disabled={disabled}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...actionProps}
      >
        <MuiCardContent
          className={clsx(
            !alignLeft && styles.flex,
            alignLeft && styles.flexLeft,
            styles.content,
            showArrow && styles.showArrow
          )}
        >
          {icon ? <Icon icon={icon} /> : null}
          {children}
        </MuiCardContent>
        {showArrow && <Icon icon={mdiChevronRight} />}
      </MuiCardActionArea>
    </MuiCard>
  );
}
