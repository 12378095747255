import MuiGrid, {
  GridItemsAlignment as MuiGridItemsAlignment,
  GridSize as MuiGridSize,
  GridSpacing as MuiGridSpacing,
} from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { OneOrMore } from "@sinch/types";
import { ChildrenProps, isArray, isNumber, isObject } from "@sinch/utils";
import clsx from "clsx";
import React, { Children, ReactElement } from "react";
import { GridSpacingType, useSpacing } from "../UiProvider";

export type GridItemSize = Partial<Record<Breakpoint, boolean | MuiGridSize>>;

export type GridItemSizeOptions = OneOrMore<number | GridItemSize>;

function getSizes(sizes: GridItemSizeOptions, i: number): GridItemSize {
  // sizes = 6
  // each item has the same size on any screen width
  if (!isArray(sizes) && isNumber(sizes)) {
    /* @ts-expect-error todo: narrow type or simplify size options */
    return { xs: sizes, sm: false, md: false, lg: false };
  }

  // sizes = { sm: 6, md: 4, lg: 3 }
  // each item has the same size for given screen width
  if (!isArray(sizes) && isObject(sizes)) {
    return {
      xs: sizes.xs || false,
      md: sizes.md || false,
      lg: sizes.lg || false,
    };
  }

  // sizes = [ ... ]
  // each item has individually defined size
  const size = sizes[i];

  // size = 6
  // this item has the same size on any screen width
  if (isNumber(size)) {
    /* @ts-expect-error todo: narrow type or simplify size options */
    return { xs: size, sm: false, md: false, lg: false };
  }

  // size = { sm: 6, md: 4, lg: 3 }
  // this item has varying size dependent on screen width
  if (isObject(size)) {
    return {
      xs: size.xs || false,
      md: size.md || false,
      lg: size.lg || false,
    };
  }
  throw new Error("Invalid size prop provided to Grid component");
}

const useStyles = makeStyles((theme) => ({
  item: {
    flexGrow: 1,
  },
  // todo: divider should behave differently on normal/vertical grid
  // todo: inconsistent behaviour when using nested grid due to negative margin
  divider: {
    // backgroundClip: "padding-box",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

export interface GridProps extends ChildrenProps {
  sizes?: GridItemSizeOptions;

  vertical?: boolean;

  divider?: boolean;

  spacing?: GridSpacingType | MuiGridSpacing;

  alignItems?: MuiGridItemsAlignment;
}

/**
 * todo: extract GridBase and export individual implementations as public
 *  - VerticalGrid - support dividers (looks like list but for components)
 *  - TwoColumnLayout
 *  - ...
 *
 * todo: review vertical functionality
 *
 * todo: call useSpacing internally and control via exclusive bool params
 *  `type Spacing = "outer" | "inner" | "data" | number`
 *
 * todo: consider removing divider? List or Table should be used instead
 *
 * todo: support custom sizes or provide preset grid layout components?
 *
 * todo: disable sizes validation in production?
 *
 * Using Children.toArray instead of mapping directly drops children that are
 * evaluated to false value
 */
export function Grid({
  sizes = 12,
  vertical = false,
  divider = false,
  spacing: spacingProp = 0,
  alignItems,
  children,
}: GridProps): ReactElement {
  const styles = useStyles();
  const spacingRecord = useSpacing(true);

  /* @ts-expect-error */
  if (isArray(sizes) && sizes.length < children.length) {
    throw new RangeError("Sizes for all child components were not provided");
  }

  const spacing = isNumber(spacingProp)
    ? spacingProp
    : spacingRecord[spacingProp];

  /* eslint-disable react/no-array-index-key */
  return (
    <MuiGrid
      alignItems={alignItems}
      // direction={vertical ? "column" : "row"}
      container
      spacing={spacing}
    >
      {Children.toArray(children).map((item, i) => {
        const { xs, md, lg } = getSizes(sizes, i);
        return (
          <MuiGrid
            key={i}
            className={clsx(styles.item, {
              [styles.divider]: vertical && divider,
            })}
            item
            lg={lg}
            md={md}
            xs={xs}
          >
            {item}
          </MuiGrid>
        );
      })}
    </MuiGrid>
  );
}
