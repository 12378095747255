import { defineRequest, IdResult } from "@sinch/core";
import {
  FeedbackContainer,
  FeedbackType,
  Fk,
  PositionAttendanceContainer,
  PositionContainer,
  ProfessionContainer,
  Shift,
  ShiftContainer,
  Worker,
  WorkerContainer,
} from "@sinch/entity";
import { Nullable } from "@sinch/types";

export interface ShiftFeedbackParams {
  shift: Fk<Shift>;

  text: string;

  to: Nullable<Fk<Worker>>;

  type: FeedbackType;
}

/**
 * todo: use IdParams<Shift> instead of `shift: Fk<Shift>`
 */
export const requestShiftFeedbackSubmit = defineRequest("Shifts/FeedbackSubmit")
  .withParams<ShiftFeedbackParams>()
  .withResponse<IdResult<Shift>>()
  .withResponseEntity<
    FeedbackContainer &
      PositionContainer &
      PositionAttendanceContainer &
      ProfessionContainer &
      ShiftContainer &
      WorkerContainer
  >()
  .withCreator((shift: Fk<Shift>, type: FeedbackType, text: string, to: Nullable<Fk<Worker>>) => ({
    params: { shift, text, to, type },
  }));
