import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { mdiCancel, mdiCellphoneCheck } from "@mdi/js";

import { DataProvider, useData, useFiles, useInstanceSettings } from "@sinch/core";
import { PersonalAttributeCitizenship, ProfileAttributeType, selectPersonalAttribute } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { FileHash, SelectFieldOption } from "@sinch/types";
import {
  Button,
  Divider,
  Header,
  IconText,
  ImageGrid,
  Link,
  ListBase,
  ScrollAnchor,
  Text,
  useMobileLayout,
} from "@sinch/ui";
import { isDefined, toElement } from "@sinch/utils";
import { equals, filter, includes, isEmpty, isNil, map, reject } from "ramda";
import { ensureArray } from "ramda-adjunct";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { requestProfilePersonal } from "./api";
import { NotFilledInfoBox } from "./components/NotFilledInfoBox";
import { ProfileAttributeGroupCard } from "./ProfileAttributeGroupCard";
import { useProfileContext } from "./ProfileContext";
import {
  bankViewData,
  getFilled,
  parseBoolLabel,
  parsePersonal,
  parseSelectValue,
  ProfileEditButton,
  StaticAttributeValue,
} from "./utils";

export interface PersonalAttributeContentProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value: any;

  type: ProfileAttributeType;

  params?: { options?: SelectFieldOption[] };

  citizenship?: PersonalAttributeCitizenship;
}

const thumbnailSrcParam = "/thumbnail_100x100";

function PersonalAttributeContent({ value, type, params }: PersonalAttributeContentProps): ReactElement {
  const { dt } = useFormat();
  const storage = useFiles();
  const { selectResult } = useData(requestProfilePersonal);
  const { phoneVerified } = selectResult();

  if (!isDefined(value) && !equals(type, ProfileAttributeType.File)) {
    return toElement("");
  }
  if (!isDefined(value) && equals(type, ProfileAttributeType.File)) {
    return toElement(t("noFile"));
  }
  if (equals(type, ProfileAttributeType.Select) && (!isDefined(params) || !isDefined(params.options))) {
    return toElement(t("Profile.display.typeError"));
  }

  switch (type) {
    case ProfileAttributeType.Number:
      return <Text>{value.toString()}</Text>;
    case ProfileAttributeType.Bool:
      return <Text>{parseBoolLabel(value)}</Text>;
    case ProfileAttributeType.Select:
      return (
        /* @ts-expect-error */
        <Text>{parseSelectValue(params.options, value)}</Text>
      );
    // same implementation like Select
    case ProfileAttributeType.Level:
      return (
        /* @ts-expect-error */
        <Text>{parseSelectValue(params.options, value)}</Text>
      );
    case ProfileAttributeType.MultiSelect:
      return (
        <Text separator=", ">
          {map(
            ({ label }: SelectFieldOption) => label,
            filter(
              (option: SelectFieldOption) => includes(option.value, value),
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              params.options!
            )
          )}
        </Text>
      );
    case ProfileAttributeType.File:
      // eslint-disable-next-line no-case-declarations
      const { url } = storage(value as FileHash);
      return (
        <Link href={url} target="_blank">
          {t("action.showFile")}
        </Link>
      );
    case ProfileAttributeType.Image:
      return <ImageGrid cellHeight={120} cols={3} files={value as FileHash} srcParam={thumbnailSrcParam} />;
    case ProfileAttributeType.Gallery:
      return <ImageGrid cellHeight={120} cols={3} files={value as FileHash[]} srcParam={thumbnailSrcParam} />;
    case ProfileAttributeType.Text:
      return <Text>{value}</Text>;
    case ProfileAttributeType.LongText:
      return <Text>{value}</Text>;
    case ProfileAttributeType.Date:
      return <Text>{dt(new Date(value))}</Text>;
    case ProfileAttributeType.Phone:
      return (
        <>
          <Text>{value}</Text>
          {!isNil(phoneVerified) &&
            (phoneVerified ? (
              <IconText color="success" icon={mdiCellphoneCheck}>
                {t<string>("verified")}
              </IconText>
            ) : (
              <>
                <IconText color="error" icon={mdiCancel}>
                  {t<string>("Profile.display.phoneUnverified")}
                </IconText>
                <MuiLink href="#/phoneVerification">{t("Profile.PhoneVerification.verifyYourPhone")}</MuiLink>
              </>
            ))}
        </>
      );
    default:
      return <Text>Type error</Text>;
  }
}

function AttributesList({
  name,
  type,
  value,
  params,
  citizenship,
  /* eslint-disable-next-line @typescript-eslint/no-shadow */
  label,
  id,
}: StaticAttributeValue) {
  if (isEmpty(value) || isNil(value)) {
    return null;
  }
  return (
    /* @ts-expect-error */
    <ScrollAnchor id={id}>
      <Text bold {...params?.additionalAttributes}>
        {label}
      </Text>
      {ensureArray(value).map((valueComponent, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${name}${index}`}>
          <PersonalAttributeContent
            citizenship={citizenship}
            params={params}
            type={type as ProfileAttributeType}
            value={valueComponent}
          />
        </div>
      ))}
    </ScrollAnchor>
  );
}

type PersonalAttributeGroupCardParams = {
  values: StaticAttributeValue[];
  edit: string;
  label: string;
  group: string;
};

function PersonalAttributeGroupCard({
  values,
  edit,
  label,
  group,
}: PersonalAttributeGroupCardParams): ReactElement | null {
  const filled = getFilled(values);
  const isNotFilledOut = filled === 0;

  return (
    <>
      <div style={{ height: "100%" }}>
        <Box display="flex" justifyContent="space-between">
          <Header level={2}>{label}</Header>
          <ProfileEditButton edit={edit} label={group} />
        </Box>
        <>
          {isNotFilledOut ? (
            <NotFilledInfoBox edit={edit} label={group} />
          ) : (
            <ListBase
              contentSelector={AttributesList}
              data={reject<StaticAttributeValue>(({ value }) => isEmpty(value) || isNil(value))(values)}
              disablePadding
            />
          )}
        </>
      </div>
      <Divider />
    </>
  );
}

export type PersonalAttributesParams = {
  edit: string;
};

export function PersonalAttributes({ edit }: PersonalAttributesParams): ReactElement {
  const classes = useStyles();
  const { country } = useInstanceSettings();

  const { selectEntity, selectMeta, selectResult } = useData(requestProfilePersonal);
  const { personalAttributes: attributesValues } = selectResult();
  const attributes = selectEntity(selectPersonalAttribute({}));

  const {
    basic,
    contact,
    residence,
    bank,
    citizenship,
    selectedBankAccountFormat,
    preferredLocation,
    visibleGroups,
  } = parsePersonal(selectResult(), selectMeta(), selectEntity);
  const bankView = bankViewData(selectedBankAccountFormat, bank, selectResult().bankAccountHolderName);
  const mobile = useMobileLayout();

  return (
    <>
      <MuiGrid container direction={mobile ? "column" : "row"} spacing={2}>
        {visibleGroups.basic !== false && (
          <MuiGrid height="100%" item md={6} xs={12}>
            <PersonalAttributeGroupCard
              key="basic"
              edit={edit}
              group="basic"
              label={t("Profile.display.basicInfo")}
              values={basic}
            />
          </MuiGrid>
        )}
        <MuiGrid height="100%" item md={6} xs={12}>
          <PersonalAttributeGroupCard
            key="contact"
            edit={edit}
            group="contact"
            label={t("Profile.display.contactInfo")}
            values={contact}
          />
        </MuiGrid>
        {visibleGroups.residence !== false && (
          <MuiGrid height="100%" item md={6} xs={12}>
            <PersonalAttributeGroupCard
              key="residence"
              edit={edit}
              group="residence"
              label={t("Profile.display.residence")}
              values={residence}
            />
          </MuiGrid>
        )}
        {visibleGroups.bank !== false && (
          <MuiGrid height="100%" item md={6} xs={12}>
            {" "}
            <PersonalAttributeGroupCard
              key="bank"
              edit={edit}
              group="bank"
              label={t("Profile.display.bankInfo")}
              values={bankView}
            />
          </MuiGrid>
        )}
        {visibleGroups.preferredLocation !== false && (
          <MuiGrid height="100%" item md={6} xs={12}>
            <PersonalAttributeGroupCard
              key="preferredResidence"
              edit={edit}
              group="preferredResidence"
              label={t("Profile.preferredLocationLabel")}
              values={preferredLocation}
            />
          </MuiGrid>
        )}
      </MuiGrid>
      <ProfileAttributeGroupCard
        attributes={attributes}
        attributesValues={attributesValues}
        edit={edit}
        isCitizen={equals(country, citizenship)}
      />
      <Button className={classes.downloadDataLink} href="/worker/workers/export" stretch variant="text">
        {t<string>("downloadAllPersonalData")}
      </Button>
    </>
  );
}

const useStyles = makeStyles(() => ({
  downloadDataLink: {
    textTransform: "unset",
    color: "#bcbcbc",
  },
}));

export type PersonalContainerParams = {
  children: ReactNode;
};

export function PersonalContainer({ children }: PersonalContainerParams): ReactElement {
  const request = useMemo(() => requestProfilePersonal(), []);
  const { refresh, refreshToken } = useProfileContext();

  return (
    <DataProvider refresh={refreshToken} refresher={refresh} request={request}>
      {children}
    </DataProvider>
  );
}
