import { mdiAccountCash } from "@mdi/js";
import {
  DataProvider,
  responseHandlerKey,
  useBusinessRules,
  useCascadeRefresh,
  useData,
  useRefresh,
} from "@sinch/core";
import { selectShift } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, ButtonProps, InformationDialog, useDialog, useSnackbar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestPositionDetail } from "../../Position";
import { requestReimbursementCreate } from "./api";
import { ReimbursementForm } from "./ReimbursementForm";

interface ReimbursementRequestProps {
  variant?: ButtonProps["variant"];
  shift?: number;
}

export function ReimbursementRequest({
  variant = undefined,
  shift = undefined,
}: ReimbursementRequestProps): ReactElement | null {
  const { allowWorkerReimbursementRequest } = useBusinessRules();
  const dialog = useDialog();

  const { selectEntity } = useData(requestPositionDetail);

  const name = shift ? selectEntity(selectShift(shift, "name")) : undefined;

  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh();
  const { cascadeRefresh } = useCascadeRefresh();
  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestReimbursementCreate,
        () => {
          snackbar("success", t("Reimbursement.createSuccess"));
          refresh();
          cascadeRefresh();
          dialog.close();
        },
        () => {
          snackbar("error", t("Reimbursement.createFailed"));
          refresh();
          cascadeRefresh();
        }
      ),
    [refresh, snackbar]
  );

  if (!allowWorkerReimbursementRequest) {
    return null;
  }

  return (
    <>
      <Button action={dialog} color="info" icon={mdiAccountCash} large variant={variant}>
        {t("Reimbursement.action.request")}
      </Button>
      <InformationDialog
        control={dialog}
        hashKey="reimbursementRequest"
        subtitle={name}
        title={t("Reimbursement.action.request")}
        width="xs"
      >
        <DataProvider handler={responseHandler} refresh={refreshToken}>
          <ReimbursementForm shiftId={shift} />
        </DataProvider>
      </InformationDialog>
    </>
  );
}
