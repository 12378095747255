import { makeStyles } from "@material-ui/core/styles";
import { Id, NotificationType } from "@sinch/entity";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { Text } from "../Text";

const useStyles = makeStyles((theme) => ({
  colorOrder: { color: theme.palette.identifiers.order.main },
  colorJob: { color: theme.palette.identifiers.job.main },
  colorShift: { color: theme.palette.identifiers.shift.main },
  colorPosition: { color: theme.palette.identifiers.position.main },
}));

export type EntityType = "appointment" | "position" | "shift" | "transport" | "news";

/**
 * todo: extract to some common utils? Notification entity? identifier
 * component?
 */
/* @ts-expect-error - General type is not included but that doesnt have link */
export const entityTypes: Record<NotificationType, EntityType> = {
  [NotificationType.Shift]: "shift",
  [NotificationType.Position]: "position",
  [NotificationType.Appointment]: "appointment",
  [NotificationType.Transport]: "transport",
  [NotificationType.News]: "news",
};

const useIdentifierColor = (entity: EntityType, disabled?: boolean) => {
  const styles = useStyles();
  if (disabled) return "neutral";
  if (equals(entity, "position")) return styles.colorPosition;
  if (equals(entity, "shift")) return styles.colorShift;
  return "neutral";
};

export const identifierPrefix = (entity: EntityType) => {
  if (equals(entity, "position")) return "P";
  if (equals(entity, "shift")) return "S";
  if (equals(entity, "appointment")) return "A";
  if (equals(entity, "transport")) return "T";
  return "";
};

export interface IdentifierProps {
  disabled?: boolean;

  entity: EntityType;

  id: Id;

  bold?: boolean;
}

// todo load separator and color + prefix values from env
export function Identifier({ disabled, entity, id, bold }: IdentifierProps): ReactElement {
  return (
    <Text bold={bold} className={useIdentifierColor(entity, disabled)} separator={null}>
      {[identifierPrefix(entity), id]}
    </Text>
  );
}
