import { mdiFilter } from "@mdi/js";
import { Box, Divider } from "@material-ui/core";

import { UserPreferencesKeys, useSearchParamsCustom, useUserPreferences } from "@sinch/core";
import { useCacheState } from "@sinch/core/backend/provider/CachedResponseProvider";
import { t } from "@sinch/intl";
import { Button, Header, Loading, MenuMultiSelect, routerLink, SpacingBox, Text, useSpacing } from "@sinch/ui";
import { rejectFalsy, useLocalStorage } from "@sinch/utils";
import { includes, intersection, keys, pipe, pluck, reduce } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../../components";
import { PositionSearchUrlParams } from "../../../Position";
import { PositionListCondensed } from "../../../Position/List/PositionListCondensed";
import { PositionListMobile } from "../../../Position/List/PositionListMobile";
import { SquareCard } from "./components";

type FilteredFields = "place" | "profession";

export function MobilePositionList(): ReactElement {
  const [, , data] = useSpacing();
  const preferences = useUserPreferences();

  const [view, setView] = useLocalStorage<PositionView>("listView", PositionView.Standard);
  const [fields, setFields] = useLocalStorage(`listViewFields${"PositionList" || ""}`, []);
  const isCached = useCacheState();
  const [rendering, setRendering] = useState<boolean>(!isCached);

  useEffect(() => {
    setTimeout(() => {
      setRendering(false);
    }, 0);
  }, [view]);

  const filterOptions = [
    {
      value: "ignoreCapacity",
      text: t("filter.onlyFree"),
      reversed: true,
    },
    { value: "ignoreRating", text: t("filter.onlyRating"), reversed: true },
  ];

  const quickFilterDefault = isNilOrEmpty(preferences[UserPreferencesKeys.positionListQuickFilter])
    ? []
    : pipe(
        keys,
        intersection(pluck("value", filterOptions))
      )(rejectFalsy(preferences[UserPreferencesKeys.positionListQuickFilter] || {}));

  const { searchParams, updateSearchParams } = useSearchParamsCustom<PositionSearchUrlParams>();

  const changeSearchParams = useCallback(
    (values) => {
      updateSearchParams(
        reduce(
          (acc, { value: val }) => ({
            ...acc,
            [val]: includes(val, values),
          }),
          searchParams,
          filterOptions
        )
      );
    },
    [updateSearchParams]
  );

  const positionListViewSelectHeader = (
    <PositionListViewSelectHeader<FilteredFields>
      additionalFields={
        <MenuMultiSelect
          defaultValue={quickFilterDefault}
          icon={mdiFilter}
          onChange={changeSearchParams}
          // @ts-expect-error
          options={filterOptions}
        />
      }
      countLabel={(count) => (
        <Text separator={" "}>
          <Header component="span" level={2}>
            {t("Position.display.availablePositions")}
          </Header>
          <Text color="neutral" separator=", " small>
            {t("Position.display.positionCount", { count })}
          </Text>
        </Text>
      )}
      fieldsKey="PositionList"
      filterFields={{
        place: t("location"),
        profession: t("Position.display.filterProfession"),
      }}
      onFieldsFilterChange={setFields}
      onViewChange={(view) => {
        setRendering(!isCached);
        setView(view);
      }}
    />
  );

  return (
    <>
      {view === PositionView.Condensed && (
        <SquareCard>
          {positionListViewSelectHeader}
          <SpacingBox mx={-1}>
            <Divider variant="fullWidth" />
          </SpacingBox>
          {!rendering && <PositionListCondensed fields={fields} />}
        </SquareCard>
      )}
      {view === PositionView.Standard && (
        <Box px={data}>
          {positionListViewSelectHeader}
          {!rendering && <PositionListMobile />}
        </Box>
      )}
      {rendering && <Loading />}
      <Box px={data} py={data}>
        <Button action={routerLink("/position")} variant="outlined">
          {t("Dashboard.showAvailablePositions")}
        </Button>
      </Box>
    </>
  );
}
