import { DataProvider, useIntParams } from "@sinch/core";
import { t } from "@sinch/intl";
import { useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { requestNewsDetail } from "../api";
import { NewsDetail } from "./NewsDetail";

export function NewsDetailContainer(): ReactElement {
  useTitleBar({
    container: "core:news",
    locationTitle: t("News.title"),
    showBack: true,
  });

  const { newsId } = useIntParams();

  const request = useMemo(() => requestNewsDetail(newsId), [newsId]);

  return (
    <DataProvider request={request}>
      <NewsDetail />
    </DataProvider>
  );
}
