import { RouteConfig } from "@sinch/core";
import React from "react";
import { Navigate } from "react-router-dom";
import { AppointmentDetail, AppointmentList } from "./Appointment";
import { Calendar } from "./Calendar";
import { Communication } from "./Communication";
import { NewsDetail, NewsList } from "./Communication/News";
import { Dashboard } from "./Dashboard";
import { NotFound } from "./NotFound";
import { NotificationList } from "./Notification";
import { PageView } from "./Page";
import { PositionDetail, PositionHistory, PositionList } from "./Position";
import { Profile } from "./Profile";
import { AttendanceClosing } from "./Shift";
import { Presence } from "./Shift/Presence";
import { Wallet } from "./Wallet";

/**
 * todo: extract paths and use them to generate links via `generatePath`
 *  (solves problem with hardcoded links and allows for easier refactoring)
 *
 * todo: define page titles for individual views which will be displayed
 *  on application main bar and/or browser window
 *  (this should be handled by ApplicationContainer and set via provided hook)
 *
 * todo: routes should be runtime configurable to enable modules and plugins
 *  registering their own routes on application load
 *  - need to be configured in init phase before application root is rendered
 *  - each view/module/plugin defines and exports its own routes and paths
 *  - init phase registers individual plugins in specified order and checks
 *    whether provided path is not colliding with already registered modules
 */
export const routes: RouteConfig[] = [
  {
    path: "/",
    element: <Navigate replace to="/dashboard/overview" />,
  },
  {
    path: "/dashboard/*",
    element: <Dashboard />,
  },
  {
    path: "/position",
    element: <PositionList />,
  },
  {
    path: "/position/:position",
    element: <PositionDetail />,
  },
  {
    path: "/history/:position",
    element: <PositionDetail />,
  },
  {
    path: "/history",
    element: <PositionHistory />,
  },
  {
    path: "/appointment",
    element: <AppointmentList />,
  },
  {
    path: "/appointment/:appointment",
    element: <AppointmentDetail />,
  },
  {
    path: "/shift/:shift/closing",
    element: <AttendanceClosing />,
  },
  {
    path: "/presence/:shift/*",
    element: <Presence />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
  {
    path: "/communication/*",
    element: <Communication />,
  },
  {
    path: "/communication/news/:newsId",
    element: <NewsDetail />,
  },
  {
    path: "/wallet/*",
    element: <Wallet />,
  },
  {
    // todo: change to "page/*" and handle child routes in Page module
    path: "/page/:page",
    element: <PageView />,
  },
  {
    path: "/page/:page/:topic",
    element: <PageView />,
  },
  {
    path: "/profile/*",
    element: <Profile />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
