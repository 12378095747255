import { Box, Grid as MuiGrid } from "@material-ui/core";
import { mdiTimerSandFull } from "@mdi/js";

import { useBusinessRules, useCurrentUser, useData } from "@sinch/core";
import { UserRoles } from "@sinch/core/contract";
import { selectPosition } from "@sinch/entity";
import { Format, t } from "@sinch/intl";
import { B, Button, routerLink, Strip, Text } from "@sinch/ui";
import { addHours, min } from "date-fns";
import React from "react";
import { requestPresenceView } from "../../api";

export function FinishedShiftStrip({ shift }: { shift: number }) {
  const { selectEntity } = useData(requestPresenceView);
  const { crewbossName: crewboss, shiftClosingTimeInterval } = useBusinessRules();
  const { currentRole } = useCurrentUser();
  const closeTimeout = addHours(min(selectEntity(selectPosition({ shift }, "startTime"))), shiftClosingTimeInterval);

  if (currentRole === UserRoles.Admin) {
    return null;
  }

  const dateBold = (
    <B>
      <Format dt={closeTimeout} variant="full" />
    </B>
  );
  return (
    <Box pb={1}>
      <Strip color="info" icon={mdiTimerSandFull}>
        <MuiGrid container direction="column">
          <MuiGrid item>
            <Text>
              {t("Position.display.crewbossCloseShift", {
                date: dateBold,
                crewboss,
              })}
            </Text>
          </MuiGrid>
          <MuiGrid item>
            <Box display="flex" justifyContent="flex-end" ml={-1} pt={2}>
              <Button action={routerLink(`/shift/${shift}/closing`)} color="info" stretch variant="contained">
                {t("Shift.action.closeShift")}
              </Button>
            </Box>
          </MuiGrid>
        </MuiGrid>
      </Strip>
    </Box>
  );
}
