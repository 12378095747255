import { mdiAccountMultiple } from "@mdi/js";
import { Box } from "@material-ui/core";

import { useBusinessRules, useData } from "@sinch/core";
import { PositionQ, PositionStatus, selectPosition, selectShift } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { ButtonCard, GridLayout, InformationDialog, useDialog } from "@sinch/ui";
import { sum } from "ramda";
import React, { ReactElement } from "react";
import { DownloadList, SmsButton } from "../../components";
import { requestPositionDetail } from "./api";
import { WorkersFeedbackDialog } from "./WorkersFeedbackDialog";

export function PositionDetailWorkers(): Nullable<ReactElement> {
  const dialog = useDialog();
  const { showWorkerList } = useBusinessRules();

  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId } = selectResult();

  const isCrewboss = queryEntity(PositionQ.User.isCrewboss(positionId));
  const isAssigned = queryEntity(PositionQ.User.isAssigned(positionId));
  if (!isAssigned || (!showWorkerList && !isCrewboss)) {
    return null;
  }

  const { shift, status } = selectEntity(selectPosition(positionId));
  const { name } = selectEntity(selectShift(shift));

  const fulfilled = sum(queryEntity(PositionQ.Shift.getPositionsProp(positionId, "freeCapacity")));

  const capacity = sum(queryEntity(PositionQ.Shift.getPositionsProp(positionId, "totalCapacity")));

  const label = `${t("Position.signUpWorkers")} (${Math.abs(capacity - fulfilled)}/${capacity})`;

  const isOpen = status < PositionStatus.CrewbossClosed;

  return (
    <GridLayout.Item>
      <ButtonCard action={dialog} icon={mdiAccountMultiple} showArrow>
        {label}
      </ButtonCard>
      <InformationDialog
        control={dialog}
        hashKey="positionDetailWorkers"
        subtitle={name}
        title={t("Position.signUpWorkers")}
      >
        {isOpen && isCrewboss && (
          <Box textAlign="end">
            <SmsButton shift={shift}>{t("Position.SmsSend")}</SmsButton>
            <DownloadList shiftId={shift} />
          </Box>
        )}
        <WorkersFeedbackDialog position={positionId} />
      </InformationDialog>
    </GridLayout.Item>
  );
}
