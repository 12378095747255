import { t } from "@sinch/intl";
import { Loading } from "@sinch/ui";
import React, { ReactElement, useEffect, useState } from "react";
import { PositionListViewSelectHeader, PositionView, SearchParamsPagination } from "../../../components";
import { PositionListCondensed } from "../PositionListCondensed";
import { PositionListMobile } from "../PositionListMobile";

type FilteredFields = "place" | "profession";

export function ListMobile(): ReactElement {
  const [view, setView] = useState<PositionView | null>(null);
  const [fields, setFields] = useState<FilteredFields[]>([]);
  const [rendering, setRendering] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setRendering(false);
    }, 0);
  }, [view]);

  return (
    <>
      <PositionListViewSelectHeader<FilteredFields>
        fieldsKey="PositionList"
        filterFields={{
          place: t("location"),
          profession: t("Position.display.filterProfession"),
        }}
        onFieldsFilterChange={setFields}
        onViewChange={(view) => {
          setRendering(true);
          setView(view);
        }}
      />

      {!rendering && view === PositionView.Condensed && <PositionListCondensed fields={fields} />}
      {!rendering && view === PositionView.Standard && <PositionListMobile />}

      <SearchParamsPagination defaultLimit={100} />
      {rendering && <Loading />}
    </>
  );
}
