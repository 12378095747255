import { makeSearchQuery, useData, useRequest, useSearchParamsCustom } from "@sinch/core";
import { selectCalendarEntriesInInterval } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, Grid, InformationDialog, routerLink } from "@sinch/ui";
import { parseDate } from "@sinch/utils";
import { pluck } from "ramda";
import React, { ReactElement } from "react";
import { requestCalendar, requestCalendarAvailabilityUnset } from "./api";

interface SelectSlotDialogProps {
  endTime?: string;

  startTime?: string;
}

export function SelectSlotDialog({ startTime, endTime }: SelectSlotDialogProps): ReactElement {
  const dispatch = useRequest();

  const { searchParams } = useSearchParamsCustom<Record<string, string>>();

  const { selectEntity } = useData(requestCalendar);
  const entries = pluck(
    "id",
    selectEntity(selectCalendarEntriesInInterval(parseDate(startTime!), parseDate(endTime!)))
  );

  const dateRangeSearchQuery = makeSearchQuery({
    startTime,
    endTime,
  });

  return (
    <InformationDialog
      title={
        t("Calendar.eventsInCalendar") // Události v kalendáři
      }
      width="xs"
    >
      <Grid sizes={[12, 12, 12]} spacing="data">
        {
          t("Calendar.addAvailabilityDescription") // Můžete nastavit, kdy jste zaneprázdnění nebo naopak kdy máte zájem pracovat. V době, kdy nemáte čas, vás nebudeme zapisovat na práce ani vám nebudeme posílat upozornění.
        }
        <Button
          action={routerLink(
            {
              search: `?${new URLSearchParams(searchParams)}`,
              hash: `#/availability/add${dateRangeSearchQuery}`,
            },
            {
              state: {
                startTime,
                endTime,
              },
            }
          )}
          color="secondary"
        >
          {t("action.add")}
        </Button>
        <Button action={() => dispatch(requestCalendarAvailabilityUnset(entries))} color="secondary">
          {t("action.clear") /* Vymazat události */}
        </Button>
      </Grid>
    </InformationDialog>
  );
}
