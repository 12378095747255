import { useFormat } from "@sinch/intl";
import React, { ReactElement } from "react";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputNumberProps } from "./InternalInput";

export interface CurrencyInputProps
  extends Omit<InternalInputNumberProps, "prefix" | "suffix" | "type"> {}

export function CurrencyInput(props: CurrencyInputProps): ReactElement {
  const [baseProps] = useFormField<number, CurrencyInputProps>(props);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <CurrencyInputBase {...baseProps} />;
}

export function CurrencyInputBase(
  props: BaseInputProps<number, CurrencyInputProps>
): ReactElement {
  const {
    curr: { prefix, suffix },
  } = useFormat();

  return (
    <InternalInput
      prefix={prefix}
      suffix={suffix}
      type="number"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  );
}
