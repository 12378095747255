/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Consumer } from "@sinch/types";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { DateInputProps, PresetProps } from "../DateInput";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";
import { DateInputPart } from "./DateInputPart";
// eslint-disable-next-line import/no-cycle
import { TimeInputPart } from "./TimeInputPart";

const useStyles = makeStyles({
  minWidth: {
    minWidth: "130px",
  },
});

/**
 * Props of SelectDateTimeInput
 */
export interface SelectDateTimeInputProps extends ManagedInputProps {
  /**
   * DatePicker props
   */
  datePickerProps?: Omit<DateInputProps, "onChange" | "name">;
  /**
   * If true, native picker are used
   */
  useNative?: boolean;
  /**
   * Step of times list
   */
  step?: number;
  /**
   * Time point for select from which relative time is counted
   */
  timePoint?: Date;
  /**
   * Minimal time of time select
   */
  minTime?: Date;

  minDate?: Date;

  fullWidth?: boolean;

  dimmed?: { date?: boolean };

  defaultDate?: Date;
}

export function SelectDateTimeInput({ preset, ...props }: PresetProps & SelectDateTimeInputProps): ReactElement {
  const [{ value, minTime, minDate, name, ...baseProps }, { setFieldValue }] = useFormField<
    Date,
    SelectDateTimeInputProps
  >(props);

  const onChange = useCallback((next) => setFieldValue(name, next), [setFieldValue]);

  const { error, note } = baseProps;

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return useMemo(
    () => (
      <SelectDateTimeInputBase
        {...baseProps}
        minDate={minDate}
        minTime={minTime}
        name={name}
        onChange={onChange}
        value={value || undefined}
      />
    ),
    [error, note, onChange, value, minTime]
  );
}

/**
 * Component for selecting date and time in separated boxes
 */
export function SelectDateTimeInputBase({
  onChange,
  error,
  name,
  useNative,
  step = 15,
  value: initValue,
  minTime,
  minDate,
  timePoint,
  label,
  note,
  fullWidth,
  dimmed,
  defaultDate,
  inputProps,
}: BaseInputProps<Date, SelectDateTimeInputProps, Consumer<MaterialUiPickersDate>>): ReactElement {
  const styles = useStyles();
  const [value, setValue] = useState<Date | undefined | null>(initValue);
  const handleChange = (newDate: Date | null): void => {
    setValue(newDate);
    onChange(newDate);
  };

  const { date: dateDimmed } = dimmed || { date: true };

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return (
    <MuiFormControl className={styles.minWidth} error={error} margin="dense" variant="outlined">
      <InputLabel shrink>{label}</InputLabel>
      <OutlinedInput
        fullWidth
        inputComponent={() => (
          <MuiGrid container>
            <MuiGrid item style={fullWidth ? {} : { maxWidth: "4em" }} xs>
              <DateInputPart
                dimmed={dateDimmed}
                error={error}
                inputProps={inputProps}
                minDate={minDate}
                name={name}
                onChange={handleChange}
                useNative={useNative}
                value={value || defaultDate}
              />
            </MuiGrid>
            <MuiGrid item xs>
              <TimeInputPart
                initValue={initValue}
                inputProps={inputProps}
                minTime={minTime}
                name={`${name}.time`}
                onChange={handleChange}
                partialDateBase={value || defaultDate}
                step={step}
                timePoint={timePoint}
                useNative={useNative}
                value={value}
              />
            </MuiGrid>
          </MuiGrid>
        )}
        label={label}
        margin="dense"
        name={name}
        notched
      />
      {note && <MuiFormHelperText error={error}>{note}</MuiFormHelperText>}
    </MuiFormControl>
  );
}
