import MuiGrid from "@material-ui/core/Grid";
import { SelectDateTimeInput, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Box, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";

export function PositionEditForm({ minTime, maxTime }: { minTime: Date; maxTime: Date }): ReactElement {
  const [, , data] = useSpacing();

  const { startTime: workerStartTime } = useFormValues();

  return (
    <MuiGrid container spacing={data}>
      <MuiGrid item xs>
        <Box px={0.5}>
          <SelectDateTimeInput
            defaultDate={minTime}
            dense
            label={t("Shift.closing.arrival")}
            minDate={minTime}
            minTime={minTime}
            name="startTime"
          />
        </Box>
      </MuiGrid>
      <MuiGrid item xs>
        <Box px={0.5}>
          <SelectDateTimeInput
            defaultDate={maxTime}
            dense
            label={t("Shift.closing.departure")}
            minDate={minTime || workerStartTime || maxTime}
            minTime={minTime || workerStartTime || maxTime}
            name="endTime"
            timePoint={workerStartTime}
          />
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
}

export function TransportEditForm(): ReactElement {
  const { startTime: workerStartTime } = useFormValues();
  return (
    <MuiGrid container>
      <MuiGrid item xs>
        <Box px={0.5}>
          <SelectDateTimeInput dense label={t("Shift.closing.departure")} name="startTime" />
        </Box>
      </MuiGrid>
      <MuiGrid item xs>
        <Box px={0.5}>
          <SelectDateTimeInput
            dense
            label={t("Shift.closing.arrival")}
            minDate={workerStartTime}
            minTime={workerStartTime}
            name="endTime"
            timePoint={workerStartTime}
          />
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
}
