import DateFnsUtils from "@date-io/date-fns";
import { GridSpacing as MuiGridSpacing, Theme, useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ColorTheme, UserPreferencesKeys } from "@sinch/core/contract";
import { availableLocales } from "@sinch/i18n";
import { useIntl } from "@sinch/intl";
import { useLocalStorage } from "@sinch/utils";
/* eslint-disable-next-line import/no-internal-modules */
/* eslint-disable-next-line import/no-internal-modules */
import { SnackbarProvider } from "notistack";
import React, { createContext, PropsWithChildren, ReactElement, useContext } from "react";
import { darkTheme, theme } from "./theme";

const useStyles = makeStyles(() => ({
  root: {
    width: "fit-content",
  },
}));

const localeMap = availableLocales;

const ThemeSwitcherContext = createContext<[ColorTheme, (value: ColorTheme) => void]>([ColorTheme.Light, () => {}]);

export function useDarkTheme() {
  return useContext(ThemeSwitcherContext);
}

const MobileLayoutContext = createContext<boolean>(false);

export function useMobileLayout(): boolean {
  return useContext(MobileLayoutContext);
}

export type GridSpacingType = "outer" | "inner" | "data";
export type GridSpacingTuple = [MuiGridSpacing, MuiGridSpacing, MuiGridSpacing];
export type GridSpacingRecord = Record<GridSpacingType, MuiGridSpacing>;

export function useSpacing(asObject: true): GridSpacingRecord;
export function useSpacing(asObject?: false): GridSpacingTuple;
/**
 * Returns array of outer, inner and data spacing factors based on
 * desktop/mobile layout.
 * @returns {number[]}
 *
 * todo: extract values to theme so they are accessible in makeStyles
 *  - https://material-ui.com/customization/theming/#custom-variables
 */
export function useSpacing(asObject?: boolean): GridSpacingTuple | GridSpacingRecord {
  const mob = useMobileLayout();
  const data = mob ? 1 : 1;
  const inner = mob ? 2 : 2;
  const outer = mob ? 1 : 3;
  return asObject ? { data, inner, outer } : [outer, inner, data];
}

/**
 * Provide UI providers and hooks for nested components as spacing, theme etc.
 *
 * Use Material-ui providers for ui elements
 */
export function UiProvider({
  children,
  defaultTheme = theme,
}: PropsWithChildren<{ defaultTheme?: Theme }>): ReactElement {
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const styles = useStyles();
  const state = useLocalStorage<ColorTheme>(UserPreferencesKeys.uiColorTheme, ColorTheme.Light);
  const [isDark] = state;

  if (isDark === ColorTheme.Dark) {
    console.log(`  __
 (  )
/<><>\\
-_/\\_- Welcome to the dark side.`);
  }

  const { safeLanguage } = useIntl();

  return (
    <ThemeSwitcherContext.Provider value={state}>
      <MuiThemeProvider theme={isDark === ColorTheme.Dark ? darkTheme : defaultTheme}>
        <MuiPickersUtilsProvider locale={localeMap[safeLanguage as keyof typeof localeMap]} utils={DateFnsUtils}>
          <CssBaseline>
            <MobileLayoutContext.Provider value={mobile}>
              <SnackbarProvider classes={{ containerRoot: styles.root }}>{children}</SnackbarProvider>
            </MobileLayoutContext.Provider>
          </CssBaseline>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ThemeSwitcherContext.Provider>
  );
}
