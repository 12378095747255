import { IdParams, useData } from "@sinch/core";
import { Payout, PayoutPaymentType, selectPayout, selectPayoutMethod } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { DataList, Grid, Text } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import React, { ReactElement } from "react";
import { requestWalletPayoutDetail } from "../Wallet";
import { CalculationTableStatic } from "./CalculationTable";

export function PayoutRequestView({ id }: IdParams<Payout>): ReactElement {
  const { curr, dt } = useFormat();

  const { selectEntity } = useData(requestWalletPayoutDetail);

  const {
    accountingDate,
    components,
    grossAmount,
    method,
    netAmount,
    paymentType,
    transactionDate,
    note,
  } = selectEntity(selectPayout(id));

  const { name } = selectEntity(selectPayoutMethod(method));

  const timeData = [
    accountingDate && {
      label: t("Wallet.display.accountingDate"),
      content: dt(accountingDate),
    },
    transactionDate && {
      label: t("PayoutRequest.transactionDate"),
      content: dt(transactionDate),
    },
  ];

  const wageData = rejectFalsy([
    {
      label: t("Wallet.display.netAmount"),
      content: curr(netAmount),
    },
    {
      label: t("Wallet.display.grossAmount"),
      content: curr(grossAmount),
    },
  ]);

  const contractData = [
    {
      label: t("Wallet.display.paymentType"),
      content: PayoutPaymentType[paymentType],
    },
    {
      label: t("Contract.title"),
      content: name,
    },
    note && {
      label: t("Payment.note"),
      content: <Text multiline>{note}</Text>,
    },
  ];

  return (
    <Grid divider spacing="data" vertical>
      <DataList data={timeData} disablePadding />
      <DataList data={wageData} disablePadding />
      <DataList data={contractData} disablePadding />
      <CalculationTableStatic components={components} grossAmount={grossAmount} netAmount={netAmount} />
    </Grid>
  );
}
