import { Nullable } from "@sinch/types";
import { asInt, ChildrenProps } from "@sinch/utils";
import { mapObjIndexed, replace } from "ramda";
import { cloneElement, ReactElement } from "react";
import { matchPath, resolvePath, useLocation } from "react-router-dom";
import { parseSearchParams } from "./searchParams";

const stripHash = replace("#", "");

interface HashRouteProps extends ChildrenProps<ReactElement> {
  path: string;
}

/**
 * - injecting match params as props directly to child component
 * - children component can call `useLocation` independently
 *
 * todo: access match params via custom hook instead?
 *  (include asInt as well)
 */
export function HashRoute({
  children,
  path,
}: HashRouteProps): Nullable<ReactElement> {
  const { hash } = useLocation();

  const resolvedPath = resolvePath(stripHash(hash));
  const hashSearchParams = parseSearchParams.asBase(
    new URLSearchParams(resolvedPath.search)
  );

  const match = matchPath(path, resolvedPath.pathname);
  if (!match) return null;

  const params = mapObjIndexed(asInt, match.params);
  return cloneElement(children, { ...hashSearchParams, ...params });
  // return match ? cloneElement(children, { ...match.params }) : null;
}
