import { Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, PositionAttendance, selectPositionAttendance, selectWorker, Worker } from "@sinch/entity";
import { CheckboxInputBase } from "@sinch/forms";
import { Avatar, Text, useSpacing } from "@sinch/ui";
import React, { ReactElement, ReactNode } from "react";
import { requestPresenceView } from "../api";

export function PresenceCardContent({
  worker,
  selectMode,
  selected = false,
  subtitle,
  attendance,
}: {
  worker: Fk<Worker>;
  selected?: boolean;
  selectMode?: boolean;
  subtitle?: ReactNode;
  attendance?: Fk<PositionAttendance>;
}): ReactElement {
  const { selectEntity } = useData(requestPresenceView);
  const [, , data] = useSpacing();

  const { id, avatar, name } = selectEntity(selectWorker(worker));

  const { newbie = false } = attendance ? selectEntity(selectPositionAttendance(attendance)) : {};

  const muiGrid = newbie ? (
    <Text>
      {name}
      <Text color="error">*</Text>
    </Text>
  ) : (
    <>{name}</>
  );
  return (
    <MuiGrid container direction="column" spacing={data} wrap="nowrap">
      <MuiGrid item>
        <MuiGrid alignItems="center" container spacing={data} wrap="nowrap">
          {selectMode && (
            <MuiGrid item xs="auto">
              <CheckboxInputBase dense name={`positionSelect${id}`} onChange={() => {}} value={selected} />
            </MuiGrid>
          )}
          <MuiGrid item>
            <Avatar file={avatar} />
          </MuiGrid>
          <MuiGrid item xs>
            <MuiGrid container direction="column" wrap="nowrap">
              <MuiGrid item>
                <Text bold color="primary">
                  {muiGrid}
                </Text>
              </MuiGrid>
              <MuiGrid item>{subtitle}</MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
