import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { useInstanceSettings } from "../context";

export function HtmlHead({ children }: ChildrenProps): ReactElement {
  const {
    name,
    theme: { favicon, toolbarColor },
    locale,
  } = useInstanceSettings();

  if (locale) {
    document.documentElement.lang = locale;
  }

  return (
    <>
      <Helmet>
        <link href={favicon} rel="apple-touch-icon" sizes="180x180" />
        <link href={favicon} rel="icon" sizes="16x16" type="image/png" />
        <link href={favicon} rel="icon" sizes="32x32" type="image/png" />
        <link color={toolbarColor} href={favicon} rel="mask-icon" />
        <link href={favicon} rel="shortcut icon" />
        <meta content={name} name="apple-mobile-web-app-title" />
        <meta content={name} name="application-name" />
        <meta content={toolbarColor} name="theme-color" />
      </Helmet>
      {children}
    </>
  );
}
