import { useData } from "@sinch/core";
import { selectAppointment } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Card, GridLayout } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";
import { Section } from "../../components";
import { requestAppointmentDetail } from "./api";

export function AppointmentDetailDescription(): Nullable<ReactElement> {
  const { selectEntity, selectResult } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const { description } = selectEntity(selectAppointment(appointmentId));

  if (isEmpty(description)) {
    return null;
  }

  return (
    <GridLayout.Item>
      <Card>
        <Section html title={t("description")}>
          {description}
        </Section>
      </Card>
    </GridLayout.Item>
  );
}
