import MuiLink from "@material-ui/core/Link";
import React, { PropsWithChildren } from "react";

export function PhoneLink({ children, phone }: PropsWithChildren<{ phone?: string | number }>) {
  if (!phone) {
    return null;
  }
  return (
    <MuiLink href={`tel:${phone}`} underline="always">
      {children || phone}
    </MuiLink>
  );
}
