import { Container as MuiContainer, makeStyles } from "@material-ui/core";
import { Callback } from "@sinch/types";
import {
  ChildrenProps,
  createCheckedContext,
  toElement,
  useToggleStateObj,
} from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement, useEffect } from "react";

const useStyles = makeStyles(({ mixins: { onLarge }, spacing }) => ({
  fixed: {},
  view: {
    flexGrow: 1,
    padding: spacing(1),
    ...onLarge({
      padding: spacing(3),
      "&:not($fixed)": {
        maxWidth: 1920,
      },
    }),
  },
}));

const FixedLayoutContext = createCheckedContext<{
  set: Callback;
  unset: Callback;
}>("FixedLayout");

export function useFullWidthContent(): void {
  const { set, unset } = FixedLayoutContext.use();
  useEffect(() => {
    unset();
    return () => set();
  }, [set, unset]);
}

export function ViewContentSlot({ children }: ChildrenProps): ReactElement {
  const styles = useStyles();
  const [fixed, update] = useToggleStateObj(true);

  return (
    <MuiContainer
      className={clsx(styles.view, fixed && styles.fixed)}
      component="slot"
      disableGutters
      fixed={fixed}
      maxWidth={fixed && "lg"}
    >
      <FixedLayoutContext value={update}>
        {toElement(children)}
      </FixedLayoutContext>
    </MuiContainer>
  );
}
