import { DataProvider, responseHandlerKey, useData, useRequest, useSession } from "@sinch/core";
import { useProgress } from "@sinch/core/backend/provider/ProgressProvider";
import { selectSnippet, Snippet } from "@sinch/entity";
import { CropData, ImageCropInput, useCropState } from "@sinch/forms";
import { FileHash, Nullable } from "@sinch/types";
import { Button, Grid, InformationDialog, LoadingOverlay, useSnackbar } from "@sinch/ui";
import { filter, isNil, propEq } from "ramda";
import React, { ReactElement, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Notice } from "../../Dashboard/components";
import { ProfilePortraitImageTarget, requestProfilePortraitUpdate, requestProfileStatus } from "./api";
import { useProfileContext } from "./ProfileContext";

const requiredPortraitSize = 200;

const initCrop: CropData = {
  aspect: 1,
  height: requiredPortraitSize,
  unit: "px",
  width: requiredPortraitSize,
  x: 0,
  y: 0,
};

function PortraitUpdate(): ReactElement {
  const { t } = useTranslation();
  const { inProgress, setInProgress } = useProgress();
  const dispatch = useRequest();

  const { selectResult, selectEntity } = useData(requestProfileStatus);
  const { portrait } = selectResult();
  const notices = selectEntity(selectSnippet({}));

  // @ts-ignore
  const [image, setImage] = useState<Nullable<FileHash>>(portrait);
  const [crop, setCrop] = useCropState(initCrop);

  const handleChange = (value: SetStateAction<Nullable<string>>, progress?: string | undefined) => {
    setInProgress(progress !== "success");
    setImage(value);
  };

  return (
    <InformationDialog title={t<string>("Profile.action.changeProfilePicture")}>
      {inProgress && <LoadingOverlay />}
      <Grid spacing="inner" vertical>
        {filter<Snippet>(propEq("name", "profile_picture_upload_note"), notices).map(
          ({ color, body, id, style }, idx) => (
            <React.Fragment key={id}>
              <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
            </React.Fragment>
          )
        )}
        <ImageCropInput
          crop={crop}
          cropProps={{
            minHeight: requiredPortraitSize,
            minWidth: requiredPortraitSize,
          }}
          file={image}
          onCropChange={setCrop}
          onFileChange={handleChange}
          target={ProfilePortraitImageTarget}
        />
        <Button
          action={() => image && crop && dispatch(requestProfilePortraitUpdate(image, crop))}
          disabled={isNil(image) || isNil(crop) || inProgress}
        >
          {t<string>("action.submit")}
        </Button>
      </Grid>
    </InformationDialog>
  );
}

export function PortraitUpdateContainer(): ReactElement {
  const { t } = useTranslation();
  const { refresh, refreshToken } = useProfileContext();
  const snackbar = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { refresh: sessionRefresh } = useSession();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestProfilePortraitUpdate,
        () => {
          snackbar("success", t<string>("Profile.display.portraitUpdateSuccess"));
          navigate(pathname);
          sessionRefresh();
          refresh();
        },
        () => {
          snackbar("error", t<string>("Profile.display.portraitUpdateError"));
          refresh();
        }
      ),
    [navigate, pathname, refresh, snackbar, sessionRefresh]
  );

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken}>
      <PortraitUpdate />
    </DataProvider>
  );
}
