export enum OnsiteArrivalTime {
  ExactTime,
  MeetingTime,
}

export interface BusinessRules {
  /**
   * Defined as difference from `expirationTime` in days.
   */
  profileAttributeExpirationWarningInterval?: number;

  /**
   * Defined as difference from `endTime` in days.
   */
  shiftClosingTimeInterval: number;

  /**
   * Allow editing break times in closing attendance view.
   */
  shiftClosingWorkBreaks?: boolean;

  /**
   * Defined as difference from `endTime` in days.
   */
  shiftFeedbackTimeInterval: number;

  /**
   * Allow user to request payout
   */
  allowWorkerPaymentRequest: boolean;

  allowWorkerReimbursementRequest: boolean;

  showWorkerList: boolean;

  referralProgram: boolean;

  inviteBonusMinAttends: number;

  crewbossName: string;

  hideFinances: boolean;

  shiftClosingCloseDistance: boolean;

  lateArrivalRules: { time: number; skipLateNote: boolean }[];

  onsiteArrivalTime: OnsiteArrivalTime;

  /**
   * Text in clock in/out modal (set in agency settings)
   */
  clockInText: string;
  clockOutText: string;
}
