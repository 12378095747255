import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";

interface BetaDisclaimerProps {
  versionLabel?: string;
}

export const Footer = ({ versionLabel = "" }: BetaDisclaimerProps): ReactElement => (
  <Box display="flex" justifyContent="center" mx={3}>
    <small>{versionLabel}</small>
  </Box>
);
