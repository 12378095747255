import { DataProvider, responseHandlerKey, useCascadeRefresh } from "@sinch/core";
import { t } from "@sinch/intl";
import { useSnackbar } from "@sinch/ui";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { requestReimbursementDelete } from "./api";

export function ReimbursementDeleteContainer({
  children,
  onSuccess,
}: {
  children: ReactNode;
  onSuccess?: () => void;
}): ReactElement {
  const snackbar = useSnackbar();
  const { cascadeRefresh } = useCascadeRefresh();
  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestReimbursementDelete,
        () => {
          snackbar("success", t("Reimbursement.deleteSuccess"));
          cascadeRefresh();
          if (onSuccess) {
            onSuccess();
          }
        },
        () => {
          snackbar("error", t("Reimbursement.deleteFailed"));
        }
      ),
    [snackbar]
  );

  return <DataProvider handler={responseHandler}>{children}</DataProvider>;
}
