import { Box as MuiBox, CircularProgress, FormControl as MuiFormControl, FormHelperText } from "@material-ui/core";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiSwitch from "@material-ui/core/Switch";
import React, { ReactElement } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";

interface SwitchInputProps extends Omit<ManagedInputProps, "dense" | "placeholder"> {
  loading?: boolean;
}

export function SwitchInput(props: SwitchInputProps): ReactElement {
  const [baseProps] = useFormField<boolean, SwitchInputProps>(props);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <SwitchInputBase {...baseProps} />;
}

export function SwitchInputBase({
  disabled,
  error,
  label,
  name,
  note,
  onChange,
  onInvalid,
  value,
  loading,
}: BaseInputProps<boolean, SwitchInputProps>): ReactElement {
  return (
    <MuiFormControl disabled={disabled || loading} error={error}>
      <MuiBox style={{ zIndex: 3, position: "absolute", marginTop: 5 }}>
        {loading && <CircularProgress style={{ height: 30, width: 30 }} />}
      </MuiBox>
      <MuiFormControlLabel
        control={<MuiSwitch checked={value} color="primary" onChange={onChange} onInvalid={onInvalid} />}
        label={label}
        name={name}
      />
      {note && <FormHelperText>{note}</FormHelperText>}
    </MuiFormControl>
  );
}
