import { useDarkTheme } from "@sinch/ui";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement, useEffect } from "react";
import { useData } from "../backend";
import { requestSession, UserPreferences, UserPreferencesKeys } from "../contract";

const UserPreferencesContext = createCheckedContext<UserPreferences>("UserPreferences");
export const { use: useUserPreferences } = UserPreferencesContext;

export function UserPreferencesProvider({ children }: ChildrenProps): ReactElement {
  const { selectResult } = useData(requestSession);
  const { userPreferences } = selectResult();

  const theme = userPreferences[UserPreferencesKeys.uiColorTheme];
  const [, setTheme] = useDarkTheme();

  useEffect(() => {
    if (theme) {
      setTheme(theme);
    }
  }, [theme]);

  return <UserPreferencesContext value={userPreferences}>{children}</UserPreferencesContext>;
}
