import { Maybe } from "@sinch/types";
import { defaultTo, evolve } from "ramda";
import { ServerMessage } from "./ServerMessage";
import { isExpectedErrorStatus, Status } from "./Status";

/* eslint-disable @typescript-eslint/ban-types */

/**
 * todo: make fields optional when type is undefined
 */
export interface ResponseData<
  TResult extends Maybe<object> = Maybe<object>,
  TMeta extends Maybe<object> = Maybe<object>,
  TEntities extends Maybe<object> = Maybe<object>
> {
  status: Status;

  /**
   * Result of message read/write request.
   *
   * Contains primitive values and/or entity references.
   */
  result: TResult;

  /**
   * Related metadata (search params, timeout for data).
   */
  meta: TMeta;

  /**
   * Contains all entities referenced in result or in other entity props.
   *
   * todo: consider if there really is any added value using generic type
   *  - it might be possible to automatically resolve type of entity container
   *    by using some advanced conditional type - traversing via result props
   *    and inferring types from Fk references
   */
  entities: TEntities;

  /**
   * Messages from server (info, warning, error).
   */
  messages: ServerMessage[];
}

export const isErrorResponse = (response: ResponseData): boolean => isExpectedErrorStatus(response.status);

/* @ts-expect-error */
export const withResponseDefaults: <TResponse extends ResponseData>(response: Partial<TResponse>) => TResponse = evolve(
  {
    entities: defaultTo({}),
    meta: defaultTo({}),
    messages: defaultTo([]),
    result: defaultTo({}),
  }
);
