import { DataProvider, useCurrentUser, useData, usePushSubscription } from "@sinch/core";
import { selectSnippet, Snippet } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { Divider, Strip, useMobileLayout, useSpacing } from "@sinch/ui";
import { FluidColumnsGrid } from "@sinch/ui/Layout/FluidColumnsGrid";
import { FluidColumnsGridItem } from "@sinch/ui/Layout/FluidColumnsGridItem";
import { filter, includes, isNil, length, map, propEq, reject } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { useAttendanceRestrictions } from "../../Application";
import {
  HibernationStatusStrip,
  PushNotificationStrip,
  pushNotificationStripHiddenSessionStorageKey,
} from "../../components";
import { Notice } from "../components";
import { requestDashboardSnippetList } from "./api";

function SnippetList(): Nullable<ReactElement> {
  const restrictions = useAttendanceRestrictions();
  const mobile = useMobileLayout();
  const {
    restrictions: { hibernated },
  } = useCurrentUser();
  const { status, isModuleActive } = usePushSubscription();
  const { selectEntity, selectResult } = useData(requestDashboardSnippetList);
  const { snippetIds } = selectResult();
  const [outerSpacing] = useSpacing();

  const pushNotificationStripHidden =
    !isModuleActive ||
    status === "active" ||
    localStorage.getItem(pushNotificationStripHiddenSessionStorageKey) === "true";

  if (
    pushNotificationStripHidden &&
    !snippetIds.length &&
    !restrictions &&
    (isNil(hibernated) || hibernated === false)
  ) {
    return null;
  }

  const notices = selectEntity(selectSnippet(snippetIds));

  return (
    <>
      <FluidColumnsGrid columns={mobile || length(notices) === 1 ? 1 : 2} spacing={outerSpacing}>
        {!isNil(hibernated) && hibernated !== false && (
          <FluidColumnsGridItem>
            <HibernationStatusStrip />
          </FluidColumnsGridItem>
        )}
        {!pushNotificationStripHidden && (
          <FluidColumnsGridItem>
            <PushNotificationStrip />
          </FluidColumnsGridItem>
        )}
        {/* Prefer 'welcome_message' snippet and show it as first, this is little hack, priority index should be used instead */}
        {filter<Snippet>(propEq("name", "welcome_message"), notices).map(({ color, body, id, style }, idx) => (
          <FluidColumnsGridItem key={id}>
            <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
          </FluidColumnsGridItem>
        ))}
        {filter<Snippet>(propEq("name", "worker_dashboard_verified"), notices).map(
          ({ color, body, id, style }, idx) => (
            <FluidColumnsGridItem key={id}>
              <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
            </FluidColumnsGridItem>
          )
        )}
        {restrictions &&
          map(({ id, ...restriction }) => (
            <FluidColumnsGridItem key={id}>
              <Strip {...restriction} />
            </FluidColumnsGridItem>
          ))(restrictions)}
        {reject<Snippet>(({ name }) => includes(name, ["welcome_message", "worker_dashboard_verified"]), notices).map(
          ({ color, body, id, style }, idx) => (
            <FluidColumnsGridItem key={id}>
              <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
            </FluidColumnsGridItem>
          )
        )}
      </FluidColumnsGrid>
      <Divider dark spacing={3} />
    </>
  );
}

export function SnippetListContainer(): ReactElement {
  const request = useMemo(() => requestDashboardSnippetList(), []);

  return (
    <DataProvider request={request}>
      <SnippetList />
    </DataProvider>
  );
}
