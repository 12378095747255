import { DataProvider, responseHandlerKey, useRefresh, useStatusUpdate } from "@sinch/core";
import { t } from "@sinch/intl";
import { InformationDialog, useSnackbar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { requestPenaltyAccept, requestPenaltyList } from "./api";
import { LiablePenaltyList } from "./LiablePenaltyList";

export function PenaltyListContainer(): ReactElement {
  const { refresh: refreshStatus } = useStatusUpdate();
  const request = useMemo(() => requestPenaltyList(), []);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { refresh, refreshToken } = useRefresh();
  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestPenaltyAccept,
        () => {
          snackbar("success", t("Penalty.agreementAccepted"));
          navigate("/dashboard");
          refresh();
          refreshStatus();
        },
        () => {
          snackbar("error", t("Penalty.agreementAcceptFailed"));
          refresh();
          refreshStatus();
        }
      ),
    [navigate, refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request}>
      <InformationDialog title={t("Penalty.titlePlural")}>
        <LiablePenaltyList />
      </InformationDialog>
    </DataProvider>
  );
}
