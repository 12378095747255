import { Box, Grid as MuiGrid, InputAdornment } from "@material-ui/core";
import { mdiClockOutline, mdiCoffeeOffOutline, mdiCoffeeOutline } from "@mdi/js";

import { useBusinessRules, useData } from "@sinch/core";
import { Fk, PositionAttendance, selectPositionAttendance } from "@sinch/entity";
import { SelectTimeInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { Button, Icon, useSpacing } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { max, min } from "date-fns";
import { contains, isNil, pipe, pluck, reject, unless } from "ramda";
import { ensureArray, isNotEmpty } from "ramda-adjunct";
import React, { ReactElement, useState } from "react";
import { requestPresenceView } from "../../api";
import { useUpdatePresence } from "../../hooks";

export interface FullEditProps {
  items: ("startTime" | "endTime" | "breakStart" | "breakEnd")[];
  onCancel: Callback;
  hasBreak: boolean;
  attendance: Fk<PositionAttendance> | Fk<PositionAttendance>[];
}

export function FullEdit({ items, onCancel, hasBreak, attendance }: FullEditProps): ReactElement {
  const [, inner] = useSpacing();
  const { shiftClosingWorkBreaks } = useBusinessRules();
  const { selectEntity } = useData(requestPresenceView);
  const { setTimes } = useUpdatePresence(attendance);

  const defaultDate = pipe(
    rejectFalsy,
    unless(isNotEmpty, () => [new Date()])
  );

  const attendances = selectEntity(selectPositionAttendance(ensureArray(attendance)));

  const minStart = min(defaultDate(pluck("startTime", attendances)));
  const maxEnd = max(defaultDate(pluck("endTime", attendances)));
  const minBreakStart = min(defaultDate(pluck("startTime", pluck("workBreak", attendances))));
  const maxBreakEnd = max(defaultDate(pluck("endTime", pluck("workBreak", attendances))));

  const [startTime, setStartTime] = useState<Date | null>(contains("startTime", items) ? minStart : null);
  const [endTime, setEndTime] = useState<Date | null>(contains("endTime", items) ? maxEnd : null);
  const [breakStart, setBreakStart] = useState<Date | null>(contains("breakStart", items) ? minBreakStart : null);
  const [breakEnd, setBreakEnd] = useState<Date | null>(contains("breakEnd", items) ? maxBreakEnd : null);
  const [breakTime, setBreakTime] = useState<boolean>(hasBreak);

  const hasAttendanceTime = contains("startTime", items) || contains("endTime", items);
  const hasBreakTime = (contains("breakStart", items) || contains("breakEnd", items)) && shiftClosingWorkBreaks;

  const handleTimeChange = async () => {
    await setTimes(
      reject(isNil, {
        startTime,
        endTime,
        breakStart: breakTime ? breakStart : null,
        breakEnd: breakTime ? breakEnd : null,
        hasBreak: breakTime,
      })
    );
    onCancel();
  };

  return (
    <MuiGrid container direction="column" spacing={inner}>
      {hasAttendanceTime && (
        <MuiGrid item>
          <MuiGrid alignItems="center" container spacing={inner}>
            {contains("startTime", items) && (
              <MuiGrid item xs>
                <SelectTimeInputBase
                  adornment={
                    <InputAdornment position="end">
                      <Icon icon={mdiClockOutline} />
                    </InputAdornment>
                  }
                  dense
                  fullWidth
                  label={t("Shift.closing.arrival")}
                  name="startTime"
                  onChange={setStartTime}
                  value={startTime}
                />
              </MuiGrid>
            )}
            {contains("endTime", items) && (
              <MuiGrid item xs>
                <SelectTimeInputBase
                  adornment={
                    <InputAdornment position="end">
                      <Icon icon={mdiClockOutline} />
                    </InputAdornment>
                  }
                  dense
                  fullWidth
                  label={t("Shift.closing.departure")}
                  name="endTime"
                  onChange={setEndTime}
                  timePoint={startTime}
                  value={endTime}
                />
              </MuiGrid>
            )}
            {!breakTime && hasBreakTime && hasAttendanceTime && (
              <MuiGrid item xs="auto">
                <Button action={() => setBreakTime(true)} icon={mdiCoffeeOutline} stretch variant="text" />
              </MuiGrid>
            )}
            {breakTime && hasBreakTime && hasAttendanceTime && (
              <MuiGrid item xs="auto">
                <Button
                  action={() => {
                    setBreakTime(false);
                  }}
                  color="error"
                  icon={mdiCoffeeOffOutline}
                  stretch
                  variant="text"
                />
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiGrid>
      )}
      {breakTime && hasBreakTime && (
        <>
          <MuiGrid item>
            <MuiGrid alignItems="center" container spacing={inner}>
              {contains("breakStart", items) && (
                <MuiGrid item xs>
                  <SelectTimeInputBase
                    adornment={
                      <InputAdornment position="end">
                        <Icon icon={mdiClockOutline} />
                      </InputAdornment>
                    }
                    dense
                    fullWidth
                    label={t("Shift.closing.breakFrom")}
                    minTime={startTime}
                    name="workBreak.startTime"
                    onChange={setBreakStart}
                    value={breakStart}
                  />
                </MuiGrid>
              )}
              {contains("breakEnd", items) && (
                <MuiGrid item xs>
                  <SelectTimeInputBase
                    adornment={
                      <InputAdornment position="end">
                        <Icon icon={mdiClockOutline} />
                      </InputAdornment>
                    }
                    dense
                    fullWidth
                    label={t("Shift.closing.breakTo")}
                    minTime={breakStart}
                    name="workBreak.endTime"
                    onChange={setBreakEnd}
                    timePoint={breakStart}
                    value={breakEnd}
                  />
                </MuiGrid>
              )}
            </MuiGrid>
          </MuiGrid>
        </>
      )}
      <MuiGrid item>
        <Box textAlign="end">
          <Button action={onCancel} stretch variant="contained">
            {t("action.cancel")}
          </Button>
          <Box component="span" pl={1}>
            <Button action={handleTimeChange} color="primary" stretch variant="contained">
              {t("action.save")}
            </Button>
          </Box>
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
}
