import { isNotNil, isNotUndefined, isPlainObj } from "ramda-adjunct";

/*
 * todo: consider deprecating aliased functions,
 *  discuss if its better to import directly from `ramda-adjunct`
 *  or re-export everything and import from `@sinch/utils`?
 */
export { isArray, isNumber, isString, isUndefined } from "ramda-adjunct";

/**
 * @deprecated usage is redundant in most of cases
 * - only valid use-case is when you need to differentiate between `undefined`
 *   and `null`, or possibly other falsy values
 * - otherwise just duplicates native coercion (javascript resolves variables
 *   to boolean values internally when used in condition expression)
 * - if really needed, use functions from `ramda-adjunct` directly
 * @see isNotNil
 * @see isNotNull
 * @see isNotUndefined
 * @see isFalsy
 * @see isTruthy
 */
export function isDefined<T>(val: T | undefined): val is T {
  return isNotUndefined(val);
}

/**
 * @deprecated usage is redundant in almost any case
 * - duplicates native coercion (javascript resolves variables to boolean
 *   values internally when used in condition expression)
 * - if really needed, use functions from `ramda-adjunct` directly
 * @see isNotNil
 * @see isNotNull
 * @see isNotUndefined
 */
export const isValue = isNotNil;

/**
 * @deprecated redundant alias, use functions from `ramda-adjunct` directly
 * @see isPlainObj
 */
export const isObject = isPlainObj;

export const isIos = () => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform || undefined;
  return (
    platform &&
    (platform.indexOf("iPhone") !== -1 || platform.indexOf("iPad") !== -1 || platform.indexOf("iPod") !== -1)
  );
};
