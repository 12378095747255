import { Box, makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { mdiCircleSmall } from "@mdi/js";

import { requestNotificationList, useCascadeRefresh, useData, useStatusUpdate } from "@sinch/core";
import { Notification, NotificationStatus, NotificationType, selectNotification } from "@sinch/entity";
import { HtmlContent, Icon, ListBase, Paper } from "@sinch/ui";
import { isEmpty, pipe, prop, reverse, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { SearchParamsPagination } from "../../components";
import { NOTIFICATION_LIST_DEFAULT_LIMIT } from "./NotificationListContainer";
import { NotificationListEmptyMessage } from "./NotificationListEmptyMessage";

const useStyles = makeStyles((theme) => ({
  notificationBody: {
    textAlign: "justify",
    width: "100%",

    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },

    "& .subject": {
      display: "block",

      "& .from": {
        color: "#4B8DF8",
        fontWeight: 600,
      },

      "& .time": {
        fontWeight: 600,
        float: "right",
      },
    },
  },
  selected: {
    "& .MuiListItem-root": {
      backgroundColor: theme.palette.action.selected,
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

interface NotificationCardProps {
  notification: Notification;
}

function NotificationCardContent({ notification }: NotificationCardProps): ReactElement {
  const { text, status } = notification;
  const classes = useStyles();

  return (
    <MuiGrid alignItems="center" container wrap="nowrap">
      {status === NotificationStatus.Unseen && (
        <MuiGrid item xs="auto">
          <Icon color="secondary" icon={mdiCircleSmall} />
        </MuiGrid>
      )}
      <MuiGrid item xs>
        <div className={classes.notificationBody}>
          <HtmlContent html={text} />
        </div>
      </MuiGrid>
    </MuiGrid>
  );
}

/* eslint-disable-next-line consistent-return */
function getLink({ link, type, url }: Notification) {
  /**
   * todo: use object map instead of switch to avoid default case requirement
   */

  if (!link) {
    return undefined;
  }

  /* eslint-disable-next-line default-case */
  switch (type) {
    case NotificationType.General:
      return url;
    case NotificationType.Shift:
      return `/shift/${link}`;

    case NotificationType.Position:
      return `/position/${link}`;

    case NotificationType.Appointment:
      return `/appointment/${link}`;

    case NotificationType.Transport:
      return `/transport/${link}`;

    case NotificationType.News:
      return `/communication/news/${link}`;
  }
}

export function NotificationsList(): ReactElement {
  const styles = useStyles();
  const { cascadeRefresh } = useCascadeRefresh();
  const { readNotification } = useStatusUpdate();
  const navigate = useNavigate();

  const { selectEntity, selectResult } = useData(requestNotificationList);
  const { notificationIds } = selectResult();

  const notifications = selectEntity(selectNotification(notificationIds));
  const sortedNotifications: Notification[] = pipe<Notification[], Notification[], Notification[]>(
    sortBy(prop("accountingDate")),
    reverse
  )(notifications);

  return (
    <Paper>
      {isEmpty(notifications) ? (
        <NotificationListEmptyMessage />
      ) : (
        <ListBase
          actionSelector={(notification) => async () => {
            const link = getLink(notification);
            if (link) {
              readNotification(notification.id);
              navigate(link);
            } else {
              await readNotification(notification.id);
              cascadeRefresh();
            }
          }}
          className={styles.selected}
          contentSelector={(notification) => (
            <Box pl={notification.status === NotificationStatus.Read ? 3 : 0} pr={3} py={1} width="100%">
              <NotificationCardContent notification={notification} />
            </Box>
          )}
          data={sortedNotifications}
          disablePadding
          dividerSelector={() => true}
          selectionSelector={({ status }) => status === NotificationStatus.Unseen}
        />
      )}
      <SearchParamsPagination defaultLimit={NOTIFICATION_LIST_DEFAULT_LIMIT} />
    </Paper>
  );
}
