import { defineRequest } from "@sinch/core";
import { CalendarEntry, CalendarEntryType, Fk } from "@sinch/entity";

/**
 * todo: consider using google-like interface for creating repeated events
 */
export interface CalendarAvailabilitySetParams {
  endTime: Date;

  entry?: Fk<CalendarEntry>;

  repeat?: boolean;

  startTime: Date;

  title?: string;

  type: CalendarEntryType;
}

export const requestCalendarAvailabilitySet = defineRequest("Calendars/SetAvailability")
  .withParams<CalendarAvailabilitySetParams>()
  .withEmptyResponse()
  .withCreator((params: CalendarAvailabilitySetParams) => ({ params }));
