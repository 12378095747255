import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useData } from "@sinch/core";
import { PositionQ, selectPosition, selectShift, WorkerRole } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Color } from "@sinch/types";
import { Action, Button, routerLink, useMobileLayout, useSpacing } from "@sinch/ui";
import { includes } from "ramda";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "./api";
import { PositionCardField, PositionCardList, PositionCardListSelector } from "./PositionCardList";

const getLabel: PositionCardListSelector<string> = (query, position) => {
  if (query(PositionQ.isStatusCrewbossClosed(position))) {
    return t("Position.status.closed");
  }
  if (query(PositionQ.isStatusUnclosedLate(position))) {
    return t("Position.status.closedLate");
  }
  return t("Position.status.notClosed");
};

const getColor: PositionCardListSelector<Color> = (query, position) => {
  if (query(PositionQ.isStatusCrewbossClosed(position))) {
    return "completed";
  }
  if (query(PositionQ.isStatusUnclosedLate(position))) {
    return "error";
  }
  return "warning";
};

const getAction: PositionCardListSelector<Action> = (query, position) => routerLink(`/position/${position}`);

const getTextColor: PositionCardListSelector<Color> = (query, position) =>
  query(PositionQ.isStatusCrewbossClosed(position)) ? "success" : "default";

const getButtons: PositionCardListSelector<ReactElement> = (query, position) => {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const [, , data] = useSpacing();
  const mobile = useMobileLayout();

  const { applicantPositionIds } = selectResult();
  const { role, shift } = query(selectPosition(position));
  const canOnSitePresence =
    role === WorkerRole.Crewboss &&
    !includes(position, applicantPositionIds) &&
    selectEntity(selectShift(shift, "presence"));

  return (
    <Box pt={data}>
      <MuiGrid container justify="flex-end" spacing={data}>
        {canOnSitePresence && (
          <MuiGrid item>
            <Button
              action={routerLink(`/presence/${shift}`)}
              color="info"
              size={mobile ? "small" : "medium"}
              stretch
              variant="outlined"
            >
              {t("Shift.closing.recordAttendanceButton")}
            </Button>
          </MuiGrid>
        )}
        <MuiGrid item>
          {" "}
          <Button
            action={routerLink(`/shift/${shift}/closing`)}
            color="info"
            size={mobile ? "small" : "medium"}
            stretch
            variant="outlined"
          >
            {t("Shift.action.closeShift")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};

export function FinishedPositions(): ReactElement {
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { positionIdsToClose } = selectResult();

  const fields: PositionCardField[] = ["date", "location", "transport", "time", "contact"];

  return (
    <PositionCardList
      actionSelector={getAction}
      buttonsSelector={getButtons}
      colorSelector={getColor}
      fields={fields}
      labelSelector={getLabel}
      positions={positionIdsToClose}
      textColorSelector={getTextColor}
    />
  );
}
