import { mdiCircleSmall } from "@mdi/js";
import MuiList, { ListProps as MuiListProps } from "@material-ui/core/List";
import MuiListItem, { ListItemProps as MuiListItemProps } from "@material-ui/core/ListItem";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import MuiTableCell, { TableCellProps as MuiTableCellProps } from "@material-ui/core/TableCell";

import React, { ReactElement } from "react";
import { Icon } from "../Icon";
import { Text } from "../Text";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    margin: theme.spacing(2),
  },
  image: {
    padding: theme.spacing(1),
  },
  unorderedList: {
    "& > $listItem > $listItemIcon": {},
  },
  orderedList: {
    counterReset: "item",

    "& > $listItem > $listItemIcon": {
      width: "24px",
      height: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      counterIncrement: "item",

      "&::before": {
        content: "counter(item)",
      },

      "& svg": {
        display: "none",
      },
    },
  },
  listItem: {},
  listItemIcon: {
    color: theme.palette.text.primary,
  },
}));

export function IMG({ src, alt }: { src: string; alt: string }): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.imageContainer}>
      <img alt={alt} className={styles.image} src={src} />
    </div>
  );
}

export function UL({ children }: MuiListProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiList className={styles.unorderedList} dense>
      {children}
    </MuiList>
  );
}

export function OL({ children }: MuiListProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiList className={styles.orderedList} component="ol" dense>
      {children}
    </MuiList>
  );
}

export function LI({ children }: MuiListItemProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiListItem className={styles.listItem}>
      <MuiListItemIcon className={styles.listItemIcon}>
        <Icon icon={mdiCircleSmall} />
      </MuiListItemIcon>
      <MuiListItemText disableTypography>
        <Text>{children}</Text>
      </MuiListItemText>
    </MuiListItem>
  );
}

export function TD({ children }: MuiTableCellProps): ReactElement {
  return (
    <MuiTableCell>
      <Text>{children}</Text>
    </MuiTableCell>
  );
}
