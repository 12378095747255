import { makeStyles } from "@material-ui/core/styles";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement } from "react";

export interface FlexProps extends ChildrenProps {
  alignCenter?: boolean;

  alignStart?: boolean;

  center?: boolean;

  column?: boolean;

  grow?: number;

  shrink?: number;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexGrow: ({ grow }: FlexProps) => grow,
    flexShrink: ({ shrink }: FlexProps) => shrink,
  },

  center: {
    justifyContent: "center",
  },

  alignStart: {
    alignItems: "start",
  },

  alignCenter: {
    alignItems: "center",
  },

  column: {
    flexDirection: "column",
  },
});

/**
 * @deprecated todo: replace with properly styled components
 */
export function Flex({
  alignCenter,
  alignStart,
  center,
  children,
  column,
  grow = 0,
  shrink = 1,
}: FlexProps): ReactElement {
  const styles = useStyles({ grow, shrink });

  return (
    <div
      className={clsx(styles.root, {
        [styles.center]: center,
        [styles.alignStart]: alignStart,
        [styles.alignCenter]: alignCenter,
        [styles.column]: column,
      })}
    >
      {children}
    </div>
  );
}
