/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Logger = (...args: any[]) => void;

export function createLogger(type: string, color: string): Logger {
  return function log(...args) {
    console.log(`%c${type}`, `color: ${color}`, ...args);
  };
}

export const logError = createLogger("ERROR", "red");
