import MuiGrid from "@material-ui/core/Grid";
import { mdiAccountCancel, mdiCheck, mdiShuffleVariant } from "@mdi/js";

import { useData } from "@sinch/core";
import {
  Appointment,
  AppointmentQ,
  Fk,
  Position,
  selectAppointment,
  selectAppointmentType,
  selectLocation,
  selectPosition,
  selectShift,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Chip,
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  createDividerRow,
  createMuiStyleRow,
  DataTableRow,
  dateTimeLogic,
  Icon,
  identifierPrefix,
  MuiDataTable,
  Paper,
  routerLink,
  TimeRange,
  useFullWidthContent,
} from "@sinch/ui";
import { isDefined, isUndefined } from "@sinch/utils";
import { isSameDay } from "date-fns";
import { isEmpty, isNil } from "ramda";
import React, { ReactElement } from "react";
import { SearchParamsPagination } from "../../components";
import { requestAppointmentList } from "./api";
import { APPOINTMENT_LIST_DEFAULT_LIMIT } from "./AppointmentListContainer";
import { AppointmentListEmptyMessage } from "./AppointmentListEmptyMessage";

type AppointmentColumn = "appointment" | "date" | "freeCapacity" | "location" | "status" | "time" | "type";

const ClickableRow = createClickableRow((appointment) => routerLink(`/appointment/${appointment}`));

export function AppointmentList(): ReactElement {
  useFullWidthContent();
  const { dt } = useFormat();
  const { selectEntity, selectResult, queryEntity } = useData(requestAppointmentList);

  const { appointmentIds } = selectResult();

  const DateDividerRow = createDividerRow((current, previous) => {
    const [currentStartTime, previousStartTime] = selectEntity(
      selectAppointment([current, previous] as number[], "startTime")
    );

    const showDivider = isUndefined(previous) || !isSameDay(currentStartTime, previousStartTime);

    return (
      showDivider && <Chip color="primary" label={`${dt.wday(currentStartTime)} ${dt.chip(currentStartTime)}`} large />
    );
  });

  const StatusColorRow = createMuiStyleRow(
    (theme) => ({
      unavailable: {
        color: theme.palette.text.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
      },
      conflicting: {
        color: theme.palette.text.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
      },
      attending: {
        backgroundColor: theme.palette.completed.main,
      },
    }),
    (id) => {
      if (queryEntity(AppointmentQ.User.isAttending(id))) return ["attending"];
      if (queryEntity(AppointmentQ.isFull(id))) return ["unavailable"];
      if (queryEntity(AppointmentQ.User.hasConflicting(id))) return ["conflicting"];
      return [];
    }
  );

  const AppointmentDataTable = MuiDataTable.withConfig<Fk<Appointment>, AppointmentColumn>({
    appointment: {
      title: t("Appointment.title"),
      selector: (id) => selectEntity(selectAppointment(id, "name")),
    },
    date: {
      title: t("date"),
      selector: (id) => selectEntity(selectAppointment(id, "startTime")),
      logic: composeCellLogic([dateTimeLogic()]),
    },
    time: {
      title: t("time"),
      selector: (id) => {
        const { startTime, endTime } = selectEntity(selectAppointment(id));
        return isDefined(endTime) ? (
          <TimeRange endTime={endTime} showLength startTime={startTime} />
        ) : (
          dt.time(startTime)
        );
      },
    },
    location: {
      title: t("place"),
      selector: (id) => {
        const location = selectEntity(selectAppointment(id, "location"));
        return selectEntity(selectLocation(location, "name"));
      },
    },
    type: {
      title: t("type"),
      selector: (id) => {
        const type = selectEntity(selectAppointment(id, "type"));
        return isDefined(type) ? selectEntity(selectAppointmentType(type, "name")) : "";
      },
    },
    freeCapacity: {
      title: t("capacity"),
      selector: (id) => {
        const { totalCapacity, freeCapacity } = selectEntity(selectAppointment(id));
        return isNil(freeCapacity) || isNil(totalCapacity)
          ? null
          : `${Math.max(0, totalCapacity - freeCapacity)}/${totalCapacity}`;
      },
    },
    status: {
      selector: (id) => {
        if (queryEntity(AppointmentQ.User.isAttending(id)))
          return <Icon color="success" icon={mdiCheck} tooltip={t("Appointment.attending")} />;

        if (queryEntity(AppointmentQ.isFull(id)))
          return <Icon color="neutral" icon={mdiAccountCancel} tooltip={t("Appointment.noFreePositions")} />;

        if (queryEntity(AppointmentQ.User.hasConflicting(id))) {
          const conflicting = selectEntity(selectAppointment(id, "conflicting"));
          // @ts-expect-error
          const { position, appointment } = conflicting;
          const conflictingList: string[] = [];
          position.forEach((conflictingPositionId: Fk<Position>) => {
            const { shift } = selectEntity(selectPosition(conflictingPositionId));
            const { name } = selectEntity(selectShift(shift));
            conflictingList.push(`${identifierPrefix("position") + conflictingPositionId} ${name}`);
          });
          appointment.forEach((conflictingAppointmentId: Fk<Position>) => {
            const { name } = selectEntity(selectAppointment(conflictingAppointmentId));
            conflictingList.push(`${identifierPrefix("appointment") + conflictingAppointmentId} ${name}`);
          });

          return (
            <Icon
              color="error"
              icon={mdiShuffleVariant}
              tooltip={
                <MuiGrid container direction="column">
                  {[t("Position.display.conflictingJob"), ...conflictingList].map((item) => (
                    <MuiGrid item>{item}</MuiGrid>
                  ))}
                </MuiGrid>
              }
            />
          );
        }

        return null;
      },
    },
  }).withLogic(composeRowLogic([DateDividerRow, StatusColorRow, ClickableRow, DataTableRow]));

  return (
    <Paper>
      {isEmpty(appointmentIds) ? (
        <AppointmentListEmptyMessage />
      ) : (
        <AppointmentDataTable
          columns={["appointment", "date", "time", "location", "type", "freeCapacity", "status"]}
          data={appointmentIds}
        >
          <SearchParamsPagination defaultLimit={APPOINTMENT_LIST_DEFAULT_LIMIT} />
        </AppointmentDataTable>
      )}
    </Paper>
  );
}
