import { AccordionProps } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { DataProvider, useData, useInstanceSettings } from "@sinch/core";
import { DateInput, SelectInput, SwitchInput, SwitchInputBase, TextInput, useFormValues } from "@sinch/forms";
import { t, useIntl } from "@sinch/intl";
import { Divider, Text } from "@sinch/ui";
import { rejectFalsy, useToggleState } from "@sinch/utils";
import { useFormikContext } from "formik";
import { equals, includes, map } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useEffect, useMemo } from "react";
import { requestProfilePersonal, requestRegion } from "../api";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { countryOptions } from "../countries";
import { useSystemAttributes } from "../hooks";
import { useFieldListMeta } from "../utils";

const RegionInput = ({ required, name }: { required: boolean; name: string }) => {
  const { selectResult } = useData(requestRegion);
  const { regionName, regions } = selectResult();
  const { setFieldValue } = useFormikContext();
  const hasRegionName = !!regionName;

  useEffect(() => {
    if (!hasRegionName) {
      setFieldValue(name, "");
    }
  }, [hasRegionName]);

  if (!regionName) {
    return null;
  }

  return (
    <MuiGrid item>
      {regions ? (
        <SelectInput
          label={regionName}
          name={name}
          options={map(([value, label]) => ({ value, label }), Object.entries(regions))}
          placeholder={regionName}
          required={required}
        />
      ) : (
        <TextInput label={regionName} name={name} placeholder={regionName} required={required} />
      )}
    </MuiGrid>
  );
};

const RegionInputContainer = ({ required, country, name }: { required: boolean; country: string; name: string }) => {
  const request = useMemo(() => requestRegion(country), [country]);

  return (
    <DataProvider request={request}>
      <RegionInput name={name} required={required} />
    </DataProvider>
  );
};

export interface EditResidenceParams {
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
}

export function EditResidence({ expanded, onExpansionChange }: EditResidenceParams): ReactElement | null {
  const { selectMeta } = useData(requestProfilePersonal);
  const { citizenship = null, isPermanentResident, permanentAddress, correspondenceAddress } = useFormValues();
  const { countriesWithRegions } = selectMeta();

  const isCorrespondenceEmptyOrSame =
    (isNilOrEmpty(correspondenceAddress?.street) &&
      isNilOrEmpty(correspondenceAddress?.city) &&
      isNilOrEmpty(correspondenceAddress?.zip)) ||
    equals(permanentAddress, correspondenceAddress);

  const [addressSame, toggleAddressSame] = useToggleState(isCorrespondenceEmptyOrSame);
  const { locale } = useIntl();
  const { country } = useInstanceSettings();
  const { isBlockEmpty, isVisible, isRequired } = useSystemAttributes();
  const { setValues } = useFormikContext();

  const isCitizen = equals(citizenship, country);

  const { filled, total, errors, fields } = useFieldListMeta(
    rejectFalsy([
      ...(!isPermanentResident
        ? []
        : rejectFalsy([
            isVisible("Workerinfo.permanent_duration_from") && `permanentResident.from`,
            isVisible("Workerinfo.permanent_duration_to") && `permanentResident.to`,
          ])),
      isVisible("Workerinfo.address") && `permanentAddress.street`,
      isVisible("Workerinfo.city") && `permanentAddress.city`,
      permanentAddress?.country &&
        includes(permanentAddress.country, countriesWithRegions) &&
        isVisible("Workerinfo.region") &&
        `permanentAddress.region`,
      isVisible("Workerinfo.zip") && `permanentAddress.zip`,
      isVisible("Workerinfo.country") && `permanentAddress.country`,
      ...(addressSame
        ? []
        : rejectFalsy([
            isVisible("Workerinfo.address_local") && `correspondenceAddress.street`,
            isVisible("Workerinfo.city_local") && `correspondenceAddress.city`,
            correspondenceAddress?.country &&
              includes(correspondenceAddress.country, countriesWithRegions) &&
              isVisible("Workerinfo.region") &&
              `correspondenceAddress.region`,
            isVisible("Workerinfo.zip_local") && `correspondenceAddress.zip`,
            isVisible("Workerinfo.country_local") && `correspondenceAddress.country_local`,
          ])),
    ])
  );

  const empty = isBlockEmpty([
    "Workerinfo.permanent_resident",
    "Workerinfo.permanent_duration_from", // TODO: DODĚLAT POLE!!
    "Workerinfo.permanent_duration_to",
    "Workerinfo.address",
    "Workerinfo.city",
    "Workerinfo.region",
    "Workerinfo.zip",
    "Workerinfo.country",
    "Workerinfo.address_local",
    "Workerinfo.city_local",
    "Workerinfo.zip_local",
    "Workerinfo.country_local",
  ]);

  const localEmpty = isBlockEmpty([
    "Workerinfo.address_local",
    "Workerinfo.city_local",
    "Workerinfo.zip_local",
    "Workerinfo.country_local",
  ]);

  const permanentEmpty = isBlockEmpty([
    "Workerinfo.permanent_resident",
    "Workerinfo.permanent_duration_from",
    "Workerinfo.permanent_duration_to",
  ]);

  useEffect(() => {
    if (addressSame) {
      setValues((values) => ({ ...values, correspondenceAddress: permanentAddress }));
    }
  }, [addressSame, setValues, permanentAddress]);
  const content = useMemo(
    () => (
      <MuiGrid container direction="column">
        <MuiGrid container direction="column" item>
          <MuiGrid item>
            <Text bold>{t("Profile.permanentResidenceLabel")}</Text>
          </MuiGrid>
          {isVisible("Workerinfo.country") && (
            <MuiGrid item>
              <SelectInput
                key="permanentAddress.country"
                label={t("Profile.form.country")}
                name="permanentAddress.country"
                options={countryOptions(locale, country)}
                placeholder={t("Profile.form.country")}
                required={isRequired("Workerinfo.country")}
              />
            </MuiGrid>
          )}
          {isVisible("Workerinfo.address") && (
            <MuiGrid item>
              <TextInput
                key="permanent-street"
                label={t("Profile.form.street")}
                name="permanentAddress.street"
                placeholder="Street"
                required={isRequired("Workerinfo.address")}
              />
            </MuiGrid>
          )}
          {isVisible("Workerinfo.city") && (
            <MuiGrid item>
              <TextInput
                key="permanent-city"
                label={t("Profile.form.city")}
                name="permanentAddress.city"
                placeholder="City"
                required={isRequired("Workerinfo.city")}
              />
            </MuiGrid>
          )}
          {isVisible("Workerinfo.region") && (
            <RegionInputContainer
              country={permanentAddress.country}
              name="permanentAddress.region"
              required={isRequired("Workerinfo.region")}
            />
          )}
          {isVisible("Workerinfo.zip") && (
            <MuiGrid item>
              <TextInput
                key="permanent-zip"
                label={t("Profile.form.postalCode")}
                name="permanentAddress.zip"
                placeholder="Zip"
                required={isRequired("Workerinfo.zip")}
              />
            </MuiGrid>
          )}
        </MuiGrid>
        {!localEmpty && (
          <>
            <MuiGrid item>
              <Divider dark />
              <Text bold>{t("Worker.contactAddress")}</Text>
            </MuiGrid>
            <MuiGrid item>
              <SwitchInputBase
                label={t("Profile.form.sameAsPermanentResidence")}
                name="addressSame"
                onChange={toggleAddressSame}
                value={addressSame}
              />
            </MuiGrid>
            {!addressSame && (
              <MuiGrid container direction="column" item>
                {isVisible("Workerinfo.country_local") && (
                  <MuiGrid item>
                    <SelectInput
                      key="correspondenceAddress.country"
                      label={t("Profile.form.country")}
                      name="correspondenceAddress.country"
                      options={countryOptions(locale, country)}
                      placeholder={t("Profile.form.country")}
                      required={isRequired("Workerinfo.country_local")}
                    />
                    {isVisible("Workerinfo.address_local") && (
                      <MuiGrid item>
                        <TextInput
                          key="correspondence-street"
                          label={t("Profile.form.street")}
                          name="correspondenceAddress.street"
                          placeholder="Street"
                          required={isRequired("Workerinfo.address_local")}
                        />
                      </MuiGrid>
                    )}
                    {isVisible("Workerinfo.city_local") && (
                      <MuiGrid item>
                        <TextInput
                          key="correspondence-city"
                          label={t("Profile.form.city")}
                          name="correspondenceAddress.city"
                          placeholder="City"
                          required={isRequired("Workerinfo.city_local")}
                        />
                      </MuiGrid>
                    )}
                    {isVisible("Workerinfo.region_local") && (
                      <RegionInputContainer
                        country={correspondenceAddress.country}
                        name="correspondenceAddress.region"
                        required={isRequired("Workerinfo.region_local")}
                      />
                    )}
                    {isVisible("Workerinfo.zip_local") && (
                      <MuiGrid item>
                        <TextInput
                          key="correspondence-zip"
                          label={t("Profile.form.postalCode")}
                          name="correspondenceAddress.zip"
                          placeholder="Zip"
                          required={isRequired("Workerinfo.zip_local")}
                        />
                      </MuiGrid>
                    )}
                  </MuiGrid>
                )}
              </MuiGrid>
            )}
          </>
        )}

        {!permanentEmpty && !isCitizen && (
          <>
            <MuiGrid item>
              <SwitchInput label={t("Profile.form.permanentResident")} name="isPermanentResident" />
            </MuiGrid>
            {isPermanentResident && (
              <MuiGrid container direction="column" item>
                {isVisible("Workerinfo.permanent_duration_from") && (
                  <MuiGrid item>
                    <DateInput
                      key="permanent-from"
                      label={t("Profile.form.permanentResidentFrom")}
                      name="permanentResident.from"
                      nullable
                      required={isRequired("Workerinfo.permanent_duration_from")}
                    />
                  </MuiGrid>
                )}
                {isVisible("Workerinfo.permanent_duration_to") && (
                  <MuiGrid item>
                    <DateInput
                      key="permanent-to"
                      label={t("Profile.form.permanentResidentTo")}
                      name="permanentResident.to"
                      nullable
                      required={isRequired("Workerinfo.permanent_duration_to")}
                    />
                  </MuiGrid>
                )}
              </MuiGrid>
            )}
          </>
        )}
      </MuiGrid>
    ),
    [filled, total, errors, fields]
  );

  if (empty) {
    return null;
  }

  return (
    <ProfileSettingAccordionItem
      error={errors > 0}
      expanded={expanded}
      filled={filled}
      label={t("Profile.display.residence")}
      onExpansionChange={onExpansionChange}
      total={total}
    >
      {content}
    </ProfileSettingAccordionItem>
  );
}
