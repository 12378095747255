import { Box, Grid as MuiGrid } from "@material-ui/core";
import { mdiClose, mdiPencil } from "@mdi/js";
import { ClosingProgress, Fk, PositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, IconButton, Toolbar, useSpacing } from "@sinch/ui";
import { useToggleState } from "@sinch/utils";
import { includes } from "ramda";
import React, { ReactElement, useState } from "react";
import { SetClosingStateDialog, SetStateButtons, StateMenuProps } from "../../components";
import { useSelectionSet } from "../../context";
import { FullEdit, FullEditProps } from "../../Detail";
import { EditMenu } from "./EditMenu";

interface SelectionMenuProps {
  closingStatus: string | number;
}

export function SelectionMenu({ closingStatus }: SelectionMenuProps): ReactElement {
  const [, inner, data] = useSpacing();
  const { selected, setSelected } = useSelectionSet<Fk<PositionAttendance>>();
  const [show, , setEditVisibility] = useToggleState();
  const handleEdit = () => {
    setEditVisibility(true);
  };

  const [stateDialog, setStateDialog] = useState<{ status: ClosingProgress; inputName?: string } | undefined>();
  const handleSetChangeState: StateMenuProps["onSetState"] = (status, inputName) => {
    setStateDialog({ status, inputName });
  };

  const handleDialogClose = () => setStateDialog(undefined);

  let editItems: FullEditProps["items"] = [];
  if (closingStatus === ClosingProgress.Present) {
    editItems = ["startTime"];
  } else if (closingStatus === ClosingProgress.Break) {
    editItems = ["breakStart"];
  } else if (closingStatus === ClosingProgress.AfterBreak) {
    editItems = ["breakStart", "breakEnd"];
  } else if (closingStatus === ClosingProgress.Finished) {
    editItems = ["startTime", "endTime", "breakStart", "breakEnd"];
  }

  return (
    <Toolbar position="bottom">
      <Box px={data} py={inner} width="100%">
        <MuiGrid container direction="column" spacing={inner}>
          <MuiGrid alignItems="center" container item justify="space-between">
            <MuiGrid item>
              {t("selectedWorkers")}: {selected.length}
            </MuiGrid>
            <MuiGrid item>
              <IconButton action={() => setSelected([])} icon={mdiClose} />
            </MuiGrid>
          </MuiGrid>
          {!show && (
            <>
              <MuiGrid item>
                <SetStateButtons closingStatus={closingStatus} onSetState={handleSetChangeState} />
              </MuiGrid>
              {closingStatus !== ClosingProgress.Pending && (
                <MuiGrid item>
                  <MuiGrid container item justify="space-between">
                    {includes(closingStatus, [
                      ClosingProgress.Present,
                      ClosingProgress.Break,
                      ClosingProgress.Finished,
                    ]) && (
                      <MuiGrid item xs>
                        <Button action={handleEdit} color="primary" icon={mdiPencil} stretch variant="text">
                          {t("editTime")}
                        </Button>
                      </MuiGrid>
                    )}
                    <MuiGrid item>
                      <EditMenu attendances={selected} target={closingStatus} />
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              )}
            </>
          )}
          {show && (
            <MuiGrid item>
              <FullEdit
                attendance={selected}
                hasBreak={false}
                items={editItems}
                onCancel={() => setEditVisibility(false)}
              />
            </MuiGrid>
          )}
        </MuiGrid>
        {stateDialog && <SetClosingStateDialog {...stateDialog} onClose={handleDialogClose} selected={selected} />}
      </Box>
    </Toolbar>
  );
}
