import { Theme } from "@material-ui/core";
import { Mixins } from "@material-ui/core/styles/createMixins";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Transform } from "@sinch/types";

export function createMixins({ breakpoints, spacing }: Theme): Mixins {
  const onLarge: Transform<CSSProperties> = (props) => ({
    [breakpoints.up("md")]: props,
  });

  const onSmall: Transform<CSSProperties> = (props: CSSProperties) => ({
    [breakpoints.down("xs")]: props,
  });

  return {
    component: {
      titleBar: {
        large: {
          height: spacing(8),
        },
        small: {
          height: spacing(7),
        },
      },
      toolBar: {
        height: spacing(7),
      },
    },
    onLarge,
    onSmall,

    /** @deprecated overriding default material values to prevent usage */
    toolbar: {},
    /** @deprecated overriding default material helper to prevent usage */
    gutters: () => {
      console.warn("Theme.mixins.gutters() is deprecated.");
      return {};
    },
  };
}

declare module "@material-ui/core/styles/createMixins" {
  interface Mixins {
    component: {
      titleBar: Record<"large" | "small", { height: number }>;

      /**
       * todo: maybe is redundant - consider merging into toolbar as
       *  { small.height, large.height, style }
       */
      toolBar: {
        height: number;
      };
    };

    onLarge: Transform<CSSProperties>;

    onSmall: Transform<CSSProperties>;
  }
}
