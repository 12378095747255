import { t } from "@sinch/intl";
import { Tabs } from "@sinch/ui";
import React, { ReactElement } from "react";
import { PositionSearchForm } from "./PositionSearchForm";
import { RecentSearches } from "./RecentSearches";

/**
 * todo: embed tab state into location state so recent searches stay opened
 *  when navigating back
 */
export function PositionSearch(): ReactElement {
  return (
    <Tabs
      tabs={[
        {
          element: <PositionSearchForm />,
          label: t("searchShifts"),
          persist: true,
        },
        {
          element: <RecentSearches />,
          label: t("Position.display.recentSearches"),
        },
      ]}
    />
  );
}
