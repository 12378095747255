import { mdiAccountMultipleOutline, mdiBagPersonal, mdiFileDocument, mdiShield, mdiStar } from "@mdi/js";
import { selectSnippet, Snippet } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Card, GridLayout, TabsNavigation, Text } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { isPast } from "date-fns";
import { filter, isEmpty, isNil, propEq } from "ramda";
import React from "react";
import { useData } from "../../../core";
import { Notice } from "../../Dashboard/components";
import { PhoneVerificationStatus, requestProfileStatus } from "./api";
import { BadgeList, BadgeListContainer } from "./BadgeList";
import { ContractList, ContractListContainer } from "./ContractList";
import { PersonalAttributes, PersonalContainer } from "./PersonalAttributes";
import { ProfileAttributeListContainer, ProfileAttributes } from "./ProfileAttributes";
import { ProfileStatus } from "./ProfileStatus";
import { DesktopRating, RatingListContainer } from "./Rating";

export function ProfileContent() {
  const { selectResult, selectEntity } = useData(requestProfileStatus);
  const { attributeExpiration, phoneVerificationStatus, availableProfileAttributesCount } = selectResult();
  const snippets = selectEntity(selectSnippet({}));
  return (
    <GridLayout spacing="outer">
      {filter<Snippet>(propEq("name", "profile_view"), snippets).map(({ color, body, id, style }, idx) => (
        <GridLayout.Item>
          <Notice key={id ?? idx} body={body} color={color} id={id} style={style} />
        </GridLayout.Item>
      ))}
      <ProfileStatus />
      <GridLayout.Item>
        <Card>
          <TabsNavigation
            replace
            tabs={rejectFalsy([
              {
                element: (
                  <PersonalContainer>
                    <PersonalAttributes edit="personal" />
                  </PersonalContainer>
                ),
                icon: mdiAccountMultipleOutline,
                label: t("Profile.display.personalAttributes"),
                path: "personal",
                badge:
                  isNil(phoneVerificationStatus) || phoneVerificationStatus === PhoneVerificationStatus.Verified
                    ? isNil(attributeExpiration?.personalAttribute) || isEmpty(attributeExpiration?.personalAttribute)
                      ? undefined
                      : {
                          // @ts-ignore
                          color: isPast(attributeExpiration?.personalAttribute.expiration) ? "error" : "warning",
                          badgeContent: <Text bold>!</Text>,
                        }
                    : {
                        // @ts-ignore
                        color: "error",
                        badgeContent: <Text bold>!</Text>,
                      },
              },
              availableProfileAttributesCount > 0 && {
                element: (
                  <ProfileAttributeListContainer>
                    <ProfileAttributes edit="attributes" />
                  </ProfileAttributeListContainer>
                ),
                icon: mdiBagPersonal,
                label: t("Profile.display.profileAttributes"),
                path: "attributes",
                badge:
                  isNil(attributeExpiration?.profileAttribute) || isEmpty(attributeExpiration?.profileAttribute)
                    ? undefined
                    : {
                        // @ts-ignore
                        color: isPast(attributeExpiration?.profileAttribute.expiration) ? "error" : "warning",
                        badgeContent: <Text bold>!</Text>,
                      },
              },
              {
                element: (
                  <ContractListContainer>
                    <ContractList />
                  </ContractListContainer>
                ),
                icon: mdiFileDocument,
                label: t("Contract.titlePlural"),
                path: "contracts",
              },
              {
                element: (
                  <RatingListContainer>
                    <DesktopRating />
                  </RatingListContainer>
                ),
                icon: mdiStar,
                label: t("Rating.title"),
                path: "rating",
              },
              {
                element: (
                  <BadgeListContainer>
                    <BadgeList />
                  </BadgeListContainer>
                ),
                icon: mdiShield,
                label: t("Badge.titlePlural"),
                path: "badges",
              },
            ])}
          />
        </Card>
      </GridLayout.Item>
    </GridLayout>
  );
}
