import MuiGrid from "@material-ui/core/Grid";
import { mdiBriefcase, mdiCarArrowLeft, mdiCarArrowRight } from "@mdi/js";

/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { useBusinessRules, useData } from "@sinch/core";
import {
  Fk,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  Shift,
  sortPosition,
  Transport,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Box, Flex, Grid, Paper, Tabs, Text } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { isEmpty } from "ramda";
import React, { ReactElement, useState } from "react";
import { requestShiftAttendanceView } from "./api";
import { CardShiftHeader } from "./components";
import { PositionAttendances } from "./PositionAttendances";
import { PositionEditDialog, TransportGroupEditDialog } from "./PositionEditDialog";
import { TransportAttendances } from "./TransportAttendances";

function Labels(): ReactElement {
  const { shiftClosingWorkBreaks } = useBusinessRules();
  return (
    <Flex>
      <Flex grow={1}>
        <Text small>{t("worker", { count: 1 })}</Text>
      </Flex>
      <Box width={76}>
        <MuiGrid container spacing={1}>
          <MuiGrid item xs={3}>
            <Text small>{t("Shift.closingArrival")}</Text>
          </MuiGrid>
          <MuiGrid item xs={3}>
            <Text small>{t("Shift.closingDeparture")}</Text>
          </MuiGrid>
          {shiftClosingWorkBreaks && (
            <MuiGrid item xs={1}>
              <Text />
            </MuiGrid>
          )}
          <MuiGrid item xs>
            <Text small>{t("Shift.closingPresence")}</Text>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <Text small>{t("Position.role.title")}</Text>
          </MuiGrid>
        </MuiGrid>
      </Box>
    </Flex>
  );
}

interface WorkTabProps {
  shift: Fk<Shift>;
}

function WorkTab({ shift }: WorkTabProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);

  const positions = sortPosition(selectEntity(selectPosition({ shift })))
    .map((shift) => shift.id)
    .filter((id) => !isEmpty(selectEntity(selectPositionAttendance({ position: id }, "id"))));

  const [groupEdit, setGroupEdit] = useState<number[] | null>(null);

  return (
    <>
      <Grid spacing="data" vertical>
        <CardShiftHeader action={() => setGroupEdit(positions)} title={t("Shift.closing.timesheet")} />
        <Labels />
        {positions.map((position) => (
          <React.Fragment key={position}>
            <PositionAttendances openDialog={() => setGroupEdit([position])} position={position} />
          </React.Fragment>
        ))}
      </Grid>
      {groupEdit && <PositionEditDialog onClose={() => setGroupEdit(null)} positions={groupEdit} />}
    </>
  );
}

function TransportLabels(): ReactElement {
  const { shiftClosingCloseDistance } = useBusinessRules();
  return (
    <MuiGrid container spacing={1}>
      <MuiGrid item xs={4}>
        <Text small>{t("worker", { count: 1 })}</Text>
      </MuiGrid>
      <MuiGrid item xs>
        <Text small>{t("Shift.closingTransportDeparture")}</Text>
      </MuiGrid>
      <MuiGrid item xs>
        <Text small>{t("Shift.closingTransportArrival")}</Text>
      </MuiGrid>
      {shiftClosingCloseDistance && (
        <MuiGrid item xs>
          <Box px={0.5} width={12}>
            <Text small>{t("Transport.distance")}</Text>
          </Box>
        </MuiGrid>
      )}
      <MuiGrid item xs>
        <Box px={0.5} width={21}>
          <Text small>{t("Transport.role.title")}</Text>
        </Box>
      </MuiGrid>
    </MuiGrid>
  );
}

interface TransportTabProps {
  shift: Fk<Shift>;

  transport: Fk<Transport>;
}

function TransportTab({ transport, shift }: TransportTabProps): ReactElement {
  const [groupEdit, setGroupEdit] = useState<number[] | null>(null);

  const { selectEntity } = useData(requestShiftAttendanceView);
  const positions = sortPosition(selectEntity(selectPosition({ shift })))
    .map((shift) => shift.id)
    .filter((id) => !isEmpty(selectEntity(selectPositionAttendance({ position: id }, "id"))));

  return (
    <>
      <Grid spacing="data" vertical>
        <CardShiftHeader action={() => setGroupEdit(positions)} title={t("display.transport")} />
        <TransportLabels />
        {positions.map((position) => (
          <TransportAttendances
            key={position}
            openDialogAction={() => setGroupEdit([position])}
            position={position}
            transport={transport}
          />
        ))}
      </Grid>
      {groupEdit && (
        <TransportGroupEditDialog onClose={() => setGroupEdit(null)} positions={groupEdit} transport={transport} />
      )}
    </>
  );
}

interface AttendanceFormProps {
  shift: Fk<Shift>;
}

export function AttendanceForm({ shift }: AttendanceFormProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);

  const transport = selectEntity(selectShift(shift, "transport"));
  const { from = null, to = null } = transport ?? {};
  return (
    <Paper>
      <Tabs
        tabs={rejectFalsy([
          {
            element: <WorkTab shift={shift} />,
            icon: mdiBriefcase,
            label: t("work"),
          },
          to && {
            element: <TransportTab shift={shift} transport={to} />,
            icon: mdiCarArrowRight,
            label: t("Transport.there"),
          },
          from && {
            element: <TransportTab shift={shift} transport={from} />,
            icon: mdiCarArrowLeft,
            label: t("Transport.back"),
          },
        ])}
      />
    </Paper>
  );
}
