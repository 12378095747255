import { propEq } from "ramda";
import { Color, IconId, Key } from "../primitives";

/**
 * todo: consider extracting to separate `fields` package
 *
 * @overview
 */

export enum FieldType {
  /**
   * Numeric value optionally constrained by min/max and step.
   */
  Number = 0,

  /**
   * Textual value optionally constrained by min/max length
   * and regexp pattern.
   */
  Text = 1,

  /**
   * True or false.
   */
  Bool = 2,

  /**
   * Single value selected from given options.
   */
  Select = 3,

  /**
   * Multiple values selected from given options.
   */
  MultiSelect = 4,

  /**
   * Select
   */
  Range = 5,

  /**
   * Reference to file uploaded on the server.
   */
  File = 6,

  /**
   * Reference to image file uploaded on the server.
   */
  Image = 7,

  /**
   * Reference to multiple image files uploaded on the server.
   */
  Gallery = 8,

  /**
   * Long textual value optionally constrained by min/max length
   * and regexp pattern.
   */
  LongText = 9,
}

export interface FieldParams {
  disabled?: boolean;
}

export interface NumberFieldParams extends FieldParams {
  min?: number;

  max?: number;

  step?: number;
}

export interface TextFieldParams extends FieldParams {
  minLength?: number;

  maxLength?: number;

  pattern?: string;
}

export interface SelectFieldOption extends FieldParams {
  color?: Color;

  icon?: IconId;

  label: string;

  secondaryLabel?: string;

  value: Key | boolean;
}

export interface SelectFieldParams extends FieldParams {
  options: SelectFieldOption[];
}

export interface FileFieldParams extends FieldParams {
  accept?: string;

  multiple?: boolean;

  /**
   * Can be defined as single number representing size in bytes or as
   * number-string tuple representing size and order (eg. [2, "M"] for 2MB).
   */
  maxSize?: number | [size: number, order: string];
}

export interface ImageFieldParams extends FileFieldParams {
  minDimensions?: [width: number, height: number];

  maxDimensions?: [width: number, height: number];
}

/**
 * Interface for describing composite and/or dynamically created fields,
 * generally used for data which are not directly associated to any named
 * database column.
 *
 * Examples:
 * - Worker Attributes are defined in Agency administration (dynamic fields)
 * - Bank account is sent as individual segments to the client, but stored as
 *   single value on the server (composite field)
 */
export interface Field<
  TType extends FieldType = FieldType,
  TParams extends FieldParams = FieldParams,
  TValue = unknown
> {
  /**
   * Field data identifier provided by server.
   *
   * Key is assigned to associated form input element and then used to
   * generate key-value record for submitting data back to server.
   */
  key: Key;

  /**
   * Textual representation of field name visible to user.
   */
  name: string;

  /**
   * Type of data contained in this field.
   *
   * Decides which component is used to display the data and controls
   * associated form input component when editing it.
   *
   * Each type supports different params used for client side validation.
   */
  type: TType;

  /**
   * Data contained in this field.
   *
   * Value type is different for each supported {@link FieldType}.
   *
   * Field needs to be casted based on associated type prop in order
   * to access the value in typesafe manner.
   */
  value: TValue;

  /**
   * Params configuring associated form input element and data validation
   * (not needed to display the data in read-only mode).
   *
   * Params shape is different for each supported {@link FieldType}.
   *
   * Field needs to be casted based on associated type prop in order
   * to access params in typesafe manner.
   */
  params: TParams;
}

/**
 * Extracts props needed to display Field data (name, type and value).
 */
export type FieldDisplayData<T extends Field = Field> = Pick<T, "name" | "type" | "value">;

/**
 * Extracts props needed to submit Field data (key and value).
 */
export type FieldSubmitData<T extends Field = Field> = Pick<T, "key" | "value">;

/**
 * Extracts type of given {@link Field.params}.
 */
export type FieldParamsType<T extends Field> = T["params"];

/**
 * Extracts type of given {@link Field.value}.
 */
export type FieldValueType<T extends Field> = T["value"];

/**
 * Associates Field keys to values for submitting data to server.
 */
export type FieldSubmitDataRecord = Record<Key, unknown>;

export type UnknownField = Field;

export type NumberField = Field<FieldType.Number, NumberFieldParams, number>;

export type TextField = Field<FieldType.Text, TextFieldParams, string>;

export type BoolField = Field<FieldType.Bool, FieldParams, boolean>;

export type SelectField = Field<FieldType.Select, SelectFieldParams, Key>;

export type MultiSelectField = Field<FieldType.MultiSelect, SelectFieldParams, Key[]>;

export type RangeField = Field<FieldType.Range, NumberFieldParams, number>;

export type FileField = Field<FieldType.File, FileFieldParams, string>;

export type ImageField = Field<FieldType.Image, ImageFieldParams, string>;

export type GalleryField = Field<FieldType.Gallery, ImageFieldParams, string[]>;

export type LongTextField = Field<FieldType.LongText, TextFieldParams, string>;

type FieldTypePredicate<T extends Field> = (field: Field) => field is T;

function isFieldType<T extends Field>(type: T["type"]): FieldTypePredicate<T> {
  /* @ts-expect-error */
  return propEq("type", type);
}

export const isNumberField = isFieldType<NumberField>(FieldType.Number);

export const isTextField = isFieldType<TextField>(FieldType.Text);

export const isBoolField = isFieldType<BoolField>(FieldType.Bool);

export const isSelectField = isFieldType<SelectField>(FieldType.Select);

export const isMultiSelectField = isFieldType<MultiSelectField>(FieldType.MultiSelect);

export const isRangeField = isFieldType<RangeField>(FieldType.Range);

export const isFileField = isFieldType<FileField>(FieldType.File);

export const isImageField = isFieldType<ImageField>(FieldType.Image);

export const isGalleryField = isFieldType<GalleryField>(FieldType.Gallery);

export const isLongTextField = isFieldType<LongTextField>(FieldType.LongText);
