import TextField from "@material-ui/core/TextField";
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from "@material-ui/lab/Autocomplete";
import { BiConsumer, Key, Nullable } from "@sinch/types";
import { identity } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";

export interface AutocompleteInputMultipleProps<TOpt = Key> extends ManagedInputProps {
  getOptionLabel: (option: TOpt) => string;

  getOptionSelected: (option: TOpt, value: TOpt) => boolean;

  onInputChange: (value: string, reason: AutocompleteInputChangeReason) => void;

  options: TOpt[];

  renderOption: (option: TOpt) => ReactNode;

  multiple?: boolean;

  noOptionsText?: ReactNode;
}

export function AutocompleteMultipleInput<TOpt = Key>(props: AutocompleteInputMultipleProps<TOpt>): ReactElement {
  const [baseProps, { setValue }] = useFormField<Nullable<TOpt | TOpt[]>, AutocompleteInputMultipleProps<TOpt>>(props);

  return (
    <AutocompleteMultipleInputBase<TOpt>
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      onChange={setValue}
    />
  );
}

export function AutocompleteMultipleInputBase<TOpt = Key>({
  dense,
  disabled,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  error,
  getOptionLabel,
  getOptionSelected,
  label,
  name,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  note,
  onChange,
  onInputChange,
  onInvalid,
  options,
  placeholder,
  renderOption,
  value,
  multiple,
  noOptionsText,
}: BaseInputProps<
  Nullable<TOpt | TOpt[]>,
  AutocompleteInputMultipleProps<TOpt>,
  BiConsumer<Nullable<TOpt | TOpt[]>, AutocompleteChangeReason>
>): ReactElement {
  return (
    <Autocomplete<TOpt, boolean>
      clearOnBlur={false}
      clearOnEscape
      disabled={disabled}
      filterOptions={identity}
      fullWidth
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      multiple={multiple}
      noOptionsText={noOptionsText}
      onChange={(e, val, reason) => onChange(val, reason)}
      onInputChange={(e, val, reason) => {
        onInputChange(val, reason);
      }}
      onInvalid={onInvalid}
      options={options}
      renderInput={(inputParams) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...inputParams}
          error={error}
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
          margin={dense ? "dense" : "normal"}
          name={name}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
      renderOption={renderOption}
      value={value}
    />
  );
}
