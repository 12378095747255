import { useTitleBar } from "@sinch/ui";
import React from "react";
import { useSwiperSlide } from "swiper/react";

export function SwipeUpdateTitle({ title }: { title: string }): null {
  const { isActive } = useSwiperSlide();
  useTitleBar({
    container: "dashboard:finished",
    locationTitle: isActive ? title : undefined,
  });
  return null;
}
