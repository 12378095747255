import { defineRequest } from "@sinch/core";
import { FileHash } from "@sinch/types";
import { mapObjIndexed, pick, pipe } from "ramda";
import { CropData } from "../../../components";

type CropParams = Pick<CropData, "height" | "width" | "x" | "y">;

const getCropParams = pipe<CropData, CropParams, CropParams>(
  pick(["height", "width", "x", "y"]),
  mapObjIndexed(Math.round)
);

export const requestProfilePortraitUpdate = defineRequest("Profiles/PortraitUpdate")
  .withParams<{ file: FileHash; crop: CropParams }>()
  .withEmptyResponse()
  .withCreator((file: FileHash, crop: CropData) => ({
    params: { file, crop: getCropParams(crop) },
  }));

/**
 * todo: update target key to "worker/Profiles/PortraitUpdate/image"
 */
export const ProfilePortraitImageTarget = "worker/Profiles/PortraitUpdateFile";
