import { MuiThemeProvider } from "@material-ui/core";
import { DataProvider, responseHandlerKey, responseHandlerSequence } from "@sinch/core";
import { ClosingProgress, Fk, Position, PositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { Button, darkTheme, useSnackbar } from "@sinch/ui";
import { useSnackbar as useNotistack } from "notistack";
import { apply, equals, fromPairs, head, map, pipe, pluck, prop, props, uniq } from "ramda";
import React, { ReactElement, useMemo } from "react";
import {
  requestShiftNoteEdit,
  requestShiftPresenceAbsent,
  requestShiftPresenceArrived,
  requestShiftPresenceBreak,
  requestShiftPresenceBreakEnd,
  requestShiftPresenceFinished,
  requestShiftPresenceMoveToPosition,
  requestShiftPresencePending,
  requestShiftPresenceResetBreak,
} from "../api";
import { useUpdatePresence } from "../hooks";

interface RollbackProps {
  refresh: Callback;
  positionAttendances: PositionAttendance[];
  position?: Fk<Position>;
}

export function PresenceRollback({ refresh, positionAttendances, position }: RollbackProps): ReactElement {
  const snackbar = useSnackbar();
  const { closeSnackbar } = useNotistack();

  function onSuccess() {
    snackbar("default", t("Shift.presenceUndoSuccess"));
    closeSnackbar();
    refresh();
  }

  function onError() {
    snackbar("error", t("Shift.presenceFailed"));
    refresh();
  }

  const responseHandler = useMemo(
    () =>
      responseHandlerSequence<any>([
        responseHandlerKey(requestShiftPresenceArrived, onSuccess, onError),
        responseHandlerKey(requestShiftPresencePending, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceBreak, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceBreakEnd, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceAbsent, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceFinished, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceResetBreak, onSuccess, onError),
        responseHandlerKey(requestShiftPresenceMoveToPosition, onSuccess, onError),
        ///
        responseHandlerKey(requestShiftNoteEdit, onSuccess, onError),
      ]),
    [refresh, snackbar, closeSnackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <UndoButton attendancesToRollback={positionAttendances} position={position} />
    </DataProvider>
  );
}

function UndoButton({
  attendancesToRollback,
  position,
}: {
  attendancesToRollback: PositionAttendance[];
  position?: Fk<Position>;
}): ReactElement | null {
  const getPair = (key: string): Record<Fk<PositionAttendance>, Date> =>
    pipe(map(props(["id", key])), fromPairs)(attendancesToRollback);
  const toMap = (obj) => new Map(Object.entries(obj));
  const { setAbsent, setArrival, setBreak, setBreakEnd, setPending, setFinished, moveToPosition } = useUpdatePresence(
    pluck("id", attendancesToRollback)
  );

  const previousProgressState = pipe(pluck("progressStatus"), uniq)(attendancesToRollback);
  if (previousProgressState.length !== 1) {
    console.error("Unconsistent progress state change!");
    return null;
  }

  const previousPositionIdMatch = !position || pipe(head, prop("position"), equals(position))(attendancesToRollback);

  function handleUndo() {
    if (!previousPositionIdMatch) {
      apply(moveToPosition, pipe(head, props(["position", "startTime", "progressStatus"]))(attendancesToRollback));
      return;
    }

    switch (previousProgressState[0]) {
      case ClosingProgress.Pending:
        setPending();
        break;
      case ClosingProgress.Present:
        setArrival(toMap(getPair("startTime")));
        break;
      case ClosingProgress.Absent:
        setAbsent();
        break;
      case ClosingProgress.Break:
        setBreak(toMap(pluck("startTime", getPair("workBreak"))));
        break;
      case ClosingProgress.AfterBreak:
        setBreakEnd(toMap(pluck("endTime", getPair("workBreak"))));
        break;
      case ClosingProgress.Finished:
        setFinished(toMap(getPair("endTime")));
        break;
      default:
        break;
    }
  }

  return (
    <MuiThemeProvider theme={darkTheme}>
      <Button action={handleUndo} color="default" variant="text">
        {t("undo")}
      </Button>
    </MuiThemeProvider>
  );
}
