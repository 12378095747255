export type NumberFormatVariant = "common" | "decShort";

export type FormatNumber = (value: number) => string;

export function numberFormatter(locale: string): FormatNumber & Record<NumberFormatVariant, FormatNumber> {
  const formatter = new Intl.NumberFormat(locale);
  const hours = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  });
  return Object.assign((value: number) => formatter.format(value), {
    common: formatter.format,
    decShort: hours.format,
  });
}
