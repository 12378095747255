import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";

const useStyles = makeStyles(() => ({
  dense: {
    padding: 0,
  },
}));

interface CheckboxInputProps extends Omit<ManagedInputProps, "dense" | "placeholder"> {
  indeterminate?: boolean;
  dense?: boolean;
}

export function CheckboxInput(props: CheckboxInputProps): ReactElement {
  const [baseProps] = useFormField<boolean, CheckboxInputProps>(props);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <CheckboxInputBase {...baseProps} />;
}

export function CheckboxInputBase({
  disabled,
  error,
  indeterminate,
  label,
  name,
  note,
  onChange,
  onInvalid,
  value,
  dense,
}: BaseInputProps<boolean, CheckboxInputProps>): ReactElement {
  const styles = useStyles();
  const checkbox = (
    <Checkbox
      className={clsx(dense && styles.dense)}
      color="primary"
      checked={value}
      indeterminate={indeterminate}
      name={name}
      onChange={onChange}
      onInvalid={onInvalid}
    />
  );

  return (
    <FormControl disabled={disabled} error={error}>
      {label ? <FormControlLabel control={checkbox} label={label} name={name} /> : checkbox}
      {note && <FormHelperText>{note}</FormHelperText>}
    </FormControl>
  );
}
