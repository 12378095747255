/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { Box as MuiBox } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useBusinessRules, useData, useInstanceSettings } from "@sinch/core";
import {
  Fk,
  selectTransportAttendance,
  selectWorker,
  TransportAttendance,
  TransportAttendanceRole,
} from "@sinch/entity";
import { NumberInput, SelectDateTimeInput, SelectInput, useFormValues } from "@sinch/forms";
import { Avatar, BorderColorListItem, Box, Text } from "@sinch/ui";
import clsx from "clsx";
import { equals, values } from "ramda";
import React, { ReactElement } from "react";
import { AttendanceClosingFormState, requestShiftAttendanceView } from "./api";
import { TransportRoleOptions } from "./options";

// todo: extract to ui package
const useStyles = makeStyles((theme) => ({
  rowNoDriver: {
    backgroundColor: theme.palette.grey[200],
  },
  fillRow: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}));

interface WorkerTransportAttendanceProps {
  attendance: Fk<TransportAttendance>;
}

export function WorkerTransportAttendance({ attendance }: WorkerTransportAttendanceProps): ReactElement {
  const styles = useStyles();
  const { distanceUnit } = useInstanceSettings();
  const { shiftClosingCloseDistance } = useBusinessRules();

  const { selectEntity } = useData(requestShiftAttendanceView);

  const { worker } = selectEntity(selectTransportAttendance(attendance));

  const { avatar, name } = selectEntity(selectWorker(worker));

  const {
    transportAttendance: {
      [attendance]: { role },
    },
  } = useFormValues<AttendanceClosingFormState>();

  const hasRole = !equals(TransportAttendanceRole.NoTransport, role);

  return (
    <BorderColorListItem color={TransportRoleOptions()[role].color}>
      <div className={clsx(!hasRole && styles.rowNoDriver, styles.fillRow)}>
        <MuiGrid alignItems="center" container spacing={1} wrap="nowrap">
          <MuiGrid item xs={4}>
            <MuiBox alignItems="flex-start" display="flex" flexGrow={2} pl={2}>
              <MuiGrid container spacing={1} wrap="nowrap">
                <MuiGrid alignItems="center" container item spacing={1} wrap="nowrap">
                  <MuiGrid item>
                    <Avatar
                      file={{ file: avatar as string, variant: "thumbnail_200x200" }}
                      withPreview="thumbnail_500x500"
                    />
                  </MuiGrid>
                  <MuiGrid item>
                    <Text>{name}</Text>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
          </MuiGrid>

          <MuiGrid item xs>
            <Box px={0.5} visibility={hasRole ? "visible" : "hidden"}>
              <SelectDateTimeInput dense name={`transportAttendance.${attendance}.startTime`} />
            </Box>
          </MuiGrid>
          <MuiGrid item xs>
            <Box px={0.5} visibility={hasRole ? "visible" : "hidden"}>
              <SelectDateTimeInput dense name={`transportAttendance.${attendance}.endTime`} />
            </Box>
          </MuiGrid>
          {shiftClosingCloseDistance && (
            <MuiGrid item xs>
              <Box px={0.5} visibility={hasRole ? "visible" : "hidden"} width={13}>
                <NumberInput dense name={`transportAttendance.${attendance}.distance`} suffix={distanceUnit} />
              </Box>
            </MuiGrid>
          )}
          <MuiGrid item xs>
            <Box px={0.5} width={21}>
              <SelectInput
                dense
                name={`transportAttendance.${attendance}.role`}
                options={values(TransportRoleOptions())}
              />
            </Box>
          </MuiGrid>
        </MuiGrid>
      </div>
    </BorderColorListItem>
  );
}
