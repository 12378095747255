import { AccordionProps } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useInstanceSettings } from "@sinch/core";
import { DateInput, SelectInput, TextInput } from "@sinch/forms";
import { t, useIntl } from "@sinch/intl";
import { rejectFalsy } from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";
import { Sex } from "../api";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { countryOptions } from "../countries";
import { useSystemAttributes } from "../hooks";
import { useFieldListMeta } from "../utils";

interface EditBasicProfileParams {
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
}

export function EditBasicProfile({ expanded, onExpansionChange }: EditBasicProfileParams): ReactElement | null {
  const { locale } = useIntl();
  const { country } = useInstanceSettings();
  const { isBlockEmpty, isVisible, isRequired } = useSystemAttributes();
  const { filled, total, errors, fields } = useFieldListMeta(
    rejectFalsy([
      `firstName`,
      `lastName`,
      isVisible("Workerinfo.sex") && `sex`,
      isVisible("User.birthdate") && `birthDate`,
      isVisible("Workerinfo.citizenship") && `citizenship`,
    ])
  );

  const empty = isBlockEmpty([
    "User.name",
    "User.surname",
    "Workerinfo.sex",
    "User.birthdate",
    "Workerinfo.citizenship",
  ]);

  const content = useMemo(
    () => (
      <MuiGrid container direction="column">
        {isVisible("User.name") && (
          <MuiGrid item>
            <TextInput
              key="firstName"
              label={t("field.firstName")}
              name="firstName"
              placeholder={t("field.firstName")}
              required={isRequired("User.name")}
            />
          </MuiGrid>
        )}
        {isVisible("User.surname") && (
          <MuiGrid item>
            <TextInput
              key="lastName"
              label={t("field.lastName")}
              name="lastName"
              placeholder={t("field.lastName")}
              required={isRequired("User.surname")}
            />
          </MuiGrid>
        )}
        {isVisible("Workerinfo.sex") && (
          <MuiGrid item>
            <SelectInput
              key="sex"
              label={t("Worker.gender")}
              name="sex"
              nullable
              options={[
                { value: Sex.Male, label: t("Worker.genderMale") },
                {
                  value: Sex.Female,
                  label: t("Worker.genderFemale"),
                },
                {
                  value: Sex.NotSpecified,
                  label: t("Worker.genderUnspecified"),
                },
              ]}
              placeholder={t("Worker.gender")}
              required={isRequired("Workerinfo.sex")}
            />
          </MuiGrid>
        )}
        {isVisible("User.birthdate") && (
          <MuiGrid item>
            <DateInput
              key="birthDate"
              label={t("Worker.birthDate")}
              name="birthDate"
              required={isRequired("User.birthdate")}
            />
          </MuiGrid>
        )}
        {isVisible("Workerinfo.citizenship") && (
          <MuiGrid item>
            <SelectInput
              key="citizenship"
              label={t("Worker.nationality")}
              name="citizenship"
              nullable
              options={countryOptions(locale, country)}
              placeholder={t("Worker.nationality")}
              required={isRequired("Workerinfo.citizenship")}
            />
          </MuiGrid>
        )}
      </MuiGrid>
    ),
    [filled, total, errors, fields]
  );

  if (empty) {
    return null;
  }

  return (
    <ProfileSettingAccordionItem
      error={errors > 0}
      expanded={expanded}
      filled={filled}
      label={t("Profile.display.basicInfo")}
      onExpansionChange={onExpansionChange}
      total={total}
    >
      {content}
    </ProfileSettingAccordionItem>
  );
}
