import { Box, lighten } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { mdiFileDownloadOutline, mdiHumanGreetingVariant } from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import { PositionQ, selectPosition, selectPositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Button,
  externalLink,
  Grid,
  GridLayout,
  Icon,
  Toolbar,
  TwoColumnLayout,
  useMobileLayout,
  useSpacing,
} from "@sinch/ui";
import { head, isEmpty, isNil, values } from "ramda";
import { ensureArray, isNilOrEmpty, isNotEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { HibernationStatusStrip } from "../../components";
import { AttendanceConfirmButtons } from "../../components/AttendanceConfirmButtons";
import { requestPositionDetail } from "./api";
import { Reimbursements } from "./components";
import { WorkerFiles } from "./components/WorkerFiles";
import { PositionDetailAcceptForm } from "./PositionDetailAcceptForm";
import { PositionDetailCapacity } from "./PositionDetailCapacity";
import { PositionDetailConnectedPositionsList } from "./PositionDetailConnectedPositionsList";
import { PositionDetailContacts } from "./PositionDetailContacts";
import { PositionDetailDescription } from "./PositionDetailDescription";
import { PositionDetailFeedback } from "./PositionDetailFeedback";
import { PositionDetailHeader } from "./PositionDetailHeader";
import { PositionDetailNotices } from "./PositionDetailNotices";
import { PositionDetailParametersList } from "./PositionDetailParametersList";
import { PositionDetailRequirements } from "./PositionDetailRequirements";
import { PositionDetailResults } from "./PositionDetailResults";
import { PositionDetailStatus } from "./PositionDetailStatus";
import { PositionDetailTransport } from "./PositionDetailTransport";
import { PositionDetailWorkers } from "./PositionDetailWorkers";

const useStyles = makeStyles((theme) => ({
  hideEmpty: {
    "&.MuiGrid-container > .MuiGrid-item:empty": {
      display: "none",
    },
  },
  backgroundWarning: {
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    height: "auto",
  },
}));

export function PositionDetail(): ReactElement {
  const mobile = useMobileLayout();
  const styles = useStyles();
  const {
    id: workerId,
    restrictions: { hibernated },
  } = useCurrentUser();
  const { selectResult, queryEntity, selectEntity } = useData(requestPositionDetail);
  const {
    positionId,
    shiftDetailDocument,
    isApplicantRequiredConfirmation,
    groupedApplicantConfirmations,
  } = selectResult();
  const assigned = queryEntity(PositionQ.User.isAssigned(positionId));
  const needConfirmation = queryEntity(PositionQ.User.isRequiredConfirmation(positionId));
  const { requirements, contacts } = selectEntity(selectPosition(positionId));
  const groupedPositionIds =
    head(
      selectEntity(selectPositionAttendance({ position: positionId, worker: workerId }, "groupedPositionIds")) ?? []
    ) ?? [];
  const [outer] = useSpacing();

  const hasContacts = !isNil(contacts) && !isEmpty(contacts);
  return (
    <>
      {isApplicantRequiredConfirmation && isNotEmpty(groupedApplicantConfirmations) && (
        <Toolbar className={styles.backgroundWarning} position="bottom">
          <Box px={mobile ? 0 : 4} py={2} width="100%">
            <MuiGrid alignItems="center" container direction={mobile ? "column" : "row"} spacing={2}>
              <MuiGrid alignItems="center" container direction="row" item spacing={1} xs>
                <MuiGrid item xs="auto">
                  <Icon color="colorApplicant" icon={mdiHumanGreetingVariant} />
                </MuiGrid>
                <MuiGrid item xs>
                  {values(groupedApplicantConfirmations)[0]?.length > 1
                    ? t("Position.youHaveBeenSelectedAsApplicantForMultiple")
                    : t("Position.youHaveBeenSelectedAsApplicant")}
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs="auto">
                <AttendanceConfirmButtons
                  confirmationType="applicant"
                  positionId={values(groupedApplicantConfirmations)[0]}
                />
              </MuiGrid>
            </MuiGrid>
          </Box>
        </Toolbar>
      )}
      {needConfirmation && (
        <Toolbar className={styles.backgroundWarning} position="bottom">
          <Box px={mobile ? 0 : 4} py={2} width="100%">
            <MuiGrid alignItems="center" container direction={mobile ? "column" : "row"} spacing={2}>
              <MuiGrid item xs>
                {ensureArray(groupedPositionIds).length > 1
                  ? t("Position.youHaveBeenSelectedForMultiple")
                  : t("Position.youHaveBeenSelected")}
              </MuiGrid>
              <MuiGrid item xs="auto">
                <AttendanceConfirmButtons positionId={groupedPositionIds} />
              </MuiGrid>
            </MuiGrid>
          </Box>
        </Toolbar>
      )}
      <TwoColumnLayout>
        <GridLayout spacing="outer">
          <PositionDetailHeader />
          {mobile && assigned && <PositionDetailWorkers />}
          <PositionDetailCapacity />
          <PositionDetailNotices positionId={positionId} />
          <PositionDetailStatus positionId={positionId} />
          <PositionDetailResults positionId={positionId} />
          <Reimbursements />
          <WorkerFiles positionId={positionId} />
          {!isNil(hibernated) && hibernated !== false && (
            <GridLayout.Item>
              <Grid spacing="inner" vertical>
                <HibernationStatusStrip />
              </Grid>
            </GridLayout.Item>
          )}
          {shiftDetailDocument && (
            <GridLayout.Item>
              <Button
                action={externalLink(shiftDetailDocument)}
                color="info"
                icon={mdiFileDownloadOutline}
                variant="outlined"
              >
                {t("Position.downloadShiftDetail")}
              </Button>
            </GridLayout.Item>
          )}
        </GridLayout>
        <GridLayout spacing="outer">
          <PositionDetailParametersList positionId={positionId} />
          <MuiGrid className={styles.hideEmpty} container direction={mobile ? "column" : "row"} item spacing={outer}>
            {(hasContacts || (!mobile && assigned)) && (
              <MuiGrid item md={6} xs>
                <MuiGrid className={styles.hideEmpty} container direction="column" spacing={outer}>
                  {hasContacts && (
                    <MuiGrid item>
                      <PositionDetailContacts />
                    </MuiGrid>
                  )}
                  {!mobile && assigned && (
                    <MuiGrid item>
                      <PositionDetailWorkers />
                    </MuiGrid>
                  )}
                </MuiGrid>
              </MuiGrid>
            )}

            <MuiGrid item xs>
              {!isNilOrEmpty(requirements) && <PositionDetailRequirements />}
            </MuiGrid>
          </MuiGrid>
          <PositionDetailDescription />
          <PositionDetailFeedback />
          <PositionDetailTransport />
          <PositionDetailConnectedPositionsList positionId={positionId} />
          <PositionDetailAcceptForm positionId={positionId} />
        </GridLayout>
      </TwoColumnLayout>
    </>
  );
}
