import { defineRequest } from "../../../backend";

interface Suggestions {
  type: string;

  text: string;
}

export const requestSuggestionsSubmit = defineRequest("Suggestions/Submit")
  .withParams<Suggestions>()
  .withEmptyResponse()
  .withCreator((params: Suggestions) => ({ params }));
