import { Grid as MuiGrid } from "@material-ui/core";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { mdiCircleMedium, mdiClockOutline } from "@mdi/js";

import { useFormat } from "@sinch/intl";
import { IconId } from "@sinch/types";
import { rejectFalsy } from "@sinch/utils";
import { length } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { Icon } from "../Icon";
import { B, Text } from "../Text";

const useStyles = makeStyles(({ palette, spacing }) => ({
  connector: {
    display: "flex",
    height: spacing(1.5),
    justifyContent: "center",
    position: "absolute",
    top: "-6px",
    width: "20px",
    "& div": {
      backgroundColor: palette.divider,
      height: "100%",
      width: "2px",
    },
  },
  connectorFull: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    height: "calc(100% - 32px)",
    top: "32px",
    paddingLeft: "9px",
    minHeight: "10px",
    "& div": {
      backgroundColor: palette.divider,
      height: "100%",
      width: "2px",
    },
  },
  icon: {
    marginRight: spacing(1),
  },
}));

function Connector({ full = false }) {
  const styles = useStyles();

  return (
    <div className={full ? styles.connectorFull : styles.connector}>
      <div />
    </div>
  );
}

export interface TimelineStep {
  startTime: Date;

  endTime?: Date;

  title: string;

  icon?: string;

  content?: ReactNode;
}

export interface TimelineProps {
  baseIcon?: IconId;

  stepIcon?: IconId;

  steps: TimelineStep[];
}

export function Timeline({
  steps,
  baseIcon = mdiClockOutline,
  stepIcon = mdiCircleMedium,
}: TimelineProps): ReactElement {
  const styles = useStyles();
  const { dt, dur } = useFormat();

  return (
    <MuiList disablePadding>
      {rejectFalsy(steps).map(({ startTime, endTime, title, icon, content }, index) => {
        const duration = startTime && endTime ? (endTime.getTime() - startTime.getTime()) / 1000 : undefined;
        // @ts-ignore
        return (
          /* eslint-disable-next-line react/no-array-index-key */
          <MuiListItem key={index} disableGutters>
            {index > 0 && false && <Connector />}
            <MuiGrid container wrap="nowrap">
              <MuiGrid item xs="auto">
                <MuiGrid container direction="column">
                  <MuiGrid item>
                    <div className={styles.icon}>
                      <Icon color="neutral" icon={icon || (index ? stepIcon : baseIcon)} size="small" />
                    </div>
                  </MuiGrid>
                  {length(rejectFalsy(steps)) - 1 > index && (
                    <MuiGrid item>
                      <Connector full />
                    </MuiGrid>
                  )}
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item>
                <MuiGrid container direction="column">
                  <MuiGrid item>
                    <Text separator=" - ">
                      <B>
                        {startTime && `${dt.short(startTime)} ${dt.time(startTime)}`}
                        {endTime && (
                          <>
                            {dt.time(endTime)}
                            {duration && ` (${dur.daysTime(duration)})`}
                          </>
                        )}
                      </B>
                      {title}
                    </Text>
                  </MuiGrid>
                  {content && <MuiGrid item>{content}</MuiGrid>}
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiListItem>
        );
      })}
    </MuiList>
  );
}
