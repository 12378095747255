import { ChildrenProps } from "@sinch/utils";
import React, { createContext, ReactElement, useContext, useMemo } from "react";
import { responseHandler, ResponseHandler, responseHandlerSequence } from "../handler";

/**
 * todo: create custom error class with properties containing request
 *  and response data for better debugging
 */
const unhandled = responseHandler("(unhandled)", null, ({ request, response }) => {
  throw new Error(`Unhandled response for request ${request.key} with status ${response.status}`);
});

const HandlerContext = createContext(unhandled);
export const useHandler = (): ResponseHandler => useContext(HandlerContext);

export interface HandlerProviderProps extends ChildrenProps {
  /**
   * Response handlers (both success and error states) for this Provider and
   * its children.
   */
  handler: ResponseHandler;
}

export function HandlerProvider({ children, handler: ownHandler }: HandlerProviderProps): ReactElement {
  const parentHandler = useHandler();

  const handler = useMemo(() => responseHandlerSequence([ownHandler, parentHandler]), [ownHandler, parentHandler]);

  return <HandlerContext.Provider value={handler}>{children}</HandlerContext.Provider>;
}
