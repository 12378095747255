import { FormHelperText, OutlinedInput } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "@sinch/intl";
import { Consumer, Key, SelectFieldOption } from "@sinch/types";
import { eqByLength, isDefined } from "@sinch/utils";
import {
  always,
  both,
  defaultTo,
  ifElse,
  includes,
  join,
  length,
  map,
  path,
  pipe,
  pluck,
  values,
  when,
  without,
} from "ramda";
import { lengthEq, lengthGt, lengthLt } from "ramda-adjunct";
import React, { ChangeEvent, ReactElement, useCallback, useMemo } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";
import { indexByValue } from "./SelectInput";

const useStyles = makeStyles(() => ({
  paperStyles: {
    maxHeight: "calc(100% - 150px)",
  },
}));

export interface MultiSelectInputProps extends ManagedInputProps {
  options: SelectFieldOption[];

  /**
   * Display additional menu item to (un)select all options at once.
   */
  toggleAll?: boolean;
}

export function MultiSelectInput(props: MultiSelectInputProps): ReactElement {
  const [baseProps, { setValue }] = useFormField<Key[], MultiSelectInputProps>(props);

  const { options } = props;
  const resolveTargetValue = useMemo(
    () =>
      when(
        // if toggleAll was clicked
        includes(undefined),
        // (de)select all depending on current field value
        pipe(without([undefined]), ifElse(eqByLength(options), always([]), always(pluck("value", options))))
        // otherwise pass event value as is
      ),
    [options]
  );

  const handleChange = useCallback(
    ({ target }) => {
      setValue(resolveTargetValue(target.value));
    },
    [setValue, resolveTargetValue]
  );

  return (
    <MultiSelectInputBase
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      onChange={handleChange}
    />
  );
}

export function MultiSelectInputBase({
  dense,
  disabled,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  error,
  label,
  name,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  note,
  onChange,
  onInvalid,
  options: optionsList,
  placeholder,
  toggleAll,
  value,
  required,
  ...props
}: BaseInputProps<Key[], MultiSelectInputProps, Consumer<ChangeEvent<{ value: unknown }>>>): ReactElement {
  const styles = useStyles();
  const options = useMemo(() => indexByValue(optionsList), [optionsList]);

  const getOptLabel = useCallback((optValue: Key) => path([optValue, "label"], options), [options]);

  const renderValue = useMemo(
    () =>
      ifElse(
        lengthEq(0),
        always(defaultTo(label, placeholder)),
        pipe<Key[], string[], string>(map(getOptLabel), join(", "))
      ),
    [label, placeholder, getOptLabel]
  );

  const isAllValues = useMemo(() => eqByLength(optionsList), [optionsList]);

  const isIndeterminate = useMemo(() => both(lengthGt(0), lengthLt(length(optionsList))), [optionsList]);

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth
      margin={dense ? "dense" : "normal"}
      required={required}
      variant="outlined"
    >
      <InputLabel shrink>{label}</InputLabel>
      <Select
        {...props}
        displayEmpty={isDefined(placeholder)}
        input={<OutlinedInput label={label} notched />}
        MenuProps={{ variant: "menu", PaperProps: { className: styles.paperStyles } }}
        multiple
        name={name}
        onChange={onChange}
        onInvalid={onInvalid}
        renderValue={renderValue}
        required={required}
        value={value}
      >
        {toggleAll && (
          <MenuItem value={undefined}>
            <Checkbox checked={isAllValues(value)} indeterminate={isIndeterminate(value)} />
            <ListItemText primary={t("selectAll")} />
          </MenuItem>
        )}
        {values(options).map(({ value: optValue, label: optLabel }) => (
          <MenuItem key={optValue} value={optValue}>
            <Checkbox checked={includes(optValue, value)} />
            <ListItemText primary={optLabel} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{note}</FormHelperText>
    </FormControl>
  );
}
