import { t } from "@sinch/intl";
import { Box, Header } from "@sinch/ui";
import React, { ReactElement } from "react";

export function AppointmentListEmptyMessage(): ReactElement {
  // todo: link search form
  return (
    <Box alignItems="center" display="flex" flexDirection="column" height="24em" justifyContent="center">
      <Header color="neutral">{t("Appointment.noAppointmentsListNotice") /* Nebyly nalezeny žádné události. */}</Header>
    </Box>
  );
}
