import { DataProvider, requestHibernate, responseHandlerKey, ServerMessage, useSession } from "@sinch/core";
import { useSnackbar } from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
import { find, prop, propEq } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function HibernateContainer({ children }: ChildrenProps): ReactElement {
  const { refresh } = useSession();
  const snackbar = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestHibernate,
        ({ response: { messages } }) => {
          const successMessage = find<ServerMessage>(propEq("level", "SUCCESS"))(messages);
          if (successMessage) {
            snackbar("success", prop("text", successMessage));
          }
          navigate(pathname);
          refresh();
        },
        ({ response: { messages } }) => {
          const errorMessage = find<ServerMessage>(propEq("level", "ERROR"))(messages);
          if (errorMessage) {
            snackbar("success", prop("text", errorMessage));
          }
          refresh();
        }
      ),
    [navigate, pathname, refresh, snackbar]
  );

  return <DataProvider handler={responseHandler}>{children}</DataProvider>;
}
