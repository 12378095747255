import { useData } from "@sinch/core";
import { selectPage } from "@sinch/entity";
import { Card, HtmlContent, TwoColumnLayout } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestPageView } from "../contract";
import { PageMenu } from "./PageMenu";

/**
 * todo: check if url location is valid when result contains menu?
 *  for example when visiting "Help - Join work"
 *  - do nothing if url is correct
 *    (`/page/help/join-work`)
 *  - push correct location if accessed via invalid url
 *    (`/page/anything/join-work`)
 *  OR server can send full page url in response, which also allows redirecting
 *  when page was renamed/moved on the server - much better solution!
 *  (same principle as returning id of requested entity)
 */

export function Page(): ReactElement {
  const { selectEntity, selectResult } = useData(requestPageView);
  const { pageId, menuId } = selectResult();
  const { body } = selectEntity(selectPage(pageId));

  return menuId ? (
    <TwoColumnLayout>
      <PageMenu id={menuId} />
      <Card>
        <HtmlContent html={body} />
      </Card>
    </TwoColumnLayout>
  ) : (
    <Card>
      <HtmlContent html={body} />
    </Card>
  );
}
