import { Box } from "@material-ui/core";
import { mdiAccountCash, mdiBankTransfer, mdiCurrencyUsd } from "@mdi/js";
import { useBusinessRules, useData } from "@sinch/core";
import { selectSnippet, Snippet } from "@sinch/entity";
import { t } from "@sinch/intl";
import { GridLayout, Paper, Tabs, useSpacing } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { head } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Notice } from "../../Dashboard/components";
import { ReimbursementListContainer, ReimbursementTable } from "../Reimbursement";

import { requestWalletStatus } from "./api";
import { WalletPayoutListContainer } from "./WalletPayoutListContainer";
import { WalletPayoutTable } from "./WalletPayoutTable";
import { WalletTransactionListContainer } from "./WalletTransactionListContainer";
import { WalletTransactionTable } from "./WalletTransactionTable";

/**
 * todo: merge into Wallet base component
 *
 * todo: use TabNavigation to assign specific url for each tab
 *  (but navigation handlers need to be updated properly first)
 */
export function DesktopWalletPaymentList(): ReactElement {
  const { allowWorkerReimbursementRequest } = useBusinessRules();
  const [outer] = useSpacing();

  const { selectEntity } = useData(requestWalletStatus);
  const notices = selectEntity(selectSnippet({ name: "wallet_main" }));
  const getNotice = (notice: Snippet[]) => {
    if (isNotNilOrEmpty(notice)) {
      // @ts-ignore
      const { body, color, id, style } = head<Snippet>(notice);
      return (
        <Box pb={outer}>
          <Notice body={body} color={color} id={id} style={style} />
        </Box>
      );
    }
    return null;
  };

  return (
    <GridLayout.Item>
      {getNotice(notices)}
      <Paper>
        <Tabs
          tabs={rejectFalsy([
            {
              element: (
                <WalletTransactionListContainer>
                  <WalletTransactionTable />
                </WalletTransactionListContainer>
              ),
              icon: mdiBankTransfer,
              label: t("Wallet.display.transactions"),
            },
            allowWorkerReimbursementRequest && {
              element: (
                <ReimbursementListContainer>
                  <ReimbursementTable />
                </ReimbursementListContainer>
              ),
              icon: mdiAccountCash,
              label: t("Reimbursement.titlePlural"),
            },
            {
              element: (
                <WalletPayoutListContainer>
                  <WalletPayoutTable />
                </WalletPayoutListContainer>
              ),
              icon: mdiCurrencyUsd,
              label: t("Wallet.display.payoutHistory"),
            },
          ])}
        />
      </Paper>
    </GridLayout.Item>
  );
}
