import { mdiMagnify, mdiClose } from "@mdi/js";
import { FormControl } from "@material-ui/core";

import { TextInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Icon, IconButton } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";

interface FilterInputProps {
  value: string;
  onChange: (value: string) => void;
}

export function FilterInput({ value: inputValue, onChange }: FilterInputProps): ReactElement {
  return (
    <FormControl fullWidth>
      <TextInputBase
        dense
        name="searchField"
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
        placeholder={t("action.search")}
        prefix={<Icon icon={mdiMagnify} />}
        suffix={isEmpty(inputValue) ? undefined : <IconButton action={() => onChange("")} icon={mdiClose} />}
        value={inputValue}
      />
    </FormControl>
  );
}
