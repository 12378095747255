import { defaultTo } from "ramda";
import React, { ReactElement } from "react";
import { DataTableCell } from "./DataTableCell";
import { DataTableDisplay, DataTableRowProps } from "./types";

// todo: resolve defaults when initializing component instead of during render
const resolveCell = defaultTo(DataTableCell);

export function DataTableRow<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>({
  table,
  row,
}: DataTableRowProps<TData, TColumn, TDisplay>): ReactElement {
  const { config, columns, display } = table;
  const { Row } = display;

  return (
    <Row>
      {columns.map((column) => {
        const Cell = resolveCell(config[column].logic);
        return <Cell key={`${column}${row}`} column={column} row={row} table={table} />;
      })}
    </Row>
  );
}
