import { Grid as MuiGrid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import { RowsGrid } from "../Layout";

const useStyles = makeStyles(() => ({
  overflowNoWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  overflowHidden: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  condensed: {
    maxWidth: "100%",
    fontSize: "12px",
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
    },
  },
  dFlex: {
    display: "flex",
  },
}));

export function CondensedHeader({ children }: { children: ReactNode }): ReactElement {
  return (
    <MuiGrid container justify="space-between" wrap="nowrap">
      {children}
    </MuiGrid>
  );
}

export function CondensedTitle({ children, wrap }: { children: ReactNode; wrap?: boolean }): ReactElement {
  const classes = useStyles();
  return (
    <MuiGrid className={clsx(!wrap && classes.overflowHidden)} item xs>
      {children}
    </MuiGrid>
  );
}

export function CondensedStatus({ children }: { children: ReactNode }): ReactElement {
  return (
    <MuiGrid item xs="auto">
      {children}
    </MuiGrid>
  );
}

export function CondensedBody({ children }: { children: ReactNode }): ReactElement {
  return <MuiGrid container>{children}</MuiGrid>;
}

export function CondensedContent({ children }: { children: ReactNode }): ReactElement {
  return (
    <MuiGrid item xs>
      <RowsGrid>{children}</RowsGrid>
    </MuiGrid>
  );
}

export function CondensedEndStatus({ children }: { children: ReactNode }): ReactElement {
  const classes = useStyles();
  return (
    <MuiGrid className={classes.dFlex} item xs="auto">
      {children}
    </MuiGrid>
  );
}

export function CondensedCard({ children, wrap }: PropsWithChildren<{ wrap?: boolean }>): ReactElement {
  const classes = useStyles();

  return (
    <RowsGrid itemProps={{ className: clsx(!wrap && classes.overflowNoWrap, classes.condensed) }}>{children}</RowsGrid>
  );
}
