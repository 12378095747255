import { defineRequest, FilesMeta, IdParams } from "@sinch/core";
import {
  CompanyContainer,
  Fk,
  LocationContainer,
  PositionAttendanceContainer,
  PositionContainer,
  ProfessionContainer,
  Shift,
  ShiftContainer,
  TransportAttendanceContainer,
  TransportContainer,
  WorkerContainer,
} from "@sinch/entity";
import { FileHash, Nullable } from "@sinch/types";
import { parseDate } from "@sinch/utils";

export const requestPresenceView = defineRequest("Shifts/OnsiteView/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Shift>>()
  .withResponse<{
    files: FileHash[];

    publicDescriptionFiles: FileHash[];

    crewbossDescriptionFiles: FileHash[];

    note: Nullable<string>;

    shiftId: Fk<Shift>;

    startTime: Nullable<Date>;
  }>({
    startTime: parseDate,
  })
  .withResponseMeta<FilesMeta & { redirect?: { type: string; id: number } }>()
  .withResponseEntity<
    CompanyContainer &
      LocationContainer &
      PositionContainer &
      PositionAttendanceContainer &
      ProfessionContainer &
      ShiftContainer &
      TransportContainer &
      TransportAttendanceContainer &
      WorkerContainer
  >()
  .withCreator((id: Fk<Shift>) => ({
    params: { id },
  }));
