import { ParamsOf } from "@sinch/core";
import { Fk, Profession, RecentSearch, WorkerRole } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { isArray, isNumber, isUndefined, parseDate, serializeDate } from "@sinch/utils";
import { reject } from "ramda";
import { ensureArray } from "ramda-adjunct";
import { requestPositionList } from "../List/api";
import { MapPlace } from "./MapPlaceInput";

/**
 * todo: consider extracting to instance settings
 *  (for example 15 km means nothing in the US)
 */
export const distanceOptions = [
  { value: 5, label: "+5 km" },
  { value: 10, label: "+10 km" },
  { value: 15, label: "+15 km" },
];
// @todo dictionary keys
/*
t("display.distance", { distance: 5 })
 */
/**
 * We need to store more information in url then what is actually sent to
 * backend in order to support form values persistence when using navigation.
 *
 * Also kind of a workaround until we have proper configurable url params
 * serializer/deserializer so we can put objects into search params directly.
 */
export interface PositionSearchUrlParams {
  distance?: number;

  from?: string;

  ignoreCapacity?: boolean;

  ignoreConflict?: boolean;

  ignoreRating?: boolean;

  lat?: number;

  lng?: number;

  place?: string;

  query?: string;

  role?: WorkerRole[];

  profession?: Fk<Profession>[];

  to?: string;

  attend?: boolean;
}

/**
 * todo: rename from/to -> startTime/endTime
 */
export interface PositionSearchFormValues {
  distance: number;

  from: Nullable<Date>;

  ignoreConflict: boolean;

  place: Nullable<MapPlace>;

  query: string;

  role: WorkerRole[];

  profession: Fk<Profession>[];

  to: Nullable<Date>;
}

const valuesOnly = reject(isUndefined);

export function correctRole(role?: WorkerRole[] | WorkerRole): WorkerRole[] {
  if (isArray(role)) return role;
  if (isNumber(role)) return [role];
  return [];
}

const dateToUrl = (date: Nullable<Date>) => date && serializeDate(date);

export const PositionSearchParams = {
  fromRecentSearch({
    from,
    ignoreConflict,
    place,
    radius,
    role,
    profession,
    query,
    to,
  }: RecentSearch): PositionSearchUrlParams {
    return valuesOnly({
      distance: radius?.distance,
      from: from ? dateToUrl(from) : null,
      ignoreConflict,
      lat: radius?.lat,
      lng: radius?.lng,
      place,
      query,
      role,
      profession,
      to: to ? dateToUrl(to) : null,
    });
  },

  fromForm({
    distance,
    from,
    ignoreConflict,
    place,
    query,
    role,
    profession,
    to,
  }: PositionSearchFormValues): PositionSearchUrlParams {
    return valuesOnly({
      distance,
      from: dateToUrl(from),
      ignoreConflict,
      lat: place?.point.lat,
      lng: place?.point.lng,
      place: place?.label.join("; "),
      query,
      role,
      profession,
      to: dateToUrl(to),
    });
  },

  toForm({
    distance,
    from,
    ignoreConflict,
    lat,
    lng,
    place,
    query,
    role,
    profession,
    to,
  }: PositionSearchUrlParams): PositionSearchFormValues {
    return {
      distance: distance ?? distanceOptions[0].value,
      from: from ? parseDate(from) : null,
      /* removed default value in order to enhance ux
      from: from ? parseDate(from) : new Date(), */
      ignoreConflict: ignoreConflict ?? false,
      place: place
        ? {
            id: -1,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            point: { lat: lat!, lng: lng! },
            label: place.split(";") as [string, string],
          }
        : null,
      query: query ?? "",
      role: correctRole(role),
      profession: ensureArray(profession ?? []),
      to: to ? parseDate(to) : null,
    };
  },

  toRequest({
    distance,
    from,
    ignoreCapacity,
    ignoreConflict,
    ignoreRating,
    lat,
    lng,
    query,
    role,
    profession,
    to,
    place,
    attend,
  }: PositionSearchUrlParams): ParamsOf<typeof requestPositionList> {
    const hasPlace = lat && lng && distance;

    return valuesOnly({
      range: hasPlace ? distance : undefined,
      place,
      from,
      ignoreCapacity,
      ignoreConflict,
      ignoreRating,
      lat: hasPlace ? lat : undefined,
      lng: hasPlace ? lng : undefined,
      query,
      role,
      profession,
      to,
      attend,
      radius: hasPlace
        ? {
            lat,
            lng,
            distance,
          }
        : undefined,
    });
  },
};
