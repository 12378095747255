/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Consumer } from "@sinch/types";
import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import { PresetProps } from "../DateInput";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";
// eslint-disable-next-line import/no-cycle
import { TimeInputPart } from "./TimeInputPart";

/**
 * Props of SelectDateTimeInput
 */
export interface SelectTimeInputProps extends ManagedInputProps {
  /**
   * If true, native picker are used
   */
  useNative?: boolean;
  /**
   * Step of times list
   */
  step?: number;
  /**
   * Time point for select from which relative time is counted
   */
  timePoint?: Date;
  /**
   * Minimal time of time select
   */
  minTime?: Date;

  /**
   * Maximal time of time select
   */
  maxTime?: Date;

  fullWidth?: boolean;

  adornment?: ReactNode;

  dense?: boolean;
  textAlign?: "right" | "center" | "left";
}

export function SelectTimeInput({ preset, ...props }: PresetProps & SelectTimeInputProps): ReactElement {
  const [{ value, ...baseProps }, { setValue }] = useFormField<Date, SelectTimeInputProps>(props);

  const onChange = useCallback((next) => setValue(next), [setValue]);

  return <SelectTimeInputBase {...baseProps} onChange={onChange} value={value || undefined} />;
}

/**
 * Component for selecting time
 */
export function SelectTimeInputBase({
  onChange,
  error,
  name,
  useNative,
  step = 15,
  value: initValue,
  minTime,
  maxTime,
  timePoint,
  label,
  note,
  adornment,
  dense,
  fullWidth = false,
  textAlign = "right",
  placeholder,
}: BaseInputProps<Date | undefined | null, SelectTimeInputProps, Consumer<MaterialUiPickersDate>>): ReactElement {
  const [value, setValue] = useState<Date | undefined>(initValue);

  const handleChange = (newDate: Date): void => {
    setValue(newDate);
    onChange(newDate);
  };

  return (
    <MuiFormControl error={error} fullWidth={fullWidth} margin="dense" variant="outlined">
      <InputLabel shrink>{label}</InputLabel>
      <OutlinedInput
        endAdornment={adornment}
        fullWidth
        inputComponent={() => (
          <TimeInputPart
            dense={dense}
            initValue={initValue ?? undefined}
            maxTime={maxTime}
            minTime={minTime}
            name={`${name}.time`}
            onChange={handleChange}
            placeholder={placeholder}
            step={step}
            textAlign={textAlign}
            timePoint={timePoint}
            useNative={useNative}
            value={value}
          />
        )}
        label={label}
        margin={dense ? "dense" : undefined}
        name={name}
        notched
      />
      {note && <MuiFormHelperText error={error}>{note}</MuiFormHelperText>}
    </MuiFormControl>
  );
}
