import { Grid } from "@material-ui/core";
import { mdiCheck, mdiClose } from "@mdi/js";
import {
  DataProvider,
  responseHandlerKey,
  responseHandlerSequence,
  ServerMessage,
  useCascadeRefresh,
  useRefresh,
  useRequest,
  useStatusUpdate,
} from "@sinch/core";
import { Fk, Position } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, LoadingOverlay, useSnackbar } from "@sinch/ui";
import { find, prop, propEq } from "ramda";
import { ensureArray } from "ramda-adjunct";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { applicantConfirm, applicantDecline, requestAttendanceConfirm, requestAttendanceDecline } from "./api";

const AttendanceButtonsContent = ({
  positionId,
  condensed,
  confirmationType,
}: {
  positionId: Fk<Position> | Fk<Position>[];
  condensed?: boolean;
  confirmationType: "position" | "applicant";
}) => {
  const positionIds = ensureArray(positionId);
  const dispatch = useRequest();
  return (
    <Grid container justify="flex-end" spacing={1}>
      <Grid item>
        <Button
          action={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              (confirmationType === "position" ? requestAttendanceDecline : applicantDecline)({
                positionId: positionIds,
              })
            );
          }}
          color="error"
          icon={mdiClose}
        >
          {!condensed && (positionIds.length > 1 ? t("action.declineAll") : t("action.decline"))}
        </Button>
      </Grid>
      <Grid item>
        <Button
          action={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              (confirmationType === "position" ? requestAttendanceConfirm : applicantConfirm)({
                positionId: positionIds,
              })
            );
          }}
          color="success"
          icon={mdiCheck}
        >
          {!condensed && (positionIds.length > 1 ? t("action.confirmAll") : t("action.confirm"))}
        </Button>
      </Grid>
    </Grid>
  );
};

export const AttendanceConfirmButtons = ({
  positionId,
  condensed,
  onSuccess = () => {},
  confirmationType = "position",
}: {
  positionId: Fk<Position> | Fk<Position>[];
  condensed?: boolean;
  onSuccess?: () => void;
  confirmationType?: "position" | "applicant";
}) => {
  const { pathname, search } = useLocation();
  const { cascadeRefresh } = useCascadeRefresh();
  const { refresh: refreshStatus } = useStatusUpdate();

  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh();
  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          confirmationType === "position" ? requestAttendanceConfirm : applicantConfirm,
          ({ response: { messages } }) => {
            const successMessage = find<ServerMessage>(propEq("level", "SUCCESS"))(messages);
            if (successMessage) {
              snackbar("success", prop("text", successMessage));
            } else {
              snackbar("success", t("Position.attendanceAccepted"));
            }
            refresh();
            cascadeRefresh();
            refreshStatus();
            onSuccess();
          },
          ({ response: { messages } }) => {
            const errorMessage = find<ServerMessage>(propEq("level", "ERROR"))(messages);
            if (errorMessage) {
              snackbar("error", prop("text", errorMessage));
            } else {
              snackbar("error", t("Position.errorWhenAcceptAttendance"));
            }
            refresh();
            refreshStatus();
            cascadeRefresh();
          }
        ),
        responseHandlerKey(
          confirmationType === "position" ? requestAttendanceDecline : applicantDecline,
          ({ response: { messages } }) => {
            const successMessage = find<ServerMessage>(propEq("level", "SUCCESS"))(messages);
            if (successMessage) {
              snackbar("success", prop("text", successMessage));
            } else {
              snackbar("success", t("Position.attendanceDeclined"));
            }
            refresh();
            cascadeRefresh();
            refreshStatus();
            onSuccess();
          },
          ({ response: { messages } }) => {
            const errorMessage = find<ServerMessage>(propEq("level", "ERROR"))(messages);
            if (errorMessage) {
              snackbar("error", prop("text", errorMessage));
            } else {
              snackbar("error", t("Position.errorWhenDeclineAttendance"));
            }
            refresh();
            cascadeRefresh();
            refreshStatus();
          }
        ),
      ]),
    [navigate, pathname, search, refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler} progress={<LoadingOverlay />} refresh={refreshToken}>
      <AttendanceButtonsContent condensed={condensed} confirmationType={confirmationType} positionId={positionId} />
    </DataProvider>
  );
};
