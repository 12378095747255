import { t } from "@sinch/intl";

export enum PayoutStatus {
  Pending = 0,

  Paid = 1,
}

export const PayoutStatusLabels = () => ({
  [PayoutStatus.Pending]: t("Wallet.payoutStatus.pending"),
  [PayoutStatus.Paid]: t("Wallet.payoutStatus.paid"),
});
