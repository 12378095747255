import { mdiCancel } from "@mdi/js";
// eslint-disable-next-line import/no-extraneous-dependencies
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Box, Center, HtmlContent, Strip } from "@sinch/ui";
import { ChildrenProps, toElement } from "@sinch/utils";
import { find, isEmpty } from "ramda";
import { isNotNil } from "ramda-adjunct";
import React, { ReactElement, ReactNode } from "react";
import { useCurrentUser, useStatus } from "../context";

/**
 * User account is completely blocked because of reasons explained in message provided by admin.
 */
function AccessRestrictionIntercept(): Nullable<ReactElement> {
  const {
    restrictions: { access },
  } = useCurrentUser();

  if (!access) return null;

  return (
    <Center horizontal>
      <Box width={64}>
        <Strip color="error" icon={mdiCancel} title={t("Application.restrictionCommon")}>
          <HtmlContent html={access} />
        </Strip>
      </Box>
    </Center>
  );
}

export function InterceptHandler({
  children,
  interceptFallback,
}: ChildrenProps & { interceptFallback?: ReactNode }): ReactElement {
  const { newsIds } = useStatus();
  const {
    restrictions: { access },
  } = useCurrentUser();

  const interceptions = [!access ? null : <AccessRestrictionIntercept />, isEmpty(newsIds) ? null : interceptFallback];

  return find(isNotNil, interceptions) ?? toElement(children);
}
