import { Grid as MuiGrid } from "@material-ui/core";
import { useBusinessRules, useData } from "@sinch/core";
import { OnsiteArrivalTime } from "@sinch/core/contract";
import {
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  WorkerRole,
} from "@sinch/entity";
import { useSpacing } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { sub } from "date-fns";
import { find, includes, isNil, propEq } from "ramda";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../api";
import { ArrivalEdit, BackupEdit, BreakEndEdit, BreakStartEdit, MissedEdit, NoteEdit, WorkEndEdit } from "./EditItems";

interface PresenceDetailEditProps {
  id: Fk<PositionAttendance>;
}

export function PresenceWorkerDetailEdit({ id }: PresenceDetailEditProps): ReactElement {
  const [outer] = useSpacing();
  const { shiftClosingWorkBreaks, onsiteArrivalTime } = useBusinessRules();
  const { selectEntity } = useData(requestPresenceView);
  const { position, progressStatus: status, startTime } = selectEntity(selectPositionAttendance(id));
  const { role, shift, meetingTimeInterval } = selectEntity(selectPosition(position));
  const shiftPositions = selectEntity(selectPosition({ shift }));
  const hasBackupSlots = !isNil(find(propEq("role", WorkerRole.Backup))(shiftPositions));

  const meetingTime =
    onsiteArrivalTime === OnsiteArrivalTime.MeetingTime ? sub(startTime, { minutes: meetingTimeInterval }) : new Date();

  const canEditBackup =
    role !== WorkerRole.Crewboss &&
    hasBackupSlots &&
    includes(status, [ClosingProgress.Pending, ClosingProgress.Present, ClosingProgress.Finished]);
  const isBackup = role !== WorkerRole.Crewboss && role === WorkerRole.Backup;

  const editBoxes = rejectFalsy([
    status === ClosingProgress.Pending && <ArrivalEdit arrivalTime={meetingTime} id={id} />,
    (status === ClosingProgress.Absent || status === ClosingProgress.Late) && <NoteEdit id={id} />,
    status === ClosingProgress.Present && !isBackup && shiftClosingWorkBreaks && <BreakStartEdit id={id} />,
    includes(status, [ClosingProgress.Present, ClosingProgress.AfterBreak]) && <WorkEndEdit id={id} />,
    status === ClosingProgress.Break && !isBackup && shiftClosingWorkBreaks && <BreakEndEdit id={id} />,
    status === ClosingProgress.Absent && <MissedEdit arrivalTime={meetingTime} id={id} />,
    canEditBackup && <BackupEdit id={id} />,
  ]);

  return (
    <MuiGrid container direction="column" spacing={outer}>
      {editBoxes.map((box, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MuiGrid key={i} item>
          {box}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
