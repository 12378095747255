import { defineRequest, IdParams } from "@sinch/core";
import {
  Appointment,
  AppointmentAttendanceContainer,
  AppointmentContainer,
  AppointmentTypeContainer,
  Fk,
  LocationContainer,
  PositionContainer,
  WorkerContainer,
} from "@sinch/entity";

export const requestAppointmentDetail = defineRequest("Appointments/View/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Appointment>>()
  .withResponse<{ appointmentId: Fk<Appointment> }>()
  .withResponseEntity<
    AppointmentContainer &
      AppointmentAttendanceContainer &
      AppointmentTypeContainer &
      LocationContainer &
      PositionContainer &
      WorkerContainer
  >()
  .withCreator((id: Fk<Appointment>) => ({ params: { id } }));
