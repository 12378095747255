import {
  DataProvider,
  PagingParams,
  requestNotificationList,
  ScrollHistory,
  useRefresh,
  useSearchParamsCustom,
} from "@sinch/core";
import { t } from "@sinch/intl";
import { useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { NotificationsList } from "./NotificationsList";

export const NOTIFICATION_LIST_DEFAULT_LIMIT = 25;

/**
 * todo: move into core package (root router needs to be defined)
 */
export function NotificationListContainer(): ReactElement {
  useTitleBar({
    container: "core:notification-list",
    locationTitle: t("Notification.titlePlural"),
  });
  const { refresh, refreshToken } = useRefresh({ interval: 30 * 60 * 1000 });

  const { searchParams } = useSearchParamsCustom<PagingParams>({ page: 0, limit: NOTIFICATION_LIST_DEFAULT_LIMIT });

  const request = useMemo(() => requestNotificationList(PagingParams.pick(searchParams)), [searchParams]);

  return (
    <DataProvider refresh={refreshToken} refresher={refresh} request={request}>
      <ScrollHistory>
        <NotificationsList />
      </ScrollHistory>
    </DataProvider>
  );
}
