import { Callback } from "@sinch/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export interface RefreshToken {
  /**
   * Last refresh timestamp.
   *
   * Output of {@link Date.now}.
   */
  lastRefresh: number;
}

export interface RefreshContext {
  /**
   * Trigger {@link RefreshContext.refreshToken} update.
   */
  refresh: Callback;

  /**
   * Memoized object to be passed into {@link DataProvider} props.
   */
  refreshToken: RefreshToken;
}

export interface RefreshParams {
  /**
   * Auto refresh interval.
   *
   * Timeout is reset when refreshed manually.
   */
  interval?: number;
}

export function useRefresh({ interval }: RefreshParams = {}): RefreshContext {
  const timeout = useRef<number>();
  const [lastRefresh, setLastRefresh] = useState(Date.now());

  const refresh = useCallback(() => {
    if (interval) {
      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => refresh(), interval);
    }

    setLastRefresh(Date.now());
  }, [interval]);

  useEffect(() => {
    if (interval) {
      timeout.current = window.setTimeout(() => refresh(), interval);
    }

    return (): void => {
      window.clearTimeout(timeout.current);
    };
  }, [refresh, interval]);

  return useMemo(
    () => ({
      refresh,
      refreshToken: {
        lastRefresh,
      },
    }),
    [refresh, lastRefresh]
  );
}
