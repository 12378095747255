import sanitize from "sanitize-html";
import { allowedAttributes, allowedTags } from "./rules";

const options: sanitize.IOptions = {
  allowedTags,
  allowedAttributes,
  // disallowedTagsMode: "discard",
};

export function sanitizeHtml(input: string): string {
  return sanitize(input, options);
}
