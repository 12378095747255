import { usePathSection } from "@sinch/core";
import { Consumer, NonEmptyArray, Nullable } from "@sinch/types";
import { always, append, pluck, unless } from "ramda";
import { contained } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo } from "react";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { useLocation, useRoutes } from "react-router";
import { Navigate, useNavigate } from "react-router-dom";
import { TabProps, TabsBase } from "../TabsBase";

function useNavigationState(
  replace?: boolean
): [Nullable<string>, Consumer<string>] {
  const navigate = useNavigate();
  const value = usePathSection();

  const handleChange = useCallback(
    (selected: string) => {
      navigate(selected, { replace });
    },
    [navigate, replace]
  );

  return [value, handleChange];
}

interface TabsNavigationProps {
  replace?: boolean;

  tabs: NonEmptyArray<TabProps>;
}

/**
 * todo: replace manual path resolution in useNavigationState
 *  by rendering tabs using router NavLink component
 *
 * todo: extract to core package to avoid extraneous dependency
 */
export function TabsNavigation({
  replace,
  tabs,
}: TabsNavigationProps): ReactElement {
  const { hash } = useLocation();
  const paths = useMemo(() => pluck("path", tabs), [tabs]);
  const validate = useMemo(
    () => unless(contained<Nullable<string>>(paths), always(null)),
    [paths]
  );

  const routes = useMemo(
    () =>
      append(
        {
          element: (
            <Navigate
              replace
              to={{
                pathname: paths[0],
                hash, // This allow use hash route on the same page with tab routes.
              }}
            />
          ),
          path: "*",
        },
        tabs
      ),
    [paths, tabs]
  );

  const [selected, setSelected] = useNavigationState(replace);

  const content = useRoutes(routes);

  return (
    <TabsBase
      onSelect={setSelected}
      selected={validate(selected) ?? false}
      tabs={tabs}
    >
      {content}
    </TabsBase>
  );
}
