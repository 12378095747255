import { Box, Grid as MuiGrid, InputAdornment } from "@material-ui/core";
import { mdiClockOutline } from "@mdi/js";
import { useBusinessRules, useData } from "@sinch/core";
import { useProgress } from "@sinch/core/backend/provider/ProgressProvider";
import { OnsiteArrivalTime } from "@sinch/core/contract";
import { ClosingProgress, Fk, PositionAttendance, selectPosition, selectPositionAttendance } from "@sinch/entity";
import { SelectTimeInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Callback } from "@sinch/types";
import { Button, Icon, LoadingOverlay, ModalDialog, Text, useSpacing } from "@sinch/ui";
import { min, sub } from "date-fns";
import { map } from "ramda";
import React, { ReactElement, useMemo, useState } from "react";
import { requestPresenceView } from "../api";
import { useClosingStatusProps, useUpdatePresence } from "../hooks";

interface SetClosingStateDialogProps {
  selected: Fk<PositionAttendance>[];
  inputName?: string;
  onClose: Callback;
  status: ClosingProgress;
}

export function SetClosingStateDialog({
  selected,
  inputName,
  onClose,
  status,
}: SetClosingStateDialogProps): ReactElement {
  const { onsiteArrivalTime } = useBusinessRules();
  const { selectEntity } = useData(requestPresenceView);
  const { setArrival, setBreakEnd, setBreak, setPending, setFinished, setAbsent } = useUpdatePresence(selected);
  const { inProgress } = useProgress();
  const [, , data] = useSpacing();
  const { getProps } = useClosingStatusProps();
  const { icon, label, color } = getProps(status);

  const positionsIds = selectEntity(selectPositionAttendance(selected, "position"));
  const positions = selectEntity(selectPosition(positionsIds));
  const startOfShift = min(
    map(({ startTime, meetingTimeInterval }) => sub(startTime, { minutes: meetingTimeInterval }))(positions)
  );

  const [time, setTime] = useState<Date | null>(
    status === ClosingProgress.Present && onsiteArrivalTime === OnsiteArrivalTime.MeetingTime ? startOfShift : null
  );

  const setStatus = () => {
    switch (status) {
      case ClosingProgress.Present:
        setArrival(time);
        break;
      case ClosingProgress.Absent:
        setAbsent();
        break;
      case ClosingProgress.Break:
        setBreak(time);
        break;
      case ClosingProgress.AfterBreak:
        setBreakEnd(time);
        break;
      case ClosingProgress.Pending:
        setPending();
        break;
      case ClosingProgress.Finished:
        setFinished(time);
        break;
      default:
    }
  };

  const inputLabel = useMemo(() => {
    if (status === ClosingProgress.Present) {
      return t("Shift.closing.arrival");
    }
    if (status === ClosingProgress.Break) {
      return t("Shift.closing.breakFrom");
    }
    if (status === ClosingProgress.AfterBreak) {
      return t("Shift.closing.breakTo");
    }
    if (status === ClosingProgress.Finished) {
      return t("Shift.closing.departure");
    }
    return t("time");
  }, [status]);

  return (
    <ModalDialog onClose={onClose} open width="xs">
      {inProgress && <LoadingOverlay />}
      <Box textAlign="center">
        <MuiGrid alignItems="stretch" container direction="column" spacing={data}>
          <MuiGrid item>
            <Box display="flex" justifyContent="center">
              <Icon color={color} icon={icon} size="large" />
            </Box>
          </MuiGrid>
          <MuiGrid item>
            <Text bold color={color}>
              {label}
            </Text>
          </MuiGrid>
          <MuiGrid item>{t("selectedWorkers")}</MuiGrid>
          <MuiGrid item>
            <Text bold>{selected.length}</Text>
          </MuiGrid>

          {inputName && (
            <>
              <MuiGrid item>
                <Text bold>{t("time")}</Text>
              </MuiGrid>
              <MuiGrid item>
                <SelectTimeInputBase
                  adornment={
                    <InputAdornment position="end">
                      <Icon icon={mdiClockOutline} />
                    </InputAdornment>
                  }
                  dense={false}
                  fullWidth
                  label={inputLabel}
                  name={inputName}
                  onChange={setTime}
                  textAlign="center"
                  value={time || new Date()}
                />
              </MuiGrid>
            </>
          )}
          <MuiGrid item xs={12}>
            <Button action={setStatus} color="info" variant="contained">
              {t("ok")}
            </Button>
          </MuiGrid>
          <MuiGrid item>
            <Button action={onClose} variant="text">
              {t("action.cancel")}
            </Button>
          </MuiGrid>
        </MuiGrid>
      </Box>
    </ModalDialog>
  );
}
