import { DataProvider, PagingParams, ParamsOf, ScrollHistory, useData, useSearchParamsCustom } from "@sinch/core";
import { t } from "@sinch/intl";
import { useFullWidthContent, useMobileLayout, useTitleBar } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement, useMemo, useState } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../components";
import { requestPositionHistory } from "./api";
import { PositionHistory } from "./PositionHistory";
import { PositionHistoryCondensed } from "./PositionHistoryCondensed";
import { PositionHistoryEmptyMessage } from "./PositionHistoryEmptyMessage";
import { PositionHistoryMobile } from "./PositionHistoryMobile";

type FilteredFields = "place" | "wageRating";

function PositionHistoryView(): ReactElement {
  useFullWidthContent();
  const mobile = useMobileLayout();

  const { selectResult } = useData(requestPositionHistory);
  const { positionIds } = selectResult();

  if (isEmpty(positionIds)) {
    return <PositionHistoryEmptyMessage />;
  }

  return mobile ? <ListMobile /> : <PositionHistory />;
}

function ListMobile(): ReactElement {
  const [view, setView] = useState<PositionView>(PositionView.Standard);
  const [fields, setFields] = useState<FilteredFields[]>([]);
  return (
    <>
      <PositionListViewSelectHeader<FilteredFields>
        fieldsKey="PositionHistory"
        filterFields={{
          place: t("location"),
          wageRating: t("Position.display.wageRating"),
        }}
        onFieldsFilterChange={setFields}
        onViewChange={setView}
      />
      {view === PositionView.Condensed ? <PositionHistoryCondensed fields={fields} /> : <PositionHistoryMobile />}
    </>
  );
}

export function PositionHistoryContainer(): ReactElement {
  useTitleBar({
    container: "core:history",
    locationTitle: t("Shift.history"),
  });

  const { searchParams } = useSearchParamsCustom<ParamsOf<typeof requestPositionHistory> & PagingParams>();

  const request = useMemo(
    () => requestPositionHistory({ searchQuery: searchParams.searchQuery }, PagingParams.pick(searchParams)),
    [searchParams]
  );

  return (
    <DataProvider request={request}>
      <ScrollHistory>
        <PositionHistoryView />
      </ScrollHistory>
    </DataProvider>
  );
}
