import { mdiLink } from "@mdi/js";
import { assoc, assocPath, equals, isEmpty, pipe } from "ramda";
import React, { ComponentProps, ReactElement } from "react";
import { Icon } from "../../Icon";
import { composeCellLogic } from "../composeLogic";
import { DataTableCell } from "../DataTableCell";
import {
  DataTableCellLogic,
  DataTableCellProps,
  DataTableDisplay,
  DataTableProps,
  DataTableRowLogic,
  DataTableRowProps,
} from "../types";
import { createMuiStyleCell } from "./MuiStyleCell";

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react/jsx-props-no-spreading */

export function createNestedRow<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>(
  nestedDataSelector: (el: TData) => TData[],
  iconColumn: TColumn
): DataTableRowLogic<TData, TColumn, TDisplay> {
  /*
   * todo: make reusable by injecting nested row component via params
   */
  const PaddedCell: DataTableCellLogic<TData, TColumn, TDisplay> = createMuiStyleCell(
    ({ spacing, palette }) => ({
      titleCell: {
        position: "relative",
        "& > :first-child": {
          marginLeft: spacing(6),
        },
        "& svg": {
          color: palette.info.light,
          position: "absolute",
          top: 0,
          left: spacing(4),
          transform: "translateY(50%)",
          backgroundColor: "transparent",
        },
      },
    }),
    (row, column, data) => [column === "name" ? "titleCell" : null]
  );

  function CellWithIcon({ table, row, column }: DataTableCellProps<TData, TColumn, TDisplay>) {
    const { config, display } = table;
    const { Cell } = display;
    const CellLogic = config[column].logic || DataTableCell;

    if (equals(iconColumn, column)) {
      const DisplayCell = ({ children, ...props }: ComponentProps<TDisplay["Cell"]>) => (
        <Cell {...props}>
          <div>{children}</div>
          <Icon icon={mdiLink} size="small" />
        </Cell>
      );

      const updateDisplay: (
        props: DataTableProps<TData, TColumn, TDisplay>
      ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(["display", "Cell"], DisplayCell);

      return <CellLogic column={column} row={row} table={updateDisplay(table)} />;
    }

    return <CellLogic column={column} row={row} table={table} />;
  }

  return function NestedRow({ table, row }: DataTableRowProps<TData, TColumn, TDisplay>): ReactElement {
    const { data, logic, config } = table;
    const RowLogic = logic;

    const current = data[row];
    const nested = nestedDataSelector(current);

    if (isEmpty(nested)) {
      return <RowLogic row={row} table={table} />;
    }

    const setData = assoc("data", nested);
    // todo: set defaults on container initialization
    const CellLogic = config[iconColumn].logic || DataTableCell;

    const setNestedCell = assocPath(
      ["config", iconColumn, "logic"],
      composeCellLogic([CellWithIcon, PaddedCell, CellLogic])
    );

    const updateTable = pipe(setNestedCell, setData);

    const renderNestedRow = (id: TData, i: number): ReactElement => (
      <RowLogic
        key={(id as unknown) as number}
        row={i}
        /* @ts-expect-error */
        table={updateTable(table)}
      />
    );

    return (
      <>
        <RowLogic row={row} table={table} />
        {nested.map(renderNestedRow)}
      </>
    );
  };
}
