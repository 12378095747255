import { useData } from "@sinch/core";
import { Fk, Penalty, PenaltyType, selectPenalty } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  composeCellLogic,
  createMuiStyleCell,
  Identifier,
  MuiDataTable,
  MuiDataTableDisplay,
  Spacer,
  Text,
  TypographyCell,
} from "@sinch/ui";
import { always } from "ramda";
import React, { ReactElement } from "react";
import { requestPenaltyList } from "./api";

type PenaltyColumn = "endTime" | "identifier" | "penalty" | "penaltyWithRating" | "startTime" | "type";

const RedColorCell = createMuiStyleCell<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>(
  (theme) => ({
    penalty: {
      "& p": {
        color: theme.palette.error.main,
      },
    },
  }),
  always(["penalty"])
);

interface PenaltyTableProps {
  columns: PenaltyColumn[];
}

export function PenaltyTable({ columns }: PenaltyTableProps): ReactElement {
  const { curr, dt } = useFormat();

  const { selectEntity, selectResult } = useData(requestPenaltyList);
  const { penaltyIds } = selectResult();

  const PenaltyDataTable = MuiDataTable.withConfig<Fk<Penalty>, PenaltyColumn>({
    identifier: {
      title: t("Position.title"),
      selector: (id) => {
        const { name, shift, position } = selectEntity(selectPenalty(id));
        return (
          <Text>
            {position && (
              <>
                <b>
                  <Identifier entity="shift" id={shift} />
                </b>
                -
                <b>
                  <Identifier entity="position" id={position} />
                </b>
              </>
            )}
            <b>{name}</b>
          </Text>
        );
      },
    },
    type: {
      title: t("Penalty.titlePlural"),
      selector: (id) => {
        const type = selectEntity(selectPenalty(id, "type"));
        return PenaltyType[type];
      },
      logic: composeCellLogic<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>([TypographyCell]),
    },
    startTime: {
      title: t("dateAndTime"),
      selector: (id) => {
        const startTime = selectEntity(selectPenalty(id, "startTime"));
        return dt.full(startTime);
      },
      logic: composeCellLogic<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>([TypographyCell]),
    },
    endTime: {
      title: t("Penalty.deadline"),
      selector: (id) => {
        const endTime = selectEntity(selectPenalty(id, "endTime"));
        return dt.full(endTime);
      },
      logic: composeCellLogic<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>([TypographyCell]),
    },
    penalty: {
      title: t("Penalty.titlePlural"),
      selector: (id) => {
        const { penalty = 0 } = selectEntity(selectPenalty(id));
        return curr(penalty);
      },
      logic: composeCellLogic<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>([RedColorCell, TypographyCell]),
    },
    penaltyWithRating: {
      title: t("Penalty.title"),
      selector: (id) => {
        const { penalty = 0, rating } = selectEntity(selectPenalty(id));
        return (
          <Spacer separator=" / ">
            {curr(penalty)}
            <>{rating}</>
          </Spacer>
        );
      },
      logic: composeCellLogic<Fk<Penalty>, PenaltyColumn, MuiDataTableDisplay>([RedColorCell, TypographyCell]),
    },
  });

  return <PenaltyDataTable columns={columns} data={penaltyIds} />;
}
