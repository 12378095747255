// todo figure out a civilized name for this component and move to ui?
import MuiGrid from "@material-ui/core/Grid";
import { useMobileLayout } from "@sinch/ui";
import { map } from "ramda";
import React, { ReactElement, ReactNode } from "react";

export type CellGridParams = {
  children: ReactNode;
};
export function CellGrid({ children }: CellGridParams): ReactElement {
  const mobile = useMobileLayout();
  return (
    <MuiGrid container direction={mobile ? "column" : "row"} spacing={2}>
      {map(
        (item, index) => (
          <MuiGrid key={`${item.key}${index}`} item md={6} xs={12}>
            {item}
          </MuiGrid>
        ),
        children
      )}
    </MuiGrid>
  );
}
