import { mdiCalendarToday, mdiClockOutline, mdiMapMarker, mdiPhone } from "@mdi/js";
import { useData } from "@sinch/core";
import { selectAppointment, selectLocation } from "@sinch/entity";
import { Format, t, useFormat } from "@sinch/intl";
import { B, DataGrid, Divider, Grid, P, StructuredText, Text, TimeRange, WrapWords } from "@sinch/ui";
import { isValue } from "@sinch/utils";
import { isBefore, subHours } from "date-fns";
import { filter, isEmpty, map } from "ramda";
import React from "react";
import { requestAppointmentDetail } from "../Appointment";

function getDate(query, appointment) {
  const startTime = query(selectAppointment(appointment, "startTime"));

  return {
    icon: mdiCalendarToday,
    content: <Format dt={startTime} />,
  };
}

function getTime(query, appointment) {
  const { startTime, endTime } = query(selectAppointment(appointment));

  return {
    icon: mdiClockOutline,
    content: <TimeRange endTime={endTime} showLength startTime={startTime} />,
  };
}

function getLocation(query, appointment) {
  const location = query(selectAppointment(appointment, "location"));
  const { address } = query(selectLocation(location));

  return {
    icon: /* ICON */ mdiMapMarker,
    content: [<WrapWords>{address}</WrapWords>],
  };
}

function getContact(query, appointment) {
  const contact = query(selectAppointment(appointment, "contact"));

  if (!contact || isEmpty(contact)) {
    return null;
  }

  return {
    icon: /* ICON */ mdiPhone,
    content: contact || t("Appointment.noContactProvided"), // No contact provided
  };
}

const cardContentSelectors = [
  getDate,
  getTime,
  getLocation,
  getContact,
  /* */
];

export function AppointmentDetailContent({ appointment }) {
  const { dt } = useFormat();
  const { selectEntity, queryEntity } = useData(requestAppointmentDetail);
  const resolve = map((selector) => selector(queryEntity, appointment));

  const data = filter(isValue, resolve(cardContentSelectors));

  const { startTime, signOffTimeInterval } = selectEntity(selectAppointment(appointment));
  const signOffTime = subHours(startTime, signOffTimeInterval);
  const canSignOff = isBefore(Date.now(), signOffTime);

  const intervalBold = <b>{t("hoursCount", { hours: signOffTimeInterval })}</b>;
  const signOffTimeBold = <B>{dt.full(signOffTime)}</B>;
  /*
   * todo: reuse status text components from AppointmentDetail module
   */
  return (
    <>
      <DataGrid data={data} small />
      <Divider />
      {canSignOff ? (
        <Text>
          {t("Appointment.signOffLimitNotice", {
            signOffTime: signOffTimeBold, // Z této události se lze odhlásit do {{signOffTime}}.
          })}
        </Text>
      ) : (
        <Grid spacing="inner" vertical>
          <StructuredText>
            <P bold>
              {t("Appointment.cannotSignOffNotice") /* Z této události se již nelze odhlásit */}{" "}
              {
                t("Appointment.signOffLimitAfter", {
                  interval: intervalBold,
                }) /* Limit pro odhlášení byl <b>{interval} h</b> před začátkem události. */
              }
            </P>
            <P>
              {
                t(
                  "Appointment.contactAdminAtProblemNotice"
                ) /* Pokud máš závažné důvody proč na událost nemůžeš dorazit, kontaktuj, prosím, administrátory. */
              }
            </P>
          </StructuredText>
        </Grid>
      )}
    </>
  );
}
