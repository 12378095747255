import { PagingParams, PagingResult, useData, useSearchParamsCustom } from "@sinch/core";
import { MuiDataTablePagination, useScroll } from "@sinch/ui";
import React, { ReactElement, useCallback } from "react";

export function SearchParamsPagination({ defaultLimit }: { defaultLimit?: number }): ReactElement {
  const {
    searchParams: { page = 1, limit = defaultLimit },
    updateSearchParams,
  } = useSearchParamsCustom<PagingParams>();

  const scrollTo = useScroll();

  /**
   * todo: metadata access should be independent of actual response
   */
  /* @ts-expect-error */
  const { selectMeta } = useData<{ meta: PagingResult }>();
  const { count } = selectMeta();

  const changePage = useCallback(
    (e, p) => {
      updateSearchParams({ page: p + 1 });
      scrollTo("top");
    },
    [updateSearchParams]
  );

  const changeLimit = useCallback(
    (e) => {
      updateSearchParams({ limit: e.target.value });
    },
    [updateSearchParams]
  );

  return (
    <MuiDataTablePagination
      count={count}
      onChangePage={changePage}
      onChangeRowsPerPage={changeLimit}
      page={page - 1}
      rowsPerPage={limit}
    />
  );
}
