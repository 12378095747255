import { Fk, User } from "@sinch/entity";
import { Nullable } from "@sinch/types";

export enum UserRoles {
  Admin = "admin",
  Worker = "worker",
}

/** todo: use string values enum for better debugging */
export interface UserRole {
  id: number;
  name: string;
}

/**
 * Restricts application functionality accessible by current user.
 *
 * Keys are not present if functionality is not restricted.
 *
 * If restricted, value contains explanation message displayed for the user.
 */
export interface Restrictions {
  /**
   * User account is completely blocked because of reasons explained in message
   * provided by admin.
   */
  access?: string;

  /**
   * User can not sign up for attendances because of unspecified reasons,
   * which should be explained in message provided by admin.
   */
  penalty?: number;

  /**
   * User can not sign up for attendances because of pending penalties.
   *
   * Displays warning when value is equal to zero and applies restrictions
   * when positive value received.
   */
  conditionalPenalty?: number;

  /**
   * User can not sign up for attendances because of incomplete profile.
   */
  incompleteProfile?: boolean | string[];

  incompleteAttributesCounts?: { profile: number; personal: number };

  positionAccess?: boolean;

  hibernated?: boolean | Date;

  portraitMandatory?: boolean;
}

export interface CurrentUser {
  id: Fk<User>;

  language: string;

  locale: Nullable<string>;

  referralBonus?: number;

  referralCode?: string;

  remainsAttendancesToInvite: number;

  restrictions: Restrictions;

  roles: {
    admin: UserRole[];
    client: UserRole[];
    worker: UserRole[];
    superadmin: UserRole[];
  };

  possessed?: boolean;

  currentRole: "worker" | "admin";
}
