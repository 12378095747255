import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { append, contains, curry, equals, ifElse, intersection, isEmpty, isNil, without } from "ramda";
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";

type SelectionSet<T> = [T, Dispatch<SetStateAction<T>>];

const SelectionSetContext = createCheckedContext<SelectionSet<any[]>>("SelectionSet");
const { use } = SelectionSetContext;

export function SelectionSetProvider({ children }: ChildrenProps): ReactElement {
  const selectionState = useState<any[]>([]);

  return <SelectionSetContext value={selectionState}>{children}</SelectionSetContext>;
}

interface UseSelectionSetProps<T> {
  selected: T[];
  setSelected: Dispatch<SetStateAction<T[]>>;
  toggleSelected: (item: T) => void;
}

export function useSelectionSet<T>(allowedList: T[] | null = null): UseSelectionSetProps<T> {
  const [selected, setSelected] = use();

  const toggleListItem = curry((value: T, list: T[]) => ifElse(contains(value), without([value]), append(value))(list));

  function handleToggle(item: any) {
    setSelected((prevState) => toggleListItem(item)(prevState));
  }

  useEffect(() => {
    if (!isNil(allowedList) && !equals(selected, intersection(selected, allowedList))) {
      setSelected((prevState) => intersection(prevState, allowedList));
    }
    if (!isEmpty(selected)) {
      // setSelected([]);
    }
  }, [allowedList]);

  return {
    selected,
    setSelected,
    toggleSelected: handleToggle,
  };
}
