import { makeStyles } from "@material-ui/core/styles";
import { Box as MuiBox, Tooltip } from "@material-ui/core";
import { FileId, useFiles } from "@sinch/core";
import { ChildrenProps, toElement } from "@sinch/utils";
import React, { ReactElement } from "react";
import { FileIcon } from "./FileIcon";

export interface AnchorProps extends ChildrenProps {
  href?: string;
}

export function Anchor({ children, href }: AnchorProps): ReactElement {
  return href ? (
    <a href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    toElement(children)
  );
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    display: "flex",
    alignItems: "center",
    color: palette.secondary.main,

    "& > svg": {
      marginRight: spacing(0.5),
    },
  },
}));

export interface FileAnchorProps extends ChildrenProps {
  file?: FileId;
  placeholder?: string;
}

export function FileAnchor({
  children,
  file,
  placeholder,
}: FileAnchorProps): ReactElement {
  const styles = useStyles();
  const storage = useFiles();

  if (file) {
    const { name, type, url } = storage(file);
    return (
      <Anchor href={url}>
        <span className={styles.content}>
          <FileIcon type={type} />
          <Tooltip title={name}>
            <span>{placeholder || name}</span>
          </Tooltip>
        </span>
      </Anchor>
    );
  }

  return toElement(children);
}
