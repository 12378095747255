import { useSearchParamsCustom } from "@sinch/core";
import { t } from "@sinch/intl";
import { Box, Header } from "@sinch/ui";
import React, { ReactElement } from "react";
import { PositionSearchUrlParams } from "../Search";

function emptySearchMessage({ ignoreCapacity, ignoreRating }: PositionSearchUrlParams): string {
  if (ignoreCapacity) {
    return ignoreRating
      ? t("PositionSearch.empty.ignoreRatingIgnoreCapacity")
      : t("PositionSearch.empty.withRatingIgnoreCapacity");
  }
  return ignoreRating
    ? t("PositionSearch.empty.ignoreRatingWithCapacity")
    : t("PositionSearch.empty.withRatingWithCapacity");
}

export function PositionListEmptyMessage(): ReactElement {
  const { searchParams } = useSearchParamsCustom<PositionSearchUrlParams>();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="24em"
      justifyContent="center"
      textAlign="center"
    >
      <Header color="neutral">{emptySearchMessage(searchParams)}</Header>
    </Box>
  );
}
