import { defineRequest } from "@sinch/core";
import { Fk, Position } from "@sinch/entity";

export const requestAttendanceConfirm = defineRequest("Positions/AttendanceConfirm")
  .withParams<{ positionId: Fk<Position> }>()
  .withEmptyResponse()
  .withCreator((params: { positionId: Fk<Position> }) => ({ params }));

export const requestAttendanceDecline = defineRequest("Positions/AttendanceDecline")
  .withParams<{ positionId: Fk<Position> }>()
  .withEmptyResponse()
  .withCreator((params: { positionId: Fk<Position> }) => ({ params }));

export const applicantConfirm = defineRequest("Positions/ApplicantConfirm")
  .withParams<{ positionId: Fk<Position> }>()
  .withEmptyResponse()
  .withCreator((params: { positionId: Fk<Position> }) => ({ params }));

export const applicantDecline = defineRequest("Positions/ApplicantDecline")
  .withParams<{ positionId: Fk<Position> }>()
  .withEmptyResponse()
  .withCreator((params: { positionId: Fk<Position> }) => ({ params }));
