import { makeStyles } from "@material-ui/core/styles";
import { t, useIntl } from "@sinch/intl";
import { GeoPoint } from "@sinch/types";
import React, { ReactElement } from "react";
import { Marker, StaticGoogleMap } from "react-static-google-map";
import { Box } from "../Layout";
import { Header } from "../Text";

const useStyles = makeStyles({
  container: {
    height: "32em",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    alignItems: "center",
  },
  map: {
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
    width: "640px",
    height: "480px",
  },
});

export interface MapProps {
  point?: GeoPoint;
  apiKey?: string;
}
export function Map({ point, apiKey }: MapProps): ReactElement {
  const styles = useStyles();
  const { safeLanguage } = useIntl();

  if (!point) {
    return <MapEmptyMessage />;
  }

  const { lat, lng } = point;

  return (
    <a
      className={styles.container}
      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
      rel="noreferrer"
      target="_blank"
    >
      <StaticGoogleMap apiKey={apiKey} className={styles.map} language={safeLanguage} size="640x480" zoom={16}>
        <Marker color="red" location={`${lat},${lng}`} />
      </StaticGoogleMap>
    </a>
  );
}

function MapEmptyMessage() {
  return (
    <Box alignItems="center" display="flex" height="24em" justifyContent="center">
      <Header color="primary">{t("Map.missingCoordinates")}</Header> {/* Nebyly zadány GPS souřadnice místa práce. */}
    </Box>
  );
}
