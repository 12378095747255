import { AccordionProps } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useData } from "@sinch/core";
import { Fk, Profession, selectProfession } from "@sinch/entity";
import { SwitchInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { descend, isEmpty, pipe, pluck, prop, propEq, reject, sort, sortBy, values } from "ramda";
import React, { ReactElement } from "react";
import { requestProfilePersonal } from "../api";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { useFieldListMeta } from "../utils";

export interface EditResidenceParams {
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
}

export function EditProfession({ expanded, onExpansionChange }: EditResidenceParams): ReactElement | null {
  const { errors } = useFieldListMeta([`addressSame`]);

  const { selectEntity, selectResult } = useData(requestProfilePersonal);

  const { professions: professionVals } = selectResult();

  const professionIds = pipe<Array<{ id: Fk<Profession> }>, { [key: number]: Fk<Profession> }, Array<Fk<Profession>>>(
    pluck("id"),
    values
  )(professionVals);
  const professions = selectEntity(selectProfession(professionIds));

  if (isEmpty(reject(propEq("choosable", false))(professions))) {
    return null;
  }

  const sortedProfessions = pipe<Profession[], Profession[], Profession[]>(
    sortBy(prop("name")),
    sort(descend(prop("choosable")))
  )(professions);

  return (
    <ProfileSettingAccordionItem
      error={errors > 0}
      expanded={expanded}
      filled={0}
      label={t("Profile.display.professions")}
      onExpansionChange={onExpansionChange}
      total={0}
    >
      <MuiGrid container direction="column">
        {sortedProfessions.map(({ name, id, choosable }) => (
          <MuiGrid key={id} item>
            <SwitchInput disabled={!choosable} label={name} name={`professions.${id}`} />
          </MuiGrid>
        ))}
      </MuiGrid>
    </ProfileSettingAccordionItem>
  );
}
