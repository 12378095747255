import { Key } from "@sinch/types";
import { filter, indexBy, prop } from "ramda";
import { isTruthy } from "ramda-adjunct";

/**
 * Unfortunately NaN value cannot be added to excluded values because it doesn't
 * have its own specific type implemented in TypeScript.
 */
type WithoutFalsy<T> = Exclude<T, undefined | null | false | 0 | "">;

/**
 * Removes falsy values from array or object.
 */
interface RejectFalsy {
  <T>(list: readonly T[]): WithoutFalsy<T>[];

  <T, K extends Key>(record: Partial<Record<K, T>>): Partial<
    Record<K, WithoutFalsy<T>>
  >;
}

export const rejectFalsy: RejectFalsy = filter(isTruthy) as RejectFalsy;

export function indexByProp<TKey extends Key>(
  p: TKey
): <TVal extends Key, TRec extends Record<TKey, TVal>>(
  records: TRec[]
) => Record<TVal, TRec> {
  /* @ts-expect-error */
  return indexBy(prop(p));
}

/*
const indexer = indexByProp("key");

const numbers = [
  { key: 1, binary: "01", textual: "one" },
  { key: 2, binary: "10", textual: "two" },
  { key: 3, binary: "11", textual: "three" },
];

const numbersIndexed = indexer(numbers);
// Record<Key, {key: number, binary: string, textual: string}>

const colors = [
  { key: "R", hex: "#ff0000", name: "red" },
  { key: "G", hex: "#00ff00", name: "green" },
  { key: "B", hex: "#0000ff", name: "blue" },
];

const colorsIndexed = indexer(colors);
// Record<Key, {key: string, hex: string, name: string}>

const mixedData = [
  { key: "foo", foo: true },
  { key: "bar", foo: false, bar: true },
];

const mixedIndexed = indexer(mixedData);
// Record<Key, {key: string, foo: boolean, bar?: undefined}>
*/
