import { useData } from "@sinch/core";
import { Fk, Position, selectLocation, selectPosition } from "@sinch/entity";
import { WrapWords } from "@sinch/ui";
import React from "react";
import { requestPositionList } from "../../Position/List/api";

export function PositionPlace({ id }: { id: Fk<Position> }) {
  const { selectEntity } = useData(requestPositionList);
  const location = selectEntity(selectPosition(id, "location"));
  const name = selectEntity(selectLocation(location, "name"));
  return <WrapWords>{name}</WrapWords>;
}
