import { isErrorResponse, RequestData, selectGlobalErrorMessages, useRequest } from "@sinch/core";
import { t } from "@sinch/intl";
import { Callback, Consumer } from "@sinch/types";
import { Header, ModalDialog, Text } from "@sinch/ui";
import { ChildrenProps, noop, useBlocker, useMounted } from "@sinch/utils";
import { Form as FormikForm, Formik as FormikContainer, FormikErrors, FormikHelpers, useFormikContext } from "formik";
import { FormikConfig } from "formik/dist/types";
import { pluck } from "ramda";
import { isFunction } from "ramda-adjunct";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { FormContextProvider } from "./FormContext";
import { useFormStatus } from "./FormStatus";
import { selectFieldErrors } from "./selectFieldErrors";
import { FormValidator } from "./validation";
import { FormValues } from "./Values";

/*
 * todo: encapsulate Formik so it can be replaced by `react-hooks-form`
 *  - performance problems
 *  - non-extendable api
 *  - broken cleanup process
 *    (submit handlers calls setState on already unmounted form)
 */

export interface FormConfig<TValues extends FormValues> extends ChildrenProps {
  initialValues: TValues;

  validate?: FormValidator<TValues>;

  validateOnMount?: boolean;

  blockRedirect?: boolean;
}

export type FormSubmitHandler<TValues extends FormValues> = {
  submitHandler: (values: TValues, helpers: { setErrors: Consumer<FormikErrors<TValues>> }) => void | Promise<void>;

  submitRequest?: never;

  onSuccess?: never;
};

export type FormSubmitRequest<TValues extends FormValues> = {
  submitHandler?: never;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  submitRequest: (values: TValues) => RequestData<any, any>;

  onSuccess?: () => void;
};

export type FormProps<TValues extends FormValues> = FormConfig<TValues> &
  (FormSubmitHandler<TValues> | FormSubmitRequest<TValues>);

export function Form<TValues extends FormValues>({
  children,
  initialValues,
  submitHandler,
  submitRequest,
  onSuccess,
  validate,
  validateOnMount,
  blockRedirect,
  ...props
}: FormProps<TValues> & Omit<FormikConfig<TValues>, "onSubmit">): ReactElement {
  const mounted = useMounted();
  const dispatch = useRequest();

  const [success, setSuccess] = useState(false);
  const [status, updateStatus] = useFormStatus();
  const [messages, setMessages] = useState<string[]>([]);

  /**
   * We have to update `submitting` status manually to prevent double submit
   * because formik internal `isSubmitting` is set before calling `onSubmit`
   * so it never triggers our handler
   */
  const handleSubmit = useCallback(
    async (values: TValues, { setErrors }: FormikHelpers<TValues>) => {
      if (!status.ready) return;

      updateStatus({ submitting: true });

      if (isFunction(submitHandler)) {
        await submitHandler(values, { setErrors });
      }

      if (isFunction(submitRequest)) {
        const response = await dispatch(submitRequest(values));
        mounted(() => {
          if (isErrorResponse(response)) {
            setMessages(pluck("text", selectGlobalErrorMessages(response)));
            setErrors(selectFieldErrors<TValues>(response));
            setSuccess(false);
          }
        });
        if (!isErrorResponse(response)) {
          setSuccess(true);
        }
        if (!isErrorResponse(response) && onSuccess) {
          onSuccess();
        }
      }

      mounted(() => updateStatus({ submitting: false }));
    },
    [dispatch, mounted, status.ready, submitHandler, submitRequest, updateStatus, onSuccess]
  );

  return (
    <FormikContainer<TValues>
      {...props}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount={validateOnMount}
    >
      <FormikForm>
        <FormContextProvider<TValues> messages={messages} status={status} updateStatus={updateStatus}>
          {blockRedirect && <BlockDialog success={success} />}
          {children}
        </FormContextProvider>
      </FormikForm>
    </FormikContainer>
  );
}

function BlockDialog({ success }: { success: boolean }) {
  const [confirmed, setConfirmed] = useState(false);
  const [open, setOpen] = useState(false);
  const [continueCallback, setContinueCallback] = useState<{ retry: Callback }>();
  const { dirty } = useFormikContext();

  useBlocker(({ retry }) => {
    setOpen(true);
    setContinueCallback({ retry });
  }, dirty && !success && !confirmed);

  useEffect(() => {
    if ((confirmed || success) && continueCallback?.retry) {
      continueCallback.retry();
      setContinueCallback(undefined);
    }
  }, [confirmed, success, continueCallback]);

  return (
    <ModalDialog
      actions={{
        cancel: {
          action: () => setOpen(false),
          color: "primary",
          label: t("action.cancel"),
        },
        confirm: {
          action: () => {
            setOpen(false);
            setConfirmed(true);
          },
          color: "primary",
          label: t("ok"),
        },
      }}
      onClose={noop}
      open={open}
      width="xs"
    >
      <Header level={2}>{t("Form.unsavedChangesTitle")}</Header>
      <Text>{t("Form.unsavedChangesMessage")}</Text>
    </ModalDialog>
  );
}
