import { Box, Grid as MuiGrid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { mdiAlertCircleOutline } from "@mdi/js";

import {
  DataProvider,
  HashRoute,
  responseHandlerKey,
  responseHandlerSequence,
  ServerLvlToType,
  ServerMessage,
  useIntParams,
  useRefresh,
} from "@sinch/core";
import { t } from "@sinch/intl";
import { Header, Icon, ListBase, LoadingOverlay, useScroll, useSnackbar, useTitleBar } from "@sinch/ui";
import { VariantType } from "notistack";
import { is, prop, propEq, reject } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { ShiftFeedback } from "../../Shift";
import { requestPositionAttendanceJoin, requestPositionAttendanceLeave, requestPositionDetail } from "./api";
import { PositionDetail } from "./PositionDetail";

const useStyles = makeStyles((theme) => ({
  large: {
    fontSize: theme.typography.pxToRem(120),
  },
  center: {
    justifyContent: "center",
    maxWidth: "50%",
    flexBasis: "50%",
  },
}));

export function PositionDetailContainer(): ReactElement {
  useTitleBar({
    container: "core:position-detail",
    locationTitle: t("Shift.detail"),
    showBack: true,
    // backHandler: () => navigate("/position"),
  });
  const styles = useStyles();
  const [notFound, setNotFound] = useState<ServerMessage[] | boolean>(false);
  const scrollTo = useScroll();

  const { position } = useIntParams();

  useEffect(() => setNotFound(false), [position]);

  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh({ interval: 60000 });
  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestPositionAttendanceJoin,
          () => {
            snackbar("success", t("Position.signUpSuccess"));
            scrollTo("top");
            refresh();
          },
          ({ response }) => {
            if (isNilOrEmpty(response.messages)) {
              snackbar("error", t("Position.signUpFail"));
            } else {
              (response.messages || []).forEach(({ level, text }) => {
                snackbar(ServerLvlToType[level] as VariantType, text);
              });
            }
            refresh();
          }
        ),
        responseHandlerKey(
          requestPositionAttendanceLeave,
          () => {
            snackbar("success", t("Position.signOffSuccess"));
            scrollTo("top");
            refresh();
          },
          ({ response }) => {
            if (isNilOrEmpty(response.messages)) {
              snackbar("error", t("Position.signUpFail"));
            } else {
              (response.messages || []).forEach(({ level, text }) => {
                snackbar(ServerLvlToType[level] as VariantType, text);
              });
            }
            refresh();
          }
        ),
        responseHandlerKey(
          requestPositionDetail,
          () => {},
          // eslint-disable-next-line consistent-return
          (reqRes) => {
            const { response } = reqRes;
            if (response.status !== 404) {
              return reqRes;
            }
            setNotFound(reject(propEq("level", "DEBUG"), response.messages));
          }
        ),
      ]),
    [refresh, snackbar]
  );

  /**
   * Send new request when navigation to different shift.
   *
   * todo: use location state to avoid sending new request
   *  when switching to other position in the same shift
   *  (we already have all the data from previous request)
   */
  const request = useMemo(() => requestPositionDetail(position), [position]);

  if (notFound) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <Alert className={styles.center} icon={false} severity="error" variant="outlined">
          <MuiGrid alignItems="center" container direction="column" spacing={4}>
            <MuiGrid item>
              <Header level={0}>{`${t("error")} 404`}</Header>
            </MuiGrid>
            <MuiGrid item>
              <Icon className={styles.large} color="error" icon={mdiAlertCircleOutline} size="large" />
            </MuiGrid>
            {is(Array, notFound) && (
              <MuiGrid item>
                <ListBase contentSelector={prop("text")} data={notFound as ServerMessage[]} />
              </MuiGrid>
            )}
          </MuiGrid>
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <DataProvider
        handler={responseHandler}
        progress={<LoadingOverlay />}
        refresh={refreshToken}
        refresher={refresh}
        request={request}
      >
        <PositionDetail />
        <HashRoute path="shift/:shift/feedback/*">
          {/* @ts-expect-error */}
          <ShiftFeedback />
        </HashRoute>
      </DataProvider>
    </>
  );
}
