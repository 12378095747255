import { isErrorResponse, RequestCreatorAny } from "../contract";
import { responseHandler, ResponseHandler, ResponseHandlerFunc } from "./ResponseHandler";

export function responseHandlerKey<TCreator extends RequestCreatorAny>(
  { key }: TCreator,
  onSuccess: ResponseHandlerFunc<TCreator>,
  onError?: ResponseHandlerFunc<TCreator>
): ResponseHandler<TCreator> {
  return responseHandler<TCreator>("key", key, (reqres) => {
    const { request, response } = reqres;
    if (!new RegExp(key.replaceAll(/:\w+/g, "[^/]+")).test(request.key)) return reqres;
    if (isErrorResponse(response)) {
      return onError ? onError(reqres) : reqres;
    }
    return onSuccess(reqres);
  });
}
