import { useEffect } from "react";
import { useLocation } from "react-router";

export const useScrollResetOnNavigation = (): null => {
  const { pathname } = useLocation();
  const scrollTo = useScroll();

  useEffect(() => {
    scrollTo("top");
  }, [pathname]);

  return null;
};

type Positions = "top" | "bottom";

export function useScroll(): (position: Positions | number) => void {
  return (position: Positions | number) => {
    switch (position) {
      case "top":
        window.scrollTo(0, 0);
        break;
      case "bottom":
        window.scrollTo(0, document.body.scrollHeight);
        break;
      default:
        window.scrollTo(0, position);
        break;
    }
  };
}
