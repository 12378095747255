/* eslint-disable react/jsx-props-no-spreading */
import { QueryEntity, ResponseOf, useData } from "@sinch/core";
import { Appointment, Fk, Position, selectAppointment, selectPosition } from "@sinch/entity";
import { BiFunc, Maybe } from "@sinch/types";
import { Center, DateChip, Grid } from "@sinch/ui";
import { isSameDay } from "date-fns";
import { concat, includes, isEmpty, map, prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "./api";
import { AppointmentCard, PositionCard } from "./components";
import { DashboardPositionEmptyMessage } from "./DashboardEmptyMessage";

export type PositionCardListSelector<T> = BiFunc<
  QueryEntity<ResponseOf<typeof requestDashboardAttendanceList>>,
  Fk<Position>,
  Maybe<T>
>;

function showChip(startTime: Date, previousStartTime?: Date): boolean {
  if (!previousStartTime) return true;

  return !isSameDay(startTime, previousStartTime);
}

interface PositionCardListProps {
  positions: Fk<Position>[];
  applicants: Fk<Position>[];
  appointments?: Fk<Appointment>[];
}

export function AgendaCardList({ applicants, positions, appointments }: PositionCardListProps): ReactElement {
  const { selectEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { incomingPositionIds, applicantPositionIds, incomingAppointmentIds } = selectResult();

  const isListEmpty =
    isEmpty(incomingPositionIds || []) && isEmpty(incomingAppointmentIds || []) && isEmpty(applicantPositionIds || []);

  if (isListEmpty) {
    return <DashboardPositionEmptyMessage />;
  }

  const appointmentList = appointments ? selectEntity(selectAppointment(appointments)) : [];
  const positionsList = selectEntity(selectPosition(concat(positions, applicants)));
  const appointmentItems = appointmentList.map(({ id, startTime }: Pick<Appointment, "id" | "startTime">) => ({
    id,
    type: "appointment",
    startTime,
  }));
  const positionItems = map(
    (position) => ({ id: position.id, type: "position", startTime: position.startTime }),
    positionsList
  );
  const sortedItems = sortBy(prop("startTime"), concat(appointmentItems, positionItems));

  return (
    <Grid spacing="inner" vertical>
      {sortedItems.map(({ id, type, startTime }, i) => [
        showChip(startTime, sortedItems[i - 1]?.startTime) && (
          <Center>
            <DateChip color="primary" date={startTime} large />
          </Center>
        ),
        type === "appointment" ? (
          <AppointmentCard appointment={id} />
        ) : (
          <PositionCard isApplicant={includes(id, applicants)} position={id} />
        ),
      ])}
    </Grid>
  );
}
