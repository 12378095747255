import {
  createEntitySelector,
  EntityContainer,
  EntitySelector,
} from "../entity";
import { Rating } from "./Rating";

export type RatingContainer = EntityContainer<Rating, "Rating">;

export const selectRating: EntitySelector<Rating> = createEntitySelector<
  Rating,
  RatingContainer
>("Rating");
