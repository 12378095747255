import { useBusinessRules } from "@sinch/core";
import { ClosingProgress, TransportAttendanceRole } from "@sinch/entity";
import { ToggleButtonProps } from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { pick } from "ramda";
import { Optional } from "utility-types";
import { TransportRoleOptions } from "../../Closing";
import { useClosingStatusProps } from "../hooks";

export function usePresenceToggleButtons({
  statusCounts = {},
}: {
  statusCounts: Optional<Record<ClosingProgress, number | string>>;
}): ToggleButtonProps<ClosingProgress>[] {
  const { getProps } = useClosingStatusProps();
  const { shiftClosingWorkBreaks } = useBusinessRules();

  const btnProps = (status: ClosingProgress) => pick(["color", "icon"])(getProps(status));
  return rejectFalsy([
    {
      value: ClosingProgress.Pending,
      ...btnProps(ClosingProgress.Pending),
      label: statusCounts[ClosingProgress.Pending] || 0,
      color: "default",
      tooltip: getProps(ClosingProgress.Pending).label,
    },
    {
      value: ClosingProgress.Present,
      ...btnProps(ClosingProgress.Present),
      label: statusCounts[ClosingProgress.Present] || 0,
      tooltip: getProps(ClosingProgress.Present).label,
    },
    shiftClosingWorkBreaks && {
      value: ClosingProgress.Break,
      ...btnProps(ClosingProgress.Break),
      label: statusCounts[ClosingProgress.Break] || 0,
      tooltip: getProps(ClosingProgress.Break).label,
    },
    {
      value: ClosingProgress.Absent,
      ...btnProps(ClosingProgress.Absent),
      label: statusCounts[ClosingProgress.Absent] || 0,
      tooltip: getProps(ClosingProgress.Absent).label,
    },
    {
      value: ClosingProgress.Finished,
      ...btnProps(ClosingProgress.Finished),
      label: statusCounts[ClosingProgress.Finished] || 0,
      tooltip: getProps(ClosingProgress.Finished).label,
    },
  ]);
}

export function useTransportPresenceToggleButtons<T extends number | string>({
  statusCounts = {},
}: {
  statusCounts: Optional<Record<ClosingProgress, number>>;
}): ToggleButtonProps<TransportAttendanceRole>[] {
  const transportRoleOpts = TransportRoleOptions();

  const btnProps = (transport: TransportAttendanceRole) => pick(["color", "icon"])(transportRoleOpts[transport]);
  return [
    {
      value: TransportAttendanceRole.Driver,
      ...btnProps(TransportAttendanceRole.Driver),
      label: statusCounts[TransportAttendanceRole.Driver] || 0,
    },
    {
      value: TransportAttendanceRole.Passenger,
      ...btnProps(TransportAttendanceRole.Passenger),
      label: statusCounts[TransportAttendanceRole.Passenger] || 0,
    },
    {
      value: TransportAttendanceRole.NoTransport,
      ...btnProps(TransportAttendanceRole.NoTransport),
      label: statusCounts[TransportAttendanceRole.NoTransport] || 0,
    },
  ];
}
