import { WalletTransactionType } from "@sinch/entity";
import { t } from "@sinch/intl";

export const transactionTypes = () => [
  { value: WalletTransactionType.Wage, label: t("WalletType.Wage") },
  { value: WalletTransactionType.Penalty, label: t("WalletType.Penalty") },
  { value: WalletTransactionType.Service, label: t("WalletType.Service") },
  { value: WalletTransactionType.Other, label: t("WalletType.Other") },
  { value: WalletTransactionType.Payout, label: t("WalletType.Payout") },
  { value: WalletTransactionType.Bonus, label: t("WalletType.Bonus") },
  { value: WalletTransactionType.Correction, label: t("WalletType.Correction") },
  { value: WalletTransactionType.Equipment, label: t("WalletType.Equipment") },
];
