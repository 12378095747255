import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MuiTooltip from "@material-ui/core/Tooltip";
import { IconId } from "@sinch/types";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { Optional } from "utility-types";
import { ActionContainer, resolveAction } from "../actions";
import { Icon } from "../Icon";
import { createPaletteStyles } from "../paletteStyles";

const paletteStyles = createPaletteStyles(({ main, dark, contrastText }) => ({
  color: contrastText,
  backgroundColor: main,
  "&:hover": {
    backgroundColor: dark,
  },
}));

const useStyles = makeStyles(paletteStyles);

/**
 * todo: consider reducing IconSize options to medium and small
 *  - possibly propagate to Icon component as well (large not used)
 */
export interface IconButtonProps
  extends Optional<ActionContainer, "action" | "label"> {
  icon: IconId;

  size?: "default" | "small";

  type?: "button" | "reset" | "submit";
}

export function IconButton({
  action,
  color,
  disabled,
  icon,
  label,
  size,
  type,
}: IconButtonProps): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(action);

  return (
    <MuiTooltip arrow title={label ?? ""}>
      <MuiIconButton
        className={clsx(color && styles[color])}
        color="default"
        disabled={disabled}
        type={type}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...actionProps}
      >
        <Icon icon={icon} size={size} />
      </MuiIconButton>
    </MuiTooltip>
  );
}
