import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { t } from "@sinch/intl";
import { Button, Divider, Paper, routerLink } from "@sinch/ui";
import React, { ReactElement } from "react";
import { PositionSearch } from "../../Position";
import { requestDashboardAttendanceList } from "./api";

/**
 * todo: replace <br /> on the bottom with some spacing
 *  (probably can be solved by extracting TabsCard component)
 *
 * todo: consider extracting showAvailablePositions button
 *  to Shortcuts component instead so its consistent with mobile
 */
export function SearchTabs(): ReactElement {
  const { selectResult } = useData(requestDashboardAttendanceList);

  const { availablePositionsCount } = selectResult();

  return (
    <Paper>
      <PositionSearch />
      <Divider label={t("display.or")} />
      <Box pb={2}>
        <Button action={routerLink("/position")} color="primary" variant="text">
          {t("Dashboard.showAvailablePositions")} ({availablePositionsCount})
        </Button>
      </Box>
    </Paper>
  );
}
