import { mdiAccountMultiple, mdiCrown, mdiAccount } from "@mdi/js";
import { useData } from "@sinch/core";
import { Fk, Position, selectPosition, WorkerRole } from "@sinch/entity";
import { Icon } from "@sinch/ui";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { requestPositionList } from "../../Position/List/api";

const PositionRoleIcon = React.memo(({ role }: { role: WorkerRole }) => {
  if (equals(role, WorkerRole.Backup)) {
    return <Icon color="secondary" icon={mdiAccountMultiple} size="small" />;
  }

  if (equals(role, WorkerRole.Crewboss)) {
    return <Icon color="colorCrewboss" icon={mdiCrown} size="small" />;
  }

  if (equals(role, WorkerRole.Worker)) {
    return <Icon icon={mdiAccount} size="small" />;
  }

  return null;
});

export function PositionRole({ id }: { id: Fk<Position> }): ReactElement | null {
  const { selectEntity } = useData(requestPositionList);
  const { role } = selectEntity(selectPosition(id));

  return <PositionRoleIcon role={role} />;
}
