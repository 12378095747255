import { GeoPoint } from "@sinch/types";
import { map } from "ramda";

export interface SearchResultItem {
  label: string;
  location: string;
  name: string;
  position: {
    lat: number;
    lon: number;
  };
  regionalStructure: { name: string; type: MapItemType }[];
  type: MapItemType;
}

export type MapItemType =
  | "regional"
  | "regional.country"
  | "regional.region"
  | "regional.municipality"
  | "regional.municipality_part"
  | "regional.street"
  | "regional.address"
  | "poi";

export interface MapItem {
  category: string;

  point: GeoPoint;

  address: string;

  label: [first: string, second: string];
}

function extractItem({ label, position, name, location }: SearchResultItem): MapItem {
  return {
    category: label,
    label: [name, location],
    address: location,
    point: {
      lat: position.lat,
      lng: position.lon,
    },
  };
}

/**
 * todo: handle optional values, for example some items doesn't have address
 */
export function extractResult(result: SearchResultItem[]): MapItem[] {
  return map(extractItem, result);
}
