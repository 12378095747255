import { useBusinessRules, useCurrentUser } from "@sinch/core";
import { EmailInput, Form, SubmitButton } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Divider, Grid, Text } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestReferralSubmit } from "./api";

export function ReferralForm(): ReactElement {
  const { curr } = useFormat();
  const { referralCode, referralBonus = 0, remainsAttendancesToInvite } = useCurrentUser();
  const { inviteBonusMinAttends } = useBusinessRules();

  const bonus = <b>{curr(referralBonus)}</b>;
  const code = <b>{referralCode}</b>;
  const remains = <b>{remainsAttendancesToInvite}</b>;
  return (
    <Form<{ email: string }> initialValues={{ email: "" }} submitRequest={({ email }) => requestReferralSubmit(email)}>
      {remainsAttendancesToInvite > 0 ? (
        <Text>{t("Referral.dialog.remainingNotice", { remains, bonus, count: remainsAttendancesToInvite })}</Text>
      ) : (
        <Grid spacing="inner" vertical>
          <Text>{t("Referral.dialog.description1", { bonus })}</Text>
          {referralBonus > 0 && (
            <Text>{t("Referral.dialog.description2", { count: inviteBonusMinAttends, bonus })}</Text>
          )}
          <Text>{t("Referral.dialog.description3", { code })}</Text>
          <Text>{t("Referral.dialog.description4")}</Text>
          <EmailInput label={t("Referral.form.email")} name="email" />
          <Divider />
          <SubmitButton label={t("Referral.action.send")} />
        </Grid>
      )}
    </Form>
  );
}
