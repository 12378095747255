import { TableProps } from "@material-ui/core";
import { defaultTo } from "ramda";
import React, { ReactElement } from "react";
import { DataTableBody } from "./DataTableBody";
import { DataTableCell } from "./DataTableCell";
import { DataTableHeader } from "./DataTableHeader";
import { DataTableRow } from "./DataTableRow";
import { DataTableConfig, DataTableContainerProps, DataTableDisplay, DataTableProps, DataTableRowLogic } from "./types";

/* eslint-disable react/jsx-props-no-spreading */

/**
 * todo: support generic types for display component props
 *
 * todo: improve the way how table columns are defined
 *  - define individual columns as Record<string, DataTableColumnProps>
 *  - pass array of column keys in which columns should be rendered
 *    (that way its much easier to show/hide or reorder columns,
 *    also overriding cell logic shouldn't be dependent on order)
 *
 * todo: extract string->selector generator using ramda as separate plugin
 *  providing particular functionality
 *  (entity query executor should be designed as separate plugin as well)
 *
 * todo: enhance logic resolver to support:
 *  - logic component (current implementation) when context is needed
 *  - evolve function when transformation can be expressed as
 *    `(...params) => (displayProps) => nextDisplayProps`
 *
 * todo: support Container/Table level logic for extending general
 *  functionality (sorting, grouping, filtering, search, selection,
 *  pagination, column ordering, editable)
 *
 * todo: implement display components supporting
 *  - fixed width columns
 *  - text align
 *  - fixed/sticky header
 *  - external/internal scrollbar
 *
 * todo: expose defaultLogic as DataTable.defaultLogic?
 */

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const defaultLogic = {
  Row: DataTableRow,
  Cell: DataTableCell,
};

export function DataTable<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>({
  children,
  columns,
  config,
  data,
  display,
  logic,
  ...props
}: DataTableContainerProps<TData, TColumn, TDisplay> & TableProps): ReactElement {
  const { Container, Table } = display;

  const table = {
    columns,
    config,
    data,
    display,
    // todo: remove default when withLogic factory step is implemented?
    //  (then update prop type from DTContainer to DT)
    logic: defaultTo(DataTableRow, logic),
  } as DataTableProps<TData, TColumn, TDisplay>;

  return (
    <Container>
      <Table {...props}>
        <DataTableHeader table={table} />
        <DataTableBody table={table} />
      </Table>
      {children}
    </Container>
  );
}

export interface WithDisplayComponent<TDisplay extends DataTableDisplay<any>> {
  <TData, TColumn extends string>(props: WithDisplayProps<TData, TColumn, TDisplay>): ReactElement;

  withConfig: <TData, TColumn extends string>(
    config: DataTableConfig<TData, TColumn, TDisplay>
  ) => WithConfigComponent<TData, TColumn, TDisplay>;
}

export interface WithDisplayProps<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>
  extends Omit<DataTableContainerProps<TData, TColumn, TDisplay>, "display"> {}

export interface WithConfigComponent<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>> {
  (props: WithConfigProps<TData, TColumn, TDisplay>): ReactElement;

  withLogic: (logic: DataTableRowLogic<TData, TColumn, TDisplay>) => WithLogicComponent<TData, TColumn, TDisplay>;

  rowLogic: (logic: DataTableRowLogic<TData, TColumn, TDisplay>) => DataTableRowLogic<TData, TColumn, TDisplay>;
}

export interface WithConfigProps<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>
  extends Omit<WithDisplayProps<TData, TColumn, TDisplay>, "config">,
    TableProps {}

export interface WithLogicComponent<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>> {
  (props: WithLogicProps<TData, TColumn, TDisplay>): ReactElement;
}

export interface WithLogicProps<TData, TColumn extends string, TDisplay extends DataTableDisplay<any>>
  extends Omit<WithConfigProps<TData, TColumn, TDisplay>, "logic"> {}

/*
 * todo: can we infer TColumn from passed config?
 *  (and would it simplify usage?)
 *
 * todo: review exported types naming
 *
 * todo: is it possible to extract individual build steps to reduce code nesting?
 */
DataTable.withDisplay = function withDisplay<TDisplay extends DataTableDisplay<any>>(
  display: TDisplay
): WithDisplayComponent<TDisplay> {
  /*
   */
  function DataTableWithDisplay<TData, TColumn extends string>(
    props: WithDisplayProps<TData, TColumn, TDisplay>
  ): ReactElement {
    return <DataTable {...props} display={display} />;
  }

  /*
   * todo: set default values of `config[column].logic` to `DataTableCell`
   */
  DataTableWithDisplay.withConfig = function withConfig<TData, TColumn extends string>(
    config: DataTableConfig<TData, TColumn, TDisplay>
  ): WithConfigComponent<TData, TColumn, TDisplay> {
    /*
     */
    function DataTableWithConfig(props: WithConfigProps<TData, TColumn, TDisplay>): ReactElement {
      return <DataTable {...props} config={config} display={display} />;
    }

    /*
     * todo: simplify api by embedding compose helper into the table
     *  - either pass logic component (=output of compose)
     *  - or pass array of components (=input of compose)
     *  - should be final DataTableLogic added automatically or not?
     */
    DataTableWithConfig.withLogic = function withLogic(
      logic: DataTableRowLogic<TData, TColumn, TDisplay>
    ): WithLogicComponent<TData, TColumn, TDisplay> {
      /*
       */
      function DataTableWithLogic(props: WithLogicProps<TData, TColumn, TDisplay>): ReactElement {
        return <DataTable {...props} config={config} display={display} logic={logic} />;
      }

      return DataTableWithLogic;
    };

    DataTableWithConfig.rowLogic = function createRowLogic(
      logic: DataTableRowLogic<TData, TColumn, TDisplay>
    ): DataTableRowLogic<TData, TColumn, TDisplay> {
      /*
       * todo: implement `createLogic` with inferred generics on return type
       *  ```
       *  DTwConfig.withLogic({
       *    Row: [
       *      DTwConfig.createLogic(LogicComponent | LogicFunction),
       *    ]
       *  });
       *  // return type resolution:
       *  (table, row) => RowLogic
       *  (table, row, column) => CellLogic
       *  ```
       */
      return logic;
    };

    return DataTableWithConfig;
  };

  return DataTableWithDisplay;
};
