import { Box } from "@material-ui/core";
import { mdiDelete } from "@mdi/js";

import { useData, useRequest } from "@sinch/core";
import { Fk, Reimbursement, ReimbursementStatus, selectReimbursement, selectShift, selectUser } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Button,
  DataList,
  Divider,
  FileList,
  HtmlContent,
  Identifier,
  InformationDialog,
  Text,
  useMobileLayout,
} from "@sinch/ui";
import { DialogControl } from "@sinch/ui/ApplicationLayout/Dialog/DialogControl";
import { rejectFalsy } from "@sinch/utils";
import { flatten, isEmpty, isNil } from "ramda";
import React, { ReactElement } from "react";
import { ReimbursementStatusElement } from "../../components";
import { requestPositionDetail } from "../../Position";
import { requestReimbursementDelete } from "./api";
import { ReimbursementDeleteContainer } from "./ReimbursementDeleteContainer";

export interface ReimbursementDetailProps {
  dialog: DialogControl;
  reimbursementId: Fk<Reimbursement>;
}

function ReimbursementDetailDialog({ dialog, reimbursementId }: ReimbursementDetailProps): ReactElement | null {
  const { curr, dt } = useFormat();
  const mobile = useMobileLayout();
  const dispatch = useRequest();

  const { selectEntity } = useData(requestPositionDetail);

  if (!selectEntity(selectReimbursement(reimbursementId))) {
    // reimbursement not found, close dialog
    dialog.close();
    return null;
  }

  const { status, description, adminNote, amount, created, processedBy, shift, attachment } = selectEntity(
    selectReimbursement(reimbursementId)
  );

  const { name } = selectEntity(selectUser(processedBy));
  const { name: shiftName, id: shiftId } = selectEntity(selectShift(shift));

  const parameters = [
    {
      label: t("status.title"),
      content: <ReimbursementStatusElement status={status} />,
    },
    status === ReimbursementStatus.Rejected && {
      label: t("Reimbursement.rejectedBy"),
      content: name,
    },
    !isNil(adminNote) &&
      !isEmpty(adminNote) && {
        label: t("Reimbursement.adminNote"),
        content: <HtmlContent html={adminNote || ""} />,
      },
    {
      label: t("dateAndTime"),
      content: dt(created),
    },
    {
      label: t("amount"),
      content: curr(amount),
    },
    {
      label: t("Shift.title"),
      content: (
        <Text separator={" "}>
          <Identifier entity="shift" id={shiftId} />
          {shiftName}
        </Text>
      ),
    },
    {
      label: t("attachments"),
      content: <FileList files={attachment} />,
    },
    !isNil(description) &&
      !isEmpty(description) && {
        label: t("description"),
        content: <HtmlContent html={description || ""} />,
      },
  ];
  return (
    <InformationDialog
      actions={
        status === ReimbursementStatus.Pending && !mobile
          ? {
              action: () => {
                dispatch(requestReimbursementDelete(reimbursementId));
              },
              label: t("deleteRequest"),
              color: "error",
              icon: mdiDelete,
            }
          : undefined
      }
      control={dialog}
      hashKey="deleteReimbursementRequest"
      title={t("Reimbursement.detailDialogTitle")}
      width="xs"
    >
      <DataList data={rejectFalsy(flatten(parameters))} disableIcons disablePadding />
      {mobile && status === ReimbursementStatus.Pending && (
        <>
          <Divider />
          <Box textAlign="right">
            <Button
              action={() => {
                dispatch(requestReimbursementDelete(reimbursementId));
              }}
              color="error"
              icon={mdiDelete}
              stretch
              variant="text"
            >
              {t("deleteRequest")}
            </Button>
          </Box>
        </>
      )}
    </InformationDialog>
  );
}

export function ReimbursementDetail({ dialog, reimbursementId }: ReimbursementDetailProps): ReactElement | null {
  return (
    <ReimbursementDeleteContainer onSuccess={dialog.close}>
      <ReimbursementDetailDialog dialog={dialog} reimbursementId={reimbursementId} />
    </ReimbursementDeleteContainer>
  );
}
