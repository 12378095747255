import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import {
  Appointment,
  AppointmentAttendanceContainer,
  AppointmentContainer,
  AppointmentTypeContainer,
  Fk,
  LocationContainer,
} from "@sinch/entity";

export const requestAppointmentList = defineRequest("Appointments/Index")
  .withEmptyParams()
  .withMeta<PagingParams>()
  .withResponse<{
    appointmentIds: Fk<Appointment>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<
    LocationContainer & AppointmentContainer & AppointmentAttendanceContainer & AppointmentTypeContainer
  >()
  .withCreator((paging: PagingParams) => ({
    meta: paging,
  }));
