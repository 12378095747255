import { useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectProfession, selectShift } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { rejectFalsy } from "@sinch/utils";
import { join } from "ramda";
import React, { ReactElement } from "react";
import { identifierPrefix } from "../../../ui";
import { WorkerRoleOptions } from "../../Shift";
import { requestPositionDetail } from "./api";

interface PositionReferenceProps {
  full?: boolean;

  id: Fk<Position>;

  long?: boolean;

  name?: boolean;

  noId?: boolean;

  prof?: boolean;

  role?: boolean;

  shift?: boolean;

  short?: boolean;

  title?: boolean;
}

/**
 * todo: consider having tooltip for more details instead of rendering
 *  all fields directly into main content
 *
 * todo: add optional coloring and formatting of individual fields
 *
 * todo: use entity auto-prefixing component to display ids
 */
export function PositionReference({
  id,
  shift,
  name,
  noId,
  prof,
  role,
  title,
  full,
  long,
  short,
}: PositionReferenceProps): ReactElement {
  const { selectEntity: q } = useData(requestPositionDetail);

  if (full) return <PositionReference id={id} name noId={noId} prof role shift title />;
  if (long) return <PositionReference id={id} name noId={noId} prof role />;
  if (short) return <PositionReference id={id} noId={noId} prof role />;
  function getLabel(): Nullable<string> {
    if (!prof && !role && !title) {
      return null;
    }

    const labelSegments = [
      prof && q(selectProfession(q(selectPosition(id, "profession")), "name")),
      role && WorkerRoleOptions()[q(selectPosition(id, "role"))].label,
      title && q(selectPosition(id, "title")),
    ];

    return join(" - ", rejectFalsy(labelSegments));
  }
  const label = getLabel();

  const referenceSegments = [
    shift
      ? `${identifierPrefix("shift")}${q(selectPosition(id, "shift"))}-${identifierPrefix("position")}${id}`
      : !noId && `${identifierPrefix("position")}${id}`,
    ,
    name && q(selectShift(q(selectPosition(id, "shift")), "name")),
    label && (name ? `- ${label}` : label),
  ];
  return <>{join(" ", rejectFalsy(referenceSegments))}</>;
}
