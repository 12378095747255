import MuiGrid from "@material-ui/core/Grid";
import { useBusinessRules, useData } from "@sinch/core";
import { Fk, Payout, PayoutPaymentType, selectPayout, selectPayoutMethod } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Box, List, routerLink, Text, useTitleBar } from "@sinch/ui";
import { pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { SearchParamsPagination } from "../../components";
import { requestWalletPayoutList } from "./api";
import { WalletSearchForm } from "./WalletSearchForm";

export function MobileWalletPayouts(): ReactElement {
  const { curr, dt } = useFormat();
  const navigate = useNavigate();
  const { search } = useLocation();

  useTitleBar({
    container: "core:wallet/payouts",
    locationTitle: t("Payout.title"),
    showBack: true,
    backHandler: () => navigate("/wallet"),
  });

  const { selectEntity, selectResult } = useData(requestWalletPayoutList);
  const { allowWorkerPaymentRequest } = useBusinessRules();

  const { payoutIds } = selectResult();

  const sortedPayoutIds: number[] = pipe<Payout[], Payout[], number[], number[]>(
    sortBy(prop("accountingDate")),
    pluck("id"),
    reverse
  )(selectEntity(selectPayout(payoutIds)));

  return (
    <>
      <WalletSearchForm entity="payout" />
      <List
        center
        getAction={
          allowWorkerPaymentRequest
            ? (payout: Fk<Payout>) => routerLink({ search, hash: `#/payout/${payout}` })
            : undefined
        }
        getBody={(payout: Fk<Payout>) => {
          const { accountingDate, method, netAmount, paymentType } = selectEntity(selectPayout(payout));

          const methodName = selectEntity(selectPayoutMethod(method, "name"));
          const typeName = PayoutPaymentType[paymentType];

          return (
            <MuiGrid container direction="column">
              <MuiGrid item>
                <Box display="flex" justifyContent="space-between">
                  <Text bold>{curr(netAmount)}</Text>
                  {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                  <Text>{accountingDate ? dt(accountingDate) : "-"}</Text>
                </Box>
              </MuiGrid>
              <MuiGrid item>
                {" "}
                <Text bold>{`${methodName}, ${typeName}`}</Text>
              </MuiGrid>{" "}
              <MuiGrid item>
                <Text>{`${t("Wallet.display.accountingDate")}: ${
                  accountingDate
                    ? dt(
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                        accountingDate!
                      )
                    : "-"
                }`}</Text>
              </MuiGrid>
            </MuiGrid>
          );
        }}
        items={sortedPayoutIds}
      />

      <SearchParamsPagination />
    </>
  );
}
