import { Box, Grid, Typography } from "@material-ui/core";
import { mdiStopCircleOutline } from "@mdi/js";
import { useBusinessRules, useFiles, useRequest } from "@sinch/core";
import { Fk, Location, Position } from "@sinch/entity";
import { FileInputBase, SelectTimeInputBase, TextInputBase } from "@sinch/forms";
import { useFormat } from "@sinch/intl";
import { Action, Button, DialogControl, ModalDialog, Text, useDialog, useMobileLayout } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { MAX_UPLOADED_FILES } from "../../Position";
import { ShiftAttendanceCloseFilesTarget } from "../../Shift";
import { requestClockOut } from "../api";
import { StaffClockInGeolocation } from "./StaffClockInGeolocation";
import { GeolocationPayload } from "./types";

interface ModalDialogResultProps {
  dateTime: Date;
  dialog: DialogControl;
}

const ModalDialogResult: React.FC<ModalDialogResultProps> = ({ dateTime, dialog }) => {
  const { t } = useTranslation();
  const { dt } = useFormat();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Text>
          {t<string>("StaffClockIn.clockOutSuccess", { time: dt.time(dateTime) })}
        </Text>
      </Grid>
      <Grid item style={{ marginTop: 18 }}>
        <Button action={dialog.close} color="info" large variant="contained">
          {t<string>("action.confirm")}
        </Button>
      </Grid>
    </Grid>
  );
};

interface ModalDialogClockOutProps {
  location?: Location;
  dialog: DialogControl;
  position: Fk<Position>;
  setLoading: (loading: boolean) => void;
  positionStartTime: Date;
}

const ModalDialogClockOut: React.FC<ModalDialogClockOutProps> = ({
  location,
  dialog,
  setLoading,
  position,
  positionStartTime,
}) => {
  const { t } = useTranslation();
  const { workerCanUploadShiftFiles } = useBusinessRules();
  const dispatch = useRequest();
  const { clockOutText } = useBusinessRules();
  const [files, setFiles] = useState([]);
  const [clockOutValues, setClockOutValues] = useState<{ dateTime: Date | null; note: string; files: string[] }>({
    dateTime: null,
    note: "",
    files: [],
  });

  const { status } = useFiles();

  const handleChangeTime = (value: Date | null) => {
    setClockOutValues({ ...clockOutValues, dateTime: value });
  };

  const handleChangeNote = (evt: ChangeEvent<HTMLInputElement>) =>
    setClockOutValues({ ...clockOutValues, note: evt.target.value });

  const handleCancel = () => {
    setClockOutValues({ dateTime: null, note: "", files: [] });
    dialog.close();
  };

  const handleClockOut: Action = async () => {
    setLoading(true);
    await dispatch(
      requestClockOut({
        positionId: position,
        note: clockOutValues.note ?? undefined,
        dateTime: clockOutValues.dateTime ?? undefined,
        files: isEmpty(files) ? undefined : files,
      })
    );
    setLoading(false);
  };

  const handleGeolocationClockOut = async (data: GeolocationPayload) => {
    setLoading(true);
    await dispatch(
      requestClockOut({
        positionId: position,
        note: clockOutValues.note ?? undefined,
        dateTime: clockOutValues.dateTime ?? undefined,
        ...data.point,
        errorCode: data.error?.code,
        accuracy: data.accuracy,
        files: isEmpty(files) ? undefined : files,
      })
    );
    setLoading(false);
  };

  const clockOutFormContent = (
    <>
      {clockOutText && (
        <Grid item>
          <Typography align="center">{clockOutText}</Typography>
        </Grid>
      )}
      <Grid item>
        <SelectTimeInputBase
          fullWidth
          label={t("StaffClockIn.workEndTime")}
          maxTime={new Date()}
          minTime={positionStartTime}
          name="dateTime"
          onChange={handleChangeTime}
          placeholder={t<string>("rightNow")}
          value={clockOutValues.dateTime}
        />
      </Grid>
      <Grid item>
        <TextInputBase
          label={t("note")}
          multiline={4}
          name="note"
          onChange={handleChangeNote}
          value={clockOutValues.note}
        />
      </Grid>
      {workerCanUploadShiftFiles && (
        <Grid item>
          <FileInputBase
            label={t("uploadAttachments")}
            maxFiles={MAX_UPLOADED_FILES}
            multiple
            name="files"
            onChange={setFiles}
            target={ShiftAttendanceCloseFilesTarget}
            value={files}
          />
        </Grid>
      )}
    </>
  );

  if (!location) {
    return (
      <Grid container direction="column" spacing={2}>
        {clockOutFormContent}
        <Grid item style={{ marginTop: 18 }}>
          <Button action={handleClockOut} color="info" disabled={status !== "ready"} large variant="contained">
            {t<string>("action.confirm")}
          </Button>
        </Grid>
        <Grid item>
          <Button action={handleCancel} color="error" large variant="text">
            {t<string>("action.cancel")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <StaffClockInGeolocation
      formContent={
        <Grid container direction="column" spacing={2}>
          {clockOutFormContent}
        </Grid>
      }
      location={location}
      onCancel={handleCancel}
      onConfirm={handleGeolocationClockOut}
    />
  );
};

interface ClockOutButtonProps {
  fullWidth?: boolean;
  position: Fk<Position>;
  dateTime?: Date;
  positionStartTime: Date;
  location?: Location;
}

export const ClockOutButton: React.FC<ClockOutButtonProps> = ({
  fullWidth,
  position,
  dateTime: serverDateTime,
  positionStartTime,
  location,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const dialog = useDialog();
  const mobile = useMobileLayout();

  const handleOpenDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dialog.open();
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} py={2}>
      <Button
        action={handleOpenDialog}
        color="error"
        icon={mdiStopCircleOutline}
        large
        loading={loading}
        stretch={fullWidth === true ? false : !mobile}
        variant="contained"
      >
        {t<string>("StaffClockIn.clockOut")}
      </Button>
      <ModalDialog onClose={dialog.close} open={dialog.state} title={t<string>("StaffClockIn.clockOut")} width="xs">
        {serverDateTime ? (
          <ModalDialogResult dateTime={serverDateTime} dialog={dialog} />
        ) : (
          <ModalDialogClockOut
            dialog={dialog}
            location={location}
            position={position}
            positionStartTime={positionStartTime}
            setLoading={setLoading}
          />
        )}
      </ModalDialog>
    </Box>
  );
};
