import { Box, Grid as MuiGrid } from "@material-ui/core";
import {
  mdiAccountCancel,
  mdiAccountCheck,
  mdiAccountMultipleCheckOutline,
  mdiAccountMultipleOutline,
  mdiCancel,
  mdiClockCheckOutline,
  mdiPen,
  mdiTimerSandEmpty,
} from "@mdi/js";

import { useData } from "@sinch/core";
import {
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  WorkerRole,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { IconId } from "@sinch/types";
import { Button, PopperMenu, Strip, Text, TimeoutTimer, UserMenuItem, useSpacing } from "@sinch/ui";
import { ChildrenProps } from "@sinch/utils";
import { max } from "date-fns";
import { includes } from "ramda";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../api";
import { useClosingStatusProps, useUpdatePresence } from "../hooks";

const closingMenuLabel = (status: ClosingProgress) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getLabel } = useClosingStatusProps();
  return `${t<string>("Shift.closing.changeTo")}: ${getLabel(status)}`;
};

function ClosingStatusStrip({
  status,
  icon,
  children,
}: { status: ClosingProgress; icon?: IconId } & ChildrenProps): ReactElement {
  const { getProps } = useClosingStatusProps();
  const { color, icon: propIcon } = getProps(status);
  return (
    <Strip color={color} icon={icon || propIcon}>
      {children}
    </Strip>
  );
}

function StripArrived({ id, onEdit }: PresenceDetailStatusProps): ReactElement {
  const { setPending, setAbsent } = useUpdatePresence(id);
  const { dt } = useFormat();
  const { selectEntity } = useData(requestPresenceView);

  const { position, startTime, progressStatus, workBreak } = selectEntity(selectPositionAttendance(id));
  const { startTime: shiftStart } = selectEntity(selectPosition(position));

  return (
    <ClosingStatusStrip status={ClosingProgress.Present}>
      <MuiGrid container direction="column">
        <MuiGrid item>
          {t("Shift.closing.present")}: <TimeoutTimer timestamp={startTime || shiftStart} />
        </MuiGrid>
        <MuiGrid item>
          {t("Shift.closing.arrival")}: {dt.time(startTime || shiftStart)}
        </MuiGrid>
        {progressStatus === ClosingProgress.AfterBreak && workBreak && (
          <MuiGrid item>
            {t("Shift.closing.breakRange")}: {dt.time(workBreak.startTime)} - {dt.time(workBreak.endTime)}
          </MuiGrid>
        )}
        <MuiGrid item>
          <PopperMenu disablePortal placement="bottom-end">
            <Box display="flex" justifyContent="flex-end" ml={-1} pt={1}>
              <Button size="small" stretch variant="outlined">
                {t("action.edit")}
              </Button>
            </Box>
            <UserMenuItem action={onEdit} icon={mdiPen} text={t("Shift.closing.editTime")} />
            <UserMenuItem
              action={() => setPending()}
              icon={mdiTimerSandEmpty}
              text={closingMenuLabel(ClosingProgress.Pending)}
            />
            <UserMenuItem
              action={() => setAbsent()}
              icon={mdiAccountCancel}
              text={closingMenuLabel(ClosingProgress.Absent)}
            />
          </PopperMenu>
        </MuiGrid>
      </MuiGrid>
    </ClosingStatusStrip>
  );
}

function StripBreak({ id, onEdit }: PresenceDetailStatusProps): ReactElement | null {
  const { resetBreak } = useUpdatePresence(id);
  const { dt } = useFormat();
  const { selectEntity } = useData(requestPresenceView);

  const { workBreak } = selectEntity(selectPositionAttendance(id));

  if (!workBreak) {
    return null;
  }
  const { startTime: breakStart } = workBreak;

  return (
    <ClosingStatusStrip status={ClosingProgress.Break}>
      <MuiGrid container direction="column">
        <MuiGrid item>
          {t("Shift.closing.break")}: <TimeoutTimer timestamp={breakStart} />
        </MuiGrid>
        <MuiGrid item>
          {t("Shift.closing.breakFrom")}: {dt.time(breakStart)}
        </MuiGrid>
        <MuiGrid item>
          <PopperMenu disablePortal={false} placement="bottom-end">
            <Box display="flex" justifyContent="flex-end" ml={-1} pt={1}>
              <Button size="small" stretch variant="outlined">
                {t("action.edit")}
              </Button>
            </Box>
            <UserMenuItem action={onEdit} icon={mdiPen} text={t("Shift.closing.editTime")} />
            <UserMenuItem action={resetBreak} icon={mdiCancel} text={t("Shift.closing.cancelBreak")} />
          </PopperMenu>
        </MuiGrid>
      </MuiGrid>
    </ClosingStatusStrip>
  );
}

function StripFinished({ id, onEdit }: PresenceDetailStatusProps): ReactElement {
  const { dt, dur } = useFormat();
  const { setArrival, setAbsent } = useUpdatePresence(id);
  const { selectEntity } = useData(requestPresenceView);

  const { position, startTime, endTime, workBreak } = selectEntity(selectPositionAttendance(id));

  const { startTime: shiftStart, endTime: shiftEnd } = selectEntity(selectPosition(position));

  const estEndTime = endTime || shiftEnd;
  const estStartTime = startTime || shiftStart;
  const duration = dur.narrowShort((estEndTime.getTime() - max([estStartTime, shiftStart]).getTime()) / 1000);

  return (
    <ClosingStatusStrip icon={mdiClockCheckOutline} status={ClosingProgress.Finished}>
      <MuiGrid container direction="column">
        <MuiGrid item>
          <Text>
            <Text bold>{`${t("Shift.closing.workingTime")}:`}</Text>
            {`${dt.time(shiftStart)} - ${dt.time(estEndTime)} (${duration})`}
          </Text>
        </MuiGrid>
        {workBreak && (
          <MuiGrid item>
            {t("Shift.closing.breakRange")}: {dt.time(workBreak.startTime)} - {dt.time(workBreak.endTime)}
          </MuiGrid>
        )}
        <MuiGrid item>
          <PopperMenu disablePortal={false} placement="bottom-end">
            <Box display="flex" justifyContent="flex-end" ml={-1} pt={1}>
              <Button size="small" stretch variant="outlined">
                {t("action.edit")}
              </Button>
            </Box>
            <UserMenuItem action={onEdit} icon={mdiPen} text={t("Shift.closing.editTime")} />
            <UserMenuItem
              action={() => setArrival()}
              icon={mdiAccountCheck}
              text={closingMenuLabel(ClosingProgress.Present)}
            />
            <UserMenuItem
              action={() => setAbsent()}
              icon={mdiAccountCancel}
              text={closingMenuLabel(ClosingProgress.Absent)}
            />
          </PopperMenu>
        </MuiGrid>
      </MuiGrid>
    </ClosingStatusStrip>
  );
}
function StripBackupFinished({ id, onEdit }: PresenceDetailStatusProps): ReactElement {
  const { dt } = useFormat();
  const { setPending, setAbsent } = useUpdatePresence(id);
  const { selectEntity } = useData(requestPresenceView);

  const { position, startTime } = selectEntity(selectPositionAttendance(id));

  const { startTime: shiftStart } = selectEntity(selectPosition(position));

  const estStartTime = startTime || shiftStart;

  return (
    <Strip color="info" icon={mdiAccountMultipleCheckOutline}>
      <MuiGrid container direction="column">
        <MuiGrid item>
          <Text bold>{t("Shift.closing.arrivedAsBackup")}</Text>
        </MuiGrid>
        <MuiGrid item>
          {t("Shift.closing.arrival")}: {dt.time(max([estStartTime, shiftStart]))}
        </MuiGrid>
        <MuiGrid item>
          <PopperMenu disablePortal={false} placement="bottom-end">
            <Box display="flex" justifyContent="flex-end" ml={-1} pt={1}>
              <Button size="small" stretch variant="outlined">
                {t("action.edit")}
              </Button>
            </Box>
            <UserMenuItem action={onEdit} icon={mdiPen} text={t("Shift.closing.editTime")} />
            <UserMenuItem
              action={() => setPending()}
              icon={mdiTimerSandEmpty}
              text={closingMenuLabel(ClosingProgress.Pending)}
            />
            <UserMenuItem
              action={() => setAbsent()}
              icon={mdiAccountCancel}
              text={closingMenuLabel(ClosingProgress.Absent)}
            />
          </PopperMenu>
        </MuiGrid>
      </MuiGrid>
    </Strip>
  );
}

function StripPending(): ReactElement {
  const { getLabel } = useClosingStatusProps();
  return <ClosingStatusStrip status={ClosingProgress.Pending}>{getLabel(ClosingProgress.Pending)}</ClosingStatusStrip>;
}

function StripMiss(): ReactElement {
  const { getLabel } = useClosingStatusProps();
  return <ClosingStatusStrip status={ClosingProgress.Absent}>{getLabel(ClosingProgress.Absent)}</ClosingStatusStrip>;
}

function StripLate(): ReactElement {
  const { getLabel } = useClosingStatusProps();
  return <ClosingStatusStrip status={ClosingProgress.Late}>{getLabel(ClosingProgress.Late)}</ClosingStatusStrip>;
}

function StripBackup(): ReactElement {
  return (
    <Strip color="info" icon={mdiAccountMultipleOutline}>
      {t("Position.role.backup")}
    </Strip>
  );
}

interface PresenceDetailStatusProps {
  id: Fk<PositionAttendance>;
  onEdit: () => void;
}

export function PresenceWorkerDetailStatus({ id, onEdit }: PresenceDetailStatusProps): ReactElement {
  const [outer] = useSpacing();
  const { selectEntity } = useData(requestPresenceView);
  const { position, progressStatus: status } = selectEntity(selectPositionAttendance(id));
  const { role } = selectEntity(selectPosition(position));

  const backup = role === WorkerRole.Backup;

  const strips = [
    backup && <StripBackup />,
    status === ClosingProgress.Pending && <StripPending />,
    includes(status, [ClosingProgress.Present, ClosingProgress.AfterBreak]) && <StripArrived id={id} onEdit={onEdit} />,
    status === ClosingProgress.Break && <StripBreak id={id} onEdit={onEdit} />,
    !backup && status === ClosingProgress.Finished && <StripFinished id={id} onEdit={onEdit} />,
    status === ClosingProgress.Absent && <StripMiss />,
    status === ClosingProgress.Late && <StripLate />,
    backup && status === ClosingProgress.Finished && <StripBackupFinished id={id} onEdit={onEdit} />,
  ];

  return (
    <MuiGrid container direction="column" spacing={outer}>
      {strips.map(
        (strip, i) =>
          strip && (
            // eslint-disable-next-line react/no-array-index-key
            <MuiGrid key={i} item>
              {strip}
            </MuiGrid>
          )
      )}
    </MuiGrid>
  );
}
