import MuiGrid from "@material-ui/core/Grid";
import { DataProvider, PagingParams, useData, useSearchParamsCustom } from "@sinch/core";
import { Rating, RatingType, selectRating } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Box,
  composeRowLogic,
  createMuiStyleRow,
  DataTableRow,
  List,
  MuiDataTable,
  NumberStatusColor,
  PaperContainer,
  Text,
  useTitleBar,
} from "@sinch/ui";
import { ChildrenProps, isDefined } from "@sinch/utils";
import { descend, isEmpty, prop, sortWith } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { SearchParamsPagination } from "../../components";
import { requestProfileRatingList } from "./api";
import { RatingEmptyBox } from "./RatingEmptyBox";

const isErrorValue = (value: number) => isDefined(value) && value < 0;

const typeSelector = (type: RatingType, value: number) => {
  const isError = isErrorValue(value);

  switch (type) {
    case RatingType.Attendance:
      return <Text color={isError ? "error" : undefined}>{t("work")}</Text>;
    case RatingType.Admin:
      return <Text color={isError ? "error" : undefined}>{t("admin")}</Text>;
    case RatingType.FriendBanList:
      return <Text color={isError ? "error" : undefined}>{t("Profile.rating.friendBanList")}</Text>;
    case RatingType.Evaluation:
      return <Text color={isError ? "error" : undefined}>{t("Profile.rating.evaluation")}</Text>;
    case RatingType.Late:
      return <Text color={isError ? "error" : undefined}>{t("Attendance.presence.late")}</Text>;
    case RatingType.Absent:
      return <Text color={isError ? "error" : undefined}>{t("Attendance.presence.miss")}</Text>;
    case RatingType.Duty:
      return <Text color={isError ? "error" : undefined}>{t("Profile.rating.duty")}</Text>;
    case RatingType.Excused:
      return <Text color={isError ? "error" : undefined}>{t("Attendance.presence.excused")}</Text>;
    default:
      return <Text color={isError ? "error" : undefined}>{t("Profile.display.typeError")}</Text>;
  }
};

const sumRatings = (data: Rating[]) =>
  sortWith([descend(prop("createdDate"))])(data.map((ratingObj) => ({ ...ratingObj })));

type RatingColumn = "description" | "date" | "ratingChange" | "type";

export function DesktopRating(): ReactElement {
  const { dt } = useFormat();
  const { selectEntity, selectResult } = useData(requestProfileRatingList);
  const { ratings } = selectResult();

  if (isEmpty(ratings)) {
    return <RatingEmptyBox />;
  }

  const data = selectEntity(selectRating(ratings));
  const summedData = sumRatings(data);

  const StatusColorRow = createMuiStyleRow(
    (theme) => ({
      ratingWarning: {
        backgroundColor: theme.palette.background.default,
      },
    }),
    ({ rating }: Rating) => {
      if (isErrorValue(rating)) return ["ratingWarning"];
      return [];
    }
  );

  const RatingDataTable = MuiDataTable.withConfig<Rating, RatingColumn>({
    date: {
      title: t("date"),
      selector: ({ createdDate, rating }) => (
        <Text color={isErrorValue(rating) ? "error" : undefined}>
          {isDefined(createdDate) ? dt.date(createdDate) : ""}
        </Text>
      ),
    },
    ratingChange: {
      title: t("Profile.rating.ratingChange"),
      selector: ({ rating }) => {
        if (!isDefined(rating)) return "";
        return <NumberStatusColor value={rating}>{rating < 0 ? `${rating}` : `+${rating}`}</NumberStatusColor>;
      },
    },
    type: {
      title: t("display.type"),
      selector: ({ type, rating }: Rating) => typeSelector(type, rating),
    },
    description: {
      title: t("description"),
      selector: ({ description, rating }) => (
        <Text color={isErrorValue(rating) ? "error" : undefined}>{isDefined(description) ? description : ""}</Text>
      ),
    },
    // @ts-ignore
  }).withLogic(composeRowLogic([StatusColorRow, DataTableRow]));

  return (
    <>
      <RatingDataTable columns={["date", "ratingChange", "type", "description"]} data={summedData} />
      <SearchParamsPagination defaultLimit={RATING_LIST_DEFAULT_LIMIT} />
    </>
  );
}

export function MobileRating(): ReactElement {
  useTitleBar({
    container: "core:rating",
    locationTitle: t("Rating.title"),
    showBack: true,
  });
  const { dt } = useFormat();
  const { selectEntity, selectResult } = useData(requestProfileRatingList);
  const { ratings } = selectResult();
  const data = selectEntity(selectRating(ratings));
  const summedData = sumRatings(data);

  return (
    <PaperContainer>
      <List
        center
        disableGutters
        getBody={({ rating, type, createdDate, description }) => (
          <>
            <Box display="flex" justifyContent="space-between">
              <Text bold>
                <NumberStatusColor value={rating}>{(rating ?? 0) < 0 ? `${rating}` : `+${rating}`}</NumberStatusColor>
              </Text>
              <NumberStatusColor value={rating}>{dt(createdDate)}</NumberStatusColor>
            </Box>
            <MuiGrid container direction="column">
              <MuiGrid item>
                <Text bold>{typeSelector(type, rating)}</Text>
              </MuiGrid>
              <MuiGrid item>
                <NumberStatusColor value={rating}>{description}</NumberStatusColor>
              </MuiGrid>
            </MuiGrid>
          </>
        )}
        items={summedData}
      />
      <SearchParamsPagination defaultLimit={RATING_LIST_DEFAULT_LIMIT} />
    </PaperContainer>
  );
}

export const RATING_LIST_DEFAULT_LIMIT = 25;

export function RatingListContainer({ children }: ChildrenProps): ReactElement {
  const { searchParams } = useSearchParamsCustom<PagingParams>({ page: 0, limit: RATING_LIST_DEFAULT_LIMIT });

  const request = useMemo(() => requestProfileRatingList(PagingParams.pick(searchParams)), [searchParams]);

  return <DataProvider request={request}>{children}</DataProvider>;
}
