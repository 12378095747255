import {
  mdiAccountCancel,
  mdiAccountCheck,
  mdiClockAlert,
  mdiCoffeeOffOutline,
  mdiCoffeeOutline,
  mdiStopCircleOutline,
  mdiTimerSandEmpty,
} from "@mdi/js";
import { useData, useRequest } from "@sinch/core";
import {
  ClosingProgress,
  Fk,
  Position,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectWorker,
  WorkerRole,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Color, IconId } from "@sinch/types";
import { IdentifierColorKey } from "@sinch/ui";
import { groupBy, head, isNil, map, pick, pipe, prop, reduce, sortBy, values } from "ramda";
import { ensureArray, isMap } from "ramda-adjunct";
import {
  requestPresenceView,
  requestShiftPresenceAbsent,
  requestShiftPresenceArrived,
  requestShiftPresenceBreak,
  requestShiftPresenceBreakEnd,
  requestShiftPresenceFinished,
  requestShiftPresenceMoveToPosition,
  requestShiftPresenceNote,
  requestShiftPresencePending,
  requestShiftPresenceResetBreak,
  requestShiftPresenceTimes,
} from "./api";

export type ClosingStatusProps = {
  icon: IconId;
  color: Color | IdentifierColorKey;
  label: string;
};

export type ClosingStatusListProps = {
  [key in ClosingProgress]: ClosingStatusProps;
};

const statusProps = (): ClosingStatusListProps => ({
  [ClosingProgress.Pending]: {
    icon: mdiTimerSandEmpty,
    color: "colorPending",
    label: t<string>("Shift.closing.statusPending"),
  },
  [ClosingProgress.Late]: {
    icon: mdiClockAlert,
    color: "warning",
    label: t<string>("Shift.closing.statusLate"),
  },
  [ClosingProgress.Present]: {
    icon: mdiAccountCheck,
    color: "success",
    label: t<string>("Shift.closing.statusPresent"),
  },
  [ClosingProgress.Absent]: {
    icon: mdiAccountCancel,
    color: "error",
    label: t<string>("Shift.closing.statusAbsent"),
  },
  [ClosingProgress.Break]: {
    icon: mdiCoffeeOutline,
    color: "colorBreak",
    label: t<string>("Shift.closing.statusBreak"),
  },
  [ClosingProgress.AfterBreak]: {
    icon: mdiCoffeeOffOutline,
    color: "success",
    label: t<string>("Shift.closing.statusPresent"),
  },
  [ClosingProgress.Finished]: {
    icon: mdiStopCircleOutline,
    color: "info",
    label: t<string>("Shift.closing.statusFinished"),
  },
});

interface UseClosingStatusPropsProps {
  getProps(): ClosingStatusListProps;
  getProps(status: ClosingProgress): ClosingStatusProps;
  getProps<K extends keyof ClosingStatusProps>(
    status: ClosingProgress,
    pickProps: readonly K[]
  ): Pick<ClosingStatusProps, Exclude<keyof ClosingStatusProps, Exclude<keyof ClosingStatusProps, K>>>;
  getLabel: (status: ClosingProgress) => string;
  getForAttendance: (attendance: PositionAttendance) => ClosingStatusProps;
}

export const useClosingStatusProps = (): UseClosingStatusPropsProps => {
  const { selectEntity } = useData(requestPresenceView);

  function getProps(): ClosingStatusListProps;
  function getProps(status: ClosingProgress): ClosingStatusProps;
  function getProps<K extends keyof ClosingStatusProps>(
    status: ClosingProgress,
    pickProps: readonly K[]
  ): Pick<ClosingStatusProps, Exclude<keyof ClosingStatusProps, Exclude<keyof ClosingStatusProps, K>>>;
  function getProps<K extends keyof ClosingStatusProps>(status?: ClosingProgress, pickProps?: readonly K[]) {
    if (typeof status === "undefined") {
      return statusProps();
    }
    if (!isNil(status) && pickProps) {
      return pick(pickProps)(statusProps()[status]);
    }
    return statusProps()[status];
  }

  function getLabel(status: ClosingProgress) {
    return prop("label")(statusProps()[status]);
  }

  const getForAttendance = ({ id }: PositionAttendance): ClosingStatusProps => {
    const { progressStatus } = selectEntity(selectPositionAttendance(id));

    return getProps(progressStatus);
  };

  return { getProps, getLabel, getForAttendance };
};

export function useAttendanceGrouping(
  attendances: PositionAttendance[]
): { attendancesByRole: PositionAttendance[][] } {
  const { selectEntity } = useData(requestPresenceView);

  const getFirstPosition = pipe<PositionAttendance[], PositionAttendance, Fk<Position>>(head, prop("position"));

  // @ts-expect-error
  const groupedAttendances = groupBy<PositionAttendance, PositionAttendance["position"]>(prop("position"))(attendances);

  const attendancesByRole = pipe<
    Record<Fk<Position>, PositionAttendance[]>,
    PositionAttendance[][],
    PositionAttendance[][],
    PositionAttendance[][]
  >(
    values,
    sortBy((items) => {
      const { role } = selectEntity(selectPosition(getFirstPosition(items)));
      if (role === WorkerRole.Crewboss) {
        return 0;
      }
      return role === WorkerRole.Worker ? 1 : 2;
    }),
    map(sortBy(({ worker }) => selectEntity(selectWorker(worker, "surname"))))
  )(groupedAttendances);
  return { attendancesByRole };
}

export function useUpdatePresence(attendanceIds: Fk<PositionAttendance> | Fk<PositionAttendance>[]) {
  const dispatch = useRequest();
  let currentAttendances: number[] = ensureArray(attendanceIds);

  function buildList(currentAttendanceIds: Fk<PositionAttendance>[], data: unknown) {
    if (isMap(data)) {
      return Object.fromEntries(data);
    }
    return reduce((acc, elem) => ({ ...acc, [elem]: data || null }), [], currentAttendanceIds);
  }

  function setAttendanceIds(newAttendanceIds: Fk<PositionAttendance> | Fk<PositionAttendance>[]) {
    currentAttendances = ensureArray(newAttendanceIds);
  }

  function setArrival(time?: Date | Map<Fk<PositionAttendance>, Date> | null) {
    return dispatch(requestShiftPresenceArrived(buildList(currentAttendances, time)));
  }

  function setPending() {
    return dispatch(requestShiftPresencePending({ attendanceId: currentAttendances }));
  }

  function resetBreak() {
    return dispatch(requestShiftPresenceResetBreak({ attendanceId: currentAttendances }));
  }

  function setBreak(time?: Date | Map<Fk<PositionAttendance>, Date> | null) {
    return dispatch(requestShiftPresenceBreak(buildList(currentAttendances, time)));
  }

  function setBreakEnd(time?: Date | Map<Fk<PositionAttendance>, Date> | null) {
    return dispatch(requestShiftPresenceBreakEnd(buildList(currentAttendances, time)));
  }

  function setAbsent() {
    return dispatch(requestShiftPresenceAbsent({ attendanceId: currentAttendances }));
  }

  function setFinished(time?: Date | Map<Fk<PositionAttendance>, Date> | null) {
    return dispatch(requestShiftPresenceFinished(buildList(currentAttendances, time)));
  }

  function setNote(note: string) {
    return dispatch(requestShiftPresenceNote({ attendanceId: currentAttendances[0], note }));
  }

  function setTimes(times: {
    startTime?: Date;
    endTime?: Date;
    breakStart?: Date;
    breakEnd?: Date;
    hasBreak?: boolean;
  }) {
    return dispatch(requestShiftPresenceTimes(buildList(currentAttendances, times)));
  }

  function moveToPosition(positionId: Fk<Position>, time: Date, progressStatus?: ClosingProgress) {
    return dispatch(
      requestShiftPresenceMoveToPosition({ attendanceId: currentAttendances[0], positionId, time, progressStatus })
    );
  }

  return {
    setArrival,
    setPending,
    setBreak,
    setBreakEnd,
    setAbsent,
    setFinished,
    resetBreak,
    setNote,
    setTimes,
    setAttendanceIds,
    moveToPosition,
  };
}
