import { mdiTimerSandFull, mdiTimerSandEmpty } from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import {
  Fk,
  Position,
  PositionAttendanceStatus,
  PositionStatus,
  selectPosition,
  selectPositionAttendance,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import { Icon } from "@sinch/ui";
import { head } from "ramda";
import React, { ReactElement } from "react";
import { PresenceOptions } from "../../Shift";

export function PositionAttendanceStatusIcons({ id }: { id: Fk<Position> }): ReactElement[] | ReactElement | null {
  // @ts-expect-error
  const { selectEntity } = useData();
  const { id: currentUserId } = useCurrentUser();
  const icons = [];

  const presence = head(
    selectEntity(
      selectPositionAttendance(
        {
          position: id,
          worker: currentUserId,
        },
        "status"
      )
    )
  );
  const status = selectEntity(selectPosition(id, "status"));

  if (presence && presence !== PositionAttendanceStatus.Present) {
    const { color, icon } = PresenceOptions()[presence];
    icons.push(<Icon color={color} icon={icon} size="small" />);
  }

  switch (status) {
    case PositionStatus.Finished:
      icons.push(
        <Icon
          color="error"
          icon={mdiTimerSandFull}
          size="small"
          tooltip={t("Position.closedStatusFinished") as string}
        />
      );
      break;
    case PositionStatus.CrewbossClosed:
      icons.push(
        <Icon
          color="warning"
          icon={mdiTimerSandEmpty}
          size="small"
          tooltip={t("Position.closedStatusCrewbossComplete") as string}
        />
      );
      break;
    default:
      break;
  }

  return icons;
}
