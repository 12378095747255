import { assocPath, reduceRight } from "ramda";
import React from "react";
import { DataTableCell } from "./DataTableCell";
import { DataTableRow } from "./DataTableRow";
import {
  DataTableCellLogic,
  DataTableCellProps,
  DataTableDisplay,
  DataTableRowLogic,
  DataTableRowProps,
} from "./types";

/* eslint-disable react/jsx-props-no-spreading */

export function composeRowLogic<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>(
  components: DataTableRowLogic<TData, TColumn, TDisplay>[],
  final: DataTableRowLogic<TData, TColumn, TDisplay> = DataTableRow
): DataTableRowLogic<TData, TColumn, TDisplay> {
  type RowLogic = DataTableRowLogic<TData, TColumn, TDisplay>;
  type RowProps = DataTableRowProps<TData, TColumn, TDisplay>;

  const updateProps = (logic: RowLogic, props: RowProps) =>
    assocPath(["table", "logic"], logic, props);

  function composeRow(Current: RowLogic, Next: RowLogic): RowLogic {
    return function compose(props: RowProps) {
      return <Current {...updateProps(Next, props)} />;
      // return Current(setProp(props));
    };
  }

  return reduceRight(
    (component, next) => composeRow(component, next),
    final,
    components
  );
}

export function composeCellLogic<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>(
  components: DataTableCellLogic<TData, TColumn, TDisplay>[],
  final: DataTableCellLogic<TData, TColumn, TDisplay> = DataTableCell
): DataTableCellLogic<TData, TColumn, TDisplay> {
  type CellLogic = DataTableCellLogic<TData, TColumn, TDisplay>;
  type CellProps = DataTableCellProps<TData, TColumn, TDisplay>;

  const updateProps = (logic: CellLogic, props: CellProps) =>
    assocPath(["table", "config", props.column, "logic"], logic, props);

  function composeRow(Current: CellLogic, Next: CellLogic): CellLogic {
    return function compose(props: CellProps) {
      return <Current {...updateProps(Next, props)} />;
      // return Current(setProp(props));
    };
  }

  return reduceRight(
    (component, next) => composeRow(component, next),
    final,
    components
  );
}
