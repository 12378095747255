import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import {
  Fk,
  Reimbursement,
  ReimbursementContainer,
  ShiftContainer,
  UserContainer,
  WalletTransactionType,
} from "@sinch/entity";

export interface ReimbursementListParams {
  from?: Date;

  to?: Date;

  transactionType?: WalletTransactionType;
}

export const requestReimbursementList = defineRequest("Wallets/ReimbursementList")
  .withParams<ReimbursementListParams>()
  .withMeta<PagingParams>()
  .withResponse<{ reimbursementIds: Fk<Reimbursement>[] }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<ReimbursementContainer & UserContainer & ShiftContainer>()
  .withCreator((params: ReimbursementListParams, paging: PagingParams) => ({
    meta: paging,
    params,
  }));
