import MuiButton from "@material-ui/core/Button";
import { fade, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { ActionContainer, resolveAction } from "../actions";
import { Icon } from "../Icon";
import { createPaletteStyles } from "../paletteStyles";

const paletteStyles = createPaletteStyles(({ main }, { theme }) => ({
  color: main,
  "&:hover": {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    backgroundColor: fade(main, theme!.palette.action.hoverOpacity),
  },
}));

const useStyles = makeStyles(paletteStyles);

export interface DialogButtonProps extends ActionContainer {}

export function DialogButton({ action, color, disabled, label, icon }: DialogButtonProps): ReactElement {
  const styles = useStyles();

  const actionProps = resolveAction(action);

  return (
    <MuiButton
      className={clsx(color && styles[color])}
      color="default"
      disabled={disabled}
      disableElevation
      size="small"
      type="button"
      variant="text"
      /* eslint-disable react/jsx-props-no-spreading */
      {...actionProps}
    >
      {icon && <Icon icon={icon} />} {label}
    </MuiButton>
  );
}
