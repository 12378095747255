import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // locale-data for en
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en"; // locale-data for en
// @ts-ignore
import DurationUnitFormat from "intl-unofficial-duration-unit-format";

export type DurationFormatVariant = "timer" | "narrow" | "narrowShort" | "short" | "long" | "daysTime";

export type FormatDuration = (value: number) => string;

export function durationTimeFormatter(locale: string): FormatDuration & Record<DurationFormatVariant, FormatDuration> {
  const timer = new DurationUnitFormat(locale.toLowerCase(), {
    style: DurationUnitFormat.styles.TIMER,
    format: "{hour}:{minutes}:{seconds}",
  });

  const narrow = new DurationUnitFormat(locale.toLowerCase(), {
    style: DurationUnitFormat.styles.NARROW,
    format: "{hour} {minutes} {seconds}",
  });

  const narrowShort = new DurationUnitFormat(locale.toLowerCase(), {
    style: DurationUnitFormat.styles.NARROW,
    format: "{hour} {minutes}",
  });

  const short = new DurationUnitFormat(locale.toLowerCase(), {
    style: DurationUnitFormat.styles.SHORT,
    format: "{hour} {minutes} {seconds}",
  });

  const daysTime = new DurationUnitFormat(locale.toLowerCase(), {
    style: DurationUnitFormat.styles.SHORT,
    format: "{days} {hour} {minutes}",
  });

  const long = new DurationUnitFormat(locale.toLowerCase(), {
    style: DurationUnitFormat.styles.LONG,
    format: "{hour} {minutes} {seconds}",
  });

  const preflight = (formatter: FormatDuration): FormatDuration => (seconds: number) =>
    seconds < 0 ? `-${formatter(Math.abs(seconds))}` : formatter(seconds);

  return Object.assign((seconds: number) => preflight(short.format.bind(short))(seconds), {
    timer: preflight(timer.format.bind(timer)),
    narrow: preflight(narrow.format.bind(narrow)),
    narrowShort: preflight(narrowShort.format.bind(narrowShort)),
    short: preflight(short.format.bind(short)),
    long: preflight(long.format.bind(long)),
    daysTime: preflight(daysTime.format.bind(daysTime)),
  });
}
