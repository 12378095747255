import { OneOrMore, Updater } from "@sinch/types";
import { always, equals, flatten, head, length, map, pipe, when } from "ramda";

/**
 * Check if given list contains only one element.
 */
export const hasOneElement = pipe<unknown[], number, boolean>(
  length,
  equals(1)
);

/**
 * Return the only element contained in given list.
 *
 * Input list is returned if there are more elements contained
 * (behaves as identity function).
 */
export const unwrapList = when(hasOneElement, head) as <T>(
  list: T[]
) => OneOrMore<T>;

export const flatList = <T,>(value?: OneOrMore<T>): T[] =>
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value ? flatten<any>([value]) : [];

export const replaceElement = <T,>(from: T, to: T): Updater<T[]> =>
  map(when(equals(from), always(to)));
