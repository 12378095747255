import MuiGrid from "@material-ui/core/Grid";
import { mdiCancel, mdiClockOutline, mdiTimerSandEmpty } from "@mdi/js";

import { useFiles } from "@sinch/core";
import { Fk, PersonalAttributeCitizenship, ProfileAttribute, ProfileAttributeType } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { FileHash, SelectFieldOption } from "@sinch/types";
import { Box, Divider, Flex, Header, Icon, ImageGrid, Link, ListBase, ScrollAnchor, Text } from "@sinch/ui";
import { isDefined, rejectFalsy, toElement } from "@sinch/utils";
import { isValid, parseISO } from "date-fns";
import {
  compose,
  defaultTo,
  equals,
  filter,
  findLast,
  groupBy,
  includes,
  isEmpty,
  isNil,
  map,
  pick,
  pipe,
  prop,
  propEq,
  sortBy,
  uniq,
} from "ramda";
import React from "react";
import { ProfileAttributeData } from "./api";
import { CellGrid } from "./components/CellGrid";
import { NotFilledInfoBox } from "./components/NotFilledInfoBox";
import { isExpired, parseBoolLabel, parseSelectValue, ProfileEditButton } from "./utils";

type AttributeExpirationParams = {
  expirable: boolean;
  expiration?: Date;
};

function AttributeExpiration({ expirable, expiration }: AttributeExpirationParams) {
  const { dt } = useFormat();

  return (
    <>
      {expirable && !isNil(expiration) && !isExpired(expiration) && (
        <MuiGrid container spacing={1}>
          {" "}
          <MuiGrid item>
            <Icon color="warning" icon={mdiClockOutline} />
          </MuiGrid>
          <MuiGrid item>
            <Text color="warning">
              {t("display.validTo")}
              {dt.date(expiration)}
            </Text>
          </MuiGrid>
        </MuiGrid>
      )}
      {expirable && !isNil(expiration) && isExpired(expiration) && (
        <MuiGrid container spacing={1}>
          <MuiGrid item>
            <Icon color="error" icon={mdiCancel} />
          </MuiGrid>
          <MuiGrid item>
            <Text color="error">
              {t("invalid")}
              {dt.date(expiration)}
            </Text>
          </MuiGrid>
        </MuiGrid>
      )}
    </>
  );
}

type AttributeVerificationParams = {
  verifiable: boolean;
  verified?: boolean;
};

function AttributeVerification({ verifiable, verified }: AttributeVerificationParams) {
  // todo: flex spacing, warning text color
  return (
    <>
      {verifiable && isDefined(verified) && !verified && (
        <Flex>
          <Icon color="default" icon={mdiTimerSandEmpty} tooltip={t("Profile.display.pendingVerification")} />
        </Flex>
      )}
    </>
  );
}

const thumbnailSrcParam = "/thumbnail_100x100";

export type AttributeContentParams = {
  value: unknown;
  id: Fk<ProfileAttribute>;
};

// todo cast typescript type or ignore?
export function AttributeContent({ value, id, params, type }: AttributeContentParams) {
  const { dt } = useFormat();
  const storage = useFiles();

  if (
    !isDefined(value) &&
    !equals(type, ProfileAttributeType.File) &&
    !equals(type, ProfileAttributeType.Image) &&
    !equals(type, ProfileAttributeType.Gallery)
  ) {
    return toElement("");
  }
  if (!isDefined(value) && equals(type, ProfileAttributeType.File)) {
    return toElement(t("noFile"));
  }
  if (!isDefined(value) && equals(type, ProfileAttributeType.Image)) {
    return toElement(t("noPicture"));
  }
  if (!isDefined(value) && equals(type, ProfileAttributeType.Gallery)) {
    return toElement(t("noPictures"));
  }
  if (
    (equals(type, ProfileAttributeType.Select) ||
      equals(type, ProfileAttributeType.Level) ||
      equals(type, ProfileAttributeType.MultiSelect)) &&
    (!isDefined(params) || !isDefined(params.options))
  ) {
    return toElement(t("Profile.display.typeError"));
  }

  switch (type) {
    case ProfileAttributeType.Number:
      /* @ts-expect-error */
      return <Text>{value.toString()}</Text>;
    case ProfileAttributeType.Bool:
      /* @ts-expect-error */
      return <Text>{parseBoolLabel(value)}</Text>;
    case ProfileAttributeType.Select:
      return (
        /* @ts-expect-error */
        <Text>{parseSelectValue(params.options, value)}</Text>
      );
    // same implementation like Select
    case ProfileAttributeType.Level:
      return (
        /* @ts-expect-error */
        <Text>{parseSelectValue(params.options, value)}</Text>
      );
    case ProfileAttributeType.MultiSelect:
      return (
        <Text separator=", ">
          {map(
            ({ label }: SelectFieldOption) => label,
            filter(
              /* @ts-expect-error */
              (option: SelectFieldOption) => includes(option.value, value),
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              params.options!
            )
          )}
        </Text>
      );
    case ProfileAttributeType.File:
      // eslint-disable-next-line no-case-declarations
      const { url } = storage(value as FileHash);
      return (
        <Link href={url} target="_blank">
          {t("action.showFile")}
        </Link>
      );
    case ProfileAttributeType.Image:
      return <ImageGrid cellHeight={120} cols={3} files={value as FileHash} srcParam={thumbnailSrcParam} />;
    case ProfileAttributeType.Gallery:
      return <ImageGrid cellHeight={120} cols={3} files={value as FileHash[]} srcParam={thumbnailSrcParam} />;
    case ProfileAttributeType.Text:
      /* @ts-expect-error */
      return <Text>{value}</Text>;
    case ProfileAttributeType.LongText:
      /* @ts-expect-error */
      return <Text>{value}</Text>;
    case ProfileAttributeType.Date:
      return <Text>{isValid(value) ? dt.date(parseISO(value)) : value}</Text>;
    default:
      return <Text>Type error</Text>;
  }
}

export type ProfileAttributeGroupCardParams = {
  edit: string;
  attributes: ProfileAttribute[];
  attributesValues: ProfileAttributeData[];
  isCitizen: boolean;
};

export function ProfileAttributeGroupCard({
  edit,
  attributes,
  attributesValues,
  isCitizen,
}: ProfileAttributeGroupCardParams) {
  const attributesWithDefaults = pipe<
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    Record<string, ProfileAttribute[]>
  >(
    map(({ id, ...props }) => {
      const savedValue = findLast(propEq("id", id))(attributesValues);
      if (savedValue) {
        return { id, ...props, ...savedValue };
      }
      return null;
    }),
    rejectFalsy,
    filter<ProfileAttribute>(
      ({ citizenship }) =>
        !isNil(citizenship) &&
        !isEmpty(citizenship) &&
        (equals(citizenship, PersonalAttributeCitizenship.Any) ||
          (equals(citizenship, PersonalAttributeCitizenship.Home) && isCitizen) ||
          (equals(citizenship, PersonalAttributeCitizenship.Other) && !isCitizen))
    ),
    sortBy(prop("sortNumber")),
    groupBy(prop("blockId"))
  )(attributes);

  const blocks = pipe<
    ProfileAttribute[],
    ProfileAttribute[],
    ProfileAttribute[],
    Pick<ProfileAttribute, "blockId" | "blockName">[],
    Pick<ProfileAttribute, "blockId" | "blockName">[]
  >(
    defaultTo([]),
    sortBy(compose(defaultTo(Infinity), prop("blockSortNumber"))),
    map(pick(["blockId", "blockName"])),
    uniq
  )(attributes);

  return (
    <CellGrid>
      {map(
        ({ blockId, blockName }) => (
          <div key={blockId} style={{ height: "100%" }}>
            <div style={{ height: "100%" }}>
              <Box display="flex" justifyContent="space-between">
                <Header level={2}>{blockName}</Header>
                <ProfileEditButton edit={edit} label={blockId} />
              </Box>
              {isEmpty(attributesWithDefaults[blockId] || []) ? (
                <NotFilledInfoBox edit={edit} label={blockId} />
              ) : (
                <ListBase
                  contentSelector={({
                    value,
                    id,
                    expiration,
                    verified,
                    name,
                    expirable,
                    verifiable,
                    params,
                    type,
                  }: ProfileAttribute & ProfileAttributeData) => (
                    <div style={{ width: "100%" }}>
                      <ScrollAnchor id={id} />
                      <Text bold>{name}</Text>
                      <AttributeExpiration expirable={expirable} expiration={expiration} />
                      <Flex>
                        <AttributeVerification verifiable={verifiable} verified={verified} />
                        <AttributeContent id={id} params={params} type={type} value={value} />
                      </Flex>
                    </div>
                  )}
                  data={attributesWithDefaults[blockId]}
                  disablePadding
                />
              )}
            </div>
            <Divider key={`${blockId}_divider`} />
          </div>
        ),
        blocks
      )}
    </CellGrid>
  );
}
