import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { mdiBriefcase, mdiCarArrowLeft, mdiCarArrowRight } from "@mdi/js";

/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { ResponseOf, SelectEntity, useData } from "@sinch/core";
import {
  Fk,
  Position,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  selectTransportAttendance,
  Shift,
  TransportAttendance,
} from "@sinch/entity";
import { selectPositionAttendanceBreak } from "@sinch/entity/PositionAttendanceBreak/selectors";
import { DateInputPresetProvider, Form } from "@sinch/forms";
import { Box, Paper, TabsBase, useMobileLayout, useSpacing } from "@sinch/ui";
import { isDefined, rejectFalsy, useToggleState } from "@sinch/utils";
import { min } from "date-fns";
import { flatten, isNil } from "ramda";
import { isNonEmptyArray, isNonEmptyString } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { AttendanceClosingFormState, requestShiftAttendanceClose, requestShiftAttendanceView } from "./api";
import { AttendanceForm } from "./AttendanceForm";
import { AttendanceFormMobile, AttendanceFormMobileViews } from "./Mobile/AttendanceFormMobile";
import { isPresent } from "./options";
import { ShiftHeader } from "./ShiftHeader";
import { ShiftOptions } from "./ShiftOptions";
import { SubmitCard } from "./SubmitCard";

const useStyles = makeStyles(({ mixins: { component, onLarge } }) => ({
  sticky: {
    position: "sticky",
    zIndex: 100,
    top: `${component.titleBar.small.height}px`,
    ...onLarge({
      top: `${component.titleBar.large.height}px`,
    }),
  },
}));

function initializeState(
  shift: Fk<Shift>,
  state: Pick<AttendanceClosingFormState, "files" | "note" | "startTime">,
  selectEntity: SelectEntity<ResponseOf<typeof requestShiftAttendanceView>>
): AttendanceClosingFormState {
  const positions: Fk<Position>[] = selectEntity(selectPosition({ shift }, "id"));

  const minStartTime = state.startTime ?? min(selectEntity(selectPosition(positions, "startTime")));

  const formState: AttendanceClosingFormState = {
    files: state.files ?? [],
    note: state.note ?? "",
    positionAttendance: {},
    startTime: minStartTime,
    transportAttendance: {},
    withStartTime: !isNil(state.startTime),
  };

  positions.forEach((pos) => {
    const role = selectEntity(selectPosition(pos, "role"));

    const attendances: Fk<PositionAttendance>[] = selectEntity(selectPositionAttendance({ position: pos }, "id"));

    attendances.forEach((att) => {
      const { endTime, note, startTime, status, position } = selectEntity(selectPositionAttendance(att));
      const positionAttendanceBreak = selectEntity(selectPositionAttendanceBreak({ positionAttendance: att }));
      const { endTime: shiftStart, startTime: shiftEnd } = selectEntity(selectPosition(position));

      formState.positionAttendance[att] = {
        endTime: endTime || shiftEnd,
        note: note ?? "",
        presence: isPresent(status),
        role,
        startTime: startTime || shiftStart,
        positionAttendanceBreak,
      };
    });
  });

  const transport = selectEntity(selectShift(shift, "transport"));

  if (isDefined(transport)) {
    const { from, to } = transport;

    const attendances: Fk<TransportAttendance>[] = flatten(
      rejectFalsy([
        isDefined(from) && selectEntity(selectTransportAttendance({ transport: from }, "id")),
        isDefined(to) && selectEntity(selectTransportAttendance({ transport: to }, "id")),
      ])
    );

    attendances.forEach((att) => {
      const { endTime, role, startTime, distance /* worker */ } = selectEntity(selectTransportAttendance(att));

      formState.transportAttendance[att] = {
        endTime,
        role,
        startTime,
        distance,
      };
    });
  }

  return formState;
}

export function AttendanceClosing(): ReactElement {
  const { selectEntity, selectResult } = useData(requestShiftAttendanceView);
  const mobile = useMobileLayout();
  const styles = useStyles();

  const { shiftId: shift, ...state } = selectResult();

  const formState = useMemo(() => initializeState(shift, state, selectEntity), [selectEntity, shift, state]);

  const [withFiles, toggleFiles] = useToggleState(isNonEmptyArray(state.files));
  const [withNote, toggleNote] = useToggleState(isNonEmptyString(state.note));

  const submitRequest = useCallback(
    ({ files, note, startTime, withStartTime, ...attendances }: AttendanceClosingFormState) =>
      requestShiftAttendanceClose({
        files: withFiles ? files : [],
        note: withNote ? note : null,
        shift,
        startTime: withStartTime ? startTime : null,
        ...attendances,
      }),
    [shift, withFiles, withNote]
  );

  const transport = selectEntity(selectShift(shift, "transport"));
  const { from = null, to = null } = transport ?? {};
  const tabs = rejectFalsy([
    {
      path: AttendanceFormMobileViews.Work,
      icon: mdiBriefcase,
    },
    to && {
      path: AttendanceFormMobileViews.TransportThere,
      icon: mdiCarArrowRight,
    },
    from && {
      path: AttendanceFormMobileViews.TransportBack,
      icon: mdiCarArrowLeft,
    },
  ]);
  const [selected, setSelected] = useState(tabs[0].path);
  const [outerSpacing] = useSpacing();

  return (
    <Form<AttendanceClosingFormState> blockRedirect initialValues={formState} submitRequest={submitRequest}>
      {mobile && ( // TODO: refactor
        <Box className={styles.sticky} margin={-outerSpacing} mb={1} mt={-outerSpacing}>
          <Paper elevation={3}>
            {/* @ts-ignore */}
            <TabsBase onSelect={setSelected} selected={selected} tabs={tabs} />
          </Paper>
        </Box>
      )}
      <DateInputPresetProvider
        DateTimeInput={{
          default: {
            format: "d/M HH:mm",
            openTo: "hours",
          },
        }}
      >
        <MuiGrid container direction={mobile ? "column" : "row"} spacing={mobile ? 2 : 3}>
          <MuiGrid item md={4} xs={12}>
            <MuiGrid container direction="column" spacing={mobile ? 2 : 3}>
              <MuiGrid item>
                <ShiftHeader shift={shift} />
              </MuiGrid>
              <MuiGrid item>
                <ShiftOptions
                  toggleFiles={toggleFiles}
                  toggleNote={toggleNote}
                  withFiles={withFiles}
                  withNote={withNote}
                />
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item md={8} xs={12}>
            <MuiGrid container direction="column" spacing={mobile ? 2 : 3}>
              <MuiGrid item xs={12}>
                {mobile ? (
                  <AttendanceFormMobile selectedView={selected} shift={shift} />
                ) : (
                  <AttendanceForm shift={shift} />
                )}
              </MuiGrid>
              <MuiGrid item xs={12}>
                <SubmitCard />
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </DateInputPresetProvider>
    </Form>
  );
}
