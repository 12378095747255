/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DomElement } from "htmlparser2";
import { ReactHTML } from "react";

export type TagName = keyof ReactHTML;

export type TagElement = {
  type: "tag";
  name: TagName;
  children: DomElement[];
  attribs: Record<string, string>;
};

export type TextElement = {
  type: "text";
  data: string;
};

function checkElementType<T extends DomElement>(type: T["type"]) {
  return (node: DomElement): node is T => node.type === type;
}

export const isTagType = checkElementType<TagElement>("tag");
export const isTextType = checkElementType<TextElement>("text");
