import { ApplicationContainer, Backend, backendLogger, createBackend } from "@sinch/core";
import { InterceptHandler } from "@sinch/core/components/InterceptHandler";
import { Router } from "@sinch/core/router";
import { initIntl } from "@sinch/intl";
import { Footer } from "@sinch/ui";
import { isIos } from "@sinch/utils";
import { format } from "date-fns";
import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { initErrorMonitor } from "../core/services/errorMonitor";
import { ApplicationLayout } from "./Application";
import { NewsIntercept } from "./Communication/News";
import dictionary from "./dict";
import {
  getBackendParams,
  getErrorMonitorParams,
  getGitRevisionInfo,
  getGTMParams,
  getReactRenderTarget,
  getRouterBasePath,
} from "./env";
import { routes } from "./routes";

if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

/**
 * Init Error monitor (probably Sentry)
 */
initErrorMonitor({ errorMonitorParams: getErrorMonitorParams(), revisionInfo: getGitRevisionInfo() });

/**
 * Init google tag manager
 */
const { GTM_ID } = getGTMParams();
if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID });
}

/**
 * Init base configuration
 */
const { date, version } = getGitRevisionInfo();
const reactRenderTarget = getReactRenderTarget();
const routerBasePath = getRouterBasePath();
const backend: Backend = createBackend(getBackendParams());
initIntl({ dictionary, routerBasePath: getRouterBasePath() });

/**
 * Top component
 */
interface WorkerViewApplicationConfig {
  rootElement: HTMLElement;
}

function WorkerViewApplication({ rootElement }: WorkerViewApplicationConfig): ReactElement {
  const currentBackend = backendLogger(backend);

  const plugins = {
    contentFooter: [
      <Footer versionLabel={`Build: ${format(new Date(date), "yyyyMMdd")}.${version}`} />,
      // @ts-ignore
      // <DevBar>
      //  <DevBarRevision branch={branch} date={date} version={version} />
      // </DevBar>,
    ],
    titleBar: [
      // <LanguageToggle />,
    ],
  };

  return (
    <ApplicationContainer backend={currentBackend} scope="worker">
      <Router
        basePath={routerBasePath}
        layout={[
          // Main Layout
          <ApplicationLayout plugins={plugins} rootElement={rootElement} />,
          // Intercept handler stop user to access application when some event occured (eg. blocked user, announcement, etc.)
          <InterceptHandler interceptFallback={<NewsIntercept />} />,
        ]}
        routes={routes}
      />
    </ApplicationContainer>
  );
}

/**
 * Start react application and attach react to container
 */
function start() {
  const container = document.getElementById(reactRenderTarget);

  if (isIos()) {
    /* if we're on iOS, add event listener to prevent swipe gestures at root */
    container?.addEventListener("touchstart", (e) => {
      // is not near edge of view, exit
      if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;

      // prevent swipe to navigate back gesture
      e.preventDefault();
    });
  }

  if (!container) {
    throw new Error(`React render target not found: ${reactRenderTarget}`);
  }

  // Check if page is in webview and redirect to browser, prevent errors caused by webview
  const userAgent = window.navigator.userAgent.toLowerCase();
  const facebook = /fban\/messenger/.test(userAgent);

  if (isIos() && facebook) {
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}/pages/unsupportedFacebook?return=${window.location.href}`
    );
    return;
  }

  const rootComponent = <WorkerViewApplication rootElement={container} />;

  ReactDOM.render(rootComponent, container);
}

/**
 * Starting application
 */
start();
