/* eslint-disable react/jsx-props-no-spreading */
import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useFormat, useIntl } from "@sinch/intl";
import { Consumer } from "@sinch/types";
import { format } from "date-fns";
import React, { ReactElement, SyntheticEvent } from "react";
import { DateInputProps } from "../DateInput";
import { BaseInputProps } from "../Input";
import { utcToZonedTime } from "date-fns-tz";

const useStyles = makeStyles(() => ({
  hideIndicators: {
    "&::-webkit-calendar-picker-indicator, & .MuiInputBase-input::-webkit-calendar-picker-indicator": {
      // display: "none",
    },
    opacity: 0,
    height: "100%",
    width: "inherit",
    position: "absolute",
  },
}));

/**
 * Native inputs for SelectDateTimeInput
 */
export function DateTimeInputNative({
  className,
  onChange,
  value,
  type = "date",
  ...props
}: BaseInputProps<
  Date | undefined,
  Omit<DateInputProps, "TextFieldComponent"> & { type: "time" | "date" },
  Consumer<MaterialUiPickersDate>
>): ReactElement {
  const classes = useStyles();
  const { dt } = useFormat();
  const { timeZone } = useIntl();

  const handleChange = (event: SyntheticEvent) => {
    // @ts-ignore
    onChange(event.target.valueAsDate);
  };
  return (
    <InputBase
      {...props}
      className={className}
      fullWidth
      inputComponent={({ inputRef, ...inputProps }) => (
        <>
          <input
            ref={inputRef}
            {...inputProps}
            className={`${inputProps.className} MuiOutlinedInput-inputMarginDense MuiOutlinedInput-input`}
            value={
              !value ? "" : type === "time" ? dt.time(value) : dt.chip(value)
            }
          />
          <input
            className={`${classes.hideIndicators}`}
            onChange={inputProps.onChange}
            type={type}
            value={inputProps.value}
          />
        </>
      )}
      margin="dense"
      onChange={handleChange}
      value={format(
        utcToZonedTime(value || new Date(), timeZone),
        type === "time" ? "hh:mm" : "yyyy-MM-dd"
      )}
    />
  );
}
