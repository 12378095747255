import { CalendarEntryType } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import { Spacer, Text } from "@sinch/ui";
import { zonedTimeToUtc } from "date-fns-tz";
import React, { ReactElement } from "react";
import { GenEvent } from "./CalendarBigCalendar";
import { isAvailabilityType } from "./queries";

export function CalendarEvent({
  event: { title, start, end, type, timeZone, showDate, showEnd },
}: {
  event: GenEvent & { timeZone: string };
}): ReactElement {
  const { dt } = useFormat();

  if (isAvailabilityType(type)) {
    return (
      <Text small>
        <Spacer>
          <strong>
            {showDate && start && dt.chip(zonedTimeToUtc(start, timeZone))}{" "}
            {start && dt.time(zonedTimeToUtc(start, timeZone))}
            {showEnd && end && `-${dt.time(zonedTimeToUtc(end, timeZone))}`}
          </strong>
          {CalendarEntryType[type] + (title ? ` - ${title}` : "")}
        </Spacer>
      </Text>
    );
  }

  return (
    <Text small>
      <Spacer>
        <strong>
          {showDate && start && dt.chip(zonedTimeToUtc(start, timeZone))}{" "}
          {start && dt.time(zonedTimeToUtc(start, timeZone))}
          {showEnd && end && `-${dt.time(zonedTimeToUtc(end, timeZone))}`}
        </strong>
        {title}
      </Spacer>
    </Text>
  );
}
