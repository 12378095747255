import { Box as MuiBox, useTheme } from "@material-ui/core";
import { BoxProps as MuiBoxProps } from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { isNumber } from "@sinch/utils";
import React, { ReactElement } from "react";

const useStyles = makeStyles({
  root: {},
});

export interface BoxProps extends MuiBoxProps {
  width?: number | string;
}

/**
 * @deprecated todo: replace with properly styled components
 */
export function Box({ children, width, ...props }: BoxProps): ReactElement {
  const theme = useTheme();
  const styles = useStyles();

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <MuiBox
      className={styles.root}
      width={width && (isNumber(width) ? theme.spacing(width) : width)}
      {...props}
    >
      {children}
    </MuiBox>
  );
}
