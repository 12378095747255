import { Box, makeStyles } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Id, Position, PositionQ, selectPosition, selectShift } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  Card,
  Center,
  Divider,
  ExpandableArea,
  GridLayout,
  Header,
  Identifier,
  ListBase,
  Radio,
  routerLink,
  Text,
  TimeRange,
} from "@sinch/ui";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { StaffClockIn } from "../../components";
import { requestPositionDetail } from "./api";
import { PositionReference } from "./PositionReference";

const useStyles = makeStyles({ noWrap: { whiteSpace: "noWrap" } });

interface PositionSelectItemProps {
  active: boolean;

  id: Fk<Position>;
}

function PositionSelectItem({ active, id }: PositionSelectItemProps): ReactElement {
  const styles = useStyles();
  const { selectEntity, queryEntity } = useData(requestPositionDetail);

  const { endTime, freeCapacity, startTime, totalCapacity } = selectEntity(selectPosition(id));

  const textProps = queryEntity(PositionQ.isCancelled(id)) ? ({ color: "neutral", strikethrough: true } as const) : {};

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Center>
      <Radio checked={active} />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Text bold={active} small {...textProps}>
          <PositionReference id={id} noId prof role title />
        </Text>
        <Text bold={active} color="secondary" small {...textProps}>
          <Identifier entity="position" id={id} />
        </Text>
      </div>
      <Box alignItems="flex-end" display="flex" flexDirection="column" pr={2}>
        <Text small {...textProps}>{`${Math.max(0, totalCapacity - freeCapacity)}/${totalCapacity}`}</Text>
        <Text className={styles.noWrap} small {...textProps}>
          <TimeRange endTime={endTime} startTime={startTime} />
        </Text>
      </Box>
    </Center>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

/**
 * todo: ExpandableArea should be put outside of MuiCardContent
 *  otherwise it gets padded inside - update Card component to support that
 */
export function PositionDetailHeader(): ReactElement {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId } = selectResult();
  const startTimeSelfClocked = queryEntity(PositionQ.User.getSelfClockedStartTime(positionId));
  const endTimeSelfClocked = queryEntity(PositionQ.User.getSelfClockedEndTime(positionId));
  const location = queryEntity(PositionQ.Location.getLocation(positionId));
  const isAssigned = queryEntity(PositionQ.User.isAssigned(positionId));
  const { shift, clockInState, startTime } = selectEntity(selectPosition(positionId));
  const { name } = selectEntity(selectShift(shift));
  const positionsIds = queryEntity(PositionQ.Shift.getPositions(positionId));
  const positions = selectEntity(selectPosition(positionsIds))
    .filter(({ id, hidden }) => !hidden || id === positionId)
    .map(({ id }) => id);

  const contentSelector = (posId: Fk<Position>) => <PositionSelectItem active={equals(posId, positionId)} id={posId} />;

  const cancelled = queryEntity(PositionQ.isCancelled(positionId));

  return (
    <GridLayout.Item>
      <Card>
        <Box>
          <Text color={cancelled ? "neutral" : "primary"} small>
            {t("Shift.title")}
          </Text>
          <Header strikethrough={cancelled}>{name}</Header>
          <Text color={cancelled ? "neutral" : "primary"} separator={" "} small>
            {t("id")}:
            <Text bold>
              <Identifier entity="shift" id={shift} />
            </Text>
          </Text>
        </Box>
        <Box my={2}>
          <Text color={cancelled ? "neutral" : "primary"} small>
            {t("Position.selectedPosition")}
          </Text>
          <Header level={2} strikethrough={cancelled}>
            <PositionReference id={positionId} noId prof role title />
          </Header>
          <Text color={cancelled ? "neutral" : "primary"} separator={" "} small>
            {t("id")}:
            <Text bold>
              <Identifier entity="position" id={positionId} />
            </Text>
          </Text>
        </Box>
        {isAssigned && (
          <StaffClockIn
            clockInState={clockInState}
            endTimeClocked={endTimeSelfClocked}
            fullWidth
            location={location}
            position={positionId}
            positionStartTime={startTime}
            startTimeClocked={startTimeSelfClocked}
          />
        )}
        {queryEntity(PositionQ.isStatusOpen(positionId)) && (
          <>
            <Divider />
            <Box mx={-2}>
              <ExpandableArea title={`${t("Position.nextPosition")} (${positions.length})`}>
                <ListBase<Id>
                  actionSelector={(posId) => routerLink(`/position/${posId}`, { replace: true })}
                  contentSelector={contentSelector}
                  data={positions}
                  selectionSelector={(itemId) => itemId === positionId}
                />
              </ExpandableArea>
            </Box>
          </>
        )}
      </Card>
    </GridLayout.Item>
  );
}
