import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import MuiListItem from "@material-ui/core/ListItem";

import { isPageItem, isSectionItem, MenuNavigationItem, MenuSectionItem } from "@sinch/core";
import { useConditionalEffect, useToggleState } from "@sinch/utils";
import React, { ReactElement, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "../../Icon";
import { useMenuContext } from "./context";
import { MenuIcon } from "./MenuIcon";
import { SectionCollapse } from "./SectionCollapse";
import { SectionPopup } from "./SectionPopup";
import { useListItemStyle } from "./style";
import { Title } from "./Title";
import { Tooltip } from "./Tooltip";

const checkMatch = (path: string, item: MenuNavigationItem): boolean =>
  isPageItem(item) && (path.endsWith(`/page/${item.page}`) || path.endsWith(`/page/${item.page}/`));

const getSelectedSection = (path: string, item: MenuSectionItem): boolean => {
  const { menu } = item;
  if (!menu) {
    return false;
  }
  for (let i = 0; i < menu.length; i += 1) {
    const subItem = menu[i];
    if (checkMatch(path, subItem)) return true;
    if (isSectionItem(subItem) && getSelectedSection(path, subItem)) {
      return true;
    }
  }
  return false;
};

interface SectionItemProps {
  item: MenuSectionItem;
}

export function SectionItem({ item }: SectionItemProps): ReactElement {
  const { pathname } = useLocation();
  const listItemStyle = useListItemStyle();
  const { showText } = useMenuContext();
  const [expanded, toggleExpanded, setExpanded] = useToggleState(false);

  const anchorRef = useRef(null);

  // collapse section if menu closed
  useConditionalEffect(!showText, setExpanded, false);

  const { name, icon, menu } = item;

  const selected = getSelectedSection(pathname, item);

  return (
    <>
      <Tooltip title={name}>
        <MuiListItem ref={anchorRef} button className={listItemStyle} onClick={toggleExpanded} selected={selected}>
          {icon && <MenuIcon icon={icon} />}
          <Title title={name} />
          {showText && <Icon icon={expanded ? mdiChevronUp : mdiChevronDown} />}
        </MuiListItem>
      </Tooltip>
      {showText ? (
        <SectionCollapse items={menu} open={expanded} />
      ) : (
        <SectionPopup anchorRef={anchorRef} items={menu} onClose={toggleExpanded} open={expanded} />
      )}
    </>
  );
}
