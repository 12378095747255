import {
  DataProvider,
  responseHandlerKey,
  responseHandlerSequence,
  useRefresh,
  useSearchParamsCustom,
} from "@sinch/core";
import { t } from "@sinch/intl";
import { useSnackbar, useTitleBar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requestCalendar, requestCalendarAvailabilitySet, requestCalendarAvailabilityUnset } from "./api";
import { Calendar } from "./Calendar";
import { CalendarRangeParams, CalendarRangeUrlParams } from "./params";

export function CalendarContainer(): ReactElement {
  useTitleBar({
    container: "core:calendar",
    locationTitle: t("Calendar.title"),
  });

  return <CalendarContent />;
}
export function CalendarContent() {
  const { pathname, search } = useLocation();

  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { refresh, refreshToken } = useRefresh({ interval: 60000 });
  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestCalendarAvailabilitySet,
          () => {
            snackbar("success", t("Calendar.availabilitySaveSuccess"), { "data-cy": "successMessage" }); // Záznam byl uložen do kalendáře
            navigate(`${pathname}${search}`, { replace: true });
            refresh();
          },
          () => {
            snackbar("error", t("Calendar.availabilitySaveFailed")); // Nelze uložit záznam do kalendáře
            refresh();
          }
        ),
        responseHandlerKey(
          requestCalendarAvailabilityUnset,
          () => {
            snackbar("success", t("Calendar.availabilityDeleteSuccess")); // "Záznam byl smazán z kalendáře";
            navigate(`${pathname}${search}`, { replace: true });
            refresh();
          },
          () => {
            snackbar("error", t("Calendar.availabilityDeleteFailed")); // "Nelze smazat záznam z kalendáře");
            refresh();
          }
        ),
      ]),
    [navigate, pathname, search, refresh, snackbar]
  );

  const { searchParams } = useSearchParamsCustom<CalendarRangeUrlParams>();

  const request = useMemo(() => requestCalendar(CalendarRangeParams.toRequest(searchParams)), [searchParams]);

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken} refresher={refresh} request={request}>
      <Calendar />
    </DataProvider>
  );
}
