import { Location } from "@sinch/entity";
import { GeoPoint } from "@sinch/types";
import { useState } from "react";

const deg2rad = (deg: number) => deg * (Math.PI / 180);

const isInRadius = (position: GeoPoint, location: GeoPoint, radius = 50) => {
  const R = 6371e3;
  const lat1 = deg2rad(position.lat);
  const lat2 = deg2rad(location.lat);
  const latDiff = deg2rad(location.lat - position.lat);
  const lngDiff = deg2rad(location.lng - position.lng);

  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(lngDiff / 2) * Math.sin(lngDiff / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;

  return d <= radius;
};

interface GeolocationData {
  point: GeoPoint;
  accuracy: number;
}

export const useGeolocation = (location: Location) => {
  const [data, setData] = useState<GeolocationData | null>(null);
  const [inRadius, setInRadius] = useState(false);
  const [error, setError] = useState<GeolocationPositionError | null>(null);
  const [loading, setLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  let watchLocationId: number;

  const onSuccess: PositionCallback = (position) => {
    setError(null);
    const { latitude, longitude, accuracy } = position.coords;

    setData({ point: { lat: latitude, lng: longitude }, accuracy });
    const inArea = location.point
      ? isInRadius({ lat: latitude, lng: longitude }, location.point, location.geolocationRadius)
      : false;
    if (inArea) {
      setInRadius(true);
      navigator.geolocation.clearWatch(watchLocationId);
    }
    setLoading(false);
  };

  const onError: PositionErrorCallback = (responseError) => {
    setError(responseError ?? 4);
    setLoading(false);
    setData(null);
    navigator.geolocation.clearWatch(watchLocationId);
  };

  const getLocation = async () => {
    setLoading(true);
    watchLocationId = navigator.geolocation.watchPosition(onSuccess, onError);
  };

  const clear = () => {
    navigator.geolocation.clearWatch(watchLocationId);
  };

  const retryLocation = () => {
    clear();
    setRetryCount(retryCount + 1);
    getLocation();
  };

  return { data, error, loading, getLocation, inRadius, clear, retryLocation, retryCount };
};
