import { t } from "@sinch/intl";
import { useFullWidthContent, useTitleBar } from "@sinch/ui";
import React, { ReactElement } from "react";
import { WorkPresenceList } from "./CardList";
import { SelectionSetProvider } from "./context";

// const useStyles = makeStyles(({ mixins: { component, onLarge } }) => ({
//   sticky: {
//     position: "sticky",
//     zIndex: 100,
//     top: `${component.titleBar.small.height}px`,
//     ...onLarge({
//       top: `${component.titleBar.large.height}px`,
//     }),
//   },
// }));

export function PresenceListMobile(): ReactElement {
  useFullWidthContent();

  useTitleBar({
    container: "core:presence",
    locationTitle: t("Position.presence.onSiteWorkers"),
  });

  return (
    <SelectionSetProvider>
      <WorkPresenceList />
    </SelectionSetProvider>
  );
}
