import MuiGrid from "@material-ui/core/Grid";
import { mdiCalendarBlank, mdiCurrencyUsd, mdiInformation } from "@mdi/js";

import { useData } from "@sinch/core";
import { selectPosition, selectSuspendedPenalty, SuspendedPenalty } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { CardList, IconText, Identifier, Spacer, Text } from "@sinch/ui";
import { prop, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { PositionReference } from "../../Position/Detail/PositionReference";
import { requestSuspendedPenaltyList } from "./api";

export function SuspendedPenaltyCardList(): ReactElement {
  const { selectEntity, selectResult } = useData(requestSuspendedPenaltyList);
  const { suspendedPenaltyIds } = selectResult();
  const penaltyEntities = sortBy(prop("deadline"), selectEntity(selectSuspendedPenalty(suspendedPenaltyIds)));

  return (
    <CardList<SuspendedPenalty>
      cardProps={({ position }) => {
        const { shift } = selectEntity(selectPosition(position));
        return {
          title: (
            <Text bold>
              <Identifier entity="shift" id={shift} />
              -
              <Identifier entity="position" id={position} />
              <b>
                <PositionReference id={position} name noId title />
              </b>
            </Text>
          ),
        };
      }}
      content={(penalty: SuspendedPenalty) => <SuspendedPenaltyCardContent penalty={penalty} />}
      items={penaltyEntities}
    />
  );
}

function SuspendedPenaltyCardContent({ penalty }: { penalty: SuspendedPenalty }): ReactElement {
  const { id } = penalty;
  const { curr, dt } = useFormat();

  const { selectEntity } = useData(requestSuspendedPenaltyList);

  const { penalty: penaltyAmount = 0, rating, description, deadline } = selectEntity(selectSuspendedPenalty(id));

  return (
    <MuiGrid container direction="column" spacing={1}>
      <MuiGrid item>
        <IconText icon={mdiCurrencyUsd}>
          <Text color="error">
            <Spacer separator=" / ">
              {curr(penaltyAmount)}
              <>{rating}</>
            </Spacer>
          </Text>
        </IconText>
      </MuiGrid>
      <MuiGrid item>
        <IconText icon={mdiInformation}>{description}</IconText>
      </MuiGrid>
      <MuiGrid item>
        <IconText icon={mdiCalendarBlank}>
          <Text>
            {t<string>("Penalty.deadline")}:{dt.full(deadline)}
          </Text>
        </IconText>
      </MuiGrid>
    </MuiGrid>
  );
}
