import { DataProvider, useData } from "@sinch/core";
import {
  Appointment,
  CalendarEntry,
  CalendarEntryType,
  Fk,
  Position,
  selectPosition,
  selectShift,
  Shift,
  Transport,
} from "@sinch/entity";
import { equals, find, head, isNil } from "ramda";
import React, { ReactElement, useMemo } from "react";
import { requestAppointmentDetail } from "../Appointment";
import { requestPositionDetail } from "../Position";
import { requestCalendar } from "./api";
import {
  CalendarAppointmentDetailDialog,
  CalendarAvailabilityDetailDialog,
  CalendarConfirmationDetailDialog,
  CalendarPositionDetailDialog,
} from "./CalendarDetails";

/*
 * todo: clean disabled eslint errors
 */

interface EntryDetailDialogProps {
  entry?: Fk<Appointment> | Fk<CalendarEntry> | Fk<Position> | Fk<Transport>;

  type?: CalendarEntryType;
}

export function EntryDetailDialog({ type, entry }: EntryDetailDialogProps): ReactElement | null {
  if (isNil(type) || isNil(entry)) {
    return null;
  }

  if (equals(CalendarEntryType.Position, type) || equals(CalendarEntryType.Applicant, type)) {
    return <PositionDetail entry={entry} />;
  }

  if (equals(CalendarEntryType.Confirmation, type)) {
    return <ConfirmationDetail entry={entry} />;
  }

  if (equals(CalendarEntryType.Appointment, type)) {
    return <AppointmentDetail entry={entry} />;
  }

  if (equals(CalendarEntryType.Transport, type)) {
    return <CalendarEntryDetail entry={entry} />;
  }

  if (equals(CalendarEntryType.Unavailable, type) || equals(CalendarEntryType.Available, type)) {
    return <CalendarAvailabilityDetailDialog id={entry} />;
  }
  return null;
}

function ConfirmationDetail({ entry }): ReactElement {
  const request = useMemo(() => requestPositionDetail(entry), [entry]);
  return (
    <DataProvider request={request}>
      <CalendarConfirmationDetailDialog />
    </DataProvider>
  );
}

function PositionDetail({ entry }): ReactElement {
  const request = useMemo(() => requestPositionDetail(entry), [entry]);
  return (
    <DataProvider request={request}>
      <CalendarPositionDetailDialog />
    </DataProvider>
  );
}

function AppointmentDetail({ entry }): ReactElement {
  const request = useMemo(() => requestAppointmentDetail(entry), [entry]);
  return (
    <DataProvider request={request}>
      <CalendarAppointmentDetailDialog />
    </DataProvider>
  );
}

function CalendarEntryDetail({ entry }): ReactElement | null {
  const { selectEntity } = useData(requestCalendar);
  const shifts = selectEntity(selectShift({}));
  // @ts-ignore
  const findResult = find((shift: Shift) => shift.transport?.to === entry || shift.transport?.from === entry)(shifts);
  if (isNil(findResult)) {
    return null;
  }
  const { id: shiftId } = findResult;
  // @ts-ignore
  const { id: positionId } = head(selectEntity(selectPosition({ shift: shiftId })));
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const request = useMemo(() => requestPositionDetail(positionId), [positionId]);
  return (
    <DataProvider request={request}>
      <CalendarPositionDetailDialog />
    </DataProvider>
  );
}
