import { AccordionProps } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { PhoneInput, TextInput } from "@sinch/forms";
import { t } from "@sinch/intl";
import { rejectFalsy } from "@sinch/utils";
import React, { ReactElement, useMemo } from "react";
import { ProfileSettingAccordionItem } from "../components/ProfileSettingAccordionItem";
import { useSystemAttributes } from "../hooks";
import { useFieldListMeta } from "../utils";

interface EditContactsParams {
  expanded: boolean;
  onExpansionChange: AccordionProps["onChange"];
}

export function EditContacts({ expanded, onExpansionChange }: EditContactsParams): ReactElement | null {
  const { isVisible, isRequired } = useSystemAttributes();
  const { filled, total, errors, fields } = useFieldListMeta(
    rejectFalsy([isVisible("User.phone") && `phone`, `email`])
  );

  const content = useMemo(
    () => (
      <MuiGrid container direction="column">
        {isVisible("User.phone") && (
          <MuiGrid item>
            <PhoneInput
              key="phone"
              label={t("field.phone")}
              name="phone"
              placeholder={t("field.phone")}
              required={isRequired("User.phone")}
            />
          </MuiGrid>
        )}
        <MuiGrid item>
          <TextInput key="email" label={t("email")} name="email" placeholder={t("email")} />
        </MuiGrid>
      </MuiGrid>
    ),
    [filled, total, errors, fields]
  );

  return (
    <ProfileSettingAccordionItem
      error={errors > 0}
      expanded={expanded}
      filled={filled}
      label={t("Profile.display.contactInfo")}
      onExpansionChange={onExpansionChange}
      total={total}
    >
      {content}
    </ProfileSettingAccordionItem>
  );
}
