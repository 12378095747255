import { mdiSleep } from "@mdi/js";
import { requestHibernate, useCurrentUser, useRequest } from "@sinch/core";
import { t, useFormat } from "@sinch/intl";
import { Header, RowsGrid, Strip } from "@sinch/ui";
import React, { ReactElement } from "react";
import { HibernateContainer } from "../../Profile/Profile/Hibernate";

export function HibernationStatusStrip(): ReactElement {
  return (
    <HibernateContainer>
      <HibernationStatus />
    </HibernateContainer>
  );
}

function HibernationStatus(): ReactElement {
  const dispatch = useRequest();
  const {
    restrictions: { hibernated },
  } = useCurrentUser();
  const { dt } = useFormat();

  return (
    <Strip
      key="hibernated"
      color="colorApplicant"
      icon={mdiSleep}
      primaryAction={{
        action: () => dispatch(requestHibernate(false)),
        label: t("Hibernation.wakeFromHibernation"),
      }}
    >
      <RowsGrid>
        <Header level={3}>
          {hibernated instanceof Date
            ? t("Profile.yourProfileIsHibernatedDate", { date: dt(hibernated) })
            : t("Profile.yourProfileIsHibernated")}
        </Header>
        {t("Profile.hibernationDescription")}
      </RowsGrid>
    </Strip>
  );
}
