import { mdiCalendarCheck, mdiCalendarToday, mdiClockOutline, mdiMapMarker } from "@mdi/js";
import { QueryEntity, ResponseOf, SelectEntity, useCurrentUser, useData } from "@sinch/core";
import { Appointment, Fk, selectAppointment, selectAppointmentAttendance, selectLocation } from "@sinch/entity";
import { Format, t, useFormat } from "@sinch/intl";
import { BiFunc, Maybe } from "@sinch/types";
import {
  Action,
  Card,
  DataGrid,
  DataGridItemProps,
  Icon,
  routerLink,
  TimeRange,
  useMobileLayout,
  WrapWords,
} from "@sinch/ui";
import { rejectFalsy } from "@sinch/utils";
import { always, head, map } from "ramda";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "../api";

export type AppointmentCardField = "date" | "location" | "time" | "type";

export type AppointmentCardListSelector<T> = BiFunc<
  QueryEntity<ResponseOf<typeof requestDashboardAttendanceList>>,
  Fk<Appointment>,
  Maybe<T>
>;

type AttendanceListSelectEntity = SelectEntity<ResponseOf<typeof requestDashboardAttendanceList>>;

function getDate(selectEntity: AttendanceListSelectEntity, appointment: Fk<Appointment>): DataGridItemProps {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { id } = useCurrentUser();
  const appointmentAttendance = selectEntity(selectAppointmentAttendance({ appointment, worker: id }));
  const appointmentItem = selectEntity(selectAppointment(appointment));
  const { startTime } = head(appointmentAttendance) || appointmentItem;
  const { dt } = useFormat();

  return {
    icon: mdiCalendarToday,
    content: (
      <span>
        {dt.wday(startTime)} {dt(startTime)}
      </span>
    ),
  };
}

function getTime(selectEntity: AttendanceListSelectEntity, appointment: Fk<Appointment>): DataGridItemProps {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { id } = useCurrentUser();
  const appointmentAttendance = selectEntity(selectAppointmentAttendance({ appointment, worker: id }));
  const appointmentItem = selectEntity(selectAppointment(appointment));
  const { startTime, endTime } = head(appointmentAttendance) || appointmentItem;

  return {
    icon: mdiClockOutline,
    content: endTime ? (
      <TimeRange endTime={endTime} showLength startTime={startTime} />
    ) : (
      <Format dt={startTime} variant="time" />
    ),
  };
}

function getLocation(selectEntity: AttendanceListSelectEntity, appointment: Fk<Appointment>): DataGridItemProps {
  const { location } = selectEntity(selectAppointment(appointment));
  const { address } = selectEntity(selectLocation(location));

  return {
    icon: mdiMapMarker,
    content: [<WrapWords>{address}</WrapWords>],
  };
}

function getType(): DataGridItemProps {
  return {
    icon: mdiCalendarCheck,
    content: [t<string>("Appointment.title")],
    color: "success",
  };
}

interface AppointmentCardContentProps {
  appointment: Fk<Appointment>;

  fields: AppointmentCardField[];
}

function AppointmentCardContent({ appointment, fields }: AppointmentCardContentProps): ReactElement {
  const { selectEntity } = useData(requestDashboardAttendanceList);
  const mobile = useMobileLayout();

  const contentSelectors = rejectFalsy([
    mobile && fields.includes("type") && getType,
    fields.includes("date") && getDate,
    fields.includes("location") && getLocation,
    always({}),
    fields.includes("time") && getTime,
  ]);

  /*
   * todo: consider embedding this selector resolving functionality directly
   *  into display components
   *  -> query is retrieved from context
   *  -> selector params are passed as data
   */
  const data = map((selector) => selector(selectEntity, appointment), contentSelectors);

  return <DataGrid data={data} small={mobile} />;
}

function getTitle(selectEntity: AttendanceListSelectEntity, appointment: Fk<Appointment>): string {
  const { name } = selectEntity(selectAppointment(appointment));
  return name;
}

const getAction: AppointmentCardListSelector<Action> = (query, appointment) =>
  routerLink(`/appointment/${appointment}`);

interface AppointmentCardListProps {
  appointment: Fk<Appointment>;
}

export function AppointmentCard({ appointment }: AppointmentCardListProps): ReactElement {
  const mobile = useMobileLayout();

  const { selectEntity } = useData(requestDashboardAttendanceList);

  return (
    <Card
      action={getAction(selectEntity, appointment)}
      color="note"
      icon={<Icon color="success" icon={mdiCalendarCheck} />}
      label={t<string>("Appointment.title")}
      small={mobile}
      title={getTitle(selectEntity, appointment)}
    >
      <AppointmentCardContent appointment={appointment} fields={["type", "date", "location", "time"]} />
    </Card>
  );
}
