/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import MuiGrid from "@material-ui/core/Grid";
import { useData } from "@sinch/core";
import {
  ClockInState,
  ClosingProgress,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
} from "@sinch/entity";
import { DateInputPresetProvider } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { TwoColumnLayout, useSpacing, useTitleBar } from "@sinch/ui";
import { countBy, pick, sum, values } from "ramda";
import React, { ReactElement } from "react";
import { requestPresenceView } from "./api";
import { FinishedShiftStrip } from "./CardList";
import { ShiftHeader } from "./components";
import { SelectionSetProvider } from "./context";
import { PresenceTableList } from "./TableList";

export function PresenceListDesktop(): Nullable<ReactElement> {
  const { selectEntity, selectResult } = useData(requestPresenceView);
  const [outer] = useSpacing();
  const { shiftId: shift } = selectResult();

  useTitleBar({
    container: "core:presence",
    locationTitle: t("Position.presence.onSiteWorkers"),
  });

  const positionEntities = selectEntity(selectPosition({}));
  const positionAttendanceEntities = selectEntity(selectPositionAttendance({}));

  const statusCounts = countBy(({ progressStatus }: PositionAttendance) =>
    progressStatus === ClosingProgress.AfterBreak ? ClosingProgress.Present : progressStatus
  )(positionAttendanceEntities);

  const canClose =
    positionEntities.every(({ clockInState }) => clockInState === ClockInState.Off) &&
    sum(
      values(
        pick(
          [
            ClosingProgress.Pending,
            ClosingProgress.Break,
            ClosingProgress.AfterBreak,
            ClosingProgress.Late,
            ClosingProgress.Present,
          ],
          statusCounts
        )
      )
    ) === 0;

  return (
    <DateInputPresetProvider
      DateTimeInput={{
        default: {
          format: "d/M HH:mm",
          openTo: "hours",
        },
      }}
    >
      <TwoColumnLayout>
        <MuiGrid container direction="column" spacing={outer}>
          <MuiGrid item>
            <ShiftHeader shift={shift} />
          </MuiGrid>
          {canClose && (
            <MuiGrid item>
              <FinishedShiftStrip shift={shift} />
            </MuiGrid>
          )}
        </MuiGrid>
        <SelectionSetProvider>
          <PresenceTableList shift={shift} />
        </SelectionSetProvider>
      </TwoColumnLayout>
    </DateInputPresetProvider>
  );
}
