import { useCurrentUser, useData, useRequest } from "@sinch/core";
import { PositionQ, selectPayoutMethod, selectPosition, sOr } from "@sinch/entity";
import { SelectInputBase } from "@sinch/forms";
import { t, useFormat } from "@sinch/intl";
import { Card, ConfirmationPanel, Grid, GridLayout, ScrollAnchor, Strip, Text } from "@sinch/ui";
import { all, head, includes, isEmpty, isNil, length, map } from "ramda";
import React, { useState } from "react";
import { useAttendanceRestrictions, useAttendanceRestrictionsBlocking } from "../../Application";
import { requestPositionAttendanceJoin, requestPositionAttendanceLeave, requestPositionDetail } from "./api";
import { PositionReference } from "./PositionReference";

/* eslint-disable camelcase */
const buildLimitString = (
  hours_label,
  max_period_hours,
  max_period_payment,
  payment_label,
  period_balance,
  period_hours_balance
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { curr, dur } = useFormat();
  const strings = [];
  if (all((value) => !isNil(value))([max_period_payment, payment_label, period_balance])) {
    strings.push(`${payment_label}: ${curr(period_balance)}/${curr(max_period_payment)}`);
  }

  if (all((value) => !isNil(value))([hours_label, max_period_hours, period_hours_balance])) {
    strings.push(`${dur.narrowShort(period_hours_balance * 3600)}/${dur.narrowShort(max_period_hours * 3600)}`);
  }

  return strings.length ? strings.join("; ") : undefined;
};

function getPayoutMethodSelect(setSelectedPayoutMethod, selectedPayoutMethod) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectResult, selectEntity } = useData(requestPositionDetail);
  const { payoutMethodsIds } = selectResult();
  return (
    <SelectInputBase
      label={t("Contract.title")}
      name="payoutMethod"
      onChange={({ target: { value } }) => {
        setSelectedPayoutMethod(value);
      }}
      options={map((payoutMethodId) => {
        const {
          name,
          hours_label,
          max_period_hours,
          max_period_payment,
          payment_label,
          period_balance,
          period_hours_balance,
        } = selectEntity(selectPayoutMethod(payoutMethodId));

        const secondaryLabel = buildLimitString(
          hours_label,
          max_period_hours,
          max_period_payment,
          payment_label,
          period_balance,
          period_hours_balance
        );

        return {
          value: payoutMethodId,
          label: name,
          secondaryLabel,
        };
      }, payoutMethodsIds)}
      value={selectedPayoutMethod}
    />
  );
}

function ChangePositions({ positionId }) {
  const dispatch = useRequest();
  const { queryEntity, selectResult } = useData(requestPositionDetail);
  const { availableInGroup } = selectResult();
  const hasConnected = queryEntity(PositionQ.hasConnected(positionId));

  const acceptText = hasConnected
    ? t("Position.acceptTermsAndSignUpConnected", { connected: availableInGroup.length - 1 })
    : t("Position.acceptTermsAndSignUp");
  const positionBold = (
    <b>
      <PositionReference id={positionId} prof role title />
    </b>
  );
  return (
    <ConfirmationPanel
      acceptText={acceptText}
      buttonText={t("Position.action.signUp")}
      color="success"
      onConfirm={() => dispatch(requestPositionAttendanceJoin(positionId))}
    >
      <Text>{t("Position.signUpToAnotherPosition", { positionBold })}</Text>
    </ConfirmationPanel>
  );
}

function AcceptForm({ positionId }) {
  const { selectEntity } = useData(requestPositionDetail);
  const { signOffTimeInterval } = selectEntity(selectPosition(positionId));
  const signOffTime = <b>{t("hoursCount", { hours: signOffTimeInterval })}</b>;
  return (
    <Grid spacing="inner" vertical>
      <Text>{t("Position.signUpSuggestions")}</Text>
      <Text>{t("Position.signOffLimitTime", { signOffTime })}</Text>
    </Grid>
  );
}

function SignInAsWorker({ positionId }) {
  const dispatch = useRequest();
  const { queryEntity, selectResult } = useData(requestPositionDetail);
  const { availableInGroup, payoutMethodsIds } = selectResult();
  const isAssigned = queryEntity(PositionQ.User.isAssigned(positionId));
  const hasConnected = queryEntity(PositionQ.hasConnected(positionId));
  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState(head(payoutMethodsIds));

  const acceptText =
    hasConnected && !isAssigned
      ? t("Position.acceptTermsAndSignUpConnected", { connected: availableInGroup.length - 1 })
      : t("Position.acceptTermsAndSignUp");

  return (
    <ConfirmationPanel
      acceptText={acceptText}
      buttonText={t("Position.action.signUp")}
      color="success"
      onConfirm={() => dispatch(requestPositionAttendanceJoin(positionId, selectedPayoutMethod))}
      secondaryContent={
        length(payoutMethodsIds) <= 1 ? undefined : getPayoutMethodSelect(setSelectedPayoutMethod, selectedPayoutMethod)
      }
    >
      <AcceptForm positionId={positionId} />
    </ConfirmationPanel>
  );
}

function SignInAsApplicant({ positionId }) {
  const dispatch = useRequest();
  const { queryEntity, selectResult } = useData(requestPositionDetail);
  const { availableInGroup, payoutMethodsIds } = selectResult();
  const isAssigned = queryEntity(PositionQ.User.isAssigned(positionId));
  const hasConnected = queryEntity(PositionQ.hasConnected(positionId));
  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState(head(payoutMethodsIds));

  const acceptText =
    hasConnected && !isAssigned
      ? t("Position.acceptTermsAndSignUpConnectedApplicant", {
          connected: availableInGroup.length - 1,
        })
      : t("Position.acceptTermsAndSignUpApplicant");

  return (
    <ConfirmationPanel
      acceptText={acceptText}
      buttonText={t("Position.applicant.signUpToPosition")}
      color="success"
      onConfirm={() => dispatch(requestPositionAttendanceJoin(positionId, selectedPayoutMethod))}
      secondaryContent={
        length(payoutMethodsIds) <= 1 ? undefined : getPayoutMethodSelect(setSelectedPayoutMethod, selectedPayoutMethod)
      }
    >
      <AcceptForm positionId={positionId} />
    </ConfirmationPanel>
  );
}

function SignOutAsApplicant({ positionId }) {
  const dispatch = useRequest();

  return (
    <ConfirmationPanel
      acceptText={t("Position.applicant.signOffText")}
      buttonText={t("Position.applicant.signOffButton")}
      color="error"
      onConfirm={() => dispatch(requestPositionAttendanceLeave(positionId))}
    >
      <ScrollAnchor id="assignedForm" />
      <Text>{t("Position.applicant.signOffNotice")}</Text>
    </ConfirmationPanel>
  );
}

function SignOutAsWorker({ positionId }) {
  const { dt } = useFormat();
  const dispatch = useRequest();
  const { queryEntity } = useData(requestPositionDetail);
  const signOffTime = dt.full(queryEntity(PositionQ.User.getSignOffTime(positionId)));

  const signOffTimeBold = <b>{signOffTime}</b>;

  return (
    <ConfirmationPanel
      acceptText={t("Position.signOffText")}
      buttonText={t("Position.action.signOff")}
      color="error"
      onConfirm={() => dispatch(requestPositionAttendanceLeave(positionId))}
    >
      <ScrollAnchor id="assignedForm" />
      <Text>
        {t("Position.signOffLimitNotice", {
          signOffTimeBold,
        })}
      </Text>
    </ConfirmationPanel>
  );
}

function NoSignOut({ positionId }) {
  const { dt } = useFormat();
  const { queryEntity } = useData(requestPositionDetail);
  const signOffTime = dt.full(queryEntity(PositionQ.User.getSignOffTime(positionId)));
  const locked = queryEntity(PositionQ.isLocked(positionId));

  const signOffTimeBold = <b>{signOffTime}</b>;

  return (
    <Card>
      <Grid spacing="inner" vertical>
        {locked ? (
          <Text>
            <b>{t("Position.cannotSignOffLockedPositionNotice")}</b>
          </Text>
        ) : (
          <Text>
            <b>{t("Position.cannotSignOffNotice")}</b>
            <b>
              {t("Position.signOffLimitAfter", {
                time: signOffTimeBold,
              })}
            </b>
          </Text>
        )}
      </Grid>
    </Card>
  );
}

function CanNotAttend() {
  return (
    <GridLayout.Item>
      <Card>
        <Text bold color="error">
          {t("Position.signUpDenied")}
        </Text>
      </Card>
    </GridLayout.Item>
  );
}

function ProfileHibernated() {
  return (
    <GridLayout.Item>
      <Card>
        <Text bold color="error">
          {t("Profile.signUpDeniedByHibernation")}
        </Text>
      </Card>
    </GridLayout.Item>
  );
}

export function PositionDetailAcceptForm({ positionId }) {
  const restrictions = useAttendanceRestrictions();
  const restrictionBlocking = useAttendanceRestrictionsBlocking();
  const {
    restrictions: { hibernated },
  } = useCurrentUser();
  const isHibernated = !isNil(hibernated) && hibernated !== false;

  const { selectResult, queryEntity } = useData(requestPositionDetail);
  const {
    applicantSignIn,
    workerSignIn,
    workerSignOut,
    attendSameShift,
    signedAsApplicant,
    isJoinable,
    isApplicantRequiredConfirmation,
  } = selectResult();

  const canJoinAsWorker = includes(positionId, workerSignIn);

  if (
    isApplicantRequiredConfirmation ||
    queryEntity(
      sOr(PositionQ.isStatusFinished, PositionQ.isStatusCrewbossClosed, PositionQ.isStatusSupervisorClosed)(positionId)
    )
  ) {
    return null;
  }

  const isAssigned = queryEntity(PositionQ.User.isAssigned(positionId));
  const isRequiredConfirmation = queryEntity(PositionQ.User.isRequiredConfirmation(positionId));

  const canRejoin = !signedAsApplicant && canJoinAsWorker && attendSameShift && !isAssigned;

  return (
    <>
      {restrictions &&
        map((restriction) => (
          <GridLayout.Item>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Strip {...restriction} />
          </GridLayout.Item>
        ))(restrictions)}
      {!restrictionBlocking && !isRequiredConfirmation && (
        <GridLayout.Item>
          {!isHibernated && canRejoin && <ChangePositions positionId={positionId} />}

          {!isHibernated && !canRejoin && !signedAsApplicant && isJoinable && !isEmpty(workerSignIn) && (
            <SignInAsWorker positionId={positionId} />
          )}
          {!isHibernated && !signedAsApplicant && isJoinable && isEmpty(workerSignIn) && !isEmpty(applicantSignIn) && (
            <SignInAsApplicant positionId={positionId} />
          )}

          {workerSignOut && !signedAsApplicant && <SignOutAsWorker positionId={positionId} />}
          {workerSignOut && signedAsApplicant && <SignOutAsApplicant positionId={positionId} />}

          {!workerSignOut && isAssigned && <NoSignOut positionId={positionId} />}
          {!isHibernated && !isJoinable && !isAssigned && !signedAsApplicant && <CanNotAttend />}
          {isHibernated && <ProfileHibernated />}
        </GridLayout.Item>
      )}
    </>
  );
}
