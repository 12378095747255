import { isEmpty, isNil } from "ramda";
import React, { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HashRoute } from "../../../core";
import { useMobileLayout } from "../../UiProvider";
import { DialogContextProvider } from "./DialogContext";
import { DialogProps } from "./DialogProps";
import { FullscreenDialog } from "./FullscreenDialog";
import { ModalDialog } from "./ModalDialog";

/**
 * todo: rename to PersistentDialog
 *  (or maybe parametrize as single component?)
 */
export function InformationDialog({
  children,
  control,
  onClose: onCloseProp,
  open: openProp,
  hashKey,
  denyFullscreen,
  ...props
}: DialogProps): ReactElement {
  const mobile = useMobileLayout();
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const hashVal = `#${hashKey || ""}`;

  const onCloseCallback =
    control?.close ??
    onCloseProp ??
    // @ts-ignore
    (() => navigate(!isEmpty(hash) ? -1 : pathname + search));
  const open = control?.state ?? openProp ?? true;

  // call when user close dialog
  const onClose = () => {
    if (hash === hashVal) {
      // if has is active, navigate back and remove hash from address
      navigate(-1);
      navigate(pathname + search, { replace: true });
    }
    onCloseCallback();
  };

  /* eslint-disable react/jsx-props-no-spreading */

  // check if modal is controlled, if false, modal is always visible
  const isControlled = hashKey && (!isNil(control) || !isNil(openProp));

  // check if modal is open and set hash if is open
  useEffect(() => {
    if (isControlled) {
      if (open) {
        window.location.hash = hashVal;
      } else if (hash === hashVal) {
        // if is not open but there is a hash, navigate back
        navigate(-1);
      }
    }
  }, [open]);

  useEffect(() => {
    // close modal when user navigate back
    if (isControlled && hash !== hashVal && open) {
      onCloseCallback();
    }
  }, [hash]);

  const dialogComponent = (
    <DialogContextProvider onClose={onClose}>
      {mobile && !denyFullscreen ? (
        <FullscreenDialog onClose={onClose} open {...props}>
          {children}
        </FullscreenDialog>
      ) : (
        <ModalDialog onClose={onClose} open {...props}>
          {children}
        </ModalDialog>
      )}
    </DialogContextProvider>
  );

  // if modal is not controlled, just show it without hash route
  return hashKey && isControlled ? <HashRoute path={hashKey}>{dialogComponent}</HashRoute> : dialogComponent;
}
