import { Box, IconButton, SvgIcon } from "@material-ui/core";
import { mdiClose } from "@mdi/js";
import { usePushSubscription } from "@sinch/core";
import { SinchPushNotification } from "@sinch/icons";
import { externalLink, Header, Icon, Strip, StripProps } from "@sinch/ui";
import React, { useState } from "react";
import { isMobileSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { SupportedBrowsersDialog } from "./SupportedBrowsersDialog";
import { pushNotificationStripHiddenSessionStorageKey } from "./utils";

const PushNotificationStripBase: React.FC<Pick<StripProps, "primaryAction" | "children">> = ({
  primaryAction,
  children,
}) => {
  const { t } = useTranslation();
  const [stripVisible, setStripVisible] = useState(true);
  const handleClose = () => {
    localStorage.setItem(pushNotificationStripHiddenSessionStorageKey, "true");
    setStripVisible(false);
  };

  if (!stripVisible) {
    return null;
  }

  return (
    <Strip color="info" icon={<SvgIcon component={SinchPushNotification} />} primaryAction={primaryAction}>
      <>
        <Box display="flex" justifyContent="space-between">
          <Header level={3}>{t("PushNotifications.title")}</Header>
          <IconButton onClick={handleClose} size="small">
            <Icon icon={mdiClose} size="small" />
          </IconButton>
        </Box>
        {children}
      </>
    </Strip>
  );
};

export const PushNotificationStrip: React.FC = () => {
  const { t } = useTranslation();
  const { status, subscribe } = usePushSubscription();
  const [dialogOpen, setDialogOpen] = useState(false);

  if (status === "active") {
    return null;
  }

  if (status === "incompatible" && isMobileSafari) {
    return (
      <PushNotificationStripBase
        primaryAction={{
          action: externalLink("https://support.apple.com/guide/iphone/iph42ab2f3a7/ios"),
          label: "learn more",
          btnProps: { variant: "outlined" },
        }}
      >
        {t("PushNotifications.alertSafari")}
      </PushNotificationStripBase>
    );
  }

  if (status === "incompatible") {
    const toggleDialog = () => setDialogOpen(!dialogOpen);

    return (
      <>
        <PushNotificationStripBase
          primaryAction={{
            action: toggleDialog,
            label: t("PushNotifications.supportedBrowsersButton"),
            btnProps: { variant: "outlined" },
          }}
        >
          {t("PushNotifications.alertIncompatible")}
        </PushNotificationStripBase>
        <SupportedBrowsersDialog dialogOpen={dialogOpen} toggleDialog={toggleDialog} />
      </>
    );
  }

  if (status === "denied") {
    return <PushNotificationStripBase>{t("PushNotifications.alertDenied")}</PushNotificationStripBase>;
  }

  return (
    <PushNotificationStripBase
      primaryAction={{
        action: subscribe,
        label: t("allow"),
        btnProps: { variant: "outlined", loading: status === "loading" },
      }}
    >
      {t("PushNotifications.alertCompatible")}
    </PushNotificationStripBase>
  );
};
