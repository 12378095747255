import { FormControlLabel, Hidden } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { Callback, Color } from "@sinch/types";
import { ChildrenProps, useToggleState } from "@sinch/utils";
import React, { ReactElement, ReactNode } from "react";
import { Button } from "../Button";
import { Card } from "../Card";
import { Checkbox } from "../Checkbox";
import { Divider } from "../Divider";
import { Flex } from "../Layout";
import { useMobileLayout } from "../UiProvider";

export interface ConfirmationPanelProps extends ChildrenProps {
  acceptText: string;

  buttonText: string;

  color?: Color;

  confirmationContent?: ReactNode;

  onConfirm: Callback;

  readyToConfirm?: boolean;

  secondaryContent?: ReactNode;
}

/**
 * @deprecated component mixes multiple site-specific functionality together
 *   thus it's too coupled to actual input data
 *   todo: replace with composition-based implementation
 */
export function ConfirmationPanel({
  acceptText,
  buttonText,
  children,
  color,
  confirmationContent,
  onConfirm,
  readyToConfirm = true,
  secondaryContent,
}: ConfirmationPanelProps): ReactElement {
  const [confirmed, toggleConfirmed] = useToggleState(false);
  const mobile = useMobileLayout();

  return (
    <Card>
      <MuiGrid container spacing={mobile ? 1 : 3}>
        <MuiGrid item md={6} xs={12}>
          {children}
        </MuiGrid>
        <MuiGrid item md={6} xs={12}>
          <Hidden mdUp>
            <Divider />
          </Hidden>
          {confirmationContent}
          <Flex alignCenter>
            <FormControlLabel
              control={<Checkbox disabled={!readyToConfirm} checked={confirmed} onChange={toggleConfirmed} />}
              label={acceptText}
            />
          </Flex>
          {secondaryContent}
          <Divider />
          <Button action={onConfirm} color={color} disabled={!confirmed} type="submit">
            {buttonText}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Card>
  );
}
