import { makeStyles } from "@material-ui/core/styles";
import { Color, IconId, OneOrMore } from "@sinch/types";
import clsx from "clsx";
import React, { Children, ReactChild, ReactElement } from "react";
import { Icon } from "../Icon";
import { getEntityColors, IdentifierColorKey } from "../paletteStyles";
import { Text } from ".";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    position: "relative",
    top: "-0.1em",
    color: theme.palette.grey[400],
  },
  iconBefore: {
    paddingRight: theme.spacing(1),
  },
  iconAfter: {
    paddingLeft: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  ...getEntityColors(theme),
}));

export interface DataGridItemProps {
  color?: Color | IdentifierColorKey;

  children?: OneOrMore<ReactChild> | null;

  icon?: IconId;

  iconPosition?: "before" | "after";
}

export function IconText({ color, children, icon, iconPosition = "before" }: DataGridItemProps): ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.item}>
      {iconPosition === "before" && (
        <div className={clsx(styles.icon, styles.iconBefore)}>
          {icon && <Icon color={color} icon={icon} size="small" />}
        </div>
      )}
      <div className={styles.content}>
        {Children.map(children, (row) => (
          <Text color={color}>{row}</Text>
        ))}
      </div>
      {iconPosition === "after" && (
        <div className={clsx(styles.icon, styles.iconAfter)}>
          {icon && <Icon color={color} icon={icon} size="small" />}
        </div>
      )}
    </div>
  );
}
