import { Box, Collapse as MuiCollapse, Grid as MuiGrid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  mdiCalendarBlank,
  mdiCarArrowLeft,
  mdiCarArrowRight,
  mdiCash,
  mdiChevronDown,
  mdiChevronUp,
  mdiCoffeeOutline,
  mdiCurrencyUsd,
  mdiDomain,
  mdiMapMarkerOutline,
  mdiTimelapse,
  mdiTimerOutline,
} from "@mdi/js";
import { useData } from "@sinch/core";
import { selectCompany, selectLocation, selectPosition, selectShift, selectTransport } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  Card,
  DataList,
  DataListItem,
  Divider,
  GridLayout,
  Icon,
  Link,
  Map,
  Spacer,
  Timeline,
  useMobileLayout,
  useSpacing,
  WrapWords,
} from "@sinch/ui";
import { Typography } from "@sinch/ui/Text/Typography";
import { rejectFalsy, useToggleState } from "@sinch/utils";
import { subMinutes } from "date-fns";
import { defaultTo, filter, prop } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React from "react";
import { getMapApiKey } from "../../env";
import { requestPositionDetail } from "./api";
// TODO: extract to utils
const mapsSelector = (lat, lng) =>
  // if (
  //   /* if we're on iOS, open in Apple Maps */
  //   navigator.platform.indexOf("iPhone") !== -1 ||
  //   navigator.platform.indexOf("iPad") !== -1 ||
  //   navigator.platform.indexOf("iPod") !== -1
  // ) {
  //   return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  //   /* else use Google */
  // }
  `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

const useStyles = makeStyles(() => ({
  toggleLink: {
    display: "flex",
  },
}));

const LocationDataItem = ({ locationName, locationDescription, locationAddress, point }) => {
  const classes = useStyles();
  const [value, toggleValue] = useToggleState();

  return (
    <Box pt={1}>
      <DataListItem
        colorSelector={prop("color")}
        contentSelector={prop("content")}
        iconSelector={prop("icon")}
        item={{
          // label: t("location"),
          content: rejectFalsy([
            <WrapWords>
              <Typography bold component="span">
                {locationName}
              </Typography>
              , {locationAddress}
            </WrapWords>,
            <MuiGrid container spacing={2}>
              {isNotNilOrEmpty(locationDescription) && (
                <MuiGrid item>
                  <Link className={classes.toggleLink} onClick={() => toggleValue()}>
                    {t("description")}
                    <Icon icon={value ? mdiChevronUp : mdiChevronDown} size="small" />
                  </Link>
                </MuiGrid>
              )}
              {point && (
                <MuiGrid item>
                  <Link href={mapsSelector(point.lat, point.lng)} rel="noopener" target="_blank">
                    {t("map")}
                  </Link>
                </MuiGrid>
              )}
            </MuiGrid>,
            isNotNilOrEmpty(locationDescription) && <MuiCollapse in={value}>{locationDescription}</MuiCollapse>,
          ]),
          icon: mdiMapMarkerOutline,
        }}
        labelSelector={prop("label")}
      />
    </Box>
  );
};

const wageFormat = (wage, type) => (
  <Spacer>
    {wage}
    {type}
  </Spacer>
);

export function PositionDetailParametersList({ positionId }) {
  const { curr, dt } = useFormat();
  const [outer] = useSpacing();

  const { selectEntity, selectResult } = useData(requestPositionDetail);
  const { startTime, endTime, meetingTimeInterval, location, workBreak, shift } = selectEntity(
    selectPosition(positionId)
  );
  const { wage, totalWage, minimumRenumeration } = selectResult();

  const { address: locationAddress, name: locationName, description: locationDescription, point } = selectEntity(
    selectLocation(location)
  );

  const { company, transport } = selectEntity(selectShift(shift));
  const { name: companyName = "" } = company ? selectEntity(selectCompany(company)) : {};

  const transportElement = (element) =>
    prop(element, transport) && selectEntity(selectTransport(prop(element, transport)));
  const transportTo = transportElement("to");
  const transportFrom = transportElement("from");
  const transportLocation = (transportObject) => selectEntity(selectLocation(prop("location", transportObject)));
  const locationTo = transportTo && transportLocation(transportTo);
  const locationFrom = transportFrom && transportLocation(transportFrom);
  const transportWageX = (transportObject) => defaultTo(0)(prop("wage", transportObject));
  const transportWage = transportWageX(transportTo) + transportWageX(transportFrom);

  const parametersBefore = [
    {
      label: dt(startTime),
      icon: mdiCalendarBlank,
    },
  ];

  let workTimelineItem = [
    {
      startTime,
      endTime,
      title: t("work"),
      icon: mdiTimelapse,
      content: (
        <LocationDataItem
          locationAddress={locationAddress}
          locationDescription={locationDescription}
          locationName={locationName}
          point={point}
        />
      ),
    },
  ];
  if (workBreak) {
    workTimelineItem = [
      {
        startTime,
        endTime: workBreak.startTime,
        title: t("work"),
        icon: mdiTimelapse,
        content: (
          <LocationDataItem
            locationAddress={locationAddress}
            locationDescription={locationDescription}
            locationName={locationName}
            point={point}
          />
        ),
      },
      {
        icon: mdiCoffeeOutline,
        startTime: workBreak.startTime,
        endTime: workBreak.endTime,
        title: t("break"),
      },
      { startTime: workBreak.endTime, endTime, title: t("work"), icon: mdiTimelapse },
    ];
  }
  const timeline = [
    transport &&
      transport.to &&
      transportTo.meetingTimeInterval !== 0 && {
        icon: mdiTimerOutline,
        startTime: subMinutes(transportTo.startTime, transportTo.meetingTimeInterval),
        title: t("Transport.meetup"),
      },
    transport &&
      transport.to && {
        ...transportTo,
        title: t("Transport.there"),
        icon: mdiCarArrowRight,
        content: (
          <LocationDataItem
            locationAddress={locationTo.address}
            locationDescription={locationTo.description}
            locationName={locationTo.name}
            point={locationTo.point}
          />
        ),
      },
    meetingTimeInterval !== 0 && {
      startTime: subMinutes(startTime, meetingTimeInterval),
      icon: mdiTimerOutline,
      title: t("Position.meetupBefore"),
    },
    ...workTimelineItem,
    transport &&
      transport.from &&
      transportFrom.meetingTimeInterval !== 0 && {
        startTime: subMinutes(transportFrom.startTime, transportFrom.meetingTimeInterval),
        icon: mdiTimerOutline,
        title: t("Transport.meetup"),
      },
    transport &&
      transport.from && {
        ...transportFrom,
        title: t("Transport.back"),
        icon: mdiCarArrowLeft,
        content: (
          <LocationDataItem
            locationAddress={locationFrom.address}
            locationDescription={locationFrom.description}
            locationName={locationFrom.name}
            point={locationFrom.point}
          />
        ),
      },
  ];

  const parametersAfter = [
    totalWage &&
      totalWage > 0 && {
        label: t("Wallet.wage"),
        content: curr(totalWage),
        icon: mdiCurrencyUsd,
        divider: true,
      },
    !totalWage &&
      wage &&
      (wage.hour > 0 || wage.fix > 0) && {
        label: t("Wallet.wage"),
        content: filter((x) => x, [
          wage &&
            wageFormat(
              <>
                {[
                  wage.hour > 0 && `${curr(wage.hour)}/${t("hoursAbbr")}`,
                  wage.hour > 0 && wage.fix > 0 && " + ",
                  wage.fix > 0 && curr(wage.fix),
                ]}
              </>,
              t("work")
            ),
          transport &&
            (transport.from || transport.to) &&
            transportWage > 0 &&
            wageFormat(curr(transportWage), t("display.transport")),
        ]),
        icon: /* ICON */ mdiCurrencyUsd,
        divider: true,
      },
    minimumRenumeration && {
      label: t("minimumRenumeration"),
      content: curr(minimumRenumeration),
      icon: mdiCash,
      divider: true,
    },
    company && {
      label: t("company"),
      content: companyName,
      icon: /* ICON */ mdiDomain,
    },
  ];

  const mobile = useMobileLayout();

  return (
    <GridLayout.Item>
      <Card>
        <MuiGrid container spacing={outer}>
          <MuiGrid item md={5} xs={12}>
            <DataList data={rejectFalsy(parametersBefore)} disablePadding />
            <Timeline steps={timeline} />
            <Divider />
            <DataList data={rejectFalsy(parametersAfter)} disablePadding />
          </MuiGrid>
          {!mobile && (
            <MuiGrid item md={7}>
              <Map apiKey={getMapApiKey() || undefined} point={point} />
            </MuiGrid>
          )}
        </MuiGrid>
      </Card>
    </GridLayout.Item>
  );
}
