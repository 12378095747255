import { Color, IconId } from "@sinch/types";
import { pick } from "ramda";
import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Toolbar } from "../../ApplicationLayout";
import { SwiperPagesNavigation } from "./SwiperPagesNavigation";
import { SwiperPagesSwiper } from "./SwiperPagesSwiper";

interface SwiperPageProps {
  badge?: { content: string | number; color?: Color };
  icon: IconId;
  id: string;
  label: ReactNode;
  lazy?: boolean;
}

interface SwiperPagesProps {
  active?: string;
}

export function SwiperPages({ children, active }: PropsWithChildren<SwiperPagesProps>): ReactElement | null {
  const [selected, setSelected] = useState<string>(active || "");
  const childrenProps: PropsWithChildren<SwiperPageProps>[] = useMemo(
    () =>
      Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return pick(["badge", "icon", "id", "label", "lazy", "children"], child.props);
      }),
    [children]
  );

  const handleChange = useCallback((val: string) => {
    setSelected(val);
  }, []);

  useEffect(() => {
    if (active && active !== selected) {
      setSelected(active);
    }
  }, [active]);

  return (
    <>
      <SwiperPagesSwiper childrenProps={childrenProps} onChange={handleChange} selected={selected} />
      <Toolbar behavior="fixed" position="bottom">
        <SwiperPagesNavigation childrenProps={childrenProps} onChange={handleChange} selected={selected} />
      </Toolbar>
    </>
  );
}

export function SwiperPage(props: PropsWithChildren<SwiperPageProps>): ReactElement {
  return <></>;
}
