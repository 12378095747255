import { t } from "@sinch/intl";
import { useSnackbar } from "@sinch/ui";
import { useFormikContext } from "formik";
import { isEmpty, values } from "ramda";
import { useEffect } from "react";

/**
 * Show invalid form toast message when user try to send invalid form
 */
export function useInvalidFormWarning() {
  const { isSubmitting, isValid, validateForm, ...rest } = useFormikContext();
  const snackbar = useSnackbar();
  useEffect(() => {
    if (isSubmitting && !isValid && isEmpty(rest.errors)) {
      validateForm().then((errors) => {
        if (values(errors).length !== 0) {
          snackbar("error", t("Profile.form.missingMandatoryFields"));
        }
      });
    }
  }, [isSubmitting, isValid]);
}
