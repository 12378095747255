import { Func, Nullable } from "@sinch/types";
import { ChildrenProps, isDefined } from "@sinch/utils";
import { always, ifElse, omit } from "ramda";
import { isNull } from "ramda-adjunct";
import React, { createContext, ReactElement, useContext } from "react";
import { TextParams } from "./TextParams";

interface StructuredParams {
  structured?: boolean;
}

type TextParamsContextState = Nullable<StructuredParams & TextParams>;

const TextParamsContext = createContext<TextParamsContextState>(null);

function isStructured(context: Nullable<StructuredParams>): boolean {
  return (context && context.structured) || false;
}

export function useStructuredText(): boolean {
  return isStructured(useContext(TextParamsContext));
}

const omitStructured: Func<TextParamsContextState, TextParams> = ifElse(isNull, always({}), omit(["structured"]));

export function useTextParams(): TextParams {
  return omitStructured(useContext(TextParamsContext));
}

function wrapperName(container: boolean, structured: boolean): string {
  if (container) return "StructuredText";
  if (structured) return "P";
  return "Text";
}

export function useTextParamsRequired(component: string, structuredText = false): TextParams | never {
  const textParams = useTextParams();
  const inStructuredContext = useStructuredText();
  const matchingStructured = structuredText === inStructuredContext;

  if (isDefined(textParams) && matchingStructured) {
    return textParams;
  }

  const wrapper = wrapperName(structuredText, inStructuredContext);
  throw new Error(`<${component}> may be used only in the context of <${wrapper}> component.`);
}

export function TextParamsProvider({
  structured,
  children,
  ...textParams
}: StructuredParams & TextParams & ChildrenProps): ReactElement {
  const context = { structured, ...textParams };

  return <TextParamsContext.Provider value={context}>{children}</TextParamsContext.Provider>;
}
