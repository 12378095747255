import { withStyles } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTablePagination from "@material-ui/core/TablePagination";
import MuiTableRow from "@material-ui/core/TableRow";
import { t } from "@sinch/intl";
import React, { ChangeEventHandler, FunctionComponent, MouseEvent, ReactElement } from "react";
import { DataTable, WithDisplayProps } from "./DataTable";
import { DataTableDisplay } from "./types";

export interface MuiDataTableDisplay extends DataTableDisplay<typeof MuiTableRow> {}

const Cell = withStyles(({ palette, typography }) => ({
  root: {
    /* set default typography to body1 */
    ...typography.body1,
    /* disable divider alpha */
    borderBottom: `1px solid ${palette.divider}`,
  },
}))(MuiTableCell);

const Head = withStyles(({ typography }) => ({
  root: {
    "& .MuiTableCell-root": {
      /* set default typography to body */
      ...typography.body2,
    },
  },
}))(MuiTableHead);

export interface MuiDataTableProps<TData, TColumn extends string>
  extends WithDisplayProps<TData, TColumn, MuiDataTableDisplay> {}

/**
 * todo: type properly so we dont need to use casting
 *
 * todo: how to assign MuiDataTableProps to factory created component?
 */
export const MuiDataTable = DataTable.withDisplay<MuiDataTableDisplay>({
  Container: MuiTableContainer,
  Table: MuiTable,
  Head: Head as FunctionComponent,
  Body: MuiTableBody,
  Row: MuiTableRow,
  Cell: Cell as FunctionComponent,
});

export interface MuiDataTablePaginationProps {
  count: number;

  onChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;

  onChangeRowsPerPage?: ChangeEventHandler<HTMLInputElement>;

  page: number;

  rowsPerPage?: number;

  rowsPerPageOptions?: number[];
}

const defaultRowsPerPageOptions = [25, 50, 100, 200];

/**
 * todo: use actions to render paging buttons as router link
 *
 * todo: consider removing rows per page prop and force default
 *
 * todo: integrate pagination into table component
 *  (can be enabled with bool prop)
 */
export function MuiDataTablePagination({
  count: setCount,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rowsPerPage = defaultRowsPerPageOptions[0],
  rowsPerPageOptions = defaultRowsPerPageOptions,
}: MuiDataTablePaginationProps): ReactElement {
  return (
    <MuiTablePagination
      component="div"
      count={setCount}
      labelDisplayedRows={({ from, to, count: total }) => {
        const totalLabel = total !== -1 ? total : t("paginator.moreThan", { total });
        const range = `${from}-${to}`;
        return t("paginator.rangeOf", {
          range,
          total: totalLabel,
        }); // `${from}-${to} of ${count !== -1 ? count : `more than {total}`)}`;
      }}
      labelRowsPerPage={t("paginator.rows")}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
}
