import { defineRequest } from "@sinch/core";
import {
  Fk,
  Penalty,
  PenaltyContainer,
  PenaltyStatus,
  SuspendedPenalty,
  SuspendedPenaltyContainer,
} from "@sinch/entity";

export const requestPenaltyList = defineRequest("Dashboard/PenaltyList")
  .withEmptyParams()
  .withResponse<{
    penaltyIds: Fk<Penalty>[];
    status: PenaltyStatus;
  }>()
  .withResponseEntity<PenaltyContainer & SuspendedPenaltyContainer>()
  .withDefaultCreator();

export const requestSuspendedPenaltyList = defineRequest("Dashboard/SuspendedPenaltyList")
  .withEmptyParams()
  .withResponse<{
    suspendedPenaltyIds: Fk<SuspendedPenalty>[];
    status: PenaltyStatus;
  }>()
  .withResponseEntity<PenaltyContainer & SuspendedPenaltyContainer>()
  .withDefaultCreator();
