import { defineRequest, IdParams } from "@sinch/core";
import { Appointment, Fk } from "@sinch/entity";

export interface AppointmentAttendanceParams extends IdParams<Appointment> {
  /**
   * Selected time slot
   */
  time?: Date;
}

export const requestAppointmentAttendanceJoin = defineRequest("Appointments/Join/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<AppointmentAttendanceParams>()
  .withEmptyResponse()
  .withCreator((id: Fk<Appointment>, time?: Date) => ({
    params: { id, time },
  }));

export const requestAppointmentAttendanceLeave = defineRequest("Appointments/Leave/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<AppointmentAttendanceParams>()
  .withEmptyResponse()
  .withCreator((id: Fk<Appointment>) => ({
    params: { id },
  }));
