import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { selectAppointment } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Identifier, InformationDialog, routerLink, Text, useMobileLayout } from "@sinch/ui";
import { isNil } from "ramda";
import React, { ReactElement } from "react";
import { requestAppointmentDetail } from "../../Appointment";
import { AppointmentDetailContent } from "../AppointmentDetailContent";

export function CalendarAppointmentDetailDialog(): ReactElement | null {
  const { selectResult, selectEntity } = useData(requestAppointmentDetail);
  const { appointmentId } = selectResult();

  const appointment = selectEntity(selectAppointment(appointmentId));
  if (isNil(appointment)) {
    return null;
  }
  const { name } = appointment;
  return (
    <InformationDialog
      actions={{
        cancel: false,
        confirm: {
          action: routerLink(`/appointment/${appointmentId}`),
          label: t("action.detail"), // Detail
        },
      }}
      denyFullscreen
      title={
        <Box display="flex" flexDirection="column">
          <Text small>
            {t("Appointment.title")} <Identifier entity="appointment" id={appointmentId} />
          </Text>
          {name}
        </Box>
      }
      width="xs"
    >
      <AppointmentDetailContent appointment={appointmentId} />
    </InformationDialog>
  );
}
