/* eslint-disable import/no-extraneous-dependencies,import/no-internal-modules */
import { Box as MuiBox, GridSize } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { mdiAlertCircleOutline, mdiAt, mdiBell, mdiCellphoneText, mdiHelpCircle } from "@mdi/js";
import { DataProvider, responseHandlerKey, useData, usePushSubscription, useRefresh } from "@sinch/core";
import {
  NotificationSettingsChannel,
  NotificationSettingsRecord,
  NotificationSettingsSection,
  NotificationSettingsValue,
  requestNotificationSettings,
  requestNotificationSettingsUpdate,
} from "@sinch/core/contract";
import {
  CheckboxInput,
  CheckboxInputBase,
  Form,
  SubmitButton,
  SwitchInputBase,
  useFormUpdate,
  useFormValues,
} from "@sinch/forms";
import { useFormContext } from "@sinch/forms/Form/FormContext";
import { SinchPushNotification } from "@sinch/icons";
import { Key } from "@sinch/types";
import {
  Button,
  Card,
  externalLink,
  Grid,
  Icon,
  Link,
  PaperContainer,
  routerLink,
  Text,
  useMobileLayout,
  useSnackbar,
  useTitleBar,
} from "@sinch/ui";
import clsx from "clsx";
import { TFunction } from "i18next";
import {
  assoc,
  flatten,
  indexBy,
  intersection,
  isEmpty,
  map,
  mapObjIndexed,
  mergeRight,
  pick,
  pluck,
  prop,
  reject,
} from "ramda";
import { allEqualTo, isNotNil, isUndefined } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { isMobileSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SupportedBrowsersDialog } from "../../components";

/**
 * todo: render form using datatable with styled row
 */
const useStyles = makeStyles(({ mixins: { component, onLarge }, spacing, zIndex, ...theme }) => ({
  section: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  row: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[300],
  },
  topHeader: {
    backgroundColor: theme.palette.background.paper,
    // height: `calc(100vh - ${spacing(8)}px)`,
    // minWidth: spacing(8),
    overflowY: "auto",
    position: "sticky",
    top: component.titleBar.small.height,
    ...onLarge({
      top: component.titleBar.large.height,
    }),
    zIndex: zIndex.drawer,
    /// maxWidth: "320px",
  },
}));

/* @ts-expect-error */
const indexById = indexBy(prop("id"));

const filterValues = reject(isUndefined);

const channels: NotificationSettingsChannel[] = ["notification", "email", "sms", "pushNotification"];

function getChannelTitle(key: string, t: TFunction): string {
  const channelsTitles: { [key: string]: string } = {
    notification: t("Notification.title"),
    email: t("email"),
    sms: t("sms"),
    pushNotification: t("PushNotifications.title"),
  };

  return channelsTitles[key];
}

const channelsIcons: { [key: string]: ReactElement } = {
  notification: <Icon icon={mdiBell} />,
  email: <Icon icon={mdiAt} />,
  sms: <Icon icon={mdiCellphoneText} />,
  pushNotification: <SvgIcon component={SinchPushNotification} />,
};

interface SectionCheckboxProps {
  channel: NotificationSettingsChannel;

  section: NotificationSettingsSection;
}

function SectionCheckbox({ channel, section }: SectionCheckboxProps) {
  const values = useFormValues<NotificationSettingsRecord>();
  const setValues = useFormUpdate<NotificationSettingsRecord>();

  const items = useMemo(() => pluck("id", section.items), [section.items]);

  const setItemValues = useCallback(
    (val: boolean) => {
      const update = (it: NotificationSettingsValue) => (isNotNil(it[channel]) ? assoc(channel, val, it) : it);

      setValues((prev) => {
        const updated = mapObjIndexed(update, pick(items, prev));
        return mergeRight(prev, updated);
      });
    },
    [channel, items, setValues]
  );

  const itemValues = filterValues(map((id: Key) => values[id][channel], items));

  if (isEmpty(itemValues) || allEqualTo(null, itemValues)) return null;

  const enabled = allEqualTo(true, itemValues);
  const disabled = allEqualTo(false, itemValues);

  return (
    <CheckboxInputBase
      indeterminate={!enabled && !disabled}
      name={`${section.title}-${channel}`}
      onChange={() => setItemValues(!enabled)}
      value={enabled}
    />
  );
}

function initializeForm(sections: NotificationSettingsSection[]): NotificationSettingsRecord {
  const items = flatten(pluck("items", sections));
  /* @ts-expect-error */
  return map(pick(channels), indexById(items));
}

const PushNotificationsPanel: React.FC = () => {
  const { t } = useTranslation();
  const { subscribe, unsubscribe, status } = usePushSubscription();
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const getErrorText = () => {
    if (status === "incompatible" && isMobileSafari) {
      return (
        <>
          {t("PushNotifications.alertSafari")}.{" "}
          <Link {...externalLink(" https://developer.mozilla.org/en-US/docs/Web/API/Push_API#browser_compatibility")}>
            {t("learnMore")}
          </Link>
        </>
      );
    }
    if (status === "denied") {
      return t("PushNotifications.alertDenied");
    }
    return (
      <>
        {t("PushNotifications.alertIncompatible")}{" "}
        <Link color="primary" onClick={toggleDialog} size="small">
          {t("learnMore")}
        </Link>
        <SupportedBrowsersDialog dialogOpen={dialogOpen} toggleDialog={toggleDialog} />
      </>
    );
  };

  return (
    <MuiBox>
      <SwitchInputBase
        disabled={["incompatible", "denied"].includes(status)}
        label={t("PushNotifications.switchTitle")}
        loading={status === "loading"}
        name="pushNotifications"
        note={t("PushNotifications.alertCompatible")}
        onChange={status === "active" ? unsubscribe : subscribe}
        value={status === "active"}
      />
      {["incompatible", "denied"].includes(status) && (
        <MuiBox style={{ gap: "4px", display: "flex", alignItems: "center", marginTop: "8px" }}>
          <Icon color="error" icon={mdiAlertCircleOutline} size="small" />
          <Text color="error" small>
            {getErrorText()}
          </Text>
        </MuiBox>
      )}
    </MuiBox>
  );
};

function NotificationSettingsBody(): ReactElement {
  const { selectResult } = useData(requestNotificationSettings);
  const { sections } = selectResult();

  return (
    <>
      <Form<NotificationSettingsRecord>
        initialValues={initializeForm(sections)}
        submitRequest={requestNotificationSettingsUpdate}
      >
        <NotificationSettingsFormBody />
      </Form>
    </>
  );
}

function NotificationSettingsFormBody(): ReactElement {
  const { t } = useTranslation();
  const styles = useStyles();
  const mobile = useMobileLayout();
  const { submit } = useFormContext();

  useTitleBar(
    mobile
      ? {
          container: "profile:editNotification",
          submitHandler: submit,
          showBack: true,
          confirmExit: true,
          locationTitle: t("NotificationSettings.title"),
        }
      : { container: "profile:editNotification" }
  );

  const { selectResult } = useData(requestNotificationSettings);
  const { sections } = selectResult();

  const activeChannels = intersection(
    channels,
    sections.reduce((result: NotificationSettingsChannel[], section) => {
      section.items.some((item) => {
        channels.forEach((channel) => {
          if (isNotNil(item[channel]) && !result.includes(channel)) {
            result.push(channel);
          }
        });
        return result.length === channels.length;
      });
      return result;
    }, [])
  );

  return (
    <>
      <MuiGrid alignItems="stretch" className={clsx(styles.topHeader, styles.row)} container>
        <MuiGrid item md={4} xs={6}>
          <MuiBox m={1}>
            <Text>{t("Notification.titlePlural")}</Text>
          </MuiBox>
        </MuiGrid>
        <MuiGrid container md={8} xs={6}>
          {activeChannels.map((channel) => (
            <MuiGrid key={channel} item xs={(12 / activeChannels.length) as GridSize}>
              <MuiBox display="flex" justifyContent="center" m={1}>
                {mobile ? channelsIcons[channel] : <Text>{getChannelTitle(channel, t)}</Text>}
                {channel === "pushNotification" && (
                  <Icon
                    color="info"
                    css={{ fontSize: 5 }}
                    icon={mdiHelpCircle}
                    size="small"
                    style={{ fontSize: 12 }}
                    tooltip={t<string>("PushNotifications.settingsTooltip")}
                  />
                )}
              </MuiBox>
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiGrid>
      {sections.map((section) => {
        const sectionRow = (
          <MuiGrid key={section.title} alignItems="stretch" className={styles.row} container>
            <MuiGrid item md={4} xs={6}>
              <MuiBox className={styles.section} pl={1}>
                <Text bold>{section.title}</Text>
              </MuiBox>
            </MuiGrid>
            <MuiGrid container md={8} xs={6}>
              {activeChannels.map((channel) => (
                <MuiGrid key={`${channel}.${section.title}`} item xs={(12 / activeChannels.length) as GridSize}>
                  <MuiBox className={styles.section} display="flex" justifyContent="center">
                    <SectionCheckbox channel={channel} section={section} />
                  </MuiBox>
                </MuiGrid>
              ))}
            </MuiGrid>
          </MuiGrid>
        );
        const typeRow = section.items.map((item) => (
          <MuiGrid key={item.id} alignItems="stretch" className={styles.row} container>
            <MuiGrid alignItems="center" container item md={4} xs={6}>
              <MuiBox pl={3}>
                <Text>{item.title}</Text>
              </MuiBox>
            </MuiGrid>
            <MuiGrid container md={8} xs={6}>
              {activeChannels.map((channel) =>
                isNotNil(item[channel]) ? (
                  <MuiGrid key={`${channel}.${item.id}`} item xs={(12 / activeChannels.length) as GridSize}>
                    <MuiBox display="flex" justifyContent="center">
                      <CheckboxInput name={`${item.id}.${channel}`} />
                    </MuiBox>
                  </MuiGrid>
                ) : (
                  <MuiGrid key={`${channel}.${item.id}`} item xs={(12 / activeChannels.length) as GridSize} />
                )
              )}
            </MuiGrid>
          </MuiGrid>
        ));
        return [sectionRow, typeRow];
      })}
      {!mobile && (
        <MuiBox m={1}>
          <Grid sizes={mobile ? 6 : 3} spacing="outer">
            <SubmitButton label={t("action.save")} />
            <Button action={routerLink("/profile")}>{t("action.cancel")}</Button>
          </Grid>
        </MuiBox>
      )}
    </>
  );
}

function NotificationSettings(): ReactElement {
  const mobile = useMobileLayout();
  const WrapperComponent = mobile ? PaperContainer : Card;
  const { isModuleActive } = usePushSubscription();
  return (
    <MuiGrid container direction="column" spacing={2}>
      {isModuleActive && (
        <MuiGrid item>
          <WrapperComponent>
            <PushNotificationsPanel />
          </WrapperComponent>
        </MuiGrid>
      )}
      <MuiGrid item>
        <WrapperComponent>
          <NotificationSettingsBody />
        </WrapperComponent>
      </MuiGrid>
    </MuiGrid>
  );
}

export function NotificationSettingsContainer(): ReactElement {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { refresh, refreshToken } = useRefresh();
  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestNotificationSettingsUpdate,
        () => {
          snackbar("success", t("Profile.notificationSettings.saveSuccess"));
          navigate("/profile");
          refresh();
        },
        () => {
          snackbar("error", t("Profile.notificationSettings.saveError"));
          refresh();
        }
      ),

    [navigate, refresh, snackbar]
  );

  const request = useMemo(() => requestNotificationSettings(), []);

  return (
    <DataProvider handler={responseHandler} refresh={refreshToken} request={request}>
      <NotificationSettings />
    </DataProvider>
  );
}
