import { FormControl as MuiFormControl, FormHelperText as MuiFormHelperText, makeStyles } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { Consumer } from "@sinch/types";
import { has, values } from "ramda";
import React, { ReactElement, useState } from "react";
import { HtmlContent } from "../../ui";
import { ChildrenProps } from "../../utils";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";
import { InternalInput, InternalInputTextProps } from "../TextInput/InternalInput";

const useStyles = makeStyles((theme) => ({
  sized: ({ maxSize }: { maxSize?: number }) => (maxSize ? { maxWidth: `${maxSize + 10}ch` } : {}),
  separatorSpacing: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(theme.typography.fontSize * 2),
  },
}));

interface CompoundedItemSeparator {
  separator: string;
}

interface CompoundedItemInput {
  name: string;
  label: string;
  length?: number;
}

type CompoundedInputSchema = Array<CompoundedItemSeparator | CompoundedItemInput>;

interface CompoundedInputProps
  extends Omit<InternalInputTextProps & ManagedInputProps, "dense" | "placeholder" | "type"> {
  schema: CompoundedInputSchema;
}

/**
 * CompoundedInput create multiple inputs separated by separator string. Structure of inputs is defined by `CompoundedInputSchema[]` interface
 *
 * CompoundedInput has base name and receive all TextField properties, which are propagated to every input.
 * If error occurred, only one error message is rendered for all inputs
 */
export function CompoundedInput(props: CompoundedInputProps): ReactElement {
  const [{ value, ...baseProps }, { setValue }] = useFormField<string[], CompoundedInputProps>(props);

  return (
    <CompoundedInputInputBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...baseProps}
      onChange={setValue}
      value={values<{ [index: number]: string }, number>(value)}
    />
  );
}

/**
 * Compound separator grid element. Separator can be anything of type ReactNode
 * @param children
 * @constructor
 */
function CompoundedSeparator({ children }: ChildrenProps) {
  const styles = useStyles({});
  return (
    <MuiGrid<"div"> component="div" item xs="auto">
      <div className={styles.separatorSpacing}>{children}</div>
    </MuiGrid>
  );
}

/**
 * Render text field grid element. It receive params from CompoundedElement.
 */
function CompoundedTextInput({
  label,
  length,
  ...props
}: CompoundedItemInput & BaseInputProps<string, InternalInputTextProps>) {
  const styles = useStyles({ maxSize: length });
  return (
    <MuiGrid<"div"> className={styles.sized} component="div" item xs>
      <InternalInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        label={label}
        placeholder={label}
        type="text"
      />
    </MuiGrid>
  );
}

/**
 * Uncontrolled CompoundedInput create multiple inputs separated by separator string. Structure of inputs is defined by `CompoundedInputSchema[]` interface
 *
 * CompoundedInput has base name and receive all TextField properties, which are propagated to every input.
 * If error occurred, only one error message is rendered for all inputs
 */
export function CompoundedInputInputBase({
  schema,
  name: baseName,
  value: fieldsValues,
  onChange,
  error = false,
  note,
  ...props
}: BaseInputProps<string[], CompoundedInputProps, Consumer<string[]>>): ReactElement {
  const [compoundedValues, setCompoundedValues] = useState(fieldsValues);

  const onChangeOneField = (index: number, value: string): void => {
    const newValues: string[] = [...compoundedValues];
    newValues[index] = value;
    onChange(newValues);
    setCompoundedValues(newValues);
  };

  // Render separator element
  const getCompoundedSeparator = (index: number, { separator }: CompoundedItemSeparator) => (
    <CompoundedSeparator key={`separator${index}`}>
      <HtmlContent html={separator} />
    </CompoundedSeparator>
  );

  // Render text input element
  const getCompoundedTextInput = (index: number, item: CompoundedItemInput) => (
    <CompoundedTextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...item}
      key={item.name}
      error={error}
      name={`${baseName}.${item.name}`}
      onChange={(e) => onChangeOneField(index, e.target.value)}
      value={compoundedValues[index] || ""}
    />
  );

  return (
    <MuiFormControl error={error} fullWidth>
      <MuiGrid alignItems="center" container spacing={1}>
        {schema.map((item: CompoundedItemSeparator | CompoundedItemInput, index) =>
          has("separator", item as CompoundedItemSeparator)
            ? getCompoundedSeparator(index, item as CompoundedItemSeparator)
            : getCompoundedTextInput(index, item as CompoundedItemInput)
        )}
      </MuiGrid>
      {note && <MuiFormHelperText error={error}>{note}</MuiFormHelperText>}
    </MuiFormControl>
  );
}
