export enum CalendarEntryType {
  Unavailable = 0,

  Available = 1,

  Position = 2,

  Appointment = 3,

  Transport = 4,

  Applicant = 5,

  Confirmation = 6,
}
