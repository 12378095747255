import { BadgeProps as MuiBadgeProps } from "@material-ui/core";
import MuiBadge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { Color } from "@sinch/types";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { configurePaletteStyles, styleKey } from "../paletteStyles";

const variants = ["text", "outlined", "contained"];

/**
 * todo: infer stylesheet names from variable+color using template strings
 */
const createPaletteStyles = configurePaletteStyles({ variants });
const paletteStyles = createPaletteStyles(({ main, contrastText }, { theme }) => ({
  "& .MuiBadge-badge": { color: contrastText || theme?.palette.getContrastText(main), backgroundColor: main },
}));

const useStyles = makeStyles((theme) => ({
  ...paletteStyles(theme),
}));

export interface BadgeProps extends Omit<MuiBadgeProps, "color"> {
  color: Color;
}

export function Badge({ color, className, children, ...props }: BadgeProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiBadge
      className={clsx(
        className,
        /* @ts-expect-error */
        color && styles[styleKey("contained", color)]
      )}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    >
      {children}
    </MuiBadge>
  );
}
