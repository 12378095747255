import MuiGrid from "@material-ui/core/Grid";
import { mdiAccountAlert, mdiAccountCancel, mdiAccountEdit, mdiAccountQuestion } from "@mdi/js";

import { useCurrentUser } from "@sinch/core";
import { t, useFormat } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { routerLink, StripProps, Text } from "@sinch/ui";
import { isEmpty } from "ramda";
import React from "react";
import { isDefined } from "../../utils";

export function useAttendanceRestrictions(): Nullable<(StripProps & { id: string })[]> {
  const { curr } = useFormat();

  const strips: StripProps[] = [];
  const appendStrip = (strip: StripProps & { id: string }) => {
    strips.push(strip);
  };

  const {
    restrictions: { conditionalPenalty, penalty, incompleteProfile, incompleteAttributesCounts, portraitMandatory },
  } = useCurrentUser();

  if (isDefined(penalty)) {
    appendStrip({
      id: "jobRestriction",
      children: (
        <Text>
          {t("Application.penaltyNotice", {
            penaltyAmount: curr(penalty),
          })}
        </Text>
      ),
      color: "error",
      icon: mdiAccountCancel,
      primaryAction: {
        btnProps: { variant: "outlined" },
        label: t("detail"),
        action: routerLink("/dashboard/incoming#/penalty"),
      },
      title: t("Application.jobJoinRestrictionMessage"),
    });
  }

  if (isDefined(conditionalPenalty) && conditionalPenalty > 0) {
    appendStrip({
      id: "jobKick",
      children: <Text>{t("Application.restrictionContactAdmin")}</Text>,
      color: "warning",
      icon: mdiAccountAlert,
      primaryAction: {
        btnProps: { variant: "outlined" },
        label: t("detail"),
        action: routerLink("/dashboard/incoming#/suspendedPenalty"),
      },
      title: t("Application.jobKickWithPenalty"),
    });
  }

  if (isDefined(incompleteProfile) && incompleteProfile !== false) {
    appendStrip({
      id: "unfilledProfile",
      children: (
        <MuiGrid container direction="column" spacing={1}>
          {(incompleteProfile as Array<string>).map((warning, index) => (
            <MuiGrid key={index} item>
              <Text>{warning}</Text>
            </MuiGrid>
          ))}
          <MuiGrid item>
            <Text>{t("Application.restrictionUnfilledFieldsWarning")}</Text>
          </MuiGrid>
        </MuiGrid>
      ),
      color: "warning",
      icon: mdiAccountQuestion,
      primaryAction: {
        btnProps: { variant: "outlined" },
        label: t("fill"),
        action: routerLink("/profile"),
        btnProps: { variant: "outlined" },
      },
      title: t("Application.unfilledRequiredInformationWarning"),
    });
  }

  if (isDefined(incompleteAttributesCounts) && incompleteAttributesCounts) {
    appendStrip({
      id: "unfilled attributes",
      children: <Text>{t("Application.unfilledAttributesWarningMessage")}</Text>,
      color: "warning",
      icon: mdiAccountEdit,
      primaryAction: {
        btnProps: { variant: "outlined" },
        label: t("editProfile"),
        action:
          (incompleteAttributesCounts?.profile || 0) > 0
            ? routerLink("/profile/edit/attributes")
            : routerLink("/profile/edit/personal"),
      },
      title: t("Application.unfilledAttributesWarningTitle"),
      additionalAttributes: { "data-cy": "worker-content" },
    });
  }

  if (isDefined(portraitMandatory) && portraitMandatory) {
    appendStrip({
      id: "unfilledAvatar",
      children: <Text>{t("Application.unfilledMandatoryAvatarWarningContent")}</Text>,
      color: "warning",
      icon: mdiAccountEdit,
      primaryAction: {
        btnProps: { variant: "outlined" },
        label: t("Profile.uploadProfilePicture"),
        action: routerLink("/profile/personal#/uploadPortrait"),
      },
      title: t("Application.unfilledMandatoryAvatarWarningTitle"),
    });
  }

  return !isEmpty(strips) ? strips : null;
}

export function useAttendanceRestrictionsBlocking(): boolean {
  const {
    restrictions: { penalty, conditionalPenalty },
  } = useCurrentUser();

  if (isDefined(penalty)) {
    return true;
  }

  if (isDefined(conditionalPenalty)) {
    return conditionalPenalty > 0;
  }

  return false;
}
