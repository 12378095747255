import { Box, Fab, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mdiMagnify } from "@mdi/js";

import {
  requestUserPreferenceUpdate,
  useData,
  useRequest,
  UserPreferenceContainer,
  UserPreferencesKeys,
  useSearchParamsCustom,
  useUserPreferences,
} from "@sinch/core";
import { useCacheState } from "@sinch/core/backend/provider/CachedResponseProvider";
import { ComplementaryDialog, Divider, Grid, Icon, Loading, Toolbar, useDialog, useSpacing } from "@sinch/ui";
import { head, isEmpty, keys, omit, values } from "ramda";
import React, { ReactElement, useEffect, useState } from "react";
import { useSwiperSlide } from "swiper/react";
import { PositionSearch, PositionSearchUrlParams } from "../../../Position";
import { PositionListContainer } from "../../../Position/List/PositionList";
import { SnippetList } from "../../SnippetList";
import { requestDashboardAttendanceList } from "../api";
// eslint-disable-next-line import/no-internal-modules
import { MobilePositionInProgress, PositionConfirmation } from "../components";
import { MobilePositionList } from "./MobilePositionList";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 56 + theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1100,
  },
  fabBottom: {
    paddingTop: 56 + theme.spacing(2),
  },
}));

export function MobileDashboardOverview(): ReactElement {
  const [, , data] = useSpacing();
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { nearestPositions, confirmationPositionIds, confirmationApplicantPositionIds } = selectResult();
  console.log("redraw");
  return (
    <>
      <Box px={data} py={data}>
        <SnippetList />
        <Grid spacing="data" vertical>
          {nearestPositions.map((nearestPosition) => (
            <MobilePositionInProgress positionId={nearestPosition} />
          ))}
          {(!isEmpty(confirmationPositionIds) || !isEmpty(confirmationApplicantPositionIds)) && (
            <PositionConfirmation
              isApplicantConfirmation={isEmpty(confirmationPositionIds) && !isEmpty(confirmationApplicantPositionIds)}
              positionId={head(confirmationPositionIds) ?? head(values(confirmationApplicantPositionIds)[0] ?? [])}
              size={(confirmationPositionIds ?? []).length + keys(confirmationApplicantPositionIds).length}
            />
          )}
        </Grid>
        {!isEmpty(nearestPositions) && <Divider dark spacing={3} />}
      </Box>
      <PositionListDahboardPart />
      <DashboardPositionFilter />
    </>
  );
}

function PositionListDahboardPart() {
  const isCached = useCacheState();
  const [loaded, setLoaded] = useState(isCached);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return <Loading />;
  }
  return (
    <UserPreferenceContainer>
      <PositionList />
    </UserPreferenceContainer>
  );
}

function PositionList() {
  const [defaultInitialized, setDefaultInitialized] = useState(false);
  const dispatch = useRequest();
  const preferences = useUserPreferences();

  const quickFilterPreferences = preferences[UserPreferencesKeys.positionListQuickFilter] || {};
  const { searchParams } = useSearchParamsCustom<PositionSearchUrlParams>(omit(["from", "to"], quickFilterPreferences));

  useEffect(() => {
    if (defaultInitialized) {
      dispatch(
        requestUserPreferenceUpdate(UserPreferencesKeys.positionListQuickFilter, omit(["from", "to"], searchParams))
      );
    } else {
      setDefaultInitialized(true);
    }
  }, [searchParams]);

  return (
    <PositionListContainer defaultParams={{ ...searchParams, limit: "50" }} useOverlayLoading={false}>
      <MobilePositionList />
    </PositionListContainer>
  );
}

function DashboardPositionFilter(): ReactElement {
  const dialog = useDialog();
  const styles = useStyles();
  const { isActive } = useSwiperSlide();

  return (
    <>
      <Box className={styles.fabBottom} />
      <Toolbar paper={false} position="bottom">
        <Zoom in={isActive} unmountOnExit>
          <Fab className={styles.fab} color="primary" onClick={dialog}>
            <Icon icon={mdiMagnify} />
          </Fab>
        </Zoom>
      </Toolbar>
      <ComplementaryDialog control={dialog}>
        <PositionSearch />
      </ComplementaryDialog>
    </>
  );
}
