import { useFormat } from "@sinch/intl";
// eslint-disable-next-line import/no-internal-modules
import { differenceInSeconds } from "date-fns";
import React, { ReactElement } from "react";
import { Spacer } from "../Spacer";

export interface TimeRangeProps {
  endTime: Date;

  showLength?: boolean;

  startTime: Date;

  startTimeSuffix?: string;

  endTimeSuffix?: string;
}

export function TimeRange({
  endTime,
  showLength,
  startTime,
  startTimeSuffix,
  endTimeSuffix,
}: TimeRangeProps): ReactElement {
  const { dt, dur } = useFormat();
  const segments = [
    `${dt.time(startTime)}${startTimeSuffix ? ` ${startTimeSuffix}` : ""}`,
    "-",
    `${dt.time(endTime)}${endTimeSuffix ? ` ${endTimeSuffix}` : ""}`,
  ];

  if (showLength) {
    const length = dur.narrow(differenceInSeconds(endTime, startTime, { roundingMethod: "ceil" }));
    segments.push(`(${length})`);
  }

  return <Spacer>{segments}</Spacer>;
}
