import { Theme } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { isIos } from "@sinch/utils";

/**
 * keep component stylesheets alphabetically sorted and utils on top
 *
 * todo: global overrides should be eliminated to avoid interference,
 *  replace with explicit styling of individual base ui components
 */
export function createOverrides({ typography, spacing, palette }: Theme, dark: boolean): Overrides {
  return {
    /**
     * disable animations globally
     * @see https://material-ui.com/getting-started/faq/#how-can-i-disable-transitions-globally
     *
     * set default typography to body1 instead of default body2
     * @see https://github.com/mui-org/material-ui/issues/17100
     */
    MuiCssBaseline: {
      "@global": {
        "*, *::before, *::after": {
          // transition: "none !important",
          // animation: "none !important",
        },
        'a:not([class*="Mui"])': {
          color: "#1976D2",
        },
        body: {
          ...typography.body1,
        },
        button: {
          ...typography.body1,
        },
        "[required] .MuiOutlinedInput-notchedOutline legend span": {
          // TODO: HACK!, make selects react correctly to requirement attribute
          paddingRight: "15px",
        },
      },
    },
    /**
     * make large button same font and icon size as medium (default)
     */
    MuiButton: {
      sizeLarge: {
        minHeight: 42,
        ...typography.button,
      },
      iconSizeLarge: {
        "& > *:first-child": {
          fontSize: 20,
        },
      },
      outlined: {
        border: "1px solid ".concat(palette.type === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.4)"),
      },
    },
    /**
     * remove top padding from card action bar
     */
    MuiCardActions: {
      root: {
        paddingTop: 0,
      },
    },
    /**
     * set bottom padding of last card content to 2x spacing instead of default
     * 3x
     */
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: spacing(2),
        },
      },
    },
    /**
     * override chip typography
     */
    MuiChip: {
      root: typography.chip,
    },
    /**
     * reset label z-index to prevent displaying over popper
     */
    MuiInputLabel: {
      outlined: {
        color: palette.type === "light" ? palette.grey[700] : palette.text.secondary,
        zIndex: "initial",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: palette.type === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255,255,255,0.4)",
      },
    },
    MuiPopover: {
      paper: isIos()
        ? {
            transition: "none !important",
            animation: "none !important",
          }
        : {},
    },
    /**
     * remove divider from last menu item so we dont have to handle it in code
     */
    MuiListItem: {
      divider: {
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    /**
     * disable icon min width
     */
    MuiListItemIcon: {
      root: {
        minWidth: 0,
      },
    },
    /**
     * set margin between icon and text
     *
     * todo: consider overriding margins where needed, not globally
     */
    MuiListItemText: {
      root: {
        marginLeft: spacing(2),
        marginRight: spacing(),
      },
    },
    /**
     * override default border and add gentle shadow to paper and cards
     *
     * todo: consider having default paper without borders for use in drawer
     *  and elevation1 paper with borders and shadows for use in cards
     *  and possibly elevation2 paper for popup menus
     */
    MuiPaper: {
      elevation0: {
        border: "1px solid ".concat(palette.divider),
        borderBottom: dark ? undefined : "none",
        boxShadow: [
          "0px 2px 1px -1px rgba(0, 0, 0, 0.1)",
          "0px 1px 1px 0px rgba(0, 0, 0, 0.1)",
          "0px 1px 3px 0px rgba(0, 0, 0, 0.1)",
        ].join(","),
      },
    },
    /**
     * override tab typography
     */
    MuiTab: {
      root: typography.tab,
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "8px 8px 8px 16px",
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiAlert: {
      outlinedWarning: {
        boxShadow: "none",
      },
      root: {
        alignItems: "center",
      },
    },
    MuiBottomNavigationAction: {
      label: {
        transition: "none",
        "&.Mui-selected": {
          fontSize: typography.caption.fontSize,
        },
      },
      root: {
        "&.Mui-selected": {
          color: palette.secondary.main,
        },
      },
    },
  };
}
