import { defineRequest, FileHash, FilesMeta } from "@sinch/core";
import {
  Fk,
  Profession,
  ProfessionContainer,
  ProfileAttribute,
  SnippetContainer,
  User,
  UserContainer,
} from "@sinch/entity";
import { Key, Maybe } from "@sinch/types";
import { parseDate, rejectFalsy } from "@sinch/utils";

/** @deprecated todo: use expiration date instead and remove when unused */
export enum ProfileAttributeExpiration {
  None = 0,

  Expiring = 1,

  Expired = 2,
}

export enum PhoneVerificationStatus {
  Unverified,
  CodeSend,
  Verified,
}

export interface ProfileStatusResult {
  /**
   * Show warning about expiring/expired ProfileAttribute.
   *
   * Defined as tuple of {@link ProfileAttribute} id and expiration date.
   *
   * todo: consider renaming to just `expiration`?
   */
  attributeExpiration?: {
    personalAttribute?: { id: Fk<ProfileAttribute>; expiration: Date };
    profileAttribute?: { id: Fk<ProfileAttribute>; expiration: Date };
  };

  badgeCount: number;

  availableProfileAttributesCount: number;

  /**
   * Show warning about incomplete Personal info.
   *
   * - undefined: no problem
   * - string: identifier of static property (eg. "lastName")
   * - number: id of {@link PersonalAttribute}
   *   (values are used to generate navigation link)
   */
  incomplete?: [block: Key, attribute: Maybe<Key>];

  maxRating: number;

  portrait?: FileHash;

  professionIds: Fk<Profession>[];

  rating: number;

  /**
   * todo: ensure consistency and not use context to access user data
   */
  userId: Fk<User>;

  verified: boolean;

  phoneVerificationStatus?: PhoneVerificationStatus;

  phoneVerificationTimeout?: Date;

  portraitEditable: boolean;

  portraitMandatory: boolean;
}

export const requestProfileStatus = defineRequest("Profiles/Status")
  .withEmptyParams()
  .withResponse<ProfileStatusResult>({
    attributeExpiration: (val: ProfileStatusResult["attributeExpiration"]) =>
      rejectFalsy({
        ...(val?.personalAttribute && {
          personalAttribute: { id: val.personalAttribute.id, expiration: parseDate(val.personalAttribute.expiration) },
        }),
        ...(val?.profileAttribute && {
          profileAttribute: { id: val.profileAttribute.id, expiration: parseDate(val.profileAttribute.expiration) },
        }),
      }),
    phoneVerificationTimeout: parseDate,
  })
  .withResponseMeta<FilesMeta>()
  .withResponseEntity<ProfessionContainer & UserContainer & SnippetContainer>()
  .withDefaultCreator();
