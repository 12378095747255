import { Box } from "@material-ui/core";
import { mdiAlert, mdiTextBoxSearchOutline } from "@mdi/js";

import { useBusinessRules, useData } from "@sinch/core";
import { Fk, selectPayoutMethod, selectWalletTransaction, WalletTransaction } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import {
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  createMuiStyleRow,
  createTextAlignCell,
  currencyLogic,
  Icon,
  MuiDataTable,
  MuiDataTableDisplay,
  Text,
  typographyLogic,
} from "@sinch/ui";
import { find, isEmpty, map, pipe, pluck, prop, propEq, reverse, sortBy } from "ramda";
import { isNumber } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchParamsPagination } from "../../components";
import { requestWalletTransactionList } from "./api";
import { transactionTypes } from "./common";
import { WalletChip, WalletTransactionHeader } from "./components";
import { WalletEmptyMessage } from "./WalletEmptyMessage";

type TransactionColumn = "accountingDate" | "archived" | "description" | "type" | "payoutMethod" | "value";
const TextAlignRightCell = createTextAlignCell("right");

export function WalletTransactionTable(): ReactElement {
  const { dt } = useFormat();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { selectEntity, selectResult } = useData(requestWalletTransactionList);
  const { walletTransactionIds } = selectResult();
  const { allowWorkerPaymentRequest } = useBusinessRules();

  const sortedWalletTransactionIds: number[] = pipe<WalletTransaction[], WalletTransaction[], number[], number[]>(
    sortBy(prop("accountingDate")),
    pluck("id"),
    reverse
  )(selectEntity(selectWalletTransaction(walletTransactionIds)));

  const ClickableRow = createClickableRow(
    (id: Fk<WalletTransaction>) => () => {
      /*
       * todo: is it possible to improve clickableRowLogic to calculate
       *  which navigation should be used in advance?
       */
      const payout = selectEntity(selectWalletTransaction(id, "payout"));
      if (payout) {
        navigate({ search, hash: `#/payout/${payout}` });
      }

      const position = selectEntity(selectWalletTransaction(id, "position"));
      if (position) {
        navigate({ search, hash: `#/detail/${id}` });
      }
    },
    (id) => {
      const { payout, position } = selectEntity(selectWalletTransaction(id));
      return (isNumber(payout) && allowWorkerPaymentRequest) || isNumber(position);
    }
  );

  const StyledRow = createMuiStyleRow(
    (theme) => ({
      negative: {
        color: theme.palette.neutral.main,
        backgroundColor: theme.palette.background.default,
        /*
         * todo: bold should be implemented using typographic component
         *  (this is selector specificity hack as a temporary workaround,
         *   otherwise styles gets overridden by Text component)
         */
        "& span": {
          fontWeight: "bold",
        },
      },
      archived: {
        textDecoration: "line-through",
        color: theme.palette.neutral.main,
        "& span": {
          color: theme.palette.neutral.main,
        },
      },
    }),
    (id: Fk<WalletTransaction>) => {
      const value = selectEntity(selectWalletTransaction(id, "value"));
      const archived = selectEntity(selectWalletTransaction(id, "archived"));
      if (archived) return ["archived"];
      if (value < 0) return ["negative"];
      return [];
    }
  );

  const TransactionDataTable = MuiDataTable.withConfig<Fk<WalletTransaction>, TransactionColumn>({
    accountingDate: {
      title: t("date"),
      selector: (id) => (
        <Box whiteSpace="nowrap">{dt(selectEntity(selectWalletTransaction(id, "accountingDate")))}</Box>
      ),
    },
    type: {
      title: t("display.type"),
      selector: (id) =>
        find(propEq("value", selectEntity(selectWalletTransaction(id, "type"))))(transactionTypes())?.label ?? "",
    },
    payoutMethod: {
      title: t("Contract.title"),
      selector: (id) => {
        const method = selectEntity(selectWalletTransaction(id, "payoutMethod"));
        return method ? selectEntity(selectPayoutMethod(method, "name")) : t("Wallet.display.unassignedType");
      },
    },
    value: {
      title: t("amount"),
      titleCellProps: { align: "right" },
      selector: (id) => selectEntity(selectWalletTransaction(id, "value")),
      logic: composeCellLogic<Fk<WalletTransaction>, TransactionColumn, MuiDataTableDisplay>([
        TextAlignRightCell as never,
        currencyLogic(),
      ]),
    },
    description: {
      title: t("description"),
      selector: (id) => {
        const { description, tags } = selectEntity(selectWalletTransaction(id));
        return (
          <Text separator={" "}>
            <Text>{description}</Text>
            {map(
              (tag) => (
                <WalletChip tag={tag} />
              ),
              tags || []
            )}
          </Text>
        );
      },
    },
    archived: {
      title: "",
      selector: (id) => {
        const { payout, position, archived } = selectEntity(selectWalletTransaction(id));
        return (
          <Box display="flex" flexWrap="noWrap" justifyContent="flex-end">
            {[
              archived && <Icon color="neutral" icon={mdiAlert} tooltip={t("Wallet.display.archived")} />,
              ((isNumber(payout) && allowWorkerPaymentRequest) || isNumber(position)) && (
                <Icon icon={mdiTextBoxSearchOutline} tooltip={t("showDetail")} />
              ),
            ]}
          </Box>
        );
      },
    },
  }).withLogic(composeRowLogic([ClickableRow, StyledRow, typographyLogic()]));

  return (
    <>
      <WalletTransactionHeader />
      {isEmpty(walletTransactionIds) ? (
        <WalletEmptyMessage />
      ) : (
        <TransactionDataTable
          columns={["accountingDate", "value", "type", "payoutMethod", "description", "archived"]}
          data={sortedWalletTransactionIds}
        >
          <SearchParamsPagination />
        </TransactionDataTable>
      )}
    </>
  );
}
