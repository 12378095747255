import MuiLinkComponent from "@material-ui/core/Link";

/**
 * todo: rename to ExternalNavigation
 */
export interface ExternalLink {
  href: string;
  target?: string;
}

/* eslint-disable-next-line @typescript-eslint/ban-types */
export function isExternalLink(action: object): action is ExternalLink {
  return "href" in action;
}

/**
 * todo: consider if there is any added value, might be passed directly as
 *  object param when all components are converted to typescript
 */
export function externalLink(href: string, options?: Omit<ExternalLink, "href">): ExternalLink {
  return {
    href,
    ...options,
  };
}

/* eslint-disable-next-line @typescript-eslint/ban-types */
export function externalLinkProps({ href, target }: ExternalLink): object {
  return {
    color: "inherit",
    component: MuiLinkComponent,
    href,
    rel: "noopener",
    target: target || "_blank",
    underline: "none",
  };
}
