import MuiTooltip from "@material-ui/core/Tooltip";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement } from "react";
import { useMenuContext } from "./context";

interface TooltipProps extends ChildrenProps<ReactElement> {
  title: string;
}

export function Tooltip({ children, title }: TooltipProps): ReactElement {
  const { showText } = useMenuContext();

  if (showText) return children;

  return (
    <MuiTooltip placement="right" title={title}>
      {children}
    </MuiTooltip>
  );
}
