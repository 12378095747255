import { useData } from "@sinch/core";
import {
  ClosingProgress,
  Fk,
  PositionAttendance,
  selectPosition,
  selectPositionAttendance,
  WorkerRole,
} from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { IconText } from "@sinch/ui";
import { intervalToDuration, max } from "date-fns";
import React, { ReactElement } from "react";
import { requestPresenceView } from "../api";
import { useClosingStatusProps } from "../hooks";

export function PresenceStatusIcon({ id, short }: { id: Fk<PositionAttendance>; short: boolean }): ReactElement {
  const { dt, dur } = useFormat();
  const { getProps } = useClosingStatusProps();
  const {
    [ClosingProgress.Pending]: propsPending,
    [ClosingProgress.Present]: propsPresent,
    [ClosingProgress.Absent]: propsAbsent,
    [ClosingProgress.Break]: propsBreak,
    [ClosingProgress.Late]: propsLate,
    [ClosingProgress.Finished]: propsFinished,
  } = getProps();

  const { selectEntity } = useData(requestPresenceView);
  const { startTime, workBreak, endTime, position, progressStatus } = selectEntity(selectPositionAttendance(id));

  const { workBreak: shiftBreak, startTime: shiftStart, role } = selectEntity(selectPosition(position));
  const backup = role === WorkerRole.Backup;

  const start = max([startTime || shiftStart, shiftStart]);
  const duration = startTime && endTime && intervalToDuration({ start, end: endTime });
  // @ts-expect-error
  const hours = typeof duration && dur.narrow(duration.hours * 60 * 60 + duration.minutes * 60);

  return (
    <>
      {progressStatus === ClosingProgress.Pending && (
        <IconText color={propsPending.color} icon={propsPending.icon} iconPosition={short ? "after" : undefined}>
          {!short ? propsPending.label : null}
        </IconText>
      )}
      {(progressStatus === ClosingProgress.Present || progressStatus === ClosingProgress.AfterBreak) && (
        <IconText color={propsPresent.color} icon={propsPresent.icon} iconPosition={short ? "after" : undefined}>
          {startTime &&
            (short
              ? dt.time(startTime)
              : `${propsPresent.label} (${t<string>("Shift.closing.begin")} ${dt.time(startTime)})`)}
        </IconText>
      )}
      {progressStatus === ClosingProgress.Late && (
        <IconText color={propsLate.color} icon={propsLate.icon} iconPosition={short ? "after" : undefined}>
          {`${propsFinished.label} ${t<string>("hoursCount", { hours })} (${startTime && dt.time(startTime)} - ${
            endTime && dt.time(endTime)
          })`}
          {propsLate.label}
        </IconText>
      )}
      {progressStatus === ClosingProgress.Absent && (
        <IconText color={propsAbsent.color} icon={propsAbsent.icon} iconPosition={short ? "after" : undefined}>
          {!short ? propsAbsent.label : null}
        </IconText>
      )}
      {progressStatus === ClosingProgress.Break && (
        <IconText color={propsBreak.color} icon={propsBreak.icon} iconPosition={short ? "after" : undefined}>
          {short ? (
            workBreak && dt.time(workBreak?.startTime || shiftBreak?.startTime)
          ) : (
            <>
              {propsBreak.label} ({t<string>("Shift.closing.begin")}{" "}
              {workBreak && dt.time(workBreak?.startTime || shiftBreak?.startTime)})
            </>
          )}
        </IconText>
      )}
      {progressStatus === ClosingProgress.Finished && (
        <IconText color={propsFinished.color} icon={propsFinished.icon} iconPosition={short ? "after" : undefined}>
          <>
            {!short && `${propsFinished.label} `}
            {!backup && (
              <>
                (<>{`${startTime && dt.time(start)}-${endTime && dt.time(endTime)}`}</>
                <>{!short && `, ${hours}`}</>)
              </>
            )}
          </>
        </IconText>
      )}
    </>
  );
}
