import MuiCheckbox from "@material-ui/core/Checkbox";
import React, { ReactElement } from "react";

export interface CheckboxProps {
  checked?: boolean;

  disabled?: boolean;

  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

  id?: string;
}

export function Checkbox({ checked, disabled, onChange, id }: CheckboxProps): ReactElement {
  return <MuiCheckbox disabled={disabled} checked={checked} id={id} onChange={onChange} />;
}
