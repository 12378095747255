import { useData } from "@sinch/core";
import { Fk, News, selectNews } from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  DataTableRow,
  dateTimeLogic,
  MuiDataTable,
  routerLink,
} from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";
import { SearchParamsPagination } from "../../../components";
import { requestNewsList } from "../api";
import { NEWS_LIST_DEFAULT_LIMIT } from "./NewsListContainer";
import { NewsListEmptyMessage } from "./NewsListEmptyMessage";

type NewsColumn = "news" | "agreed" | "date";

const ClickableRow = createClickableRow((news) => routerLink(`/communication/news/${news}`));

export function NewsList(): ReactElement {
  const { selectEntity, selectResult } = useData(requestNewsList);
  const { newsIds } = selectResult();

  const NewsDataTable = MuiDataTable.withConfig<Fk<News>, NewsColumn>({
    news: {
      title: t("News.title"),
      selector: (id) => selectEntity(selectNews(id, "title")),
    },
    agreed: {
      title: t("confirmed"),
      selector: (id) => selectEntity(selectNews(id, "confirmation")) && t("yes"),
    },
    date: {
      title: t("published"),
      selector: (id) => selectEntity(selectNews(id, "publishedDate")),
      logic: composeCellLogic([dateTimeLogic()]),
    },
  }).withLogic(composeRowLogic([ClickableRow, DataTableRow]));

  return (
    <>
      {isEmpty(newsIds) ? (
        <NewsListEmptyMessage />
      ) : (
        <NewsDataTable columns={["news", "agreed", "date"]} data={newsIds}>
          <SearchParamsPagination defaultLimit={NEWS_LIST_DEFAULT_LIMIT} />
        </NewsDataTable>
      )}
    </>
  );
}
