import { Box, Divider } from "@material-ui/core";
import { useData } from "@sinch/core";
import { t } from "@sinch/intl";
import { Header, SpacingBox, Text, useSpacing } from "@sinch/ui";
import { useLocalStorage } from "@sinch/utils";
import { isEmpty, isNil, length } from "ramda";
import React, { ReactElement, useCallback, useMemo } from "react";
import { PositionListViewSelectHeader, PositionView } from "../../../components";
import { requestDashboardAttendanceList } from "../api";
import { PositionCardField } from "../components";
import { FinishedCondensedList } from "../FinishedCondensedList";
import { FinishedPositions } from "../FinishedPositions";
import { SquareCard } from "./components";

type FilteredFields = "place" | "contact";

export function MobileFinishedTab(): ReactElement {
  const [, , data] = useSpacing();
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { feedbackPositionIds, positionIdsToClose } = selectResult();

  if (
    (isNil(feedbackPositionIds) || isEmpty(feedbackPositionIds)) &&
    (isNil(positionIdsToClose) || isEmpty(positionIdsToClose))
  ) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="24em"
        justifyContent="center"
        textAlign="center"
      >
        <Header color="neutral">{t("Dashboard.noFinished")}</Header>
      </Box>
    );
  }

  return (
    <Box pt={data}>
      <Finished />
    </Box>
  );
}

const FinishedList = React.memo(({ view, positionListViewSelectHeader, filterFields }) => {
  const [, , data] = useSpacing();
  return (
    <>
      {view === PositionView.Condensed && (
        <SquareCard>
          {positionListViewSelectHeader}
          <SpacingBox mx={-data}>
            <Divider variant="fullWidth" />
          </SpacingBox>
          <FinishedCondensedList fields={filterFields} />
        </SquareCard>
      )}
      {view === PositionView.Standard && (
        <Box px={data}>
          {positionListViewSelectHeader}
          <FinishedPositions />
        </Box>
      )}
    </>
  );
});

function Finished(): ReactElement | null {
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { positionIdsToClose } = selectResult();
  const [view, setView] = useLocalStorage<PositionView>("listView", PositionView.Standard);
  const [filterFields, setFields] = useLocalStorage(`listViewFields${"FinishedPositions" || ""}`, []);

  const fields: PositionCardField[] = ["date", "location", "transport", "time", "contact"];

  fields.unshift("status");

  const handleFilterChange = useCallback(setFields, [setFields]);
  const handleViewChange = useCallback(setView, [setView]);

  const positionListViewSelectHeader = useMemo(
    () => (
      <PositionListViewSelectHeader<FilteredFields>
        count={length(positionIdsToClose || [])}
        countLabel={(count) => (
          <Text separator={" "}>
            <Header component="span" level={2}>
              {t("Dashboard.shiftsToClose")}
            </Header>
            <Text color="neutral" small>
              {t("Position.display.positionCount", { count })}
            </Text>
          </Text>
        )}
        fieldsKey="FinishedPositions"
        filterFields={{
          place: t("location"),
          contact: t("Position.display.contact"),
        }}
        onFieldsFilterChange={handleFilterChange}
        onViewChange={handleViewChange}
      />
    ),
    [positionIdsToClose, setView, setFields]
  );

  if (isNil(positionIdsToClose) || isEmpty(positionIdsToClose)) {
    return null;
  }

  return (
    <FinishedList filterFields={filterFields} positionListViewSelectHeader={positionListViewSelectHeader} view={view} />
  );
}
