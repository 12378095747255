import { makeStyles } from "@material-ui/core/styles";
import React, { ReactElement, ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  disable: {
    "& *": {
      color: `${theme.palette.text.disabled} !important`,
      // borderColor: `${theme.palette.neutral.main} !important`,
      // filter: "grayscale(100%)", // DO NOT USE! BROKE SAFARI!
    },
  },
}));

export function DisableStyle({ children }: { children: ReactNode }): ReactElement {
  const styles = useStyles();

  return <div className={styles.disable}>{children}</div>;
}
