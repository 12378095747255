import { useBusinessRules } from "@sinch/core";
import { isDefined, isValue, rejectFalsy } from "@sinch/utils";
import { addHours, isAfter, isBefore, subHours } from "date-fns";
import {
  equals,
  filter,
  gt,
  head,
  includes,
  intersection,
  isEmpty,
  isNil,
  length,
  map,
  or,
  pipe,
  pluck,
  propEq,
  reduce,
  values,
  without,
} from "ramda";
import { selectApplicantAttendance } from "../ApplicantAttendance";
import { selectFeedback } from "../Feedback";
import { selectLocation } from "../Location";
import { PositionAttendanceStatus, selectPositionAttendance } from "../PositionAttendance";
import { selectShift, ShiftFeedbackStatus } from "../Shift";
import { selectTransportAttendance, TransportAttendanceRole } from "../TransportAttendance";
import { PositionStatus } from "./PositionStatus";
import { selectPosition } from "./selectors";
import { WorkerRole } from "./WorkerRole";

/*
 * IMPORTANT: All queries must follow the same selector signature API!
 * `(...params) => (container, appContext) => result`
 *
 * todo: convert to TS and type properly
 *
 * todo: avoid accessing container directly if not necessary,
 *  delegate to already existing selectors instead
 *  (base selector `selectEntity` needs to have direct access)
 *
 * todo: rename Assigned -> Attended for consistent naming!
 *
 * todo: consider encapsulating all selectors into single object,
 *  ideally having eg. Position as class instead of an interface,
 *  so we can define static fields on it and access them like
 *  `Position.isLocked(id)`
 *
 * todo: if we want to use this in type safe manner, then we need to
 *  define expected entity container type for each individual selector,
 *  and also pass container type of actual message when calling hook,
 *  so we get `query` function knowing which entities can be accessed
 *  and can warn when trying to use selector accessing unavailable data
 *
 * todo: can we extract some factory function to simplify selector code?
 *  can we somehow avoid repeating container param all over?
 *  (should pass application context through the selectors as well)
 *
 * todo: collocate selectors into single file (with selectPosition etc.)
 *
 * todo: define standardized ordering in file based on selector complexity
 *  - checking simple data (usually boolean)
 *  - retrieving related data
 *  - checking related data (dependent on retrieving selectors)
 *  - site specific queries
 *
 * todo: consider grouping selectors related to other entities under
 *  separate keys - for example `Position.User.isAssignedToOther`
 */

/*
 * Entity queries
 */

const isStatus = (value) => (positionId) => (container) => {
  const { status } = selectPosition(positionId)(container);
  return equals(value, status);
  // can be simply defined as propEq :-)
  // return propEq("status", value, selectPosition(positionId)(container));
};

const isStatusOpen = isStatus(PositionStatus.Open);
const isStatusRunning = isStatus(PositionStatus.Running);
const isStatusFinished = isStatus(PositionStatus.Finished);
const isStatusCrewbossClosed = isStatus(PositionStatus.CrewbossClosed);
const isStatusUnclosedLate = (positionId) => (container) => {
  const { shiftClosingTimeInterval } = useBusinessRules();
  const { status, endTime } = selectPosition(positionId)(container);
  return (
    !equals(PositionStatus.CrewbossClosed, status) && isAfter(new Date(), addHours(endTime, shiftClosingTimeInterval))
  );
};
const isStatusSupervisorClosed = isStatus(PositionStatus.SupervisorClosed);

const isRole = (value) => (positionId) => (container) => {
  const { role } = selectPosition(positionId)(container);
  return equals(value, role);
};

const isRoleWorker = isRole(WorkerRole.Worker);
const isRoleCrewboss = isRole(WorkerRole.Crewboss);
const isRoleBackup = isRole(WorkerRole.Backup);

const isAttendanceStatus = (value) => (positionId) => (container, { currentUser }) => {
  const attendance = head(
    selectPositionAttendance(
      {
        position: positionId,
        worker: currentUser.id,
      },
      "status"
    )(container)
  );

  return equals(value, attendance);
};

const hasAttendancePresent = isAttendanceStatus(PositionAttendanceStatus.Present);
const hasAttendanceLate = isAttendanceStatus(PositionAttendanceStatus.Late);
const hasAttendanceExcused = isAttendanceStatus(PositionAttendanceStatus.Excused);
const hasAttendanceAbsent = isAttendanceStatus(PositionAttendanceStatus.Absent);

const isLocked = (positionId) => (container) => {
  const { locked } = selectPosition(positionId)(container);
  return locked;
};

const isCancelled = (positionId) => (container) => {
  const { cancelled } = selectPosition(positionId)(container);
  return cancelled || false;
};

const isFull = (positionId) => (container) => {
  const { freeCapacity } = selectPosition(positionId)(container);
  return freeCapacity < 1;
};

const hasRequirementsFailed = (positionId) => (container) => {
  const { requirementsFailed } = selectPosition(positionId)(container);
  return requirementsFailed || false;
};

/** @deprecated todo: replace with requirementsFailed */
const hasRequirementsFulfilled = (positionId) => (container) => !hasRequirementsFailed(positionId)(container);

const hasConnected = (positionId) => (container) => {
  const { connected } = selectPosition(positionId)(container);
  return isValue(connected) && gt(length(connected), 0);
};

const hasConnectedRequirementFailed = (positionId) => (container) => {
  const { connected } = selectPosition(positionId)(container);
  const connectedPositions = selectPosition(connected)(container);

  return pipe(
    // get only with free capacity
    filter(({ freeCapacity }) => gt(freeCapacity, 0)),
    pluck("requirementsFailed"),
    // check if there is at least one failed requirement
    reduce(or, false)
  )(connectedPositions);
};

const hasConflicting = (positionId) => (container) => {
  const { conflicting } = selectPosition(positionId)(container);
  if (!conflicting) return false;
  const { position, appointment } = conflicting;
  return (isValue(position) && gt(length(position), 0)) || (isValue(appointment) && gt(length(appointment), 0));
};

const hasConflictingApplicants = (positionId) => (container) => {
  const { conflicting } = selectPosition(positionId)(container);
  return isDefined(conflicting ? conflicting.applicants : undefined);
};

const hasConflictingPosition = (positionId) => (container) => {
  const { conflicting } = selectPosition(positionId)(container);
  return isDefined(conflicting ? conflicting.position : undefined);
};

const hasConflictingAppointment = (positionId) => (container) => {
  const { conflicting } = selectPosition(positionId)(container);
  return isDefined(conflicting ? conflicting.appointment : undefined);
};

const hasTransport = (positionId) => (container) => {
  const { shift } = selectPosition(positionId)(container);
  const { transport } = selectShift(shift)(container);
  // todo: consider extracting helper `notEmpty = complement(isEmpty)`?
  return !isNil(transport) && !isEmpty(transport);
};

/*
 * todo: data selectors
 *  - User.getShiftAssignedPositions
 * todo: user related selectors
 *  - isUserAssigned
 *  - isUserWaitingList
 *  - isUserTransportDriver
 *  - isUserCrewboss // IMPORTANT!!!
 *
 * todo: group selectors
 *  - canUserJoin
 */

/*
 * Shift queries
 */

/** @deprecated merge with {@link getPositions} */
const getPositionsProp = (positionId, out) => (container) => {
  const { shift } = selectPosition(positionId)(container);
  return selectPosition({ shift }, out)(container);
};

// todo: add outProp as second param?
const getPositions = (positionId) => (container) => getPositionsProp(positionId, "id")(container);

const getOtherPositions = (positionId) => (container) => {
  const positions = getPositions(positionId)(container);
  return without([positionId], positions);
};

const getCrewbossPositions = (positionId) => (container) => {
  const positions = getPositions(positionId)(container);

  return pluck(
    "id",
    filter(propEq("role", WorkerRole.Crewboss), rejectFalsy(map((pos) => selectPosition(pos)(container), positions)))
  );
};

const getTransports = (positionId) => (container) => {
  const { shift } = selectPosition(positionId)(container);
  const { transport } = selectShift(shift)(container);
  return transport ? filter(isDefined, values(transport)) : [];
};

const getFeedback = (positionId) => (container) => {
  const { shift } = selectPosition(positionId)(container);
  return selectFeedback({ shift })(container);
};

const statusCanFeedback = [ShiftFeedbackStatus.Open, ShiftFeedbackStatus.InProgress, ShiftFeedbackStatus.Completed];

const canFeedback = (positionId) => (container) => {
  const { shift } = selectPosition(positionId)(container);
  const feedbackStatus = selectShift(shift, "feedbackStatus")(container);
  return includes(feedbackStatus, statusCanFeedback);
};

const statusHasFeedback = [
  // ShiftFeedbackStatus.InProgress,
  ShiftFeedbackStatus.Completed,
  ShiftFeedbackStatus.Closed,
];

const hasFeedback = (positionId) => (container) => {
  const { shift } = selectPosition(positionId)(container);
  const feedbackStatus = selectShift(shift, "feedbackStatus")(container);
  return includes(feedbackStatus, statusHasFeedback);
};

/*
 * User queries
 */

/**
 * todo: should be defined elsewhere if not explicitly related to current
 * position
 *  (is it regular query or just a helper?)
 */
const getUserAttendedPositions = () => (container, { currentUser }) =>
  selectPositionAttendance({ worker: currentUser.id }, "position")(container);

const getUserAppliedPositions = () => (container, { currentUser }) =>
  selectApplicantAttendance({ worker: currentUser.id }, "position")(container);

const isApplicant = (positionId) => (container, { currentUser }) => {
  const applied = getUserAppliedPositions()(container, { currentUser });
  return includes(positionId, applied);
};
const isAssigned = (positionId) => (container, appContext) => {
  const attended = getUserAttendedPositions()(container, appContext);
  return includes(positionId, attended);
};

const isCrewboss = (positionId) => (container, appContext) => {
  const attended = getUserAttendedPositions()(container, appContext);
  const positions = getCrewbossPositions(positionId)(container);
  return !isEmpty(intersection(positions, attended));
};

const getAssignedPositions = (positionId) => (container, appContext) => {
  const attended = getUserAttendedPositions()(container, appContext);
  const positions = getPositions(positionId)(container);
  return intersection(positions, attended);
};

const isAssignedToOther = (positionId) => (container, appContext) => {
  const position = getAssignedPositions(positionId)(container, appContext);
  const others = without([positionId], position);
  return !isEmpty(others);
};

const isTransportDriver = (positionId) => (container, { currentUser }) => {
  const transports = getTransports(positionId)(container);

  const attendances = selectTransportAttendance(
    {
      worker: currentUser.id,
      role: TransportAttendanceRole.Driver,
    },
    "transport"
  )(container);

  return !isEmpty(intersection(transports, attendances));
};

const getSignOffTime = (positionId) => (container) => {
  const { startTime, signOffTimeInterval } = selectPosition(positionId)(container);
  return subHours(startTime, signOffTimeInterval);
};

const canSignOff = (positionId) => (container) =>
  isStatusOpen(positionId)(container) && isBefore(new Date(), getSignOffTime(positionId)(container));

const hasConnectedUnavailable = (positionId) => (container) => {
  const { connected } = selectPosition(positionId)(container);
  const e = connected.map((connectedPosition) => {
    const { requirementsFailed, inConflict } = selectPosition(connectedPosition)(container);
    return requirementsFailed || inConflict;
  });
  return e.includes(true);
};

const isRequiredConfirmation = (positionId) => (container, appContext) => {
  const attendance = head(
    selectPositionAttendance(
      {
        position: positionId,
        worker: appContext.currentUser.id,
      },
      "confirmation"
    )(container)
  );

  return Boolean(attendance);
};

const canAttend = (positionId) => (container) =>
  hasRequirementsFulfilled(positionId)(container) &&
  !isFull(positionId)(container) &&
  !isLocked(positionId)(container) &&
  !hasConflicting(positionId)(container) &&
  !(hasConnected(positionId)(container) && hasConnectedUnavailable(positionId)(container));

const getSelfClockedStartTime = (positionId) => (container, appContext) => {
  const result = head(
    selectPositionAttendance({
      position: positionId,
      worker: appContext.currentUser.id,
    })(container)
  );
  return result && result.startTimeSelfClocked ? result.startTime : undefined;
};

const getSelfClockedEndTime = (positionId) => (container, appContext) => {
  const result = head(
    selectPositionAttendance({
      position: positionId,
      worker: appContext.currentUser.id,
    })(container)
  );
  return result && result.endTimeSelfClocked ? result.endTime : undefined;
};

const isPositionSelfClockIn = (positionId) => (container) => selectPosition(positionId, "clockInState")(container) > 0;

const isGeolocationActive = (positionId) => (container) => selectPosition(positionId, "geolocationIsActive")(container);

const getLocation = (positionId) => (container) =>
  selectLocation(selectPosition(positionId)(container).location)(container);

export const PositionQ = {
  isStatusOpen,
  isStatusRunning,
  isStatusFinished,
  isStatusCrewbossClosed,
  isStatusSupervisorClosed,
  isStatusUnclosedLate,
  isRoleWorker,
  isRoleCrewboss,
  isRoleBackup,
  isLocked,
  isCancelled,
  isFull,
  hasConnected,
  hasConnectedRequirementFailed,
  hasTransport,
  isPositionSelfClockIn,
  isGeolocationActive,
  Shift: {
    getPositions,
    /** @deprecated merge with {@link getPositions} */
    getPositionsProp,
    getOtherPositions,
    getCrewbossPositions,
    getTransports,
    hasFeedback,
    getFeedback,
  },
  User: {
    getAssignedPositions,
    hasConflicting,
    hasConflictingApplicants,
    hasConflictingPosition,
    hasConflictingAppointment,
    isAssignedToOther,
    isAssigned,
    isApplicant,
    isCrewboss,
    isTransportDriver,
    hasRequirementsFailed,
    hasRequirementsFulfilled,
    hasAttendancePresent,
    hasAttendanceLate,
    hasAttendanceAbsent,
    hasAttendanceExcused,
    canSignOff,
    getSignOffTime,
    canAttend,
    hasConnectedUnavailable,
    canFeedback,
    isRequiredConfirmation,
    getSelfClockedStartTime,
    getSelfClockedEndTime,
  },
  Location: {
    getLocation,
  },
};
