import { mdiFileDocumentOutline } from "@mdi/js";
import { Fk, Shift } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, externalLink } from "@sinch/ui";
import React, { ReactElement } from "react";

export function DownloadList({ shiftId }: { shiftId: Fk<Shift> }): ReactElement {
  return (
    <Button
      action={externalLink(`${window.location.origin}/teams/attendanceDocument/${shiftId}`, { target: "_blank" })}
      color="info"
      icon={mdiFileDocumentOutline}
      stretch
      variant="text"
    >
      {t("Position.downloadAttendanceList")}
    </Button>
  );
}
