import { PaperProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { ReactElement } from "react";
import { Paper } from "../Paper";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    height: `calc(100% + ${theme.spacing(1) * 2}px)`,
    margin: theme.spacing(-1),
    marginBottom: 0,
    padding: theme.spacing(1),
    borderRadius: 0,
    flexGrow: 1,
  },
}));

export function PaperContainer({
  children,
  ...props
}: PaperProps): ReactElement {
  const styles = useStyles();
  return (
    <Paper className={styles.paperContainer} {...props}>
      {children}
    </Paper>
  );
}
