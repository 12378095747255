import { join } from "ramda";

/**
 * Return link for sending sms
 * @param list
 */
export const sendSmsLink = (list: string[]): string => {
  // @ts-expect-error
  const platform = navigator.userAgentData?.platform || navigator.platform;
  if (platform.indexOf("iPhone") !== -1 || platform.indexOf("iPad") !== -1 || platform.indexOf("iPod") !== -1) {
    return `sms:/open?addresses=${join(",", list)}`;
  }
  return `sms:${join(",", list)}`;
};
