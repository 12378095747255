import { defineRequest } from "@sinch/core";
import { Fk, Snippet, SnippetContainer } from "@sinch/entity";

/**
 * todo: change to "Dashboard/Notices" for consistent naming and update entity
 *  (term "Snippet" does not provide clear information about its purpose)
 */
export const requestDashboardSnippetList = defineRequest("Dashboard/SnippetList")
  .withEmptyParams()
  .withResponse<{
    snippetIds: Fk<Snippet>[];
  }>()
  .withResponseEntity<SnippetContainer>()
  .withDefaultCreator();
