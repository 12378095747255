import { VariantType } from "notistack";

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const requestGetAllUsersSubscriptions = async (): Promise<boolean> => {
  const response = await (
    await fetch("/worker/userPushNotificationSubscriptions/manageSubscription", {
      method: "GET",
    })
  ).json();

  return response.result;
};
export const requestGetActiveSubscription = (
  subscription: PushSubscription,
  handleMessage?: (type: VariantType, message?: string) => void
) => subscriptionRequest(subscription, "PUT", handleMessage);
export const requestCreateSubscription = (
  subscription: PushSubscription,
  handleMessage?: (type: VariantType, message?: string) => void
) => subscriptionRequest(subscription, "POST", handleMessage);
export const requestDeleteSubscription = (
  subscription: PushSubscription,
  handleMessage?: (type: VariantType, message?: string) => void
) => subscriptionRequest(subscription, "DELETE", handleMessage);

export const getActiveSubscription = async () => {
  const registration = await navigator.serviceWorker.register("/serviceWorker.js");
  const subscription = await registration.pushManager.getSubscription();
  if (subscription) {
    return requestGetActiveSubscription(subscription);
  }

  return null;
};

const subscriptionRequest = async (
  subscription: PushSubscription,
  method: string,
  handleMessage?: (type: VariantType, message?: string) => void
) => {
  const key = subscription.getKey("p256dh");
  const token = subscription.getKey("auth");
  const contentEncoding = (PushManager.supportedContentEncodings || ["aesgcm"])[0];
  const response = await (
    await fetch("/worker/userPushNotificationSubscriptions/manageSubscription", {
      method,
      body: JSON.stringify({
        endpoint: subscription.endpoint,
        publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
        authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
        contentEncoding,
      }),
    })
  ).json();

  if (handleMessage) {
    handleMessage(response.data.type, response.data.message);
  }

  if (response.result) {
    return subscription;
  }
  return null;
};
