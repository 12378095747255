import { SelectFieldOption } from "@sinch/types";
import { NamedEntity } from "../entity";

export type ProfileAttributeBlock =
  | "car"
  | "certificates"
  | "education"
  | "experience"
  | "gallery"
  | "languages"
  | "main"
  | "other"
  | "personal";

export enum PersonalAttributeCitizenship {
  Any = 0,

  Home = 1,

  Other = 2,
}

export enum ProfileAttributeType {
  /**
   * Numeric value optionally constrained by min/max and step.
   */
  Number = 0,

  /**
   * Textual value optionally constrained by min/max length
   * and regexp pattern.
   */
  Text = 1,

  /**
   * True or false.
   */
  Bool = 2,

  /**
   * Single value selected from given options.
   */
  Select = 3,

  /**
   * Multiple values selected from given options.
   */
  MultiSelect = 4,

  /**
   * Hierarchical selection (rendered as SelectInput).
   */
  Level = 5,

  /**
   * Reference to file uploaded on the server.
   */
  File = 6,

  /**
   * Reference to image file uploaded on the server.
   */
  Image = 7,

  /**
   * Reference to multiple image files uploaded on the server.
   */
  Gallery = 8,

  /**
   * Long textual value optionally constrained by min/max length
   * and regexp pattern.
   */
  LongText = 9,

  Date = 10,

  Phone = -1,
}

export interface ProfileAttribute extends NamedEntity {
  description?: string;

  longDescription?: string;

  expirable: boolean;

  params: {
    options?: SelectFieldOption[];
  };

  type: ProfileAttributeType;

  verifiable: boolean;

  citizenship?: PersonalAttributeCitizenship;

  mandatory: boolean;

  blockId: string;
  blockName: string;
  blockSortNumber: number;
  sortNumber: number;
}
