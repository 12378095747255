import { useData } from "@sinch/core";
import { filter, find, includes, isEmpty, isNil, prop, propEq } from "ramda";
import { requestProfilePersonal } from "../api";

export const useSystemAttributes = () => {
  const { selectResult } = useData(requestProfilePersonal);
  const { systemAttributes } = selectResult();
  const isBlockEmpty = (fields) => isEmpty(filter(({ name }) => includes(name, fields), systemAttributes));

  const isVisible = (field: string) => !isNil(find(propEq("name", field))(systemAttributes));
  const isRequired = (field: string): boolean => !!prop("mandatory", find(propEq("name", field))(systemAttributes));
  return { isBlockEmpty, isVisible, isRequired, systemAttributes };
};
