import MuiListItemText from "@material-ui/core/ListItemText";
import React, { ReactElement } from "react";
import { useMenuContext } from "./context";

interface TitleProps {
  title: string;
}

export function Title({ title }: TitleProps): ReactElement | null {
  const { showText } = useMenuContext();

  if (!showText) return null;

  return <MuiListItemText>{title}</MuiListItemText>;
}
