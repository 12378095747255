import React, { ReactElement } from "react";
import { useFormField, validator } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputTextProps } from "./InternalInput";

interface PhoneInputProps
  extends Omit<InternalInputTextProps, "multiline" | "type"> {}

/**
 * Matches literal '+' followed with 6 to 14 digits.
 */
const regExp = new RegExp(/^\+(?:[0-9]●?){6,14}[0-9]$/);
const isPhone = (val: string) => regExp.test(val);

const config = {
  validate: validator(
    isPhone,
    "Please provide a valid phone number in international format such as +420123456789."
  ),
};

/**
 * todo: use `pattern` prop for native validation (needs react-hooks-form)
 *  - not compatible with formik onChange (overrides the error in next render)
 *  - not connected to material input - needs to be propagated via error handler
 */
export function PhoneInput(props: PhoneInputProps): ReactElement {
  const [baseProps] = useFormField<string, PhoneInputProps>(props, config);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <PhoneInputBase {...baseProps} />;
}

export function PhoneInputBase(
  props: BaseInputProps<string, PhoneInputProps>
): ReactElement {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <InternalInput type="tel" {...props} />;
}
