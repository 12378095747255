import { RefreshContext, useRefresh } from "@sinch/core";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";

const ProfileContext = createCheckedContext<RefreshContext>("ProfileContext");
export const { use: useProfileContext } = ProfileContext;

export function ProfileContextProvider({
  children,
}: ChildrenProps): ReactElement {
  const refreshContext = useRefresh();

  return <ProfileContext value={refreshContext}>{children}</ProfileContext>;
}
