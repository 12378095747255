import { useData } from "@sinch/core";
import { Fk, Position, selectAppointment, selectLocation } from "@sinch/entity";
import { WrapWords } from "@sinch/ui";
import React, { ReactElement } from "react";

export function AppointmentPlace({ id }: { id: Fk<Position> }): ReactElement {
  // @ts-expect-error
  const { selectEntity } = useData();
  const location = selectEntity(selectAppointment(id, "location"));
  const name = selectEntity(selectLocation(location, "name"));
  return <WrapWords>{name}</WrapWords>;
}
