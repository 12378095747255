import { Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, Position, selectPosition } from "@sinch/entity";
import { t, useFormat } from "@sinch/intl";
import { Text, TimeRange, useMobileLayout, useSpacing } from "@sinch/ui";
import { sub } from "date-fns";
import React from "react";
import { requestPositionList } from "../../Position/List/api";

export function PositionTime({ id, withMeetup }: { id: Fk<Position>; withMeetup?: boolean }) {
  const [, inner] = useSpacing();
  const { dt } = useFormat();
  const { selectEntity } = useData(requestPositionList);
  const { startTime, endTime, meetingTimeInterval } = selectEntity(selectPosition(id));
  const isMobile = useMobileLayout();

  return (
    <MuiGrid container spacing={inner}>
      <MuiGrid item>
        <MuiGrid container wrap={isMobile ? "wrap" : "nowrap"}>
          <MuiGrid item style={{ paddingRight: "8px" }} xs="auto">
            <span>
              {dt.wday(startTime)} {dt(startTime)}
            </span>
          </MuiGrid>
          <MuiGrid item style={{ paddingRight: "8px" }} xs="auto">
            <TimeRange endTime={endTime} showLength startTime={startTime} />
          </MuiGrid>
          {meetingTimeInterval > 0 && withMeetup && (
            <MuiGrid item xs="auto">
              <Text bold>
                <>
                  {t("Position.meetingLabel")} {dt.time(sub(startTime, { minutes: meetingTimeInterval }))}
                </>
              </Text>
            </MuiGrid>
          )}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
