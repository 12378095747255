import MuiDialogActions from "@material-ui/core/DialogActions";
import React, { ReactElement } from "react";
import { DialogButton } from "../../Button";
import { DialogActionsProps } from "./DialogProps";
import { resolveDialogActions } from "./resolveDialogActions";

/* eslint-disable react/jsx-props-no-spreading */

export function DialogActions({
  actions,
  onClose,
}: DialogActionsProps): ReactElement {
  const { confirm, cancel } = resolveDialogActions(onClose, actions);

  return (
    <MuiDialogActions>
      {cancel && <DialogButton {...cancel} />}
      {confirm && <DialogButton {...confirm} />}
    </MuiDialogActions>
  );
}
