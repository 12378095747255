import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import { Func, Key, Maybe, Nullable } from "@sinch/types";
import { isDefined, undef } from "@sinch/utils";
import { identity, isEmpty, isNil } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { Action, resolveAction } from "../actions";

/**
 * todo: consider what should be supported by ListBase vs List,
 *  how to divide functionality between components?
 *  - text content list without icons and dividers
 *  - text content list with icons and/or dividers
 *  - text content link behavior list
 *  - custom content list (eg. radio select list)
 *  - custom content link behavior list
 *
 * todo: should we assume that data are objects with id props so idSelector
 *  is a good default choice?
 *  what if we pass array of ids as data?
 *  then identity would be better default..?
 *  maybe noop is the best..?
 *  needs broader use case analysis!
 */

export interface ListBaseProps<T> {
  actionSelector?: Func<T, Maybe<Action>>;

  contentSelector?: Func<T, ReactNode>;

  data: T[];

  dense?: boolean;

  disablePadding?: boolean;

  dividerSelector?: Func<T, Maybe<boolean>>;

  selectionSelector?: Func<T, Maybe<boolean>>;

  idSelector?: Func<T, Maybe<Key>>;

  className?: string;
}

/* eslint-disable react/jsx-props-no-spreading */
export function ListBase<T>({
  className,
  actionSelector = undef,
  contentSelector = identity,
  data,
  dense,
  disablePadding,
  dividerSelector = undef,
  selectionSelector = undef,
  idSelector = undef,
}: ListBaseProps<T>): Nullable<ReactElement> {
  if (isEmpty(data)) {
    return null;
  }
  return (
    <MuiList className={className} dense={dense} disablePadding={disablePadding}>
      {data.map((item, i) => {
        const id = idSelector(item);
        const content = contentSelector(item);
        const divider = dividerSelector(item);
        const action = actionSelector(item);
        const selected = selectionSelector(item);

        if (isNil(content)) {
          return null;
        }

        return (
          <MuiListItem
            key={id || i}
            /* @ts-expect-error */
            button={isDefined(action)}
            disableGutters
            divider={divider}
            selected={selected}
            {...resolveAction(action)}
          >
            {content}
          </MuiListItem>
        );
      })}
    </MuiList>
  );
}
