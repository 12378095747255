import { makeStyles } from "@material-ui/core/styles";
import { Nullable } from "@sinch/types";
import clsx from "clsx";
import { equals } from "ramda";
import React, { ReactElement, useMemo, useState } from "react";
import { Optional } from "utility-types";
import { TabProps, TabsBase } from "../TabsBase";

const useStyles = makeStyles({
  selected: {},
  tab: {
    display: "none",

    "&$selected": {
      display: "initial",
    },
  },
});

interface TabsTabProps extends Optional<TabProps, "path"> {
  /**
   * Keep child components mounted even for not selected tab.
   */
  persist?: boolean;
}

interface TabsProps {
  /**
   * Keep all child components mounted even for not selected tabs.
   */
  persist?: boolean;

  tabs: TabsTabProps[];
}

/**
 * todo: consider embedding selected tab value into location state
 *  so tab state can be restored when navigating back...
 */
export function Tabs({
  persist: persistAll,
  tabs: tabsPartial,
}: TabsProps): ReactElement {
  const tabs = useMemo(
    () =>
      tabsPartial.map(({ path, ...tab }, i) => ({
        ...tab,
        path: path ?? i.toString(),
      })),
    [tabsPartial]
  );

  const [selected, setSelected] = useState(tabs[0].path);

  return (
    <TabsBase onSelect={setSelected} selected={selected} tabs={tabs}>
      {tabs.map(({ element, path, persist }) => (
        <TabContent
          key={path}
          element={element}
          persist={persistAll || persist}
          selected={equals(path, selected)}
        />
      ))}
    </TabsBase>
  );
}

interface TabContentProps {
  element: ReactElement;

  persist?: boolean;

  selected: boolean;
}

function TabContent({
  element,
  persist,
  selected,
}: TabContentProps): Nullable<ReactElement> {
  const styles = useStyles();

  if (persist || selected) {
    return (
      <div className={clsx(styles.tab, { [styles.selected]: selected })}>
        {element}
      </div>
    );
  }

  return null;
}
