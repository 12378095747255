import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import {
  Fk,
  LocationContainer,
  Position,
  PositionAttendanceContainer,
  PositionContainer,
  Profession,
  ProfessionContainer,
  ShiftContainer,
  WorkerContainer,
  WorkerRole,
} from "@sinch/entity";
import { GeoRadius } from "@sinch/types";

/**
 * todo: consider renaming DateString props to startTime and endTime
 *  to enable usage of common interfaces and utils (see ParseDates)
 *
 * todo: implement utils wrapping date-fns formatISO to allow
 *  serializing dates without time part
 */
interface Params {
  from?: Date;

  to?: Date;

  role?: WorkerRole[];

  profession?: Fk<Profession>[];

  /**
   * Setting this to `true` includes positions with failed rating requirement,
   * which are excluded by default.
   */
  ignoreRating?: boolean;

  /**
   * Setting this to `true` includes positions with conflicting attendances,
   * which are excluded by default.
   */
  ignoreConflict?: boolean;

  /**
   * Setting this to `true` includes positions with full attendance capacity,
   * which are excluded by default.
   */
  ignoreCapacity?: boolean;

  /**
   * Fulltext search input.
   *
   * todo: rename to searchQuery?
   */
  query?: string;

  /**
   * Geographic coordinates of search center point.
   *
   * todo: use radius instead of lat|lng|range
   */
  radius?: GeoRadius;

  /** @deprecated */
  lat?: number;

  /** @deprecated */
  lng?: number;

  /** @deprecated */
  range?: number;
}

export const requestPositionList = defineRequest("Positions/Index")
  .withParams<Params>()
  .withMeta<PagingParams>()
  .withResponse<{
    positionIds: Fk<Position>[];
    featuredPositionIds: Fk<Position>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<
    LocationContainer &
      PositionContainer &
      PositionAttendanceContainer &
      ProfessionContainer &
      ShiftContainer &
      WorkerContainer
  >()
  .withCreator((search: Params, paging: PagingParams) => ({
    params: search,
    meta: paging,
  }));
