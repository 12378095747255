import { Consumer, Nullable } from "@sinch/types";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import i18next from "i18next";
import React, { ReactElement, useMemo, useState } from "react";
import { FormatProvider } from "./format";

interface IntlSettings {
  currency: string;

  language: string;

  safeLanguage: string;

  locale: Nullable<string>;

  setCurrency: Consumer<string>;

  setLanguage: Consumer<string>;

  setLocale: Consumer<Nullable<string>>;

  setTimeZone: Consumer<string>;

  timeZone: string;
}

const IntlSettingsContext = createCheckedContext<IntlSettings>("IntlSettings");
export const { use: useIntl } = IntlSettingsContext;

/**
 * Provide Intl methods hooks to nested components
 */
export function IntlProvider({ children }: ChildrenProps): ReactElement {
  const [currency, setCurrency] = useState<string>("EUR");
  const [language, setLanguage] = useState<string>(i18next.language);
  const [locale, setLocale] = useState<Nullable<string>>("en-GB");
  const [timeZone, setTimeZone] = useState<string>("Europe/Prague");

  const changeLanguage = (lng: string) => i18next.changeLanguage(lng, () => setLanguage(lng));

  const langVariant = language.match(/^[a-zA-Z]{2}-[a-zA-Z]{2}$/gm) ? language : language.substring(0, 2);
  const context = useMemo(
    () => ({
      currency,
      language,
      safeLanguage: langVariant,
      locale,
      setCurrency,
      setLanguage: changeLanguage,
      setLocale,
      setTimeZone,
      timeZone,
    }),
    [currency, language, locale, timeZone]
  );

  return (
    <IntlSettingsContext value={context}>
      <FormatProvider currency={currency} language={langVariant} locale={locale ?? langVariant} timeZone={timeZone}>
        {children}
      </FormatProvider>
    </IntlSettingsContext>
  );
}
