import { Predicate } from "@sinch/types";
import { dayEnd, dayStart } from "@sinch/utils";
import { isAfter, isBefore, isWithinInterval } from "date-fns/fp";
import { either, filter, values } from "ramda";
import { isDate } from "ramda-adjunct";
import { createEntitySelector, EntityContainer, EntitySelector } from "../entity";
import { CalendarEntry } from "./CalendarEntry";
import { CalendarEntryContainer } from "./container";

export const selectCalendarEntry: EntitySelector<CalendarEntry> = createEntitySelector<
  CalendarEntry,
  CalendarEntryContainer
>("CalendarEntry");

/**
 * Select entries with duration intersecting given interval
 */
export function selectCalendarEntriesInInterval(
  start: Date | number,
  end: Date | number
): (container: EntityContainer<CalendarEntry, "CalendarEntry">) => CalendarEntry[] {
  const selectionEnd = dayEnd(end);
  const selectionStart = dayStart(start);

  const isInSelectedInterval = isWithinInterval({
    end: selectionEnd,
    start: selectionStart,
  });

  const selectionContainsEvent: Predicate<CalendarEntry> = ({ endTime = 0, startTime }) => {
    try {
      return isInSelectedInterval(startTime) || isInSelectedInterval(endTime);
    } catch (e) {
      if (e instanceof RangeError) {
        return false;
      }
      throw e;
    }
  };

  const eventContainsSelection: Predicate<CalendarEntry> = ({ endTime, startTime }) =>
    isDate(endTime) && isAfter(startTime, selectionStart) && isBefore(endTime, selectionEnd);

  const isSelected: Predicate<CalendarEntry> = either(selectionContainsEvent, eventContainsSelection);

  return ({ CalendarEntry: entries = {} }) => filter(isSelected, values(entries));
}
