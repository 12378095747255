import { useData } from "@sinch/core";
import { Fk, Position, selectPosition } from "@sinch/entity";
import { Text } from "@sinch/ui";
import React, { ReactElement } from "react";

export function PositionCapacity({ id }: { id: Fk<Position> }): ReactElement {
  // @ts-expect-error
  const { selectEntity } = useData();
  const { freeCapacity, totalCapacity } = selectEntity(selectPosition(id));

  return <Text bold>{`${Math.max(0, totalCapacity - freeCapacity)}/${totalCapacity}`}</Text>;
}
