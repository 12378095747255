import { mdiMessageProcessing } from "@mdi/js";
import { useCurrentUser, useData } from "@sinch/core";
import { Fk, Position, selectPosition, selectPositionAttendance, selectWorker, Shift } from "@sinch/entity";
import { Button } from "@sinch/ui";
import { ChildrenProps, sendSmsLink } from "@sinch/utils";
import { filter, includes, isEmpty } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { requestPositionDetail } from "../../Position/Detail/api";

export function SmsButton({
  shift,
  position,
  children,
}: { shift?: Fk<Shift>; position?: Fk<Position> } & ChildrenProps): ReactElement | null {
  const { selectEntity } = useData(requestPositionDetail);
  const { id } = useCurrentUser();

  const positionsInShift = position ? [position] : selectEntity(selectPosition({ shift }, "id"));
  const workerIds = selectEntity(selectPositionAttendance({}))
    .filter(({ position: shiftPosition }) => includes(shiftPosition, positionsInShift))
    .map(({ worker }) => worker)
    .filter((workerId) => workerId !== id);
  const phones = selectEntity(selectWorker(workerIds, "phone"));

  if (isEmpty(filter(isNotNilOrEmpty, phones))) {
    return null;
  }

  return (
    <Button
      action={() => {
        // @ts-ignore
        window.location.href = sendSmsLink(phones);
      }}
      color="info"
      icon={mdiMessageProcessing}
      stretch
      variant="text"
    >
      {children}
    </Button>
  );
}
