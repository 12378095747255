import MuiCollapse from "@material-ui/core/Collapse";
import { MenuNavigationItem } from "@sinch/core";
import React, { ReactElement } from "react";
import { NavigationItem } from "./NavigationItem";

interface SectionCollapseProps {
  items: MenuNavigationItem[];

  open: boolean;
}

export function SectionCollapse({
  open,
  items,
}: SectionCollapseProps): ReactElement {
  return (
    <MuiCollapse in={open}>
      {items.map((item) => (
        <NavigationItem key={item.id} item={item} nested />
      ))}
    </MuiCollapse>
  );
}
