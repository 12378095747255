import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import { Fk, PushNotificationsContainer, PushNotifications } from "@sinch/entity";

export const requestPushNotificationsList = defineRequest("PushNotifications/Index")
  .withEmptyParams()
  .withMeta<PagingParams>()
  .withResponse<{
    newsIds: Fk<PushNotifications>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<PushNotificationsContainer>()
  .withCreator((paging: PagingParams) => ({
    meta: paging,
  }));
