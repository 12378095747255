import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import MuiList from "@material-ui/core/List";
import {
  isDivider,
  isLinkItem,
  isPageItem,
  isSectionItem,
  MenuRouteItem,
  useCurrentUser,
  useInstanceSettings,
} from "@sinch/core";
import { Callback, Nullable } from "@sinch/types";
import { ChildrenProps } from "@sinch/utils";
import { isNonEmptyString } from "ramda-adjunct";
import React, { ReactElement, ReactNode } from "react";
import { useMobileLayout } from "../../UiProvider";
import { MenuContextProvider } from "./context";
import { MenuDivider } from "./Divider";
import { NavigationItem } from "./NavigationItem";
import { SectionItem } from "./SectionItem";
import { SideMenuDrawer } from "./SideMenuDrawer";
import { TopMenuDrawer } from "./TopMenuDrawer";

export function MenuDrawer({ children }: ChildrenProps): ReactElement {
  return useMobileLayout() ? <TopMenuDrawer>{children}</TopMenuDrawer> : <SideMenuDrawer>{children}</SideMenuDrawer>;
}

interface MainMenuProps {
  onClose: Callback;

  opened: boolean;

  bottomMenu?: ReactNode;

  staticMenu: MenuRouteItem[];
}

/**
 * Application main menu
 */
export function MainMenu({
  onClose,
  opened,
  bottomMenu,
  staticMenu: staticMenuList,
}: MainMenuProps): Nullable<ReactElement> {
  const user = useCurrentUser();
  const { menu } = useInstanceSettings();

  if (isNonEmptyString(user.restrictions.access)) {
    return null;
  }

  return (
    <MenuContextProvider onClose={onClose} opened={opened}>
      <MenuDrawer>
        <Box display="flex" height="100%">
          <MuiGrid container direction="column" justify="space-between">
            <MuiGrid item>
              <MuiList>
                {[...staticMenuList, ...menu].map((item, i) => {
                  if (isDivider(item)) {
                    /* eslint-disable-next-line react/no-array-index-key */
                    return <MenuDivider key={`divider-${i}`} />;
                  }

                  if (isSectionItem(item) && !isPageItem(item) && !isLinkItem(item)) {
                    return <SectionItem key={item.id} item={item} />;
                  }

                  return <NavigationItem key={item.id} item={item} nested={false} />;
                })}
              </MuiList>
            </MuiGrid>
            <MuiGrid item>{bottomMenu}</MuiGrid>
          </MuiGrid>
        </Box>
      </MenuDrawer>
    </MenuContextProvider>
  );
}
