import { isSameYear } from "date-fns";
import { includes } from "ramda";

export type DateFormatVariant = "chip" | "date" | "full" | "time" | "wday" | "short";

export type FormatDate = (value: Date) => string;

/**
 * todo: extend format functions to use `formatRange` when two dates given
 *  (not available in all browsers yet)
 *  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatRange
 */
export function dateTimeFormatter(
  locale: string,
  language: string,
  timeZone: string
): FormatDate & Record<DateFormatVariant, FormatDate> {
  /**
   * Return translated formatted date
   */
  const withTranslated = (formatter: Intl.DateTimeFormat) => (date: Date) => {
    const usedOptions = formatter.resolvedOptions();
    if (usedOptions.timeStyle || usedOptions.dateStyle) {
      // can't translate when timeStyle or dateStyle is set, because style is not specified
      return formatter.format(date);
    }
    return formatter
      .formatToParts()
      .map(({ type, value }) => {
        if (includes(type, ["dayPeriod", "weekday", "era", "month", "timeZoneName"])) {
          const translateFormatter = new Intl.DateTimeFormat(language, {
            [type]: usedOptions[type],
          });
          return translateFormatter.format(date);
        }
        return value;
      })
      .join("");
  };

  /**
   * Add year if is different from current year
   */
  const withExpandedYear = (formatter: Intl.DateTimeFormat) => (date: Date) => {
    if (!isSameYear(date, new Date())) {
      // @ts-ignore
      const withYearFormatter = new Intl.DateTimeFormat(locale, {
        ...formatter.resolvedOptions(),
        year: "numeric",
      });
      return withYearFormatter.format(date);
    }
    return formatter.format(date);
  };

  const chipFormatter = new Intl.DateTimeFormat(locale, {
    month: "numeric",
    day: "numeric",
    timeZone,
  });

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone,
  });

  const fullFormatter = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    timeZone,
  });

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "2-digit",
    timeZone,
  });

  const wdayFormatter = new Intl.DateTimeFormat(locale, {
    weekday: "short",
    timeZone,
  });

  return Object.assign((date: Date) => dateFormatter.format(date), {
    chip: withExpandedYear(chipFormatter),
    short: chipFormatter.format,
    date: dateFormatter.format,
    full: fullFormatter.format,
    time: timeFormatter.format,
    wday: withTranslated(wdayFormatter),
  });
}
