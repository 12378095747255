import { Divider } from "@material-ui/core";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import { useData } from "@sinch/core";
import { Fk, Position, selectPositionAttendance, selectTransportAttendance, Transport } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Action, Button } from "@sinch/ui";
import { filter, map } from "ramda";
import React, { ReactElement } from "react";
import { requestShiftAttendanceView } from "../api";
import { PositionHeader } from "../components";
import { WorkerTransportAttendanceContent } from "./WorkerTransportAttendanceContent";

interface TransportAttendancesProps {
  openDialogAction: Action;

  position: Fk<Position>;

  transport: Fk<Transport>;
}

export function TransportAttendances({
  openDialogAction,
  position,
  transport,
}: TransportAttendancesProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);

  const transportAttendances = selectEntity(selectTransportAttendance({ transport }));

  const positionIds = selectEntity(selectPositionAttendance({ position }, "position"));

  const attendances = filter(({ positionId }) => positionIds.includes(positionId), transportAttendances);

  return (
    <MuiList>
      {positionIds.length > 0 && (
        <MuiListItem>
          <PositionHeader position={position}>
            <Button action={openDialogAction} color="info" size="small" stretch variant="text">
              {t("Shift.closing.bulkEdit")}
            </Button>
          </PositionHeader>
        </MuiListItem>
      )}
      {map(
        ({ id }) => (
          <React.Fragment key={id}>
            <Divider component="li" variant="fullWidth" />
            <WorkerTransportAttendanceContent attendance={id} />
          </React.Fragment>
        ),
        attendances
      )}
    </MuiList>
  );
}
