import React, { ReactElement } from "react";
import { useFormField } from "../Form";
import { BaseInputProps } from "../Input";
import { InternalInput, InternalInputNumberProps } from "./InternalInput";

interface NumberInputProps extends Omit<InternalInputNumberProps, "type"> {}

export function NumberInput(props: NumberInputProps): ReactElement {
  const [baseProps] = useFormField<number, NumberInputProps>(props);

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <NumberInputBase {...baseProps} />;
}

export function NumberInputBase(
  props: BaseInputProps<number, NumberInputProps>
): ReactElement {
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <InternalInput type="number" {...props} />;
}
