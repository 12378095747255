import { BottomNavigation, BottomNavigationAction, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../Badge";
import { Icon } from "../../Icon";

const useStyles = makeStyles(({ mixins: { component, onLarge }, palette }) => ({
  swiper: {
    width: "100vw",
    // minHeight: "100vh",
    "& .swiper-wrapper": {
      height: `calc(100vh - ${component.titleBar.small.height}px - 56px)`,
      ...onLarge({
        height: `calc(100vh - ${component.titleBar.large.height}px - 56px)`,
      }),
    },
    "& .swiper-slide": {
      overflowY: "auto",
      height: `calc(100vh - ${component.titleBar.small.height}px - 56px)`,
      ...onLarge({
        height: `calc(100vh - ${component.titleBar.large.height}px - 56px)`,
      }),
    },
  },
  fullWidth: {
    width: "100vw",
  },
  badge: {
    bottom: "8px",
    right: "-8px",
  },
  borderTop: {
    borderTop: `1px solid ${palette.dividerDark.main}`,
  },
  bottomNavigationButton: {
    padding: "6px 4px 8px",
    minWidth: "65px",
  },
  bottomNavigationButtonLabel: {
    whiteSpace: "nowrap",
  },
}));

export function SwiperPagesNavigation({ childrenProps, selected, onChange }) {
  const classes = useStyles();
  const [menuVal, setMenuVal] = useState(selected);
  const navigate = useNavigate();

  useEffect(() => {
    onChange(menuVal);
    window.setTimeout(() => navigate(`${menuVal}`, { replace: true }), 1);
  }, [menuVal]);

  return (
    <BottomNavigation
      className={clsx(classes.fullWidth, classes.borderTop)}
      onChange={(event, newValue) => {
        setMenuVal(newValue);
        if (selected !== newValue) {
          onChange(newValue);
        }
      }}
      showLabels
      value={selected}
    >
      {childrenProps.map(({ badge, id, icon, label }) => (
        <BottomNavigationAction
          key={id}
          classes={{ root: classes.bottomNavigationButton, label: classes.bottomNavigationButtonLabel }}
          icon={
            badge ? (
              <Badge
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                badgeContent={badge.content}
                classes={{ badge: classes.badge }}
                color={badge.color}
                max={99}
              >
                <Icon icon={icon} />
              </Badge>
            ) : (
              <Icon icon={icon} />
            )
          }
          label={label as string}
          value={id}
        />
      ))}
    </BottomNavigation>
  );
}
