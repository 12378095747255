import { DataProvider, responseHandlerKey, useSession } from "@sinch/core";
import { t } from "@sinch/intl";
import { useSnackbar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router";
import { requestProfilePersonalUpdate } from "../api";
import { useProfileContext } from "../ProfileContext";
import { EditPersonalAttributes } from "./EditPersonalAttributes";

export function PersonalUpdateContainer(): ReactElement {
  const navigate = useNavigate();
  const { refresh } = useProfileContext();
  const { refresh: sessionRefresh } = useSession();
  const snackbar = useSnackbar();

  const responseHandler = useMemo(
    () =>
      responseHandlerKey(
        requestProfilePersonalUpdate,
        () => {
          snackbar("success", t("Profile.display.updatePersonalAttributesSuccessful"));
          navigate("/profile");
          refresh();
          sessionRefresh();
        },
        ({response}) => {
          const errors = response.messages.filter((msg) => msg.source === null);
          const stringErrors = errors.map((msg) => msg.text).join(" ");
          snackbar("error", t("Profile.display.updatePersonalAttributesError") + (stringErrors.length ? (` ${  stringErrors}`) : ''));
          refresh();
        }
      ),
    [navigate, refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <EditPersonalAttributes />
    </DataProvider>
  );
}
