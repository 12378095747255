import { defineRequest, PagingParams, PagingResult } from "@sinch/core";
import {
  Fk,
  LocationContainer,
  Position,
  PositionAttendanceContainer,
  PositionContainer,
  ProfessionContainer,
  ShiftContainer,
  WorkerContainer,
} from "@sinch/entity";

interface Params {
  searchQuery?: string;
}

export const requestPositionHistory = defineRequest("Positions/History")
  .withParams<Params>()
  .withMeta<PagingParams>()
  .withResponse<{
    positionIds: Fk<Position>[];
  }>()
  .withResponseMeta<PagingResult>()
  .withResponseEntity<
    LocationContainer &
      PositionContainer &
      PositionAttendanceContainer &
      ProfessionContainer &
      ShiftContainer &
      WorkerContainer
  >()
  .withCreator((search: Params, paging: PagingParams) => ({
    params: search,
    meta: paging,
  }));
