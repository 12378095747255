import { Box } from "@material-ui/core";
import { useData } from "@sinch/core";
import { PositionQ, selectPosition } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Color } from "@sinch/types";
import { Action, Button, routerLink, useMobileLayout, useSpacing } from "@sinch/ui";
import React, { ReactElement } from "react";
import { requestDashboardAttendanceList } from "./api";
import { PositionCardList, PositionCardListSelector } from "./PositionCardList";

const getLabel: PositionCardListSelector<string> = (query, position) =>
  query(PositionQ.Shift.hasFeedback(position)) ? t("Feedback.sent") : t("Feedback.pending");

const getColor: PositionCardListSelector<Color> = (query, position) =>
  /* @ts-expect-error - todo: what to do with "note" color? */
  query(PositionQ.Shift.hasFeedback(position)) ? "completed" : "note";

/*
 * todo: consider implementing separate HashRouteInteraction?
 *  - it can check that passed url is always absolute (since relative doesn't work here)
 */
const getAction: PositionCardListSelector<Action> = (query, position) => routerLink(`/position/${position}`);

const getTextColor: PositionCardListSelector<Color> = (query, position) =>
  query(PositionQ.Shift.hasFeedback(position)) ? "success" : "default";

const getButtons: PositionCardListSelector<ReactElement> = (query, position) => {
  const [, , data] = useSpacing();
  const mobile = useMobileLayout();

  const { shift } = query(selectPosition(position));

  return (
    <Box display="flex" justifyContent="flex-end" pt={data}>
      <Button
        action={routerLink(`#/shift/${shift}/feedback`)}
        color="info"
        size={mobile ? "small" : "medium"}
        stretch
        variant="outlined"
      >
        {t("Feedback.action.submit")}
      </Button>
    </Box>
  );
};

export function FeedbackPositions(): ReactElement {
  const { selectResult } = useData(requestDashboardAttendanceList);
  const { feedbackPositionIds } = selectResult();

  return (
    <PositionCardList
      actionSelector={getAction}
      buttonsSelector={getButtons}
      colorSelector={getColor}
      fields={["role", "date", "location"]}
      labelSelector={getLabel}
      positions={feedbackPositionIds}
      textColorSelector={getTextColor}
    />
  );
}
