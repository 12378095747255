import { assocPath } from "ramda";
import React, { ComponentProps, ReactElement, ReactNode } from "react";
import { Text } from "../../Text";
import { DataTableCell } from "../DataTableCell";
import {
  DataTableCellProps,
  DataTableDisplay,
  DataTableProps,
  DataTableRowProps,
} from "../types";

/* eslint-disable react/jsx-props-no-spreading */

/**
 * todo: needs review if we can wrap all content even if its non-textual
 */
const wrapText: (val: ReactNode) => ReactNode = (val) => <Text>{val}</Text>;

/*
const wrapText: (val: ReactNode) => ReactNode = when(isTextual, (val) => (
  <Text>{val}</Text>
));
 */

export function TypographyCell<
  TData,
  TColumn extends string,
  TDisplay extends DataTableDisplay<any>
>({
  table,
  row,
  column,
}: DataTableCellProps<TData, TColumn, TDisplay>): ReactElement {
  const { config, display } = table;
  const { Cell } = display;
  // todo: set defaults on container initialization
  const CellLogic = config[column].logic || DataTableCell;

  const DisplayCell = ({
    children,
    ...props
  }: ComponentProps<TDisplay["Row"]>) => (
    <Cell {...props}>{wrapText(children)}</Cell>
  );

  const updateDisplay: (
    props: DataTableProps<TData, TColumn, TDisplay>
  ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(
    ["display", "Cell"],
    DisplayCell
  );

  return <CellLogic column={column} row={row} table={updateDisplay(table)} />;
}

// todo: add parameter whether to use "isTextual" helper or wrap everything?
export function typographyLogic() {
  return function TypographyLogic<
    TData,
    TColumn extends string,
    TDisplay extends DataTableDisplay<any>
  >({ table, row }: DataTableRowProps<TData, TColumn, TDisplay>): ReactElement {
    const { display, logic } = table;
    const { Cell } = display;
    const RowLogic = logic;

    const DisplayCell = ({
      children,
      ...props
    }: ComponentProps<TDisplay["Cell"]>) => (
      <Cell {...props}>
        <Text>{children}</Text>
      </Cell>
    );

    const updateDisplay: (
      props: DataTableProps<TData, TColumn, TDisplay>
    ) => DataTableProps<TData, TColumn, TDisplay> = assocPath(
      ["display", "Cell"],
      DisplayCell
    );

    return <RowLogic row={row} table={updateDisplay(table)} />;
  };
}
