import { mdiCalendarArrowLeft, mdiCalendarArrowRight, mdiCalendarTextOutline } from "@mdi/js";
import { useData } from "@sinch/core";
import { selectCalendarEntry } from "@sinch/entity";
import { Format } from "@sinch/intl";
import { DataGrid } from "@sinch/ui";
import { isValue } from "@sinch/utils";
import { filter, map } from "ramda";
import React from "react";
import { requestCalendar } from "./api";

function getStartTime(query, entry) {
  const startTime = query(selectCalendarEntry(entry, "startTime"));

  return {
    icon: mdiCalendarArrowRight,
    content: (
      <>
        <Format dt={startTime} /> <Format dt={startTime} variant="time" />
      </>
    ),
  };
}

function getEndTime(query, entry) {
  const endTime = query(selectCalendarEntry(entry, "endTime"));

  return {
    icon: /* ICON */ mdiCalendarArrowLeft,
    content: (
      <>
        <Format dt={endTime} /> <Format dt={endTime} variant="time" />
      </>
    ),
  };
}

function getTitle(query, entry) {
  const title = query(selectCalendarEntry(entry, "title"));

  return (
    title && {
      icon: /* ICON */ mdiCalendarTextOutline,
      content: [title],
    }
  );
}

const cardContentSelectors = [getStartTime, getEndTime, getTitle];

export function AvailabilityDetailContent({ entry }) {
  const { queryEntity } = useData(requestCalendar);

  const resolve = map((selector) => selector(queryEntity, entry));

  const data = filter(isValue, resolve(cardContentSelectors));

  return (
    <>
      <DataGrid data={data} small />
    </>
  );
}
