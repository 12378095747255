import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@material-ui/lab/Autocomplete";
import { BiConsumer, Key, Nullable } from "@sinch/types";
import { identity } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { useFormField } from "../Form";
import { BaseInputProps, ManagedInputProps } from "../Input";

export interface AutocompleteInputProps<TOpt = Key> extends ManagedInputProps {
  getOptionLabel: (option: TOpt) => string;

  getOptionSelected: (option: TOpt, value: TOpt) => boolean;

  onInputChange: (value: string, reason: AutocompleteInputChangeReason) => void;

  options: TOpt[];

  renderOption: (option: TOpt) => ReactNode;

  freeSolo?: boolean;
}

export function AutocompleteInput<TOpt = Key>(
  props: AutocompleteInputProps<TOpt>
): ReactElement {
  const [baseProps, { setValue }] = useFormField<
    Nullable<TOpt>,
    AutocompleteInputProps<TOpt>
  >(props);

  return (
    <AutocompleteInputBase<TOpt>
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...baseProps}
      onChange={setValue}
    />
  );
}

export function AutocompleteInputBase<TOpt = Key>({
  dense,
  disabled,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  error,
  getOptionLabel,
  getOptionSelected,
  label,
  name,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  note,
  onChange,
  onInputChange,
  onInvalid,
  options,
  placeholder,
  renderOption,
  value,
  freeSolo,
  required = false,
}: BaseInputProps<
  Nullable<TOpt>,
  AutocompleteInputProps<TOpt>,
  BiConsumer<Nullable<TOpt>, AutocompleteChangeReason>
>): ReactElement {
  return (
    <Autocomplete<TOpt>
      clearOnBlur={false}
      clearOnEscape
      disabled={disabled}
      filterOptions={identity}
      // @ts-expect-error
      freeSolo={freeSolo}
      fullWidth
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={(e, val, reason) => onChange(val, reason)}
      onInputChange={(e, val, reason) => {
        onInputChange(val, reason);
      }}
      onInvalid={onInvalid}
      options={options}
      renderInput={(inputParams) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...inputParams}
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
          margin={dense ? "dense" : "normal"}
          name={name}
          placeholder={placeholder}
          required={required}
          variant="outlined"
        />
      )}
      renderOption={renderOption}
      required={required}
      value={value}
    />
  );
}
