import { Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { Fk, PositionAttendance, selectPosition, selectPositionAttendance } from "@sinch/entity";
import { Format } from "@sinch/intl";
import { TimeRange, useSpacing } from "@sinch/ui";
import { max } from "date-fns";
import React from "react";
import { requestPositionList } from "../../Position/List/api";

export function PositionAttendanceTime({ id }: { id: Fk<PositionAttendance> }) {
  const [, inner] = useSpacing();
  const { selectEntity } = useData(requestPositionList);
  const { startTime, endTime, position } = selectEntity(selectPositionAttendance(id));
  const { startTime: positionStartTime } = selectEntity(selectPosition(position));
  const realStartTime = max([positionStartTime, startTime]);
  return (
    <MuiGrid container spacing={inner}>
      <MuiGrid item xs={4}>
        <Format dt={startTime} />
      </MuiGrid>
      <MuiGrid item xs>
        <TimeRange endTime={endTime} showLength startTime={realStartTime} />
      </MuiGrid>
    </MuiGrid>
  );
}
