import { useData, useRequest, useSearchParamsCustom } from "@sinch/core";
import { CalendarEntryType, selectCalendarEntry } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Grid, InformationDialog, routerLink, Text, useDialog, useMobileLayout } from "@sinch/ui";
import { equals, isNil } from "ramda";
import React, { ReactElement } from "react";
import { requestCalendar, requestCalendarAvailabilityUnset } from "../api";
import { AvailabilityDetailContent } from "../AvailabilityDetailContent";

interface CalendarAvailabilityDetailDialogParams {
  id: number;
}

export function CalendarAvailabilityDetailDialog({ id }: CalendarAvailabilityDetailDialogParams): ReactElement | null {
  const { selectEntity } = useData(requestCalendar);
  const mobile = useMobileLayout();
  const dialog = useDialog(false);

  const dispatch = useRequest();

  const { searchParams } = useSearchParamsCustom<Record<string, string>>();

  const calendarEntry = selectEntity(selectCalendarEntry(id));
  if (isNil(calendarEntry)) {
    return null;
  }
  const { type: calendarType } = calendarEntry;
  const title = equals(CalendarEntryType.Unavailable, calendarType)
    ? t("Calendar.notAvailable")
    : t("Calendar.available");
  return (
    <>
      <InformationDialog
        actions={{
          confirm: {
            action: routerLink(
              {
                search: `?${new URLSearchParams(searchParams)}`,
                hash: `#/availability/${id}`,
              },
              { state: { id } }
            ),
            label: t("action.edit"),
          },
          cancel: {
            action: () => dialog.open(),
            label: t("action.delete"),
            color: "error",
          },
        }}
        denyFullscreen
        title={title}
        width="xs"
      >
        <AvailabilityDetailContent entry={id} />
      </InformationDialog>
      {dialog.state && (
        <InformationDialog
          actions={{
            confirm: {
              action: () => {
                dispatch(requestCalendarAvailabilityUnset([id]));
                dialog.close();
              },
              label: t("action.confirm"),
              color: "error",
            },
            cancel: {
              action: () => dialog.close(),
              label: t("action.cancel"),
              color: "primary",
            },
          }}
          denyFullscreen
          onClose={() => dialog.close()}
          width="xs"
        >
          <Grid spacing="inner" vertical>
            <Text bold>{t("Calendar.doYouReallyWantDeleteEntry")}</Text>
          </Grid>
        </InformationDialog>
      )}
    </>
  );
}
