import { t } from "@sinch/intl";
import { KeyOf, Maybe, OneOrMore, PartialRecord, Predicate } from "@sinch/types";
import { always, filter, ifElse, mapObjIndexed, reduceWhile } from "ramda";
import { isArray, isNonEmptyString, isNotNilOrEmpty, isUndefined } from "ramda-adjunct";
import { FormValues } from "./Values";

/**
 * Validate form values.
 * Return record of error messages for individual fields.
 */
export type FormValidator<TValues extends FormValues> = (
  values: TValues
) => PartialRecord<KeyOf<TValues>, Maybe<string>>;

/**
 * Validate field in context of form values.
 * Return error message for current field.
 */
type FormFieldValidator<TFieldValue, TFormValues extends FormValues> = (
  fieldValue: TFieldValue,
  formValues: TFormValues
) => string | false | null | undefined;

type FormFieldValidators<TFormValues extends FormValues> = {
  [Key in KeyOf<TFormValues>]?: OneOrMore<FormFieldValidator<TFormValues[Key], TFormValues>>;
};

function applyValidators<TFieldValue, TFormValues extends FormValues>(
  fieldValue: TFieldValue,
  formValues: TFormValues,
  validators: FormFieldValidator<TFieldValue, TFormValues>[]
) {
  return reduceWhile<FormFieldValidator<TFieldValue, TFormValues>, Maybe<string>>(
    isUndefined,
    (_, validate) => validate(fieldValue, formValues) || undefined,
    undefined,
    validators
  );
}

export const filterValues = filter(isNonEmptyString);

/**
 * todo: improving types not necessary - to be replaced by zod schema later
 */
export function formValidator<TValues extends FormValues>(
  fields: FormFieldValidators<TValues>
): FormValidator<TValues> {
  return (values) =>
    /* @ts-expect-error */
    filterValues(
      mapObjIndexed(
        (validate, key) =>
          isArray(validate)
            ? applyValidators(values[key] as never, values, validate)
            : validate?.(values[key] as never, values) || undefined,
        /* @ts-expect-error */
        fields
      )
    );
}

/**
 * Validate separate field by its value.
 * Return error message for current field.
 */
export type FieldValidator<TValue> = (value: TValue) => Maybe<string>;

export const validator = <TValue,>(condition: Predicate<TValue>, message: string): FieldValidator<TValue> =>
  ifElse(condition, always(undefined), always(message));

export function validateRequired(val: string | number) {
  return validator(isNotNilOrEmpty, t("Form.provideRequiredValue"))(val);
}
