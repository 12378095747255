import { useData } from "@sinch/core";
import { PositionQ, selectPosition, sNot, sOr } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Card, GridLayout, Header, Text } from "@sinch/ui";
import { max } from "ramda";
import React, { ReactElement } from "react";
import { requestPositionDetail } from "./api";

export function PositionDetailCapacity(): Nullable<ReactElement> {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { positionId } = selectResult();

  const { freeCapacity, totalCapacity } = selectEntity(selectPosition(positionId));

  if (queryEntity(sOr(sNot(PositionQ.isStatusOpen), PositionQ.isCancelled, PositionQ.User.isAssigned)(positionId)))
    return null;

  const capacity = (
    <span>
      <Header component="span">{max(0, totalCapacity - freeCapacity)}/</Header>
      {totalCapacity}
    </span>
  );

  return (
    <GridLayout.Item>
      <Card center>
        <Text>
          <Header level={2}>{t("Position.totalCapacity", { capacity })}</Header>
        </Text>
      </Card>
    </GridLayout.Item>
  );
}
