import { useCallback, useMemo } from "react";
import { indexFiles, requestWithFiles } from "../contract";
import { FileUpload, GetFile, useFileUpload } from "./FileUploadProvider";
import { FileId } from "./FileUploadReducer";
import { useData } from "./useData";

/**
 * Calling storage as function returns {@link FileInfo} directly.
 */
export interface FileStorage extends GetFile, FileUpload {}

function assignStorage(upload: FileUpload, get: GetFile): FileStorage {
  const storage = (key: FileId) => get(key);

  storage.get = get;
  storage.remove = upload.remove;
  storage.store = upload.store;
  storage.status = upload.status;

  return storage;
}

export function useFiles(): FileStorage {
  const upload = useFileUpload();

  const { selectMeta } = useData(requestWithFiles);
  const responseFiles = useMemo(() => selectMeta(indexFiles), [selectMeta]);

  const get = useCallback<GetFile>(
    (key) => {
      const fileInfo = responseFiles[key] ?? upload.get(key);
      if (fileInfo) return fileInfo;

      throw new Error(`Trying to access unregistered file with key: ${key}`);
    },
    [upload, responseFiles]
  );

  return useMemo<FileStorage>(() => assignStorage(upload, get), [upload, get]);
}
