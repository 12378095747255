import { Consumer } from "@sinch/types";
import { Text } from "@sinch/ui";
import { isDefined } from "@sinch/utils";
import { useField, useFormikContext } from "formik";
import { assocPath, path, split } from "ramda";
import { isFunction } from "ramda-adjunct";
import React, { InvalidEvent, SetStateAction, useCallback, useMemo } from "react";
import { BaseInputProps, ManagedInputProps } from "../Input";
import { FieldValidator, validateRequired } from "./validation";

interface FieldControl<TValue> {
  setError: Consumer<string>;

  setValue: Consumer<SetStateAction<TValue>>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

interface FieldConfig<TValue> {
  validate?: FieldValidator<TValue>;
}

function extendConfig<TValue>(required?: boolean, validate?: FieldValidator<TValue>): FieldConfig<TValue> {
  return {
    validate: required ? (val: TValue) => validateRequired(val) || validate?.(val) : validate,
  };
}

interface OptionalProps {
  /**
   * Prevent form submit when value not provided.
   *
   * todo: how to handle `required` in constrained value input types?
   *  (checkbox, date, switch, select,..)
   *  - collides with `nullable` prop
   */
  required?: boolean;
}

export function useFormField<TValue, TProps extends ManagedInputProps>(
  props: TProps & OptionalProps,
  config: FieldConfig<TValue> = {}
): [Required<BaseInputProps<TValue, TProps>>, FieldControl<TValue>] {
  const { name, note, required } = props;
  const { validate } = config;

  const { setValues: setFormValues, setFieldValue } = useFormikContext<unknown>();

  const fieldPath = useMemo(() => split(".", name), [name]);
  const setValue = useCallback(
    (setState: SetStateAction<TValue>) => {
      setFormValues((formValues: unknown) => {
        const nextValue = isFunction(setState) ? setState(path(fieldPath, formValues) as TValue) : setState;

        return assocPath(fieldPath, nextValue, formValues);
      });
    },
    [fieldPath, setFormValues]
  );

  const fieldConfig = useMemo(() => ({ name, ...extendConfig(required, validate) }), [name, required, validate]);

  const [{ onChange, value }, { error: errorText }, { setError }] = useField<TValue>(fieldConfig);

  const onInvalid = useCallback(
    (event: InvalidEvent<HTMLInputElement>) => {
      const {
        target: { validationMessage },
      } = event;
      setError(validationMessage as never);
      event.preventDefault();
    },
    [setError]
  );

  const error = isDefined(errorText);
  return [
    {
      ...props,
      error,
      note: error ? (
        <Text separator={<br />}>
          {errorText}
          {note}
        </Text>
      ) : (
        note
      ),
      onChange,
      onInvalid,
      value,
    },
    {
      setError: (setError as unknown) as Consumer<string>,
      setValue,
      setFieldValue,
    },
  ];
}
