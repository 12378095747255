import MuiAvatar from "@material-ui/core/Avatar";
import MuiChip from "@material-ui/core/Chip";
import MuiGrid from "@material-ui/core/Grid";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Color } from "@sinch/types";
import clsx from "clsx";
import React, { ReactChild, ReactElement } from "react";
import { createPaletteStyles } from "../paletteStyles";

/**
 * todo: extract hover helper to palette styles?
 */
const paletteStyles = createPaletteStyles(({ contrastText, main }, { theme }) => ({
  background: main,
  color: contrastText,
  "&:hover": {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    backgroundColor: fade(main, 1 - theme!.palette.action.hoverOpacity),
  },
}));

const useStyles = makeStyles((theme) => ({
  maxWidthViewport: {
    "& .MuiChip-label": {
      maxWidth: `calc(100vw - ${theme.spacing(3)}px)`,
      paddingRight: theme.spacing(1) / 2,
      paddingLeft: theme.spacing(2),
    },
  },
  avatarChip: {
    cursor: "pointer",
    height: "50px",
    borderRadius: "25px",
    background: "#AE5700",
    color: theme.palette.getContrastText("#AE5700"),
    "& .MuiAvatar-root": {
      width: "40px",
      height: "40px",
    },
  },
  ...paletteStyles(theme),
}));

export interface AvatarChipProps {
  color?: Color;

  label: ReactChild;

  avatarUrl?: string;
}

export function AvatarChip({ color, label, avatarUrl }: AvatarChipProps): ReactElement {
  const styles = useStyles();

  return (
    <MuiChip
      className={`${clsx(color && styles[color], styles.avatarChip)} ${styles.maxWidthViewport}`}
      clickable={false}
      // deleteIcon={<MuiAvatar src={avatarUrl} />}
      label={
        <MuiGrid alignItems="center" container spacing={1}>
          <MuiGrid item>{label}</MuiGrid>
          <MuiGrid item>
            <MuiAvatar src={avatarUrl} />
          </MuiGrid>
        </MuiGrid>
      }
      // onDelete={() => {}}
    />
  );
}
