import MuiGrid from "@material-ui/core/Grid";
import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { mdiChevronLeft, mdiChevronRight, mdiPlus } from "@mdi/js";

import { makeSearchQuery, useSearchParamsCustom } from "@sinch/core";
import { t, useIntl } from "@sinch/intl";
import { Button, Icon, useMobileLayout } from "@sinch/ui";
import { serializeDateTime } from "@sinch/utils";
import { endOfDay, startOfDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import React, { ReactElement } from "react";
import { ToolbarProps, View } from "react-big-calendar";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      background: "transparent",
    },
  },
  toolbar: {
    marginBottom: theme.spacing(1),
  },
  btnLeft: {
    paddingRight: 0,
  },
  btnRight: {
    paddingLeft: 0,
  },
}));

const CalendarRangeSlider = ({ onNavigate, label }: Pick<ToolbarProps, "onNavigate" | "label">): ReactElement => {
  const classes = useStyles();

  return (
    <MuiGrid alignItems="center" container>
      <MuiGrid item xs="auto">
        <MuiIconButton className={`${classes.root} ${classes.btnLeft}`} onClick={() => onNavigate("PREV")}>
          <Icon icon={mdiChevronLeft} />
        </MuiIconButton>
        <MuiIconButton className={`${classes.root} ${classes.btnRight}`} onClick={() => onNavigate("NEXT")}>
          <Icon data-cy="forwardArrowCalendar" icon={mdiChevronRight} />
        </MuiIconButton>
      </MuiGrid>
      <MuiGrid item>
        <Typography variant="h2">{label}</Typography>
      </MuiGrid>
    </MuiGrid>
  );
};

const viewTranslate = (view: string) => {
  switch (view) {
    case "month":
      return t("month");
    case "week":
      return t("week");
    case "day":
      return t("day");
    default:
      return view;
  }
};

export function CalendarToolbar({ views, view, onView, onNavigate, label }: ToolbarProps): ReactElement {
  const classes = useStyles();
  const mobile = useMobileLayout();
  const { timeZone } = useIntl();
  const { searchParams } = useSearchParamsCustom();
  const navigate = useNavigate();

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: View) => {
    if (newView !== null) {
      onView(newView);
    }
  };

  const handleAddCalendarEntry = () => {
    // @ts-ignore

    const dateRangeSearchQuery = makeSearchQuery({
      startTime: serializeDateTime(zonedTimeToUtc(startOfDay(new Date()), timeZone)),
      endTime: serializeDateTime(zonedTimeToUtc(endOfDay(new Date()), timeZone)),
    });

    navigate({
      search: makeSearchQuery(searchParams),
      hash: `#/availability/add${dateRangeSearchQuery}`,
    });
  };

  return (
    <>
      {mobile && (
        <MuiGrid alignItems="center" container>
          <MuiGrid item xs>
            <CalendarRangeSlider label={label} onNavigate={onNavigate} />
          </MuiGrid>
          <MuiGrid item xs="auto">
            <Button
              action={handleAddCalendarEntry}
              color="info"
              icon={mdiPlus}
              iconProps={{ "data-cy": "plusButton" }}
              stretch
              variant="contained"
            />
          </MuiGrid>
        </MuiGrid>
      )}
      <MuiGrid alignItems="baseline" className={classes.toolbar} container direction="row" justify="space-between">
        <MuiGrid item>
          <MuiGrid alignItems="center" container>
            <MuiGrid item>
              <Button action={() => onNavigate("TODAY")} large stretch variant="outlined">
                {t("today")}
              </Button>
            </MuiGrid>
            {!mobile && (
              <MuiGrid item>
                <CalendarRangeSlider label={label} onNavigate={onNavigate} />
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiGrid>

        <MuiGrid item>
          <ToggleButtonGroup aria-label="text alignment" exclusive onChange={handleViewChange} value={view}>
            {(views as string[]).map((viewName) => (
              <ToggleButton key={viewName} value={viewName}>
                {viewTranslate(viewName)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </MuiGrid>
      </MuiGrid>
    </>
  );
}
