import MuiCard from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import { IconId } from "@sinch/types";
import { ChildrenProps, useToggleState } from "@sinch/utils";
import React, { ReactChild, ReactElement } from "react";
import { Icon } from "../Icon";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  card: {
    alignSelf: "flex-end",
    position: "relative",
    top: theme.spacing(-1),
    paddingTop: theme.spacing(1),
    width: ({ identation }) => (identation ? "96%" : "100%"),
  },
  handle: {
    padding: theme.spacing(0.5, 1),
    display: "flex",
    justifyContent: "space-between",
    "& > :nth-child(2)": {
      flexGrow: 1,
      padding: theme.spacing(0, 1),
    },
  },
  label: {},
}));

export interface CardUnderlayProps extends ChildrenProps {
  card: ReactElement;

  header?: ReactChild;

  icon?: IconId;

  identation?: boolean;
}

export function CardUnderlay({ card, children, header, icon, identation = true }: CardUnderlayProps): ReactElement {
  const styles = useStyles({ identation });

  const [open, toggleOpen] = useToggleState(false);

  return (
    <div className={styles.container}>
      <MuiCard className={styles.card} elevation={0}>
        {header && (
          <MuiCardActionArea className={styles.handle} onClick={children ? toggleOpen : undefined}>
            {icon && <Icon icon={icon} />}
            <div className={styles.label}>{header}</div>
            {children && <Icon icon={open ? mdiChevronUp : mdiChevronDown} />}
          </MuiCardActionArea>
        )}
        {(!header || open) && <MuiCardContent>{children}</MuiCardContent>}
      </MuiCard>
      {card}
    </div>
  );
}
