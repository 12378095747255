import { makeStyles, Paper as MuiPaper } from "@material-ui/core";
import { ChildrenProps } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactPortal } from "react";
import { createPortal } from "react-dom";
import { ToolbarPosition, useToolbarPortal } from "./ToolbarProvider";

type CssPosition = "fixed" | "scrollable" | "sticky";

const useStyles = makeStyles(({ mixins: { component, onLarge }, zIndex }) => ({
  positionBottom: {},
  positionTop: {},
  scrollable: {},
  fixed: {},
  sticky: {},

  toolbar: {
    alignItems: "center",
    display: "flex",
    // height: component.toolBar.height,
    // padding: spacing(2),
    position: "sticky",
    zIndex: zIndex.appBar,

    ...onLarge({
      height: component.toolBar.height,
    }),

    "&$positionBottom": {
      bottom: 0,
    },

    "&$positionTop": {
      top: component.titleBar.small.height,

      ...onLarge({
        top: component.titleBar.large.height,
      }),
    },

    "&$scrollable": {
      position: "initial",
    },

    "&$fixed": {
      position: "fixed",
    },

    "&$sticky": {
      position: "sticky",
    },
  },
}));

interface ToolbarProps extends ChildrenProps {
  position: ToolbarPosition;

  behavior?: CssPosition;
  paper?: boolean;
  className?: string;
}

/**
 * todo: add top border elevation shade for bottom bar
 *  to better separate it visually from content
 */
export function Toolbar({ children, position, behavior, paper = true, className }: ToolbarProps): ReactPortal | null {
  const styles = useStyles();
  const element = useToolbarPortal(position);

  if (!element) {
    return null;
  }

  return createPortal(
    paper ? (
      <MuiPaper
        className={clsx(
          styles.toolbar,
          position === "top" ? styles.positionTop : styles.positionBottom,
          behavior && styles[behavior],
          className
        )}
        component="aside"
        square
      >
        {children}
      </MuiPaper>
    ) : (
      children
    ),
    element
  );
}
