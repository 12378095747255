import { Transform } from "@sinch/types";
import { parseDate, parseExactDate } from "@sinch/utils";
import { evolve, identity, map, mapObjIndexed } from "ramda";
import { availableMenuIcons } from "../../components";
import { RequestCreator, RequestOf, ResponseOf } from "../contract";

const interval = {
  endTime: parseDate,
  startTime: parseDate,
};

const entityParsers = {
  News: {
    publishedDate: parseDate,
  },

  PushNotification: {
    created: parseDate,
  },

  Appointment: {
    ...interval,
    attendanceTimeSlots: map(parseDate),
  },

  AppointmentAttendance: interval,

  CalendarEntry: interval,

  Contract: {
    startTime: parseExactDate,
    endTime: parseExactDate,
  },

  Notification: {
    createdDate: parseDate,
  },

  Payout: {
    accountingDate: parseExactDate,
    transactionDate: parseExactDate,
  },

  Penalty: interval,

  Position: {
    ...interval,
    workBreak: interval,
  },

  PositionAttendanceBreak: {
    beginning: parseDate,
    end: parseDate,
  },

  PositionAttendance: {
    ...interval,
    workBreak: interval,
  },

  Rating: {
    createdDate: parseDate,
  },

  RecentSearch: {
    from: parseDate,
    to: parseDate,
  },

  Reimbursement: {
    created: parseDate,
  },

  Shift: {
    closeTime: parseDate,
  },

  SuspendedPenalty: {
    deadline: parseDate,
  },

  Transport: interval,

  TransportAttendance: interval,

  WalletTransaction: {
    accountingDate: parseExactDate,
  },
  Menu: {
    icon: (icon: string) => availableMenuIcons[icon] || null, // Transform all dynamic menu icons names to their Base64 content
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */

const parseEntities: Transform<any> = evolve(
  mapObjIndexed((transform) => mapObjIndexed(evolve(transform)), entityParsers)
);

/**
 * todo: to be replaced by `zod` schema implementation later
 */
export function parseResponse<TCreator extends RequestCreator>(
  { resultParser }: RequestOf<TCreator>,
  response: ResponseOf<TCreator>
): ResponseOf<TCreator> {
  const { entities, result } = response;

  const parseResult: Transform<any> = resultParser ? evolve(resultParser) : identity;

  return {
    ...response,
    entities: parseEntities(entities),
    result: parseResult(result),
  };
}
