import { Grid as MuiGrid, InputAdornment } from "@material-ui/core";
import { mdiClockOutline } from "@mdi/js";
import { ClosingProgress, Fk, PositionAttendance } from "@sinch/entity";
import { SelectTimeInputBase } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Button, Card, Icon, useSpacing } from "@sinch/ui";
import React, { ReactElement, useState } from "react";
import { useClosingStatusProps, useUpdatePresence } from "../../hooks";

export function ArrivalEdit({ id, arrivalTime }: { id: Fk<PositionAttendance>; arrivalTime?: Date }): ReactElement {
  const { setArrival, setAbsent } = useUpdatePresence(id);
  const [, inner] = useSpacing();
  const { getProps } = useClosingStatusProps();
  const [time, setTime] = useState<Date>(arrivalTime || new Date());

  const setCurrentStartWork = () => {
    setArrival(time);
  };

  return (
    <Card>
      <MuiGrid container direction="column" spacing={inner}>
        <MuiGrid item>
          <SelectTimeInputBase
            adornment={
              <InputAdornment position="end">
                <Icon icon={mdiClockOutline} />
              </InputAdornment>
            }
            dense={false}
            fullWidth
            label={t("Shift.closing.arrival")}
            name="startTime"
            onChange={setTime}
            textAlign="center"
            value={time || new Date()}
          />
        </MuiGrid>
        <MuiGrid item>
          <Button
            action={setCurrentStartWork}
            {...getProps(ClosingProgress.Present, ["icon", "color"])}
            variant="contained"
          >
            {t<string>("Shift.closing.clockIn")}
          </Button>
        </MuiGrid>
        <MuiGrid item>
          <Button
            action={() => setAbsent()}
            {...getProps(ClosingProgress.Absent, ["icon", "color"])}
            variant="contained"
          >
            {t<string>("Shift.closing.noShow")}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </Card>
  );
}
