import { useData } from "@sinch/core";
import { Grid, GridLayout, Strip } from "@sinch/ui";
import { isArray, noop } from "@sinch/utils";
import { always, filter, flatten, isEmpty, map, sort } from "ramda";
import React, { Children } from "react";
import { useNavigate } from "react-router-dom";
import { requestPositionDetail } from "./api";
import { positionStatusDefinitionsNew } from "./status";

/**
 * todo: add props to status definitions
 *  - priority (low|normal|high) or numeric?
 *  - group = string key for grouping into collapsible statuses
 *  - key or id? string prefixed by app/plugin namespace
 *
 * todo: what parameters do we need to pass?
 *  - considering we are evaluating statuses for specific entity,
 *    we should be able to query all data simply via entity id
 *  - in some cases we also need currentUserId and possibly other
 *    variables from application context
 *  - are there any cases where other optional parameters are
 *    required for executing the query?
 *
 * todo: use currying to extract generic evaluator factory
 */
function useEntityStatus(statusDefinitions, entityId) {
  const { queryEntity, selectResult, selectEntity } = useData(requestPositionDetail);

  const filterActive = filter(
    ({ condition, resultConditions = always(true) }) =>
      queryEntity(condition(entityId)) && resultConditions(selectResult())
  );

  const selectResults = map(({ id: key, resultSelector, dataSelector = always(noop) }) => {
    const result = resultSelector(queryEntity(dataSelector(entityId)), { queryEntity, selectResult, selectEntity });
    return isArray(result)
      ? result.map((x, index) => ({ key: key + index, ...x }))
      : {
          key,
          ...result,
        };
  });

  return flatten(selectResults(filterActive(statusDefinitions)));
}

const sortByPriority = (a, b) => a.priority - b.priority;

export function PositionDetailStatus({ positionId }) {
  const statusResults = useEntityStatus(positionStatusDefinitionsNew, positionId);

  const navigate = useNavigate();

  // do not render if no conditions resolved
  if (isEmpty(statusResults)) return null;

  const sortedStatusResults = sort(sortByPriority, statusResults);

  /* eslint-disable react/no-array-index-key */
  return (
    <GridLayout.Item>
      <Grid spacing="data" vertical>
        {sortedStatusResults.map(({ icon, color, content, action, key, link }, i) => (
          <Strip
            key={`${key}${i}`}
            color={color}
            icon={icon}
            onClick={link ? () => navigate(link) : null}
            primaryAction={action}
          >
            {Children.toArray(content)}
          </Strip>
        ))}
      </Grid>
    </GridLayout.Item>
  );
}
