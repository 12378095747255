import { Box, Grid } from "@material-ui/core";
import {
  mdiAlert,
  mdiAlertCircle,
  mdiCalendarToday,
  mdiCancel,
  mdiCar,
  mdiCheck,
  mdiCheckboxBlankCircle,
  mdiClockOutline,
  mdiCrown,
  mdiDomain,
  mdiHumanGreetingVariant,
  mdiMapMarker,
  mdiTimerSandEmpty,
} from "@mdi/js"; /* eslint-disable react/jsx-props-no-spreading */
import { QueryEntity, ResponseOf, SelectEntity, useBusinessRules, useCurrentUser, useData } from "@sinch/core";
import {
  ContactType,
  Fk,
  Position,
  PositionAttendance,
  PositionQ,
  PositionStatus,
  selectAppointment,
  selectLocation,
  selectPosition,
  selectPositionAttendance,
  selectShift,
  selectTransport,
  selectUser,
  Transport,
  TransportDirection,
  WorkerRole,
} from "@sinch/entity";
import { Format, t, useFormat } from "@sinch/intl";
import { BiFunc, Color, IconId, Maybe } from "@sinch/types";
import {
  Action,
  Animate,
  Button,
  Card,
  DataGrid,
  DataGridItem,
  DataGridItemProps,
  Icon,
  Identifier,
  routerLink,
  Text,
  TimeRange,
  useMobileLayout,
  useSpacing,
  WrapWords,
} from "@sinch/ui";
import { isArray, rejectFalsy } from "@sinch/utils";
import { subMinutes } from "date-fns";
import {
  always,
  both,
  cond,
  find,
  flatten,
  has,
  head,
  ifElse,
  includes,
  isEmpty,
  pipe,
  prop,
  propEq,
  props,
  toUpper,
  values,
} from "ramda";
import React, { ReactChild, ReactElement, ReactNode } from "react";
import { StaffClockIn } from "../../../components";
import { AttendanceConfirmButtons } from "../../../components/AttendanceConfirmButtons";
import { PositionReference } from "../../../Position/Detail/PositionReference";
import { requestDashboardAttendanceList } from "../api";

export type PositionCardField =
  | "status"
  | "contact"
  | "date"
  | "location"
  | "time"
  | "transport"
  | "applicants"
  | "conflict";

export type PositionCardListSelector<T> = BiFunc<
  QueryEntity<ResponseOf<typeof requestDashboardAttendanceList>>,
  Fk<Position>,
  Maybe<T>
>;

type AttendanceListSelectEntity = SelectEntity<ResponseOf<typeof requestDashboardAttendanceList>>;

function getStatus(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  if (selectEntity(PositionQ.isStatusCrewbossClosed(position))) {
    return {
      icon: mdiCheck,
      color: "success",
      content: toUpper(t("Position.status.closed")),
    };
  }
  if (selectEntity(PositionQ.isStatusUnclosedLate(position))) {
    return {
      icon: mdiTimerSandEmpty,
      color: "error",
      content: toUpper(t("Position.status.closedLate")),
    };
  }
  return {
    icon: mdiAlertCircle,
    color: "warning",
    content: toUpper(t("Position.status.notClosed")),
  };
}

function getDate(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const { dt } = useFormat();
  const startTime = selectEntity(selectPosition(position, "startTime"));

  return {
    icon: mdiCalendarToday,
    content: (
      <span>
        {dt.wday(startTime)} {dt(startTime)}
      </span>
    ),
  };
}

function getTime(
  selectEntity: AttendanceListSelectEntity,
  position: Fk<Position>,
  transportThere: Transport | null,
  transportBack: Transport | null
): DataGridItemProps {
  const { startTime, endTime, meetingTimeInterval, status } = selectEntity(selectPosition(position));
  if (status < PositionStatus.Finished) {
    const timeData = [
      ...(transportThere?.startTime && transportThere.meetingTimeInterval
        ? [
            <Text>
              {t("Transport.meetup")}
              <Format dt={subMinutes(transportThere!.startTime, transportThere!.meetingTimeInterval)} variant="time" />
            </Text>,
          ]
        : []),
      ...(transportThere?.startTime && transportThere.endTime
        ? [
            <Text>
              {t("Transport.there")}
              <TimeRange endTime={transportThere!.endTime} startTime={transportThere!.startTime} />
            </Text>,
          ]
        : []),
      ...(meetingTimeInterval
        ? [
            <Text>
              {t("meeting")}
              <Format dt={subMinutes(startTime, meetingTimeInterval)} variant="time" />
            </Text>,
          ]
        : []),
      <Text>
        {t("work")}
        <TimeRange endTime={endTime} showLength startTime={startTime} />
      </Text>,
      ...(transportBack?.startTime && transportBack.endTime
        ? [
            <Text>
              {t("Transport.back")}
              <TimeRange endTime={transportBack!.endTime} startTime={transportBack!.startTime} />
            </Text>,
          ]
        : []),
    ];

    return {
      icon: mdiClockOutline,
      content: timeData,
    };
  }

  return {
    icon: mdiClockOutline,
    content: [
      <Text>
        {t("work")}
        <TimeRange endTime={endTime} showLength startTime={startTime} />
      </Text>,
    ],
  };
}

function getLocation(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const location = selectEntity(selectPosition(position, "location"));
  const { address } = selectEntity(selectLocation(location));

  return {
    icon: mdiMapMarker,
    content: [<WrapWords>{address}</WrapWords>],
  };
}

/**
 * todo: can Position have no contact?
 *  -> simplify code if there will always be at least one
 */
function getContact(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps {
  const contacts = selectEntity(selectPosition(position, "contacts"));

  const getDisplayContact = ifElse(
    has("userId"),
    pipe(
      // contact is User reference
      prop("userId"),
      /* @ts-expect-error */
      selectUser,
      selectEntity,
      props(["name", "phone"])
    ),
    // contact is just plain text
    props(["name", "phone"])
  );

  const hasCrewboss = find(propEq("type", ContactType.Crewboss));

  return {
    icon: hasCrewboss(contacts || []) ? mdiCrown : mdiDomain,
    content:
      isArray(contacts) && contacts.length > 0
        ? flatten(contacts.map((contact) => getDisplayContact(contact)))
        : t<string>("Position.noContact"),
  };
}

/**
 * todo: hide label completely when no transport?
 */
function getTransport(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps | false {
  const shift = selectEntity(selectPosition(position, "shift"));
  const transport = selectEntity(selectShift(shift, "transport"));

  if (!transport || (!transport.from && !transport.to)) return false;

  const label = cond([
    [both(has("to"), has("from")), always(t("Transport.thereAndBack"))], // Transport there and back
    [has("to"), always(t("Transport.there"))], // Transport there
    [has("from"), always(t("Transport.back"))], // Transport back
    // [T, always(t("Transport.noTransport"))],
  ]);

  return {
    icon: mdiCar,
    content: label(transport),
  };
}

function getApplicant(): DataGridItemProps | false {
  return {
    color: "colorApplicant",
    icon: mdiHumanGreetingVariant,
    content: t<string>("Position.signedAsApplicantNoticeTitle"),
  };
}

function getRole(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): DataGridItemProps | false {
  const role = selectEntity(selectPosition(position, "role"));
  const { crewbossName } = useBusinessRules();

  if (role === WorkerRole.Crewboss) {
    return {
      color: "colorCrewboss",
      icon: mdiCrown,
      content: t<string>("Position.youAreCrewboss", { crewboss: crewbossName }),
    };
  }
  return false;
}

function getConflict(selectEntity: AttendanceListSelectEntity, position: Fk<Position>) {
  const inConflict = selectEntity(selectPosition(position, "inConflict"));

  if (!inConflict) {
    return {};
  }
  const conflicting = selectEntity(selectPosition(position, "conflicting"));
  const { position: conflictedPositions, appointment: conflictedAppointments } = conflicting;
  const appointmentId = conflictedAppointments ? head(conflictedAppointments) : null;

  const hasConflictingPosition = conflictedPositions && !isEmpty(conflictedPositions);

  const conflictingItem = (
    <>
      {hasConflictingPosition && <PositionReference id={head(conflictedPositions)} name noId />}
      {!hasConflictingPosition && conflictedAppointments && !isEmpty(conflictedAppointments) && (
        <>
          <Identifier entity="appointment" id={appointmentId} />{" "}
          {selectEntity(selectAppointment(appointmentId, "name"))}
        </>
      )}
    </>
  );

  return {
    color: "warning",
    icon: mdiAlert,
    content: [
      <>
        {`${t("Position.display.conflictingJob")} `}
        {conflictingItem}
      </>,
    ],
  };
}

interface PositionCardContentProps {
  position: Fk<Position>;

  fields: PositionCardField[];
}

function PositionCardContent({ position, fields }: PositionCardContentProps): ReactElement {
  const { id: workerId } = useCurrentUser();
  const { selectEntity, selectResult, queryEntity } = useData(requestDashboardAttendanceList);
  const startTimeSelfClocked = queryEntity(PositionQ.User.getSelfClockedStartTime(position));
  const endTimeSelfClocked = queryEntity(PositionQ.User.getSelfClockedEndTime(position));
  const location = queryEntity(PositionQ.isGeolocationActive(position))
    ? queryEntity(PositionQ.Location.getLocation(position))
    : undefined;
  const transportIds = queryEntity(PositionQ.Shift.getTransports(position));

  const [, , data] = useSpacing();

  const mobile = useMobileLayout();

  const { applicantPositionIds, confirmationApplicantPositionIds } = selectResult();

  const { role, shift, clockInState, startTime } = selectEntity(selectPosition(position));
  const canOnSitePresence =
    role === WorkerRole.Crewboss &&
    !includes(position, applicantPositionIds) &&
    selectEntity(selectShift(shift, "presence"));

  let transportThere: Transport | null = null;
  let transportBack: Transport | null = null;

  transportIds.forEach((id) => {
    const transport = selectEntity(selectTransport(id));
    if (!transport) {
      return;
    }
    if (transport.direction === TransportDirection.There) {
      transportThere = transport;
    } else {
      transportBack = transport;
    }
  });

  const groupedPositionIds =
    head(
      selectEntity<PositionAttendance["groupedPositionIds"]>(
        selectPositionAttendance({ position, worker: workerId }, "groupedPositionIds")
      ) ?? []
    ) ?? [];
  const isApplicantConfirmation = includes(position, flatten(values(confirmationApplicantPositionIds)));

  const isConfirmationRequiredInGroupedPosition =
    values(confirmationApplicantPositionIds).some((ids) => ids.includes(position) && ids.length > 1) ||
    (includes(position, groupedPositionIds) && groupedPositionIds.length > 1);

  return (
    <>
      {selectEntity(PositionQ.isCancelled(position)) && (
        <Grid item>
          <DataGridItem color="error" content={t<string>("Position.thisPositionCancelled")} icon={mdiCancel} />
        </Grid>
      )}
      {fields.includes("conflict") && (
        <Box mb={mobile ? 0 : data}>
          <DataGrid data={[getConflict(selectEntity, position)]} small />
        </Box>
      )}
      <Grid container spacing={data}>
        <Grid container direction="column" item md={4} spacing={data} xs={12}>
          {fields.includes("status") && (
            <Grid item>
              <DataGridItem {...getStatus(selectEntity, position)} />
            </Grid>
          )}
          {isApplicantConfirmation && (
            <Grid item>
              <DataGridItem
                color="colorApplicant"
                content={t("Position.youHaveBeenSelectedAsApplicant")}
                icon={mdiHumanGreetingVariant}
              />
            </Grid>
          )}
          {!isApplicantConfirmation && fields.includes("applicants") && includes(position, applicantPositionIds) && (
            <Grid item>
              <DataGridItem {...getApplicant(selectEntity, position)} />
            </Grid>
          )}
          {mobile && !(fields.includes("applicants") && includes(position, applicantPositionIds)) && (
            <Grid item>
              <DataGridItem {...getRole(selectEntity, position)} />
            </Grid>
          )}
          {fields.includes("date") && (
            <Grid item>
              <DataGridItem {...getDate(selectEntity, position)} />
            </Grid>
          )}
          {fields.includes("time") && (
            <Grid item>
              <DataGridItem {...getTime(selectEntity, position, transportThere, transportBack)} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" item md={4} spacing={data} xs={12}>
          {fields.includes("location") && (
            <Grid item>
              <DataGridItem {...getLocation(selectEntity, position)} />
            </Grid>
          )}
          {fields.includes("contact") && (
            <Grid item>
              <DataGridItem {...getContact(selectEntity, position)} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" item md={4} spacing={data} xs={12}>
          {fields.includes("transport") && getTransport(selectEntity, position) && (
            <Grid item>
              <DataGridItem {...getTransport(selectEntity, position)} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <StaffClockIn
        clockInState={clockInState}
        endTimeClocked={endTimeSelfClocked}
        location={location}
        position={position}
        positionFinishedLabel
        positionStartTime={startTime}
        startTimeClocked={startTimeSelfClocked}
      />
      {canOnSitePresence && (
        <Box display="flex" justifyContent="flex-end" pt={data}>
          <Button
            action={routerLink(`/presence/${shift}`)}
            color="info"
            size={mobile ? "small" : "medium"}
            stretch
            variant="outlined"
          >
            {t("Shift.closing.recordAttendanceButton")}
          </Button>
        </Box>
      )}
      {(isApplicantConfirmation || queryEntity(PositionQ.User.isRequiredConfirmation(position))) && (
        <Box pt={1}>
          {isConfirmationRequiredInGroupedPosition ? (
            <Text>{t("Position.groupedPositionConfirmation")}</Text>
          ) : (
            <AttendanceConfirmButtons
              confirmationType={isApplicantConfirmation ? "applicant" : "position"}
              positionId={position}
            />
          )}
        </Box>
      )}
    </>
  );
}

function getTitle(selectEntity: AttendanceListSelectEntity, position: Fk<Position>): ReactNode {
  const { cancelled } = selectEntity(selectPosition(position));
  return (
    <Text strikethrough={cancelled}>
      <PositionReference id={position} name noId title />
    </Text>
  );
}

const getIcon = (isApplicant: boolean): PositionCardListSelector<IconId | ReactChild> => (query, position) => {
  if (isApplicant) {
    return <Icon color="colorApplicant" icon={mdiHumanGreetingVariant} />;
  }
  if (query(PositionQ.isStatusRunning(position)))
    return (
      <Animate animation="pulse">
        <Icon color="error" icon={mdiCheckboxBlankCircle} />
      </Animate>
    );
  if (query(PositionQ.isRoleCrewboss(position))) return <Icon color="colorCrewboss" icon={mdiCrown} />;
  return undefined;
};

const getLabel = (isApplicant: boolean): PositionCardListSelector<string | ReactChild> => (query, position) => {
  const { crewbossName } = useBusinessRules();
  if (isApplicant) {
    return <Text color="colorApplicant">{t("Position.applicant.title")}</Text>;
  }
  if (query(PositionQ.isStatusRunning(position))) return t<string>("Position.status.inProgress");
  if (query(PositionQ.isRoleCrewboss(position))) return crewbossName;
  return undefined;
};

// @ts-ignore
const getColor: PositionCardListSelector<Color> = () => "note";

const getAction: PositionCardListSelector<Action> = (query, position) => {
  if (query(PositionQ.isCancelled(position))) {
    return undefined;
  }
  return routerLink(`/position/${position}`);
};

const getHeaderColor: PositionCardListSelector<Color | undefined> = (query, position) => {
  const { queryEntity, selectResult } = useData(requestDashboardAttendanceList);
  const { confirmationApplicantPositionIds } = selectResult();
  if (
    queryEntity(PositionQ.User.isRequiredConfirmation(position)) ||
    includes(position, flatten(values(confirmationApplicantPositionIds)))
  ) {
    return "warning";
  }
  return undefined;
};

interface PositionCardProps {
  position: Fk<Position>;
  isApplicant: boolean;
}

export function PositionCard({ position, isApplicant }: PositionCardProps): ReactElement {
  const mobile = useMobileLayout();
  const { selectEntity } = useData(requestDashboardAttendanceList);

  const fields: PositionCardField[] = rejectFalsy([
    "conflict",
    "date",
    "location",
    "transport",
    "time",
    "contact",
    mobile && "applicants",
  ]);

  return (
    <Card
      action={getAction(selectEntity, position)}
      color={getColor(selectEntity, position)}
      headerColor={getHeaderColor(selectEntity, position)}
      icon={getIcon(isApplicant)(selectEntity, position)}
      label={getLabel(isApplicant)(selectEntity, position)}
      small={mobile}
      title={getTitle(selectEntity, position)}
    >
      <PositionCardContent fields={fields} position={position} />
    </Card>
  );
}
