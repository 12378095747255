import { useInstanceSettings } from "@sinch/core";
import { Callback } from "@sinch/types";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { Location } from "history";
import { append, assoc, equals, init, isNil, last, map, prop, propEq, reject, slice, without } from "ramda";
import React, { ReactElement, useCallback, useEffect, useMemo, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface TitleBarParams {
  /**
   * view container id for proper history caching
   */
  container: string;

  /**
   * text displayed on title bar
   */
  locationTitle?: string;

  /**
   * for confirmation button
   */
  submitHandler?: Callback;

  /**
   * for back button
   */
  backHandler?: Callback;

  /**
   * show back/cancel button?
   * (handler is created from location history)
   */
  showBack?: boolean;

  /**
   * render back or cancel icon?
   * protect navigation with window.confirm?
   */
  confirmExit?: boolean;

  /**
   * dialog container props are not rendered on toolbar
   * when in desktop layout, only for mobile
   */
  isDialog?: boolean;
}

const historySize = 4;

const rootContainer = "::root";

const emptyParams: TitleBarParams = { container: "" };

/**
 * todo: fix extraneous dependency on history
 */
interface TitleBarHistoryRecord {
  container: string;

  location: Location;
}

interface TitleBarReducerState {
  history: TitleBarHistoryRecord[];

  stack: TitleBarParams[];
}

interface TitleBarUpdateContextState {
  push: (params: TitleBarParams, location: Location) => void;

  remove: (params: TitleBarParams) => void;
}

const TitleBarUpdateContext = createCheckedContext<TitleBarUpdateContextState>("TitleBarUpdateContextState");

const TitleBarReadContext = createCheckedContext<TitleBarReducerState>("TitleBarReducerState");

export function useTitleBar(params: TitleBarParams, recoverState?: TitleBarParams | undefined): void {
  const location = useLocation();
  const { push, remove } = TitleBarUpdateContext.use();

  useEffect(() => {
    push(params, location);
    return () => {
      remove(recoverState || params);
    };
  }, [params, location, push, remove]);
}

type FilterParams = (stack: TitleBarParams[]) => TitleBarParams[];

const withoutDialogs = reject(propEq("isDialog", true)) as FilterParams;

const getTitle = prop("locationTitle") as (params: TitleBarParams) => string;
const assocTitle = assoc("locationTitle");

function lastParams(stack: TitleBarParams[]): TitleBarParams {
  return last(stack) || emptyParams;
}

function getLastTitle(stack: TitleBarParams[]): string {
  return getTitle(lastParams(stack)) || getLastTitle(init(stack));
}

function prevLocation(history: TitleBarHistoryRecord[]): Location {
  // history is never empty
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  return prop("location", last(init(history))!);
}

function resolveTitle(current: TitleBarParams, stack: TitleBarParams[]): TitleBarParams {
  return getTitle(current) ? current : assocTitle(getLastTitle(stack), current);
}

const assocBackHandler = assoc("backHandler");

/**
 * @deprecated temporary override of back button behaviour
 *  todo: implement proper navigation and remove
 */
const BROWSER_NAV_BACK = true;

export function useTitleBarParams(includeDialog = false): TitleBarParams & { backHandler?: Callback } {
  const navigate = useNavigate();
  const { stack, history } = TitleBarReadContext.use();
  const searchStack = includeDialog ? stack : withoutDialogs(stack);
  const current = lastParams(searchStack);

  const withTitle = resolveTitle(current, searchStack);

  if (withTitle.showBack && !withTitle.backHandler) {
    if (BROWSER_NAV_BACK) {
      return assocBackHandler(() => navigate(-1), withTitle);
    }
    const location = prevLocation(history);
    return assocBackHandler(() => navigate(location, location), withTitle);
  }

  return withTitle;
}

interface TitleBarReducerAction {
  type: "push" | "remove";

  params: TitleBarParams;

  location?: Location;
}

function lastContainer(history: TitleBarHistoryRecord[]): string {
  // history is never empty
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  return prop("container", last(history)!);
}

function stackReducer(stack: TitleBarParams[], { type, params }: TitleBarReducerAction) {
  switch (type) {
    case "push":
      return append(params, stack);
    case "remove":
      return without([params], stack);
    default:
      return stack;
  }
}

const isPush = equals("push");

const trimHistory = slice(-historySize, Infinity);

function shiftHistory(record: TitleBarHistoryRecord, history: TitleBarHistoryRecord[]): TitleBarHistoryRecord[] {
  return trimHistory(append(record, history));
}

function replaceHistory(record: TitleBarHistoryRecord, history: TitleBarHistoryRecord[]): TitleBarHistoryRecord[] {
  return append(record, init(history));
}

function historyReducer(
  history: TitleBarHistoryRecord[],
  { type, params: { container }, location }: TitleBarReducerAction
) {
  if (!isPush(type) || isNil(location)) return history;

  const updater = equals(container, lastContainer(history)) ? replaceHistory : shiftHistory;

  return updater({ container, location }, history);
}

function titleBarReducer(
  { stack, history }: TitleBarReducerState,
  action: TitleBarReducerAction
): TitleBarReducerState {
  return {
    stack: stackReducer(stack, action),
    history: historyReducer(history, action),
  };
}

function initialState(params: TitleBarParams): TitleBarReducerState {
  return {
    stack: [params],
    history: [
      {
        container: params.container,
        location: { pathname: "/" } as Location,
      },
    ],
  };
}

function baseParams(title: string): TitleBarParams {
  return {
    container: rootContainer,
    locationTitle: <></>,
  };
}

function formatHistoryRecord({ container, location: { pathname, hash } }: TitleBarHistoryRecord): string {
  return `${pathname} ${hash || ""} (${container})`;
}

function formatHistory(history: TitleBarHistoryRecord[]): string[] {
  return map(formatHistoryRecord, history);
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function logHistory({ history }: TitleBarReducerState): void {
  console.log("HISTORY", formatHistory(history));
}

/**
 * todo: make history logger configurable by env
 */
export function TitleBarProvider({ children }: ChildrenProps): ReactElement {
  const { name: instanceName } = useInstanceSettings();

  const [state, dispatch] = useReducer(titleBarReducer, initialState(baseParams(instanceName)));

  // logHistory(state);

  const push = useCallback(
    (params: TitleBarParams, location: Location) => dispatch({ params, location, type: "push" }),
    []
  );

  const remove = useCallback(
    (params: TitleBarParams) =>
      dispatch({
        params,
        type: "remove",
      }),
    []
  );

  const update = useMemo(() => ({ push, remove }), [push, remove]);

  return (
    <TitleBarUpdateContext value={update}>
      <TitleBarReadContext value={state}>{children}</TitleBarReadContext>
    </TitleBarUpdateContext>
  );
}
