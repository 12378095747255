import { isDefined, isValue } from "@sinch/utils";
import { subHours } from "date-fns";
import { gt, head, isEmpty, length } from "ramda";
import { selectAppointmentAttendance } from "../AppointmentAttendance";
import { selectAppointment } from "./selectors";

const isFull = (appointment) => (container) => {
  const { isFull: isAppointmentFull } = selectAppointment(appointment)(container);
  return isAppointmentFull;
};

const isAttending = (appointment) => (container, appContext) => {
  const { currentUser } = appContext;
  const attendances = selectAppointmentAttendance({
    worker: currentUser.id,
    appointment,
  })(container);
  return isDefined(head(attendances));
};

// todo: naming?
const isTimeSlot = (appointment) => (container) => {
  const { attendanceTimeSlots } = selectAppointment(appointment)(container);
  return !isEmpty(attendanceTimeSlots);
};

const getAttendanceStartTime = (appointment) => (container, appContext) => {
  const { currentUser } = appContext;
  const attendances = selectAppointmentAttendance({
    worker: currentUser.id,
    appointment,
  })(container);
  const { startTime } = head(attendances);
  return startTime;
};

const getSignOffTime = (appointment) => (container) => {
  const { startTime, signOffTimeInterval } = selectAppointment(appointment)(container);
  return subHours(startTime, signOffTimeInterval);
};

const getSignInTime = (appointment) => (container) => {
  const { startTime, signInTimeInterval } = selectAppointment(appointment)(container);
  return subHours(startTime, signInTimeInterval);
};

const hasConflicting = (appointmentId) => (container) => {
  const { conflicting } = selectAppointment(appointmentId)(container);
  if (!conflicting) return false;
  const { position, appointment } = conflicting;
  return (isValue(position) && gt(length(position), 0)) || (isValue(appointment) && gt(length(appointment), 0));
};

export const AppointmentQ = {
  isFull,
  isTimeSlot,
  getSignOffTime,
  getSignInTime,
  User: {
    isAttending,
    getAttendanceStartTime,
    hasConflicting,
  },
};
