import { useTitleBar } from "@sinch/ui";
import { ChildrenProps, isUndefined, toElement } from "@sinch/utils";
import { ReactElement } from "react";
import { useNavigate } from "react-router";

const rootId = "core:wallet/mobile";

interface MobileSectionContainerProps extends ChildrenProps {
  id: string;

  title: string;
}

export function MobileSectionContainer({ children, id, title }: MobileSectionContainerProps): ReactElement {
  const root = isUndefined(id);
  const navigate = useNavigate();

  useTitleBar({
    container: root ? rootId : `${rootId}/${id}`,
    locationTitle: title,
    showBack: !root,
    backHandler: () => navigate("/wallet"),
  });

  return toElement(children);
}
