import { useData } from "@sinch/core";
import { Fk, Position, PositionQ, selectSnippet } from "@sinch/entity";
import { Nullable } from "@sinch/types";
import { Grid, GridLayout } from "@sinch/ui";
import { isNonEmptyArray } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { getNoticesStrips } from "../../Dashboard/components";
import { requestPositionDetail } from "./api";

export function PositionDetailNotices({ positionId }: { positionId: Fk<Position> }): Nullable<ReactElement> {
  const { selectEntity, selectResult, queryEntity } = useData(requestPositionDetail);
  const { noticeIds } = selectResult();
  const requiredConfirmation = queryEntity(PositionQ.User.isRequiredConfirmation(positionId));

  if (isNonEmptyArray(noticeIds) && !requiredConfirmation) {
    return (
      <GridLayout.Item>
        <Grid spacing="inner" vertical>
          {getNoticesStrips(selectEntity(selectSnippet(noticeIds)))}
        </Grid>
      </GridLayout.Item>
    );
  }

  return null;
}
