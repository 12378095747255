import { Box, Divider, Grid as MuiGrid } from "@material-ui/core";
import { useData } from "@sinch/core";
import { ClosingProgress, Fk, PositionAttendance, selectWorker, Worker } from "@sinch/entity";
import { Card, Header, Icon, SwipeableCardList, useSpacing } from "@sinch/ui";
import { head, includes, isEmpty, pick, pluck } from "ramda";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { requestPresenceView } from "../../api";
import { PresenceStatusIcon } from "../../components";
import { useSelectionSet } from "../../context";
import { useAttendanceGrouping, useClosingStatusProps } from "../../hooks";
import { PresenceCardContent } from "../PresenceCardContent";
import { ShiftCardWrapper } from "../ShiftCardWrapper";
import { useLeadingAction } from "./LeadingSwipe";
import { useTrailingAction } from "./TrailingSwipe";

interface PresenceCardListProps {
  attendances: PositionAttendance[];
  presenceStatus: ClosingProgress;
}

export function PresenceCardList({ attendances, presenceStatus }: PresenceCardListProps): ReactElement {
  const navigate = useNavigate();
  const [outer, , data] = useSpacing();
  const { selected, toggleSelected } = useSelectionSet<Fk<PositionAttendance>>();
  const selectMode = !isEmpty(selected);

  const { getLeadingEvent, getLeadingElement } = useLeadingAction();
  const { getTrailingEvent, getTrailingElement } = useTrailingAction();

  const { getForAttendance, getLabel, getProps } = useClosingStatusProps();

  const { color, icon } = getProps(presenceStatus);

  const { attendancesByRole } = useAttendanceGrouping(attendances);

  const handleClick = ({ id }: PositionAttendance) => {
    if (selectMode) {
      toggleSelected(id);
    } else {
      navigate(`detail/${id}`);
    }
  };

  const handleLongTouch = ({ id }: PositionAttendance) => {
    toggleSelected(id);
  };

  return (
    <Card gutters="none" guttersVertical="none">
      <MuiGrid container direction="column" spacing={outer}>
        <MuiGrid item>
          <Box p={data}>
            <Header color={color} level={2}>
              <MuiGrid alignItems="center" container spacing={data}>
                <MuiGrid item xs="auto">
                  <Icon icon={icon} />
                </MuiGrid>
                <MuiGrid item xs="auto">
                  {getLabel(presenceStatus)}
                </MuiGrid>
              </MuiGrid>
            </Header>
          </Box>
          <Divider variant="fullWidth" />
        </MuiGrid>
        {attendancesByRole.map((items, i) => {
          const position = head(pluck("position", items));
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MuiGrid key={i} item>
              <ShiftCardWrapper
                attendancesIds={pluck("id", items)}
                // @ts-expect-error
                position={position}
              >
                <SwipeableCardList<PositionAttendance>
                  cardProps={(item) => ({
                    ...pick(["color"], getForAttendance(item)),
                    selected: includes(item.id, selected),
                    gutters: "small",
                  })}
                  content={(positionAttendance: PositionAttendance) => (
                    <PresenceCardContent
                      attendance={positionAttendance.id}
                      selected={includes(positionAttendance.id, selected)}
                      selectMode={selectMode}
                      subtitle={
                        <PresenceSubtitle attendanceId={positionAttendance.id} worker={positionAttendance.worker} />
                      }
                      worker={positionAttendance.worker}
                    />
                  )}
                  itemId={({ id }) => id}
                  items={items}
                  leadingAction={getLeadingElement}
                  leadingClick={getLeadingEvent}
                  onClick={handleClick}
                  onLongTouch={handleLongTouch}
                  trailingAction={getTrailingElement}
                  trailingClick={getTrailingEvent}
                />
              </ShiftCardWrapper>
            </MuiGrid>
          );
        })}
      </MuiGrid>
    </Card>
  );
}

function PresenceSubtitle({ attendanceId, worker }: { attendanceId: Fk<PositionAttendance>; worker: Fk<Worker> }) {
  const { selectEntity } = useData(requestPresenceView);

  const { phone } = selectEntity(selectWorker(worker));

  return (
    <MuiGrid container justify="space-between" wrap="nowrap">
      <MuiGrid item xs="auto">
        {phone ?? <PresenceStatusIcon id={attendanceId} />}
      </MuiGrid>
      <MuiGrid item xs="auto">
        <PresenceStatusIcon id={attendanceId} short />
      </MuiGrid>
    </MuiGrid>
  );
}
