import { t } from "@sinch/intl";
import { Box, Header } from "@sinch/ui";
import React, { ReactElement } from "react";

export function ContractEmptyBox(): ReactElement {
  return (
    <Box alignItems="center" display="flex" height="24em" justifyContent="center">
      <Header color="secondary">{t("Contract.emptyMessage")}</Header>
    </Box>
  );
}
