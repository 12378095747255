import { Checkbox, InputBase, ListItemText, MenuItem, Select, SelectProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IconId } from "@sinch/types";
import { includes } from "ramda";
import React, { ChangeEvent, ReactElement, useState } from "react";
import { Icon } from "../Icon";

const useStyles = makeStyles((theme) => ({
  itemText: {
    marginLeft: theme.spacing(0),
  },
}));

interface MenuMultiSelectProps<T> {
  options: { value: T; text: string; reversed?: boolean }[];
  icon: IconId;
  defaultValue: T[];
  onChange?: (value: T[]) => void;
  SelectDisplayProps: SelectProps["SelectDisplayProps"];
}

export function MenuMultiSelect<T extends string | number>({
  options,
  icon,
  defaultValue = [],
  onChange,
  SelectDisplayProps,
}: MenuMultiSelectProps<T>): ReactElement {
  const [values, setValues] = useState<T[]>(defaultValue);
  const classes = useStyles();
  return (
    <Select
      displayEmpty
      input={<InputBase />}
      MenuProps={{ variant: "menu" }}
      multiple
      // @ts-ignore
      onChange={(event: ChangeEvent<{ value: T[] }>) => {
        const currentValues: T[] = event.target.value;
        setValues(currentValues);
        if (onChange) {
          onChange(currentValues);
        }
      }}
      renderValue={() => <Icon icon={icon} />}
      SelectDisplayProps={SelectDisplayProps}
      value={values}
    >
      {options.map(({ value, text, reversed }) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={reversed ? !includes(value, values) : includes(value, values)} />
          <ListItemText className={classes.itemText} primary={text} />
        </MenuItem>
      ))}
    </Select>
  );
}
