import parse from "html-react-parser";
import { isNil, pipe } from "ramda";
import React, { ReactElement } from "react";
import { StructuredText } from "../Text";
import { parseHtml } from "./parseHtml";
import { sanitizeHtml } from "./sanitizeHtml";

interface HtmlContentProps {
  html: string;
  sanitize?: boolean;
}

const processHtml = pipe(sanitizeHtml, parseHtml);

/**
 * todo: handle case where top level node is not wrapped in p or other
 *  root element - we have to find all text nodes and wrap them in p
 *  containers in order to enable formatting and prevent exceptions
 *  (see ProblemCases in stories)
 *
 * todo: consider updating divider color in theme so its better visible
 *
 * todo: how to handle div elements?
 *  strip them in sanitize phase or render them as is?
 *  (currently they are stripped)
 *
 * todo: consider using `domPurify` instead of `sanitize-html`
 *  (significantly smaller bundle size and a bit faster)
 */
export function HtmlContent({ html, sanitize = false }: HtmlContentProps): ReactElement | null {
  return isNil(html) ? null : <StructuredText>{sanitize ? processHtml(html) : parse(html)}</StructuredText>;
}
