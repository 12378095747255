import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiMenuList from "@material-ui/core/MenuList";
import MuiPopper from "@material-ui/core/Popper";
import { PopperPlacementType } from "@material-ui/core/Popper/Popper";
import { makeStyles } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Callback, Consumer } from "@sinch/types";
import { ChildrenProps, createCheckedContext, useToggleState } from "@sinch/utils";
import clsx from "clsx";
import React, { ReactElement, useCallback, useMemo, useRef } from "react";
import { Paper } from "../Paper";

/**
 * todo: review width, ideally get value from theme
 *
 * todo: fix problem with top margin, anchor element must have fixed
 *  dimensions, because margin is calculated from the border
 *  - in our case one anchor is icon and other is avatar which is larger
 */
const useStyles = makeStyles((theme) => ({
  popperMenu: {
    marginTop: theme.spacing(1),
    // width: "360px",
    maxHeight: `calc(100vh - ${theme.spacing(3)}px)`,
    overflow: "auto",
  },
  handleWrapper: {
    display: "inline",
  },
  pushOnTop: {
    zIndex: 1500,
  },
  popperMaxWidth: ({ maxWidth }: { maxWidth?: Breakpoint }) =>
    maxWidth ? { maxWidth: maxWidth === "xs" ? "380px" : `${theme.breakpoints.values[maxWidth]}px` } : {},
}));

interface PopperMenuContextParams {
  close: Callback;
}

const PopperMenuContext = createCheckedContext<PopperMenuContextParams>("PopperMenuContext");
export const { use: usePopperMenuContext } = PopperMenuContext;

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

interface PopperMenuInterface extends ChildrenProps {
  disablePortal?: boolean;
  maxWidth?: Breakpoint;
  placement?: PopperPlacementType;
  toggleControl?: [boolean, Callback, Consumer<boolean>];
}

/**
 * todo: add more params if reused for MainMenu items
 *  (different placement and styles)
 *
 * todo: fix positioning bug when menu list is too long
 *  (gets shifted to the top, possibly covering the handle)
 */
export function PopperMenu({
  children,
  maxWidth,
  disablePortal = true,
  placement = "bottom-end",
  toggleControl,
}: PopperMenuInterface): ReactElement {
  const styles = useStyles({ maxWidth });

  const anchorRef = useRef(null);
  let [open, toggleOpen, setOpen] = useToggleState();
  if (toggleControl) {
    [open, toggleOpen, setOpen] = toggleControl;
  }
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const context = useMemo(() => ({ close: handleClose }), [handleClose]);

  const [handle, ...menuItems] = React.Children.toArray(children);

  return (
    <>
      <div
        ref={anchorRef}
        className={styles.handleWrapper}
        onClick={(e) => {
          e.stopPropagation();
          toggleOpen();
        }}
      >
        {handle}
      </div>
      <MuiPopper
        anchorEl={anchorRef.current}
        className={clsx(styles.pushOnTop, styles.popperMaxWidth)}
        disablePortal={disablePortal}
        open={open}
        placement={placement}
      >
        <MuiClickAwayListener onClickAway={handleClose}>
          <Paper className={styles.popperMenu}>
            <MuiMenuList autoFocusItem={open} variant="menu">
              <PopperMenuContext value={context}>{menuItems}</PopperMenuContext>
            </MuiMenuList>
          </Paper>
        </MuiClickAwayListener>
      </MuiPopper>
    </>
  );
}
