import { Entity, Fk } from "../entity";
import { Position } from "../Position";

export enum RatingType {
  Attendance = 0,

  Admin = 1,

  FriendBanList = 2,

  Evaluation = 3,

  Late = 4,

  Absent = 5,

  Duty = 6,

  Excused = 7,
}

export interface Rating extends Entity {
  createdDate: Date;

  description?: string;

  position?: Fk<Position>;

  rating: number;

  type: RatingType;
}
