import { FileHash, Maybe } from "@sinch/types";
import { indexByProp } from "@sinch/utils";
import { Dictionary, find, propEq } from "ramda";
import { defineRequest } from "./define";
import { ResponseData } from "./ResponseData";

export interface FileInfo {
  /**
   * Identifier for assigning uploaded file to entity data.
   */
  hash: FileHash;

  /**
   * Local file name if uploading or backend file name if already stored.
   */
  name: string;

  /**
   * Size in bytes.
   */
  size: number;

  /**
   * MIME type.
   */
  type: string;

  /**
   * URL for accessing file stored on the backend.
   */
  url: string;

  variants: Record<
    string,
    {
      hash: FileHash;
      type: string;
      url: string;
    }
  >;
}

/**
 * Response meta containing file info.
 *
 * todo: consider sending from backend already as object map
 */
export interface FilesMeta {
  files: FileInfo[];
}

/**
 * @deprecated temporary workaround for proper type inference
 * todo: remove when `useData` accepts mixin-like types
 */
export const requestWithFiles = defineRequest("")
  .withEmptyParams()
  .withEmptyResponse()
  .withResponseMeta<FilesMeta>()
  .withDefaultCreator();

const indexByHash = indexByProp("hash");

export function indexFiles({ files = [] }: FilesMeta): Dictionary<FileInfo> {
  return indexByHash(files);
}

/**
 * @deprecated todo: replace with `useFiles`
 */
export function selectFileInfo(
  hash?: FileHash
): <TResponse extends ResponseData<never, FilesMeta, never>>(meta: TResponse["meta"]) => Maybe<FileInfo> {
  return ({ files }) => find(propEq("hash", hash), files);
}
