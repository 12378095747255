import { State, To } from "history";
import { Link as RouterLinkComponent } from "react-router-dom";

/**
 * todo: rename to RouterNavigation
 *
 * todo: extract to router package to cleanup dependencies (history)?
 */
export interface RouterLink {
  replace?: boolean;

  state?: State;

  to: To;
}

/* eslint-disable-next-line @typescript-eslint/ban-types */
export function isRouterLink(action: object): action is RouterLink {
  return "to" in action;
}

/**
 * todo: extend second param to support replace
 *
 * todo: consider if there is any added value, might be passed directly as
 *  object param when all components are converted to typescript
 */
export function routerLink(
  to: To,
  { replace = false, state = {} }: { replace?: boolean; state?: State } = {}
): RouterLink {
  return {
    replace,
    state,
    to,
  };
}

/* eslint-disable-next-line @typescript-eslint/ban-types */
export function routerLinkProps({ replace, state, to }: RouterLink): object {
  return {
    component: RouterLinkComponent,
    replace,
    state,
    to,
  };
}
