/**
 * {@link Worker} attendance status at given {@link Position position}.
 */
export enum PositionAttendanceStatus {
  /**
   * {@link Position} has not started yet.
   *
   * todo: has not started or is not closed?
   *  review with backend
   */
  Pending = 0,

  /**
   * {@link Worker} has been present.
   */
  Present = 1,

  /**
   * {@link Worker} has arrived late and is penalized.
   */
  Late = 2,

  /**
   * {@link Worker} has been absent and is penalized.
   */
  Absent = 3,

  /**
   * {@link Worker} has been excused of being absent or arriving late.
   */
  Excused = 4,
}
