/* eslint-disable-next-line import/no-extraneous-dependencies,import/no-internal-modules */
import { Box } from "@material-ui/core";
import MuiGrid from "@material-ui/core/Grid";
import { useBusinessRules, useData, useInstanceSettings } from "@sinch/core";
import {
  Fk,
  selectTransportAttendance,
  selectWorker,
  TransportAttendance,
  TransportAttendanceRole,
} from "@sinch/entity";
import { NumberInput, SelectDateTimeInput, SelectInput, useFormValues } from "@sinch/forms";
import { t } from "@sinch/intl";
import { Avatar, BorderColorListItem, Text } from "@sinch/ui";
import { equals, values } from "ramda";
import React, { ReactElement } from "react";
import { AttendanceClosingFormState, requestShiftAttendanceView } from "../api";
import { TransportRoleOptions } from "../options";

interface WorkerTransportAttendanceProps {
  attendance: Fk<TransportAttendance>;
}

export function WorkerTransportAttendanceContent({ attendance }: WorkerTransportAttendanceProps): ReactElement {
  const { selectEntity } = useData(requestShiftAttendanceView);
  const { distanceUnit } = useInstanceSettings();
  const { shiftClosingCloseDistance } = useBusinessRules();

  const { worker } = selectEntity(selectTransportAttendance(attendance));

  const { avatar, name } = selectEntity(selectWorker(worker));

  const {
    transportAttendance: {
      [attendance]: { role },
    },
  } = useFormValues<AttendanceClosingFormState>();

  const hasRole = !equals(TransportAttendanceRole.NoTransport, role);

  return (
    <BorderColorListItem color={TransportRoleOptions()[role].color}>
      <Box width="100%">
        <MuiGrid container direction="column">
          <MuiGrid alignItems="center" container spacing={1}>
            <MuiGrid item xs="auto">
              <Avatar file={{ file: avatar as string, variant: "thumbnail_200x200" }} withPreview="thumbnail_500x500" />
            </MuiGrid>
            <MuiGrid item>
              <Text>{name}</Text>
            </MuiGrid>
          </MuiGrid>
          {hasRole && (
            <MuiGrid container item spacing={1} xs>
              <MuiGrid item xs>
                <SelectDateTimeInput dense name={`transportAttendance.${attendance}.startTime`} />
              </MuiGrid>
              <MuiGrid item xs>
                <SelectDateTimeInput dense name={`transportAttendance.${attendance}.endTime`} />
              </MuiGrid>
            </MuiGrid>
          )}
          <MuiGrid container item spacing={1} xs>
            {hasRole && shiftClosingCloseDistance && (
              <MuiGrid item xs>
                <NumberInput
                  dense
                  label={t("Transport.distance")}
                  name={`transportAttendance.${attendance}.distance`}
                  suffix={distanceUnit}
                />
              </MuiGrid>
            )}
            <MuiGrid item xs>
              <SelectInput
                dense
                name={`transportAttendance.${attendance}.role`}
                options={values(TransportRoleOptions())}
              />
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </Box>
    </BorderColorListItem>
  );
}
