/**
 * Describes steps in {@link Position position} lifecycle from creating and
 * publishing to finishing and closing.
 */
export enum PositionStatus {
  /**
   * {@link Position} is registered in the system, but not yet published and
   * visible to {@link Worker workers}. Positions with this status shouldn't
   * be sent to the client application at all.
   */
  Pending = 0,

  /**
   * {@link Position} is open for {@link Worker workers} to sign for.
   */
  Open = 2,

  /**
   * {@link Position} is happening right now.
   */
  Running = 3,

  /**
   * {@link Position} has already ended, but it's not yet resolved as complete.
   */
  Finished = 4,

  /**
   * {@link Position} is resolved as complete by {@link Shift shift} crewboss.
   */
  CrewbossClosed = 5,

  /**
   * {@link Position} is resolved as complete by {@link Shift shift} supervisor.
   */
  SupervisorClosed = 6,
}
