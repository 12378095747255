import { RefreshContext, useRefresh } from "@sinch/core";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import React, { ReactElement } from "react";

const WalletContext = createCheckedContext<RefreshContext>("WalletContext");
export const { use: useWalletContext } = WalletContext;

export function WalletContextProvider({
  children,
}: ChildrenProps): ReactElement {
  const refreshContext = useRefresh();

  return <WalletContext value={refreshContext}>{children}</WalletContext>;
}
