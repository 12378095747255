import { mdiCancel } from "@mdi/js";
import { Box as MuiBox } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

import { t } from "@sinch/intl";
import { Center, Icon, resolveAction, routerLink, Text } from "@sinch/ui";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  notFilledBox: {
    color: theme.palette.grey[500],
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.action.disabledBackground,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export type NotFilledInfoBoxParams = {
  edit: string;
  label: string;
};

/**
 * Grey box as indication that section is not filled
 */
export function NotFilledInfoBox({ edit, label }: NotFilledInfoBoxParams): ReactElement {
  const styles = useStyles();
  const actionProps = resolveAction(
    routerLink({
      pathname: `/profile/edit/${edit}`,
      hash: `#${label}`,
    })
  );

  return (
    <MuiLink {...actionProps}>
      <MuiBox
        className={styles.notFilledBox}
        /* eslint-disable react/jsx-props-no-spreading */
      >
        <Center>
          <Icon icon={mdiCancel} />
          <MuiBox ml={1}>
            <Text>{t("Profile.display.notFilledOut")}</Text>
          </MuiBox>
        </Center>
      </MuiBox>
    </MuiLink>
  );
}
