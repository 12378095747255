import { defineRequest, IdParams } from "@sinch/core";
import { Fk, PayoutMethod, Position } from "@sinch/entity";

export const requestPositionAttendanceJoin = defineRequest("Positions/Join/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Position> & { payoutMethod: Fk<PayoutMethod> }>()
  .withEmptyResponse()
  .withCreator((id: Fk<Position>, payoutMethod) => ({
    params: { id, payoutMethod },
  }));

export const requestPositionAttendanceLeave = defineRequest("Positions/Leave/:id")
  .withUriParams({ id: ["params", "id"] })
  .withParams<IdParams<Position>>()
  .withEmptyResponse()
  .withCreator((id: Fk<Position>) => ({
    params: { id },
  }));
