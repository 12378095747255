import { useData, useIntParams } from "@sinch/core";
import { selectPositionAttendance } from "@sinch/entity";
import { t } from "@sinch/intl";
import { useTitleBar } from "@sinch/ui";
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { requestPresenceView } from "./api";
import { PresenceDetailWorker } from "./PresenceDetailWorker";

export function PresenceDetailWorkerContainer(): ReactElement | null {
  const { selectEntity } = useData(requestPresenceView);

  const { id, shift } = useIntParams();
  useTitleBar({
    container: "core:presence-detail",
    locationTitle: t("Shift.closing.closingDetail"),
    showBack: true,
  });

  return !selectEntity(selectPositionAttendance(id, "id")) ? (
    <Navigate replace to={`/presence/${shift}`} />
  ) : (
    <PresenceDetailWorker id={id} />
  );
}
