import { defineRequest } from "@sinch/core";
import { Fk, Profession, ProfileAttributeContainer } from "@sinch/entity";
import { parseExactDate } from "@sinch/utils";
import { isNil, map } from "ramda";
import { BankAccountFormat } from "./BankAccount";
import { ProfileAttributeValue } from "./ProfileAttributeList";

export enum Sex {
  Male,
  Female,
  NotSpecified,
}

export interface PersonalAttributeValue {
  id: Fk<ProfileAttributeValue>;

  value: unknown;
}

export interface Address {
  street: string;

  city: string;

  region: string;

  zip: string;

  country: string;
}

export interface ProfilePersonalResult {
  bankAccountFormat?: string;

  bankAccountSegments?: string[];

  bankAccountNumber?: string;

  bankAccountHolderName?: string;

  birthDate?: Date | null;

  /**
   * todo: define enum for country codes
   *  - render as autocomplete select
   */
  citizenship?: string;

  correspondenceAddress?: Address;

  email: string;

  firstName: string;

  lastName: string;

  permanentAddress?: Address;

  permanentAddressPreferred?: boolean;

  localAddressPreferred?: boolean;

  preferredPlaces?: string[];

  isPermanentResident?: boolean;

  permanentResident?: {
    from: Date | null;
    to: Date | null;
  };

  personalAttributes: ProfileAttributeValue[];

  phone?: string;

  phoneVerified?: boolean;

  sex?: Sex;

  professions: {
    id: Fk<Profession>;
    value: boolean;
  }[];

  systemAttributes: {
    name: string;
    value: number | string;
    mandatory: boolean;
  }[];
}

export interface ProfilePersonalMeta {
  /**
   * Bank account format options for Profile edit view.
   */
  bankAccountFormats: BankAccountFormat[];
  countriesWithRegions: string[];
}

export const requestProfilePersonal = defineRequest("Profiles/Personal")
  .withEmptyParams()
  .withResponse<ProfilePersonalResult>({
    birthDate: parseExactDate,
    personalAttributes: map(({ expiration, ...item }) => {
      if (isNil(expiration)) {
        return item;
      }
      return { ...item, expiration: parseExactDate(expiration) };
    }),
  })
  .withResponseMeta<ProfilePersonalMeta>()
  .withResponseEntity<ProfileAttributeContainer>()
  .withDefaultCreator();
