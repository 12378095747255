import { mdiAlertOutline, mdiCheckboxMarkedCircleOutline, mdiProgressUpload, mdiUpdate } from "@mdi/js";

import { t } from "@sinch/intl";
import { Color, Consumer, IconId } from "@sinch/types";
import { all, assoc, mergeRight, props } from "ramda";
import { isFalse } from "ramda-adjunct";
import { useReducer } from "react";

type FormInvalid = { invalid: boolean };
type FormSubmitting = { submitting: boolean };
type FormUploading = { uploading: boolean };

export interface FormStatus extends FormInvalid, FormSubmitting, FormUploading {
  ready: boolean;
}

interface FormStatusDisplayProps {
  color: Color;

  icon: IconId;

  /** todo: label should be translation key */
  label: string;
}

export function formStatusDisplayProps({
  invalid,
  submitting,
  uploading,
}: FormInvalid & FormSubmitting & FormUploading): FormStatusDisplayProps {
  if (submitting)
    return {
      color: "secondary",
      icon: mdiUpdate,
      label: t("formStatus.submitting"),
    };

  if (invalid)
    return {
      color: "error",
      icon: mdiAlertOutline,
      label: t("formStatus.invalid"),
    };

  if (uploading)
    return {
      color: "warning",
      icon: mdiProgressUpload,
      label: t("formStatus.uploading"),
    };

  return {
    color: "primary",
    icon: mdiCheckboxMarkedCircleOutline,
    label: t("formStatus.ready"),
  };
}

const initialStatus: FormStatus = {
  invalid: false,
  ready: true,
  submitting: false,
  uploading: false,
};

export type FormAction = FormInvalid | FormSubmitting | FormUploading;

const isReady = (status: FormStatus) => all(isFalse, props(["invalid", "submitting", "uploading"], status));

function formStatusReducer(status: FormStatus, action: FormAction): FormStatus {
  const next: FormStatus = mergeRight(status, action);
  return assoc("ready", isReady(next), next);
}

export function useFormStatus(): [status: FormStatus, updateStatus: Consumer<FormAction>] {
  return useReducer(formStatusReducer, initialStatus);
}
