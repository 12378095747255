import { t } from "@sinch/intl";
import { OneOrMore, Predicate } from "@sinch/types";
import { flatList } from "@sinch/utils";
import {
  anyPass,
  concat,
  endsWith,
  equals,
  flatten,
  gt,
  isEmpty,
  join,
  map,
  startsWith,
  T,
} from "ramda";

/**
 * todo: consider merging `accept` and `extensions` into single prop
 *  for better consistency with html
 */
export interface FileValidatorParams {
  /**
   * One or more [unique file type
   * specifiers](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers)
   * describing file types to allow.
   *
   * Note that this attribute doesn't validate the types of the selected files;
   * it simply provides hints for browsers to guide users towards selecting the
   * correct file types. It is still possible (in most cases) for users to
   * toggle an option in the file chooser that makes it possible to override
   * this and select any file they wish, and then choose incorrect file types.
   */
  accept?: OneOrMore<string>;

  /**
   * File extensions to be validated.
   *
   */
  extensions?: OneOrMore<string>;

  /**
   * Maximum file size for validations.
   */
  maxSize?: number;
}

export interface FileValidator extends Predicate<File>, FileValidatorParams {
  /**
   * Prepared value for HTML input element `accept` prop
   */
  inputAccept: string;
}

/**
 * todo: fix validation error where both accept and extensions are defined
 *  accept="image/*"
 *  extensions=".pdf"
 *  => throws error when any of those are selected
 */
export function createValidator({
  accept: acceptProp,
  extensions: extensionsProp,
  maxSize,
}: FileValidatorParams): FileValidator {
  const accept = flatList(acceptProp);
  const extensions = flatList(extensionsProp);

  const hasValidType = isEmpty(accept)
    ? T
    : anyPass(
        map(
          (t) =>
            endsWith("*", t)
              ? startsWith(t.length === 1 ? t.slice(0, -1) : t.slice(0, -2))
              : equals(t),
          flatten([accept])
        )
      );

  const hasValidExtension = isEmpty(extensions)
    ? T
    : anyPass(map((t) => endsWith(t), flatten([extensions])));

  const hasValidMaxSize = maxSize ? (size: number) => gt(size, maxSize) : T;

  function validate({ name, size, type }: File): true | never {
    if (!hasValidExtension(name))
      throw new TypeError(
        t("FileValidation.unsupportedExtension", { name })
      ); // File has unsupported extension: {{name}}

    if (!hasValidType(type))
      throw new TypeError(t("FileValidation.unsupportedType", { type })); // File has unsupported mime type: ${type}

    if (!hasValidMaxSize(size))
      throw new TypeError(t("FileValidation.tooLarge", { size })); // File is too large: ${size}b

    return true;
  }

  validate.accept = accept;
  validate.extensions = extensions;
  validate.maxSize = maxSize;
  validate.inputAccept = join(",", concat(accept, extensions));

  return validate;
}
