import { t } from "@sinch/intl";

export enum PayoutPaymentType {
  Cash = 1,

  Transfer = 0,
}

/**
 * Return labels for transfer types
 */
export function getPayoutPaymentTypeLabels() {
  return {
    [PayoutPaymentType.Cash]: t("Wallet.payoutPaymentTypeCash"),
    [PayoutPaymentType.Transfer]: t("Wallet.payoutPaymentTypeTransfer"),
  };
}
