import { useData } from "@sinch/core";
import { Fk, Position, selectAppointment } from "@sinch/entity";
import React, { ReactElement } from "react";

export function AppointmentContact({ id }: { id: Fk<Position> }): ReactElement {
  // @ts-expect-error
  const { selectEntity } = useData();
  const { contact } = selectEntity(selectAppointment(id));

  return (
    <>
      {contact.map((contactItem) => (
        <React.Fragment key={contactItem}>{contactItem}</React.Fragment>
      ))}
    </>
  );
}
