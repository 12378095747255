import { FormHelperText as MuiFormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mdiRefresh, mdiTrayPlus, mdiUpload } from "@mdi/js";

import { t } from "@sinch/intl";
import { Color, FileHash, IconId, Nullable, OneOrMore } from "@sinch/types";
import { Button } from "@sinch/ui";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ChangeEvent, ReactElement, useCallback, useRef } from "react";
import { CommonInputProps } from "../Input";
import { useFileInputContext } from "./FileInputProvider";

const useStyles = makeStyles({
  hidden: {
    display: "none",
  },
});

export interface FileInputButtonProps extends Pick<CommonInputProps, "disabled" | "name" | "note"> {
  error?: boolean;
  variant?: "text" | "outlined" | "contained";
  color?: Color;
  size?: "small" | "medium" | "large";
  label?: string;
  icon?: string;
}

type ButtonDisplayProps = [label: string, icon: IconId];
type ButtonDisplayState = "mEmp" | "mVal" | "sEmp" | "sVal";

const displayProps = (): Record<ButtonDisplayState, ButtonDisplayProps> => ({
  mEmp: [t("FileInput.selectFiles"), mdiUpload],
  mVal: [t("FileInput.addFiles"), mdiTrayPlus],
  sEmp: [t("FileInput.selectFile"), mdiUpload],
  sVal: [t("FileInput.changeFile"), mdiRefresh],
});

const getDisplayProps = (value: Nullable<OneOrMore<FileHash>>, multiple?: boolean): ButtonDisplayProps =>
  isNilOrEmpty(value) ? displayProps()[multiple ? "mEmp" : "sEmp"] : displayProps()[multiple ? "mVal" : "sVal"];

export function FileInputButton({
  disabled,
  error,
  name,
  note,
  variant = "text",
  color,
  size,
  label: buttonLabel,
  icon: buttonIcon,
}: FileInputButtonProps): ReactElement {
  const styles = useStyles();
  const { addFiles, multiple, validator, value } = useFileInputContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const handleOpen = useCallback(() => inputRef.current?.click(), []);

  const handleSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.currentTarget.files ?? [];
      addFiles([...fileList]);
    },
    [addFiles]
  );

  const [label, icon] = getDisplayProps(value, multiple);

  return (
    <>
      <Button
        action={handleOpen}
        color={color ?? "secondary"}
        disabled={disabled}
        icon={buttonIcon ?? icon}
        size={size}
        variant={variant}
      >
        {buttonLabel ?? label}
      </Button>
      <input
        ref={inputRef}
        accept={validator.inputAccept}
        className={styles.hidden}
        disabled={disabled}
        multiple={multiple}
        name={name}
        onChange={handleSelect}
        type="file"
        value={[]}
      />
      {note && <MuiFormHelperText error={error}>{note}</MuiFormHelperText>}
    </>
  );
}
