import { makeStyles } from "@material-ui/core/styles";
import { Color, Func, IconId, Maybe, OneOrMore } from "@sinch/types";
import { prop } from "ramda";
import React, { Children, ReactElement } from "react";
import { Icon } from "../Icon";
import { ListBase } from "../ListBase";
import { Text } from "../Text";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    width: "100%",
  },
  icon: {
    position: "relative",
    top: "0.2em",
    paddingRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

export interface DataListSelectors<T> {
  colorSelector: Func<T, Maybe<Color>>;

  contentSelector: Func<T, OneOrMore<string>>;

  iconSelector: Func<T, IconId>;

  labelSelector: Func<T, OneOrMore<string>>;
}

export interface DataListItemProps<T> extends DataListSelectors<T> {
  item: T;
  disableIcons?: boolean;
}

export function DataListItem<T>({
  colorSelector,
  contentSelector,
  iconSelector,
  item,
  labelSelector,
  disableIcons,
}: DataListItemProps<T>): ReactElement | null {
  const styles = useStyles();

  const color = colorSelector(item);
  const content = contentSelector(item);
  const icon = iconSelector(item);
  const label = labelSelector(item);

  if (content === null) {
    return null;
  }

  return (
    <div className={styles.item}>
      {!disableIcons && (
        <div className={styles.icon}>
          <Icon color={color || "neutral"} icon={icon} size="small" />
        </div>
      )}
      <div className={styles.content}>
        {Children.map(label, (row) => (
          <Text bold color={color}>
            {row}
          </Text>
        ))}
        {Children.map(content, (row) => (
          <div>
            <Text color={color}>{row}</Text>
          </div>
        ))}
      </div>
    </div>
  );
}

export interface DataListProps<T> extends Partial<DataListSelectors<T>> {
  data: T[];

  disablePadding?: boolean;
  disableIcons?: boolean;

  dividerSelector?: Func<T, Maybe<boolean>>;
  dense?: boolean;
}

/**
 * todo: might be better to add divider above and not under
 *
 * todo: support content formatting and icon colors
 *
 * todo: support expansible content?
 *
 * todo: how to differentiate between full item link
 *  vs small link on the right?
 *
 * todo: support any action type and not only router link
 *
 * todo: we should differentiate base component without default selectors
 *  and preconfigured component expecting particular data shape,
 *  since current implementation is error prone
 */
export function DataList<T = DataListDefaultItem>({
  /* @ts-expect-error */
  colorSelector = prop("color"),
  /* @ts-expect-error */
  contentSelector = prop("content"),
  data,
  disablePadding,
  disableIcons,
  /* @ts-expect-error */
  dividerSelector = prop("divider"),
  /* @ts-expect-error */
  iconSelector = prop("icon"),
  /* @ts-expect-error */
  labelSelector = prop("label"),
  dense,
}: DataListProps<T>): ReactElement {
  const listContentSelector = (item: T) => (
    <DataListItem
      colorSelector={colorSelector}
      contentSelector={contentSelector}
      disableIcons={disableIcons}
      iconSelector={iconSelector}
      item={item}
      labelSelector={labelSelector}
    />
  );

  return (
    <ListBase
      contentSelector={listContentSelector}
      data={data}
      dense={dense}
      disablePadding={disablePadding}
      dividerSelector={dividerSelector}
    />
  );
}

export interface DataListDefaultItem {
  color?: Color;

  content: OneOrMore<string>;

  divider?: boolean;

  icon?: IconId;

  label: OneOrMore<string>;

  link?: { text: string; url: string };
}
