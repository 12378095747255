import { useData } from "@sinch/core";
import { selectPosition } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Nullable } from "@sinch/types";
import { Card, GridLayout, ListBase, Text } from "@sinch/ui";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Section } from "../../components";
import { requestPositionDetail } from "./api";

export function PositionDetailRequirements(): Nullable<ReactElement> {
  const { selectEntity, selectResult } = useData(requestPositionDetail);
  const { positionId } = selectResult();

  const { requirements } = selectEntity(selectPosition(positionId));

  if (isNilOrEmpty(requirements)) {
    return null;
  }

  return (
    <GridLayout.Item>
      <Card>
        <Section title={t("Requirement.titlePlural")}>
          <ListBase
            contentSelector={({ title, failed }) => <Text color={failed ? "error" : undefined}>{title}</Text>}
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            data={requirements!}
            dense
            disablePadding
          />
        </Section>
      </Card>
    </GridLayout.Item>
  );
}
