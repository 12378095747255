import { DataProvider, responseHandlerKey, responseHandlerSequence, useSession } from "@sinch/core";
import { t } from "@sinch/intl";
import { useSnackbar } from "@sinch/ui";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router";
import { requestProfileAttributeUpdate } from "../api";
import { useProfileContext } from "../ProfileContext";
import { EditProfileAttributes } from "./EditProfileAttributes";

export function ProfileAttributeUpdateContainer(): ReactElement {
  const navigate = useNavigate();
  const { refresh } = useProfileContext();
  const { refresh: sessionRefresh } = useSession();
  const snackbar = useSnackbar();

  const responseHandler = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestProfileAttributeUpdate,
          () => {
            snackbar("success", t("Profile.display.updateProfileAttributesSuccessful"));
            refresh();
            sessionRefresh();
            navigate("/profile");
          },
          () => {
            snackbar("error", t("Profile.display.updateProfileAttributesError"));
            refresh();
          }
        ),
      ]),
    [navigate, refresh, snackbar]
  );

  return (
    <DataProvider handler={responseHandler}>
      <EditProfileAttributes />
    </DataProvider>
  );
}
