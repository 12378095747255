import { Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { t } from "@sinch/intl";
import { ChildrenProps } from "@sinch/utils";
import React, { ReactElement, useEffect, useState } from "react";
import { useOnlineStatus } from "../backend/provider/OnlineStatusProvider";

const useStyles = makeStyles(({ mixins: { component, onLarge } }) => ({
  backdrop: {
    zIndex: 2000,
  },
}));

export function OfflineWarning({ children }: ChildrenProps): ReactElement {
  const styles = useStyles();
  const { isOnline, timeout } = useOnlineStatus();
  const [timer, setTimer] = useState(timeout);

  useEffect(() => {
    if (!isOnline && timeout) {
      setTimeout(() => {
        setTimer((current) => {
          const newTimer = current - 1;
          if (newTimer < 0) {
            return timeout;
          }
          return newTimer;
        });
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    if (!timer || timer <= 0) {
      setTimer(!isOnline ? timeout : undefined);
    }
  }, [timeout]);

  return (
    <>
      {!isOnline && (
        <Backdrop className={styles.backdrop} invisible open>
          <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!isOnline}>
            <Alert
              action={timeout ? timer : <CircularProgress color="inherit" size={16} />}
              className={styles.alert}
              severity="warning"
              variant="filled"
            >
              <>
                <AlertTitle>{t("youAreOffline")}</AlertTitle>
                {t("waitingForConnection")}
              </>
            </Alert>
          </Snackbar>
        </Backdrop>
      )}
      {children}
    </>
  );
}
