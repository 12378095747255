import { DataProvider, useCurrentUser, useData, useInstanceSettings } from "@sinch/core";
import { selectProfileAttribute } from "@sinch/entity";
import { equals } from "ramda";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { requestProfileAttributeList } from "./api";
import { ProfileAttributeGroupCard } from "./ProfileAttributeGroupCard";

export type ProfileAttributesParams = {
  edit: string;
};

export function ProfileAttributes({ edit }: ProfileAttributesParams): ReactElement {
  const { selectEntity, selectResult } = useData(requestProfileAttributeList);
  const { attributes: attributesValues } = selectResult();
  const attributes = selectEntity(selectProfileAttribute({}));
  const { country } = useInstanceSettings();
  const { citizenship } = useCurrentUser();

  return (
    <ProfileAttributeGroupCard
      attributes={attributes}
      attributesValues={attributesValues}
      edit={edit}
      isCitizen={equals(country, citizenship)}
    />
  );
}

export type ProfileAttributeListContainerParams = {
  children: ReactNode;
};

export function ProfileAttributeListContainer({ children }: ProfileAttributeListContainerParams): ReactElement {
  const request = useMemo(() => requestProfileAttributeList(), []);

  return <DataProvider request={request}>{children}</DataProvider>;
}
