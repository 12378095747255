import { Box, ListItem, MenuItem as MuiMenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { mdiAccountReactivateOutline, mdiChevronDown } from "@mdi/js";

import { useCurrentUser } from "@sinch/core";
import { UserRole } from "@sinch/core/contract";
import { t } from "@sinch/intl";
import { map } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { ReactElement } from "react";
import { Nullable } from "../../types";
import { useExclusiveToggle } from "../ApplicationLayout/ExclusiveToggleContext";
import { PopperMenu } from "../ApplicationLayout/PopperMenu";
import { ToolbarButton } from "../ApplicationLayout/TitleBar";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { useMobileLayout } from "../UiProvider";

const useStyles = makeStyles((theme) => ({
  active: {
    background: theme.palette.action.selected,
  },
}));

export function RoleSwitch(): Nullable<ReactElement> {
  const { roles } = useCurrentUser();
  const styles = useStyles();
  const toggleControl = useExclusiveToggle("RoleSwitch");
  const isMobile = useMobileLayout();

  if (Object.entries(roles).length <= 1) {
    return null;
  }

  const getMenuItem = ({ id, name }: UserRole) => (
    <MuiMenuItem
      key={id}
      button
      component={(props) => (
        <a {...props} href={`${window.location.protocol}//${window.location.host}/users/switchRole/${id}`}>
          <Box data-cy="parter-profile" pl={2}>
            {name}
          </Box>
        </a>
      )}
    />
  );

  return (
    <PopperMenu toggleControl={toggleControl}>
      <ToolbarButton data-cy="select-profile">
        {!isMobile && (
          <>
            <Box display="flex" pr={1}>
              <Text bold>{roles.worker[0].name}</Text>
            </Box>
            <Icon icon={mdiChevronDown} size="small" />
          </>
        )}
        {isMobile && <Icon icon={mdiAccountReactivateOutline} />}
      </ToolbarButton>
      {!isNilOrEmpty(roles.admin) && (
        <ListItem divider>
          <Text bold>{t("User.roleAdmin")}</Text>
        </ListItem>
      )}
      {map(getMenuItem, roles.admin || [])}
      {!isNilOrEmpty(roles.client) && (
        <ListItem divider>
          <Text bold>{t("User.roleClient")}</Text>
        </ListItem>
      )}
      {map(getMenuItem, roles.client || [])}
      {(!isNilOrEmpty(roles.worker) || !isNilOrEmpty(roles.superadmin)) && (
        <ListItem divider>
          <Text bold>{t("User.roleWorker")}</Text>
        </ListItem>
      )}
      <MuiMenuItem
        className={styles.active}
        divider
        onClick={() => (window.location.href = `/users/switchRole/${roles.worker[0].id}`)}
      >
        <Box pl={2}>{roles.worker[0].name}</Box>
      </MuiMenuItem>
      {map(getMenuItem, roles.superadmin || [])}
    </PopperMenu>
  );
}
