import MuiAvatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { FileInfo, useFiles } from "@sinch/core";
import { FileHash, IconId, Maybe } from "@sinch/types";
import clsx from "clsx";
import { isNil } from "ramda";
import { isObject, isString } from "ramda-adjunct";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Icon } from "../Icon";
import { ImagePreview } from "../MediaPreview";

const useStyles = makeStyles((theme) => ({
  xs: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  sm: {},
  md: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  lg: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  xl: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  clickable: {
    cursor: "pointer",
  },
}));

type FileWithVariant = { file: FileHash; variant: string };

export type AvatarProps = {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  className?: string;
  withPreview?: boolean | string;
} & (
  | { icon?: never; file: Maybe<FileHash | FileWithVariant>; src?: never }
  | { icon?: never; file?: never; src: Maybe<string> }
  | { icon?: IconId; file?: never; src?: never }
);

export function Avatar({ file, src, size = "sm", icon, className, withPreview }: AvatarProps): ReactElement {
  const storage = useFiles();
  const classes = useStyles();
  const [preview, setPreview] = useState(false);

  const storedFile: FileInfo | null = useMemo(() => {
    if (file) {
      if (isObject(file)) {
        const { file: variantFile } = file as FileWithVariant;
        return variantFile ? storage(variantFile) : null;
      }
      return file ? storage(file as string) : null;
    }
    return null;
  }, [file]);

  // @ts-ignore
  const fileSrc: string = useMemo(() => {
    if (storedFile) {
      if (isObject(file) && storedFile?.variants) {
        const { variant } = file as FileWithVariant;
        return storedFile?.variants[variant]?.url;
      }
      return storedFile?.url;
    }
    return src;
  }, [file, src, storedFile]);

  const handleClick = useCallback(
    (e) => {
      if (withPreview) {
        e.stopPropagation();
        e.preventDefault();
        setPreview(true);
      }
    },
    [withPreview]
  );

  if (icon) {
    return (
      <MuiAvatar className={clsx(classes[size], className)}>
        <Icon icon={icon} />
      </MuiAvatar>
    );
  }

  const clickable = withPreview && ((storedFile && !isNil(storedFile.variants)) || src);
  return (
    <>
      <MuiAvatar
        className={clsx(clickable && classes.clickable, className, classes[size])}
        onClick={handleClick}
        src={fileSrc}
      />
      {preview && clickable && (
        <ImagePreview
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setPreview(false);
          }}
          src={isString(withPreview) && storedFile ? storedFile.variants[withPreview].url : fileSrc}
        />
      )}
    </>
  );
}
