import { useSearchParamsCustom } from "@sinch/core";
import { t } from "@sinch/intl";
import { Box, Header } from "@sinch/ui";
import { isEmpty } from "ramda";
import React, { ReactElement } from "react";

export function WalletEmptyMessage(): ReactElement {
  const { searchParams } = useSearchParamsCustom();

  return (
    <Box alignItems="center" display="flex" height="24em" justifyContent="center">
      <Header color="secondary">{t("Wallet.display.filterEmpty")}</Header>
    </Box>
  );
}
