/* eslint-disable react/jsx-props-no-spreading */
import { Grid as MuiGrid } from "@material-ui/core";
import { ClosingProgress } from "@sinch/entity";
import { t } from "@sinch/intl";
import { Button, useSpacing } from "@sinch/ui";
import { includes } from "ramda";
import React, { ReactElement } from "react";
import { useClosingStatusProps } from "../hooks";

export interface StateMenuProps {
  onSetState: (status: ClosingProgress, inputName?: string) => void;
}

function PendingMenu({ onSetState }: StateMenuProps) {
  const [, , data] = useSpacing();
  const { getProps, getLabel } = useClosingStatusProps();
  return (
    <MuiGrid container spacing={data} wrap="nowrap">
      <MuiGrid item xs>
        <Button
          action={() => onSetState(ClosingProgress.Present, "startTime")}
          {...getProps(ClosingProgress.Present, ["color", "icon"])}
          large
        >
          {t<string>("Shift.closing.clockIn")}
        </Button>
      </MuiGrid>
      <MuiGrid item xs="auto">
        <Button
          action={() => onSetState(ClosingProgress.Absent)}
          {...getProps(ClosingProgress.Absent, ["color", "icon"])}
          large
        />
      </MuiGrid>
    </MuiGrid>
  );
}

function BreakMenu({ onSetState }: StateMenuProps) {
  const { getProps } = useClosingStatusProps();
  return (
    <MuiGrid item xs>
      <Button
        action={() => onSetState(ClosingProgress.AfterBreak, "workBreak.endTime")}
        large
        {...getProps(ClosingProgress.AfterBreak, ["color", "icon"])}
      >
        {t("Shift.closing.breakTo")}
      </Button>
    </MuiGrid>
  );
}

function PresentMenu({ onSetState, isAfterBreak }: StateMenuProps & { isAfterBreak: boolean }) {
  const { getProps } = useClosingStatusProps();
  const [, , data] = useSpacing();
  return (
    <MuiGrid container spacing={data} wrap="nowrap">
      <MuiGrid item xs>
        <Button
          action={() => onSetState(ClosingProgress.Finished, "endTime")}
          large
          {...getProps(ClosingProgress.Finished, ["color", "icon"])}
        >
          {t<string>("Shift.closing.clockOut")}
        </Button>
      </MuiGrid>
      {!isAfterBreak && (
        <MuiGrid item xs="auto">
          <Button
            action={() => onSetState(ClosingProgress.Break, "workBreak.startTime")}
            large
            {...getProps(ClosingProgress.Break, ["color", "icon"])}
          />
        </MuiGrid>
      )}
    </MuiGrid>
  );
}

function AbsentMenu({ onSetState }: StateMenuProps) {
  const { getProps, getLabel } = useClosingStatusProps();
  return (
    <MuiGrid item xs>
      <Button
        action={() => onSetState(ClosingProgress.Present, "startTime")}
        large
        {...getProps(ClosingProgress.Present, ["color", "icon"])}
      >
        {getLabel(ClosingProgress.Present)}
      </Button>
    </MuiGrid>
  );
}

export function SetStateButtons({
  closingStatus,
  onSetState,
}: {
  closingStatus: string | number;
  onSetState: (status: ClosingProgress, inputName?: string) => void;
}): ReactElement {
  return (
    <>
      {closingStatus === ClosingProgress.Pending && <PendingMenu onSetState={onSetState} />}
      {closingStatus === ClosingProgress.Break && <BreakMenu onSetState={onSetState} />}
      {includes(closingStatus, [ClosingProgress.Present, ClosingProgress.AfterBreak]) && (
        <PresentMenu isAfterBreak={closingStatus === ClosingProgress.AfterBreak} onSetState={onSetState} />
      )}
      {closingStatus === ClosingProgress.Absent && <AbsentMenu onSetState={onSetState} />}
    </>
  );
}
