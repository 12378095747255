import { Callback, Consumer } from "@sinch/types";
import { ChildrenProps, createCheckedContext } from "@sinch/utils";
import { isNil, map } from "ramda";
import React, { ReactElement, useEffect, useState } from "react";

const ExclusiveToggleContext = createCheckedContext<[Record<string, boolean>, Consumer<Record<string, boolean>>]>(
  "ExclusiveToggleContext"
);
const { use: useExclusiveToggleContext } = ExclusiveToggleContext;

export function ExclusiveToggleContextProvider({ children }: ChildrenProps): ReactElement {
  const stateContext = useState<Record<string, boolean>>({});

  return <ExclusiveToggleContext value={stateContext}>{children}</ExclusiveToggleContext>;
}

export function useExclusiveToggle(id: string, initialState = false): [boolean, Callback, Consumer<boolean>] {
  const [toggleItems, setToggleItems] = useExclusiveToggleContext();

  useEffect(() => {
    if (isNil(toggleItems[id])) {
      setToggleItems({
        ...toggleItems,
        [id]: initialState,
      });
    }
  }, [id]);

  return [
    toggleItems[id] || initialState,
    () =>
      setToggleItems({
        ...map(() => false, toggleItems),
        [id]: !toggleItems[id],
      }),
    (val) =>
      setToggleItems({
        ...map(() => false, toggleItems),
        [id]: val,
      }),
  ];
}
