import { defineRequest } from "@sinch/core";
import { Fk, ProfileAttribute, ProfileAttributeContainer } from "@sinch/entity";
import { parseDate } from "@sinch/utils";
import { evolve, map } from "ramda";

export interface ProfileAttributeValue {
  id: Fk<ProfileAttribute>;

  value: unknown;
}

export interface ProfileAttributeStatus {
  expiration?: Date;

  verified?: boolean;
}

export type ProfileAttributeData = ProfileAttributeValue & ProfileAttributeStatus;

export const requestProfileAttributeList = defineRequest("Profiles/AttributeList")
  .withEmptyParams()
  .withResponse<{ attributes: ProfileAttributeData[] }>({
    attributes: map(evolve({ expiration: parseDate })),
  })
  .withResponseEntity<ProfileAttributeContainer>()
  .withDefaultCreator();
