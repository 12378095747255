import { isArray, isObject } from "@sinch/utils";
import { filter, pluck, prop, props, values, whereEq } from "ramda";
import { Entity } from "./Entity";
import { EntityContainer } from "./EntityContainer";
import { Id } from "./Id";

export interface EntitySelector<TEntity extends Entity> {
  (id: Id): <TContainer extends EntityContainer<TEntity, never>>(container: TContainer) => TEntity;

  (ids: Id[]): <TContainer extends EntityContainer<TEntity, never>>(container: TContainer) => TEntity[];

  (spec: Partial<TEntity>): <TContainer extends EntityContainer<TEntity, never>>(container: TContainer) => TEntity[];

  <TOut extends keyof TEntity>(id: Id, out: TOut): <TContainer extends EntityContainer<TEntity, never>>(
    container: TContainer
  ) => TEntity[TOut];

  <TOut extends keyof TEntity>(ids: Id[], out: TOut): <TContainer extends EntityContainer<TEntity, never>>(
    container: TContainer
  ) => TEntity[TOut][];

  <TOut extends keyof TEntity>(spec: Partial<TEntity>, out: TOut): <TContainer extends EntityContainer<TEntity, never>>(
    container: TContainer
  ) => TEntity[TOut][];
}

type SelectOne<TEntity, TContainer> = (id: Id) => (container: TContainer) => TEntity;

type SelectOneProp<TEntity, TContainer> = <TOut extends keyof TEntity>(
  id: Id,
  out: TOut
) => (container: TContainer) => TEntity[TOut];

type SelectMany<TEntity, TContainer> = (ids: Id[]) => (container: TContainer) => TEntity[];

type SelectManyProp<TEntity, TContainer> = <TOut extends keyof TEntity>(
  ids: Id[],
  out: TOut
) => (container: TContainer) => TEntity[TOut][];

type FindMatch<TEntity, TContainer> = (spec: Partial<TEntity>) => (container: TContainer) => TEntity[];

type FindMatchProp<TEntity, TContainer> = <TOut extends keyof TEntity>(
  spec: Partial<TEntity>,
  out: TOut
) => (container: TContainer) => TEntity[TOut][];

export function createEntitySelector<TEntity extends Entity, TContainer extends EntityContainer<TEntity>>(
  containerKey: keyof TContainer
): EntitySelector<TEntity> {
  const selectOne: SelectOne<TEntity, TContainer> = (id: Id) => (container) => container[containerKey][id];

  const selectOneProp: SelectOneProp<TEntity, TContainer> = (id: Id, out) => (container) =>
    prop(out, container[containerKey][id]);

  const selectMany: SelectMany<TEntity, TContainer> = (ids: Id[]) =>
    /* @ts-expect-error (numeric ids are valid object indexes as well) */
    (container) => props(ids, container[containerKey]);

  const selectManyProp: SelectManyProp<TEntity, TContainer> = (ids: Id[], out) => (container) =>
    /* @ts-expect-error (numeric ids are valid object indexes as well) */
    pluck(out, props(ids, container[containerKey]));

  const findMatch: FindMatch<TEntity, TContainer> = (spec) => {
    const search = filter(whereEq(spec));
    return (container) => values(search(container[containerKey]));
  };

  const findMatchProp: FindMatchProp<TEntity, TContainer> = (spec, out) => {
    const search = filter(whereEq(spec));
    return (container) => pluck(out, values(search(container[containerKey])));
  };

  function selectEntities<TOut extends keyof TEntity>(params: Id | Id[] | Partial<TEntity>, out?: TOut) {
    if (typeof params === "number") return out ? selectOneProp(params, out) : selectOne(params);

    if (isArray(params)) return out ? selectManyProp(params, out) : selectMany(params);

    if (isObject(params)) {
      return out ? findMatchProp(params, out) : findMatch(params);
    }

    /*
     * undefined selector will be reported as error when passed into
     *  `query` function returned from `useEntityContainer` hook
     */
    return undefined;
  }

  return selectEntities as EntitySelector<TEntity>;
}
