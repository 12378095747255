import {
  DataProvider,
  responseHandlerKey,
  responseHandlerSequence,
  ServerMessage,
  useCascadeRefresh,
  useRefresh,
} from "@sinch/core";
import { Fk, Location, Position } from "@sinch/entity";
import { useFormat } from "@sinch/intl";
import { LoadingOverlay, useSnackbar } from "@sinch/ui";
import { find, prop, propEq } from "ramda";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestClockIn, requestClockOut } from "../api";
import { ClockInButton } from "./ClockInButton";
import { ClockOutButton } from "./ClockOutButton";

interface StaffClockInOutButtonsWrapperProps {
  position: Fk<Position>;
  fullWidth?: boolean;
  isStartTimeClockIn?: boolean;
  isEndTimeClockIn?: boolean;
  positionStartTime: Date;
  location?: Location;
}

export const StaffClockInOutButtonsWrapper: React.FC<StaffClockInOutButtonsWrapperProps> = ({
  position,
  fullWidth,
  isStartTimeClockIn,
  isEndTimeClockIn,
  positionStartTime,
  location,
}) => {
  const { dt } = useFormat();
  const { refresh, refreshToken } = useRefresh();
  const { cascadeRefresh } = useCascadeRefresh();
  const [clockInDateTime, setClockInDateTime] = useState<Date | undefined>(undefined);
  const [clockOutDateTime, setClockOutDateTime] = useState<Date | undefined>(undefined);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleError = (messages: ServerMessage[], defaultMessage: string) => {
    const errorMessage = find<ServerMessage>(propEq("level", "ERROR"))(messages);
    if (errorMessage) {
      snackbar("error", prop("text", errorMessage));
    } else {
      snackbar("error", defaultMessage);
    }
  };

  const handleSuccess = (messages: ServerMessage[], defaultMessage: string) => {
    const successMessage = find<ServerMessage>(propEq("level", "SUCCESS"))(messages);
    if (successMessage) {
      snackbar("success", prop("text", successMessage));
    } else {
      snackbar("success", defaultMessage);
    }
  };

  const responseHandler = useMemo(
    () =>
      responseHandlerSequence<any>([
        responseHandlerKey(
          requestClockIn,
          ({
            response: {
              result: { clockIn, clockInTime },
              messages,
            },
          }) => {
            if (clockIn && clockInTime) {
              setClockInDateTime(clockInTime);
              cascadeRefresh();
              refresh();
              handleSuccess(
                messages,
                t<string>("StaffClockIn.clockInSuccess", { time: dt.time(clockInTime) })
              );
            } else {
              handleError(messages, t<string>("StaffClockIn.clockInError"));
            }
          },
          ({ response: { messages } }) => {
            handleError(messages, t<string>("StaffClockIn.clockInError"));
          }
        ),
        responseHandlerKey(
          requestClockOut,
          ({
            response: {
              result: { clockOutTime, clockOut },
              messages,
            },
          }) => {
            if (clockOut && clockOutTime) {
              setClockOutDateTime(clockOutTime);
              cascadeRefresh();
              refresh();
              handleSuccess(
                messages,
                t<string>("StaffClockIn.clockOutSuccess", { time: dt.time(clockOutTime) })
              );
            } else {
              handleError(messages, t<string>("StaffClockIn.clockOutError"));
            }
          },
          ({ response: { messages } }) => {
            handleError(messages, t<string>("StaffClockIn.clockOutError"));
          }
        ),
      ]),
    []
  );

  const Content = () => {
    if (!isStartTimeClockIn && !isEndTimeClockIn) {
      return <ClockInButton dateTime={clockInDateTime} fullWidth={fullWidth} location={location} position={position} />;
    }
    if (isStartTimeClockIn && !isEndTimeClockIn) {
      return (
        <ClockOutButton
          dateTime={clockOutDateTime}
          fullWidth={fullWidth}
          location={location}
          position={position}
          positionStartTime={positionStartTime}
        />
      );
    }
    return null;
  };

  return (
    <DataProvider handler={responseHandler} progress={<LoadingOverlay />} refresh={refreshToken}>
      <Content />
    </DataProvider>
  );
};
