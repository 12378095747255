import { useBusinessRules, useData } from "@sinch/core";
import {
  Fk,
  getPayoutPaymentTypeLabels,
  Payout,
  PayoutStatusLabels,
  selectPayout,
  selectPayoutMethod,
} from "@sinch/entity";
import { t } from "@sinch/intl";
import {
  composeCellLogic,
  composeRowLogic,
  createClickableRow,
  createMuiStyleCell,
  createTextAlignCell,
  currencyLogic,
  dateTimeLogic,
  MuiDataTable,
  MuiDataTableDisplay,
  routerLink,
  typographyLogic,
} from "@sinch/ui";
import { always, isEmpty, pipe, pluck, prop, reverse, sortBy } from "ramda";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { SearchParamsPagination } from "../../components";
import { requestWalletPayoutList } from "./api";
import { WalletEmptyMessage } from "./WalletEmptyMessage";
import { WalletSearchForm } from "./WalletSearchForm";

type PayoutColumn = "accountingDate" | "grossAmount" | "method" | "netAmount" | "paymentType" | "status";
const TextAlignRightCell = createTextAlignCell("right");

export function WalletPayoutTable(): ReactElement {
  const { selectEntity, selectResult } = useData(requestWalletPayoutList);
  const { search } = useLocation();
  const { payoutIds } = selectResult();
  const { allowWorkerPaymentRequest } = useBusinessRules();

  const sortedPayoutIds: number[] = pipe<Payout[], Payout[], number[], number[]>(
    sortBy(prop("accountingDate")),
    pluck("id"),
    reverse
  )(selectEntity(selectPayout(payoutIds)));

  const ClickableRow = createClickableRow(
    (id: Fk<Payout>) => routerLink({ search, hash: `#/payout/${id}` }),
    () => allowWorkerPaymentRequest
  );

  const PayoutDataTable = MuiDataTable.withConfig<Fk<Payout>, PayoutColumn>({
    accountingDate: {
      title: t("Wallet.display.accountingDate"),
      selector: (id) => selectEntity(selectPayout(id, "accountingDate")),
      logic: composeCellLogic<Fk<Payout>, PayoutColumn, MuiDataTableDisplay>([dateTimeLogic()]),
    },
    status: {
      title: t("status.title"),
      selector: (id) => {
        const status = selectEntity(selectPayout(id, "status"));
        return PayoutStatusLabels()[status];
      },
    },
    method: {
      title: t("Contract.title"),
      selector: (id) => {
        const method = selectEntity(selectPayout(id, "method"));
        return selectEntity(selectPayoutMethod(method, "name"));
      },
    },
    paymentType: {
      title: t("Wallet.display.paymentType"),
      selector: (id) => {
        const paymentType = selectEntity(selectPayout(id, "paymentType"));
        return getPayoutPaymentTypeLabels()[paymentType];
      },
    },
    netAmount: {
      title: t("Wallet.display.netAmount"),
      titleCellProps: { align: "right" },
      selector: (id) => selectEntity(selectPayout(id, "netAmount")),
      logic: composeCellLogic<Fk<Payout>, PayoutColumn, MuiDataTableDisplay>([
        currencyLogic(),
        TextAlignRightCell as never,
        createMuiStyleCell(
          {
            amount: {
              /*
               * todo: bold should be implemented using typographic component
               *  (this is selector specificity hack as a temporary workaround,
               *   otherwise styles gets overridden by Text component)
               */
              "& p": {
                fontWeight: "bold",
              },
            },
          },
          always(["amount"])
        ),
      ]),
    },
    grossAmount: {
      title: t("Wallet.display.grossAmount"),
      titleCellProps: { align: "right" },
      selector: (id) => selectEntity(selectPayout(id, "grossAmount")),
      logic: composeCellLogic<Fk<Payout>, PayoutColumn, MuiDataTableDisplay>([
        TextAlignRightCell as never,
        currencyLogic(),
      ]),
    },
  }).withLogic(composeRowLogic([ClickableRow, typographyLogic()]));

  return (
    <>
      <WalletSearchForm entity="payout" />
      {isEmpty(payoutIds) ? (
        <WalletEmptyMessage />
      ) : (
        <PayoutDataTable
          columns={["accountingDate", "netAmount", "grossAmount", "method", "paymentType", "status"]}
          data={sortedPayoutIds}
        >
          <SearchParamsPagination />
        </PayoutDataTable>
      )}
    </>
  );
}
